import type { VestingInversifyService } from '@coti-cvi/lw-sdk'
import { isHardhatChainId } from '@coti-cvi/lw-sdk'
import { isNum, Vester, enumValues } from '@coti-cvi/lw-sdk'
import type { InverifyContext } from '../context/inversify-context'
import type { MenuItem } from '../types'
import type { Wrappers } from './wrappers'
import { BACK, NONE } from './constants'

export class Vesting {
  public readonly VestingMenu: { [key: string]: MenuItem } = {
    1: { description: 'account info', action: () => this.accountInfo() },
    i: { description: 'info', action: () => this.info() },
    d: { description: 'deposit', action: () => this.deposit() },
    w: { description: 'withdraw', action: () => this.withdraw() },
    c: { description: 'claim', action: () => this.claim() },
    e: { description: 'events', action: () => this.events() },
    r: { description: 'give bunus reward (owner)', action: () => this.bonusReward() },
    ss: { description: 'swap and send govi to vesters', action: () => this.swapAndSendGoviToVesters() },
  }

  constructor(private readonly inverifyContext: Required<InverifyContext>, private readonly wrappers: Wrappers) {}

  private async getService(): Promise<VestingInversifyService> {
    const chainId = this.inverifyContext.useVestingChainId()
    this.inverifyContext.inversifyContainer.getByBlockchain(chainId, 'TokenGOVI')
    this.inverifyContext.inversifyContainer.getByBlockchain(chainId, 'TokenEsGOVI')
    return this.inverifyContext.inversifyContainer.getByBlockchain(chainId, 'VestingInversifyService')
  }

  public async swapAndSendGoviToVesters() {
    const service = await this.getService()
    const uniswapService = this.inverifyContext.uniswapInversifyService
    const [eth, govi] = [uniswapService.currencies['ETH'], uniswapService.currencies['GOVI']]
    const pair = await this.inverifyContext.uniswapInversifyService.getPair(eth, govi)
    if (!pair.isConnected()) {
      throw new Error(`${pair} not connected`)
    }
    const { signer, address } = this.inverifyContext.signerInversifyService
    const { received, sent } = await this.inverifyContext.uniswapInversifyService.swap(100, [pair])
    this.wrappers.writeOutput(`swapped ${sent.toFixed(2)} ETH for ${received.toFixed(2)} GOVI`)
    const vesters = Object.values(Vester).map(v => [v, service.getVestingContract(v)] as const)
    const defaultAmount = 100_000
    for (const [vester, contract] of vesters) {
      const inputString = `(number) GOVI amount to transfer to ${vester} vester`
      const defaultString = `[default ${defaultAmount} GOVI]`
      const balanceString = `currenct balance ${await service.tokenGOVI.balanceToString(address)}`
      const answer = await this.wrappers.question(`${inputString} ${defaultString} ${balanceString}`)
      const amount = isNum(answer) ? +answer : defaultAmount
      await service.tokenGOVI.transfer(signer, contract.address, amount)
      this.wrappers.writeOutput(`transferred ${amount.toFixed(2)} GOVI to ${vester} vester - [${contract.address}]`)
    }
  }

  public async accountInfo(): Promise<void> {
    const service = await this.getService()
    const { address } = this.inverifyContext.signerInversifyService
    const getInfoStringOfStakedToken = async (vester: Vester) => {
      const [
        { rewardedString },
        { vestedString },
        { claimableGoviString },
        { cumulativeRewardedString },
        { totalVestedString },
        { reservedNumber, stakedNumber },
      ] = await Promise.all([
        service.getVestingStatus(vester, address),
        service.getVested(vester, address),
        service.getClaimable(vester, address),
        service.getTotalVestingStatus(vester, address),
        service.getTotalVested(vester, address),
        service.getReserved(vester, address),
      ])
      const status = `vesting status: ${rewardedString}/${vestedString}`
      const comulative = `comulative status: ${cumulativeRewardedString}/${totalVestedString}`
      const reserved = `reserved: ${reservedNumber.toFixed(6)}/${stakedNumber.toFixed(6)}`
      return `${Vester[vester]} claimable: ${claimableGoviString}, ${status}, ${comulative}, ${reserved}`
    }
    const infos = await Promise.all(enumValues(Vester).map(getInfoStringOfStakedToken))
    this.wrappers.writeOutput(`${address} info:\n${infos.join('\n')}`)
  }

  public async info(): Promise<void> {
    const service = await this.getService()
    const getInfoStringOfStakedToken = async (vester: Vester) => {
      const [{ esGoviBalanceString }] = await Promise.all([service.getVesterEsGoviBalance(vester)])
      return `${Vester[vester]} balance: ${esGoviBalanceString}, address: ${service.getVestingContract(vester).address}`
    }
    const infos = await Promise.all(enumValues(Vester).map(getInfoStringOfStakedToken))
    this.wrappers.writeOutput(`${infos.join('\n')}`)
  }

  public async bonusReward(): Promise<void> {
    const service = await this.getService()
    const { signer, address } = this.inverifyContext.signerInversifyService
    const { provider } = this.inverifyContext.providerInversifyService
    const esGoviContract = service.vestingContractsInversifyService.esGovi
    const account = await this.wrappers.selectAccount('to give bonus to')
    const printerFunction: (key: keyof typeof Vester) => Promise<string> = async key =>
      `[${key}] - current bonus amount: ${(await service.getBonusReward(Vester[key], account)).bonusString}`
    const vester = await this.wrappers.selectEnum('vester to deposit', Vester, printerFunction)
    const [answer, vestingOwner, esGOVIOwner] = await Promise.all([
      this.wrappers.question(`add bonus amount - number`),
      service.getVestingContract(vester).owner(),
      esGoviContract.owner(),
    ])

    if (
      !isHardhatChainId(this.inverifyContext.chainId) &&
      this.inverifyContext.signerInversifyService.address !== esGOVIOwner
    ) {
      throw new Error(
        `signer is not the owner of the esgovi & vestingOwner contract. make sure you sign as ${esGOVIOwner} (in arb-mainnet it's the multisig: https://app.safe.global/transactions/queue?safe=arb1:0xbE32Abcd58272Da5254945031508D4EBFcbe92EF)`,
      )
    }

    if (!isNum(answer)) {
      throw new Error('Invalid amount')
    }
    const amount = service.tokenEsGOVI.fromNumber(+answer)
    const esGoviOwnerSigner = esGOVIOwner === address ? signer : provider.getSigner(esGOVIOwner)
    await service.mintEsGoviReward(esGoviOwnerSigner, account, amount, { skipWaitingTx: true })
    const vestingOwnerSigner = vestingOwner === address ? signer : provider.getSigner(vestingOwner)
    const response = await service.giveBonusReward(vestingOwnerSigner, vester, account, amount)
    if (response) {
      this.wrappers.writeOutput(`added bonus reward, txHash: ${response.transactionHash}`)
    }
  }

  public async deposit(): Promise<void> {
    const service = await this.getService()
    const { address } = this.inverifyContext.signerInversifyService
    const balance = await service.tokenEsGOVI.getBalance(address)
    const printerFunction: (key: keyof typeof Vester) => Promise<string> = async key =>
      `[${key}] - max vestible amount: ${(await service.getMaxVestableAmount(Vester[key], address)).maxVestableString}`
    const vester = await this.wrappers.selectEnum('vester to deposit', Vester, printerFunction)
    const answer = await this.wrappers.question(
      `enter stake amount - number [${service.tokenEsGOVI.toString(balance)}] (leave empty for full stake)`,
    )
    const response = isNum(answer)
      ? await service.deposit({ vester: vester, esGoviAmount: +answer })
      : await service.depositAll({ vester: vester })
    if (response) {
      this.wrappers.writeOutput(`deposited, txHash: ${response.transactionHash}`)
    }
  }

  public async withdraw(): Promise<void> {
    const service = await this.getService()
    const { address } = this.inverifyContext.signerInversifyService
    const printerFunction: (key: keyof typeof Vester) => Promise<string> = async key =>
      `[${key}] - vested: ${(await service.getVested(Vester[key], address)).vestedString}, claimable: ${
        (await service.getClaimable(Vester[key], address)).claimableGoviString
      }`
    const vester = await this.wrappers.selectEnum('vester to withdraw', Vester, printerFunction)
    const response = await service.withdraw({ vester: vester })
    if (response) {
      this.wrappers.writeOutput(`withdrawn txHash: ${response.transactionHash}`)
    }
  }

  public async claim(): Promise<void> {
    const service = await this.getService()
    const { address } = this.inverifyContext.signerInversifyService
    const printerFunction: (key: keyof typeof Vester) => Promise<string> = async key =>
      `[${key}] - claimable: ${(await service.getClaimable(Vester[key], address)).claimableGoviString}`
    const vester = await this.wrappers.selectEnum('vester to claim', Vester, printerFunction)
    const response = await service.claim({ vester: vester })
    if (response) {
      this.wrappers.writeOutput(`claimed, txHash: ${response.transactionHash}`)
    }
  }

  public async events(): Promise<void> {
    const { address } = this.inverifyContext.signerInversifyService
    const eventsService = await this.inverifyContext.inversifyContainer.getByBlockchain(
      this.inverifyContext.useVestingChainId(),
      'VestingContractsEventsInversifyService',
    )
    let vester: Vester | undefined = undefined
    try {
      vester = await this.wrappers.selectEnum('vester (or [n]one for all events)', Vester, undefined, [BACK, NONE])
    } catch (error) {
      if (error.message !== NONE.error) {
        throw error
      }
    }
    const answer = await this.wrappers.question(
      `(string) address to filter (leave empty for current signer address (a) for all events)`,
    )
    const account = answer === 'a' ? undefined : answer.length !== 42 ? address : answer
    const { allEvents } = await eventsService.getEvents({ vester, account })
    this.wrappers.writeOutput(`all events:\n${allEvents.map(eventsService.eventToString).join('\n')}`)
  }
}
