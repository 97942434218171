import { ContainerModule } from 'inversify'
import {
  CviCacheEventsApiInversifyService,
  FormatStakingContractsEventsInversifyService,
  FormatTvContractsEventsInversifyService,
  FormatVtContractsEventsInversifyService,
  RewardRouterContractsEventsInversifyService,
  StakingContractsEventsInversifyService,
  TvContractsEventsInversifyService,
  VestingContractsEventsInversifyService,
  VtContractsEventsInversifyService,
} from '../contracts-events'
import { CviContractsInversifyService } from '../cvi-contracts'
import type { GetContractInversifyService } from '../get-contract.inversify.service'
import { StakingInversifyService } from '../staking'
import { RewardRouterInversifyService, VestingInversifyService } from '../staking-vesting'
import { ThetaVaultInversifyService } from '../theta-vault'
import type { ConnectedPair, Token } from '../token'
import { TokenPriceApiInversifyService } from '../token-prices'
import type { ChainId, IERC20, TokenName } from '../types'
import { ZKSYNC_CHAIN_IDS } from '../types'
import { TV_SUPPORTED_CHAIN_IDS } from '../types'
import { VESTING_SUPPORTED_CHAIN_IDS } from '../types'
import type { UniswapInversifyService } from '../uniswap'
import { VestingContractsInversifyService } from '../vesting-contracts'
import { VtReBaserInversifyService, VtInversifyService } from '../volatility-token'
import { ZKSyncNFTInversifyService } from '../cvi-nft'
//
export function createCviModule(chainId: ChainId) {
  return new ContainerModule(bind => {
    if (ZKSYNC_CHAIN_IDS.some(c => c === chainId)) {
      bind('ZKSyncNFTInversifyService').to(ZKSyncNFTInversifyService)
    }

    if (VESTING_SUPPORTED_CHAIN_IDS.some(c => c === chainId)) {
      bind('VestingContractsInversifyService').to(VestingContractsInversifyService)
      bind('RewardRouterInversifyService').to(RewardRouterInversifyService)
      bind('VestingInversifyService').to(VestingInversifyService)
      bind('RewardRouterContractsEventsInversifyService').to(RewardRouterContractsEventsInversifyService)
      bind('VestingContractsEventsInversifyService').to(VestingContractsEventsInversifyService)

      bind('TokenEsGOVI').toDynamicValue(async ({ container }) => {
        const getContractInversifyService = await container.getAsync<GetContractInversifyService>(
          'GetContractInversifyService',
        )
        return getContractInversifyService.getTokenFromContract('EsGOVI')
      })
    }

    if ([...TV_SUPPORTED_CHAIN_IDS, ...VESTING_SUPPORTED_CHAIN_IDS].some(c => c === chainId)) {
      bind('CVIUSDCThetaToken').toDynamicValue(async ({ container }) => {
        const getContractInversifyService = await container.getAsync<GetContractInversifyService>(
          'GetContractInversifyService',
        )
        return getContractInversifyService.getTokenFromContract('CVIUSDCThetaVault')
      })
      bind('TokenPriceApiInversifyService').to(TokenPriceApiInversifyService)
    }

    if (TV_SUPPORTED_CHAIN_IDS.some(c => c === chainId)) {
      bind('StakingInversifyService').to(StakingInversifyService)
      bind('ThetaVaultInversifyService').to(ThetaVaultInversifyService)
      bind('FormatVtContractsEventsInversifyService').to(FormatVtContractsEventsInversifyService)
      bind('FormatTvContractsEventsInversifyService').to(FormatTvContractsEventsInversifyService)
      bind('CviCacheEventsApiInversifyService').to(CviCacheEventsApiInversifyService)
      bind('TvContractsEventsInversifyService').to(TvContractsEventsInversifyService)
      bind('CviContractsInversifyService').to(CviContractsInversifyService)
      bind('FormatStakingContractsEventsInversifyService').to(FormatStakingContractsEventsInversifyService)
      bind('StakingContractsEventsInversifyService').to(StakingContractsEventsInversifyService)
      bind('VtInversifyService').to(VtInversifyService)
      bind('VtReBaserInversifyService').to(VtReBaserInversifyService)
      bind('VtContractsEventsInversifyService').to(VtContractsEventsInversifyService)
      bind('CVIUSDCVolatilityToken').toDynamicValue(async ({ container }) => {
        const getContractInversifyService = await container.getAsync<GetContractInversifyService>(
          'GetContractInversifyService',
        )
        return getContractInversifyService.getTokenFromContract('CVIUSDCVolatilityToken')
      })
      bind('CVIUSDCShortToken').toDynamicValue(async ({ container }) => {
        const getContractInversifyService = await container.getAsync<GetContractInversifyService>(
          'GetContractInversifyService',
        )
        return getContractInversifyService.getTokenFromContract('CVIUSDCPlatform')
      })
      bind('cviUsdcPairContractAndLpToken').toDynamicValue(async ({ container }): Promise<ConnectedPair> => {
        const tokenCvi = await container.getAsync<Token<IERC20, TokenName.USDC>>('CVIUSDCVolatilityToken')
        const tokenUSDC = await container.getAsync<Token<IERC20, TokenName.CVI>>('TokenUSDC')
        const uniswapInversifyService = await container.getAsync<UniswapInversifyService>('UniswapInversifyService')
        const pair = await uniswapInversifyService.getPair(tokenUSDC, tokenCvi)

        if (!pair.isConnected()) {
          throw new Error(`${createCviModule.name} - pair is not connected`)
        }

        return pair
      })
    }
  })
}
