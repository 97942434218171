/* eslint-disable @typescript-eslint/no-explicit-any */

import { Container } from 'inversify'
import {
  createCviModule,
  inversifyBuilder,
  ChainId,
  GmxContractsEventsInversifyService,
  FormatGmxContractsEventsInversifyService,
  GmxContractsInversifyService,
  isIlSupportedChainId,
} from '@coti-cvi/lw-sdk'
import type {
  ThetaVaultInversifyService,
  StakingInversifyService,
  VtInversifyService,
  CVIOracleInversifyService,
  GetContractInversifyService,
  IERC20,
  ILProtectionInversifyService,
  CVISupportedChainIds,
  Token,
  TokenName,
  SignerInversifyService,
  ProviderInversifyService,
  CviOldPlatformContractsEventsInversifyService,
  Contracts,
  UntypedToken,
  EmbedArmadilloDiscountInversifyService,
  EmbedHardhatImpersonationInversifyService,
  HardhatCommandsInversifyService,
  HardhatAdvanceTimeInversifyService,
  IlSupportedChainIds,
  TvSupportedChainIds,
  VestingSupportedChainIds,
  ILContractsEventsInversifyService,
  IlContractsInversifyService,
  ILAdminApiInversifyService,
  ETHPriceInversifyService,
  VtContractsEventsInversifyService,
  TvContractsEventsInversifyService,
  VtReBaserInversifyService,
  RewardRouterInversifyService,
  VestingInversifyService,
  RewardRouterContractsEventsInversifyService,
  VestingContractsEventsInversifyService,
  UniswapInversifyService,
  GenericContractInteractionInversifyService,
  OverridesInversifyService,
  TokenPriceApiInversifyService,
  ZkSupportedChainIds,
  ZKSyncNFTInversifyService,
} from '@coti-cvi/lw-sdk'
import {
  createContractsModule,
  GlobalEventsInversifyService,
  LatestBlockInfoInversifyService,
  GasPriceInversifyService,
  createIlFrontendsModule,
  createCommonModule,
} from '@coti-cvi/lw-sdk'
import type { JsonRpcProvider } from '@ethersproject/providers'
import type { Signer } from 'ethers'

export interface GetAsyncOverloads {
  (token: 'GlobalEventsInversifyService'): Promise<GlobalEventsInversifyService>
  (token: 'ChainId'): Promise<CVISupportedChainIds>
  (token: 'SingleDeploymentsFile'): Promise<Contracts>
  (token: 'EthersJsonRpcBatchProvider'): Promise<JsonRpcProvider>
  (token: 'GetContractInversifyService'): Promise<GetContractInversifyService>
  (token: 'LatestBlockInfoInversifyService'): Promise<LatestBlockInfoInversifyService>
  (token: 'Signer'): Promise<Signer>
  (token: 'SignerInversifyService'): Promise<SignerInversifyService>
  (token: 'ProviderInversifyService'): Promise<ProviderInversifyService>
  (token: 'GasPriceInversifyService'): Promise<GasPriceInversifyService>
  (token: 'OverridesInversifyService'): Promise<OverridesInversifyService>
  (token: 'HardhatCommandsInversifyService'): Promise<HardhatCommandsInversifyService>
  (token: 'HardhatAdvanceTimeInversifyService'): Promise<HardhatAdvanceTimeInversifyService>
  (token: 'UniswapInversifyService'): Promise<UniswapInversifyService>
  (token: 'GenericContractInteractionInversifyService'): Promise<GenericContractInteractionInversifyService>
  (token: 'TokenPriceApiInversifyService'): Promise<TokenPriceApiInversifyService>
}

export interface GetByBlockchainOverloads {
  (chainId: CVISupportedChainIds, token: 'CVIOracleInversifyService'): Promise<CVIOracleInversifyService>
  (
    chainId: IlSupportedChainIds,
    token: 'EmbedArmadilloDiscountInversifyService',
  ): Promise<EmbedArmadilloDiscountInversifyService>
  (
    chainId: IlSupportedChainIds,
    token: 'EmbedHardhatImpersonationInversifyService',
  ): Promise<EmbedHardhatImpersonationInversifyService>
  (chainId: IlSupportedChainIds, token: 'IlContractsInversifyService'): Promise<IlContractsInversifyService>
  (chainId: IlSupportedChainIds, token: 'ILAdminApiInversifyService'): Promise<ILAdminApiInversifyService>
  (chainId: IlSupportedChainIds, token: 'ILContractsEventsInversifyService'): Promise<ILContractsEventsInversifyService>
  (chainId: IlSupportedChainIds, token: 'ILProtectionInversifyService'): Promise<ILProtectionInversifyService>
  (chainId: CVISupportedChainIds, token: 'TokenUSDC'): Promise<Token<IERC20, TokenName.USDC>>
  (chainId: TvSupportedChainIds, token: 'ILProtectionInversifyService'): Promise<ILProtectionInversifyService>
  (chainId: TvSupportedChainIds | VestingSupportedChainIds, token: 'TokenGOVI'): Promise<Token<IERC20, TokenName.GOVI>>
  (chainId: TvSupportedChainIds, token: 'VtContractsEventsInversifyService'): Promise<VtContractsEventsInversifyService>
  (chainId: TvSupportedChainIds, token: 'ThetaVaultInversifyService'): Promise<ThetaVaultInversifyService>
  (chainId: TvSupportedChainIds, token: 'VtInversifyService'): Promise<VtInversifyService>
  (chainId: TvSupportedChainIds, token: 'VtReBaserInversifyService'): Promise<VtReBaserInversifyService>
  (chainId: TvSupportedChainIds | VestingSupportedChainIds, token: 'CVIUSDCThetaToken'): Promise<UntypedToken>
  (chainId: TvSupportedChainIds, token: 'TvContractsEventsInversifyService'): Promise<TvContractsEventsInversifyService>
  (chainId: TvSupportedChainIds, token: 'CVIUSDCVolatilityToken'): Promise<UntypedToken>
  (chainId: TvSupportedChainIds, token: 'CVIUSDCShortToken'): Promise<UntypedToken>
  (chainId: TvSupportedChainIds, token: 'StakingInversifyService'): Promise<StakingInversifyService>
  (chainId: VestingSupportedChainIds, token: 'RewardRouterInversifyService'): Promise<RewardRouterInversifyService>
  (chainId: VestingSupportedChainIds, token: 'VestingInversifyService'): Promise<VestingInversifyService>
  (
    chainId: VestingSupportedChainIds,
    token: 'RewardRouterContractsEventsInversifyService',
  ): Promise<RewardRouterContractsEventsInversifyService>
  (
    chainId: VestingSupportedChainIds,
    token: 'VestingContractsEventsInversifyService',
  ): Promise<VestingContractsEventsInversifyService>
  (chainId: VestingSupportedChainIds, token: 'TokenEsGOVI'): Promise<UntypedToken>
  (
    chainId: CVISupportedChainIds,
    token: 'CviOldPlatformContractsEventsInversifyService',
  ): Promise<CviOldPlatformContractsEventsInversifyService>
  (chainId: IlSupportedChainIds, token: 'ETHPriceInversifyService'): Promise<ETHPriceInversifyService>
  (
    chainId: TvSupportedChainIds,
    token: 'GmxContractsEventsInversifyService',
  ): Promise<GmxContractsEventsInversifyService>
  (chainId: ZkSupportedChainIds, token: 'ZKSyncNFTInversifyService'): Promise<ZKSyncNFTInversifyService>
}

export type InversifyContainer = {
  getAsync: GetAsyncOverloads
  getByBlockchain: GetByBlockchainOverloads
  closeContainer: () => void
}

export function initInversify(
  options: { chainId: ChainId } & (
    | {
        privateKey: string
      }
    | { impersonatedPublicWalletAddress: string }
    | { signer: Signer }
    | {}
  ),
): InversifyContainer {
  const container = new Container({ defaultScope: 'Singleton' })

  container.bind('ChainId').toConstantValue(options.chainId)

  container.load(createCommonModule(options))
  container.load(createContractsModule(options))

  container.bind('LatestBlockInfoInversifyService').to(LatestBlockInfoInversifyService)
  container.bind('GlobalEventsInversifyService').to(GlobalEventsInversifyService)
  container.bind('GasPriceInversifyService').to(GasPriceInversifyService)

  const globalEventsInversifyService = container.get<GlobalEventsInversifyService>('GlobalEventsInversifyService')

  if (isIlSupportedChainId(options.chainId)) {
    container.load(createIlFrontendsModule({ chainId: options.chainId }))
  }

  container.load(createCviModule(options.chainId))

  if ([ChainId.ArbitrumMainnet, ChainId.ArbitrumStaging, ChainId.ArbitrumLocal].includes(options.chainId)) {
    container.bind('GmxContractsEventsInversifyService').to(GmxContractsEventsInversifyService)
    container.bind('FormatGmxContractsEventsInversifyService').to(FormatGmxContractsEventsInversifyService)
    container.bind('GmxContractsInversifyService').to(GmxContractsInversifyService)
  }

  return inversifyBuilder<ChainId>(container, globalEventsInversifyService)
}
