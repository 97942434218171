import type { Mutability } from '@coti-cvi/lw-sdk'
import { toNumber } from '@coti-cvi/lw-sdk'
import type { FunctionFragment, ParamType } from '@ethersproject/abi'
import type { BigNumber, Contract, ContractTransaction } from 'ethers'
import type { InverifyContext } from '../context/inversify-context'
import type { MenuItem } from '../types'
import type { Wrappers } from './wrappers'

const shouldAutoRead = true

export class ContractInteraction {
  loadedContracts: Record<string, Contract> = {}

  workingContract: Contract | undefined = undefined

  public readonly ContractInteractionMenu: { [key: string]: MenuItem } = {
    t: { description: 'test', action: () => this.test() },
    u: { description: 'use contract', action: () => this.use() },
    l: { description: 'load contract', action: () => this.load() },
    r: { description: `read functions`, action: () => this.read() },
    w: { description: `write functions`, action: () => this.write() },
    c: { description: `call static functions`, action: () => this.static() },
    e: { description: `estimate gas`, action: () => this.estimate() },
  }

  constructor(private readonly inverifyContext: Required<InverifyContext>, private readonly wrappers: Wrappers) {
    const contracts = this.inverifyContext.genericContractInteractionInversifyService.getContracts()
    contracts.sort(([n1], [n2]) => n1.localeCompare(n2))
    contracts.map(([name, contract]) => Object.assign(this.loadedContracts, { [name]: contract }))
    const contractName = localStorage.getItem('workingContract')
    if (contractName && contractName in this.loadedContracts) {
      this.workingContract = this.loadedContracts[contractName]
    }
  }

  public async test() {
    const functions = await this.inverifyContext.genericContractInteractionInversifyService.test()
    this.wrappers.writeOutput(`function results: ${functions.map(f => JSON.stringify(f)).join('\n')}`)
  }

  public async load() {
    const service = this.inverifyContext.genericContractInteractionInversifyService
    const name = await this.wrappers.question('enter name of a contract')
    const address = await this.wrappers.question('enter address of a contract')
    const abi = await this.wrappers.question('enter abi of a contract')
    this.workingContract = service.getContract(address, abi)
    Object.assign(this.loadedContracts, { [name]: this.workingContract })
  }

  public async use() {
    const printer = ([name, { address }]: [string, { address: string }]) => `${name} - ${address}`
    const [name, contract] = await this.wrappers.selectItem('contract', Object.entries(this.loadedContracts), printer)
    localStorage.setItem('workingContract', name)
    return (this.workingContract = contract)
  }

  private async selectFunction(...mutability: Mutability[]): Promise<{
    contract: Contract
    func: FunctionFragment
    params: (string | string[])[]
  }> {
    const service = this.inverifyContext.genericContractInteractionInversifyService
    const contract = this.workingContract || (await this.use())
    const functions = service.getFunctions(contract.interface, ...mutability)
    const [name, func] = await this.wrappers.selectItem(
      `function [${contract.address}]`,
      functions,
      async ([n, f]) =>
        `${n} => (${f.outputs?.map(o => o.baseType).join(',')})${
          shouldAutoRead && ['view', 'pure'].includes(f.stateMutability) && f.inputs.length === 0
            ? ` = ${await contract.functions[f.name]()}`
            : ''
        }`,
    )
    this.wrappers.writeOutput(`selected function: ${name}`)
    const params = await this.inputParams(func)
    this.wrappers.writeOutput(`params: ${JSON.stringify(params)}`)
    return { contract, func, params }
  }

  public async read() {
    const { contract, func, params } = await this.selectFunction('view', 'pure')
    const res = await contract.functions[func.name](...params)
    this.wrappers.writeOutput(`function output: ${this.outputParams(func, res)}`)
  }

  public async write() {
    const { signer } = this.inverifyContext.signerInversifyService
    const { contract, func, params } = await this.selectFunction('nonpayable')
    const tx: ContractTransaction = await contract.connect(signer).functions[func.name](...params)
    this.wrappers.writeOutput(`function tx hash: ${tx.hash}`)
  }

  public async static() {
    const { signer } = this.inverifyContext.signerInversifyService
    const { contract, func, params } = await this.selectFunction('nonpayable')
    const res = await contract.connect(signer).callStatic[func.name](...params)
    this.wrappers.writeOutput(`function output: ${this.outputParams(func, res)}`)
  }

  public async estimate() {
    const { signer } = this.inverifyContext.signerInversifyService
    const { contract, func, params } = await this.selectFunction('nonpayable')
    const gasCostPromise = contract.connect(signer).estimateGas[func.name](...params)
    const { gasCost, total } = await this.inverifyContext.overridesInversifyService.getGasOfTransaction(gasCostPromise)
    this.wrappers.writeOutput(`function gas estimate: gas cost ${gasCost.toString()}, total gas ${toNumber(total, 18)}`)
  }

  private async inputParams(func: FunctionFragment) {
    const params: (string | string[])[] = []
    for await (const [i, f] of func.inputs.entries()) {
      const res = await this.input(f, i + 1, func.inputs.length)
      const input = f.baseType === 'array' ? res.split(',') : res
      params.push(input)
    }
    return params
  }

  private async input(paramType: ParamType, index: number, length: number): Promise<string> {
    if (paramType.baseType === 'address') {
      const contractAccounts = Object.entries(this.loadedContracts).map(([name, c]) => ({ name, address: c.address }))
      return this.wrappers.selectAccount(`${paramType.name}`, contractAccounts)
    } else if (paramType.baseType === 'uint256' || paramType.baseType === 'uint168') {
      const amount = await this.wrappers.selectAmount(`[${index}/${length}]`)
      return amount.toString()
    }
    return this.wrappers.question(`[${index}/${length}] ${paramType.format('sighash')}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private outputParams(func: FunctionFragment, res: any[]) {
    return func.outputs?.map((p, i) => this.output(p, res[i]))
  }

  private parseInt({ baseType }: ParamType, num: BigNumber): string {
    const size = baseType.includes('uint') ? parseInt(baseType.slice(4)) : parseInt(baseType.slice(3))
    return `${num.toString()}${!isNaN(size) && size > 32 ? ` - [18:${toNumber(num, 18)}] [6:${toNumber(num, 6)}]` : ''}`
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private output(paramType: ParamType, res: any): string {
    const name = paramType.name === null ? '' : `${paramType.name} `
    const str = paramType.baseType.includes('int') ? this.parseInt(paramType, res) : JSON.stringify(res)
    return `${name}${str}`
  }
}
