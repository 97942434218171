// curl https://min-api.cryptocompare.com/data/all/coinlist
// fs.writeFileSync("./b.json",JSON.stringify(Object.fromEntries(Object.entries(j.Data).map(en=>[en[0],{Name:en[1].Name,Symbol:en[1].Symbol,CoinName:en[1].CoinName,FullName:en[1].FullName}])),null,2),'utf-8')

export const CRYPTO_COMPARE_TOKENS = {
  '42': {
    Name: '42',
    Symbol: '42',
    CoinName: '42 Coin',
    FullName: '42 Coin (42)',
  },
  '300': {
    Name: '300',
    Symbol: '300',
    CoinName: '300 token',
    FullName: '300 token (300)',
  },
  '365': {
    Name: '365',
    Symbol: '365',
    CoinName: '365Coin',
    FullName: '365Coin (365)',
  },
  '404': {
    Name: '404',
    Symbol: '404',
    CoinName: '404Coin',
    FullName: '404Coin (404)',
  },
  '433': {
    Name: '433',
    Symbol: '433',
    CoinName: '433 Token',
    FullName: '433 Token (433)',
  },
  '611': {
    Name: '611',
    Symbol: '611',
    CoinName: 'SixEleven',
    FullName: 'SixEleven (611)',
  },
  '808': {
    Name: '808',
    Symbol: '808',
    CoinName: '808',
    FullName: '808 (808)',
  },
  '888': {
    Name: '888',
    Symbol: '888',
    CoinName: 'Octocoin',
    FullName: 'Octocoin (888)',
  },
  '1337': {
    Name: '1337',
    Symbol: '1337',
    CoinName: 'EliteCoin',
    FullName: 'EliteCoin (1337)',
  },
  '1717': {
    Name: '1717',
    Symbol: '1717',
    CoinName: '1717 Masonic Commemorative Token',
    FullName: '1717 Masonic Commemorative Token (1717)',
  },
  '2015': {
    Name: '2015',
    Symbol: '2015',
    CoinName: '2015 coin',
    FullName: '2015 coin (2015)',
  },
  BTCD: {
    Name: 'BTCD',
    Symbol: 'BTCD',
    CoinName: 'BitcoinDark',
    FullName: 'BitcoinDark (BTCD)',
  },
  CRAIG: {
    Name: 'CRAIG',
    Symbol: 'CRAIG',
    CoinName: 'CraigsCoin',
    FullName: 'CraigsCoin (CRAIG)',
  },
  XBS: {
    Name: 'XBS',
    Symbol: 'XBS',
    CoinName: 'Bitstake',
    FullName: 'Bitstake (XBS)',
  },
  XPY: {
    Name: 'XPY',
    Symbol: 'XPY',
    CoinName: 'PayCoin',
    FullName: 'PayCoin (XPY)',
  },
  PRC: {
    Name: 'PRC',
    Symbol: 'PRC',
    CoinName: 'ProsperCoin',
    FullName: 'ProsperCoin (PRC)',
  },
  YBC: {
    Name: 'YBC',
    Symbol: 'YBC',
    CoinName: 'YbCoin',
    FullName: 'YbCoin (YBC)',
  },
  DANK: {
    Name: 'DANK',
    Symbol: 'DANK',
    CoinName: 'DarkKush',
    FullName: 'DarkKush (DANK)',
  },
  GIVE: {
    Name: 'GIVE',
    Symbol: 'GIVE',
    CoinName: 'GiveCoin',
    FullName: 'GiveCoin (GIVE)',
  },
  KOBO: {
    Name: 'KOBO',
    Symbol: 'KOBO',
    CoinName: 'KoboCoin',
    FullName: 'KoboCoin (KOBO)',
  },
  DT: {
    Name: 'DT',
    Symbol: 'DT',
    CoinName: 'DarkToken',
    FullName: 'DarkToken (DT)',
  },
  CETI: {
    Name: 'CETI',
    Symbol: 'CETI',
    CoinName: 'CETUS Coin',
    FullName: 'CETUS Coin (CETI)',
  },
  SUP: {
    Name: 'SUP',
    Symbol: 'SUP',
    CoinName: 'Supcoin',
    FullName: 'Supcoin (SUP)',
  },
  XPD: {
    Name: 'XPD',
    Symbol: 'XPD',
    CoinName: 'PetroDollar',
    FullName: 'PetroDollar (XPD)',
  },
  GEO: {
    Name: 'GEO',
    Symbol: 'GEO',
    CoinName: 'GeoCoin',
    FullName: 'GeoCoin (GEO)',
  },
  CHASH: {
    Name: 'CHASH',
    Symbol: 'CHASH',
    CoinName: 'CleverHash',
    FullName: 'CleverHash (CHASH)',
  },
  NXTI: {
    Name: 'NXTI',
    Symbol: 'NXTI',
    CoinName: 'NXTI',
    FullName: 'NXTI (NXTI)',
  },
  WOLF: {
    Name: 'WOLF',
    Symbol: 'WOLF',
    CoinName: 'Insanity Coin',
    FullName: 'Insanity Coin (WOLF)',
  },
  XDP: {
    Name: 'XDP',
    Symbol: 'XDP',
    CoinName: 'DogeParty',
    FullName: 'DogeParty (XDP)',
  },
  AC: {
    Name: 'AC',
    Symbol: 'AC',
    CoinName: 'Asia Coin',
    FullName: 'Asia Coin (AC)',
  },
  ACOIN: {
    Name: 'ACOIN',
    Symbol: 'ACOIN',
    CoinName: 'ACoin',
    FullName: 'ACoin (ACOIN)',
  },
  AERO: {
    Name: 'AERO',
    Symbol: 'AERO',
    CoinName: 'Aero Coin',
    FullName: 'Aero Coin (AERO)',
  },
  ALF: {
    Name: 'ALF',
    Symbol: 'ALF',
    CoinName: 'AlphaCoin',
    FullName: 'AlphaCoin (ALF)',
  },
  AEGIS: {
    Name: 'AEGIS',
    Symbol: 'AEGIS',
    CoinName: 'Aegis',
    FullName: 'Aegis (AEGIS)',
  },
  AMERICANCOIN: {
    Name: 'AMERICANCOIN',
    Symbol: 'AMERICANCOIN',
    CoinName: 'AmericanCoin',
    FullName: 'AmericanCoin (AMERICANCOIN)',
  },
  ALIEN: {
    Name: 'ALIEN',
    Symbol: 'ALIEN',
    CoinName: 'AlienCoin',
    FullName: 'AlienCoin (ALIEN)',
  },
  ARCH: {
    Name: 'ARCH',
    Symbol: 'ARCH',
    CoinName: 'ArchCoin',
    FullName: 'ArchCoin (ARCH)',
  },
  AURORAC: {
    Name: 'AURORAC',
    Symbol: 'AURORAC',
    CoinName: 'Auroracoin',
    FullName: 'Auroracoin (AURORAC)',
  },
  AXR: {
    Name: 'AXR',
    Symbol: 'AXR',
    CoinName: 'AXRON',
    FullName: 'AXRON (AXR)',
  },
  BETACOIN: {
    Name: 'BETACOIN',
    Symbol: 'BETACOIN',
    CoinName: 'BetaCoin',
    FullName: 'BetaCoin (BETACOIN)',
  },
  BEAN: {
    Name: 'BEAN',
    Symbol: 'BEAN',
    CoinName: 'BeanCash',
    FullName: 'BeanCash (BEAN)',
  },
  BLU: {
    Name: 'BLU',
    Symbol: 'BLU',
    CoinName: 'BlueCoin',
    FullName: 'BlueCoin (BLU)',
  },
  BOST: {
    Name: 'BOST',
    Symbol: 'BOST',
    CoinName: 'BoostCoin',
    FullName: 'BoostCoin (BOST)',
  },
  BQC: {
    Name: 'BQC',
    Symbol: 'BQC',
    CoinName: 'BQCoin',
    FullName: 'BQCoin (BQC)',
  },
  MOONC: {
    Name: 'MOONC',
    Symbol: 'MOONC',
    CoinName: 'MoonCoin',
    FullName: 'MoonCoin (MOONC)',
  },
  ZET: {
    Name: 'ZET',
    Symbol: 'ZET',
    CoinName: 'ZetaCoin',
    FullName: 'ZetaCoin (ZET)',
  },
  SXC: {
    Name: 'SXC',
    Symbol: 'SXC',
    CoinName: 'SexCoin',
    FullName: 'SexCoin (SXC)',
  },
  QTL: {
    Name: 'QTL',
    Symbol: 'QTL',
    CoinName: 'Quatloo',
    FullName: 'Quatloo (QTL)',
  },
  ENRG: {
    Name: 'ENRG',
    Symbol: 'ENRG',
    CoinName: 'EnergyCoin',
    FullName: 'EnergyCoin (ENRG)',
  },
  QRK: {
    Name: 'QRK',
    Symbol: 'QRK',
    CoinName: 'QuarkCoin',
    FullName: 'QuarkCoin (QRK)',
  },
  RIC: {
    Name: 'RIC',
    Symbol: 'RIC',
    CoinName: 'Riecoin',
    FullName: 'Riecoin (RIC)',
  },
  DGC: {
    Name: 'DGC',
    Symbol: 'DGC',
    CoinName: 'Digitalcoin',
    FullName: 'Digitalcoin (DGC)',
  },
  LIMX: {
    Name: 'LIMX',
    Symbol: 'LIMX',
    CoinName: 'LimeCoinX',
    FullName: 'LimeCoinX (LIMX)',
  },
  BTB: {
    Name: 'BTB',
    Symbol: 'BTB',
    CoinName: 'BitBar',
    FullName: 'BitBar (BTB)',
  },
  CAIX: {
    Name: 'CAIx',
    Symbol: 'CAIX',
    CoinName: 'CAIx',
    FullName: 'CAIx (CAIx)',
  },
  BTMK: {
    Name: 'BTMK',
    Symbol: 'BTMK',
    CoinName: 'BitMark',
    FullName: 'BitMark (BTMK)',
  },
  BUK: {
    Name: 'BUK',
    Symbol: 'BUK',
    CoinName: 'CryptoBuk',
    FullName: 'CryptoBuk (BUK)',
  },
  CACH: {
    Name: 'CACH',
    Symbol: 'CACH',
    CoinName: 'Cachecoin',
    FullName: 'Cachecoin (CACH)',
  },
  CANN: {
    Name: 'CANN',
    Symbol: 'CANN',
    CoinName: 'CannabisCoin',
    FullName: 'CannabisCoin (CANN)',
  },
  CAP: {
    Name: 'CAP',
    Symbol: 'CAP',
    CoinName: 'BottleCaps',
    FullName: 'BottleCaps (CAP)',
  },
  CASH: {
    Name: 'CASH',
    Symbol: 'CASH',
    CoinName: 'CashCoin',
    FullName: 'CashCoin (CASH)',
  },
  CATC: {
    Name: 'CATC',
    Symbol: 'CATC',
    CoinName: 'Catcoin',
    FullName: 'Catcoin (CATC)',
  },
  CCN: {
    Name: 'CCN',
    Symbol: 'CCN',
    CoinName: 'CannaCoin',
    FullName: 'CannaCoin (CCN)',
  },
  CINNI: {
    Name: 'CINNI',
    Symbol: 'CINNI',
    CoinName: 'CINNICOIN',
    FullName: 'CINNICOIN (CINNI)',
  },
  CXC: {
    Name: 'CXC',
    Symbol: 'CXC',
    CoinName: 'CheckCoin',
    FullName: 'CheckCoin (CXC)',
  },
  CLAM: {
    Name: 'CLAM',
    Symbol: 'CLAM',
    CoinName: 'CLAMS',
    FullName: 'CLAMS (CLAM)',
  },
  CLR: {
    Name: 'CLR',
    Symbol: 'CLR',
    CoinName: 'CopperLark',
    FullName: 'CopperLark (CLR)',
  },
  CMC: {
    Name: 'CMC',
    Symbol: 'CMC',
    CoinName: 'CosmosCoin',
    FullName: 'CosmosCoin (CMC)',
  },
  CNC: {
    Name: 'CNC',
    Symbol: 'CNC',
    CoinName: 'ChinaCoin',
    FullName: 'ChinaCoin (CNC)',
  },
  CNL: {
    Name: 'CNL',
    Symbol: 'CNL',
    CoinName: 'ConcealCoin',
    FullName: 'ConcealCoin (CNL)',
  },
  COMM: {
    Name: 'COMM',
    Symbol: 'COMM',
    CoinName: 'Community Coin',
    FullName: 'Community Coin (COMM)',
  },
  COOL: {
    Name: 'COOL',
    Symbol: 'COOL',
    CoinName: 'CoolCoin',
    FullName: 'CoolCoin (COOL)',
  },
  CRACK: {
    Name: 'CRACK',
    Symbol: 'CRACK',
    CoinName: 'CrackCoin',
    FullName: 'CrackCoin (CRACK)',
  },
  CRYPT: {
    Name: 'CRYPT',
    Symbol: 'CRYPT',
    CoinName: 'CryptCoin',
    FullName: 'CryptCoin (CRYPT)',
  },
  CSC: {
    Name: 'CSC',
    Symbol: 'CSC',
    CoinName: 'CasinoCoin',
    FullName: 'CasinoCoin (CSC)',
  },
  DEM: {
    Name: 'DEM',
    Symbol: 'DEM',
    CoinName: 'eMark',
    FullName: 'eMark (DEM)',
  },
  DIAM: {
    Name: 'DIAM',
    Symbol: 'DIAM',
    CoinName: 'Diamond',
    FullName: 'Diamond (DIAM)',
  },
  DRKC: {
    Name: 'DRKC',
    Symbol: 'DRKC',
    CoinName: 'DarkCash',
    FullName: 'DarkCash (DRKC)',
  },
  DSB: {
    Name: 'DSB',
    Symbol: 'DSB',
    CoinName: 'DarkShibe',
    FullName: 'DarkShibe (DSB)',
  },
  DEVCOIN: {
    Name: 'DEVCOIN',
    Symbol: 'DEVCOIN',
    CoinName: 'DevCoin',
    FullName: 'DevCoin (DEVCOIN)',
  },
  EFL: {
    Name: 'EFL',
    Symbol: 'EFL',
    CoinName: 'E-Gulden',
    FullName: 'E-Gulden (EFL)',
  },
  ELC: {
    Name: 'ELC',
    Symbol: 'ELC',
    CoinName: 'Elacoin',
    FullName: 'Elacoin (ELC)',
  },
  EMD: {
    Name: 'EMD',
    Symbol: 'EMD',
    CoinName: 'Emerald',
    FullName: 'Emerald (EMD)',
  },
  EXCL: {
    Name: 'EXCL',
    Symbol: 'EXCL',
    CoinName: 'Exclusive Coin',
    FullName: 'Exclusive Coin (EXCL)',
  },
  EXE: {
    Name: 'EXE',
    Symbol: 'EXE',
    CoinName: 'ExeCoin',
    FullName: 'ExeCoin (EXE)',
  },
  EZC: {
    Name: 'EZC',
    Symbol: 'EZC',
    CoinName: 'EZCoin',
    FullName: 'EZCoin (EZC)',
  },
  FLAP: {
    Name: 'FLAP',
    Symbol: 'FLAP',
    CoinName: 'Flappy Coin',
    FullName: 'Flappy Coin (FLAP)',
  },
  FC2: {
    Name: 'FC2',
    Symbol: 'FC2',
    CoinName: 'Fuel2Coin',
    FullName: 'Fuel2Coin (FC2)',
  },
  FFC: {
    Name: 'FFC',
    Symbol: 'FFC',
    CoinName: 'FireflyCoin',
    FullName: 'FireflyCoin (FFC)',
  },
  FIBRE: {
    Name: 'FIBRE',
    Symbol: 'FIBRE',
    CoinName: 'FIBRE',
    FullName: 'FIBRE (FIBRE)',
  },
  FRC: {
    Name: 'FRC',
    Symbol: 'FRC',
    CoinName: 'FireRoosterCoin',
    FullName: 'FireRoosterCoin (FRC)',
  },
  FLT: {
    Name: 'FLT',
    Symbol: 'FLT',
    CoinName: 'FlutterCoin',
    FullName: 'FlutterCoin (FLT)',
  },
  FRK: {
    Name: 'FRK',
    Symbol: 'FRK',
    CoinName: 'Franko',
    FullName: 'Franko (FRK)',
  },
  FRAC: {
    Name: 'FRAC',
    Symbol: 'FRAC',
    CoinName: 'FractalCoin',
    FullName: 'FractalCoin (FRAC)',
  },
  FSTC: {
    Name: 'FSTC',
    Symbol: 'FSTC',
    CoinName: 'FastCoin',
    FullName: 'FastCoin (FSTC)',
  },
  GLOBAL: {
    Name: 'GLOBAL',
    Symbol: 'GLOBAL',
    CoinName: 'GlobalCoin',
    FullName: 'GlobalCoin (GLOBAL)',
  },
  GLX: {
    Name: 'GLX',
    Symbol: 'GLX',
    CoinName: 'GalaxyCoin',
    FullName: 'GalaxyCoin (GLX)',
  },
  GLYPH: {
    Name: 'GLYPH',
    Symbol: 'GLYPH',
    CoinName: 'GlyphCoin',
    FullName: 'GlyphCoin (GLYPH)',
  },
  GML: {
    Name: 'GML',
    Symbol: 'GML',
    CoinName: 'GameLeagueCoin',
    FullName: 'GameLeagueCoin (GML)',
  },
  GUE: {
    Name: 'GUE',
    Symbol: 'GUE',
    CoinName: 'GuerillaCoin',
    FullName: 'GuerillaCoin (GUE)',
  },
  HAL: {
    Name: 'HAL',
    Symbol: 'HAL',
    CoinName: 'Halcyon',
    FullName: 'Halcyon (HAL)',
  },
  HBN: {
    Name: 'HBN',
    Symbol: 'HBN',
    CoinName: 'HoboNickels',
    FullName: 'HoboNickels (HBN)',
  },
  HUC: {
    Name: 'HUC',
    Symbol: 'HUC',
    CoinName: 'HunterCoin',
    FullName: 'HunterCoin (HUC)',
  },
  HVC: {
    Name: 'HVC',
    Symbol: 'HVC',
    CoinName: 'HeavyCoin',
    FullName: 'HeavyCoin (HVC)',
  },
  HYP: {
    Name: 'HYP',
    Symbol: 'HYP',
    CoinName: 'HyperStake',
    FullName: 'HyperStake (HYP)',
  },
  ICB: {
    Name: 'ICB',
    Symbol: 'ICB',
    CoinName: 'IceBergCoin',
    FullName: 'IceBergCoin (ICB)',
  },
  IFC: {
    Name: 'IFC',
    Symbol: 'IFC',
    CoinName: 'Infinite Coin',
    FullName: 'Infinite Coin (IFC)',
  },
  IXC: {
    Name: 'IXC',
    Symbol: 'IXC',
    CoinName: 'IXcoin',
    FullName: 'IXcoin (IXC)',
  },
  JBS: {
    Name: 'JBS',
    Symbol: 'JBS',
    CoinName: 'JumBucks Coin',
    FullName: 'JumBucks Coin (JBS)',
  },
  JKC: {
    Name: 'JKC',
    Symbol: 'JKC',
    CoinName: 'JunkCoin',
    FullName: 'JunkCoin (JKC)',
  },
  JUDGE: {
    Name: 'JUDGE',
    Symbol: 'JUDGE',
    CoinName: 'JudgeCoin',
    FullName: 'JudgeCoin (JUDGE)',
  },
  KDC: {
    Name: 'KDC',
    Symbol: 'KDC',
    CoinName: 'Klondike Coin',
    FullName: 'Klondike Coin (KDC)',
  },
  KEYC: {
    Name: 'KEYC',
    Symbol: 'KEYC',
    CoinName: 'KeyCoin',
    FullName: 'KeyCoin (KEYC)',
  },
  LK7: {
    Name: 'LK7',
    Symbol: 'LK7',
    CoinName: 'Lucky7Coin',
    FullName: 'Lucky7Coin (LK7)',
  },
  LKY: {
    Name: 'LKY',
    Symbol: 'LKY',
    CoinName: 'LuckyCoin',
    FullName: 'LuckyCoin (LKY)',
  },
  LSD: {
    Name: 'LSD',
    Symbol: 'LSD',
    CoinName: 'LightSpeedCoin',
    FullName: 'LightSpeedCoin (LSD)',
  },
  LTB: {
    Name: 'LTB',
    Symbol: 'LTB',
    CoinName: 'Litebar',
    FullName: 'Litebar (LTB)',
  },
  LTCD: {
    Name: 'LTCD',
    Symbol: 'LTCD',
    CoinName: 'LitecoinDark',
    FullName: 'LitecoinDark (LTCD)',
  },
  LTCX: {
    Name: 'LTCX',
    Symbol: 'LTCX',
    CoinName: 'LitecoinX',
    FullName: 'LitecoinX (LTCX)',
  },
  LXC: {
    Name: 'LXC',
    Symbol: 'LXC',
    CoinName: 'LibrexCoin',
    FullName: 'LibrexCoin (LXC)',
  },
  LYC: {
    Name: 'LYC',
    Symbol: 'LYC',
    CoinName: 'LycanCoin',
    FullName: 'LycanCoin (LYC)',
  },
  MAX: {
    Name: 'MAX',
    Symbol: 'MAX',
    CoinName: 'MaxCoin',
    FullName: 'MaxCoin (MAX)',
  },
  MEC: {
    Name: 'MEC',
    Symbol: 'MEC',
    CoinName: 'MegaCoin',
    FullName: 'MegaCoin (MEC)',
  },
  MEDIT: {
    Name: 'MEDIT',
    Symbol: 'MEDIT',
    CoinName: 'MediterraneanCoin',
    FullName: 'MediterraneanCoin (MEDIT)',
  },
  MINRL: {
    Name: 'MINRL',
    Symbol: 'MINRL',
    CoinName: 'Minerals Coin',
    FullName: 'Minerals Coin (MINRL)',
  },
  MINTCOIN: {
    Name: 'MINTCOIN',
    Symbol: 'MINTCOIN',
    CoinName: 'MintCoin',
    FullName: 'MintCoin (MINTCOIN)',
  },
  MN: {
    Name: 'MN',
    Symbol: 'MN',
    CoinName: 'Cryptsy Mining Contract',
    FullName: 'Cryptsy Mining Contract (MN)',
  },
  MINC: {
    Name: 'MINC',
    Symbol: 'MINC',
    CoinName: 'MinCoin',
    FullName: 'MinCoin (MINC)',
  },
  MRY: {
    Name: 'MRY',
    Symbol: 'MRY',
    CoinName: 'MurrayCoin',
    FullName: 'MurrayCoin (MRY)',
  },
  MZC: {
    Name: 'MZC',
    Symbol: 'MZC',
    CoinName: 'MazaCoin',
    FullName: 'MazaCoin (MZC)',
  },
  NAN: {
    Name: 'NAN',
    Symbol: 'NAN',
    CoinName: 'NanoToken',
    FullName: 'NanoToken (NAN)',
  },
  NAUT: {
    Name: 'NAUT',
    Symbol: 'NAUT',
    CoinName: 'Nautilus Coin',
    FullName: 'Nautilus Coin (NAUT)',
  },
  NYBBLE: {
    Name: 'NYBBLE',
    Symbol: 'NYBBLE',
    CoinName: 'Nybble',
    FullName: 'Nybble (NYBBLE)',
  },
  NET: {
    Name: 'NET',
    Symbol: 'NET',
    CoinName: 'NetCoin',
    FullName: 'NetCoin (NET)',
  },
  NMB: {
    Name: 'NMB',
    Symbol: 'NMB',
    CoinName: 'Nimbus Coin',
    FullName: 'Nimbus Coin (NMB)',
  },
  NRB: {
    Name: 'NRB',
    Symbol: 'NRB',
    CoinName: 'NoirBits',
    FullName: 'NoirBits (NRB)',
  },
  NOBL: {
    Name: 'NOBL',
    Symbol: 'NOBL',
    CoinName: 'NobleCoin',
    FullName: 'NobleCoin (NOBL)',
  },
  NRS: {
    Name: 'NRS',
    Symbol: 'NRS',
    CoinName: 'NoirShares',
    FullName: 'NoirShares (NRS)',
  },
  ORBITCOIN: {
    Name: 'ORBITCOIN',
    Symbol: 'ORBITCOIN',
    CoinName: 'Orbitcoin',
    FullName: 'Orbitcoin (ORBITCOIN)',
  },
  OPSC: {
    Name: 'OPSC',
    Symbol: 'OPSC',
    CoinName: 'OpenSourceCoin',
    FullName: 'OpenSourceCoin (OPSC)',
  },
  PHS: {
    Name: 'PHS',
    Symbol: 'PHS',
    CoinName: 'PhilosophersStone',
    FullName: 'PhilosophersStone (PHS)',
  },
  POINTS: {
    Name: 'POINTS',
    Symbol: 'POINTS',
    CoinName: 'Cryptsy Points',
    FullName: 'Cryptsy Points (POINTS)',
  },
  PSEUD: {
    Name: 'PSEUD',
    Symbol: 'PSEUD',
    CoinName: 'PseudoCash',
    FullName: 'PseudoCash (PSEUD)',
  },
  PXC: {
    Name: 'PXC',
    Symbol: 'PXC',
    CoinName: 'PhoenixCoin',
    FullName: 'PhoenixCoin (PXC)',
  },
  PYC: {
    Name: 'PYC',
    Symbol: 'PYC',
    CoinName: 'PayCoin',
    FullName: 'PayCoin (PYC)',
  },
  RIPO: {
    Name: 'RIPO',
    Symbol: 'RIPO',
    CoinName: 'RipOffCoin',
    FullName: 'RipOffCoin (RIPO)',
  },
  RPC: {
    Name: 'RPC',
    Symbol: 'RPC',
    CoinName: 'RonPaulCoin',
    FullName: 'RonPaulCoin (RPC)',
  },
  RT2: {
    Name: 'RT2',
    Symbol: 'RT2',
    CoinName: 'RotoCoin',
    FullName: 'RotoCoin (RT2)',
  },
  RYC: {
    Name: 'RYC',
    Symbol: 'RYC',
    CoinName: 'RoyalCoin',
    FullName: 'RoyalCoin (RYC)',
  },
  RZR: {
    Name: 'RZR',
    Symbol: 'RZR',
    CoinName: 'RazorCoin',
    FullName: 'RazorCoin (RZR)',
  },
  SBC: {
    Name: 'SBC',
    Symbol: 'SBC',
    CoinName: 'StableCoin',
    FullName: 'StableCoin (SBC)',
  },
  SDC: {
    Name: 'SDC',
    Symbol: 'SDC',
    CoinName: 'ShadowCash',
    FullName: 'ShadowCash (SDC)',
  },
  SFR: {
    Name: 'SFR',
    Symbol: 'SFR',
    CoinName: 'SaffronCoin',
    FullName: 'SaffronCoin (SFR)',
  },
  SHADE: {
    Name: 'SHADE',
    Symbol: 'SHADE',
    CoinName: 'ShadeCoin',
    FullName: 'ShadeCoin (SHADE)',
  },
  SHLD: {
    Name: 'SHLD',
    Symbol: 'SHLD',
    CoinName: 'ShieldCoin',
    FullName: 'ShieldCoin (SHLD)',
  },
  SILK: {
    Name: 'SILK',
    Symbol: 'SILK',
    CoinName: 'SilkCoin',
    FullName: 'SilkCoin (SILK)',
  },
  SMC: {
    Name: 'SMC',
    Symbol: 'SMC',
    CoinName: 'SmartCoin',
    FullName: 'SmartCoin (SMC)',
  },
  SOLE: {
    Name: 'SOLE',
    Symbol: 'SOLE',
    CoinName: 'SoleCoin',
    FullName: 'SoleCoin (SOLE)',
  },
  SPAIN: {
    Name: 'SPAIN',
    Symbol: 'SPAIN',
    CoinName: 'SpainCoin',
    FullName: 'SpainCoin (SPAIN)',
  },
  SPOTS: {
    Name: 'SPOTS',
    Symbol: 'SPOTS',
    CoinName: 'Spots',
    FullName: 'Spots (SPOTS)',
  },
  SRC: {
    Name: 'SRC',
    Symbol: 'SRC',
    CoinName: 'SecureCoin',
    FullName: 'SecureCoin (SRC)',
  },
  SSVCOIN: {
    Name: 'SSVCOIN',
    Symbol: 'SSVCOIN',
    CoinName: 'SSVCoin',
    FullName: 'SSVCoin (SSVCOIN)',
  },
  SUPERC: {
    Name: 'SUPERC',
    Symbol: 'SUPERC',
    CoinName: 'SuperCoin',
    FullName: 'SuperCoin (SUPERC)',
  },
  SWIFT: {
    Name: 'SWIFT',
    Symbol: 'SWIFT',
    CoinName: 'BitSwift',
    FullName: 'BitSwift (SWIFT)',
  },
  SYNCC: {
    Name: 'SYNCC',
    Symbol: 'SYNCC',
    CoinName: 'SyncCoin',
    FullName: 'SyncCoin (SYNCC)',
  },
  TAK: {
    Name: 'TAK',
    Symbol: 'TAK',
    CoinName: 'TakCoin',
    FullName: 'TakCoin (TAK)',
  },
  TES: {
    Name: 'TES',
    Symbol: 'TES',
    CoinName: 'TeslaCoin',
    FullName: 'TeslaCoin (TES)',
  },
  TGC: {
    Name: 'TGC',
    Symbol: 'TGC',
    CoinName: 'TigerCoin',
    FullName: 'TigerCoin (TGC)',
  },
  TIT: {
    Name: 'TIT',
    Symbol: 'TIT',
    CoinName: 'TittieCoin',
    FullName: 'TittieCoin (TIT)',
  },
  TRC: {
    Name: 'TRC',
    Symbol: 'TRC',
    CoinName: 'TerraCoin',
    FullName: 'TerraCoin (TRC)',
  },
  TITC: {
    Name: 'TITC',
    Symbol: 'TITC',
    CoinName: 'TitCoin',
    FullName: 'TitCoin (TITC)',
  },
  ULTC: {
    Name: 'ULTC',
    Symbol: 'ULTC',
    CoinName: 'Umbrella',
    FullName: 'Umbrella (ULTC)',
  },
  UNBREAKABLE: {
    Name: 'UNBREAKABLE',
    Symbol: 'UNBREAKABLE',
    CoinName: 'UnbreakableCoin',
    FullName: 'UnbreakableCoin (UNBREAKABLE)',
  },
  UNO: {
    Name: 'UNO',
    Symbol: 'UNO',
    CoinName: 'Unobtanium',
    FullName: 'Unobtanium (UNO)',
  },
  URO: {
    Name: 'URO',
    Symbol: 'URO',
    CoinName: 'UroCoin',
    FullName: 'UroCoin (URO)',
  },
  USDE: {
    Name: 'USDE',
    Symbol: 'USDE',
    CoinName: 'UnitaryStatus Dollar',
    FullName: 'UnitaryStatus Dollar (USDE)',
  },
  UTC: {
    Name: 'UTC',
    Symbol: 'UTC',
    CoinName: 'UltraCoin',
    FullName: 'UltraCoin (UTC)',
  },
  UTIL: {
    Name: 'UTIL',
    Symbol: 'UTIL',
    CoinName: 'Utility Coin',
    FullName: 'Utility Coin (UTIL)',
  },
  VDO: {
    Name: 'VDO',
    Symbol: 'VDO',
    CoinName: 'VidioCoin',
    FullName: 'VidioCoin (VDO)',
  },
  VOOT: {
    Name: 'VOOT',
    Symbol: 'VOOT',
    CoinName: 'VootCoin',
    FullName: 'VootCoin (VOOT)',
  },
  VRC: {
    Name: 'VRC',
    Symbol: 'VRC',
    CoinName: 'VeriCoin',
    FullName: 'VeriCoin (VRC)',
  },
  WDC: {
    Name: 'WDC',
    Symbol: 'WDC',
    CoinName: 'WorldCoin',
    FullName: 'WorldCoin (WDC)',
  },
  XBOT: {
    Name: 'XBOT',
    Symbol: 'XBOT',
    CoinName: 'SocialXbotCoin',
    FullName: 'SocialXbotCoin (XBOT)',
  },
  XC: {
    Name: 'XC',
    Symbol: 'XC',
    CoinName: 'X11 Coin',
    FullName: 'X11 Coin (XC)',
  },
  XCR: {
    Name: 'XCR',
    Symbol: 'XCR',
    CoinName: 'Crypti',
    FullName: 'Crypti (XCR)',
  },
  XJO: {
    Name: 'XJO',
    Symbol: 'XJO',
    CoinName: 'JouleCoin',
    FullName: 'JouleCoin (XJO)',
  },
  XLB: {
    Name: 'XLB',
    Symbol: 'XLB',
    CoinName: 'LibertyCoin',
    FullName: 'LibertyCoin (XLB)',
  },
  XPM: {
    Name: 'XPM',
    Symbol: 'XPM',
    CoinName: 'PrimeCoin',
    FullName: 'PrimeCoin (XPM)',
  },
  XST: {
    Name: 'XST',
    Symbol: 'XST',
    CoinName: 'StealthCoin',
    FullName: 'StealthCoin (XST)',
  },
  XXX: {
    Name: 'XXX',
    Symbol: 'XXX',
    CoinName: 'XXXCoin',
    FullName: 'XXXCoin (XXX)',
  },
  YAC: {
    Name: 'YAC',
    Symbol: 'YAC',
    CoinName: 'YAcCoin',
    FullName: 'YAcCoin (YAC)',
  },
  ZCC: {
    Name: 'ZCC',
    Symbol: 'ZCC',
    CoinName: 'ZCC Coin',
    FullName: 'ZCC Coin (ZCC)',
  },
  ZED: {
    Name: 'ZED',
    Symbol: 'ZED',
    CoinName: 'ZedCoins',
    FullName: 'ZedCoins (ZED)',
  },
  EKN: {
    Name: 'EKN',
    Symbol: 'EKN',
    CoinName: 'Elektron',
    FullName: 'Elektron (EKN)',
  },
  XAU: {
    Name: 'XAU',
    Symbol: 'XAU',
    CoinName: 'XauCoin',
    FullName: 'XauCoin (XAU)',
  },
  SIGNA: {
    Name: 'SIGNA',
    Symbol: 'SIGNA',
    CoinName: 'Signa',
    FullName: 'Signa (SIGNA)',
  },
  USNBT: {
    Name: 'USNBT',
    Symbol: 'USNBT',
    CoinName: 'NuBits',
    FullName: 'NuBits (USNBT)',
  },
  SJCX: {
    Name: 'SJCX',
    Symbol: 'SJCX',
    CoinName: 'StorjCoin',
    FullName: 'StorjCoin (SJCX)',
  },
  START: {
    Name: 'START',
    Symbol: 'START',
    CoinName: 'StartCoin',
    FullName: 'StartCoin (START)',
  },
  HUGE: {
    Name: 'HUGE',
    Symbol: 'HUGE',
    CoinName: 'BigCoin',
    FullName: 'BigCoin (HUGE)',
  },
  MAID: {
    Name: 'MAID',
    Symbol: 'MAID',
    CoinName: 'MaidSafe Coin',
    FullName: 'MaidSafe Coin (MAID)',
  },
  '007': {
    Name: '007',
    Symbol: '007',
    CoinName: '007 coin',
    FullName: '007 coin (007)',
  },
  NSR: {
    Name: 'NSR',
    Symbol: 'NSR',
    CoinName: 'NuShares',
    FullName: 'NuShares (NSR)',
  },
  SOLARFARM: {
    Name: 'SOLARFARM',
    Symbol: 'SOLARFARM',
    CoinName: 'SolarFarm',
    FullName: 'SolarFarm (SOLARFARM)',
  },
  TEK: {
    Name: 'TEK',
    Symbol: 'TEK',
    CoinName: 'TekCoin',
    FullName: 'TekCoin (TEK)',
  },
  BAY: {
    Name: 'BAY',
    Symbol: 'BAY',
    CoinName: 'BitBay',
    FullName: 'BitBay (BAY)',
  },
  NTRN: {
    Name: 'NTRN',
    Symbol: 'NTRN',
    CoinName: 'Neutron',
    FullName: 'Neutron (NTRN)',
  },
  SLING: {
    Name: 'SLING',
    Symbol: 'SLING',
    CoinName: 'Sling Coin',
    FullName: 'Sling Coin (SLING)',
  },
  XVC: {
    Name: 'XVC',
    Symbol: 'XVC',
    CoinName: 'Vcash',
    FullName: 'Vcash (XVC)',
  },
  CRAVE: {
    Name: 'CRAVE',
    Symbol: 'CRAVE',
    CoinName: 'CraveCoin',
    FullName: 'CraveCoin (CRAVE)',
  },
  XSI: {
    Name: 'XSI',
    Symbol: 'XSI',
    CoinName: 'Stability Shares',
    FullName: 'Stability Shares (XSI)',
  },
  BYC: {
    Name: 'BYC',
    Symbol: 'BYC',
    CoinName: 'ByteCent',
    FullName: 'ByteCent (BYC)',
  },
  GRIDCOIN: {
    Name: 'GRIDCOIN',
    Symbol: 'GRIDCOIN',
    CoinName: 'GridCoin',
    FullName: 'GridCoin (GRIDCOIN)',
  },
  GEMZ: {
    Name: 'GEMZ',
    Symbol: 'GEMZ',
    CoinName: 'Gemz Social',
    FullName: 'Gemz Social (GEMZ)',
  },
  KTK: {
    Name: 'KTK',
    Symbol: 'KTK',
    CoinName: 'KryptCoin',
    FullName: 'KryptCoin (KTK)',
  },
  HZ: {
    Name: 'HZ',
    Symbol: 'HZ',
    CoinName: 'Horizon',
    FullName: 'Horizon (HZ)',
  },
  FAIR: {
    Name: 'FAIR',
    Symbol: 'FAIR',
    CoinName: 'FairCoin',
    FullName: 'FairCoin (FAIR)',
  },
  QORA: {
    Name: 'QORA',
    Symbol: 'QORA',
    CoinName: 'QoraCoin',
    FullName: 'QoraCoin (QORA)',
  },
  RBY: {
    Name: 'RBY',
    Symbol: 'RBY',
    CoinName: 'RubyCoin',
    FullName: 'RubyCoin (RBY)',
  },
  PTC: {
    Name: 'PTC',
    Symbol: 'PTC',
    CoinName: 'PesetaCoin',
    FullName: 'PesetaCoin (PTC)',
  },
  KOREC: {
    Name: 'KOREC',
    Symbol: 'KOREC',
    CoinName: 'Kore',
    FullName: 'Kore (KOREC)',
  },
  WBB: {
    Name: 'WBB',
    Symbol: 'WBB',
    CoinName: 'Wild Beast Coin',
    FullName: 'Wild Beast Coin (WBB)',
  },
  SSD: {
    Name: 'SSD',
    Symbol: 'SSD',
    CoinName: 'Sonic Screw Driver Coin',
    FullName: 'Sonic Screw Driver Coin (SSD)',
  },
  XTC: {
    Name: 'XTC',
    Symbol: 'XTC',
    CoinName: 'TileCoin',
    FullName: 'TileCoin (XTC)',
  },
  MMXIV: {
    Name: 'MMXIV',
    Symbol: 'MMXIV',
    CoinName: 'MaieutiCoin',
    FullName: 'MaieutiCoin (MMXIV)',
  },
  '8BIT': {
    Name: '8BIT',
    Symbol: '8BIT',
    CoinName: '8BIT Coin',
    FullName: '8BIT Coin (8BIT)',
  },
  STV: {
    Name: 'STV',
    Symbol: 'STV',
    CoinName: 'Sativa Coin',
    FullName: 'Sativa Coin (STV)',
  },
  EBS: {
    Name: 'EBS',
    Symbol: 'EBS',
    CoinName: 'EbolaShare',
    FullName: 'EbolaShare (EBS)',
  },
  AM: {
    Name: 'AM',
    Symbol: 'AM',
    CoinName: 'AeroMe',
    FullName: 'AeroMe (AM)',
  },
  XMG: {
    Name: 'XMG',
    Symbol: 'XMG',
    CoinName: 'Coin Magi',
    FullName: 'Coin Magi (XMG)',
  },
  AMBER: {
    Name: 'AMBER',
    Symbol: 'AMBER',
    CoinName: 'AmberCoin',
    FullName: 'AmberCoin (AMBER)',
  },
  NKT: {
    Name: 'NKT',
    Symbol: 'NKT',
    CoinName: 'NakomotoDark',
    FullName: 'NakomotoDark (NKT)',
  },
  J: {
    Name: 'J',
    Symbol: 'J',
    CoinName: 'JoinCoin',
    FullName: 'JoinCoin (J)',
  },
  GHOSTCOIN: {
    Name: 'GHOSTCOIN',
    Symbol: 'GHOSTCOIN',
    CoinName: 'GhostCoin',
    FullName: 'GhostCoin (GHOSTCOIN)',
  },
  ABY: {
    Name: 'ABY',
    Symbol: 'ABY',
    CoinName: 'ArtByte',
    FullName: 'ArtByte (ABY)',
  },
  LDOGE: {
    Name: 'LDOGE',
    Symbol: 'LDOGE',
    CoinName: 'LiteDoge',
    FullName: 'LiteDoge (LDOGE)',
  },
  MTR: {
    Name: 'MTR',
    Symbol: 'MTR',
    CoinName: 'MasterTraderCoin',
    FullName: 'MasterTraderCoin (MTR)',
  },
  TRI: {
    Name: 'TRI',
    Symbol: 'TRI',
    CoinName: 'Triangles Coin',
    FullName: 'Triangles Coin (TRI)',
  },
  SWARM: {
    Name: 'SWARM',
    Symbol: 'SWARM',
    CoinName: 'SwarmCoin',
    FullName: 'SwarmCoin (SWARM)',
  },
  BBR: {
    Name: 'BBR',
    Symbol: 'BBR',
    CoinName: 'Boolberry',
    FullName: 'Boolberry (BBR)',
  },
  BTCRY: {
    Name: 'BTCRY',
    Symbol: 'BTCRY',
    CoinName: 'BitCrystal',
    FullName: 'BitCrystal (BTCRY)',
  },
  BCR: {
    Name: 'BCR',
    Symbol: 'BCR',
    CoinName: 'BitCredit',
    FullName: 'BitCredit (BCR)',
  },
  XPB: {
    Name: 'XPB',
    Symbol: 'XPB',
    CoinName: 'Pebble Coin',
    FullName: 'Pebble Coin (XPB)',
  },
  XDQ: {
    Name: 'XDQ',
    Symbol: 'XDQ',
    CoinName: 'Dirac Coin',
    FullName: 'Dirac Coin (XDQ)',
  },
  FLDC: {
    Name: 'FLDC',
    Symbol: 'FLDC',
    CoinName: 'Folding Coin',
    FullName: 'Folding Coin (FLDC)',
  },
  SLR: {
    Name: 'SLR',
    Symbol: 'SLR',
    CoinName: 'SolarCoin',
    FullName: 'SolarCoin (SLR)',
  },
  SMAC: {
    Name: 'SMAC',
    Symbol: 'SMAC',
    CoinName: 'Social Media Coin',
    FullName: 'Social Media Coin (SMAC)',
  },
  TRK: {
    Name: 'TRK',
    Symbol: 'TRK',
    CoinName: 'TruckCoin',
    FullName: 'TruckCoin (TRK)',
  },
  UIS: {
    Name: 'UIS',
    Symbol: 'UIS',
    CoinName: 'Unitus',
    FullName: 'Unitus (UIS)',
  },
  CYP: {
    Name: 'CYP',
    Symbol: 'CYP',
    CoinName: 'CypherPunkCoin',
    FullName: 'CypherPunkCoin (CYP)',
  },
  UFOCOIN: {
    Name: 'UFOCOIN',
    Symbol: 'UFOCOIN',
    CoinName: 'Uniform Fiscal Object',
    FullName: 'Uniform Fiscal Object (UFOCOIN)',
  },
  ASN: {
    Name: 'ASN',
    Symbol: 'ASN',
    CoinName: 'Ascension Coin',
    FullName: 'Ascension Coin (ASN)',
  },
  OC: {
    Name: 'OC',
    Symbol: 'OC',
    CoinName: 'OrangeCoin',
    FullName: 'OrangeCoin (OC)',
  },
  GSM: {
    Name: 'GSM',
    Symbol: 'GSM',
    CoinName: 'GSM Coin',
    FullName: 'GSM Coin (GSM)',
  },
  FSC: {
    Name: 'FSC',
    Symbol: 'FSC',
    CoinName: 'FriendshipCoin',
    FullName: 'FriendshipCoin (FSC)',
  },
  NXTTY: {
    Name: 'NXTTY',
    Symbol: 'NXTTY',
    CoinName: 'NXTTY',
    FullName: 'NXTTY (NXTTY)',
  },
  QBK: {
    Name: 'QBK',
    Symbol: 'QBK',
    CoinName: 'QuBuck Coin',
    FullName: 'QuBuck Coin (QBK)',
  },
  BLC: {
    Name: 'BLC',
    Symbol: 'BLC',
    CoinName: 'BlakeCoin',
    FullName: 'BlakeCoin (BLC)',
  },
  MARYJ: {
    Name: 'MARYJ',
    Symbol: 'MARYJ',
    CoinName: 'MaryJane Coin',
    FullName: 'MaryJane Coin (MARYJ)',
  },
  OMC: {
    Name: 'OMC',
    Symbol: 'OMC',
    CoinName: 'OmniCron',
    FullName: 'OmniCron (OMC)',
  },
  GIG: {
    Name: 'GIG',
    Symbol: 'GIG',
    CoinName: 'GigaCoin',
    FullName: 'GigaCoin (GIG)',
  },
  LTBC: {
    Name: 'LTBC',
    Symbol: 'LTBC',
    CoinName: 'LTBCoin',
    FullName: 'LTBCoin (LTBC)',
  },
  NEOS: {
    Name: 'NEOS',
    Symbol: 'NEOS',
    CoinName: 'NeosCoin',
    FullName: 'NeosCoin (NEOS)',
  },
  HYPER: {
    Name: 'HYPER',
    Symbol: 'HYPER',
    CoinName: 'HyperCoin',
    FullName: 'HyperCoin (HYPER)',
  },
  METAL: {
    Name: 'METAL',
    Symbol: 'METAL',
    CoinName: 'MetalCoin',
    FullName: 'MetalCoin (METAL)',
  },
  PINK: {
    Name: 'PINK',
    Symbol: 'PINK',
    CoinName: 'PinkCoin',
    FullName: 'PinkCoin (PINK)',
  },
  GRE: {
    Name: 'GRE',
    Symbol: 'GRE',
    CoinName: 'GreenCoin',
    FullName: 'GreenCoin (GRE)',
  },
  XG: {
    Name: 'XG',
    Symbol: 'XG',
    CoinName: 'XG Sports',
    FullName: 'XG Sports (XG)',
  },
  CHILD: {
    Name: 'CHILD',
    Symbol: 'CHILD',
    CoinName: 'ChildCoin',
    FullName: 'ChildCoin (CHILD)',
  },
  BOOM: {
    Name: 'BOOM',
    Symbol: 'BOOM',
    CoinName: 'Boom Token',
    FullName: 'Boom Token (BOOM)',
  },
  MINE: {
    Name: 'MINE',
    Symbol: 'MINE',
    CoinName: 'Instamine Nuggets',
    FullName: 'Instamine Nuggets (MINE)',
  },
  ROS: {
    Name: 'ROS',
    Symbol: 'ROS',
    CoinName: 'ROS Coin',
    FullName: 'ROS Coin (ROS)',
  },
  UNAT: {
    Name: 'UNAT',
    Symbol: 'UNAT',
    CoinName: 'Unattanium',
    FullName: 'Unattanium (UNAT)',
  },
  SLM: {
    Name: 'SLM',
    Symbol: 'SLM',
    CoinName: 'SlimCoin',
    FullName: 'SlimCoin (SLM)',
  },
  GAIAPLATFORM: {
    Name: 'GAIAPLATFORM',
    Symbol: 'GAIAPLATFORM',
    CoinName: 'GAIA Platform',
    FullName: 'GAIA Platform (GAIAPLATFORM)',
  },
  FCN: {
    Name: 'FCN',
    Symbol: 'FCN',
    CoinName: 'FantomCoin',
    FullName: 'FantomCoin (FCN)',
  },
  CRYPTONITE: {
    Name: 'CRYPTONITE',
    Symbol: 'CRYPTONITE',
    CoinName: 'Cryptonite',
    FullName: 'Cryptonite (CRYPTONITE)',
  },
  CURE: {
    Name: 'CURE',
    Symbol: 'CURE',
    CoinName: 'Curecoin',
    FullName: 'Curecoin (CURE)',
  },
  GMC: {
    Name: 'GMC',
    Symbol: 'GMC',
    CoinName: 'Gridmaster',
    FullName: 'Gridmaster (GMC)',
  },
  XBC: {
    Name: 'XBC',
    Symbol: 'XBC',
    CoinName: 'BitcoinPlus',
    FullName: 'BitcoinPlus (XBC)',
  },
  CONS: {
    Name: 'CONS',
    Symbol: 'CONS',
    CoinName: 'ConSpiracy Coin',
    FullName: 'ConSpiracy Coin (CONS)',
  },
  OCTOC: {
    Name: 'OCTOC',
    Symbol: 'OCTOC',
    CoinName: 'OctoCoin',
    FullName: 'OctoCoin (OCTOC)',
  },
  MASTERCOIN: {
    Name: 'MASTERCOIN',
    Symbol: 'MASTERCOIN',
    CoinName: 'MasterCoin',
    FullName: 'MasterCoin (MASTERCOIN)',
  },
  EGGC: {
    Name: 'EGGC',
    Symbol: 'EGGC',
    CoinName: 'EggCoin',
    FullName: 'EggCoin (EGGC)',
  },
  C2: {
    Name: 'C2',
    Symbol: 'C2',
    CoinName: 'Coin.2',
    FullName: 'Coin.2 (C2)',
  },
  GLOWSHA: {
    Name: 'GLOWSHA',
    Symbol: 'GLOWSHA',
    CoinName: 'GlowShares',
    FullName: 'GlowShares (GLOWSHA)',
  },
  CAMC: {
    Name: 'CAMC',
    Symbol: 'CAMC',
    CoinName: 'Camcoin',
    FullName: 'Camcoin (CAMC)',
  },
  RBR: {
    Name: 'RBR',
    Symbol: 'RBR',
    CoinName: 'Ribbit Rewards',
    FullName: 'Ribbit Rewards (RBR)',
  },
  XQN: {
    Name: 'XQN',
    Symbol: 'XQN',
    CoinName: 'Quotient',
    FullName: 'Quotient (XQN)',
  },
  ICASH: {
    Name: 'ICASH',
    Symbol: 'ICASH',
    CoinName: 'ICASH',
    FullName: 'ICASH (ICASH)',
  },
  BTMI: {
    Name: 'BTMI',
    Symbol: 'BTMI',
    CoinName: 'BitMiles',
    FullName: 'BitMiles (BTMI)',
  },
  EVENT: {
    Name: 'EVENT',
    Symbol: 'EVENT',
    CoinName: 'Event Token',
    FullName: 'Event Token (EVENT)',
  },
  '1CR': {
    Name: '1CR',
    Symbol: '1CR',
    CoinName: '1Credit',
    FullName: '1Credit (1CR)',
  },
  VIOR: {
    Name: 'VIOR',
    Symbol: 'VIOR',
    CoinName: 'ViorCoin',
    FullName: 'ViorCoin (VIOR)',
  },
  XCO: {
    Name: 'XCO',
    Symbol: 'XCO',
    CoinName: 'XCoin',
    FullName: 'XCoin (XCO)',
  },
  VMC: {
    Name: 'VMC',
    Symbol: 'VMC',
    CoinName: 'VirtualMining Coin',
    FullName: 'VirtualMining Coin (VMC)',
  },
  MARSC: {
    Name: 'MARSC',
    Symbol: 'MARSC',
    CoinName: 'MarsCoin',
    FullName: 'MarsCoin (MARSC)',
  },
  VIRAL: {
    Name: 'VIRAL',
    Symbol: 'VIRAL',
    CoinName: 'Viral Coin',
    FullName: 'Viral Coin (VIRAL)',
  },
  EQM: {
    Name: 'EQM',
    Symbol: 'EQM',
    CoinName: 'Equilibrium Coin',
    FullName: 'Equilibrium Coin (EQM)',
  },
  ISL: {
    Name: 'ISL',
    Symbol: 'ISL',
    CoinName: 'IslaCoin',
    FullName: 'IslaCoin (ISL)',
  },
  QSLV: {
    Name: 'QSLV',
    Symbol: 'QSLV',
    CoinName: 'Quicksilver coin',
    FullName: 'Quicksilver coin (QSLV)',
  },
  XWT: {
    Name: 'XWT',
    Symbol: 'XWT',
    CoinName: 'World Trade Funds',
    FullName: 'World Trade Funds (XWT)',
  },
  XNA: {
    Name: 'XNA',
    Symbol: 'XNA',
    CoinName: 'DeOxyRibose',
    FullName: 'DeOxyRibose (XNA)',
  },
  SKB: {
    Name: 'SKB',
    Symbol: 'SKB',
    CoinName: 'SkullBuzz',
    FullName: 'SkullBuzz (SKB)',
  },
  BSTY: {
    Name: 'BSTY',
    Symbol: 'BSTY',
    CoinName: 'GlobalBoost',
    FullName: 'GlobalBoost (BSTY)',
  },
  FCS: {
    Name: 'FCS',
    Symbol: 'FCS',
    CoinName: 'CryptoFocus',
    FullName: 'CryptoFocus (FCS)',
  },
  GAM: {
    Name: 'GAM',
    Symbol: 'GAM',
    CoinName: 'Gambit coin',
    FullName: 'Gambit coin (GAM)',
  },
  CESC: {
    Name: 'CESC',
    Symbol: 'CESC',
    CoinName: 'Crypto Escudo',
    FullName: 'Crypto Escudo (CESC)',
  },
  TWLV: {
    Name: 'TWLV',
    Symbol: 'TWLV',
    CoinName: 'Twelve Coin',
    FullName: 'Twelve Coin (TWLV)',
  },
  EAGS: {
    Name: 'EAGS',
    Symbol: 'EAGS',
    CoinName: 'EagsCoin',
    FullName: 'EagsCoin (EAGS)',
  },
  MLTC: {
    Name: 'MLTC',
    Symbol: 'MLTC',
    CoinName: 'MultiWallet Coin',
    FullName: 'MultiWallet Coin (MLTC)',
  },
  ADC: {
    Name: 'ADC',
    Symbol: 'ADC',
    CoinName: 'AudioCoin',
    FullName: 'AudioCoin (ADC)',
  },
  MARS: {
    Name: 'MARS',
    Symbol: 'MARS',
    CoinName: 'MarsCoin',
    FullName: 'MarsCoin (MARS)',
  },
  XMS: {
    Name: 'XMS',
    Symbol: 'XMS',
    CoinName: 'Megastake',
    FullName: 'Megastake (XMS)',
  },
  SPHR: {
    Name: 'SPHR',
    Symbol: 'SPHR',
    CoinName: 'Sphere Coin',
    FullName: 'Sphere Coin (SPHR)',
  },
  SIGU: {
    Name: 'SIGU',
    Symbol: 'SIGU',
    CoinName: 'Singular',
    FullName: 'Singular (SIGU)',
  },
  M1: {
    Name: 'M1',
    Symbol: 'M1',
    CoinName: 'SupplyShock',
    FullName: 'SupplyShock (M1)',
  },
  DB: {
    Name: 'DB',
    Symbol: 'DB',
    CoinName: 'DarkBit',
    FullName: 'DarkBit (DB)',
  },
  CTO: {
    Name: 'CTO',
    Symbol: 'CTO',
    CoinName: 'Crypto',
    FullName: 'Crypto (CTO)',
  },
  EDGE: {
    Name: 'EDGE',
    Symbol: 'EDGE',
    CoinName: 'EDGE',
    FullName: 'EDGE (EDGE)',
  },
  BITL: {
    Name: 'BITL',
    Symbol: 'BITL',
    CoinName: 'BitLux',
    FullName: 'BitLux (BITL)',
  },
  FUTC: {
    Name: 'FUTC',
    Symbol: 'FUTC',
    CoinName: 'FutCoin',
    FullName: 'FutCoin (FUTC)',
  },
  GLOBE: {
    Name: 'GLOBE',
    Symbol: 'GLOBE',
    CoinName: 'Global',
    FullName: 'Global (GLOBE)',
  },
  MRP: {
    Name: 'MRP',
    Symbol: 'MRP',
    CoinName: 'MorpheusCoin',
    FullName: 'MorpheusCoin (MRP)',
  },
  CREVA: {
    Name: 'CREVA',
    Symbol: 'CREVA',
    CoinName: 'Creva Coin',
    FullName: 'Creva Coin (CREVA)',
  },
  FOREVER: {
    Name: 'FOREVER',
    Symbol: 'FOREVER',
    CoinName: 'Forever Coin',
    FullName: 'Forever Coin (FOREVER)',
  },
  NANAS: {
    Name: 'NANAS',
    Symbol: 'NANAS',
    CoinName: 'BananaBits',
    FullName: 'BananaBits (NANAS)',
  },
  LOG: {
    Name: 'LOG',
    Symbol: 'LOG',
    CoinName: 'Wood Coin',
    FullName: 'Wood Coin (LOG)',
  },
  XCE: {
    Name: 'XCE',
    Symbol: 'XCE',
    CoinName: 'Cerium',
    FullName: 'Cerium (XCE)',
  },
  ACP: {
    Name: 'ACP',
    Symbol: 'ACP',
    CoinName: 'Anarchists Prime',
    FullName: 'Anarchists Prime (ACP)',
  },
  DRZ: {
    Name: 'DRZ',
    Symbol: 'DRZ',
    CoinName: 'Droidz',
    FullName: 'Droidz (DRZ)',
  },
  BSC: {
    Name: 'BSC',
    Symbol: 'BSC',
    CoinName: 'BowsCoin',
    FullName: 'BowsCoin (BSC)',
  },
  DRKT: {
    Name: 'DRKT',
    Symbol: 'DRKT',
    CoinName: 'DarkTron',
    FullName: 'DarkTron (DRKT)',
  },
  CIRC: {
    Name: 'CIRC',
    Symbol: 'CIRC',
    CoinName: 'CryptoCircuits',
    FullName: 'CryptoCircuits (CIRC)',
  },
  NKA: {
    Name: 'NKA',
    Symbol: 'NKA',
    CoinName: 'IncaKoin',
    FullName: 'IncaKoin (NKA)',
  },
  VERSA: {
    Name: 'VERSA',
    Symbol: 'VERSA',
    CoinName: 'Versa Token',
    FullName: 'Versa Token (VERSA)',
  },
  EPY: {
    Name: 'EPY',
    Symbol: 'EPY',
    CoinName: 'Empyrean',
    FullName: 'Empyrean (EPY)',
  },
  SQL: {
    Name: 'SQL',
    Symbol: 'SQL',
    CoinName: 'Squall Coin',
    FullName: 'Squall Coin (SQL)',
  },
  PIGGYCOIN: {
    Name: 'PIGGYCOIN',
    Symbol: 'PIGGYCOIN',
    CoinName: 'Piggy Coin',
    FullName: 'Piggy Coin (PIGGYCOIN)',
  },
  MIL: {
    Name: 'MIL',
    Symbol: 'MIL',
    CoinName: 'Milllionaire Coin',
    FullName: 'Milllionaire Coin (MIL)',
  },
  CRW: {
    Name: 'CRW',
    Symbol: 'CRW',
    CoinName: 'Crown Coin',
    FullName: 'Crown Coin (CRW)',
  },
  GENSTAKE: {
    Name: 'GENSTAKE',
    Symbol: 'GENSTAKE',
    CoinName: 'Genstake',
    FullName: 'Genstake (GENSTAKE)',
  },
  XPH: {
    Name: 'XPH',
    Symbol: 'XPH',
    CoinName: 'PharmaCoin',
    FullName: 'PharmaCoin (XPH)',
  },
  GRM: {
    Name: 'GRM',
    Symbol: 'GRM',
    CoinName: 'GridMaster',
    FullName: 'GridMaster (GRM)',
  },
  QTZ: {
    Name: 'QTZ',
    Symbol: 'QTZ',
    CoinName: 'Quartz',
    FullName: 'Quartz (QTZ)',
  },
  ARB: {
    Name: 'ARB',
    Symbol: 'ARB',
    CoinName: 'Arbit Coin',
    FullName: 'Arbit Coin (ARB)',
  },
  LTS: {
    Name: 'LTS',
    Symbol: 'LTS',
    CoinName: 'Litestar Coin',
    FullName: 'Litestar Coin (LTS)',
  },
  GOLDPIECES: {
    Name: 'GOLDPIECES',
    Symbol: 'GOLDPIECES',
    CoinName: 'GoldPieces',
    FullName: 'GoldPieces (GOLDPIECES)',
  },
  BITZ: {
    Name: 'BITZ',
    Symbol: 'BITZ',
    CoinName: 'Bitz Coin',
    FullName: 'Bitz Coin (BITZ)',
  },
  DUB: {
    Name: 'DUB',
    Symbol: 'DUB',
    CoinName: 'DubCoin',
    FullName: 'DubCoin (DUB)',
  },
  GRAV: {
    Name: 'GRAV',
    Symbol: 'GRAV',
    CoinName: 'Graviton',
    FullName: 'Graviton (GRAV)',
  },
  BOBS: {
    Name: 'BOBS',
    Symbol: 'BOBS',
    CoinName: "Bob's Repair",
    FullName: "Bob's Repair (BOBS)",
  },
  MNV: {
    Name: 'MNV',
    Symbol: 'MNV',
    CoinName: 'MonetaVerde',
    FullName: 'MonetaVerde (MNV)',
  },
  QCN: {
    Name: 'QCN',
    Symbol: 'QCN',
    CoinName: 'Quazar Coin',
    FullName: 'Quazar Coin (QCN)',
  },
  HEDGE: {
    Name: 'HEDGE',
    Symbol: 'HEDGE',
    CoinName: 'Hedgecoin',
    FullName: 'Hedgecoin (HEDGE)',
  },
  SONG: {
    Name: 'SONG',
    Symbol: 'SONG',
    CoinName: 'Song Coin',
    FullName: 'Song Coin (SONG)',
  },
  XSEED: {
    Name: 'XSEED',
    Symbol: 'XSEED',
    CoinName: 'BitSeeds',
    FullName: 'BitSeeds (XSEED)',
  },
  AXIOM: {
    Name: 'AXIOM',
    Symbol: 'AXIOM',
    CoinName: 'Axiom Coin',
    FullName: 'Axiom Coin (AXIOM)',
  },
  SMLY: {
    Name: 'SMLY',
    Symbol: 'SMLY',
    CoinName: 'SmileyCoin',
    FullName: 'SmileyCoin (SMLY)',
  },
  RIMBIT: {
    Name: 'RIMBIT',
    Symbol: 'RIMBIT',
    CoinName: 'Rimbit',
    FullName: 'Rimbit (RIMBIT)',
  },
  CHIP: {
    Name: 'CHIP',
    Symbol: 'CHIP',
    CoinName: 'Chip',
    FullName: 'Chip (CHIP)',
  },
  UNC: {
    Name: 'UNC',
    Symbol: 'UNC',
    CoinName: 'UnCoin',
    FullName: 'UnCoin (UNC)',
  },
  SPRTS: {
    Name: 'SPRTS',
    Symbol: 'SPRTS',
    CoinName: 'Sprouts',
    FullName: 'Sprouts (SPRTS)',
  },
  ZNY: {
    Name: 'ZNY',
    Symbol: 'ZNY',
    CoinName: 'BitZeny',
    FullName: 'BitZeny (ZNY)',
  },
  BTQ: {
    Name: 'BTQ',
    Symbol: 'BTQ',
    CoinName: 'BitQuark',
    FullName: 'BitQuark (BTQ)',
  },
  PKB: {
    Name: 'PKB',
    Symbol: 'PKB',
    CoinName: 'ParkByte',
    FullName: 'ParkByte (PKB)',
  },
  SNRG: {
    Name: 'SNRG',
    Symbol: 'SNRG',
    CoinName: 'Synergy',
    FullName: 'Synergy (SNRG)',
  },
  GHOUL: {
    Name: 'GHOUL',
    Symbol: 'GHOUL',
    CoinName: 'Ghoul Coin',
    FullName: 'Ghoul Coin (GHOUL)',
  },
  HNC: {
    Name: 'HNC',
    Symbol: 'HNC',
    CoinName: 'Hellenic Coin',
    FullName: 'Hellenic Coin (HNC)',
  },
  DIGS: {
    Name: 'DIGS',
    Symbol: 'DIGS',
    CoinName: 'Diggits',
    FullName: 'Diggits (DIGS)',
  },
  GCR: {
    Name: 'GCR',
    Symbol: 'GCR',
    CoinName: 'Global Currency Reserve',
    FullName: 'Global Currency Reserve (GCR)',
  },
  MAPC: {
    Name: 'MAPC',
    Symbol: 'MAPC',
    CoinName: 'MapCoin',
    FullName: 'MapCoin (MAPC)',
  },
  MI: {
    Name: 'MI',
    Symbol: 'MI',
    CoinName: 'XiaoMiCoin',
    FullName: 'XiaoMiCoin (MI)',
  },
  PAYCON: {
    Name: 'PAYCON',
    Symbol: 'PAYCON',
    CoinName: 'Paycon',
    FullName: 'Paycon (PAYCON)',
  },
  CLEVERCOIN: {
    Name: 'CLEVERCOIN',
    Symbol: 'CLEVERCOIN',
    CoinName: 'CleverCoin',
    FullName: 'CleverCoin (CLEVERCOIN)',
  },
  LYB: {
    Name: 'LYB',
    Symbol: 'LYB',
    CoinName: 'LyraBar',
    FullName: 'LyraBar (LYB)',
  },
  PXI: {
    Name: 'PXI',
    Symbol: 'PXI',
    CoinName: 'Prime-X1',
    FullName: 'Prime-X1 (PXI)',
  },
  CPS: {
    Name: 'CPS',
    Symbol: 'CPS',
    CoinName: 'CapriCoin',
    FullName: 'CapriCoin (CPS)',
  },
  AMS: {
    Name: 'AMS',
    Symbol: 'AMS',
    CoinName: 'Amsterdam Coin',
    FullName: 'Amsterdam Coin (AMS)',
  },
  OBITS: {
    Name: 'OBITS',
    Symbol: 'OBITS',
    CoinName: 'Obits Coin',
    FullName: 'Obits Coin (OBITS)',
  },
  CLUB: {
    Name: 'CLUB',
    Symbol: 'CLUB',
    CoinName: 'ClubCoin',
    FullName: 'ClubCoin (CLUB)',
  },
  VAL: {
    Name: 'VAL',
    Symbol: 'VAL',
    CoinName: 'Validity',
    FullName: 'Validity (VAL)',
  },
  EMC: {
    Name: 'EMC',
    Symbol: 'EMC',
    CoinName: 'Emercoin',
    FullName: 'Emercoin (EMC)',
  },
  BLITZ: {
    Name: 'BLITZ',
    Symbol: 'BLITZ',
    CoinName: 'BlitzCoin',
    FullName: 'BlitzCoin (BLITZ)',
  },
  BHIRE: {
    Name: 'BHIRE',
    Symbol: 'BHIRE',
    CoinName: 'BitHIRE',
    FullName: 'BitHIRE (BHIRE)',
  },
  EVERGREEN: {
    Name: 'EVERGREEN',
    Symbol: 'EVERGREEN',
    CoinName: 'EverGreenCoin',
    FullName: 'EverGreenCoin (EVERGREEN)',
  },
  MINDCOIN: {
    Name: 'MINDCOIN',
    Symbol: 'MINDCOIN',
    CoinName: 'MindCoin',
    FullName: 'MindCoin (MINDCOIN)',
  },
  I0C: {
    Name: 'I0C',
    Symbol: 'I0C',
    CoinName: 'I0coin',
    FullName: 'I0coin (I0C)',
  },
  BTA: {
    Name: 'BTA',
    Symbol: 'BTA',
    CoinName: 'Bata',
    FullName: 'Bata (BTA)',
  },
  NAS2: {
    Name: 'NAS2',
    Symbol: 'NAS2',
    CoinName: 'Nas2Coin',
    FullName: 'Nas2Coin (NAS2)',
  },
  PAK: {
    Name: 'PAK',
    Symbol: 'PAK',
    CoinName: 'Pakcoin',
    FullName: 'Pakcoin (PAK)',
  },
  CRB: {
    Name: 'CRB',
    Symbol: 'CRB',
    CoinName: 'Creditbit',
    FullName: 'Creditbit (CRB)',
  },
  DOGED: {
    Name: 'DOGED',
    Symbol: 'DOGED',
    CoinName: 'DogeCoinDark',
    FullName: 'DogeCoinDark (DOGED)',
  },
  OK: {
    Name: 'OK',
    Symbol: 'OK',
    CoinName: 'OKCash',
    FullName: 'OKCash (OK)',
  },
  RVR: {
    Name: 'RVR',
    Symbol: 'RVR',
    CoinName: 'Revolution VR',
    FullName: 'Revolution VR (RVR)',
  },
  HODL: {
    Name: 'HODL',
    Symbol: 'HODL',
    CoinName: 'HOdlcoin',
    FullName: 'HOdlcoin (HODL)',
  },
  EDRC: {
    Name: 'EDRC',
    Symbol: 'EDRC',
    CoinName: 'EDRCoin',
    FullName: 'EDRCoin (EDRC)',
  },
  THEDAO: {
    Name: 'THEDAO',
    Symbol: 'THEDAO',
    CoinName: 'The DAO',
    FullName: 'The DAO (THEDAO)',
  },
  HTC: {
    Name: 'HTC',
    Symbol: 'HTC',
    CoinName: 'Hitcoin',
    FullName: 'Hitcoin (HTC)',
  },
  GAMECRED: {
    Name: 'GAMECRED',
    Symbol: 'GAMECRED',
    CoinName: 'GameCredits',
    FullName: 'GameCredits (GAMECRED)',
  },
  DSH: {
    Name: 'DSH',
    Symbol: 'DSH',
    CoinName: 'Dashcoin',
    FullName: 'Dashcoin (DSH)',
  },
  DBIC: {
    Name: 'DBIC',
    Symbol: 'DBIC',
    CoinName: 'DubaiCoin',
    FullName: 'DubaiCoin (DBIC)',
  },
  XHI: {
    Name: 'XHI',
    Symbol: 'XHI',
    CoinName: 'HiCoin',
    FullName: 'HiCoin (XHI)',
  },
  BIOS: {
    Name: 'BIOS',
    Symbol: 'BIOS',
    CoinName: 'BiosCrypto',
    FullName: 'BiosCrypto (BIOS)',
  },
  CAB: {
    Name: 'CAB',
    Symbol: 'CAB',
    CoinName: 'CabbageUnit',
    FullName: 'CabbageUnit (CAB)',
  },
  CARPE: {
    Name: 'CARPE',
    Symbol: 'CARPE',
    CoinName: 'CarpeDiemCoin',
    FullName: 'CarpeDiemCoin (CARPE)',
  },
  GBT: {
    Name: 'GBT',
    Symbol: 'GBT',
    CoinName: 'GameBetCoin',
    FullName: 'GameBetCoin (GBT)',
  },
  RCX: {
    Name: 'RCX',
    Symbol: 'RCX',
    CoinName: 'RedCrowCoin',
    FullName: 'RedCrowCoin (RCX)',
  },
  PWR: {
    Name: 'PWR',
    Symbol: 'PWR',
    CoinName: 'PWR Coin',
    FullName: 'PWR Coin (PWR)',
  },
  TRUMP: {
    Name: 'TRUMP',
    Symbol: 'TRUMP',
    CoinName: 'TrumpCoin',
    FullName: 'TrumpCoin (TRUMP)',
  },
  PRM: {
    Name: 'PRM',
    Symbol: 'PRM',
    CoinName: 'PrismChain',
    FullName: 'PrismChain (PRM)',
  },
  BCY: {
    Name: 'BCY',
    Symbol: 'BCY',
    CoinName: 'BitCrystals',
    FullName: 'BitCrystals (BCY)',
  },
  RBIES: {
    Name: 'RBIES',
    Symbol: 'RBIES',
    CoinName: 'Rubies',
    FullName: 'Rubies (RBIES)',
  },
  BLRY: {
    Name: 'BLRY',
    Symbol: 'BLRY',
    CoinName: 'BillaryCoin',
    FullName: 'BillaryCoin (BLRY)',
  },
  DOTC: {
    Name: 'DOTC',
    Symbol: 'DOTC',
    CoinName: 'Dotcoin',
    FullName: 'Dotcoin (DOTC)',
  },
  SCOT: {
    Name: 'SCOT',
    Symbol: 'SCOT',
    CoinName: 'Scotcoin',
    FullName: 'Scotcoin (SCOT)',
  },
  CREED: {
    Name: 'CREED',
    Symbol: 'CREED',
    CoinName: 'Thecreed',
    FullName: 'Thecreed (CREED)',
  },
  POSTC: {
    Name: 'POSTC',
    Symbol: 'POSTC',
    CoinName: 'PostCoin',
    FullName: 'PostCoin (POSTC)',
  },
  INFX: {
    Name: 'INFX',
    Symbol: 'INFX',
    CoinName: 'Influxcoin',
    FullName: 'Influxcoin (INFX)',
  },
  ETHS: {
    Name: 'ETHS',
    Symbol: 'ETHS',
    CoinName: 'EthereumScrypt',
    FullName: 'EthereumScrypt (ETHS)',
  },
  PHALA: {
    Name: 'PHALA',
    Symbol: 'PHALA',
    CoinName: 'Phalanx',
    FullName: 'Phalanx (PHALA)',
  },
  NUMBERS: {
    Name: 'NUMBERS',
    Symbol: 'NUMBERS',
    CoinName: 'NumbersCoin',
    FullName: 'NumbersCoin (NUMBERS)',
  },
  GOST: {
    Name: 'GOST',
    Symbol: 'GOST',
    CoinName: 'SoulCoin',
    FullName: 'SoulCoin (GOST)',
  },
  ION: {
    Name: 'ION',
    Symbol: 'ION',
    CoinName: 'Ionomy',
    FullName: 'Ionomy (ION)',
  },
  GROW: {
    Name: 'GROW',
    Symbol: 'GROW',
    CoinName: 'GrownCoin',
    FullName: 'GrownCoin (GROW)',
  },
  UNITY: {
    Name: 'UNITY',
    Symbol: 'UNITY',
    CoinName: 'SuperNET',
    FullName: 'SuperNET (UNITY)',
  },
  OLDSF: {
    Name: 'OLDSF',
    Symbol: 'OLDSF',
    CoinName: 'OldSafeCoin',
    FullName: 'OldSafeCoin (OLDSF)',
  },
  SSTC: {
    Name: 'SSTC',
    Symbol: 'SSTC',
    CoinName: 'SunShotCoin',
    FullName: 'SunShotCoin (SSTC)',
  },
  NETC: {
    Name: 'NETC',
    Symbol: 'NETC',
    CoinName: 'NetworkCoin',
    FullName: 'NetworkCoin (NETC)',
  },
  GPU: {
    Name: 'GPU',
    Symbol: 'GPU',
    CoinName: 'GPU Coin',
    FullName: 'GPU Coin (GPU)',
  },
  TAGR: {
    Name: 'TAGR',
    Symbol: 'TAGR',
    CoinName: 'Think And Get Rich Coin',
    FullName: 'Think And Get Rich Coin (TAGR)',
  },
  HMP: {
    Name: 'HMP',
    Symbol: 'HMP',
    CoinName: 'HempCoin',
    FullName: 'HempCoin (HMP)',
  },
  ADZ: {
    Name: 'ADZ',
    Symbol: 'ADZ',
    CoinName: 'Adzcoin',
    FullName: 'Adzcoin (ADZ)',
  },
  GAPC: {
    Name: 'GAPC',
    Symbol: 'GAPC',
    CoinName: 'Gapcoin',
    FullName: 'Gapcoin (GAPC)',
  },
  IVZ: {
    Name: 'IVZ',
    Symbol: 'IVZ',
    CoinName: 'InvisibleCoin',
    FullName: 'InvisibleCoin (IVZ)',
  },
  SOIL: {
    Name: 'SOIL',
    Symbol: 'SOIL',
    CoinName: 'SoilCoin',
    FullName: 'SoilCoin (SOIL)',
  },
  YOC: {
    Name: 'YOC',
    Symbol: 'YOC',
    CoinName: 'YoCoin',
    FullName: 'YoCoin (YOC)',
  },
  VPRC: {
    Name: 'VPRC',
    Symbol: 'VPRC',
    CoinName: 'VapersCoin',
    FullName: 'VapersCoin (VPRC)',
  },
  APC: {
    Name: 'APC',
    Symbol: 'APC',
    CoinName: 'AlpaCoin',
    FullName: 'AlpaCoin (APC)',
  },
  STEPS: {
    Name: 'STEPS',
    Symbol: 'STEPS',
    CoinName: 'Steps',
    FullName: 'Steps (STEPS)',
  },
  DBTC: {
    Name: 'DBTC',
    Symbol: 'DBTC',
    CoinName: 'DebitCoin',
    FullName: 'DebitCoin (DBTC)',
  },
  UNIT: {
    Name: 'UNIT',
    Symbol: 'UNIT',
    CoinName: 'Universal Currency',
    FullName: 'Universal Currency (UNIT)',
  },
  AEON: {
    Name: 'AEON',
    Symbol: 'AEON',
    CoinName: 'AEON',
    FullName: 'AEON (AEON)',
  },
  MOIN: {
    Name: 'MOIN',
    Symbol: 'MOIN',
    CoinName: 'MoinCoin',
    FullName: 'MoinCoin (MOIN)',
  },
  ERC: {
    Name: 'ERC',
    Symbol: 'ERC',
    CoinName: 'EuropeCoin',
    FullName: 'EuropeCoin (ERC)',
  },
  AIB: {
    Name: 'AIB',
    Symbol: 'AIB',
    CoinName: 'AdvancedInternetBlock',
    FullName: 'AdvancedInternetBlock (AIB)',
  },
  PRIME: {
    Name: 'PRIME',
    Symbol: 'PRIME',
    CoinName: 'PrimeChain',
    FullName: 'PrimeChain (PRIME)',
  },
  BERN: {
    Name: 'BERN',
    Symbol: 'BERN',
    CoinName: 'BERNcash',
    FullName: 'BERNcash (BERN)',
  },
  BIGUP: {
    Name: 'BIGUP',
    Symbol: 'BIGUP',
    CoinName: 'BigUp',
    FullName: 'BigUp (BIGUP)',
  },
  XRE: {
    Name: 'XRE',
    Symbol: 'XRE',
    CoinName: 'RevolverCoin',
    FullName: 'RevolverCoin (XRE)',
  },
  PEPE: {
    Name: 'PEPE',
    Symbol: 'PEPE',
    CoinName: 'Memetic',
    FullName: 'Memetic (PEPE)',
  },
  XDB: {
    Name: 'XDB',
    Symbol: 'XDB',
    CoinName: 'DragonSphere',
    FullName: 'DragonSphere (XDB)',
  },
  ANTI: {
    Name: 'ANTI',
    Symbol: 'ANTI',
    CoinName: 'Anti Bitcoin',
    FullName: 'Anti Bitcoin (ANTI)',
  },
  BRK: {
    Name: 'BRK',
    Symbol: 'BRK',
    CoinName: 'BreakoutCoin',
    FullName: 'BreakoutCoin (BRK)',
  },
  MNM: {
    Name: 'MNM',
    Symbol: 'MNM',
    CoinName: 'Mineum',
    FullName: 'Mineum (MNM)',
  },
  ZEIT: {
    Name: 'ZEIT',
    Symbol: 'ZEIT',
    CoinName: 'ZeitCoin',
    FullName: 'ZeitCoin (ZEIT)',
  },
  '2GIVE': {
    Name: '2GIVE',
    Symbol: '2GIVE',
    CoinName: '2GiveCoin',
    FullName: '2GiveCoin (2GIVE)',
  },
  CGA: {
    Name: 'CGA',
    Symbol: 'CGA',
    CoinName: 'Cryptographic Anomaly',
    FullName: 'Cryptographic Anomaly (CGA)',
  },
  SWING: {
    Name: 'SWING',
    Symbol: 'SWING',
    CoinName: 'SwingCoin',
    FullName: 'SwingCoin (SWING)',
  },
  NEBU: {
    Name: 'NEBU',
    Symbol: 'NEBU',
    CoinName: 'Nebuchadnezzar',
    FullName: 'Nebuchadnezzar (NEBU)',
  },
  AEC: {
    Name: 'AEC',
    Symbol: 'AEC',
    CoinName: 'AcesCoin',
    FullName: 'AcesCoin (AEC)',
  },
  FRN: {
    Name: 'FRN',
    Symbol: 'FRN',
    CoinName: 'Francs',
    FullName: 'Francs (FRN)',
  },
  ADNT: {
    Name: 'ADNT',
    Symbol: 'ADNT',
    CoinName: 'Aiden',
    FullName: 'Aiden (ADNT)',
  },
  PULSE: {
    Name: 'PULSE',
    Symbol: 'PULSE',
    CoinName: 'Pulse',
    FullName: 'Pulse (PULSE)',
  },
  N7: {
    Name: 'N7',
    Symbol: 'N7',
    CoinName: 'Number7',
    FullName: 'Number7 (N7)',
  },
  CYG: {
    Name: 'CYG',
    Symbol: 'CYG',
    CoinName: 'Cygnus',
    FullName: 'Cygnus (CYG)',
  },
  LGBTQ: {
    Name: 'LGBTQ',
    Symbol: 'LGBTQ',
    CoinName: 'LGBTQoin',
    FullName: 'LGBTQoin (LGBTQ)',
  },
  UTH: {
    Name: 'UTH',
    Symbol: 'UTH',
    CoinName: 'Uther',
    FullName: 'Uther (UTH)',
  },
  MPRO: {
    Name: 'MPRO',
    Symbol: 'MPRO',
    CoinName: 'MediumProject',
    FullName: 'MediumProject (MPRO)',
  },
  KATZ: {
    Name: 'KATZ',
    Symbol: 'KATZ',
    CoinName: 'KATZcoin',
    FullName: 'KATZcoin (KATZ)',
  },
  SPM: {
    Name: 'SPM',
    Symbol: 'SPM',
    CoinName: 'Supreme',
    FullName: 'Supreme (SPM)',
  },
  MOJO: {
    Name: 'MOJO',
    Symbol: 'MOJO',
    CoinName: 'Mojocoin',
    FullName: 'Mojocoin (MOJO)',
  },
  BELA: {
    Name: 'BELA',
    Symbol: 'BELA',
    CoinName: 'Bela',
    FullName: 'Bela (BELA)',
  },
  CFLASH: {
    Name: 'CFLASH',
    Symbol: 'CFLASH',
    CoinName: 'Flash',
    FullName: 'Flash (CFLASH)',
  },
  BOLI: {
    Name: 'BOLI',
    Symbol: 'BOLI',
    CoinName: 'BolivarCoin',
    FullName: 'BolivarCoin (BOLI)',
  },
  CLUD: {
    Name: 'CLUD',
    Symbol: 'CLUD',
    CoinName: 'CludCoin',
    FullName: 'CludCoin (CLUD)',
  },
  FLYCOIN: {
    Name: 'FLYCOIN',
    Symbol: 'FLYCOIN',
    CoinName: 'FlyCoin',
    FullName: 'FlyCoin (FLYCOIN)',
  },
  HVCO: {
    Name: 'HVCO',
    Symbol: 'HVCO',
    CoinName: 'High Voltage Coin',
    FullName: 'High Voltage Coin (HVCO)',
  },
  GIZ: {
    Name: 'GIZ',
    Symbol: 'GIZ',
    CoinName: 'GIZMOcoin',
    FullName: 'GIZMOcoin (GIZ)',
  },
  GREXIT: {
    Name: 'GREXIT',
    Symbol: 'GREXIT',
    CoinName: 'GrexitCoin',
    FullName: 'GrexitCoin (GREXIT)',
  },
  CARBON: {
    Name: 'CARBON',
    Symbol: 'CARBON',
    CoinName: 'Carboncoin',
    FullName: 'Carboncoin (CARBON)',
  },
  DEUR: {
    Name: 'DEUR',
    Symbol: 'DEUR',
    CoinName: 'DigiEuro',
    FullName: 'DigiEuro (DEUR)',
  },
  TUR: {
    Name: 'TUR',
    Symbol: 'TUR',
    CoinName: 'Turron',
    FullName: 'Turron (TUR)',
  },
  LEMON: {
    Name: 'LEMON',
    Symbol: 'LEMON',
    CoinName: 'LemonCoin',
    FullName: 'LemonCoin (LEMON)',
  },
  DISK: {
    Name: 'DISK',
    Symbol: 'DISK',
    CoinName: 'Dark Lisk',
    FullName: 'Dark Lisk (DISK)',
  },
  NEVA: {
    Name: 'NEVA',
    Symbol: 'NEVA',
    CoinName: 'NevaCoin',
    FullName: 'NevaCoin (NEVA)',
  },
  CYT: {
    Name: 'CYT',
    Symbol: 'CYT',
    CoinName: 'Cryptokenz',
    FullName: 'Cryptokenz (CYT)',
  },
  FUZZ: {
    Name: 'FUZZ',
    Symbol: 'FUZZ',
    CoinName: 'Fuzzballs',
    FullName: 'Fuzzballs (FUZZ)',
  },
  NKC: {
    Name: 'NKC',
    Symbol: 'NKC',
    CoinName: 'Nukecoinz',
    FullName: 'Nukecoinz (NKC)',
  },
  SECRT: {
    Name: 'SECRT',
    Symbol: 'SECRT',
    CoinName: 'SecretCoin',
    FullName: 'SecretCoin (SECRT)',
  },
  XNX: {
    Name: 'XNX',
    Symbol: 'XNX',
    CoinName: 'XanaxCoin',
    FullName: 'XanaxCoin (XNX)',
  },
  STHR: {
    Name: 'STHR',
    Symbol: 'STHR',
    CoinName: 'Stakerush',
    FullName: 'Stakerush (STHR)',
  },
  BONES: {
    Name: 'BONES',
    Symbol: 'BONES',
    CoinName: 'BonesCoin',
    FullName: 'BonesCoin (BONES)',
  },
  WMC: {
    Name: 'WMC',
    Symbol: 'WMC',
    CoinName: 'WMCoin',
    FullName: 'WMCoin (WMC)',
  },
  GOTX: {
    Name: 'GOTX',
    Symbol: 'GOTX',
    CoinName: 'GothicCoin',
    FullName: 'GothicCoin (GOTX)',
  },
  FLVR: {
    Name: 'FLVR',
    Symbol: 'FLVR',
    CoinName: 'FlavorCoin',
    FullName: 'FlavorCoin (FLVR)',
  },
  SHREK: {
    Name: 'SHREK',
    Symbol: 'SHREK',
    CoinName: 'ShrekCoin',
    FullName: 'ShrekCoin (SHREK)',
  },
  RISEVISION: {
    Name: 'RISEVISION',
    Symbol: 'RISEVISION',
    CoinName: 'Rise',
    FullName: 'Rise (RISEVISION)',
  },
  REVE: {
    Name: 'REVE',
    Symbol: 'REVE',
    CoinName: 'Revenu',
    FullName: 'Revenu (REVE)',
  },
  PBC: {
    Name: 'PBC',
    Symbol: 'PBC',
    CoinName: 'PabyosiCoin',
    FullName: 'PabyosiCoin (PBC)',
  },
  OBSCURE: {
    Name: 'OBSCURE',
    Symbol: 'OBSCURE',
    CoinName: 'Obscurebay',
    FullName: 'Obscurebay (OBSCURE)',
  },
  EDUC: {
    Name: 'EDUC',
    Symbol: 'EDUC',
    CoinName: 'EducoinV',
    FullName: 'EducoinV (EDUC)',
  },
  CLINT: {
    Name: 'CLINT',
    Symbol: 'CLINT',
    CoinName: 'Clinton',
    FullName: 'Clinton (CLINT)',
  },
  CKC: {
    Name: 'CKC',
    Symbol: 'CKC',
    CoinName: 'Clockcoin',
    FullName: 'Clockcoin (CKC)',
  },
  VIP: {
    Name: 'VIP',
    Symbol: 'VIP',
    CoinName: 'VIP Tokens',
    FullName: 'VIP Tokens (VIP)',
  },
  NXE: {
    Name: 'NXE',
    Symbol: 'NXE',
    CoinName: 'NXEcoin',
    FullName: 'NXEcoin (NXE)',
  },
  ZOOM: {
    Name: 'ZOOM',
    Symbol: 'ZOOM',
    CoinName: 'ZoomCoin',
    FullName: 'ZoomCoin (ZOOM)',
  },
  DRACO: {
    Name: 'DRACO',
    Symbol: 'DRACO',
    CoinName: 'DT Token',
    FullName: 'DT Token (DRACO)',
  },
  YOVI: {
    Name: 'YOVI',
    Symbol: 'YOVI',
    CoinName: 'YobitVirtualCoin',
    FullName: 'YobitVirtualCoin (YOVI)',
  },
  ORLY: {
    Name: 'ORLY',
    Symbol: 'ORLY',
    CoinName: 'OrlyCoin',
    FullName: 'OrlyCoin (ORLY)',
  },
  KUBOS: {
    Name: 'KUBOS',
    Symbol: 'KUBOS',
    CoinName: 'KubosCoin',
    FullName: 'KubosCoin (KUBOS)',
  },
  INCP: {
    Name: 'INCP',
    Symbol: 'INCP',
    CoinName: 'InceptionCoin',
    FullName: 'InceptionCoin (INCP)',
  },
  SAK: {
    Name: 'SAK',
    Symbol: 'SAK',
    CoinName: 'SharkCoin',
    FullName: 'SharkCoin (SAK)',
  },
  EVIL: {
    Name: 'EVIL',
    Symbol: 'EVIL',
    CoinName: 'EvilCoin',
    FullName: 'EvilCoin (EVIL)',
  },
  OMA: {
    Name: 'OMA',
    Symbol: 'OMA',
    CoinName: 'OmegaCoin',
    FullName: 'OmegaCoin (OMA)',
  },
  MUE: {
    Name: 'MUE',
    Symbol: 'MUE',
    CoinName: 'MonetaryUnit',
    FullName: 'MonetaryUnit (MUE)',
  },
  BSEND: {
    Name: 'BSEND',
    Symbol: 'BSEND',
    CoinName: 'BitSend',
    FullName: 'BitSend (BSEND)',
  },
  DESTINY: {
    Name: 'DESTINY',
    Symbol: 'DESTINY',
    CoinName: 'Destiny',
    FullName: 'Destiny (DESTINY)',
  },
  BIT16: {
    Name: 'BIT16',
    Symbol: 'BIT16',
    CoinName: '16BitCoin',
    FullName: '16BitCoin (BIT16)',
  },
  PDC: {
    Name: 'PDC',
    Symbol: 'PDC',
    CoinName: 'Project Decorum',
    FullName: 'Project Decorum (PDC)',
  },
  CMTC: {
    Name: 'CMTC',
    Symbol: 'CMTC',
    CoinName: 'CometCoin',
    FullName: 'CometCoin (CMTC)',
  },
  SPACECOIN: {
    Name: 'SPACECOIN',
    Symbol: 'SPACECOIN',
    CoinName: 'SpaceCoin',
    FullName: 'SpaceCoin (SPACECOIN)',
  },
  REE: {
    Name: 'REE',
    Symbol: 'REE',
    CoinName: 'ReeCoin',
    FullName: 'ReeCoin (REE)',
  },
  LQD: {
    Name: 'LQD',
    Symbol: 'LQD',
    CoinName: 'Liquid',
    FullName: 'Liquid (LQD)',
  },
  MARV: {
    Name: 'MARV',
    Symbol: 'MARV',
    CoinName: 'Marvelous',
    FullName: 'Marvelous (MARV)',
  },
  VEC2: {
    Name: 'VEC2',
    Symbol: 'VEC2',
    CoinName: 'VectorCoin 2.0',
    FullName: 'VectorCoin 2.0 (VEC2)',
  },
  OMNI: {
    Name: 'OMNI',
    Symbol: 'OMNI',
    CoinName: 'Omni',
    FullName: 'Omni (OMNI)',
  },
  GSY: {
    Name: 'GSY',
    Symbol: 'GSY',
    CoinName: 'GenesysCoin',
    FullName: 'GenesysCoin (GSY)',
  },
  LIR: {
    Name: 'LIR',
    Symbol: 'LIR',
    CoinName: 'Let it Ride',
    FullName: 'Let it Ride (LIR)',
  },
  MMNXT: {
    Name: 'MMNXT',
    Symbol: 'MMNXT',
    CoinName: 'MMNXT',
    FullName: 'MMNXT (MMNXT)',
  },
  SCRPT: {
    Name: 'SCRPT',
    Symbol: 'SCRPT',
    CoinName: 'ScryptCoin',
    FullName: 'ScryptCoin (SCRPT)',
  },
  SPCIE: {
    Name: 'SPCIE',
    Symbol: 'SPCIE',
    CoinName: 'Specie',
    FullName: 'Specie (SPCIE)',
  },
  CJ: {
    Name: 'CJ',
    Symbol: 'CJ',
    CoinName: 'CryptoJacks',
    FullName: 'CryptoJacks (CJ)',
  },
  PUT: {
    Name: 'PUT',
    Symbol: 'PUT',
    CoinName: 'PutinCoin',
    FullName: 'PutinCoin (PUT)',
  },
  KRAK: {
    Name: 'KRAK',
    Symbol: 'KRAK',
    CoinName: 'Kraken',
    FullName: 'Kraken (KRAK)',
  },
  DLISK: {
    Name: 'DLISK',
    Symbol: 'DLISK',
    CoinName: 'Dlisk',
    FullName: 'Dlisk (DLISK)',
  },
  STRAX: {
    Name: 'STRAX',
    Symbol: 'STRAX',
    CoinName: 'Stratis',
    FullName: 'Stratis (STRAX)',
  },
  VOYA: {
    Name: 'VOYA',
    Symbol: 'VOYA',
    CoinName: 'Voyacoin',
    FullName: 'Voyacoin (VOYA)',
  },
  ENTER: {
    Name: 'ENTER',
    Symbol: 'ENTER',
    CoinName: 'EnterCoin',
    FullName: 'EnterCoin (ENTER)',
  },
  BM: {
    Name: 'BM',
    Symbol: 'BM',
    CoinName: 'BitMoon',
    FullName: 'BitMoon (BM)',
  },
  FRWC: {
    Name: 'FRWC',
    Symbol: 'FRWC',
    CoinName: 'Frankywillcoin',
    FullName: 'Frankywillcoin (FRWC)',
  },
  PSILOC: {
    Name: 'PSILOC',
    Symbol: 'PSILOC',
    CoinName: 'Psilocybin',
    FullName: 'Psilocybin (PSILOC)',
  },
  XTREME: {
    Name: 'XTREME',
    Symbol: 'XTREME',
    CoinName: 'ExtremeCoin',
    FullName: 'ExtremeCoin (XTREME)',
  },
  RUST: {
    Name: 'RUST',
    Symbol: 'RUST',
    CoinName: 'RustCoin',
    FullName: 'RustCoin (RUST)',
  },
  NZC: {
    Name: 'NZC',
    Symbol: 'NZC',
    CoinName: 'NewZealandCoin',
    FullName: 'NewZealandCoin (NZC)',
  },
  XAUR: {
    Name: 'XAUR',
    Symbol: 'XAUR',
    CoinName: 'Xaurum',
    FullName: 'Xaurum (XAUR)',
  },
  BFX: {
    Name: 'BFX',
    Symbol: 'BFX',
    CoinName: 'BitFinex Tokens',
    FullName: 'BitFinex Tokens (BFX)',
  },
  UNIQ: {
    Name: 'UNIQ',
    Symbol: 'UNIQ',
    CoinName: 'Uniqredit',
    FullName: 'Uniqredit (UNIQ)',
  },
  CRX: {
    Name: 'CRX',
    Symbol: 'CRX',
    CoinName: 'ChronosCoin',
    FullName: 'ChronosCoin (CRX)',
  },
  DCT: {
    Name: 'DCT',
    Symbol: 'DCT',
    CoinName: 'Decent',
    FullName: 'Decent (DCT)',
  },
  XPOKE: {
    Name: 'XPOKE',
    Symbol: 'XPOKE',
    CoinName: 'PokeChain',
    FullName: 'PokeChain (XPOKE)',
  },
  MUDRA: {
    Name: 'MUDRA',
    Symbol: 'MUDRA',
    CoinName: 'MudraCoin',
    FullName: 'MudraCoin (MUDRA)',
  },
  WARP: {
    Name: 'WARP',
    Symbol: 'WARP',
    CoinName: 'WarpCoin',
    FullName: 'WarpCoin (WARP)',
  },
  CNMT: {
    Name: 'CNMT',
    Symbol: 'CNMT',
    CoinName: 'Coinomat',
    FullName: 'Coinomat (CNMT)',
  },
  PIZZACOIN: {
    Name: 'PIZZACOIN',
    Symbol: 'PIZZACOIN',
    CoinName: 'PizzaCoin',
    FullName: 'PizzaCoin (PIZZACOIN)',
  },
  LUTETIUM: {
    Name: 'LUTETIUM',
    Symbol: 'LUTETIUM',
    CoinName: 'Lutetium Coin',
    FullName: 'Lutetium Coin (LUTETIUM)',
  },
  HEAT: {
    Name: 'HEAT',
    Symbol: 'HEAT',
    CoinName: 'Heat Ledger',
    FullName: 'Heat Ledger (HEAT)',
  },
  ICN: {
    Name: 'ICN',
    Symbol: 'ICN',
    CoinName: 'Iconomi',
    FullName: 'Iconomi (ICN)',
  },
  EXB: {
    Name: 'EXB',
    Symbol: 'EXB',
    CoinName: 'ExaByte (EXB)',
    FullName: 'ExaByte (EXB) (EXB)',
  },
  CDEX: {
    Name: 'CDEX',
    Symbol: 'CDEX',
    CoinName: 'Cryptodex',
    FullName: 'Cryptodex (CDEX)',
  },
  RBIT: {
    Name: 'RBIT',
    Symbol: 'RBIT',
    CoinName: 'ReturnBit',
    FullName: 'ReturnBit (RBIT)',
  },
  'DCS.': {
    Name: 'DCS',
    Symbol: 'DCS.',
    CoinName: 'deCLOUDs',
    FullName: 'deCLOUDs (DCS)',
  },
  GB: {
    Name: 'GB',
    Symbol: 'GB',
    CoinName: 'GoldBlocks',
    FullName: 'GoldBlocks (GB)',
  },
  SYNX: {
    Name: 'SYNX',
    Symbol: 'SYNX',
    CoinName: 'Syndicate',
    FullName: 'Syndicate (SYNX)',
  },
  EDC: {
    Name: 'EDC',
    Symbol: 'EDC',
    CoinName: 'EDC Blockchain',
    FullName: 'EDC Blockchain (EDC)',
  },
  JWL: {
    Name: 'JWL',
    Symbol: 'JWL',
    CoinName: 'Jewels',
    FullName: 'Jewels (JWL)',
  },
  WAY: {
    Name: 'WAY',
    Symbol: 'WAY',
    CoinName: 'WayCoin',
    FullName: 'WayCoin (WAY)',
  },
  TAB: {
    Name: 'TAB',
    Symbol: 'TAB',
    CoinName: 'MollyCoin',
    FullName: 'MollyCoin (TAB)',
  },
  TRIG: {
    Name: 'TRIG',
    Symbol: 'TRIG',
    CoinName: 'Trigger',
    FullName: 'Trigger (TRIG)',
  },
  BITUSD: {
    Name: 'BITUSD',
    Symbol: 'BITUSD',
    CoinName: 'bitUSD',
    FullName: 'bitUSD (BITUSD)',
  },
  ATMC: {
    Name: 'ATMC',
    Symbol: 'ATMC',
    CoinName: 'Autumncoin',
    FullName: 'Autumncoin (ATMC)',
  },
  STO: {
    Name: 'STO',
    Symbol: 'STO',
    CoinName: 'Save The Ocean',
    FullName: 'Save The Ocean (STO)',
  },
  CARTERCOIN: {
    Name: 'CARTERCOIN',
    Symbol: 'CARTERCOIN',
    CoinName: 'CarterCoin',
    FullName: 'CarterCoin (CARTERCOIN)',
  },
  TOT: {
    Name: 'TOT',
    Symbol: 'TOT',
    CoinName: 'TotCoin',
    FullName: 'TotCoin (TOT)',
  },
  BTD: {
    Name: 'BTD',
    Symbol: 'BTD',
    CoinName: 'Bitcloud',
    FullName: 'Bitcloud (BTD)',
  },
  BOTS: {
    Name: 'BOTS',
    Symbol: 'BOTS',
    CoinName: 'ArkDAO',
    FullName: 'ArkDAO (BOTS)',
  },
  MDC: {
    Name: 'MDC',
    Symbol: 'MDC',
    CoinName: 'MedicCoin',
    FullName: 'MedicCoin (MDC)',
  },
  FTP: {
    Name: 'FTP',
    Symbol: 'FTP',
    CoinName: 'FuturePoints',
    FullName: 'FuturePoints (FTP)',
  },
  ZET2: {
    Name: 'ZET2',
    Symbol: 'ZET2',
    CoinName: 'Zeta2Coin',
    FullName: 'Zeta2Coin (ZET2)',
  },
  CVNC: {
    Name: 'CVNC',
    Symbol: 'CVNC',
    CoinName: 'CovenCoin',
    FullName: 'CovenCoin (CVNC)',
  },
  KRB: {
    Name: 'KRB',
    Symbol: 'KRB',
    CoinName: 'Karbo',
    FullName: 'Karbo (KRB)',
  },
  TELL: {
    Name: 'TELL',
    Symbol: 'TELL',
    CoinName: 'Tellurion',
    FullName: 'Tellurion (TELL)',
  },
  ENE: {
    Name: 'ENE',
    Symbol: 'ENE',
    CoinName: 'EneCoin',
    FullName: 'EneCoin (ENE)',
  },
  TDFB: {
    Name: 'TDFB',
    Symbol: 'TDFB',
    CoinName: 'TDFB',
    FullName: 'TDFB (TDFB)',
  },
  BLOCKPAY: {
    Name: 'BLOCKPAY',
    Symbol: 'BLOCKPAY',
    CoinName: 'BlockPay',
    FullName: 'BlockPay (BLOCKPAY)',
  },
  BXT: {
    Name: 'BXT',
    Symbol: 'BXT',
    CoinName: 'BitTokens',
    FullName: 'BitTokens (BXT)',
  },
  ZYD: {
    Name: 'ZYD',
    Symbol: 'ZYD',
    CoinName: 'ZayedCoin',
    FullName: 'ZayedCoin (ZYD)',
  },
  MUSTANGC: {
    Name: 'MUSTANGC',
    Symbol: 'MUSTANGC',
    CoinName: 'MustangCoin',
    FullName: 'MustangCoin (MUSTANGC)',
  },
  GOON: {
    Name: 'GOON',
    Symbol: 'GOON',
    CoinName: 'Goonies',
    FullName: 'Goonies (GOON)',
  },
  VLT: {
    Name: 'VLT',
    Symbol: 'VLT',
    CoinName: 'Veltor',
    FullName: 'Veltor (VLT)',
  },
  ZNE: {
    Name: 'ZNE',
    Symbol: 'ZNE',
    CoinName: 'ZoneCoin',
    FullName: 'ZoneCoin (ZNE)',
  },
  DCK: {
    Name: 'DCK',
    Symbol: 'DCK',
    CoinName: 'DickCoin',
    FullName: 'DickCoin (DCK)',
  },
  COVAL: {
    Name: 'COVAL',
    Symbol: 'COVAL',
    CoinName: 'Circuits of Value',
    FullName: 'Circuits of Value (COVAL)',
  },
  DGDC: {
    Name: 'DGDC',
    Symbol: 'DGDC',
    CoinName: 'DarkGold',
    FullName: 'DarkGold (DGDC)',
  },
  TODAY: {
    Name: 'TODAY',
    Symbol: 'TODAY',
    CoinName: 'TodayCoin',
    FullName: 'TodayCoin (TODAY)',
  },
  VRM: {
    Name: 'VRM',
    Symbol: 'VRM',
    CoinName: 'Verium',
    FullName: 'Verium (VRM)',
  },
  ROOT: {
    Name: 'ROOT',
    Symbol: 'ROOT',
    CoinName: 'RootCoin',
    FullName: 'RootCoin (ROOT)',
  },
  '1ST': {
    Name: '1ST',
    Symbol: '1ST',
    CoinName: 'FirstBlood',
    FullName: 'FirstBlood (1ST)',
  },
  GPL: {
    Name: 'GPL',
    Symbol: 'GPL',
    CoinName: 'Gold Pressed Latinum',
    FullName: 'Gold Pressed Latinum (GPL)',
  },
  DOPE: {
    Name: 'DOPE',
    Symbol: 'DOPE',
    CoinName: 'DopeCoin',
    FullName: 'DopeCoin (DOPE)',
  },
  B3: {
    Name: 'B3',
    Symbol: 'B3',
    CoinName: 'B3 Coin',
    FullName: 'B3 Coin (B3)',
  },
  PIO: {
    Name: 'PIO',
    Symbol: 'PIO',
    CoinName: 'Pioneershares',
    FullName: 'Pioneershares (PIO)',
  },
  PROUD: {
    Name: 'PROUD',
    Symbol: 'PROUD',
    CoinName: 'PROUD Money',
    FullName: 'PROUD Money (PROUD)',
  },
  SMSR: {
    Name: 'SMSR',
    Symbol: 'SMSR',
    CoinName: 'Samsara Coin',
    FullName: 'Samsara Coin (SMSR)',
  },
  UBIQ: {
    Name: 'UBIQ',
    Symbol: 'UBIQ',
    CoinName: 'Ubiqoin',
    FullName: 'Ubiqoin (UBIQ)',
  },
  ARM: {
    Name: 'ARM',
    Symbol: 'ARM',
    CoinName: 'Armory Coin',
    FullName: 'Armory Coin (ARM)',
  },
  ERB: {
    Name: 'ERB',
    Symbol: 'ERB',
    CoinName: 'ERBCoin',
    FullName: 'ERBCoin (ERB)',
  },
  LAZ: {
    Name: 'LAZ',
    Symbol: 'LAZ',
    CoinName: 'Lazarus',
    FullName: 'Lazarus (LAZ)',
  },
  FONZ: {
    Name: 'FONZ',
    Symbol: 'FONZ',
    CoinName: 'FonzieCoin',
    FullName: 'FonzieCoin (FONZ)',
  },
  BTCR: {
    Name: 'BTCR',
    Symbol: 'BTCR',
    CoinName: 'BitCurrency',
    FullName: 'BitCurrency (BTCR)',
  },
  FCTC: {
    Name: 'FCTC',
    Symbol: 'FCTC',
    CoinName: 'FaucetCoin',
    FullName: 'FaucetCoin (FCTC)',
  },
  SANDG: {
    Name: 'SANDG',
    Symbol: 'SANDG',
    CoinName: 'Save and Gain',
    FullName: 'Save and Gain (SANDG)',
  },
  PUNK: {
    Name: 'PUNK',
    Symbol: 'PUNK',
    CoinName: 'SteamPunk',
    FullName: 'SteamPunk (PUNK)',
  },
  MOOND: {
    Name: 'MOOND',
    Symbol: 'MOOND',
    CoinName: 'Dark Moon',
    FullName: 'Dark Moon (MOOND)',
  },
  SEN: {
    Name: 'SEN',
    Symbol: 'SEN',
    CoinName: 'Sentaro',
    FullName: 'Sentaro (SEN)',
  },
  SCN: {
    Name: 'SCN',
    Symbol: 'SCN',
    CoinName: 'Swiscoin',
    FullName: 'Swiscoin (SCN)',
  },
  LTH: {
    Name: 'LTH',
    Symbol: 'LTH',
    CoinName: 'Lathaan',
    FullName: 'Lathaan (LTH)',
  },
  BRONZ: {
    Name: 'BRONZ',
    Symbol: 'BRONZ',
    CoinName: 'BitBronze',
    FullName: 'BitBronze (BRONZ)',
  },
  BUZZ: {
    Name: 'BUZZ',
    Symbol: 'BUZZ',
    CoinName: 'BuzzCoin',
    FullName: 'BuzzCoin (BUZZ)',
  },
  MINDGENE: {
    Name: 'MINDGENE',
    Symbol: 'MINDGENE',
    CoinName: 'Mind Gene',
    FullName: 'Mind Gene (MINDGENE)',
  },
  PSI: {
    Name: 'PSI',
    Symbol: 'PSI',
    CoinName: 'PSIcoin',
    FullName: 'PSIcoin (PSI)',
  },
  XPO: {
    Name: 'XPO',
    Symbol: 'XPO',
    CoinName: 'Opair',
    FullName: 'Opair (XPO)',
  },
  NLC: {
    Name: 'NLC',
    Symbol: 'NLC',
    CoinName: 'NoLimitCoin',
    FullName: 'NoLimitCoin (NLC)',
  },
  PESOBIT: {
    Name: 'PESOBIT',
    Symbol: 'PESOBIT',
    CoinName: 'PesoBit',
    FullName: 'PesoBit (PESOBIT)',
  },
  XBTS: {
    Name: 'XBTS',
    Symbol: 'XBTS',
    CoinName: 'Beats',
    FullName: 'Beats (XBTS)',
  },
  FITC: {
    Name: 'FITC',
    Symbol: 'FITC',
    CoinName: 'Fitcoin',
    FullName: 'Fitcoin (FITC)',
  },
  PINKX: {
    Name: 'PINKX',
    Symbol: 'PINKX',
    CoinName: 'PantherCoin',
    FullName: 'PantherCoin (PINKX)',
  },
  FIRE: {
    Name: 'FIRE',
    Symbol: 'FIRE',
    CoinName: 'FireCoin',
    FullName: 'FireCoin (FIRE)',
  },
  UNF: {
    Name: 'UNF',
    Symbol: 'UNF',
    CoinName: 'Unfed Coin',
    FullName: 'Unfed Coin (UNF)',
  },
  SPORT: {
    Name: 'SPORT',
    Symbol: 'SPORT',
    CoinName: 'SportsCoin',
    FullName: 'SportsCoin (SPORT)',
  },
  PPY: {
    Name: 'PPY',
    Symbol: 'PPY',
    CoinName: 'Peerplays',
    FullName: 'Peerplays (PPY)',
  },
  NTC: {
    Name: 'NTC',
    Symbol: 'NTC',
    CoinName: 'NineElevenTruthCoin',
    FullName: 'NineElevenTruthCoin (NTC)',
  },
  EGO: {
    Name: 'EGO',
    Symbol: 'EGO',
    CoinName: 'EGOcoin',
    FullName: 'EGOcoin (EGO)',
  },
  RCOIN: {
    Name: 'RCOIN',
    Symbol: 'RCOIN',
    CoinName: 'RCoin',
    FullName: 'RCoin (RCOIN)',
  },
  X2: {
    Name: 'X2',
    Symbol: 'X2',
    CoinName: 'X2Coin',
    FullName: 'X2Coin (X2)',
  },
  MYCELIUM: {
    Name: 'MYCELIUM',
    Symbol: 'MYCELIUM',
    CoinName: 'Mycelium Token',
    FullName: 'Mycelium Token (MYCELIUM)',
  },
  TIA: {
    Name: 'TIA',
    Symbol: 'TIA',
    CoinName: 'Tianhe',
    FullName: 'Tianhe (TIA)',
  },
  GBRC: {
    Name: 'GBRC',
    Symbol: 'GBRC',
    CoinName: 'GBR Coin',
    FullName: 'GBR Coin (GBRC)',
  },
  UPCOIN: {
    Name: 'UPCOIN',
    Symbol: 'UPCOIN',
    CoinName: 'UPcoin',
    FullName: 'UPcoin (UPCOIN)',
  },
  HALLO: {
    Name: 'HALLO',
    Symbol: 'HALLO',
    CoinName: 'Halloween Coin',
    FullName: 'Halloween Coin (HALLO)',
  },
  BBCC: {
    Name: 'BBCC',
    Symbol: 'BBCC',
    CoinName: 'BaseballCardCoin',
    FullName: 'BaseballCardCoin (BBCC)',
  },
  EMIGR: {
    Name: 'EMIGR',
    Symbol: 'EMIGR',
    CoinName: 'EmiratesGoldCoin',
    FullName: 'EmiratesGoldCoin (EMIGR)',
  },
  BIGHAN: {
    Name: 'BIGHAN',
    Symbol: 'BIGHAN',
    CoinName: 'BighanCoin',
    FullName: 'BighanCoin (BIGHAN)',
  },
  CRAFTCOIN: {
    Name: 'CRAFTCOIN',
    Symbol: 'CRAFTCOIN',
    CoinName: 'Craftcoin',
    FullName: 'Craftcoin (CRAFTCOIN)',
  },
  INVIC: {
    Name: 'INVIC',
    Symbol: 'INVIC',
    CoinName: 'Invictus',
    FullName: 'Invictus (INVIC)',
  },
  OLYMP: {
    Name: 'OLYMP',
    Symbol: 'OLYMP',
    CoinName: 'OlympCoin',
    FullName: 'OlympCoin (OLYMP)',
  },
  DPAY: {
    Name: 'DPAY',
    Symbol: 'DPAY',
    CoinName: 'DelightPay',
    FullName: 'DelightPay (DPAY)',
  },
  HKG: {
    Name: 'HKG',
    Symbol: 'HKG',
    CoinName: 'Hacker Gold',
    FullName: 'Hacker Gold (HKG)',
  },
  ANTC: {
    Name: 'ANTC',
    Symbol: 'ANTC',
    CoinName: 'AntiLitecoin',
    FullName: 'AntiLitecoin (ANTC)',
  },
  JOBS: {
    Name: 'JOBS',
    Symbol: 'JOBS',
    CoinName: 'JobsCoin',
    FullName: 'JobsCoin (JOBS)',
  },
  DGORE: {
    Name: 'DGORE',
    Symbol: 'DGORE',
    CoinName: 'DogeGoreCoin',
    FullName: 'DogeGoreCoin (DGORE)',
  },
  TETRA: {
    Name: 'TETRA',
    Symbol: 'TETRA',
    CoinName: 'Tetra',
    FullName: 'Tetra (TETRA)',
  },
  RMS: {
    Name: 'RMS',
    Symbol: 'RMS',
    CoinName: 'Resumeo Shares',
    FullName: 'Resumeo Shares (RMS)',
  },
  FJC: {
    Name: 'FJC',
    Symbol: 'FJC',
    CoinName: 'FujiCoin',
    FullName: 'FujiCoin (FJC)',
  },
  VAPOR: {
    Name: 'VAPOR',
    Symbol: 'VAPOR',
    CoinName: 'Vaporcoin',
    FullName: 'Vaporcoin (VAPOR)',
  },
  SDP: {
    Name: 'SDP',
    Symbol: 'SDP',
    CoinName: 'SydPakCoin',
    FullName: 'SydPakCoin (SDP)',
  },
  RRT: {
    Name: 'RRT',
    Symbol: 'RRT',
    CoinName: 'Recovery Right Tokens',
    FullName: 'Recovery Right Tokens (RRT)',
  },
  PREM: {
    Name: 'PREM',
    Symbol: 'PREM',
    CoinName: 'Premium',
    FullName: 'Premium (PREM)',
  },
  CALC: {
    Name: 'CALC',
    Symbol: 'CALC',
    CoinName: 'CaliphCoin',
    FullName: 'CaliphCoin (CALC)',
  },
  LEA: {
    Name: 'LEA',
    Symbol: 'LEA',
    CoinName: 'LeaCoin',
    FullName: 'LeaCoin (LEA)',
  },
  CF: {
    Name: 'CF',
    Symbol: 'CF',
    CoinName: 'Californium',
    FullName: 'Californium (CF)',
  },
  CRNK: {
    Name: 'CRNK',
    Symbol: 'CRNK',
    CoinName: 'CrankCoin',
    FullName: 'CrankCoin (CRNK)',
  },
  COFFEECOIN: {
    Name: 'COFFEECOIN',
    Symbol: 'COFFEECOIN',
    CoinName: 'CoffeeCoin',
    FullName: 'CoffeeCoin (COFFEECOIN)',
  },
  VTY: {
    Name: 'VTY',
    Symbol: 'VTY',
    CoinName: 'Victoriouscoin',
    FullName: 'Victoriouscoin (VTY)',
  },
  BS: {
    Name: 'BS',
    Symbol: 'BS',
    CoinName: 'BlackShadowCoin',
    FullName: 'BlackShadowCoin (BS)',
  },
  JIF: {
    Name: 'JIF',
    Symbol: 'JIF',
    CoinName: 'JiffyCoin',
    FullName: 'JiffyCoin (JIF)',
  },
  CRAB: {
    Name: 'CRAB',
    Symbol: 'CRAB',
    CoinName: 'CrabCoin',
    FullName: 'CrabCoin (CRAB)',
  },
  HILL: {
    Name: 'HILL',
    Symbol: 'HILL',
    CoinName: 'President Clinton',
    FullName: 'President Clinton (HILL)',
  },
  MONETA: {
    Name: 'MONETA',
    Symbol: 'MONETA',
    CoinName: 'Moneta',
    FullName: 'Moneta (MONETA)',
  },
  ECLIP: {
    Name: 'ECLIP',
    Symbol: 'ECLIP',
    CoinName: 'Eclipse',
    FullName: 'Eclipse (ECLIP)',
  },
  RUBIT: {
    Name: 'RUBIT',
    Symbol: 'RUBIT',
    CoinName: 'Rublebit',
    FullName: 'Rublebit (RUBIT)',
  },
  HCC: {
    Name: 'HCC',
    Symbol: 'HCC',
    CoinName: 'HappyCreatorCoin',
    FullName: 'HappyCreatorCoin (HCC)',
  },
  BRAIN: {
    Name: 'BRAIN',
    Symbol: 'BRAIN',
    CoinName: 'BrainCoin',
    FullName: 'BrainCoin (BRAIN)',
  },
  VERTEX: {
    Name: 'VERTEX',
    Symbol: 'VERTEX',
    CoinName: 'Vertex',
    FullName: 'Vertex (VERTEX)',
  },
  KRC: {
    Name: 'KRC',
    Symbol: 'KRC',
    CoinName: 'KRCoin',
    FullName: 'KRCoin (KRC)',
  },
  ROYAL: {
    Name: 'ROYAL',
    Symbol: 'ROYAL',
    CoinName: 'RoyalCoin',
    FullName: 'RoyalCoin (ROYAL)',
  },
  LFC: {
    Name: 'LFC',
    Symbol: 'LFC',
    CoinName: 'BigLifeCoin',
    FullName: 'BigLifeCoin (LFC)',
  },
  ZUR: {
    Name: 'ZUR',
    Symbol: 'ZUR',
    CoinName: 'Zurcoin',
    FullName: 'Zurcoin (ZUR)',
  },
  NUBIS: {
    Name: 'NUBIS',
    Symbol: 'NUBIS',
    CoinName: 'NubisCoin',
    FullName: 'NubisCoin (NUBIS)',
  },
  TENNET: {
    Name: 'TENNET',
    Symbol: 'TENNET',
    CoinName: 'Tennet',
    FullName: 'Tennet (TENNET)',
  },
  PEC: {
    Name: 'PEC',
    Symbol: 'PEC',
    CoinName: 'PeaceCoin',
    FullName: 'PeaceCoin (PEC)',
  },
  '32BIT': {
    Name: '32BIT',
    Symbol: '32BIT',
    CoinName: '32Bitcoin',
    FullName: '32Bitcoin (32BIT)',
  },
  GNJ: {
    Name: 'GNJ',
    Symbol: 'GNJ',
    CoinName: 'GanjaCoin V2',
    FullName: 'GanjaCoin V2 (GNJ)',
  },
  TEAM: {
    Name: 'TEAM',
    Symbol: 'TEAM',
    CoinName: 'TeamUP',
    FullName: 'TeamUP (TEAM)',
  },
  SCT: {
    Name: 'SCT',
    Symbol: 'SCT',
    CoinName: 'ScryptToken',
    FullName: 'ScryptToken (SCT)',
  },
  LANA: {
    Name: 'LANA',
    Symbol: 'LANA',
    CoinName: 'LanaCoin',
    FullName: 'LanaCoin (LANA)',
  },
  ELE: {
    Name: 'ELE',
    Symbol: 'ELE',
    CoinName: 'Elementrem',
    FullName: 'Elementrem (ELE)',
  },
  GCC: {
    Name: 'GCC',
    Symbol: 'GCC',
    CoinName: 'GuccioneCoin',
    FullName: 'GuccioneCoin (GCC)',
  },
  AND: {
    Name: 'AND',
    Symbol: 'AND',
    CoinName: 'AndromedaCoin',
    FullName: 'AndromedaCoin (AND)',
  },
  EQUAL: {
    Name: 'EQUAL',
    Symbol: 'EQUAL',
    CoinName: 'EqualCoin',
    FullName: 'EqualCoin (EQUAL)',
  },
  SWEET: {
    Name: 'SWEET',
    Symbol: 'SWEET',
    CoinName: 'SweetStake',
    FullName: 'SweetStake (SWEET)',
  },
  '2BACCO': {
    Name: '2BACCO',
    Symbol: '2BACCO',
    CoinName: '2BACCO Coin',
    FullName: '2BACCO Coin (2BACCO)',
  },
  DKC: {
    Name: 'DKC',
    Symbol: 'DKC',
    CoinName: 'DarkKnightCoin',
    FullName: 'DarkKnightCoin (DKC)',
  },
  CHOOF: {
    Name: 'CHOOF',
    Symbol: 'CHOOF',
    CoinName: 'ChoofCoin',
    FullName: 'ChoofCoin (CHOOF)',
  },
  CSH: {
    Name: 'CSH',
    Symbol: 'CSH',
    CoinName: 'CashOut',
    FullName: 'CashOut (CSH)',
  },
  ZCL: {
    Name: 'ZCL',
    Symbol: 'ZCL',
    CoinName: 'ZClassic',
    FullName: 'ZClassic (ZCL)',
  },
  RYCN: {
    Name: 'RYCN',
    Symbol: 'RYCN',
    CoinName: 'RoyalCoin 2.0',
    FullName: 'RoyalCoin 2.0 (RYCN)',
  },
  PCS: {
    Name: 'PCS',
    Symbol: 'PCS',
    CoinName: 'Pabyosi Coin',
    FullName: 'Pabyosi Coin (PCS)',
  },
  NBIT: {
    Name: 'NBIT',
    Symbol: 'NBIT',
    CoinName: 'NetBit',
    FullName: 'NetBit (NBIT)',
  },
  WINE: {
    Name: 'WINE',
    Symbol: 'WINE',
    CoinName: 'WineCoin',
    FullName: 'WineCoin (WINE)',
  },
  DARCRUS: {
    Name: 'DARCRUS',
    Symbol: 'DARCRUS',
    CoinName: 'Darcrus',
    FullName: 'Darcrus (DARCRUS)',
  },
  IFLT: {
    Name: 'IFLT',
    Symbol: 'IFLT',
    CoinName: 'InflationCoin',
    FullName: 'InflationCoin (IFLT)',
  },
  ZECD: {
    Name: 'ZECD',
    Symbol: 'ZECD',
    CoinName: 'ZCashDarkCoin',
    FullName: 'ZCashDarkCoin (ZECD)',
  },
  ZXT: {
    Name: 'ZXT',
    Symbol: 'ZXT',
    CoinName: 'Zcrypt',
    FullName: 'Zcrypt (ZXT)',
  },
  WASH: {
    Name: 'WASH',
    Symbol: 'WASH',
    CoinName: 'WashingtonCoin',
    FullName: 'WashingtonCoin (WASH)',
  },
  TESLA: {
    Name: 'TESLA',
    Symbol: 'TESLA',
    CoinName: 'TeslaCoilCoin',
    FullName: 'TeslaCoilCoin (TESLA)',
  },
  LUCKYB: {
    Name: 'LUCKYB',
    Symbol: 'LUCKYB',
    CoinName: 'LuckyBlocks',
    FullName: 'LuckyBlocks (LUCKYB)',
  },
  VSL: {
    Name: 'VSL',
    Symbol: 'VSL',
    CoinName: 'vSlice',
    FullName: 'vSlice (VSL)',
  },
  TPG: {
    Name: 'TPG',
    Symbol: 'TPG',
    CoinName: 'Troll Payment',
    FullName: 'Troll Payment (TPG)',
  },
  MIDN: {
    Name: 'MIDN',
    Symbol: 'MIDN',
    CoinName: 'Midnight',
    FullName: 'Midnight (MIDN)',
  },
  CBD: {
    Name: 'CBD',
    Symbol: 'CBD',
    CoinName: 'CBD Crystals',
    FullName: 'CBD Crystals (CBD)',
  },
  POSEX: {
    Name: 'POSEX',
    Symbol: 'POSEX',
    CoinName: 'PosEx',
    FullName: 'PosEx (POSEX)',
  },
  INSANE: {
    Name: 'INSANE',
    Symbol: 'INSANE',
    CoinName: 'InsaneCoin',
    FullName: 'InsaneCoin (INSANE)',
  },
  PENC: {
    Name: 'PENC',
    Symbol: 'PENC',
    CoinName: 'PenCoin',
    FullName: 'PenCoin (PENC)',
  },
  BASH: {
    Name: 'BASH',
    Symbol: 'BASH',
    CoinName: 'LuckChain',
    FullName: 'LuckChain (BASH)',
  },
  FAMEC: {
    Name: 'FAMEC',
    Symbol: 'FAMEC',
    CoinName: 'FameCoin',
    FullName: 'FameCoin (FAMEC)',
  },
  LIV: {
    Name: 'LIV',
    Symbol: 'LIV',
    CoinName: 'LiviaCoin',
    FullName: 'LiviaCoin (LIV)',
  },
  SP: {
    Name: 'SP',
    Symbol: 'SP',
    CoinName: 'Sex Pistols',
    FullName: 'Sex Pistols (SP)',
  },
  MEGA: {
    Name: 'MEGA',
    Symbol: 'MEGA',
    CoinName: 'MegaFlash',
    FullName: 'MegaFlash (MEGA)',
  },
  ALC: {
    Name: 'ALC',
    Symbol: 'ALC',
    CoinName: 'Arab League Coin',
    FullName: 'Arab League Coin (ALC)',
  },
  DOGETH: {
    Name: 'DOGETH',
    Symbol: 'DOGETH',
    CoinName: 'EtherDoge',
    FullName: 'EtherDoge (DOGETH)',
  },
  KLC: {
    Name: 'KLC',
    Symbol: 'KLC',
    CoinName: 'KiloCoin',
    FullName: 'KiloCoin (KLC)',
  },
  HUSH: {
    Name: 'HUSH',
    Symbol: 'HUSH',
    CoinName: 'Hush',
    FullName: 'Hush (HUSH)',
  },
  BTLC: {
    Name: 'BTLC',
    Symbol: 'BTLC',
    CoinName: 'BitLuckCoin',
    FullName: 'BitLuckCoin (BTLC)',
  },
  DRM8: {
    Name: 'DRM8',
    Symbol: 'DRM8',
    CoinName: 'Dream8Coin',
    FullName: 'Dream8Coin (DRM8)',
  },
  FIST: {
    Name: 'FIST',
    Symbol: 'FIST',
    CoinName: 'FistBump',
    FullName: 'FistBump (FIST)',
  },
  EBZ: {
    Name: 'EBZ',
    Symbol: 'EBZ',
    CoinName: 'Ebitz',
    FullName: 'Ebitz (EBZ)',
  },
  DRS: {
    Name: 'DRS',
    Symbol: 'DRS',
    CoinName: 'Digital Rupees',
    FullName: 'Digital Rupees (DRS)',
  },
  FGZ: {
    Name: 'FGZ',
    Symbol: 'FGZ',
    CoinName: 'Free Game Zone',
    FullName: 'Free Game Zone (FGZ)',
  },
  BOSONC: {
    Name: 'BOSONC',
    Symbol: 'BOSONC',
    CoinName: 'BosonCoin',
    FullName: 'BosonCoin (BOSONC)',
  },
  ATX: {
    Name: 'ATX',
    Symbol: 'ATX',
    CoinName: 'ArtexCoin',
    FullName: 'ArtexCoin (ATX)',
  },
  PNC: {
    Name: 'PNC',
    Symbol: 'PNC',
    CoinName: 'PlatiniumCoin',
    FullName: 'PlatiniumCoin (PNC)',
  },
  BRDD: {
    Name: 'BRDD',
    Symbol: 'BRDD',
    CoinName: 'BeardDollars',
    FullName: 'BeardDollars (BRDD)',
  },
  TIME: {
    Name: 'TIME',
    Symbol: 'TIME',
    CoinName: 'Chrono.tech',
    FullName: 'Chrono.tech (TIME)',
  },
  BIPC: {
    Name: 'BIPC',
    Symbol: 'BIPC',
    CoinName: 'BipCoin',
    FullName: 'BipCoin (BIPC)',
  },
  EMBER: {
    Name: 'EMBER',
    Symbol: 'EMBER',
    CoinName: 'EmberCoin',
    FullName: 'EmberCoin (EMBER)',
  },
  BTTF: {
    Name: 'BTTF',
    Symbol: 'BTTF',
    CoinName: 'Coin to the Future',
    FullName: 'Coin to the Future (BTTF)',
  },
  DLR: {
    Name: 'DLR',
    Symbol: 'DLR',
    CoinName: 'DollarOnline',
    FullName: 'DollarOnline (DLR)',
  },
  CSMIC: {
    Name: 'CSMIC',
    Symbol: 'CSMIC',
    CoinName: 'Cosmic',
    FullName: 'Cosmic (CSMIC)',
  },
  JIO: {
    Name: 'JIO',
    Symbol: 'JIO',
    CoinName: 'JIO Token',
    FullName: 'JIO Token (JIO)',
  },
  IW: {
    Name: 'IW',
    Symbol: 'IW',
    CoinName: 'iWallet',
    FullName: 'iWallet (IW)',
  },
  JNS: {
    Name: 'JNS',
    Symbol: 'JNS',
    CoinName: 'Janus',
    FullName: 'Janus (JNS)',
  },
  TRICK: {
    Name: 'TRICK',
    Symbol: 'TRICK',
    CoinName: 'TrickyCoin',
    FullName: 'TrickyCoin (TRICK)',
  },
  DCRE: {
    Name: 'DCRE',
    Symbol: 'DCRE',
    CoinName: 'DeltaCredits',
    FullName: 'DeltaCredits (DCRE)',
  },
  FRE: {
    Name: 'FRE',
    Symbol: 'FRE',
    CoinName: 'FreeCoin',
    FullName: 'FreeCoin (FRE)',
  },
  NPC: {
    Name: 'NPC',
    Symbol: 'NPC',
    CoinName: 'NPCcoin',
    FullName: 'NPCcoin (NPC)',
  },
  PLNC: {
    Name: 'PLNC',
    Symbol: 'PLNC',
    CoinName: 'PLNCoin',
    FullName: 'PLNCoin (PLNC)',
  },
  DGMS: {
    Name: 'DGMS',
    Symbol: 'DGMS',
    CoinName: 'Digigems',
    FullName: 'Digigems (DGMS)',
  },
  ARCO: {
    Name: 'ARCO',
    Symbol: 'ARCO',
    CoinName: 'AquariusCoin',
    FullName: 'AquariusCoin (ARCO)',
  },
  KURT: {
    Name: 'KURT',
    Symbol: 'KURT',
    CoinName: 'Kurrent',
    FullName: 'Kurrent (KURT)',
  },
  XCRE: {
    Name: 'XCRE',
    Symbol: 'XCRE',
    CoinName: 'Creatio',
    FullName: 'Creatio (XCRE)',
  },
  ENT: {
    Name: 'ENT',
    Symbol: 'ENT',
    CoinName: 'Eternity',
    FullName: 'Eternity (ENT)',
  },
  UR: {
    Name: 'UR',
    Symbol: 'UR',
    CoinName: 'UR',
    FullName: 'UR (UR)',
  },
  MTLM3: {
    Name: 'MTLM3',
    Symbol: 'MTLM3',
    CoinName: 'Metal Music v3',
    FullName: 'Metal Music v3 (MTLM3)',
  },
  EUC: {
    Name: 'EUC',
    Symbol: 'EUC',
    CoinName: 'Eurocoin',
    FullName: 'Eurocoin (EUC)',
  },
  CCXC: {
    Name: 'CCXC',
    Symbol: 'CCXC',
    CoinName: 'CoolinDarkCoin',
    FullName: 'CoolinDarkCoin (CCXC)',
  },
  BCF: {
    Name: 'BCF',
    Symbol: 'BCF',
    CoinName: 'BitcoinFast',
    FullName: 'BitcoinFast (BCF)',
  },
  SEEDS: {
    Name: 'SEEDS',
    Symbol: 'SEEDS',
    CoinName: 'SeedShares',
    FullName: 'SeedShares (SEEDS)',
  },
  TKS: {
    Name: 'TKS',
    Symbol: 'TKS',
    CoinName: 'Tokes',
    FullName: 'Tokes (TKS)',
  },
  BCCOIN: {
    Name: 'BCCOIN',
    Symbol: 'BCCOIN',
    CoinName: 'BitConnect Coin',
    FullName: 'BitConnect Coin (BCCOIN)',
  },
  SHORTY: {
    Name: 'SHORTY',
    Symbol: 'SHORTY',
    CoinName: 'ShortyCoin',
    FullName: 'ShortyCoin (SHORTY)',
  },
  PCM: {
    Name: 'PCM',
    Symbol: 'PCM',
    CoinName: 'Procom',
    FullName: 'Procom (PCM)',
  },
  KC: {
    Name: 'KC',
    Symbol: 'KC',
    CoinName: 'Kernalcoin',
    FullName: 'Kernalcoin (KC)',
  },
  CORAL: {
    Name: 'CORAL',
    Symbol: 'CORAL',
    CoinName: 'CoralPay',
    FullName: 'CoralPay (CORAL)',
  },
  BamitCoin: {
    Name: 'BAM',
    Symbol: 'BamitCoin',
    CoinName: 'BAM',
    FullName: 'BAM (BAM)',
  },
  NXC: {
    Name: 'NXC',
    Symbol: 'NXC',
    CoinName: 'Nexium',
    FullName: 'Nexium (NXC)',
  },
  MONEY: {
    Name: 'MONEY',
    Symbol: 'MONEY',
    CoinName: 'MoneyCoin',
    FullName: 'MoneyCoin (MONEY)',
  },
  BSTAR: {
    Name: 'BSTAR',
    Symbol: 'BSTAR',
    CoinName: 'Blackstar',
    FullName: 'Blackstar (BSTAR)',
  },
  HSP: {
    Name: 'HSP',
    Symbol: 'HSP',
    CoinName: 'Horse Power',
    FullName: 'Horse Power (HSP)',
  },
  HZT: {
    Name: 'HZT',
    Symbol: 'HZT',
    CoinName: 'HazMatCoin',
    FullName: 'HazMatCoin (HZT)',
  },
  CRSP: {
    Name: 'CRSP',
    Symbol: 'CRSP',
    CoinName: 'CryptoSpots',
    FullName: 'CryptoSpots (CRSP)',
  },
  XSPT: {
    Name: 'XSPT',
    Symbol: 'XSPT',
    CoinName: 'PoolStamp',
    FullName: 'PoolStamp (XSPT)',
  },
  CCRB: {
    Name: 'CCRB',
    Symbol: 'CCRB',
    CoinName: 'CryptoCarbon',
    FullName: 'CryptoCarbon (CCRB)',
  },
  BULLS: {
    Name: 'BULLS',
    Symbol: 'BULLS',
    CoinName: 'BullshitCoin',
    FullName: 'BullshitCoin (BULLS)',
  },
  INCNT: {
    Name: 'INCNT',
    Symbol: 'INCNT',
    CoinName: 'Incent',
    FullName: 'Incent (INCNT)',
  },
  ICON: {
    Name: 'ICON',
    Symbol: 'ICON',
    CoinName: 'Iconic',
    FullName: 'Iconic (ICON)',
  },
  NIC: {
    Name: 'NIC',
    Symbol: 'NIC',
    CoinName: 'NewInvestCoin',
    FullName: 'NewInvestCoin (NIC)',
  },
  ACN: {
    Name: 'ACN',
    Symbol: 'ACN',
    CoinName: 'AvonCoin',
    FullName: 'AvonCoin (ACN)',
  },
  XNG: {
    Name: 'XNG',
    Symbol: 'XNG',
    CoinName: 'Enigma',
    FullName: 'Enigma (XNG)',
  },
  XCI: {
    Name: 'XCI',
    Symbol: 'XCI',
    CoinName: 'Cannabis Industry Coin',
    FullName: 'Cannabis Industry Coin (XCI)',
  },
  LOOK: {
    Name: 'LOOK',
    Symbol: 'LOOK',
    CoinName: 'LookCoin',
    FullName: 'LookCoin (LOOK)',
  },
  LOCO: {
    Name: 'LOCO',
    Symbol: 'LOCO',
    CoinName: 'Loco',
    FullName: 'Loco (LOCO)',
  },
  MMXVI: {
    Name: 'MMXVI',
    Symbol: 'MMXVI',
    CoinName: 'MMXVI',
    FullName: 'MMXVI (MMXVI)',
  },
  TRST: {
    Name: 'TRST',
    Symbol: 'TRST',
    CoinName: 'TrustCoin',
    FullName: 'TrustCoin (TRST)',
  },
  MISCOIN: {
    Name: 'MISCOIN',
    Symbol: 'MISCOIN',
    CoinName: 'MIScoin',
    FullName: 'MIScoin (MISCOIN)',
  },
  WOP: {
    Name: 'WOP',
    Symbol: 'WOP',
    CoinName: 'WorldPay',
    FullName: 'WorldPay (WOP)',
  },
  CQST: {
    Name: 'CQST',
    Symbol: 'CQST',
    CoinName: 'ConquestCoin',
    FullName: 'ConquestCoin (CQST)',
  },
  IMPS: {
    Name: 'IMPS',
    Symbol: 'IMPS',
    CoinName: 'Impulse Coin',
    FullName: 'Impulse Coin (IMPS)',
  },
  IN: {
    Name: 'IN',
    Symbol: 'IN',
    CoinName: 'InCoin',
    FullName: 'InCoin (IN)',
  },
  CHIEF: {
    Name: 'CHIEF',
    Symbol: 'CHIEF',
    CoinName: 'TheChiefCoin',
    FullName: 'TheChiefCoin (CHIEF)',
  },
  GOAT: {
    Name: 'GOAT',
    Symbol: 'GOAT',
    CoinName: 'Goat',
    FullName: 'Goat (GOAT)',
  },
  ANAL: {
    Name: 'ANAL',
    Symbol: 'ANAL',
    CoinName: 'AnalCoin',
    FullName: 'AnalCoin (ANAL)',
  },
  RC: {
    Name: 'RC',
    Symbol: 'RC',
    CoinName: 'Russiacoin',
    FullName: 'Russiacoin (RC)',
  },
  PND: {
    Name: 'PND',
    Symbol: 'PND',
    CoinName: 'PandaCoin',
    FullName: 'PandaCoin (PND)',
  },
  PX: {
    Name: 'PX',
    Symbol: 'PX',
    CoinName: 'PXcoin',
    FullName: 'PXcoin (PX)',
  },
  OPTION: {
    Name: 'OPTION',
    Symbol: 'OPTION',
    CoinName: 'OptionCoin',
    FullName: 'OptionCoin (OPTION)',
  },
  AV: {
    Name: 'AV',
    Symbol: 'AV',
    CoinName: 'Avatar Coin',
    FullName: 'Avatar Coin (AV)',
  },
  LTD: {
    Name: 'LTD',
    Symbol: 'LTD',
    CoinName: 'Limited Coin',
    FullName: 'Limited Coin (LTD)',
  },
  UNITS: {
    Name: 'UNITS',
    Symbol: 'UNITS',
    CoinName: 'GameUnits',
    FullName: 'GameUnits (UNITS)',
  },
  HEEL: {
    Name: 'HEEL',
    Symbol: 'HEEL',
    CoinName: 'HeelCoin',
    FullName: 'HeelCoin (HEEL)',
  },
  GAKH: {
    Name: 'GAKH',
    Symbol: 'GAKH',
    CoinName: 'GAKHcoin',
    FullName: 'GAKHcoin (GAKH)',
  },
  GAIN: {
    Name: 'GAIN',
    Symbol: 'GAIN',
    CoinName: 'Gainfy',
    FullName: 'Gainfy (GAIN)',
  },
  S8C: {
    Name: 'S8C',
    Symbol: 'S8C',
    CoinName: 'S88 Coin',
    FullName: 'S88 Coin (S8C)',
  },
  LVG: {
    Name: 'LVG',
    Symbol: 'LVG',
    CoinName: 'Leverage Coin',
    FullName: 'Leverage Coin (LVG)',
  },
  DRA: {
    Name: 'DRA',
    Symbol: 'DRA',
    CoinName: 'DraculaCoin',
    FullName: 'DraculaCoin (DRA)',
  },
  ASAFE2: {
    Name: 'ASAFE2',
    Symbol: 'ASAFE2',
    CoinName: 'Allsafe',
    FullName: 'Allsafe (ASAFE2)',
  },
  LTCR: {
    Name: 'LTCR',
    Symbol: 'LTCR',
    CoinName: 'LiteCreed',
    FullName: 'LiteCreed (LTCR)',
  },
  QBC: {
    Name: 'QBC',
    Symbol: 'QBC',
    CoinName: 'Quebecoin',
    FullName: 'Quebecoin (QBC)',
  },
  XPRO: {
    Name: 'XPRO',
    Symbol: 'XPRO',
    CoinName: 'ProCoin',
    FullName: 'ProCoin (XPRO)',
  },
  ASTRAL: {
    Name: 'ASTRAL',
    Symbol: 'ASTRAL',
    CoinName: 'Astral',
    FullName: 'Astral (ASTRAL)',
  },
  GIFT: {
    Name: 'GIFT',
    Symbol: 'GIFT',
    CoinName: 'GiftNet',
    FullName: 'GiftNet (GIFT)',
  },
  VIDZ: {
    Name: 'VIDZ',
    Symbol: 'VIDZ',
    CoinName: 'PureVidz',
    FullName: 'PureVidz (VIDZ)',
  },
  INC: {
    Name: 'INC',
    Symbol: 'INC',
    CoinName: 'Incrementum',
    FullName: 'Incrementum (INC)',
  },
  PTA: {
    Name: 'PTA',
    Symbol: 'PTA',
    CoinName: 'PentaCoin',
    FullName: 'PentaCoin (PTA)',
  },
  ACID: {
    Name: 'ACID',
    Symbol: 'ACID',
    CoinName: 'AcidCoin',
    FullName: 'AcidCoin (ACID)',
  },
  ZLQ: {
    Name: 'ZLQ',
    Symbol: 'ZLQ',
    CoinName: 'ZLiteQubit',
    FullName: 'ZLiteQubit (ZLQ)',
  },
  RADI: {
    Name: 'RADI',
    Symbol: 'RADI',
    CoinName: 'RadicalCoin',
    FullName: 'RadicalCoin (RADI)',
  },
  RNC: {
    Name: 'RNC',
    Symbol: 'RNC',
    CoinName: 'ReturnCoin',
    FullName: 'ReturnCoin (RNC)',
  },
  GOLOS: {
    Name: 'GOLOS',
    Symbol: 'GOLOS',
    CoinName: 'Golos',
    FullName: 'Golos (GOLOS)',
  },
  PASC: {
    Name: 'PASC',
    Symbol: 'PASC',
    CoinName: 'Pascal Coin',
    FullName: 'Pascal Coin (PASC)',
  },
  TWIST: {
    Name: 'TWIST',
    Symbol: 'TWIST',
    CoinName: 'TwisterCoin',
    FullName: 'TwisterCoin (TWIST)',
  },
  PAYP: {
    Name: 'PAYP',
    Symbol: 'PAYP',
    CoinName: 'PayPeer',
    FullName: 'PayPeer (PAYP)',
  },
  DETH: {
    Name: 'DETH',
    Symbol: 'DETH',
    CoinName: 'DarkEther',
    FullName: 'DarkEther (DETH)',
  },
  YAYCOIN: {
    Name: 'YAYCOIN',
    Symbol: 'YAYCOIN',
    CoinName: 'YAYcoin',
    FullName: 'YAYcoin (YAYCOIN)',
  },
  LENIN: {
    Name: 'LENIN',
    Symbol: 'LENIN',
    CoinName: 'LeninCoin',
    FullName: 'LeninCoin (LENIN)',
  },
  MRSA: {
    Name: 'MRSA',
    Symbol: 'MRSA',
    CoinName: 'MrsaCoin',
    FullName: 'MrsaCoin (MRSA)',
  },
  OS76: {
    Name: 'OS76',
    Symbol: 'OS76',
    CoinName: 'OsmiumCoin',
    FullName: 'OsmiumCoin (OS76)',
  },
  BOSS: {
    Name: 'BOSS',
    Symbol: 'BOSS',
    CoinName: 'BitBoss',
    FullName: 'BitBoss (BOSS)',
  },
  BIC: {
    Name: 'BIC',
    Symbol: 'BIC',
    CoinName: 'Bikercoins',
    FullName: 'Bikercoins (BIC)',
  },
  CRPS: {
    Name: 'CRPS',
    Symbol: 'CRPS',
    CoinName: 'CryptoPennies',
    FullName: 'CryptoPennies (CRPS)',
  },
  MOTO: {
    Name: 'MOTO',
    Symbol: 'MOTO',
    CoinName: 'Motocoin',
    FullName: 'Motocoin (MOTO)',
  },
  NTCC: {
    Name: 'NTCC',
    Symbol: 'NTCC',
    CoinName: 'NeptuneClassic',
    FullName: 'NeptuneClassic (NTCC)',
  },
  HXX: {
    Name: 'HXX',
    Symbol: 'HXX',
    CoinName: 'HexxCoin',
    FullName: 'HexxCoin (HXX)',
  },
  SPKTR: {
    Name: 'SPKTR',
    Symbol: 'SPKTR',
    CoinName: 'Ghost Coin',
    FullName: 'Ghost Coin (SPKTR)',
  },
  MAC: {
    Name: 'MAC',
    Symbol: 'MAC',
    CoinName: 'MachineCoin',
    FullName: 'MachineCoin (MAC)',
  },
  SEL: {
    Name: 'SEL',
    Symbol: 'SEL',
    CoinName: 'SelenCoin',
    FullName: 'SelenCoin (SEL)',
  },
  NOO: {
    Name: 'NOO',
    Symbol: 'NOO',
    CoinName: 'Noocoin',
    FullName: 'Noocoin (NOO)',
  },
  CHAO: {
    Name: 'CHAO',
    Symbol: 'CHAO',
    CoinName: '23 Skidoo',
    FullName: '23 Skidoo (CHAO)',
  },
  XGB: {
    Name: 'XGB',
    Symbol: 'XGB',
    CoinName: 'GoldenBird',
    FullName: 'GoldenBird (XGB)',
  },
  YMC: {
    Name: 'YMC',
    Symbol: 'YMC',
    CoinName: 'YamahaCoin',
    FullName: 'YamahaCoin (YMC)',
  },
  JOK: {
    Name: 'JOK',
    Symbol: 'JOK',
    CoinName: 'JokerCoin',
    FullName: 'JokerCoin (JOK)',
  },
  GBIT: {
    Name: 'GBIT',
    Symbol: 'GBIT',
    CoinName: 'GravityBit',
    FullName: 'GravityBit (GBIT)',
  },
  TEC: {
    Name: 'TEC',
    Symbol: 'TEC',
    CoinName: 'TeCoin',
    FullName: 'TeCoin (TEC)',
  },
  BOMBC: {
    Name: 'BOMBC',
    Symbol: 'BOMBC',
    CoinName: 'BombCoin',
    FullName: 'BombCoin (BOMBC)',
  },
  KED: {
    Name: 'KED',
    Symbol: 'KED',
    CoinName: 'Klingon Empire Darsek',
    FullName: 'Klingon Empire Darsek (KED)',
  },
  CNO: {
    Name: 'CNO',
    Symbol: 'CNO',
    CoinName: 'Coino',
    FullName: 'Coino (CNO)',
  },
  WEALTH: {
    Name: 'WEALTH',
    Symbol: 'WEALTH',
    CoinName: 'WealthCoin',
    FullName: 'WealthCoin (WEALTH)',
  },
  IOP: {
    Name: 'IOP',
    Symbol: 'IOP',
    CoinName: 'Internet of People',
    FullName: 'Internet of People (IOP)',
  },
  XSPEC: {
    Name: 'XSPEC',
    Symbol: 'XSPEC',
    CoinName: 'Spectre',
    FullName: 'Spectre (XSPEC)',
  },
  PEPECASH: {
    Name: 'PEPECASH',
    Symbol: 'PEPECASH',
    CoinName: 'Pepe Cash',
    FullName: 'Pepe Cash (PEPECASH)',
  },
  CLICK: {
    Name: 'CLICK',
    Symbol: 'CLICK',
    CoinName: 'Clickcoin',
    FullName: 'Clickcoin (CLICK)',
  },
  ELS: {
    Name: 'ELS',
    Symbol: 'ELS',
    CoinName: 'Elysium',
    FullName: 'Elysium (ELS)',
  },
  KUSH: {
    Name: 'KUSH',
    Symbol: 'KUSH',
    CoinName: 'KushCoin',
    FullName: 'KushCoin (KUSH)',
  },
  ERY: {
    Name: 'ERY',
    Symbol: 'ERY',
    CoinName: 'Eryllium',
    FullName: 'Eryllium (ERY)',
  },
  PLU: {
    Name: 'PLU',
    Symbol: 'PLU',
    CoinName: 'Pluton',
    FullName: 'Pluton (PLU)',
  },
  PRES: {
    Name: 'PRES',
    Symbol: 'PRES',
    CoinName: 'President Trump',
    FullName: 'President Trump (PRES)',
  },
  BTZ: {
    Name: 'BTZ',
    Symbol: 'BTZ',
    CoinName: 'BitzCoin',
    FullName: 'BitzCoin (BTZ)',
  },
  OPES: {
    Name: 'OPES',
    Symbol: 'OPES',
    CoinName: 'Opes',
    FullName: 'Opes (OPES)',
  },
  WCT: {
    Name: 'WCT',
    Symbol: 'WCT',
    CoinName: 'Waves Community Token',
    FullName: 'Waves Community Token (WCT)',
  },
  RATIO: {
    Name: 'RATIO',
    Symbol: 'RATIO',
    CoinName: 'Ratio',
    FullName: 'Ratio (RATIO)',
  },
  BANC: {
    Name: 'BANC',
    Symbol: 'BANC',
    CoinName: 'Babes and Nerds',
    FullName: 'Babes and Nerds (BANC)',
  },
  NICEC: {
    Name: 'NICEC',
    Symbol: 'NICEC',
    CoinName: 'NiceCoin',
    FullName: 'NiceCoin (NICEC)',
  },
  SMF: {
    Name: 'SMF',
    Symbol: 'SMF',
    CoinName: 'SmurfCoin',
    FullName: 'SmurfCoin (SMF)',
  },
  CWXT: {
    Name: 'CWXT',
    Symbol: 'CWXT',
    CoinName: 'CryptoWorldXToken',
    FullName: 'CryptoWorldXToken (CWXT)',
  },
  TECH: {
    Name: 'TECH',
    Symbol: 'TECH',
    CoinName: 'TechCoin',
    FullName: 'TechCoin (TECH)',
  },
  CIR: {
    Name: 'CIR',
    Symbol: 'CIR',
    CoinName: 'CircuitCoin',
    FullName: 'CircuitCoin (CIR)',
  },
  LEPEN: {
    Name: 'LEPEN',
    Symbol: 'LEPEN',
    CoinName: 'LePenCoin',
    FullName: 'LePenCoin (LEPEN)',
  },
  ROUND: {
    Name: 'ROUND',
    Symbol: 'ROUND',
    CoinName: 'RoundCoin',
    FullName: 'RoundCoin (ROUND)',
  },
  MARXCOIN: {
    Name: 'MARXCOIN',
    Symbol: 'MARXCOIN',
    CoinName: 'MarxCoin',
    FullName: 'MarxCoin (MARXCOIN)',
  },
  HAZE: {
    Name: 'HAZE',
    Symbol: 'HAZE',
    CoinName: 'HazeCoin',
    FullName: 'HazeCoin (HAZE)',
  },
  PRX: {
    Name: 'PRX',
    Symbol: 'PRX',
    CoinName: 'Printerium',
    FullName: 'Printerium (PRX)',
  },
  NRC: {
    Name: 'NRC',
    Symbol: 'NRC',
    CoinName: 'Neurocoin',
    FullName: 'Neurocoin (NRC)',
  },
  IMPCH: {
    Name: 'IMPCH',
    Symbol: 'IMPCH',
    CoinName: 'Impeach',
    FullName: 'Impeach (IMPCH)',
  },
  ERR: {
    Name: 'ERR',
    Symbol: 'ERR',
    CoinName: 'ErrorCoin',
    FullName: 'ErrorCoin (ERR)',
  },
  TIC: {
    Name: 'TIC',
    Symbol: 'TIC',
    CoinName: 'TrueInvestmentCoin',
    FullName: 'TrueInvestmentCoin (TIC)',
  },
  NUKE: {
    Name: 'NUKE',
    Symbol: 'NUKE',
    CoinName: 'NukeCoin',
    FullName: 'NukeCoin (NUKE)',
  },
  EOC: {
    Name: 'EOC',
    Symbol: 'EOC',
    CoinName: 'EveryonesCoin',
    FullName: 'EveryonesCoin (EOC)',
  },
  SFC: {
    Name: 'SFC',
    Symbol: 'SFC',
    CoinName: 'Solarflarecoin',
    FullName: 'Solarflarecoin (SFC)',
  },
  JANE: {
    Name: 'JANE',
    Symbol: 'JANE',
    CoinName: 'JaneCoin',
    FullName: 'JaneCoin (JANE)',
  },
  PARANOIA: {
    Name: 'PARANOIA',
    Symbol: 'PARANOIA',
    CoinName: 'ParanoiaCoin',
    FullName: 'ParanoiaCoin (PARANOIA)',
  },
  MASTERMINT: {
    Name: 'MASTERMINT',
    Symbol: 'MASTERMINT',
    CoinName: 'MasterMint',
    FullName: 'MasterMint (MASTERMINT)',
  },
  CTL: {
    Name: 'CTL',
    Symbol: 'CTL',
    CoinName: 'Citadel',
    FullName: 'Citadel (CTL)',
  },
  NDOGE: {
    Name: 'NDOGE',
    Symbol: 'NDOGE',
    CoinName: 'NinjaDoge',
    FullName: 'NinjaDoge (NDOGE)',
  },
  ZILBERCOIN: {
    Name: 'ZILBERCOIN',
    Symbol: 'ZILBERCOIN',
    CoinName: 'Zilbercoin',
    FullName: 'Zilbercoin (ZILBERCOIN)',
  },
  FRST: {
    Name: 'FRST',
    Symbol: 'FRST',
    CoinName: 'FirstCoin',
    FullName: 'FirstCoin (FRST)',
  },
  OROCOIN: {
    Name: 'OROCOIN',
    Symbol: 'OROCOIN',
    CoinName: 'OroCoin',
    FullName: 'OroCoin (OROCOIN)',
  },
  ALEX: {
    Name: 'ALEX',
    Symbol: 'ALEX',
    CoinName: 'Alexandrite',
    FullName: 'Alexandrite (ALEX)',
  },
  TBCX: {
    Name: 'TBCX',
    Symbol: 'TBCX',
    CoinName: 'TrashBurn',
    FullName: 'TrashBurn (TBCX)',
  },
  MCAR: {
    Name: 'MCAR',
    Symbol: 'MCAR',
    CoinName: 'MasterCar',
    FullName: 'MasterCar (MCAR)',
  },
  THS: {
    Name: 'THS',
    Symbol: 'THS',
    CoinName: 'TechShares',
    FullName: 'TechShares (THS)',
  },
  ACES: {
    Name: 'ACES',
    Symbol: 'ACES',
    CoinName: 'AcesCoin',
    FullName: 'AcesCoin (ACES)',
  },
  UAEC: {
    Name: 'UAEC',
    Symbol: 'UAEC',
    CoinName: 'United Arab Emirates Coin',
    FullName: 'United Arab Emirates Coin (UAEC)',
  },
  EA: {
    Name: 'EA',
    Symbol: 'EA',
    CoinName: 'EagleCoin',
    FullName: 'EagleCoin (EA)',
  },
  PIE: {
    Name: 'PIE',
    Symbol: 'PIE',
    CoinName: 'Persistent Information Exchange',
    FullName: 'Persistent Information Exchange (PIE)',
  },
  WISC: {
    Name: 'WISC',
    Symbol: 'WISC',
    CoinName: 'WisdomCoin',
    FullName: 'WisdomCoin (WISC)',
  },
  BVC: {
    Name: 'BVC',
    Symbol: 'BVC',
    CoinName: 'BeaverCoin',
    FullName: 'BeaverCoin (BVC)',
  },
  FIND: {
    Name: 'FIND',
    Symbol: 'FIND',
    CoinName: 'FindCoin',
    FullName: 'FindCoin (FIND)',
  },
  MLITE: {
    Name: 'MLITE',
    Symbol: 'MLITE',
    CoinName: 'MeLite',
    FullName: 'MeLite (MLITE)',
  },
  STALIN: {
    Name: 'STALIN',
    Symbol: 'STALIN',
    CoinName: 'StalinCoin',
    FullName: 'StalinCoin (STALIN)',
  },
  TSE: {
    Name: 'TSE',
    Symbol: 'TSE',
    CoinName: 'TattooCoin',
    FullName: 'TattooCoin (TSE)',
  },
  VLTC: {
    Name: 'VLTC',
    Symbol: 'VLTC',
    CoinName: 'VaultCoin',
    FullName: 'VaultCoin (VLTC)',
  },
  BIOB: {
    Name: 'BIOB',
    Symbol: 'BIOB',
    CoinName: 'BioBar',
    FullName: 'BioBar (BIOB)',
  },
  SWT: {
    Name: 'SWT',
    Symbol: 'SWT',
    CoinName: 'Swarm City Token',
    FullName: 'Swarm City Token (SWT)',
  },
  PASL: {
    Name: 'PASL',
    Symbol: 'PASL',
    CoinName: 'Pascal Lite',
    FullName: 'Pascal Lite (PASL)',
  },
  ZER: {
    Name: 'ZER',
    Symbol: 'ZER',
    CoinName: 'Zero',
    FullName: 'Zero (ZER)',
  },
  CHAT: {
    Name: 'CHAT',
    Symbol: 'CHAT',
    CoinName: 'OpenChat',
    FullName: 'OpenChat (CHAT)',
  },
  CDN: {
    Name: 'CDN',
    Symbol: 'CDN',
    CoinName: 'Canada eCoin',
    FullName: 'Canada eCoin (CDN)',
  },
  NETKO: {
    Name: 'NETKO',
    Symbol: 'NETKO',
    CoinName: 'Netko',
    FullName: 'Netko (NETKO)',
  },
  ZOI: {
    Name: 'ZOI',
    Symbol: 'ZOI',
    CoinName: 'Zoin',
    FullName: 'Zoin (ZOI)',
  },
  HONEY: {
    Name: 'HONEY',
    Symbol: 'HONEY',
    CoinName: 'Honey',
    FullName: 'Honey (HONEY)',
  },
  MXTC: {
    Name: 'MXTC',
    Symbol: 'MXTC',
    CoinName: 'MartexCoin',
    FullName: 'MartexCoin (MXTC)',
  },
  DTB: {
    Name: 'DTB',
    Symbol: 'DTB',
    CoinName: 'Databits',
    FullName: 'Databits (DTB)',
  },
  VEG: {
    Name: 'VEG',
    Symbol: 'VEG',
    CoinName: 'BitVegan',
    FullName: 'BitVegan (VEG)',
  },
  MBIT: {
    Name: 'MBIT',
    Symbol: 'MBIT',
    CoinName: 'Mbitbooks',
    FullName: 'Mbitbooks (MBIT)',
  },
  BITVOLT: {
    Name: 'BITVOLT',
    Symbol: 'BITVOLT',
    CoinName: 'BitVolt',
    FullName: 'BitVolt (BITVOLT)',
  },
  EDG: {
    Name: 'EDG',
    Symbol: 'EDG',
    CoinName: 'Edgeless',
    FullName: 'Edgeless (EDG)',
  },
  'B@': {
    Name: 'B@',
    Symbol: 'B@',
    CoinName: 'BankCoin',
    FullName: 'BankCoin (B@)',
  },
  CHC: {
    Name: 'CHC',
    Symbol: 'CHC',
    CoinName: 'ChainCoin',
    FullName: 'ChainCoin (CHC)',
  },
  ZENI: {
    Name: 'ZENI',
    Symbol: 'ZENI',
    CoinName: 'Zennies',
    FullName: 'Zennies (ZENI)',
  },
  PLANET: {
    Name: 'PLANET',
    Symbol: 'PLANET',
    CoinName: 'PlanetCoin',
    FullName: 'PlanetCoin (PLANET)',
  },
  DUCKD: {
    Name: 'DUCKD',
    Symbol: 'DUCKD',
    CoinName: 'DuckDuckCoin',
    FullName: 'DuckDuckCoin (DUCKD)',
  },
  BNRTX: {
    Name: 'BNRTX',
    Symbol: 'BNRTX',
    CoinName: 'BnrtxCoin',
    FullName: 'BnrtxCoin (BNRTX)',
  },
  BSTK: {
    Name: 'BSTK',
    Symbol: 'BSTK',
    CoinName: 'BattleStake',
    FullName: 'BattleStake (BSTK)',
  },
  RNS: {
    Name: 'RNS',
    Symbol: 'RNS',
    CoinName: 'RenosCoin',
    FullName: 'RenosCoin (RNS)',
  },
  DBIX: {
    Name: 'DBIX',
    Symbol: 'DBIX',
    CoinName: 'DubaiCoin',
    FullName: 'DubaiCoin (DBIX)',
  },
  AMIS: {
    Name: 'AMIS',
    Symbol: 'AMIS',
    CoinName: 'AMIS',
    FullName: 'AMIS (AMIS)',
  },
  KAYI: {
    Name: 'KAYI',
    Symbol: 'KAYI',
    CoinName: 'Kayı',
    FullName: 'Kayı (KAYI)',
  },
  XVP: {
    Name: 'XVP',
    Symbol: 'XVP',
    CoinName: 'VirtacoinPlus',
    FullName: 'VirtacoinPlus (XVP)',
  },
  BOAT: {
    Name: 'BOAT',
    Symbol: 'BOAT',
    CoinName: 'Doubloon',
    FullName: 'Doubloon (BOAT)',
  },
  TAJ: {
    Name: 'TAJ',
    Symbol: 'TAJ',
    CoinName: 'TajCoin',
    FullName: 'TajCoin (TAJ)',
  },
  CJC: {
    Name: 'CJC',
    Symbol: 'CJC',
    CoinName: 'CryptoJournal',
    FullName: 'CryptoJournal (CJC)',
  },
  AMY: {
    Name: 'AMY',
    Symbol: 'AMY',
    CoinName: 'Amygws',
    FullName: 'Amygws (AMY)',
  },
  EB3: {
    Name: 'EB3',
    Symbol: 'EB3',
    CoinName: 'EB3coin',
    FullName: 'EB3coin (EB3)',
  },
  XVE: {
    Name: 'XVE',
    Symbol: 'XVE',
    CoinName: 'The Vegan Initiative',
    FullName: 'The Vegan Initiative (XVE)',
  },
  FAZZ: {
    Name: 'FAZZ',
    Symbol: 'FAZZ',
    CoinName: 'FazzCoin',
    FullName: 'FazzCoin (FAZZ)',
  },
  APTCOIN: {
    Name: 'APTCOIN',
    Symbol: 'APTCOIN',
    CoinName: 'Aptcoin',
    FullName: 'Aptcoin (APTCOIN)',
  },
  BLAZR: {
    Name: 'BLAZR',
    Symbol: 'BLAZR',
    CoinName: 'BlazerCoin',
    FullName: 'BlazerCoin (BLAZR)',
  },
  ARPAC: {
    Name: 'ARPAC',
    Symbol: 'ARPAC',
    CoinName: 'ArpaCoin',
    FullName: 'ArpaCoin (ARPAC)',
  },
  ECOC: {
    Name: 'ECOC',
    Symbol: 'ECOC',
    CoinName: 'ECOcoin',
    FullName: 'ECOcoin (ECOC)',
  },
  XLR: {
    Name: 'XLR',
    Symbol: 'XLR',
    CoinName: 'Solaris',
    FullName: 'Solaris (XLR)',
  },
  DARK: {
    Name: 'DARK',
    Symbol: 'DARK',
    CoinName: 'Dark',
    FullName: 'Dark (DARK)',
  },
  DONATION: {
    Name: 'DONATION',
    Symbol: 'DONATION',
    CoinName: 'DonationCoin',
    FullName: 'DonationCoin (DONATION)',
  },
  MERCURY: {
    Name: 'MERCURY',
    Symbol: 'MERCURY',
    CoinName: 'Mercury',
    FullName: 'Mercury (MERCURY)',
  },
  WGO: {
    Name: 'WGO',
    Symbol: 'WGO',
    CoinName: 'WavesGO',
    FullName: 'WavesGO (WGO)',
  },
  ATMOS: {
    Name: 'ATMOS',
    Symbol: 'ATMOS',
    CoinName: 'Novusphere',
    FullName: 'Novusphere (ATMOS)',
  },
  ETT: {
    Name: 'ETT',
    Symbol: 'ETT',
    CoinName: 'EncryptoTel',
    FullName: 'EncryptoTel (ETT)',
  },
  VISIO: {
    Name: 'VISIO',
    Symbol: 'VISIO',
    CoinName: 'Visio',
    FullName: 'Visio (VISIO)',
  },
  HPC: {
    Name: 'HPC',
    Symbol: 'HPC',
    CoinName: 'HappyCoin',
    FullName: 'HappyCoin (HPC)',
  },
  GIOT: {
    Name: 'GIOT',
    Symbol: 'GIOT',
    CoinName: 'Giotto Coin',
    FullName: 'Giotto Coin (GIOT)',
  },
  CXT: {
    Name: 'CXT',
    Symbol: 'CXT',
    CoinName: 'Coinonat',
    FullName: 'Coinonat (CXT)',
  },
  EMPC: {
    Name: 'EMPC',
    Symbol: 'EMPC',
    CoinName: 'EmporiumCoin',
    FullName: 'EmporiumCoin (EMPC)',
  },
  LGD: {
    Name: 'LGD',
    Symbol: 'LGD',
    CoinName: 'Legends Cryptocurrency',
    FullName: 'Legends Cryptocurrency (LGD)',
  },
  TAAS: {
    Name: 'TAAS',
    Symbol: 'TAAS',
    CoinName: 'Token as a Service',
    FullName: 'Token as a Service (TAAS)',
  },
  BUCKS: {
    Name: 'BUCKS',
    Symbol: 'BUCKS',
    CoinName: 'SwagBucks',
    FullName: 'SwagBucks (BUCKS)',
  },
  XBY: {
    Name: 'XBY',
    Symbol: 'XBY',
    CoinName: 'XTRABYTES',
    FullName: 'XTRABYTES (XBY)',
  },
  MCRN: {
    Name: 'MCRN',
    Symbol: 'MCRN',
    CoinName: 'MacronCoin',
    FullName: 'MacronCoin (MCRN)',
  },
  CONDENSATE: {
    Name: 'CONDENSATE',
    Symbol: 'CONDENSATE',
    CoinName: 'Condensate',
    FullName: 'Condensate (CONDENSATE)',
  },
  WSX: {
    Name: 'WSX',
    Symbol: 'WSX',
    CoinName: 'WeAreSatoshi',
    FullName: 'WeAreSatoshi (WSX)',
  },
  IEC: {
    Name: 'IEC',
    Symbol: 'IEC',
    CoinName: 'IvugeoEvolutionCoin',
    FullName: 'IvugeoEvolutionCoin (IEC)',
  },
  IMS: {
    Name: 'IMS',
    Symbol: 'IMS',
    CoinName: 'Independent Money System',
    FullName: 'Independent Money System (IMS)',
  },
  ARGUS: {
    Name: 'ARGUS',
    Symbol: 'ARGUS',
    CoinName: 'ArgusCoin',
    FullName: 'ArgusCoin (ARGUS)',
  },
  CNT: {
    Name: 'CNT',
    Symbol: 'CNT',
    CoinName: 'Centurion',
    FullName: 'Centurion (CNT)',
  },
  LMC: {
    Name: 'LMC',
    Symbol: 'LMC',
    CoinName: 'LomoCoin',
    FullName: 'LomoCoin (LMC)',
  },
  BTCS: {
    Name: 'BTCS',
    Symbol: 'BTCS',
    CoinName: 'Bitcoin Scrypt',
    FullName: 'Bitcoin Scrypt (BTCS)',
  },
  PROC: {
    Name: 'PROC',
    Symbol: 'PROC',
    CoinName: 'ProCurrency',
    FullName: 'ProCurrency (PROC)',
  },
  XGR: {
    Name: 'XGR',
    Symbol: 'XGR',
    CoinName: 'GoldReserve',
    FullName: 'GoldReserve (XGR)',
  },
  BENJI: {
    Name: 'BENJI',
    Symbol: 'BENJI',
    CoinName: 'BenjiRolls',
    FullName: 'BenjiRolls (BENJI)',
  },
  HMQ: {
    Name: 'HMQ',
    Symbol: 'HMQ',
    CoinName: 'Humaniq',
    FullName: 'Humaniq (HMQ)',
  },
  BCAP: {
    Name: 'BCAP',
    Symbol: 'BCAP',
    CoinName: 'Blockchain Capital',
    FullName: 'Blockchain Capital (BCAP)',
  },
  RBX: {
    Name: 'RBX',
    Symbol: 'RBX',
    CoinName: 'RiptoBuX',
    FullName: 'RiptoBuX (RBX)',
  },
  GRW: {
    Name: 'GRW',
    Symbol: 'GRW',
    CoinName: 'GrowthCoin',
    FullName: 'GrowthCoin (GRW)',
  },
  MILOCOIN: {
    Name: 'MILOCOIN',
    Symbol: 'MILOCOIN',
    CoinName: 'MiloCoin',
    FullName: 'MiloCoin (MILOCOIN)',
  },
  OLV: {
    Name: 'OLV',
    Symbol: 'OLV',
    CoinName: 'OldV',
    FullName: 'OldV (OLV)',
  },
  MRT: {
    Name: 'MRT',
    Symbol: 'MRT',
    CoinName: 'MinersReward',
    FullName: 'MinersReward (MRT)',
  },
  IOU: {
    Name: 'IOU',
    Symbol: 'IOU',
    CoinName: 'IOU1',
    FullName: 'IOU1 (IOU)',
  },
  PHR: {
    Name: 'PHR',
    Symbol: 'PHR',
    CoinName: 'Phreak',
    FullName: 'Phreak (PHR)',
  },
  PUPA: {
    Name: 'PUPA',
    Symbol: 'PUPA',
    CoinName: 'PupaCoin',
    FullName: 'PupaCoin (PUPA)',
  },
  RICECOIN: {
    Name: 'RICECOIN',
    Symbol: 'RICECOIN',
    CoinName: 'RiceCoin',
    FullName: 'RiceCoin (RICECOIN)',
  },
  XCT: {
    Name: 'XCT',
    Symbol: 'XCT',
    CoinName: 'C-Bits',
    FullName: 'C-Bits (XCT)',
  },
  DEA: {
    Name: 'DEA',
    Symbol: 'DEA',
    CoinName: 'Degas Coin',
    FullName: 'Degas Coin (DEA)',
  },
  REDCO: {
    Name: 'REDCO',
    Symbol: 'REDCO',
    CoinName: 'Redcoin',
    FullName: 'Redcoin (REDCO)',
  },
  ZSE: {
    Name: 'ZSE',
    Symbol: 'ZSE',
    CoinName: 'ZSEcoin',
    FullName: 'ZSEcoin (ZSE)',
  },
  CTIC: {
    Name: 'CTIC',
    Symbol: 'CTIC',
    CoinName: 'Coinmatic',
    FullName: 'Coinmatic (CTIC)',
  },
  BITOK: {
    Name: 'BITOK',
    Symbol: 'BITOK',
    CoinName: 'BitOKX',
    FullName: 'BitOKX (BITOK)',
  },
  PBT: {
    Name: 'PBT',
    Symbol: 'PBT',
    CoinName: 'Primalbase',
    FullName: 'Primalbase (PBT)',
  },
  MUU: {
    Name: 'MUU',
    Symbol: 'MUU',
    CoinName: 'MilkCoin',
    FullName: 'MilkCoin (MUU)',
  },
  INF: {
    Name: 'INF',
    Symbol: 'INF',
    CoinName: 'Infinium',
    FullName: 'Infinium (INF)',
  },
  MNE: {
    Name: 'MNE',
    Symbol: 'MNE',
    CoinName: 'Minereum',
    FullName: 'Minereum (MNE)',
  },
  DICE: {
    Name: 'DICE',
    Symbol: 'DICE',
    CoinName: 'Etheroll',
    FullName: 'Etheroll (DICE)',
  },
  SBSC: {
    Name: 'SBSC',
    Symbol: 'SBSC',
    CoinName: 'Subscriptio',
    FullName: 'Subscriptio (SBSC)',
  },
  USC: {
    Name: 'USC',
    Symbol: 'USC',
    CoinName: 'Ultimate Secure Cash',
    FullName: 'Ultimate Secure Cash (USC)',
  },
  DUX: {
    Name: 'DUX',
    Symbol: 'DUX',
    CoinName: 'DuxCoin',
    FullName: 'DuxCoin (DUX)',
  },
  XPS: {
    Name: 'XPS',
    Symbol: 'XPS',
    CoinName: 'PoisonIvyCoin',
    FullName: 'PoisonIvyCoin (XPS)',
  },
  EQT: {
    Name: 'EQT',
    Symbol: 'EQT',
    CoinName: 'EquiTrader',
    FullName: 'EquiTrader (EQT)',
  },
  INSN: {
    Name: 'INSN',
    Symbol: 'INSN',
    CoinName: 'Insane Coin',
    FullName: 'Insane Coin (INSN)',
  },
  MNTC: {
    Name: 'MNTC',
    Symbol: 'MNTC',
    CoinName: 'Manet Coin',
    FullName: 'Manet Coin (MNTC)',
  },
  F16: {
    Name: 'F16',
    Symbol: 'F16',
    CoinName: 'F16Coin',
    FullName: 'F16Coin (F16)',
  },
  HAMS: {
    Name: 'HAMS',
    Symbol: 'HAMS',
    CoinName: 'HamsterCoin',
    FullName: 'HamsterCoin (HAMS)',
  },
  NEF: {
    Name: 'NEF',
    Symbol: 'NEF',
    CoinName: 'NefariousCoin',
    FullName: 'NefariousCoin (NEF)',
  },
  BOS: {
    Name: 'BOS',
    Symbol: 'BOS',
    CoinName: 'BOScoin',
    FullName: 'BOScoin (BOS)',
  },
  QWARK: {
    Name: 'QWARK',
    Symbol: 'QWARK',
    CoinName: 'Qwark',
    FullName: 'Qwark (QWARK)',
  },
  ADL: {
    Name: 'ADL',
    Symbol: 'ADL',
    CoinName: 'Adel',
    FullName: 'Adel (ADL)',
  },
  PTOY: {
    Name: 'PTOY',
    Symbol: 'PTOY',
    CoinName: 'Patientory',
    FullName: 'Patientory (PTOY)',
  },
  ZRC: {
    Name: 'ZRC',
    Symbol: 'ZRC',
    CoinName: 'ZrCoin',
    FullName: 'ZrCoin (ZRC)',
  },
  LKK: {
    Name: 'LKK',
    Symbol: 'LKK',
    CoinName: 'Lykke',
    FullName: 'Lykke (LKK)',
  },
  ESP: {
    Name: 'ESP',
    Symbol: 'ESP',
    CoinName: 'Espers',
    FullName: 'Espers (ESP)',
  },
  DYN: {
    Name: 'DYN',
    Symbol: 'DYN',
    CoinName: 'Dynamic',
    FullName: 'Dynamic (DYN)',
  },
  SEQ: {
    Name: 'SEQ',
    Symbol: 'SEQ',
    CoinName: 'Sequence',
    FullName: 'Sequence (SEQ)',
  },
  MCAP: {
    Name: 'MCAP',
    Symbol: 'MCAP',
    CoinName: 'MCAP',
    FullName: 'MCAP (MCAP)',
  },
  MYST: {
    Name: 'MYST',
    Symbol: 'MYST',
    CoinName: 'Mysterium',
    FullName: 'Mysterium (MYST)',
  },
  CFI: {
    Name: 'CFI',
    Symbol: 'CFI',
    CoinName: 'Cofound.it',
    FullName: 'Cofound.it (CFI)',
  },
  STARTA: {
    Name: 'STARTA',
    Symbol: 'STARTA',
    CoinName: 'Starta',
    FullName: 'Starta (STARTA)',
  },
  TFL: {
    Name: 'TFL',
    Symbol: 'TFL',
    CoinName: 'True Flip Lottery',
    FullName: 'True Flip Lottery (TFL)',
  },
  QAU: {
    Name: 'QAU',
    Symbol: 'QAU',
    CoinName: 'Quantum',
    FullName: 'Quantum (QAU)',
  },
  ECOB: {
    Name: 'ECOB',
    Symbol: 'ECOB',
    CoinName: 'EcoBit',
    FullName: 'EcoBit (ECOB)',
  },
  AHOO: {
    Name: 'AHOO',
    Symbol: 'AHOO',
    CoinName: 'Ahoolee',
    FullName: 'Ahoolee (AHOO)',
  },
  ATB: {
    Name: 'ATB',
    Symbol: 'ATB',
    CoinName: 'ATB coin',
    FullName: 'ATB coin (ATB)',
  },
  TIX: {
    Name: 'TIX',
    Symbol: 'TIX',
    CoinName: 'Blocktix',
    FullName: 'Blocktix (TIX)',
  },
  CHAN: {
    Name: 'CHAN',
    Symbol: 'CHAN',
    CoinName: 'ChanCoin',
    FullName: 'ChanCoin (CHAN)',
  },
  RVT: {
    Name: 'RVT',
    Symbol: 'RVT',
    CoinName: 'Rivetz',
    FullName: 'Rivetz (RVT)',
  },
  HRB: {
    Name: 'HRB',
    Symbol: 'HRB',
    CoinName: 'Harbour DAO',
    FullName: 'Harbour DAO (HRB)',
  },
  NIM: {
    Name: 'NIM',
    Symbol: 'NIM',
    CoinName: 'Nimiq',
    FullName: 'Nimiq (NIM)',
  },
  '8BT': {
    Name: '8BT',
    Symbol: '8BT',
    CoinName: '8 Circuit Studios',
    FullName: '8 Circuit Studios (8BT)',
  },
  SSV: {
    Name: 'SSV',
    Symbol: 'SSV',
    CoinName: 'ssv.network',
    FullName: 'ssv.network (SSV)',
  },
  DAOACT: {
    Name: 'DAOACT',
    Symbol: 'DAOACT',
    CoinName: 'ACT',
    FullName: 'ACT (DAOACT)',
  },
  MIV: {
    Name: 'MIV',
    Symbol: 'MIV',
    CoinName: 'MakeItViral',
    FullName: 'MakeItViral (MIV)',
  },
  DAOB: {
    Name: 'DAOB',
    Symbol: 'DAOB',
    CoinName: 'DAOBet',
    FullName: 'DAOBet (DAOB)',
  },
  WGR: {
    Name: 'WGR',
    Symbol: 'WGR',
    CoinName: 'Wagerr',
    FullName: 'Wagerr (WGR)',
  },
  XEL: {
    Name: 'XEL',
    Symbol: 'XEL',
    CoinName: 'Xel',
    FullName: 'Xel (XEL)',
  },
  NVST: {
    Name: 'NVST',
    Symbol: 'NVST',
    CoinName: 'NVO',
    FullName: 'NVO (NVST)',
  },
  FUNC: {
    Name: 'FUNC',
    Symbol: 'FUNC',
    CoinName: 'FunCoin',
    FullName: 'FunCoin (FUNC)',
  },
  WTT: {
    Name: 'WTT',
    Symbol: 'WTT',
    CoinName: 'Giga Watt',
    FullName: 'Giga Watt (WTT)',
  },
  HVN: {
    Name: 'HVN',
    Symbol: 'HVN',
    CoinName: 'Hiveterminal Token',
    FullName: 'Hiveterminal Token (HVN)',
  },
  MYB: {
    Name: 'MYB',
    Symbol: 'MYB',
    CoinName: 'MyBit',
    FullName: 'MyBit (MYB)',
  },
  STARBASE: {
    Name: 'STARBASE',
    Symbol: 'STARBASE',
    CoinName: 'Starbase',
    FullName: 'Starbase (STARBASE)',
  },
  COR: {
    Name: 'COR',
    Symbol: 'COR',
    CoinName: 'Corion',
    FullName: 'Corion (COR)',
  },
  XRL: {
    Name: 'XRL',
    Symbol: 'XRL',
    CoinName: 'Rialto.AI',
    FullName: 'Rialto.AI (XRL)',
  },
  OROC: {
    Name: 'OROC',
    Symbol: 'OROC',
    CoinName: 'Orocrypt',
    FullName: 'Orocrypt (OROC)',
  },
  MBI: {
    Name: 'MBI',
    Symbol: 'MBI',
    CoinName: 'Monster Byte Inc',
    FullName: 'Monster Byte Inc (MBI)',
  },
  DDF: {
    Name: 'DDF',
    Symbol: 'DDF',
    CoinName: 'Digital Developers Fund',
    FullName: 'Digital Developers Fund (DDF)',
  },
  DIM: {
    Name: 'DIM',
    Symbol: 'DIM',
    CoinName: 'DIMCOIN',
    FullName: 'DIMCOIN (DIM)',
  },
  GGS: {
    Name: 'GGS',
    Symbol: 'GGS',
    CoinName: 'Gilgam',
    FullName: 'Gilgam (GGS)',
  },
  FUNDYOUR: {
    Name: 'FUNDYOUR',
    Symbol: 'FUNDYOUR',
    CoinName: 'FundYourselfNow',
    FullName: 'FundYourselfNow (FUNDYOUR)',
  },
  DCY: {
    Name: 'DCY',
    Symbol: 'DCY',
    CoinName: 'Dinastycoin',
    FullName: 'Dinastycoin (DCY)',
  },
  CFT: {
    Name: 'CFT',
    Symbol: 'CFT',
    CoinName: 'CryptoForecast',
    FullName: 'CryptoForecast (CFT)',
  },
  D: {
    Name: 'D',
    Symbol: 'D',
    CoinName: 'Denarius',
    FullName: 'Denarius (D)',
  },
  DP: {
    Name: 'DP',
    Symbol: 'DP',
    CoinName: 'DigitalPrice',
    FullName: 'DigitalPrice (DP)',
  },
  VUC: {
    Name: 'VUC',
    Symbol: 'VUC',
    CoinName: 'Virta Unique Coin',
    FullName: 'Virta Unique Coin (VUC)',
  },
  BTPL: {
    Name: 'BTPL',
    Symbol: 'BTPL',
    CoinName: 'Bitcoin Planet',
    FullName: 'Bitcoin Planet (BTPL)',
  },
  UNIFY: {
    Name: 'UNIFY',
    Symbol: 'UNIFY',
    CoinName: 'Unify',
    FullName: 'Unify (UNIFY)',
  },
  BRIT: {
    Name: 'BRIT',
    Symbol: 'BRIT',
    CoinName: 'BritCoin',
    FullName: 'BritCoin (BRIT)',
  },
  AMMO: {
    Name: 'AMMO',
    Symbol: 'AMMO',
    CoinName: 'Ammo Rewards',
    FullName: 'Ammo Rewards (AMMO)',
  },
  SOCC: {
    Name: 'SOCC',
    Symbol: 'SOCC',
    CoinName: 'SocialCoin',
    FullName: 'SocialCoin (SOCC)',
  },
  LA: {
    Name: 'LA',
    Symbol: 'LA',
    CoinName: 'LATOKEN',
    FullName: 'LATOKEN (LA)',
  },
  IML: {
    Name: 'IML',
    Symbol: 'IML',
    CoinName: 'IMMLA',
    FullName: 'IMMLA (IML)',
  },
  STU: {
    Name: 'STU',
    Symbol: 'STU',
    CoinName: 'BitJob',
    FullName: 'BitJob (STU)',
  },
  PLR: {
    Name: 'PLR',
    Symbol: 'PLR',
    CoinName: 'Pillar',
    FullName: 'Pillar (PLR)',
  },
  GUNS: {
    Name: 'GUNS',
    Symbol: 'GUNS',
    CoinName: 'GeoFunders',
    FullName: 'GeoFunders (GUNS)',
  },
  IFT: {
    Name: 'IFT',
    Symbol: 'IFT',
    CoinName: 'InvestFeed',
    FullName: 'InvestFeed (IFT)',
  },
  BCAT: {
    Name: 'BCAT',
    Symbol: 'BCAT',
    CoinName: 'BitClave',
    FullName: 'BitClave (BCAT)',
  },
  SYC: {
    Name: 'SYC',
    Symbol: 'SYC',
    CoinName: 'SynchroCoin',
    FullName: 'SynchroCoin (SYC)',
  },
  IND: {
    Name: 'IND',
    Symbol: 'IND',
    CoinName: 'Indorse',
    FullName: 'Indorse (IND)',
  },
  TRIBETOKEN: {
    Name: 'TRIBETOKEN',
    Symbol: 'TRIBETOKEN',
    CoinName: 'TribeToken',
    FullName: 'TribeToken (TRIBETOKEN)',
  },
  COS: {
    Name: 'COS',
    Symbol: 'COS',
    CoinName: 'COS',
    FullName: 'COS (COS)',
  },
  STORM: {
    Name: 'STORM',
    Symbol: 'STORM',
    CoinName: 'Storm',
    FullName: 'Storm (STORM)',
  },
  NPX: {
    Name: 'NPX',
    Symbol: 'NPX',
    CoinName: 'Napoleon X',
    FullName: 'Napoleon X (NPX)',
  },
  SCORE: {
    Name: 'SCORE',
    Symbol: 'SCORE',
    CoinName: 'Scorecoin',
    FullName: 'Scorecoin (SCORE)',
  },
  OTX: {
    Name: 'OTX',
    Symbol: 'OTX',
    CoinName: 'Octanox',
    FullName: 'Octanox (OTX)',
  },
  VOISE: {
    Name: 'VOISE',
    Symbol: 'VOISE',
    CoinName: 'Voise',
    FullName: 'Voise (VOISE)',
  },
  ETBS: {
    Name: 'ETBS',
    Symbol: 'ETBS',
    CoinName: 'EthBits',
    FullName: 'EthBits (ETBS)',
  },
  CVCOIN: {
    Name: 'CVCOIN',
    Symbol: 'CVCOIN',
    CoinName: 'Crypviser',
    FullName: 'Crypviser (CVCOIN)',
  },
  DRP: {
    Name: 'DRP',
    Symbol: 'DRP',
    CoinName: 'DCORP',
    FullName: 'DCORP (DRP)',
  },
  ARC: {
    Name: 'ARC',
    Symbol: 'ARC',
    CoinName: 'ArcticCoin',
    FullName: 'ArcticCoin (ARC)',
  },
  BOGCOIN: {
    Name: 'BOGCOIN',
    Symbol: 'BOGCOIN',
    CoinName: 'Bogcoin',
    FullName: 'Bogcoin (BOGCOIN)',
  },
  NDC: {
    Name: 'NDC',
    Symbol: 'NDC',
    CoinName: 'NeverDie',
    FullName: 'NeverDie (NDC)',
  },
  POE: {
    Name: 'POE',
    Symbol: 'POE',
    CoinName: 'Po.et',
    FullName: 'Po.et (POE)',
  },
  ADT: {
    Name: 'ADT',
    Symbol: 'ADT',
    CoinName: 'AdToken',
    FullName: 'AdToken (ADT)',
  },
  UET: {
    Name: 'UET',
    Symbol: 'UET',
    CoinName: 'Useless Ethereum Token',
    FullName: 'Useless Ethereum Token (UET)',
  },
  AGRS: {
    Name: 'AGRS',
    Symbol: 'AGRS',
    CoinName: 'Agoras Token',
    FullName: 'Agoras Token (AGRS)',
  },
  BEACH: {
    Name: 'BEACH',
    Symbol: 'BEACH',
    CoinName: 'BeachCoin',
    FullName: 'BeachCoin (BEACH)',
  },
  DAS: {
    Name: 'DAS',
    Symbol: 'DAS',
    CoinName: 'DAS',
    FullName: 'DAS (DAS)',
  },
  ADS: {
    Name: 'ADS',
    Symbol: 'ADS',
    CoinName: 'Adshares',
    FullName: 'Adshares (ADS)',
  },
  RKC: {
    Name: 'RKC',
    Symbol: 'RKC',
    CoinName: 'Royal Kingdom Coin',
    FullName: 'Royal Kingdom Coin (RKC)',
  },
  NLC2: {
    Name: 'NLC2',
    Symbol: 'NLC2',
    CoinName: 'NoLimitCoin',
    FullName: 'NoLimitCoin (NLC2)',
  },
  LINDA: {
    Name: 'LINDA',
    Symbol: 'LINDA',
    CoinName: 'Metrix',
    FullName: 'Metrix (LINDA)',
  },
  KING93: {
    Name: 'KING93',
    Symbol: 'KING93',
    CoinName: 'King93',
    FullName: 'King93 (KING93)',
  },
  ANCP: {
    Name: 'ANCP',
    Symbol: 'ANCP',
    CoinName: 'Anacrypt',
    FullName: 'Anacrypt (ANCP)',
  },
  RCC: {
    Name: 'RCC',
    Symbol: 'RCC',
    CoinName: 'Reality Clash',
    FullName: 'Reality Clash (RCC)',
  },
  ROOTS: {
    Name: 'ROOTS',
    Symbol: 'ROOTS',
    CoinName: 'RootProject',
    FullName: 'RootProject (ROOTS)',
  },
  SOSNOVKINO: {
    Name: 'SOSNOVKINO',
    Symbol: 'SOSNOVKINO',
    CoinName: 'Sosnovkino',
    FullName: 'Sosnovkino (SOSNOVKINO)',
  },
  CABS: {
    Name: 'CABS',
    Symbol: 'CABS',
    CoinName: 'CryptoABS',
    FullName: 'CryptoABS (CABS)',
  },
  OPT: {
    Name: 'OPT',
    Symbol: 'OPT',
    CoinName: 'Opus',
    FullName: 'Opus (OPT)',
  },
  BITSD: {
    Name: 'BITSD',
    Symbol: 'BITSD',
    CoinName: 'Bits Digit',
    FullName: 'Bits Digit (BITSD)',
  },
  XLC: {
    Name: 'XLC',
    Symbol: 'XLC',
    CoinName: 'LeviarCoin',
    FullName: 'LeviarCoin (XLC)',
  },
  SKIN: {
    Name: 'SKIN',
    Symbol: 'SKIN',
    CoinName: 'Skincoin',
    FullName: 'Skincoin (SKIN)',
  },
  MSP: {
    Name: 'MSP',
    Symbol: 'MSP',
    CoinName: 'Mothership',
    FullName: 'Mothership (MSP)',
  },
  HIRE: {
    Name: 'HIRE',
    Symbol: 'HIRE',
    CoinName: 'HireMatch',
    FullName: 'HireMatch (HIRE)',
  },
  REALPLATFORM: {
    Name: 'REALPLATFORM',
    Symbol: 'REALPLATFORM',
    CoinName: 'REAL',
    FullName: 'REAL (REALPLATFORM)',
  },
  DFBT: {
    Name: 'DFBT',
    Symbol: 'DFBT',
    CoinName: 'DentalFix',
    FullName: 'DentalFix (DFBT)',
  },
  WLK: {
    Name: 'WLK',
    Symbol: 'WLK',
    CoinName: 'Wolk',
    FullName: 'Wolk (WLK)',
  },
  ONION: {
    Name: 'ONION',
    Symbol: 'ONION',
    CoinName: 'DeepOnion',
    FullName: 'DeepOnion (ONION)',
  },
  BTX: {
    Name: 'BTX',
    Symbol: 'BTX',
    CoinName: 'BitCore',
    FullName: 'BitCore (BTX)',
  },
  IDICE: {
    Name: 'IDICE',
    Symbol: 'IDICE',
    CoinName: 'iDice',
    FullName: 'iDice (IDICE)',
  },
  XID: {
    Name: 'XID',
    Symbol: 'XID',
    CoinName: 'Sphre AIR',
    FullName: 'Sphre AIR (XID)',
  },
  GCN: {
    Name: 'GCN',
    Symbol: 'GCN',
    CoinName: 'gCn Coin',
    FullName: 'gCn Coin (GCN)',
  },
  ICOO: {
    Name: 'ICOO',
    Symbol: 'ICOO',
    CoinName: 'ICO OpenLedger',
    FullName: 'ICO OpenLedger (ICOO)',
  },
  TME: {
    Name: 'TME',
    Symbol: 'TME',
    CoinName: 'Timereum',
    FullName: 'Timereum (TME)',
  },
  SIGT: {
    Name: 'SIGT',
    Symbol: 'SIGT',
    CoinName: 'Signatum',
    FullName: 'Signatum (SIGT)',
  },
  ONX: {
    Name: 'ONX',
    Symbol: 'ONX',
    CoinName: 'Onix',
    FullName: 'Onix (ONX)',
  },
  COE: {
    Name: 'COE',
    Symbol: 'COE',
    CoinName: 'CoEval',
    FullName: 'CoEval (COE)',
  },
  ARENA: {
    Name: 'ARENA',
    Symbol: 'ARENA',
    CoinName: 'Arena',
    FullName: 'Arena (ARENA)',
  },
  WINK: {
    Name: 'WINK',
    Symbol: 'WINK',
    CoinName: 'Wink',
    FullName: 'Wink (WINK)',
  },
  CRM: {
    Name: 'CRM',
    Symbol: 'CRM',
    CoinName: 'Cream',
    FullName: 'Cream (CRM)',
  },
  DGPT: {
    Name: 'DGPT',
    Symbol: 'DGPT',
    CoinName: 'DigiPulse',
    FullName: 'DigiPulse (DGPT)',
  },
  MOBI: {
    Name: 'MOBI',
    Symbol: 'MOBI',
    CoinName: 'Mobius',
    FullName: 'Mobius (MOBI)',
  },
  CSNO: {
    Name: 'CSNO',
    Symbol: 'CSNO',
    CoinName: 'BitDice',
    FullName: 'BitDice (CSNO)',
  },
  SOLARDAO: {
    Name: 'SOLARDAO',
    Symbol: 'SOLARDAO',
    CoinName: 'Solar DAO',
    FullName: 'Solar DAO (SOLARDAO)',
  },
  COREG: {
    Name: 'COREG',
    Symbol: 'COREG',
    CoinName: 'Core Group Asset',
    FullName: 'Core Group Asset (COREG)',
  },
  KEN: {
    Name: 'KEN',
    Symbol: 'KEN',
    CoinName: 'Kencoin',
    FullName: 'Kencoin (KEN)',
  },
  QVT: {
    Name: 'QVT',
    Symbol: 'QVT',
    CoinName: 'Qvolta',
    FullName: 'Qvolta (QVT)',
  },
  TIE: {
    Name: 'TIE',
    Symbol: 'TIE',
    CoinName: 'Ties Network',
    FullName: 'Ties Network (TIE)',
  },
  AUT: {
    Name: 'AUT',
    Symbol: 'AUT',
    CoinName: 'Autoria',
    FullName: 'Autoria (AUT)',
  },
  GRWI: {
    Name: 'GRWI',
    Symbol: 'GRWI',
    CoinName: 'Growers International',
    FullName: 'Growers International (GRWI)',
  },
  MONKEY: {
    Name: 'MONKEY',
    Symbol: 'MONKEY',
    CoinName: 'Monkey',
    FullName: 'Monkey (MONKEY)',
  },
  CCC: {
    Name: 'CCC',
    Symbol: 'CCC',
    CoinName: 'CCCoin',
    FullName: 'CCCoin (CCC)',
  },
  UMC: {
    Name: 'UMC',
    Symbol: 'UMC',
    CoinName: 'Umbrella Coin',
    FullName: 'Umbrella Coin (UMC)',
  },
  BMXT: {
    Name: 'BMXT',
    Symbol: 'BMXT',
    CoinName: 'Bitmxittz',
    FullName: 'Bitmxittz (BMXT)',
  },
  OCL: {
    Name: 'OCL',
    Symbol: 'OCL',
    CoinName: 'Oceanlab',
    FullName: 'Oceanlab (OCL)',
  },
  BENJACOIN: {
    Name: 'BENJACOIN',
    Symbol: 'BENJACOIN',
    CoinName: 'Benjacoin',
    FullName: 'Benjacoin (BENJACOIN)',
  },
  XAS: {
    Name: 'XAS',
    Symbol: 'XAS',
    CoinName: 'Asch',
    FullName: 'Asch (XAS)',
  },
  MRV: {
    Name: 'MRV',
    Symbol: 'MRV',
    CoinName: 'Macroverse',
    FullName: 'Macroverse (MRV)',
  },
  MBRS: {
    Name: 'MBRS',
    Symbol: 'MBRS',
    CoinName: 'Embers',
    FullName: 'Embers (MBRS)',
  },
  SUB: {
    Name: 'SUB',
    Symbol: 'SUB',
    CoinName: 'Substratum Network',
    FullName: 'Substratum Network (SUB)',
  },
  PGL: {
    Name: 'PGL',
    Symbol: 'PGL',
    CoinName: 'Prospectors',
    FullName: 'Prospectors (PGL)',
  },
  XMCC: {
    Name: 'XMCC',
    Symbol: 'XMCC',
    CoinName: 'Monoeci',
    FullName: 'Monoeci (XMCC)',
  },
  AUN: {
    Name: 'AUN',
    Symbol: 'AUN',
    CoinName: 'Authoreon',
    FullName: 'Authoreon (AUN)',
  },
  CMPCO: {
    Name: 'CMPCO',
    Symbol: 'CMPCO',
    CoinName: 'CampusCoin',
    FullName: 'CampusCoin (CMPCO)',
  },
  DTCT: {
    Name: 'DTCT',
    Symbol: 'DTCT',
    CoinName: 'DetectorToken',
    FullName: 'DetectorToken (DTCT)',
  },
  CENTRA: {
    Name: 'CENTRA',
    Symbol: 'CENTRA',
    CoinName: 'Centra',
    FullName: 'Centra (CENTRA)',
  },
  WNET: {
    Name: 'WNET',
    Symbol: 'WNET',
    CoinName: 'Wavesnode.net',
    FullName: 'Wavesnode.net (WNET)',
  },
  PRG: {
    Name: 'PRG',
    Symbol: 'PRG',
    CoinName: 'Paragon',
    FullName: 'Paragon (PRG)',
  },
  THNX: {
    Name: 'THNX',
    Symbol: 'THNX',
    CoinName: 'ThankYou',
    FullName: 'ThankYou (THNX)',
  },
  WORM: {
    Name: 'WORM',
    Symbol: 'WORM',
    CoinName: 'HealthyWorm',
    FullName: 'HealthyWorm (WORM)',
  },
  FUCK: {
    Name: 'FUCK',
    Symbol: 'FUCK',
    CoinName: 'Fuck Token',
    FullName: 'Fuck Token (FUCK)',
  },
  SIFT: {
    Name: 'SIFT',
    Symbol: 'SIFT',
    CoinName: 'Smart Investment Fund Token',
    FullName: 'Smart Investment Fund Token (SIFT)',
  },
  IGNIS: {
    Name: 'IGNIS',
    Symbol: 'IGNIS',
    CoinName: 'Ignis',
    FullName: 'Ignis (IGNIS)',
  },
  IWT: {
    Name: 'IWT',
    Symbol: 'IWT',
    CoinName: 'IwToken',
    FullName: 'IwToken (IWT)',
  },
  JDC: {
    Name: 'JDC',
    Symbol: 'JDC',
    CoinName: 'JustDatingSite',
    FullName: 'JustDatingSite (JDC)',
  },
  ITF: {
    Name: 'ITF',
    Symbol: 'ITF',
    CoinName: 'Intelligent Trading',
    FullName: 'Intelligent Trading (ITF)',
  },
  AIX: {
    Name: 'AIX',
    Symbol: 'AIX',
    CoinName: 'Aigang',
    FullName: 'Aigang (AIX)',
  },
  ETERNALC: {
    Name: 'ETERNALC',
    Symbol: 'ETERNALC',
    CoinName: 'Eternal Coin',
    FullName: 'Eternal Coin (ETERNALC)',
  },
  ENTRP: {
    Name: 'ENTRP',
    Symbol: 'ENTRP',
    CoinName: 'Hut34 Project',
    FullName: 'Hut34 Project (ENTRP)',
  },
  PIX: {
    Name: 'PIX',
    Symbol: 'PIX',
    CoinName: 'Lampix',
    FullName: 'Lampix (PIX)',
  },
  MEDI: {
    Name: 'MEDI',
    Symbol: 'MEDI',
    CoinName: 'MediBond',
    FullName: 'MediBond (MEDI)',
  },
  HGT: {
    Name: 'HGT',
    Symbol: 'HGT',
    CoinName: 'Hello Gold',
    FullName: 'Hello Gold (HGT)',
  },
  LTA: {
    Name: 'LTA',
    Symbol: 'LTA',
    CoinName: 'Litra',
    FullName: 'Litra (LTA)',
  },
  NIMFA: {
    Name: 'NIMFA',
    Symbol: 'NIMFA',
    CoinName: 'Nimfamoney',
    FullName: 'Nimfamoney (NIMFA)',
  },
  SCOR: {
    Name: 'SCOR',
    Symbol: 'SCOR',
    CoinName: 'Scorista',
    FullName: 'Scorista (SCOR)',
  },
  MLS: {
    Name: 'MLS',
    Symbol: 'MLS',
    CoinName: 'CPROP',
    FullName: 'CPROP (MLS)',
  },
  COB: {
    Name: 'COB',
    Symbol: 'COB',
    CoinName: 'Cobinhood',
    FullName: 'Cobinhood (COB)',
  },
  BRO: {
    Name: 'BRO',
    Symbol: 'BRO',
    CoinName: 'Bitradio',
    FullName: 'Bitradio (BRO)',
  },
  MINEX: {
    Name: 'MINEX',
    Symbol: 'MINEX',
    CoinName: 'Minex',
    FullName: 'Minex (MINEX)',
  },
  ATL: {
    Name: 'ATL',
    Symbol: 'ATL',
    CoinName: 'ATLANT',
    FullName: 'ATLANT (ATL)',
  },
  DFT: {
    Name: 'DFT',
    Symbol: 'DFT',
    CoinName: 'Draftcoin',
    FullName: 'Draftcoin (DFT)',
  },
  SOJ: {
    Name: 'SOJ',
    Symbol: 'SOJ',
    CoinName: 'Sojourn Coin',
    FullName: 'Sojourn Coin (SOJ)',
  },
  HDG: {
    Name: 'HDG',
    Symbol: 'HDG',
    CoinName: 'Hedge Token',
    FullName: 'Hedge Token (HDG)',
  },
  STCN: {
    Name: 'STCN',
    Symbol: 'STCN',
    CoinName: 'Stakecoin',
    FullName: 'Stakecoin (STCN)',
  },
  RIYA: {
    Name: 'RIYA',
    Symbol: 'RIYA',
    CoinName: 'Etheriya',
    FullName: 'Etheriya (RIYA)',
  },
  LNK: {
    Name: 'LNK',
    Symbol: 'LNK',
    CoinName: 'Ethereum.Link',
    FullName: 'Ethereum.Link (LNK)',
  },
  MNTP: {
    Name: 'MNTP',
    Symbol: 'MNTP',
    CoinName: 'GoldMint',
    FullName: 'GoldMint (MNTP)',
  },
  ALTOCAR: {
    Name: 'ALTOCAR',
    Symbol: 'ALTOCAR',
    CoinName: 'AltoCar',
    FullName: 'AltoCar (ALTOCAR)',
  },
  BKX: {
    Name: 'BKX',
    Symbol: 'BKX',
    CoinName: 'BANKEX',
    FullName: 'BANKEX (BKX)',
  },
  BOU: {
    Name: 'BOU',
    Symbol: 'BOU',
    CoinName: 'Boulle',
    FullName: 'Boulle (BOU)',
  },
  TAPC: {
    Name: 'TAPC',
    Symbol: 'TAPC',
    CoinName: 'Tap Coin',
    FullName: 'Tap Coin (TAPC)',
  },
  AMT: {
    Name: 'AMT',
    Symbol: 'AMT',
    CoinName: 'Acumen',
    FullName: 'Acumen (AMT)',
  },
  GIM: {
    Name: 'GIM',
    Symbol: 'GIM',
    CoinName: 'Gimli',
    FullName: 'Gimli (GIM)',
  },
  NYC: {
    Name: 'NYC',
    Symbol: 'NYC',
    CoinName: 'NewYorkCoin',
    FullName: 'NewYorkCoin (NYC)',
  },
  LBTC: {
    Name: 'LBTC',
    Symbol: 'LBTC',
    CoinName: 'LiteBitcoin',
    FullName: 'LiteBitcoin (LBTC)',
  },
  FRAZ: {
    Name: 'FRAZ',
    Symbol: 'FRAZ',
    CoinName: 'FrazCoin',
    FullName: 'FrazCoin (FRAZ)',
  },
  EMT: {
    Name: 'EMT',
    Symbol: 'EMT',
    CoinName: 'EasyMine',
    FullName: 'EasyMine (EMT)',
  },
  HUBII: {
    Name: 'HUBII',
    Symbol: 'HUBII',
    CoinName: 'Hubii Network',
    FullName: 'Hubii Network (HUBII)',
  },
  KRONE: {
    Name: 'KRONE',
    Symbol: 'KRONE',
    CoinName: 'Kronecoin',
    FullName: 'Kronecoin (KRONE)',
  },
  AVALON: {
    Name: 'AVALON',
    Symbol: 'AVALON',
    CoinName: 'Avalon',
    FullName: 'Avalon (AVALON)',
  },
  BUILDTEAM: {
    Name: 'BUILDTEAM',
    Symbol: 'BUILDTEAM',
    CoinName: 'BuildTeam',
    FullName: 'BuildTeam (BUILDTEAM)',
  },
  LINX: {
    Name: 'LINX',
    Symbol: 'LINX',
    CoinName: 'Linx',
    FullName: 'Linx (LINX)',
  },
  XCXT: {
    Name: 'XCXT',
    Symbol: 'XCXT',
    CoinName: 'CoinonatX',
    FullName: 'CoinonatX (XCXT)',
  },
  BLAS: {
    Name: 'BLAS',
    Symbol: 'BLAS',
    CoinName: 'BlakeStar',
    FullName: 'BlakeStar (BLAS)',
  },
  SCL: {
    Name: 'SCL',
    Symbol: 'SCL',
    CoinName: 'Sociall',
    FullName: 'Sociall (SCL)',
  },
  CRTM: {
    Name: 'CRTM',
    Symbol: 'CRTM',
    CoinName: 'Cryptum',
    FullName: 'Cryptum (CRTM)',
  },
  PRIX: {
    Name: 'PRIX',
    Symbol: 'PRIX',
    CoinName: 'Privatix',
    FullName: 'Privatix (PRIX)',
  },
  CARTAXI: {
    Name: 'CARTAXI',
    Symbol: 'CARTAXI',
    CoinName: 'CarTaxi',
    FullName: 'CarTaxi (CARTAXI)',
  },
  ETHER: {
    Name: 'ETHER',
    Symbol: 'ETHER',
    CoinName: 'Etherparty',
    FullName: 'Etherparty (ETHER)',
  },
  TOKENSTARS: {
    Name: 'TOKENSTARS',
    Symbol: 'TOKENSTARS',
    CoinName: 'TokenStars',
    FullName: 'TokenStars (TOKENSTARS)',
  },
  WRC: {
    Name: 'WRC',
    Symbol: 'WRC',
    CoinName: 'Worldcore',
    FullName: 'Worldcore (WRC)',
  },
  WTC: {
    Name: 'WTC',
    Symbol: 'WTC',
    CoinName: 'Waltonchain',
    FullName: 'Waltonchain (WTC)',
  },
  BRX: {
    Name: 'BRX',
    Symbol: 'BRX',
    CoinName: 'Breakout Stake',
    FullName: 'Breakout Stake (BRX)',
  },
  UCASH: {
    Name: 'UCASH',
    Symbol: 'UCASH',
    CoinName: 'U.CASH',
    FullName: 'U.CASH (UCASH)',
  },
  WRT: {
    Name: 'WRT',
    Symbol: 'WRT',
    CoinName: 'WRTcoin',
    FullName: 'WRTcoin (WRT)',
  },
  ORME: {
    Name: 'ORME',
    Symbol: 'ORME',
    CoinName: 'Ormeus Coin',
    FullName: 'Ormeus Coin (ORME)',
  },
  DEEPG: {
    Name: 'DEEPG',
    Symbol: 'DEEPG',
    CoinName: 'Deep Gold',
    FullName: 'Deep Gold (DEEPG)',
  },
  ARNA: {
    Name: 'ARNA',
    Symbol: 'ARNA',
    CoinName: 'ARNA Panacea',
    FullName: 'ARNA Panacea (ARNA)',
  },
  PRP: {
    Name: 'PRP',
    Symbol: 'PRP',
    CoinName: 'Papyrus',
    FullName: 'Papyrus (PRP)',
  },
  BMC: {
    Name: 'BMC',
    Symbol: 'BMC',
    CoinName: 'Blackmoon Crypto',
    FullName: 'Blackmoon Crypto (BMC)',
  },
  SKRT: {
    Name: 'SKRT',
    Symbol: 'SKRT',
    CoinName: 'Skrilla Token',
    FullName: 'Skrilla Token (SKRT)',
  },
  '3DES': {
    Name: '3DES',
    Symbol: '3DES',
    CoinName: '3DES',
    FullName: '3DES (3DES)',
  },
  PYN: {
    Name: 'PYN',
    Symbol: 'PYN',
    CoinName: 'Paycent',
    FullName: 'Paycent (PYN)',
  },
  KAPU: {
    Name: 'KAPU',
    Symbol: 'KAPU',
    CoinName: 'Kapu',
    FullName: 'Kapu (KAPU)',
  },
  SENSE: {
    Name: 'SENSE',
    Symbol: 'SENSE',
    CoinName: 'Sense Token',
    FullName: 'Sense Token (SENSE)',
  },
  FC: {
    Name: 'FC',
    Symbol: 'FC',
    CoinName: 'Facecoin',
    FullName: 'Facecoin (FC)',
  },
  NRN: {
    Name: 'NRN',
    Symbol: 'NRN',
    CoinName: 'Doc.ai Neuron',
    FullName: 'Doc.ai Neuron (NRN)',
  },
  EVC: {
    Name: 'EVC',
    Symbol: 'EVC',
    CoinName: 'Eventchain',
    FullName: 'Eventchain (EVC)',
  },
  WIZ: {
    Name: 'WIZ',
    Symbol: 'WIZ',
    CoinName: 'Crowdwiz',
    FullName: 'Crowdwiz (WIZ)',
  },
  ATKN: {
    Name: 'ATKN',
    Symbol: 'ATKN',
    CoinName: 'A-Token',
    FullName: 'A-Token (ATKN)',
  },
  RUSTBITS: {
    Name: 'RUSTBITS',
    Symbol: 'RUSTBITS',
    CoinName: 'Rustbits',
    FullName: 'Rustbits (RUSTBITS)',
  },
  REX: {
    Name: 'REX',
    Symbol: 'REX',
    CoinName: 'Imbrex',
    FullName: 'Imbrex (REX)',
  },
  ETHD: {
    Name: 'ETHD',
    Symbol: 'ETHD',
    CoinName: 'Ethereum Dark',
    FullName: 'Ethereum Dark (ETHD)',
  },
  SUMO: {
    Name: 'SUMO',
    Symbol: 'SUMO',
    CoinName: 'Sumokoin',
    FullName: 'Sumokoin (SUMO)',
  },
  HYDROMINER: {
    Name: 'HYDROMINER',
    Symbol: 'HYDROMINER',
    CoinName: 'Hydrominer',
    FullName: 'Hydrominer (HYDROMINER)',
  },
  TKT: {
    Name: 'TKT',
    Symbol: 'TKT',
    CoinName: 'Crypto Tickets',
    FullName: 'Crypto Tickets (TKT)',
  },
  RHEA: {
    Name: 'RHEA',
    Symbol: 'RHEA',
    CoinName: 'Rhea',
    FullName: 'Rhea (RHEA)',
  },
  ART: {
    Name: 'ART',
    Symbol: 'ART',
    CoinName: 'Maecenas',
    FullName: 'Maecenas (ART)',
  },
  DRT: {
    Name: 'DRT',
    Symbol: 'DRT',
    CoinName: 'DomRaider',
    FullName: 'DomRaider (DRT)',
  },
  SNOV: {
    Name: 'SNOV',
    Symbol: 'SNOV',
    CoinName: 'Snovio',
    FullName: 'Snovio (SNOV)',
  },
  MTN: {
    Name: 'MTN',
    Symbol: 'MTN',
    CoinName: 'TrackNetToken',
    FullName: 'TrackNetToken (MTN)',
  },
  SND: {
    Name: 'SND',
    Symbol: 'SND',
    CoinName: 'Sandcoin',
    FullName: 'Sandcoin (SND)',
  },
  XP: {
    Name: 'XP',
    Symbol: 'XP',
    CoinName: 'Experience Points',
    FullName: 'Experience Points (XP)',
  },
  GLA: {
    Name: 'GLA',
    Symbol: 'GLA',
    CoinName: 'Gladius',
    FullName: 'Gladius (GLA)',
  },
  EZM: {
    Name: 'EZM',
    Symbol: 'EZM',
    CoinName: 'EZMarket',
    FullName: 'EZMarket (EZM)',
  },
  ODN: {
    Name: 'ODN',
    Symbol: 'ODN',
    CoinName: 'Obsidian',
    FullName: 'Obsidian (ODN)',
  },
  CLEARPOLL: {
    Name: 'CLEARPOLL',
    Symbol: 'CLEARPOLL',
    CoinName: 'ClearPoll',
    FullName: 'ClearPoll (CLEARPOLL)',
  },
  MTK: {
    Name: 'MTK',
    Symbol: 'MTK',
    CoinName: 'Moya Token',
    FullName: 'Moya Token (MTK)',
  },
  CAS: {
    Name: 'CAS',
    Symbol: 'CAS',
    CoinName: 'Cashaa',
    FullName: 'Cashaa (CAS)',
  },
  MAT: {
    Name: 'MAT',
    Symbol: 'MAT',
    CoinName: 'MiniApps',
    FullName: 'MiniApps (MAT)',
  },
  GJC: {
    Name: 'GJC',
    Symbol: 'GJC',
    CoinName: 'Global Jobcoin',
    FullName: 'Global Jobcoin (GJC)',
  },
  WIC: {
    Name: 'WIC',
    Symbol: 'WIC',
    CoinName: 'Wi Coin',
    FullName: 'Wi Coin (WIC)',
  },
  WEB: {
    Name: 'WEB',
    Symbol: 'WEB',
    CoinName: 'Webcoin',
    FullName: 'Webcoin (WEB)',
  },
  WAND: {
    Name: 'WAND',
    Symbol: 'WAND',
    CoinName: 'WandX',
    FullName: 'WandX (WAND)',
  },
  ELIX: {
    Name: 'ELIX',
    Symbol: 'ELIX',
    CoinName: 'Elixir',
    FullName: 'Elixir (ELIX)',
  },
  EBTC: {
    Name: 'EBTC',
    Symbol: 'EBTC',
    CoinName: 'eBitcoin',
    FullName: 'eBitcoin (EBTC)',
  },
  HAC: {
    Name: 'HAC',
    Symbol: 'HAC',
    CoinName: 'Hackspace Capital',
    FullName: 'Hackspace Capital (HAC)',
  },
  REGALCOIN: {
    Name: 'REGALCOIN',
    Symbol: 'REGALCOIN',
    CoinName: 'Regalcoin',
    FullName: 'Regalcoin (REGALCOIN)',
  },
  BIS: {
    Name: 'BIS',
    Symbol: 'BIS',
    CoinName: 'Bismuth',
    FullName: 'Bismuth (BIS)',
  },
  OPP: {
    Name: 'OPP',
    Symbol: 'OPP',
    CoinName: 'Opporty',
    FullName: 'Opporty (OPP)',
  },
  ROCK2: {
    Name: 'ROCK2',
    Symbol: 'ROCK2',
    CoinName: 'Ice Rock Mining',
    FullName: 'Ice Rock Mining (ROCK2)',
  },
  EARTH: {
    Name: 'EARTH',
    Symbol: 'EARTH',
    CoinName: 'Earth Token',
    FullName: 'Earth Token (EARTH)',
  },
  VSX: {
    Name: 'VSX',
    Symbol: 'VSX',
    CoinName: 'Vsync',
    FullName: 'Vsync (VSX)',
  },
  FLASHC: {
    Name: 'FLASHC',
    Symbol: 'FLASHC',
    CoinName: 'FLASH coin',
    FullName: 'FLASH coin (FLASHC)',
  },
  GRFT: {
    Name: 'GRFT',
    Symbol: 'GRFT',
    CoinName: 'Graft Blockchain',
    FullName: 'Graft Blockchain (GRFT)',
  },
  BTCZ: {
    Name: 'BTCZ',
    Symbol: 'BTCZ',
    CoinName: 'BitcoinZ',
    FullName: 'BitcoinZ (BTCZ)',
  },
  CZC: {
    Name: 'CZC',
    Symbol: 'CZC',
    CoinName: 'Crazy Coin',
    FullName: 'Crazy Coin (CZC)',
  },
  PPP: {
    Name: 'PPP',
    Symbol: 'PPP',
    CoinName: 'PayPie',
    FullName: 'PayPie (PPP)',
  },
  GUESS: {
    Name: 'GUESS',
    Symbol: 'GUESS',
    CoinName: 'Peerguess',
    FullName: 'Peerguess (GUESS)',
  },
  CANYA: {
    Name: 'CANYA',
    Symbol: 'CANYA',
    CoinName: 'CanYaCoin',
    FullName: 'CanYaCoin (CANYA)',
  },
  CIX: {
    Name: 'CIX',
    Symbol: 'CIX',
    CoinName: 'Cryptonetix',
    FullName: 'Cryptonetix (CIX)',
  },
  ERT: {
    Name: 'ERT',
    Symbol: 'ERT',
    CoinName: 'Esports.com',
    FullName: 'Esports.com (ERT)',
  },
  FLIK: {
    Name: 'FLIK',
    Symbol: 'FLIK',
    CoinName: 'FLiK',
    FullName: 'FLiK (FLIK)',
  },
  MULTIBOT: {
    Name: 'MULTIBOT',
    Symbol: 'MULTIBOT',
    CoinName: 'Multibot',
    FullName: 'Multibot (MULTIBOT)',
  },
  JVY: {
    Name: 'JVY',
    Symbol: 'JVY',
    CoinName: 'Javvy',
    FullName: 'Javvy (JVY)',
  },
  ALIS: {
    Name: 'ALIS',
    Symbol: 'ALIS',
    CoinName: 'ALISmedia',
    FullName: 'ALISmedia (ALIS)',
  },
  ARBI: {
    Name: 'ARBI',
    Symbol: 'ARBI',
    CoinName: 'Arbi',
    FullName: 'Arbi (ARBI)',
  },
  ROK: {
    Name: 'ROK',
    Symbol: 'ROK',
    CoinName: 'Rockchain',
    FullName: 'Rockchain (ROK)',
  },
  DAY: {
    Name: 'DAY',
    Symbol: 'DAY',
    CoinName: 'Chronologic',
    FullName: 'Chronologic (DAY)',
  },
  FLP: {
    Name: 'FLP',
    Symbol: 'FLP',
    CoinName: 'Gameflip',
    FullName: 'Gameflip (FLP)',
  },
  HXT: {
    Name: 'HXT',
    Symbol: 'HXT',
    CoinName: 'HextraCoin',
    FullName: 'HextraCoin (HXT)',
  },
  VRP: {
    Name: 'VRP',
    Symbol: 'VRP',
    CoinName: 'Prosense.tv',
    FullName: 'Prosense.tv (VRP)',
  },
  NTM: {
    Name: 'NTM',
    Symbol: 'NTM',
    CoinName: 'NetM',
    FullName: 'NetM (NTM)',
  },
  TZC: {
    Name: 'TZC',
    Symbol: 'TZC',
    CoinName: 'TrezarCoin',
    FullName: 'TrezarCoin (TZC)',
  },
  MCI: {
    Name: 'MCI',
    Symbol: 'MCI',
    CoinName: 'Musiconomi',
    FullName: 'Musiconomi (MCI)',
  },
  AIRTOKEN: {
    Name: 'AIRTOKEN',
    Symbol: 'AIRTOKEN',
    CoinName: 'AirToken',
    FullName: 'AirToken (AIRTOKEN)',
  },
  FUJIN: {
    Name: 'FUJIN',
    Symbol: 'FUJIN',
    CoinName: 'Fujinto',
    FullName: 'Fujinto (FUJIN)',
  },
  ATCC: {
    Name: 'ATCC',
    Symbol: 'ATCC',
    CoinName: 'ATC Coin',
    FullName: 'ATC Coin (ATCC)',
  },
  KOLION: {
    Name: 'KOLION',
    Symbol: 'KOLION',
    CoinName: 'Kolion',
    FullName: 'Kolion (KOLION)',
  },
  WILDC: {
    Name: 'WILDC',
    Symbol: 'WILDC',
    CoinName: 'Wild Crypto',
    FullName: 'Wild Crypto (WILDC)',
  },
  ELTC2: {
    Name: 'ELTC2',
    Symbol: 'ELTC2',
    CoinName: 'eLTC',
    FullName: 'eLTC (ELTC2)',
  },
  ILCT: {
    Name: 'ILCT',
    Symbol: 'ILCT',
    CoinName: 'ILCoin Token',
    FullName: 'ILCoin Token (ILCT)',
  },
  RYZ: {
    Name: 'RYZ',
    Symbol: 'RYZ',
    CoinName: 'Anryze',
    FullName: 'Anryze (RYZ)',
  },
  ELEMENTS: {
    Name: 'ELEMENTS',
    Symbol: 'ELEMENTS',
    CoinName: 'Elements',
    FullName: 'Elements (ELEMENTS)',
  },
  TER: {
    Name: 'TER',
    Symbol: 'TER',
    CoinName: 'TerraNovaCoin',
    FullName: 'TerraNovaCoin (TER)',
  },
  BQ: {
    Name: 'BQ',
    Symbol: 'BQ',
    CoinName: 'Bitqy',
    FullName: 'Bitqy (BQ)',
  },
  CLOUTIO: {
    Name: 'CLOUTIO',
    Symbol: 'CLOUTIO',
    CoinName: 'Clout',
    FullName: 'Clout (CLOUTIO)',
  },
  EVR: {
    Name: 'EVR',
    Symbol: 'EVR',
    CoinName: 'Everus',
    FullName: 'Everus (EVR)',
  },
  TOA: {
    Name: 'TOA',
    Symbol: 'TOA',
    CoinName: 'TOA Coin',
    FullName: 'TOA Coin (TOA)',
  },
  VIVO: {
    Name: 'VIVO',
    Symbol: 'VIVO',
    CoinName: 'VIVO Coin',
    FullName: 'VIVO Coin (VIVO)',
  },
  AURS: {
    Name: 'AURS',
    Symbol: 'AURS',
    CoinName: 'Aureus',
    FullName: 'Aureus (AURS)',
  },
  CAG: {
    Name: 'CAG',
    Symbol: 'CAG',
    CoinName: 'Change',
    FullName: 'Change (CAG)',
  },
  PLAYKEY: {
    Name: 'PLAYKEY',
    Symbol: 'PLAYKEY',
    CoinName: 'Playkey',
    FullName: 'Playkey (PLAYKEY)',
  },
  ECHT: {
    Name: 'ECHT',
    Symbol: 'ECHT',
    CoinName: 'e-Chat',
    FullName: 'e-Chat (ECHT)',
  },
  INXT: {
    Name: 'INXT',
    Symbol: 'INXT',
    CoinName: 'Internxt',
    FullName: 'Internxt (INXT)',
  },
  AUTHORSHIP: {
    Name: 'AUTHORSHIP',
    Symbol: 'AUTHORSHIP',
    CoinName: 'Authorship',
    FullName: 'Authorship (AUTHORSHIP)',
  },
  RGC: {
    Name: 'RGC',
    Symbol: 'RGC',
    CoinName: 'RG Coin',
    FullName: 'RG Coin (RGC)',
  },
  EBET: {
    Name: 'EBET',
    Symbol: 'EBET',
    CoinName: 'EthBet',
    FullName: 'EthBet (EBET)',
  },
  REV: {
    Name: 'REV',
    Symbol: 'REV',
    CoinName: 'Revain',
    FullName: 'Revain (REV)',
  },
  MODUM: {
    Name: 'MODUM',
    Symbol: 'MODUM',
    CoinName: 'Modum',
    FullName: 'Modum (MODUM)',
  },
  BITCM: {
    Name: 'BITCM',
    Symbol: 'BITCM',
    CoinName: 'Bitcomo',
    FullName: 'Bitcomo (BITCM)',
  },
  CPAY: {
    Name: 'CPAY',
    Symbol: 'CPAY',
    CoinName: 'CryptoPay',
    FullName: 'CryptoPay (CPAY)',
  },
  RUP: {
    Name: 'RUP',
    Symbol: 'RUP',
    CoinName: 'Rupee',
    FullName: 'Rupee (RUP)',
  },
  BON: {
    Name: 'BON',
    Symbol: 'BON',
    CoinName: 'Bonpay',
    FullName: 'Bonpay (BON)',
  },
  WHL: {
    Name: 'WHL',
    Symbol: 'WHL',
    CoinName: 'WhaleCoin',
    FullName: 'WhaleCoin (WHL)',
  },
  UP: {
    Name: 'UP',
    Symbol: 'UP',
    CoinName: 'UpToken',
    FullName: 'UpToken (UP)',
  },
  ETG: {
    Name: 'ETG',
    Symbol: 'ETG',
    CoinName: 'Ethereum Gold',
    FullName: 'Ethereum Gold (ETG)',
  },
  WOMEN: {
    Name: 'WOMEN',
    Symbol: 'WOMEN',
    CoinName: 'WomenCoin',
    FullName: 'WomenCoin (WOMEN)',
  },
  MAY: {
    Name: 'MAY',
    Symbol: 'MAY',
    CoinName: 'Theresa May Coin',
    FullName: 'Theresa May Coin (MAY)',
  },
  RNDR: {
    Name: 'RNDR',
    Symbol: 'RNDR',
    CoinName: 'Render Token',
    FullName: 'Render Token (RNDR)',
  },
  EDDIE: {
    Name: 'EDDIE',
    Symbol: 'EDDIE',
    CoinName: 'Eddie coin',
    FullName: 'Eddie coin (EDDIE)',
  },
  NAMO: {
    Name: 'NAMO',
    Symbol: 'NAMO',
    CoinName: 'NamoCoin',
    FullName: 'NamoCoin (NAMO)',
  },
  GAT: {
    Name: 'GAT',
    Symbol: 'GAT',
    CoinName: 'GATCOIN',
    FullName: 'GATCOIN (GAT)',
  },
  BLUE: {
    Name: 'BLUE',
    Symbol: 'BLUE',
    CoinName: 'Ethereum Blue',
    FullName: 'Ethereum Blue (BLUE)',
  },
  FLLW: {
    Name: 'FLLW',
    Symbol: 'FLLW',
    CoinName: 'Follow Coin',
    FullName: 'Follow Coin (FLLW)',
  },
  VZT: {
    Name: 'VZT',
    Symbol: 'VZT',
    CoinName: 'Vezt',
    FullName: 'Vezt (VZT)',
  },
  INDICOIN: {
    Name: 'INDICOIN',
    Symbol: 'INDICOIN',
    CoinName: 'IndiCoin',
    FullName: 'IndiCoin (INDICOIN)',
  },
  LUX: {
    Name: 'LUX',
    Symbol: 'LUX',
    CoinName: 'LUXCoin',
    FullName: 'LUXCoin (LUX)',
  },
  TBIS: {
    Name: 'TBIS',
    Symbol: 'TBIS',
    CoinName: 'TBIS token',
    FullName: 'TBIS token (TBIS)',
  },
  PIRL: {
    Name: 'PIRL',
    Symbol: 'PIRL',
    CoinName: 'Pirl',
    FullName: 'Pirl (PIRL)',
  },
  ECASH: {
    Name: 'ECASH',
    Symbol: 'ECASH',
    CoinName: 'Ethereum Cash',
    FullName: 'Ethereum Cash (ECASH)',
  },
  ODMC: {
    Name: 'ODMC',
    Symbol: 'ODMC',
    CoinName: 'ODMCoin',
    FullName: 'ODMCoin (ODMC)',
  },
  BRAT: {
    Name: 'BRAT',
    Symbol: 'BRAT',
    CoinName: 'BROTHER',
    FullName: 'BROTHER (BRAT)',
  },
  DTR: {
    Name: 'DTR',
    Symbol: 'DTR',
    CoinName: 'Dynamic Trading Rights',
    FullName: 'Dynamic Trading Rights (DTR)',
  },
  TKR: {
    Name: 'TKR',
    Symbol: 'TKR',
    CoinName: 'CryptoInsight',
    FullName: 'CryptoInsight (TKR)',
  },
  ELITE: {
    Name: 'ELITE',
    Symbol: 'ELITE',
    CoinName: 'EthereumLite',
    FullName: 'EthereumLite (ELITE)',
  },
  XIOS: {
    Name: 'XIOS',
    Symbol: 'XIOS',
    CoinName: 'Xios',
    FullName: 'Xios (XIOS)',
  },
  DOV: {
    Name: 'DOV',
    Symbol: 'DOV',
    CoinName: 'DOVU',
    FullName: 'DOVU (DOV)',
  },
  REA: {
    Name: 'REA',
    Symbol: 'REA',
    CoinName: 'Realisto',
    FullName: 'Realisto (REA)',
  },
  AVE: {
    Name: 'AVE',
    Symbol: 'AVE',
    CoinName: 'Avesta',
    FullName: 'Avesta (AVE)',
  },
  XNN: {
    Name: 'XNN',
    Symbol: 'XNN',
    CoinName: 'Xenon',
    FullName: 'Xenon (XNN)',
  },
  BTDX: {
    Name: 'BTDX',
    Symbol: 'BTDX',
    CoinName: 'Bitcloud 2.0',
    FullName: 'Bitcloud 2.0 (BTDX)',
  },
  LOAN: {
    Name: 'LOAN',
    Symbol: 'LOAN',
    CoinName: 'Lendoit',
    FullName: 'Lendoit (LOAN)',
  },
  BT1: {
    Name: 'BT1',
    Symbol: 'BT1',
    CoinName: 'Bitfinex Bitcoin Future',
    FullName: 'Bitfinex Bitcoin Future (BT1)',
  },
  BT2: {
    Name: 'BT2',
    Symbol: 'BT2',
    CoinName: 'Bitcoin SegWit2X',
    FullName: 'Bitcoin SegWit2X (BT2)',
  },
  SHARPE: {
    Name: 'SHARPE',
    Symbol: 'SHARPE',
    CoinName: 'Sharpe Capital',
    FullName: 'Sharpe Capital (SHARPE)',
  },
  EBST: {
    Name: 'EBST',
    Symbol: 'EBST',
    CoinName: 'eBoost',
    FullName: 'eBoost (EBST)',
  },
  KEK: {
    Name: 'KEK',
    Symbol: 'KEK',
    CoinName: 'KekCoin',
    FullName: 'KekCoin (KEK)',
  },
  AID: {
    Name: 'AID',
    Symbol: 'AID',
    CoinName: 'AidCoin',
    FullName: 'AidCoin (AID)',
  },
  BLHC: {
    Name: 'BLHC',
    Symbol: 'BLHC',
    CoinName: 'BlackholeCoin',
    FullName: 'BlackholeCoin (BLHC)',
  },
  ALTCOM: {
    Name: 'ALTCOM',
    Symbol: 'ALTCOM',
    CoinName: 'AltCommunity Coin',
    FullName: 'AltCommunity Coin (ALTCOM)',
  },
  UGC: {
    Name: 'UGC',
    Symbol: 'UGC',
    CoinName: 'ugChain',
    FullName: 'ugChain (UGC)',
  },
  CLD: {
    Name: 'CLD',
    Symbol: 'CLD',
    CoinName: 'Cloud',
    FullName: 'Cloud (CLD)',
  },
  OTN: {
    Name: 'OTN',
    Symbol: 'OTN',
    CoinName: 'Open Trading Network',
    FullName: 'Open Trading Network (OTN)',
  },
  POS: {
    Name: 'POS',
    Symbol: 'POS',
    CoinName: 'PoSToken',
    FullName: 'PoSToken (POS)',
  },
  REBL: {
    Name: 'REBL',
    Symbol: 'REBL',
    CoinName: 'REBL',
    FullName: 'REBL (REBL)',
  },
  NEOG: {
    Name: 'NEOG',
    Symbol: 'NEOG',
    CoinName: 'NEO Gold',
    FullName: 'NEO Gold (NEOG)',
  },
  EXN: {
    Name: 'EXN',
    Symbol: 'EXN',
    CoinName: 'ExchangeN',
    FullName: 'ExchangeN (EXN)',
  },
  XNS: {
    Name: 'XNS',
    Symbol: 'XNS',
    CoinName: 'Insolar',
    FullName: 'Insolar (XNS)',
  },
  TRCT: {
    Name: 'TRCT',
    Symbol: 'TRCT',
    CoinName: 'Tracto',
    FullName: 'Tracto (TRCT)',
  },
  UKG: {
    Name: 'UKG',
    Symbol: 'UKG',
    CoinName: 'UnikoinGold',
    FullName: 'UnikoinGold (UKG)',
  },
  BTCRED: {
    Name: 'BTCRED',
    Symbol: 'BTCRED',
    CoinName: 'Bitcoin Red',
    FullName: 'Bitcoin Red (BTCRED)',
  },
  CPEX: {
    Name: 'CPEX',
    Symbol: 'CPEX',
    CoinName: 'CoinPulseToken',
    FullName: 'CoinPulseToken (CPEX)',
  },
  JTX: {
    Name: 'JTX',
    Symbol: 'JTX',
    CoinName: 'Project J',
    FullName: 'Project J (JTX)',
  },
  AXT: {
    Name: 'AXT',
    Symbol: 'AXT',
    CoinName: 'AIX',
    FullName: 'AIX (AXT)',
  },
  RUPX: {
    Name: 'RUPX',
    Symbol: 'RUPX',
    CoinName: 'Rupaya',
    FullName: 'Rupaya (RUPX)',
  },
  BDR: {
    Name: 'BDR',
    Symbol: 'BDR',
    CoinName: 'BlueDragon',
    FullName: 'BlueDragon (BDR)',
  },
  INFINI: {
    Name: 'INFINI',
    Symbol: 'INFINI',
    CoinName: 'Infinity Economics',
    FullName: 'Infinity Economics (INFINI)',
  },
  TIOX: {
    Name: 'TIOX',
    Symbol: 'TIOX',
    CoinName: 'TIOx',
    FullName: 'TIOx (TIOX)',
  },
  HNCN: {
    Name: 'HNCN',
    Symbol: 'HNCN',
    CoinName: 'Huncoin',
    FullName: 'Huncoin (HNCN)',
  },
  MADC: {
    Name: 'MADC',
    Symbol: 'MADC',
    CoinName: 'MadCoin',
    FullName: 'MadCoin (MADC)',
  },
  PURA: {
    Name: 'PURA',
    Symbol: 'PURA',
    CoinName: 'Pura',
    FullName: 'Pura (PURA)',
  },
  INN: {
    Name: 'INN',
    Symbol: 'INN',
    CoinName: 'Innova',
    FullName: 'Innova (INN)',
  },
  HST: {
    Name: 'HST',
    Symbol: 'HST',
    CoinName: 'Decision Token',
    FullName: 'Decision Token (HST)',
  },
  BDL: {
    Name: 'BDL',
    Symbol: 'BDL',
    CoinName: 'Bitdeal',
    FullName: 'Bitdeal (BDL)',
  },
  CMS: {
    Name: 'CMS',
    Symbol: 'CMS',
    CoinName: 'COMSA',
    FullName: 'COMSA (CMS)',
  },
  XBL: {
    Name: 'XBL',
    Symbol: 'XBL',
    CoinName: 'Billionaire Token',
    FullName: 'Billionaire Token (XBL)',
  },
  ZEPH: {
    Name: 'ZEPH',
    Symbol: 'ZEPH',
    CoinName: 'Zeph',
    FullName: 'Zeph (ZEPH)',
  },
  ATFS: {
    Name: 'ATFS',
    Symbol: 'ATFS',
    CoinName: 'ATFS Project',
    FullName: 'ATFS Project (ATFS)',
  },
  GES: {
    Name: 'GES',
    Symbol: 'GES',
    CoinName: 'Galaxy eSolutions',
    FullName: 'Galaxy eSolutions (GES)',
  },
  PHORE: {
    Name: 'PHORE',
    Symbol: 'PHORE',
    CoinName: 'Phore',
    FullName: 'Phore (PHORE)',
  },
  LCASH: {
    Name: 'LCASH',
    Symbol: 'LCASH',
    CoinName: 'LitecoinCash',
    FullName: 'LitecoinCash (LCASH)',
  },
  CFD: {
    Name: 'CFD',
    Symbol: 'CFD',
    CoinName: 'Confido',
    FullName: 'Confido (CFD)',
  },
  SPHTX: {
    Name: 'SPHTX',
    Symbol: 'SPHTX',
    CoinName: 'SophiaTX',
    FullName: 'SophiaTX (SPHTX)',
  },
  PLC: {
    Name: 'PLC',
    Symbol: 'PLC',
    CoinName: 'PlusCoin',
    FullName: 'PlusCoin (PLC)',
  },
  DBET: {
    Name: 'DBET',
    Symbol: 'DBET',
    CoinName: 'Decent.bet',
    FullName: 'Decent.bet (DBET)',
  },
  XGOX: {
    Name: 'xGOx',
    Symbol: 'XGOX',
    CoinName: 'Go!',
    FullName: 'Go! (xGOx)',
  },
  NEWB: {
    Name: 'NEWB',
    Symbol: 'NEWB',
    CoinName: 'Newbium',
    FullName: 'Newbium (NEWB)',
  },
  LIFETOKEN: {
    Name: 'LIFETOKEN',
    Symbol: 'LIFETOKEN',
    CoinName: 'LIFE',
    FullName: 'LIFE (LIFETOKEN)',
  },
  RMC: {
    Name: 'RMC',
    Symbol: 'RMC',
    CoinName: 'Russian Mining Coin',
    FullName: 'Russian Mining Coin (RMC)',
  },
  CREDO: {
    Name: 'CREDO',
    Symbol: 'CREDO',
    CoinName: 'Credo',
    FullName: 'Credo (CREDO)',
  },
  MSR: {
    Name: 'MSR',
    Symbol: 'MSR',
    CoinName: 'Masari',
    FullName: 'Masari (MSR)',
  },
  CJT: {
    Name: 'CJT',
    Symbol: 'CJT',
    CoinName: 'ConnectJob Token',
    FullName: 'ConnectJob Token (CJT)',
  },
  BNK: {
    Name: 'BNK',
    Symbol: 'BNK',
    CoinName: 'Bankera',
    FullName: 'Bankera (BNK)',
  },
  ELLA: {
    Name: 'ELLA',
    Symbol: 'ELLA',
    CoinName: 'Ellaism',
    FullName: 'Ellaism (ELLA)',
  },
  BPL: {
    Name: 'BPL',
    Symbol: 'BPL',
    CoinName: 'BlockPool',
    FullName: 'BlockPool (BPL)',
  },
  COINVEST: {
    Name: 'COINVEST',
    Symbol: 'COINVEST',
    CoinName: 'Coinvest',
    FullName: 'Coinvest (COINVEST)',
  },
  ROCKETCOIN: {
    Name: 'ROCKETCOIN',
    Symbol: 'ROCKETCOIN',
    CoinName: 'RocketCoin',
    FullName: 'RocketCoin (ROCKETCOIN)',
  },
  DRXNE: {
    Name: 'DRXNE',
    Symbol: 'DRXNE',
    CoinName: 'Droxne',
    FullName: 'Droxne (DRXNE)',
  },
  SKR: {
    Name: 'SKR',
    Symbol: 'SKR',
    CoinName: 'Sakuracoin',
    FullName: 'Sakuracoin (SKR)',
  },
  XPTX: {
    Name: 'XPTX',
    Symbol: 'XPTX',
    CoinName: 'PlatinumBAR',
    FullName: 'PlatinumBAR (XPTX)',
  },
  ETK: {
    Name: 'ETK',
    Symbol: 'ETK',
    CoinName: 'Energi Token',
    FullName: 'Energi Token (ETK)',
  },
  ASTRONAUT: {
    Name: 'ASTRONAUT',
    Symbol: 'ASTRONAUT',
    CoinName: 'Astronaut',
    FullName: 'Astronaut (ASTRONAUT)',
  },
  EMPH: {
    Name: 'EMPH',
    Symbol: 'EMPH',
    CoinName: 'Emphy',
    FullName: 'Emphy (EMPH)',
  },
  SOAR: {
    Name: 'SOAR',
    Symbol: 'SOAR',
    CoinName: 'Soarcoin',
    FullName: 'Soarcoin (SOAR)',
  },
  EXY: {
    Name: 'EXY',
    Symbol: 'EXY',
    CoinName: 'Experty',
    FullName: 'Experty (EXY)',
  },
  ISH: {
    Name: 'ISH',
    Symbol: 'ISH',
    CoinName: 'Interstellar Holdings',
    FullName: 'Interstellar Holdings (ISH)',
  },
  MNX: {
    Name: 'MNX',
    Symbol: 'MNX',
    CoinName: 'MinexCoin',
    FullName: 'MinexCoin (MNX)',
  },
  CRDS: {
    Name: 'CRDS',
    Symbol: 'CRDS',
    CoinName: 'Credits',
    FullName: 'Credits (CRDS)',
  },
  VIU: {
    Name: 'VIU',
    Symbol: 'VIU',
    CoinName: 'Viuly',
    FullName: 'Viuly (VIU)',
  },
  SCRM: {
    Name: 'SCRM',
    Symbol: 'SCRM',
    CoinName: 'Scorum',
    FullName: 'Scorum (SCRM)',
  },
  DBR: {
    Name: 'DBR',
    Symbol: 'DBR',
    CoinName: 'Düber',
    FullName: 'Düber (DBR)',
  },
  STAC: {
    Name: 'STAC',
    Symbol: 'STAC',
    CoinName: 'STAC',
    FullName: 'STAC (STAC)',
  },
  RIPT: {
    Name: 'RIPT',
    Symbol: 'RIPT',
    CoinName: 'RiptideCoin',
    FullName: 'RiptideCoin (RIPT)',
  },
  GBX: {
    Name: 'GBX',
    Symbol: 'GBX',
    CoinName: 'GoByte',
    FullName: 'GoByte (GBX)',
  },
  CSTL: {
    Name: 'CSTL',
    Symbol: 'CSTL',
    CoinName: 'Castle',
    FullName: 'Castle (CSTL)',
  },
  XLQ: {
    Name: 'XLQ',
    Symbol: 'XLQ',
    CoinName: 'Alqo',
    FullName: 'Alqo (XLQ)',
  },
  TRIA: {
    Name: 'TRIA',
    Symbol: 'TRIA',
    CoinName: 'Triaconta',
    FullName: 'Triaconta (TRIA)',
  },
  PBL: {
    Name: 'PBL',
    Symbol: 'PBL',
    CoinName: 'Pebbles',
    FullName: 'Pebbles (PBL)',
  },
  MAG: {
    Name: 'MAG',
    Symbol: 'MAG',
    CoinName: 'Magnet',
    FullName: 'Magnet (MAG)',
  },
  STEX: {
    Name: 'STEX',
    Symbol: 'STEX',
    CoinName: 'STEX',
    FullName: 'STEX (STEX)',
  },
  UFR: {
    Name: 'UFR',
    Symbol: 'UFR',
    CoinName: 'Upfiring',
    FullName: 'Upfiring (UFR)',
  },
  LOCI: {
    Name: 'LOCI',
    Symbol: 'LOCI',
    CoinName: 'LociCoin',
    FullName: 'LociCoin (LOCI)',
  },
  TAU: {
    Name: 'TAU',
    Symbol: 'TAU',
    CoinName: 'Lamden Tau',
    FullName: 'Lamden Tau (TAU)',
  },
  LAB: {
    Name: 'LAB',
    Symbol: 'LAB',
    CoinName: 'Labrys',
    FullName: 'Labrys (LAB)',
  },
  DEB: {
    Name: 'DEB',
    Symbol: 'DEB',
    CoinName: 'Debitum Token',
    FullName: 'Debitum Token (DEB)',
  },
  FLIXX: {
    Name: 'FLIXX',
    Symbol: 'FLIXX',
    CoinName: 'Flixxo',
    FullName: 'Flixxo (FLIXX)',
  },
  FRD: {
    Name: 'FRD',
    Symbol: 'FRD',
    CoinName: 'Farad',
    FullName: 'Farad (FRD)',
  },
  PFR: {
    Name: 'PFR',
    Symbol: 'PFR',
    CoinName: 'PayFair',
    FullName: 'PayFair (PFR)',
  },
  ECA: {
    Name: 'ECA',
    Symbol: 'ECA',
    CoinName: 'Electra',
    FullName: 'Electra (ECA)',
  },
  LDM: {
    Name: 'LDM',
    Symbol: 'LDM',
    CoinName: 'Ludum token',
    FullName: 'Ludum token (LDM)',
  },
  LTG: {
    Name: 'LTG',
    Symbol: 'LTG',
    CoinName: 'LiteCoin Gold',
    FullName: 'LiteCoin Gold (LTG)',
  },
  STP: {
    Name: 'STP',
    Symbol: 'STP',
    CoinName: 'StashPay',
    FullName: 'StashPay (STP)',
  },
  SPANK: {
    Name: 'SPANK',
    Symbol: 'SPANK',
    CoinName: 'SpankChain',
    FullName: 'SpankChain (SPANK)',
  },
  WISH: {
    Name: 'WISH',
    Symbol: 'WISH',
    CoinName: 'MyWish',
    FullName: 'MyWish (WISH)',
  },
  AERM: {
    Name: 'AERM',
    Symbol: 'AERM',
    CoinName: 'Aerium',
    FullName: 'Aerium (AERM)',
  },
  PLX: {
    Name: 'PLX',
    Symbol: 'PLX',
    CoinName: 'PlexCoin',
    FullName: 'PlexCoin (PLX)',
  },
  AUTON: {
    Name: 'AUTON',
    Symbol: 'AUTON',
    CoinName: 'Autonio',
    FullName: 'Autonio (AUTON)',
  },
  ETHB: {
    Name: 'ETHB',
    Symbol: 'ETHB',
    CoinName: 'EtherBTC',
    FullName: 'EtherBTC (ETHB)',
  },
  CDX: {
    Name: 'CDX',
    Symbol: 'CDX',
    CoinName: 'CDX Network',
    FullName: 'CDX Network (CDX)',
  },
  FOOD: {
    Name: 'FOOD',
    Symbol: 'FOOD',
    CoinName: 'FoodCoin',
    FullName: 'FoodCoin (FOOD)',
  },
  DARICO: {
    Name: 'DARICO',
    Symbol: 'DARICO',
    CoinName: 'Darico',
    FullName: 'Darico (DARICO)',
  },
  VOT: {
    Name: 'VOT',
    Symbol: 'VOT',
    CoinName: 'Votecoin',
    FullName: 'Votecoin (VOT)',
  },
  SETHER: {
    Name: 'SETHER',
    Symbol: 'SETHER',
    CoinName: 'Sether',
    FullName: 'Sether (SETHER)',
  },
  XSH: {
    Name: 'XSH',
    Symbol: 'XSH',
    CoinName: 'SHIELD',
    FullName: 'SHIELD (XSH)',
  },
  GEA: {
    Name: 'GEA',
    Symbol: 'GEA',
    CoinName: 'Goldea',
    FullName: 'Goldea (GEA)',
  },
  BCO: {
    Name: 'BCO',
    Symbol: 'BCO',
    CoinName: 'BridgeCoin',
    FullName: 'BridgeCoin (BCO)',
  },
  DSR: {
    Name: 'DSR',
    Symbol: 'DSR',
    CoinName: 'Desire',
    FullName: 'Desire (DSR)',
  },
  BDG: {
    Name: 'BDG',
    Symbol: 'BDG',
    CoinName: 'BitDegree',
    FullName: 'BitDegree (BDG)',
  },
  ONG: {
    Name: 'ONG',
    Symbol: 'ONG',
    CoinName: 'SoMee.Social',
    FullName: 'SoMee.Social (ONG)',
  },
  PRL: {
    Name: 'PRL',
    Symbol: 'PRL',
    CoinName: 'Oyster Pearl',
    FullName: 'Oyster Pearl (PRL)',
  },
  BTCM: {
    Name: 'BTCM',
    Symbol: 'BTCM',
    CoinName: 'BTCMoon',
    FullName: 'BTCMoon (BTCM)',
  },
  ETBT: {
    Name: 'ETBT',
    Symbol: 'ETBT',
    CoinName: 'Ethereum Black',
    FullName: 'Ethereum Black (ETBT)',
  },
  ZCG: {
    Name: 'ZCG',
    Symbol: 'ZCG',
    CoinName: 'ZCashGOLD',
    FullName: 'ZCashGOLD (ZCG)',
  },
  MUT: {
    Name: 'MUT',
    Symbol: 'MUT',
    CoinName: 'Mutual Coin',
    FullName: 'Mutual Coin (MUT)',
  },
  DIVX: {
    Name: 'DIVX',
    Symbol: 'DIVX',
    CoinName: 'Divi Exchange Token',
    FullName: 'Divi Exchange Token (DIVX)',
  },
  CNBC: {
    Name: 'CNBC',
    Symbol: 'CNBC',
    CoinName: 'Cash & Back Coin',
    FullName: 'Cash & Back Coin (CNBC)',
  },
  RHOC: {
    Name: 'RHOC',
    Symbol: 'RHOC',
    CoinName: 'RChain',
    FullName: 'RChain (RHOC)',
  },
  XUN: {
    Name: 'XUN',
    Symbol: 'XUN',
    CoinName: 'UltraNote',
    FullName: 'UltraNote (XUN)',
  },
  RFL: {
    Name: 'RFL',
    Symbol: 'RFL',
    CoinName: 'RAFL',
    FullName: 'RAFL (RFL)',
  },
  ELTCOIN: {
    Name: 'ELTCOIN',
    Symbol: 'ELTCOIN',
    CoinName: 'ELTCOIN',
    FullName: 'ELTCOIN (ELTCOIN)',
  },
  GRX: {
    Name: 'GRX',
    Symbol: 'GRX',
    CoinName: 'Gold Reward Token',
    FullName: 'Gold Reward Token (GRX)',
  },
  NTK: {
    Name: 'NTK',
    Symbol: 'NTK',
    CoinName: 'Neurotoken',
    FullName: 'Neurotoken (NTK)',
  },
  ERO: {
    Name: 'ERO',
    Symbol: 'ERO',
    CoinName: 'Eroscoin',
    FullName: 'Eroscoin (ERO)',
  },
  RLX: {
    Name: 'RLX',
    Symbol: 'RLX',
    CoinName: 'Relex',
    FullName: 'Relex (RLX)',
  },
  CWV: {
    Name: 'CWV',
    Symbol: 'CWV',
    CoinName: 'CryptoWave',
    FullName: 'CryptoWave (CWV)',
  },
  NRO: {
    Name: 'NRO',
    Symbol: 'NRO',
    CoinName: 'Neuro',
    FullName: 'Neuro (NRO)',
  },
  SEND: {
    Name: 'SEND',
    Symbol: 'SEND',
    CoinName: 'Social Send',
    FullName: 'Social Send (SEND)',
  },
  GLT: {
    Name: 'GLT',
    Symbol: 'GLT',
    CoinName: 'GlobalToken',
    FullName: 'GlobalToken (GLT)',
  },
  X8X: {
    Name: 'X8X',
    Symbol: 'X8X',
    CoinName: 'X8Currency',
    FullName: 'X8Currency (X8X)',
  },
  DAXX: {
    Name: 'DAXX',
    Symbol: 'DAXX',
    CoinName: 'DaxxCoin',
    FullName: 'DaxxCoin (DAXX)',
  },
  BWK: {
    Name: 'BWK',
    Symbol: 'BWK',
    CoinName: 'Bulwark',
    FullName: 'Bulwark (BWK)',
  },
  FNTB: {
    Name: 'FNTB',
    Symbol: 'FNTB',
    CoinName: 'FinTab',
    FullName: 'FinTab (FNTB)',
  },
  XMRG: {
    Name: 'XMRG',
    Symbol: 'XMRG',
    CoinName: 'Monero Gold',
    FullName: 'Monero Gold (XMRG)',
  },
  BTCE: {
    Name: 'BTCE',
    Symbol: 'BTCE',
    CoinName: 'EthereumBitcoin',
    FullName: 'EthereumBitcoin (BTCE)',
  },
  BOXY: {
    Name: 'BOXY',
    Symbol: 'BOXY',
    CoinName: 'BoxyCoin',
    FullName: 'BoxyCoin (BOXY)',
  },
  UTNP: {
    Name: 'UTNP',
    Symbol: 'UTNP',
    CoinName: 'Universa',
    FullName: 'Universa (UTNP)',
  },
  EGAS: {
    Name: 'EGAS',
    Symbol: 'EGAS',
    CoinName: 'ETHGAS',
    FullName: 'ETHGAS (EGAS)',
  },
  DPP: {
    Name: 'DPP',
    Symbol: 'DPP',
    CoinName: 'Digital Assets Power Play',
    FullName: 'Digital Assets Power Play (DPP)',
  },
  TGT: {
    Name: 'TGT',
    Symbol: 'TGT',
    CoinName: 'TargetCoin',
    FullName: 'TargetCoin (TGT)',
  },
  BMT: {
    Name: 'BMT',
    Symbol: 'BMT',
    CoinName: 'BMChain',
    FullName: 'BMChain (BMT)',
  },
  MTRC: {
    Name: 'MTRC',
    Symbol: 'MTRC',
    CoinName: 'ModulTrade',
    FullName: 'ModulTrade (MTRC)',
  },
  BTCL: {
    Name: 'BTCL',
    Symbol: 'BTCL',
    CoinName: 'BTC Lite',
    FullName: 'BTC Lite (BTCL)',
  },
  PCN: {
    Name: 'PCN',
    Symbol: 'PCN',
    CoinName: 'PeepCoin',
    FullName: 'PeepCoin (PCN)',
  },
  PYP: {
    Name: 'PYP',
    Symbol: 'PYP',
    CoinName: 'PayPro',
    FullName: 'PayPro (PYP)',
  },
  CRED: {
    Name: 'CRED',
    Symbol: 'CRED',
    CoinName: 'Verify',
    FullName: 'Verify (CRED)',
  },
  SBTC: {
    Name: 'SBTC',
    Symbol: 'SBTC',
    CoinName: 'Super Bitcoin',
    FullName: 'Super Bitcoin (SBTC)',
  },
  KLKS: {
    Name: 'KLKS',
    Symbol: 'KLKS',
    CoinName: 'Kalkulus',
    FullName: 'Kalkulus (KLKS)',
  },
  AC3: {
    Name: 'AC3',
    Symbol: 'AC3',
    CoinName: 'AC3',
    FullName: 'AC3 (AC3)',
  },
  CHIPS: {
    Name: 'CHIPS',
    Symbol: 'CHIPS',
    CoinName: 'CHIPS',
    FullName: 'CHIPS (CHIPS)',
  },
  HKN: {
    Name: 'HKN',
    Symbol: 'HKN',
    CoinName: 'Hacken',
    FullName: 'Hacken (HKN)',
  },
  LOC: {
    Name: 'LOC',
    Symbol: 'LOC',
    CoinName: 'LockTrip',
    FullName: 'LockTrip (LOC)',
  },
  LTHN: {
    Name: 'LTHN',
    Symbol: 'LTHN',
    CoinName: 'Lethean',
    FullName: 'Lethean (LTHN)',
  },
  SMT: {
    Name: 'SMT',
    Symbol: 'SMT',
    CoinName: 'SmartMesh',
    FullName: 'SmartMesh (SMT)',
  },
  GER: {
    Name: 'GER',
    Symbol: 'GER',
    CoinName: 'GermanCoin',
    FullName: 'GermanCoin (GER)',
  },
  LTCU: {
    Name: 'LTCU',
    Symbol: 'LTCU',
    CoinName: 'LiteCoin Ultra',
    FullName: 'LiteCoin Ultra (LTCU)',
  },
  MGO: {
    Name: 'MGO',
    Symbol: 'MGO',
    CoinName: 'MobileGo',
    FullName: 'MobileGo (MGO)',
  },
  STRAKS: {
    Name: 'STRAKS',
    Symbol: 'STRAKS',
    CoinName: 'Straks',
    FullName: 'Straks (STRAKS)',
  },
  BANNER: {
    Name: 'BANNER',
    Symbol: 'BANNER',
    CoinName: 'BannerCoin',
    FullName: 'BannerCoin (BANNER)',
  },
  CCOS: {
    Name: 'CCOS',
    Symbol: 'CCOS',
    CoinName: 'CrowdCoinage',
    FullName: 'CrowdCoinage (CCOS)',
  },
  HAT: {
    Name: 'HAT',
    Symbol: 'HAT',
    CoinName: 'Hawala.Exchange',
    FullName: 'Hawala.Exchange (HAT)',
  },
  CWX: {
    Name: 'CWX',
    Symbol: 'CWX',
    CoinName: 'Crypto-X',
    FullName: 'Crypto-X (CWX)',
  },
  ZP: {
    Name: 'ZP',
    Symbol: 'ZP',
    CoinName: 'Zen Protocol',
    FullName: 'Zen Protocol (ZP)',
  },
  POP: {
    Name: 'POP',
    Symbol: 'POP',
    CoinName: 'PopularCoin',
    FullName: 'PopularCoin (POP)',
  },
  CROWD: {
    Name: 'CROWD',
    Symbol: 'CROWD',
    CoinName: 'CrowdCoin',
    FullName: 'CrowdCoin (CROWD)',
  },
  PNX: {
    Name: 'PNX',
    Symbol: 'PNX',
    CoinName: 'PhantomX',
    FullName: 'PhantomX (PNX)',
  },
  BITASEAN: {
    Name: 'BITASEAN',
    Symbol: 'BITASEAN',
    CoinName: 'BitAsean',
    FullName: 'BitAsean (BITASEAN)',
  },
  UTT: {
    Name: 'UTT',
    Symbol: 'UTT',
    CoinName: 'United Traders Token',
    FullName: 'United Traders Token (UTT)',
  },
  AMM: {
    Name: 'AMM',
    Symbol: 'AMM',
    CoinName: 'MicroMoney',
    FullName: 'MicroMoney (AMM)',
  },
  XCPO: {
    Name: 'XCPO',
    Symbol: 'XCPO',
    CoinName: 'Copico',
    FullName: 'Copico (XCPO)',
  },
  GET: {
    Name: 'GET',
    Symbol: 'GET',
    CoinName: 'Guaranteed Entrance Token',
    FullName: 'Guaranteed Entrance Token (GET)',
  },
  HTML: {
    Name: 'HTML',
    Symbol: 'HTML',
    CoinName: 'HTML Coin',
    FullName: 'HTML Coin (HTML)',
  },
  PARKGENE: {
    Name: 'PARKGENE',
    Symbol: 'PARKGENE',
    CoinName: 'PARKGENE',
    FullName: 'PARKGENE (PARKGENE)',
  },
  NMS: {
    Name: 'NMS',
    Symbol: 'NMS',
    CoinName: 'Numus',
    FullName: 'Numus (NMS)',
  },
  PHO: {
    Name: 'PHO',
    Symbol: 'PHO',
    CoinName: 'Photon',
    FullName: 'Photon (PHO)',
  },
  XTRA: {
    Name: 'XTRA',
    Symbol: 'XTRA',
    CoinName: 'ExtraCredit',
    FullName: 'ExtraCredit (XTRA)',
  },
  NTWK: {
    Name: 'NTWK',
    Symbol: 'NTWK',
    CoinName: 'Network Token',
    FullName: 'Network Token (NTWK)',
  },
  SUCR: {
    Name: 'SUCR',
    Symbol: 'SUCR',
    CoinName: 'Sucre',
    FullName: 'Sucre (SUCR)',
  },
  BYTHER: {
    Name: 'BYTHER',
    Symbol: 'BYTHER',
    CoinName: 'Bytether ',
    FullName: 'Bytether  (BYTHER)',
  },
  TOKU: {
    Name: 'TOKU',
    Symbol: 'TOKU',
    CoinName: 'TokugawaCoin',
    FullName: 'TokugawaCoin (TOKU)',
  },
  EREAL: {
    Name: 'EREAL',
    Symbol: 'EREAL',
    CoinName: 'eREAL',
    FullName: 'eREAL (EREAL)',
  },
  CPN: {
    Name: 'CPN',
    Symbol: 'CPN',
    CoinName: 'CompuCoin',
    FullName: 'CompuCoin (CPN)',
  },
  XFT: {
    Name: 'XFT',
    Symbol: 'XFT',
    CoinName: 'Fantasy Cash',
    FullName: 'Fantasy Cash (XFT)',
  },
  BSE: {
    Name: 'BSE',
    Symbol: 'BSE',
    CoinName: 'BitSerial',
    FullName: 'BitSerial (BSE)',
  },
  OMGC: {
    Name: 'OMGC',
    Symbol: 'OMGC',
    CoinName: 'OmiseGO Classic',
    FullName: 'OmiseGO Classic (OMGC)',
  },
  Q2C: {
    Name: 'Q2C',
    Symbol: 'Q2C',
    CoinName: 'QubitCoin',
    FullName: 'QubitCoin (Q2C)',
  },
  BLOOMT: {
    Name: 'BLOOMT',
    Symbol: 'BLOOMT',
    CoinName: 'Bloom Token',
    FullName: 'Bloom Token (BLOOMT)',
  },
  SPF: {
    Name: 'SPF',
    Symbol: 'SPF',
    CoinName: 'SportyCo',
    FullName: 'SportyCo (SPF)',
  },
  TDS: {
    Name: 'TDS',
    Symbol: 'TDS',
    CoinName: 'TokenDesk',
    FullName: 'TokenDesk (TDS)',
  },
  ORE: {
    Name: 'ORE',
    Symbol: 'ORE',
    CoinName: 'Galactrum',
    FullName: 'Galactrum (ORE)',
  },
  GOA: {
    Name: 'GOA',
    Symbol: 'GOA',
    CoinName: 'GoaCoin',
    FullName: 'GoaCoin (GOA)',
  },
  WAGE: {
    Name: 'WAGE',
    Symbol: 'WAGE',
    CoinName: 'Digiwage',
    FullName: 'Digiwage (WAGE)',
  },
  GUN: {
    Name: 'GUN',
    Symbol: 'GUN',
    CoinName: 'GunCoin',
    FullName: 'GunCoin (GUN)',
  },
  DFSPORTS: {
    Name: 'DFSPORTS',
    Symbol: 'DFSPORTS',
    CoinName: 'Digital Fantasy Sports',
    FullName: 'Digital Fantasy Sports (DFSPORTS)',
  },
  POLISPLAY: {
    Name: 'POLISPLAY',
    Symbol: 'POLISPLAY',
    CoinName: 'PolisPay',
    FullName: 'PolisPay (POLISPLAY)',
  },
  WELL: {
    Name: 'WELL',
    Symbol: 'WELL',
    CoinName: 'Well',
    FullName: 'Well (WELL)',
  },
  FLOT: {
    Name: 'FLOT',
    Symbol: 'FLOT',
    CoinName: 'FireLotto',
    FullName: 'FireLotto (FLOT)',
  },
  CL: {
    Name: 'CL',
    Symbol: 'CL',
    CoinName: 'CoinLancer',
    FullName: 'CoinLancer (CL)',
  },
  SHND: {
    Name: 'SHND',
    Symbol: 'SHND',
    CoinName: 'StrongHands',
    FullName: 'StrongHands (SHND)',
  },
  AUA: {
    Name: 'AUA',
    Symbol: 'AUA',
    CoinName: 'ArubaCoin',
    FullName: 'ArubaCoin (AUA)',
  },
  DNN: {
    Name: 'DNN',
    Symbol: 'DNN',
    CoinName: 'DNN Token',
    FullName: 'DNN Token (DNN)',
  },
  SAGA: {
    Name: 'SAGA',
    Symbol: 'SAGA',
    CoinName: 'SagaCoin',
    FullName: 'SagaCoin (SAGA)',
  },
  IRL: {
    Name: 'IRL',
    Symbol: 'IRL',
    CoinName: 'IrishCoin',
    FullName: 'IrishCoin (IRL)',
  },
  BODHI: {
    Name: 'BODHI',
    Symbol: 'BODHI',
    CoinName: 'Bodhi Network',
    FullName: 'Bodhi Network (BODHI)',
  },
  TROLL: {
    Name: 'TROLL',
    Symbol: 'TROLL',
    CoinName: 'Trollcoin',
    FullName: 'Trollcoin (TROLL)',
  },
  LCP: {
    Name: 'LCP',
    Symbol: 'LCP',
    CoinName: 'Litecoin Plus',
    FullName: 'Litecoin Plus (LCP)',
  },
  IETH: {
    Name: 'IETH',
    Symbol: 'IETH',
    CoinName: 'iEthereum',
    FullName: 'iEthereum (IETH)',
  },
  TGCC: {
    Name: 'TGCC',
    Symbol: 'TGCC',
    CoinName: 'TheGCCcoin',
    FullName: 'TheGCCcoin (TGCC)',
  },
  SDRN: {
    Name: 'SDRN',
    Symbol: 'SDRN',
    CoinName: 'Senderon',
    FullName: 'Senderon (SDRN)',
  },
  INK: {
    Name: 'INK',
    Symbol: 'INK',
    CoinName: 'Ink',
    FullName: 'Ink (INK)',
  },
  KBR: {
    Name: 'KBR',
    Symbol: 'KBR',
    CoinName: 'Kubera Coin',
    FullName: 'Kubera Coin (KBR)',
  },
  MONK: {
    Name: 'MONK',
    Symbol: 'MONK',
    CoinName: 'Monkey Project',
    FullName: 'Monkey Project (MONK)',
  },
  MGN: {
    Name: 'MGN',
    Symbol: 'MGN',
    CoinName: 'MagnaCoin',
    FullName: 'MagnaCoin (MGN)',
  },
  KZC: {
    Name: 'KZC',
    Symbol: 'KZC',
    CoinName: 'KZCash',
    FullName: 'KZCash (KZC)',
  },
  GNR: {
    Name: 'GNR',
    Symbol: 'GNR',
    CoinName: 'Gainer',
    FullName: 'Gainer (GNR)',
  },
  LNC: {
    Name: 'LNC',
    Symbol: 'LNC',
    CoinName: 'Blocklancer',
    FullName: 'Blocklancer (LNC)',
  },
  LWF: {
    Name: 'LWF',
    Symbol: 'LWF',
    CoinName: 'Local World Forwarders',
    FullName: 'Local World Forwarders (LWF)',
  },
  WCG: {
    Name: 'WCG',
    Symbol: 'WCG',
    CoinName: 'World Crypto Gold',
    FullName: 'World Crypto Gold (WCG)',
  },
  BHIVE: {
    Name: 'BHIVE',
    Symbol: 'BHIVE',
    CoinName: 'Hive',
    FullName: 'Hive (BHIVE)',
  },
  GX: {
    Name: 'GX',
    Symbol: 'GX',
    CoinName: 'GameX',
    FullName: 'GameX (GX)',
  },
  LCK: {
    Name: 'LCK',
    Symbol: 'LCK',
    CoinName: 'Luckbox',
    FullName: 'Luckbox (LCK)',
  },
  MFG: {
    Name: 'MFG',
    Symbol: 'MFG',
    CoinName: 'SyncFab',
    FullName: 'SyncFab (MFG)',
  },
  ETL: {
    Name: 'ETL',
    Symbol: 'ETL',
    CoinName: 'EtherLite',
    FullName: 'EtherLite (ETL)',
  },
  DRG: {
    Name: 'DRG',
    Symbol: 'DRG',
    CoinName: 'Dragon Coin',
    FullName: 'Dragon Coin (DRG)',
  },
  SPX: {
    Name: 'SPX',
    Symbol: 'SPX',
    CoinName: 'Sp8de',
    FullName: 'Sp8de (SPX)',
  },
  ONL: {
    Name: 'ONL',
    Symbol: 'ONL',
    CoinName: 'On.Live',
    FullName: 'On.Live (ONL)',
  },
  ET4: {
    Name: 'ET4',
    Symbol: 'ET4',
    CoinName: 'Eticket4',
    FullName: 'Eticket4 (ET4)',
  },
  LCT: {
    Name: 'LCT',
    Symbol: 'LCT',
    CoinName: 'LendConnect',
    FullName: 'LendConnect (LCT)',
  },
  EBC: {
    Name: 'EBC',
    Symbol: 'EBC',
    CoinName: 'EBCoin',
    FullName: 'EBCoin (EBC)',
  },
  VESTA: {
    Name: 'VESTA',
    Symbol: 'VESTA',
    CoinName: 'Vestarin',
    FullName: 'Vestarin (VESTA)',
  },
  INT: {
    Name: 'INT',
    Symbol: 'INT',
    CoinName: 'Internet Node token',
    FullName: 'Internet Node token (INT)',
  },
  CPY: {
    Name: 'CPY',
    Symbol: 'CPY',
    CoinName: 'COPYTRACK',
    FullName: 'COPYTRACK (CPY)',
  },
  STEN: {
    Name: 'STEN',
    Symbol: 'STEN',
    CoinName: 'Steneum Coin',
    FullName: 'Steneum Coin (STEN)',
  },
  SFU: {
    Name: 'SFU',
    Symbol: 'SFU',
    CoinName: 'Saifu',
    FullName: 'Saifu (SFU)',
  },
  PCOIN: {
    Name: 'PCOIN',
    Symbol: 'PCOIN',
    CoinName: 'Pioneer Coin',
    FullName: 'Pioneer Coin (PCOIN)',
  },
  BLNM: {
    Name: 'BLNM',
    Symbol: 'BLNM',
    CoinName: 'Bolenum',
    FullName: 'Bolenum (BLNM)',
  },
  LUC: {
    Name: 'LUC',
    Symbol: 'LUC',
    CoinName: 'Play 2 Live',
    FullName: 'Play 2 Live (LUC)',
  },
  ETHERDELTA: {
    Name: 'ETHERDELTA',
    Symbol: 'ETHERDELTA',
    CoinName: 'EtherDelta',
    FullName: 'EtherDelta (ETHERDELTA)',
  },
  CYDER: {
    Name: 'CYDER',
    Symbol: 'CYDER',
    CoinName: 'Cyder Coin',
    FullName: 'Cyder Coin (CYDER)',
  },
  SRNT: {
    Name: 'SRNT',
    Symbol: 'SRNT',
    CoinName: 'Serenity',
    FullName: 'Serenity (SRNT)',
  },
  MLT: {
    Name: 'MLT',
    Symbol: 'MLT',
    CoinName: 'MultiGames',
    FullName: 'MultiGames (MLT)',
  },
  UBTC: {
    Name: 'UBTC',
    Symbol: 'UBTC',
    CoinName: 'UnitedBitcoin',
    FullName: 'UnitedBitcoin (UBTC)',
  },
  BTO: {
    Name: 'BTO',
    Symbol: 'BTO',
    CoinName: 'Bottos',
    FullName: 'Bottos (BTO)',
  },
  DOCC: {
    Name: 'DOCC',
    Symbol: 'DOCC',
    CoinName: 'Doc Coin',
    FullName: 'Doc Coin (DOCC)',
  },
  ARCT: {
    Name: 'ARCT',
    Symbol: 'ARCT',
    CoinName: 'ArbitrageCT',
    FullName: 'ArbitrageCT (ARCT)',
  },
  IMVR: {
    Name: 'IMVR',
    Symbol: 'IMVR',
    CoinName: 'ImmVRse',
    FullName: 'ImmVRse (IMVR)',
  },
  IDH: {
    Name: 'IDH',
    Symbol: 'IDH',
    CoinName: 'IndaHash',
    FullName: 'IndaHash (IDH)',
  },
  ITZ: {
    Name: 'ITZ',
    Symbol: 'ITZ',
    CoinName: 'Interzone',
    FullName: 'Interzone (ITZ)',
  },
  XBP: {
    Name: 'XBP',
    Symbol: 'XBP',
    CoinName: 'Black Pearl Coin',
    FullName: 'Black Pearl Coin (XBP)',
  },
  EXRN: {
    Name: 'EXRN',
    Symbol: 'EXRN',
    CoinName: 'EXRNchain',
    FullName: 'EXRNchain (EXRN)',
  },
  LGO: {
    Name: 'LGO',
    Symbol: 'LGO',
    CoinName: 'Legolas Exchange',
    FullName: 'Legolas Exchange (LGO)',
  },
  TRINI: {
    Name: 'TRINI',
    Symbol: 'TRINI',
    CoinName: 'Trinity Network Credit',
    FullName: 'Trinity Network Credit (TRINI)',
  },
  FSBT: {
    Name: 'FSBT',
    Symbol: 'FSBT',
    CoinName: 'Forty Seven Bank',
    FullName: 'Forty Seven Bank (FSBT)',
  },
  CFTY: {
    Name: 'CFTY',
    Symbol: 'CFTY',
    CoinName: 'Crafty',
    FullName: 'Crafty (CFTY)',
  },
  DTA: {
    Name: 'DTA',
    Symbol: 'DTA',
    CoinName: 'Data',
    FullName: 'Data (DTA)',
  },
  DTX: {
    Name: 'DTX',
    Symbol: 'DTX',
    CoinName: 'DataBroker DAO',
    FullName: 'DataBroker DAO (DTX)',
  },
  MCU: {
    Name: 'MCU',
    Symbol: 'MCU',
    CoinName: 'MediChain',
    FullName: 'MediChain (MCU)',
  },
  OCN: {
    Name: 'OCN',
    Symbol: 'OCN',
    CoinName: 'Odyssey',
    FullName: 'Odyssey (OCN)',
  },
  PRPS: {
    Name: 'PRPS',
    Symbol: 'PRPS',
    CoinName: 'Purpose',
    FullName: 'Purpose (PRPS)',
  },
  DUBI: {
    Name: 'DUBI',
    Symbol: 'DUBI',
    CoinName: 'Decentralized Universal Basic Income',
    FullName: 'Decentralized Universal Basic Income (DUBI)',
  },
  SGN: {
    Name: 'SGN',
    Symbol: 'SGN',
    CoinName: 'Signals Network',
    FullName: 'Signals Network (SGN)',
  },
  MOT: {
    Name: 'MOT',
    Symbol: 'MOT',
    CoinName: 'Olympus Labs',
    FullName: 'Olympus Labs (MOT)',
  },
  HORSE: {
    Name: 'HORSE',
    Symbol: 'HORSE',
    CoinName: 'Ethorse',
    FullName: 'Ethorse (HORSE)',
  },
  MONF: {
    Name: 'MONF',
    Symbol: 'MONF',
    CoinName: 'Monfter',
    FullName: 'Monfter (MONF)',
  },
  QBAO: {
    Name: 'QBAO',
    Symbol: 'QBAO',
    CoinName: 'Qbao',
    FullName: 'Qbao (QBAO)',
  },
  ACCN: {
    Name: 'ACCN',
    Symbol: 'ACCN',
    CoinName: 'Accelerator Network',
    FullName: 'Accelerator Network (ACCN)',
  },
  OPC: {
    Name: 'OPC',
    Symbol: 'OPC',
    CoinName: 'OP Coin',
    FullName: 'OP Coin (OPC)',
  },
  SAF: {
    Name: 'SAF',
    Symbol: 'SAF',
    CoinName: 'Safinus',
    FullName: 'Safinus (SAF)',
  },
  SHACOIN: {
    Name: 'SHACOIN',
    Symbol: 'SHACOIN',
    CoinName: 'Shacoin',
    FullName: 'Shacoin (SHACOIN)',
  },
  PYLNT: {
    Name: 'PYLNT',
    Symbol: 'PYLNT',
    CoinName: 'Pylon Network',
    FullName: 'Pylon Network (PYLNT)',
  },
  GRLC: {
    Name: 'GRLC',
    Symbol: 'GRLC',
    CoinName: 'Garlicoin',
    FullName: 'Garlicoin (GRLC)',
  },
  EVE: {
    Name: 'EVE',
    Symbol: 'EVE',
    CoinName: 'Devery',
    FullName: 'Devery (EVE)',
  },
  REPUX: {
    Name: 'REPUX',
    Symbol: 'REPUX',
    CoinName: 'Repux',
    FullName: 'Repux (REPUX)',
  },
  JOYT: {
    Name: 'JOYT',
    Symbol: 'JOYT',
    CoinName: 'JoyToken',
    FullName: 'JoyToken (JOYT)',
  },
  CAPD: {
    Name: 'CAPD',
    Symbol: 'CAPD',
    CoinName: 'Capdax',
    FullName: 'Capdax (CAPD)',
  },
  BTW: {
    Name: 'BTW',
    Symbol: 'BTW',
    CoinName: 'BitWhite',
    FullName: 'BitWhite (BTW)',
  },
  AXPR: {
    Name: 'AXPR',
    Symbol: 'AXPR',
    CoinName: 'aXpire',
    FullName: 'aXpire (AXPR)',
  },
  CPC: {
    Name: 'CPC',
    Symbol: 'CPC',
    CoinName: 'CPChain',
    FullName: 'CPChain (CPC)',
  },
  SPEND: {
    Name: 'SPEND',
    Symbol: 'SPEND',
    CoinName: 'Spend',
    FullName: 'Spend (SPEND)',
  },
  ZPT: {
    Name: 'ZPT',
    Symbol: 'ZPT',
    CoinName: 'Zeepin',
    FullName: 'Zeepin (ZPT)',
  },
  CROAT: {
    Name: 'CROAT',
    Symbol: 'CROAT',
    CoinName: 'Croat',
    FullName: 'Croat (CROAT)',
  },
  SXDT: {
    Name: 'SXDT',
    Symbol: 'SXDT',
    CoinName: 'SPECTRE Dividend Token',
    FullName: 'SPECTRE Dividend Token (SXDT)',
  },
  SXUT: {
    Name: 'SXUT',
    Symbol: 'SXUT',
    CoinName: 'SPECTRE Utility Token',
    FullName: 'SPECTRE Utility Token (SXUT)',
  },
  VALORBIT: {
    Name: 'VALORBIT',
    Symbol: 'VALORBIT',
    CoinName: 'Valorbit',
    FullName: 'Valorbit (VALORBIT)',
  },
  STK: {
    Name: 'STK',
    Symbol: 'STK',
    CoinName: 'STK Token',
    FullName: 'STK Token (STK)',
  },
  MZX: {
    Name: 'MZX',
    Symbol: 'MZX',
    CoinName: 'Mosaic Network',
    FullName: 'Mosaic Network (MZX)',
  },
  CRC: {
    Name: 'CRC',
    Symbol: 'CRC',
    CoinName: 'CryCash',
    FullName: 'CryCash (CRC)',
  },
  Q1S: {
    Name: 'Q1S',
    Symbol: 'Q1S',
    CoinName: 'Quantum1Net',
    FullName: 'Quantum1Net (Q1S)',
  },
  XTO: {
    Name: 'XTO',
    Symbol: 'XTO',
    CoinName: 'Tao',
    FullName: 'Tao (XTO)',
  },
  TPAY: {
    Name: 'TPAY',
    Symbol: 'TPAY',
    CoinName: 'TokenPay',
    FullName: 'TokenPay (TPAY)',
  },
  CXO: {
    Name: 'CXO',
    Symbol: 'CXO',
    CoinName: 'CargoX',
    FullName: 'CargoX (CXO)',
  },
  WT: {
    Name: 'WT',
    Symbol: 'WT',
    CoinName: 'WeToken',
    FullName: 'WeToken (WT)',
  },
  HGS: {
    Name: 'HGS',
    Symbol: 'HGS',
    CoinName: 'HashGains',
    FullName: 'HashGains (HGS)',
  },
  SISA: {
    Name: 'SISA',
    Symbol: 'SISA',
    CoinName: 'Strategic Investments in Significant Areas',
    FullName: 'Strategic Investments in Significant Areas (SISA)',
  },
  EBIT: {
    Name: 'EBIT',
    Symbol: 'EBIT',
    CoinName: 'eBit',
    FullName: 'eBit (EBIT)',
  },
  RCT: {
    Name: 'RCT',
    Symbol: 'RCT',
    CoinName: 'RealChain',
    FullName: 'RealChain (RCT)',
  },
  CUZ: {
    Name: 'CUZ',
    Symbol: 'CUZ',
    CoinName: 'Cool Cousin',
    FullName: 'Cool Cousin (CUZ)',
  },
  BETR: {
    Name: 'BETR',
    Symbol: 'BETR',
    CoinName: 'BetterBetting',
    FullName: 'BetterBetting (BETR)',
  },
  GIMMER: {
    Name: 'GIMMER',
    Symbol: 'GIMMER',
    CoinName: 'Gimmer',
    FullName: 'Gimmer (GIMMER)',
  },
  LHC: {
    Name: 'LHC',
    Symbol: 'LHC',
    CoinName: 'LHCoin',
    FullName: 'LHCoin (LHC)',
  },
  CVNG: {
    Name: 'CVNG',
    Symbol: 'CVNG',
    CoinName: 'Crave-NG',
    FullName: 'Crave-NG (CVNG)',
  },
  CWIS: {
    Name: 'CWIS',
    Symbol: 'CWIS',
    CoinName: 'Crypto Wisdom Coin',
    FullName: 'Crypto Wisdom Coin (CWIS)',
  },
  GRON: {
    Name: 'GRON',
    Symbol: 'GRON',
    CoinName: 'Gron Digital',
    FullName: 'Gron Digital (GRON)',
  },
  SWM: {
    Name: 'SWM',
    Symbol: 'SWM',
    CoinName: 'Swarm Fund',
    FullName: 'Swarm Fund (SWM)',
  },
  MDCL: {
    Name: 'MDCL',
    Symbol: 'MDCL',
    CoinName: 'Medicalchain',
    FullName: 'Medicalchain (MDCL)',
  },
  DNO: {
    Name: 'DNO',
    Symbol: 'DNO',
    CoinName: 'Denaro',
    FullName: 'Denaro (DNO)',
  },
  eFIC: {
    Name: 'eFIC',
    Symbol: 'eFIC',
    CoinName: 'FIC Network',
    FullName: 'FIC Network (eFIC)',
  },
  TKY: {
    Name: 'TKY',
    Symbol: 'TKY',
    CoinName: 'THEKEY Token',
    FullName: 'THEKEY Token (TKY)',
  },
  BANCA: {
    Name: 'BANCA',
    Symbol: 'BANCA',
    CoinName: 'BANCA',
    FullName: 'BANCA (BANCA)',
  },
  TRTL: {
    Name: 'TRTL',
    Symbol: 'TRTL',
    CoinName: 'TurtleCoin',
    FullName: 'TurtleCoin (TRTL)',
  },
  DRPU: {
    Name: 'DRPU',
    Symbol: 'DRPU',
    CoinName: 'DRP Utility',
    FullName: 'DRP Utility (DRPU)',
  },
  DOR: {
    Name: 'DOR',
    Symbol: 'DOR',
    CoinName: 'Dorado',
    FullName: 'Dorado (DOR)',
  },
  PRFT: {
    Name: 'PRFT',
    Symbol: 'PRFT',
    CoinName: 'Proof Suite Token',
    FullName: 'Proof Suite Token (PRFT)',
  },
  PARETO: {
    Name: 'PARETO',
    Symbol: 'PARETO',
    CoinName: 'Pareto Network Token',
    FullName: 'Pareto Network Token (PARETO)',
  },
  DTRC: {
    Name: 'DTRC',
    Symbol: 'DTRC',
    CoinName: 'Datarius',
    FullName: 'Datarius (DTRC)',
  },
  NDLC: {
    Name: 'NDLC',
    Symbol: 'NDLC',
    CoinName: 'NeedleCoin',
    FullName: 'NeedleCoin (NDLC)',
  },
  MUN: {
    Name: 'MUN',
    Symbol: 'MUN',
    CoinName: 'MUNcoin',
    FullName: 'MUNcoin (MUN)',
  },
  TIG: {
    Name: 'TIG',
    Symbol: 'TIG',
    CoinName: 'Tigereum',
    FullName: 'Tigereum (TIG)',
  },
  LYK: {
    Name: 'LYK',
    Symbol: 'LYK',
    CoinName: 'Loyakk Vega',
    FullName: 'Loyakk Vega (LYK)',
  },
  NYX: {
    Name: 'NYX',
    Symbol: 'NYX',
    CoinName: 'NYXCOIN',
    FullName: 'NYXCOIN (NYX)',
  },
  DATAWALLET: {
    Name: 'DATAWALLET',
    Symbol: 'DATAWALLET',
    CoinName: 'DataWallet',
    FullName: 'DataWallet (DATAWALLET)',
  },
  SAT: {
    Name: 'SAT',
    Symbol: 'SAT',
    CoinName: 'Satisfaction Token',
    FullName: 'Satisfaction Token (SAT)',
  },
  CRL: {
    Name: 'CRL',
    Symbol: 'CRL',
    CoinName: 'Cryptelo Coin',
    FullName: 'Cryptelo Coin (CRL)',
  },
  ORI: {
    Name: 'ORI',
    Symbol: 'ORI',
    CoinName: 'Origami',
    FullName: 'Origami (ORI)',
  },
  USX: {
    Name: 'USX',
    Symbol: 'USX',
    CoinName: 'USX Quantum',
    FullName: 'USX Quantum (USX)',
  },
  LGR: {
    Name: 'LGR',
    Symbol: 'LGR',
    CoinName: 'Logarithm',
    FullName: 'Logarithm (LGR)',
  },
  BCA: {
    Name: 'BCA',
    Symbol: 'BCA',
    CoinName: 'Bitcoin Atom',
    FullName: 'Bitcoin Atom (BCA)',
  },
  B2X: {
    Name: 'B2X',
    Symbol: 'B2X',
    CoinName: 'SegWit2x',
    FullName: 'SegWit2x (B2X)',
  },
  EXMR: {
    Name: 'EXMR',
    Symbol: 'EXMR',
    CoinName: 'EXMR FDN',
    FullName: 'EXMR FDN (EXMR)',
  },
  UETL: {
    Name: 'UETL',
    Symbol: 'UETL',
    CoinName: 'Useless Eth Token Lite',
    FullName: 'Useless Eth Token Lite (UETL)',
  },
  NBR: {
    Name: 'NBR',
    Symbol: 'NBR',
    CoinName: 'Niobio Cash',
    FullName: 'Niobio Cash (NBR)',
  },
  ARY: {
    Name: 'ARY',
    Symbol: 'ARY',
    CoinName: 'Block Array',
    FullName: 'Block Array (ARY)',
  },
  RAVELOUS: {
    Name: 'RAVELOUS',
    Symbol: 'RAVELOUS',
    CoinName: 'Ravelous',
    FullName: 'Ravelous (RAVELOUS)',
  },
  ILT: {
    Name: 'ILT',
    Symbol: 'ILT',
    CoinName: 'iOlite',
    FullName: 'iOlite (ILT)',
  },
  SCOOBY: {
    Name: 'SCOOBY',
    Symbol: 'SCOOBY',
    CoinName: 'Scooby coin',
    FullName: 'Scooby coin (SCOOBY)',
  },
  SAI: {
    Name: 'SAI',
    Symbol: 'SAI',
    CoinName: 'SAI',
    FullName: 'SAI (SAI)',
  },
  CEFS: {
    Name: 'CEFS',
    Symbol: 'CEFS',
    CoinName: 'CryptopiaFeeShares',
    FullName: 'CryptopiaFeeShares (CEFS)',
  },
  BUN: {
    Name: 'BUN',
    Symbol: 'BUN',
    CoinName: 'BunnyCoin',
    FullName: 'BunnyCoin (BUN)',
  },
  BSR: {
    Name: 'BSR',
    Symbol: 'BSR',
    CoinName: 'BitSoar Coin',
    FullName: 'BitSoar Coin (BSR)',
  },
  SKULL: {
    Name: 'SKULL',
    Symbol: 'SKULL',
    CoinName: 'Pirate Blocks',
    FullName: 'Pirate Blocks (SKULL)',
  },
  TRDT: {
    Name: 'TRDT',
    Symbol: 'TRDT',
    CoinName: 'Trident',
    FullName: 'Trident (TRDT)',
  },
  JC: {
    Name: 'JC',
    Symbol: 'JC',
    CoinName: 'JesusCoin',
    FullName: 'JesusCoin (JC)',
  },
  BTCP: {
    Name: 'BTCP',
    Symbol: 'BTCP',
    CoinName: 'Bitcoin Private',
    FullName: 'Bitcoin Private (BTCP)',
  },
  SKC: {
    Name: 'SKC',
    Symbol: 'SKC',
    CoinName: 'Skeincoin',
    FullName: 'Skeincoin (SKC)',
  },
  JEW: {
    Name: 'JEW',
    Symbol: 'JEW',
    CoinName: 'Shekel',
    FullName: 'Shekel (JEW)',
  },
  ERIS: {
    Name: 'ERIS',
    Symbol: 'ERIS',
    CoinName: 'Eristica',
    FullName: 'Eristica (ERIS)',
  },
  KRM: {
    Name: 'KRM',
    Symbol: 'KRM',
    CoinName: 'Karma',
    FullName: 'Karma (KRM)',
  },
  SHARECHAIN: {
    Name: 'SHARECHAIN',
    Symbol: 'SHARECHAIN',
    CoinName: 'ShareChain',
    FullName: 'ShareChain (SHARECHAIN)',
  },
  CRDNC: {
    Name: 'CRDNC',
    Symbol: 'CRDNC',
    CoinName: 'Credence Coin',
    FullName: 'Credence Coin (CRDNC)',
  },
  BTCF: {
    Name: 'BTCF',
    Symbol: 'BTCF',
    CoinName: 'BitcoinFile',
    FullName: 'BitcoinFile (BTCF)',
  },
  CADN: {
    Name: 'CADN',
    Symbol: 'CADN',
    CoinName: 'Content and AD Network',
    FullName: 'Content and AD Network (CADN)',
  },
  BTF: {
    Name: 'BTF',
    Symbol: 'BTF',
    CoinName: 'Blockchain Traded Fund',
    FullName: 'Blockchain Traded Fund (BTF)',
  },
  IPC: {
    Name: 'IPC',
    Symbol: 'IPC',
    CoinName: 'IPChain',
    FullName: 'IPChain (IPC)',
  },
  SHOW: {
    Name: 'SHOW',
    Symbol: 'SHOW',
    CoinName: 'ShowCoin',
    FullName: 'ShowCoin (SHOW)',
  },
  STARC: {
    Name: 'STARC',
    Symbol: 'STARC',
    CoinName: 'StarChain',
    FullName: 'StarChain (STARC)',
  },
  AIT: {
    Name: 'AIT',
    Symbol: 'AIT',
    CoinName: 'AIChain Token',
    FullName: 'AIChain Token (AIT)',
  },
  STQ: {
    Name: 'STQ',
    Symbol: 'STQ',
    CoinName: 'Storiqa Token',
    FullName: 'Storiqa Token (STQ)',
  },
  ALTCOIN: {
    Name: 'ALTCOIN',
    Symbol: 'ALTCOIN',
    CoinName: 'ALTcoin',
    FullName: 'ALTcoin (ALTCOIN)',
  },
  CXP: {
    Name: 'CXP',
    Symbol: 'CXP',
    CoinName: 'Caixa Pay',
    FullName: 'Caixa Pay (CXP)',
  },
  KB3: {
    Name: 'KB3',
    Symbol: 'KB3',
    CoinName: 'B3Coin',
    FullName: 'B3Coin (KB3)',
  },
  FDX: {
    Name: 'FDX',
    Symbol: 'FDX',
    CoinName: 'fidentiaX',
    FullName: 'fidentiaX (FDX)',
  },
  KREDS: {
    Name: 'KREDS',
    Symbol: 'KREDS',
    CoinName: 'KREDS',
    FullName: 'KREDS (KREDS)',
  },
  EQL: {
    Name: 'EQL',
    Symbol: 'EQL',
    CoinName: 'EQUAL',
    FullName: 'EQUAL (EQL)',
  },
  GAI: {
    Name: 'GAI',
    Symbol: 'GAI',
    CoinName: 'GraphGrail AI',
    FullName: 'GraphGrail AI (GAI)',
  },
  VULC: {
    Name: 'VULC',
    Symbol: 'VULC',
    CoinName: 'Vulcano',
    FullName: 'Vulcano (VULC)',
  },
  DVTC: {
    Name: 'DVTC',
    Symbol: 'DVTC',
    CoinName: 'DivotyCoin',
    FullName: 'DivotyCoin (DVTC)',
  },
  DADI: {
    Name: 'DADI',
    Symbol: 'DADI',
    CoinName: 'Edge',
    FullName: 'Edge (DADI)',
  },
  MGGT: {
    Name: 'MGGT',
    Symbol: 'MGGT',
    CoinName: 'Maggie Token',
    FullName: 'Maggie Token (MGGT)',
  },
  TOKC: {
    Name: 'TOKC',
    Symbol: 'TOKC',
    CoinName: 'Tokyo Coin',
    FullName: 'Tokyo Coin (TOKC)',
  },
  UNRC: {
    Name: 'UNRC',
    Symbol: 'UNRC',
    CoinName: 'UniversalRoyalCoin',
    FullName: 'UniversalRoyalCoin (UNRC)',
  },
  BBP: {
    Name: 'BBP',
    Symbol: 'BBP',
    CoinName: 'BiblePay',
    FullName: 'BiblePay (BBP)',
  },
  NOX: {
    Name: 'NOX',
    Symbol: 'NOX',
    CoinName: 'NITRO',
    FullName: 'NITRO (NOX)',
  },
  HYS: {
    Name: 'HYS',
    Symbol: 'HYS',
    CoinName: 'Heiss Shares',
    FullName: 'Heiss Shares (HYS)',
  },
  LCWP: {
    Name: 'LCWP',
    Symbol: 'LCWP',
    CoinName: 'LiteCoinW Plus',
    FullName: 'LiteCoinW Plus (LCWP)',
  },
  NAVI: {
    Name: 'NAVI',
    Symbol: 'NAVI',
    CoinName: 'NaviAddress',
    FullName: 'NaviAddress (NAVI)',
  },
  ADI: {
    Name: 'ADI',
    Symbol: 'ADI',
    CoinName: 'Aditus',
    FullName: 'Aditus (ADI)',
  },
  VVI: {
    Name: 'VVI',
    Symbol: 'VVI',
    CoinName: 'VV Coin',
    FullName: 'VV Coin (VVI)',
  },
  ANKORUS: {
    Name: 'ANKORUS',
    Symbol: 'ANKORUS',
    CoinName: 'Ankorus Token',
    FullName: 'Ankorus Token (ANKORUS)',
  },
  IVC: {
    Name: 'IVC',
    Symbol: 'IVC',
    CoinName: 'Investy Coin',
    FullName: 'Investy Coin (IVC)',
  },
  HLP: {
    Name: 'HLP',
    Symbol: 'HLP',
    CoinName: 'Purpose Coin',
    FullName: 'Purpose Coin (HLP)',
  },
  VIN: {
    Name: 'VIN',
    Symbol: 'VIN',
    CoinName: 'VinChain',
    FullName: 'VinChain (VIN)',
  },
  PTR: {
    Name: 'PTR',
    Symbol: 'PTR',
    CoinName: 'Petro',
    FullName: 'Petro (PTR)',
  },
  VANY: {
    Name: 'VANY',
    Symbol: 'VANY',
    CoinName: 'Vanywhere',
    FullName: 'Vanywhere (VANY)',
  },
  TONE: {
    Name: 'TONE',
    Symbol: 'TONE',
    CoinName: 'TE-FOOD',
    FullName: 'TE-FOOD (TONE)',
  },
  NOXB: {
    Name: 'NOXB',
    Symbol: 'NOXB',
    CoinName: 'Noxbox',
    FullName: 'Noxbox (NOXB)',
  },
  RENTBE: {
    Name: 'RENTBE',
    Symbol: 'RENTBE',
    CoinName: 'Rentberry',
    FullName: 'Rentberry (RENTBE)',
  },
  'SPC.QRC': {
    Name: 'SPC.QRC',
    Symbol: 'SPC.QRC',
    CoinName: 'SpaceChain (QRC-20)',
    FullName: 'SpaceChain (QRC-20) (SPC.QRC)',
  },
  FLIP: {
    Name: 'FLIP',
    Symbol: 'FLIP',
    CoinName: 'BitFlip',
    FullName: 'BitFlip (FLIP)',
  },
  CLIN: {
    Name: 'CLIN',
    Symbol: 'CLIN',
    CoinName: 'Clinicoin',
    FullName: 'Clinicoin (CLIN)',
  },
  GOOD: {
    Name: 'GOOD',
    Symbol: 'GOOD',
    CoinName: 'Goodomy',
    FullName: 'Goodomy (GOOD)',
  },
  ENK: {
    Name: 'ENK',
    Symbol: 'ENK',
    CoinName: 'Enkidu',
    FullName: 'Enkidu (ENK)',
  },
  ALX: {
    Name: 'ALX',
    Symbol: 'ALX',
    CoinName: 'ALAX',
    FullName: 'ALAX (ALX)',
  },
  DTH: {
    Name: 'DTH',
    Symbol: 'DTH',
    CoinName: 'Dether',
    FullName: 'Dether (DTH)',
  },
  LOTTO: {
    Name: 'LOTTO',
    Symbol: 'LOTTO',
    CoinName: 'LottoCoin',
    FullName: 'LottoCoin (LOTTO)',
  },
  FUNK: {
    Name: 'FUNK',
    Symbol: 'FUNK',
    CoinName: 'Cypherfunks Coin',
    FullName: 'Cypherfunks Coin (FUNK)',
  },
  LEAF: {
    Name: 'LEAF',
    Symbol: 'LEAF',
    CoinName: 'LeafCoin',
    FullName: 'LeafCoin (LEAF)',
  },
  COMPD: {
    Name: 'COMPD',
    Symbol: 'COMPD',
    CoinName: 'Compound Coin',
    FullName: 'Compound Coin (COMPD)',
  },
  BITCAR: {
    Name: 'BITCAR',
    Symbol: 'BITCAR',
    CoinName: 'BitCar',
    FullName: 'BitCar (BITCAR)',
  },
  CLN: {
    Name: 'CLN',
    Symbol: 'CLN',
    CoinName: 'Colu Local Network',
    FullName: 'Colu Local Network (CLN)',
  },
  ORYX: {
    Name: 'ORYX',
    Symbol: 'ORYX',
    CoinName: 'OryxCoin',
    FullName: 'OryxCoin (ORYX)',
  },
  BASHC: {
    Name: 'BASHC',
    Symbol: 'BASHC',
    CoinName: 'BashCoin',
    FullName: 'BashCoin (BASHC)',
  },
  DIGIF: {
    Name: 'DIGIF',
    Symbol: 'DIGIF',
    CoinName: 'DigiFel',
    FullName: 'DigiFel (DIGIF)',
  },
  DGM: {
    Name: 'DGM',
    Symbol: 'DGM',
    CoinName: 'DigiMoney',
    FullName: 'DigiMoney (DGM)',
  },
  CBS: {
    Name: 'CBS',
    Symbol: 'CBS',
    CoinName: 'Cerberus',
    FullName: 'Cerberus (CBS)',
  },
  SVD: {
    Name: 'SVD',
    Symbol: 'SVD',
    CoinName: 'savedroid',
    FullName: 'savedroid (SVD)',
  },
  PROOF: {
    Name: 'PROOF',
    Symbol: 'PROOF',
    CoinName: 'PROVER',
    FullName: 'PROVER (PROOF)',
  },
  BTCH: {
    Name: 'BTCH',
    Symbol: 'BTCH',
    CoinName: 'Bitcoin Hush',
    FullName: 'Bitcoin Hush (BTCH)',
  },
  redBUX: {
    Name: 'redBUX',
    Symbol: 'redBUX',
    CoinName: 'redBUX',
    FullName: 'redBUX (redBUX)',
  },
  LIZ: {
    Name: 'LIZ',
    Symbol: 'LIZ',
    CoinName: 'Lizus Payment',
    FullName: 'Lizus Payment (LIZ)',
  },
  CIF: {
    Name: 'CIF',
    Symbol: 'CIF',
    CoinName: 'Crypto Improvement Fund',
    FullName: 'Crypto Improvement Fund (CIF)',
  },
  NCASH: {
    Name: 'NCASH',
    Symbol: 'NCASH',
    CoinName: 'Nucleus Vision',
    FullName: 'Nucleus Vision (NCASH)',
  },
  SPD: {
    Name: 'SPD',
    Symbol: 'SPD',
    CoinName: 'Stipend',
    FullName: 'Stipend (SPD)',
  },
  CMCT: {
    Name: 'CMCT',
    Symbol: 'CMCT',
    CoinName: 'Crowd Machine',
    FullName: 'Crowd Machine (CMCT)',
  },
  RPUT: {
    Name: 'RPUT',
    Symbol: 'RPUT',
    CoinName: 'Robin8 Profile Utility Token',
    FullName: 'Robin8 Profile Utility Token (RPUT)',
  },
  FILL: {
    Name: 'FILL',
    Symbol: 'FILL',
    CoinName: 'Fillit',
    FullName: 'Fillit (FILL)',
  },
  PROPS: {
    Name: 'PROPS',
    Symbol: 'PROPS',
    CoinName: 'Props',
    FullName: 'Props (PROPS)',
  },
  CEDEX: {
    Name: 'CEDEX',
    Symbol: 'CEDEX',
    CoinName: 'CEDEX Coin',
    FullName: 'CEDEX Coin (CEDEX)',
  },
  FUNDP: {
    Name: 'FUNDP',
    Symbol: 'FUNDP',
    CoinName: 'Fund Platform',
    FullName: 'Fund Platform (FUNDP)',
  },
  PUSHI: {
    Name: 'PUSHI',
    Symbol: 'PUSHI',
    CoinName: 'Pushi',
    FullName: 'Pushi (PUSHI)',
  },
  BINS: {
    Name: 'BINS',
    Symbol: 'BINS',
    CoinName: 'Bitsense',
    FullName: 'Bitsense (BINS)',
  },
  POKER: {
    Name: 'POKER',
    Symbol: 'POKER',
    CoinName: 'PokerCoin',
    FullName: 'PokerCoin (POKER)',
  },
  AXYS: {
    Name: 'AXYS',
    Symbol: 'AXYS',
    CoinName: 'Axys',
    FullName: 'Axys (AXYS)',
  },
  EVENC: {
    Name: 'EVENC',
    Symbol: 'EVENC',
    CoinName: 'EvenCoin',
    FullName: 'EvenCoin (EVENC)',
  },
  BOLD: {
    Name: 'BOLD',
    Symbol: 'BOLD',
    CoinName: 'Bold',
    FullName: 'Bold (BOLD)',
  },
  EXTN: {
    Name: 'EXTN',
    Symbol: 'EXTN',
    CoinName: 'Extensive Coin',
    FullName: 'Extensive Coin (EXTN)',
  },
  DIG: {
    Name: 'DIG',
    Symbol: 'DIG',
    CoinName: 'Dignity',
    FullName: 'Dignity (DIG)',
  },
  ETS: {
    Name: 'ETS',
    Symbol: 'ETS',
    CoinName: 'ETH Share',
    FullName: 'ETH Share (ETS)',
  },
  LIPC: {
    Name: 'LIPC',
    Symbol: 'LIPC',
    CoinName: 'LIpcoin',
    FullName: 'LIpcoin (LIPC)',
  },
  GOFF: {
    Name: 'GOFF',
    Symbol: 'GOFF',
    CoinName: 'Gift Off Token',
    FullName: 'Gift Off Token (GOFF)',
  },
  HELL: {
    Name: 'HELL',
    Symbol: 'HELL',
    CoinName: 'HELL COIN',
    FullName: 'HELL COIN (HELL)',
  },
  ELP: {
    Name: 'ELP',
    Symbol: 'ELP',
    CoinName: 'Ellerium',
    FullName: 'Ellerium (ELP)',
  },
  RKT: {
    Name: 'RKT',
    Symbol: 'RKT',
    CoinName: 'Rock Token',
    FullName: 'Rock Token (RKT)',
  },
  ELI: {
    Name: 'ELI',
    Symbol: 'ELI',
    CoinName: 'GoCrypto',
    FullName: 'GoCrypto (ELI)',
  },
  CO2: {
    Name: 'CO2',
    Symbol: 'CO2',
    CoinName: 'CO2 Token',
    FullName: 'CO2 Token (CO2)',
  },
  INVOX: {
    Name: 'INVOX',
    Symbol: 'INVOX',
    CoinName: 'Invox Finance',
    FullName: 'Invox Finance (INVOX)',
  },
  VELOXPROJECT: {
    Name: 'VELOXPROJECT',
    Symbol: 'VELOXPROJECT',
    CoinName: 'Velox',
    FullName: 'Velox (VELOXPROJECT)',
  },
  ACTN: {
    Name: 'ACTN',
    Symbol: 'ACTN',
    CoinName: 'Action Coin',
    FullName: 'Action Coin (ACTN)',
  },
  LTCH: {
    Name: 'LTCH',
    Symbol: 'LTCH',
    CoinName: 'Litecoin Cash',
    FullName: 'Litecoin Cash (LTCH)',
  },
  HMST: {
    Name: 'HMST',
    Symbol: 'HMST',
    CoinName: 'Hamster Marketplace Token',
    FullName: 'Hamster Marketplace Token (HMST)',
  },
  USCOIN: {
    Name: 'USCOIN',
    Symbol: 'USCOIN',
    CoinName: 'USCoin',
    FullName: 'USCoin (USCOIN)',
  },
  KIND: {
    Name: 'KIND',
    Symbol: 'KIND',
    CoinName: 'Kind Ads',
    FullName: 'Kind Ads (KIND)',
  },
  BCHT: {
    Name: 'BCHT',
    Symbol: 'BCHT',
    CoinName: 'Blockchain Terminal',
    FullName: 'Blockchain Terminal (BCHT)',
  },
  ELIC: {
    Name: 'ELIC',
    Symbol: 'ELIC',
    CoinName: 'Elicoin',
    FullName: 'Elicoin (ELIC)',
  },
  MOAT: {
    Name: 'MOAT',
    Symbol: 'MOAT',
    CoinName: 'Mother Of All Tokens',
    FullName: 'Mother Of All Tokens (MOAT)',
  },
  BBI: {
    Name: 'BBI',
    Symbol: 'BBI',
    CoinName: 'BelugaPay',
    FullName: 'BelugaPay (BBI)',
  },
  ENTRC: {
    Name: 'ENTRC',
    Symbol: 'ENTRC',
    CoinName: 'ENTER COIN',
    FullName: 'ENTER COIN (ENTRC)',
  },
  BTCGO: {
    Name: 'BTCGO',
    Symbol: 'BTCGO',
    CoinName: 'BitcoinGo',
    FullName: 'BitcoinGo (BTCGO)',
  },
  KNW: {
    Name: 'KNW',
    Symbol: 'KNW',
    CoinName: 'Knowledge',
    FullName: 'Knowledge (KNW)',
  },
  PGC: {
    Name: 'PGC',
    Symbol: 'PGC',
    CoinName: 'Pegascoin',
    FullName: 'Pegascoin (PGC)',
  },
  BITREWARDS: {
    Name: 'BITREWARDS',
    Symbol: 'BITREWARDS',
    CoinName: 'BitRewards',
    FullName: 'BitRewards (BITREWARDS)',
  },
  DATX: {
    Name: 'DATX',
    Symbol: 'DATX',
    CoinName: 'DATx',
    FullName: 'DATx (DATX)',
  },
  PKC: {
    Name: 'PKC',
    Symbol: 'PKC',
    CoinName: 'Pikciochain',
    FullName: 'Pikciochain (PKC)',
  },
  TBAR: {
    Name: 'TBAR',
    Symbol: 'TBAR',
    CoinName: 'Titanium BAR',
    FullName: 'Titanium BAR (TBAR)',
  },
  TAN: {
    Name: 'TAN',
    Symbol: 'TAN',
    CoinName: 'Taklimakan',
    FullName: 'Taklimakan (TAN)',
  },
  CPL: {
    Name: 'CPL',
    Symbol: 'CPL',
    CoinName: 'CoinPlace Token',
    FullName: 'CoinPlace Token (CPL)',
  },
  TUBE: {
    Name: 'TUBE',
    Symbol: 'TUBE',
    CoinName: 'BitTube',
    FullName: 'BitTube (TUBE)',
  },
  CUBEAUTO: {
    Name: 'CUBEAUTO',
    Symbol: 'CUBEAUTO',
    CoinName: 'Cube',
    FullName: 'Cube (CUBEAUTO)',
  },
  OMX: {
    Name: 'OMX',
    Symbol: 'OMX',
    CoinName: 'Project Shivom',
    FullName: 'Project Shivom (OMX)',
  },
  XAYA: {
    Name: 'XAYA',
    Symbol: 'XAYA',
    CoinName: 'XAYA',
    FullName: 'XAYA (XAYA)',
  },
  MBM: {
    Name: 'MBM',
    Symbol: 'MBM',
    CoinName: 'MobileBridge Momentum',
    FullName: 'MobileBridge Momentum (MBM)',
  },
  INVC: {
    Name: 'INVC',
    Symbol: 'INVC',
    CoinName: 'Invacio',
    FullName: 'Invacio (INVC)',
  },
  W3C: {
    Name: 'W3C',
    Symbol: 'W3C',
    CoinName: 'W3Coin',
    FullName: 'W3Coin (W3C)',
  },
  DIN: {
    Name: 'DIN',
    Symbol: 'DIN',
    CoinName: 'Dinero',
    FullName: 'Dinero (DIN)',
  },
  PSD: {
    Name: 'PSD',
    Symbol: 'PSD',
    CoinName: 'Poseidon',
    FullName: 'Poseidon (PSD)',
  },
  J8T: {
    Name: 'J8T',
    Symbol: 'J8T',
    CoinName: 'JET8',
    FullName: 'JET8 (J8T)',
  },
  LELE: {
    Name: 'LELE',
    Symbol: 'LELE',
    CoinName: 'Lelecoin',
    FullName: 'Lelecoin (LELE)',
  },
  DROP: {
    Name: 'DROP',
    Symbol: 'DROP',
    CoinName: 'Dropil',
    FullName: 'Dropil (DROP)',
  },
  AKA: {
    Name: 'AKA',
    Symbol: 'AKA',
    CoinName: 'Akroma',
    FullName: 'Akroma (AKA)',
  },
  SHIP: {
    Name: 'SHIP',
    Symbol: 'SHIP',
    CoinName: 'ShipChain',
    FullName: 'ShipChain (SHIP)',
  },
  LCS: {
    Name: 'LCS',
    Symbol: 'LCS',
    CoinName: 'LocalCoinSwap',
    FullName: 'LocalCoinSwap (LCS)',
  },
  LALA: {
    Name: 'LALA',
    Symbol: 'LALA',
    CoinName: 'LaLa World',
    FullName: 'LaLa World (LALA)',
  },
  LEDU: {
    Name: 'LEDU',
    Symbol: 'LEDU',
    CoinName: 'Education Ecosystem',
    FullName: 'Education Ecosystem (LEDU)',
  },
  FOXT: {
    Name: 'FOXT',
    Symbol: 'FOXT',
    CoinName: 'Fox Trading',
    FullName: 'Fox Trading (FOXT)',
  },
  ETKN: {
    Name: 'ETKN',
    Symbol: 'ETKN',
    CoinName: 'EasyToken',
    FullName: 'EasyToken (ETKN)',
  },
  ROX: {
    Name: 'ROX',
    Symbol: 'ROX',
    CoinName: 'Robotina',
    FullName: 'Robotina (ROX)',
  },
  ADM: {
    Name: 'ADM',
    Symbol: 'ADM',
    CoinName: 'ADAMANT Messenger',
    FullName: 'ADAMANT Messenger (ADM)',
  },
  AMBT: {
    Name: 'AMBT',
    Symbol: 'AMBT',
    CoinName: 'AMBT Token',
    FullName: 'AMBT Token (AMBT)',
  },
  BTRM: {
    Name: 'BTRM',
    Symbol: 'BTRM',
    CoinName: 'Betrium Token',
    FullName: 'Betrium Token (BTRM)',
  },
  MANNA: {
    Name: 'MANNA',
    Symbol: 'MANNA',
    CoinName: 'Manna',
    FullName: 'Manna (MANNA)',
  },
  ePRX: {
    Name: 'ePRX',
    Symbol: 'ePRX',
    CoinName: 'eProxy',
    FullName: 'eProxy (ePRX)',
  },
  HMC: {
    Name: 'HMC',
    Symbol: 'HMC',
    CoinName: 'Hi Mutual Society',
    FullName: 'Hi Mutual Society (HMC)',
  },
  ZIX: {
    Name: 'ZIX',
    Symbol: 'ZIX',
    CoinName: 'ZIX Token',
    FullName: 'ZIX Token (ZIX)',
  },
  ORGT: {
    Name: 'ORGT',
    Symbol: 'ORGT',
    CoinName: 'Organic Token',
    FullName: 'Organic Token (ORGT)',
  },
  PAN: {
    Name: 'PAN',
    Symbol: 'PAN',
    CoinName: 'Pantos',
    FullName: 'Pantos (PAN)',
  },
  BOTC: {
    Name: 'BOTC',
    Symbol: 'BOTC',
    CoinName: 'BotChain',
    FullName: 'BotChain (BOTC)',
  },
  VIEW: {
    Name: 'VIEW',
    Symbol: 'VIEW',
    CoinName: 'Viewly',
    FullName: 'Viewly (VIEW)',
  },
  OKOIN: {
    Name: 'OKOIN',
    Symbol: 'OKOIN',
    CoinName: 'OKOIN',
    FullName: 'OKOIN (OKOIN)',
  },
  ADK: {
    Name: 'ADK',
    Symbol: 'ADK',
    CoinName: 'Aidos Kuneen',
    FullName: 'Aidos Kuneen (ADK)',
  },
  VIT: {
    Name: 'VIT',
    Symbol: 'VIT',
    CoinName: 'Vision Industry Token',
    FullName: 'Vision Industry Token (VIT)',
  },
  BLN: {
    Name: 'BLN',
    Symbol: 'BLN',
    CoinName: 'Bulleon',
    FullName: 'Bulleon (BLN)',
  },
  AET: {
    Name: 'AET',
    Symbol: 'AET',
    CoinName: 'AfterEther',
    FullName: 'AfterEther (AET)',
  },
  CMOS: {
    Name: 'CMOS',
    Symbol: 'CMOS',
    CoinName: 'Cosmo',
    FullName: 'Cosmo (CMOS)',
  },
  PGN: {
    Name: 'PGN',
    Symbol: 'PGN',
    CoinName: 'Pigeoncoin',
    FullName: 'Pigeoncoin (PGN)',
  },
  BMH: {
    Name: 'BMH',
    Symbol: 'BMH',
    CoinName: 'BlockMesh',
    FullName: 'BlockMesh (BMH)',
  },
  REDN: {
    Name: 'REDN',
    Symbol: 'REDN',
    CoinName: 'Reden',
    FullName: 'Reden (REDN)',
  },
  TLP: {
    Name: 'TLP',
    Symbol: 'TLP',
    CoinName: 'TulipCoin',
    FullName: 'TulipCoin (TLP)',
  },
  GEN: {
    Name: 'GEN',
    Symbol: 'GEN',
    CoinName: 'DAOstack',
    FullName: 'DAOstack (GEN)',
  },
  BBN: {
    Name: 'BBN',
    Symbol: 'BBN',
    CoinName: 'BBNCOIN',
    FullName: 'BBNCOIN (BBN)',
  },
  PAVO: {
    Name: 'PAVO',
    Symbol: 'PAVO',
    CoinName: 'Pavocoin',
    FullName: 'Pavocoin (PAVO)',
  },
  BUBO: {
    Name: 'BUBO',
    Symbol: 'BUBO',
    CoinName: 'Budbo',
    FullName: 'Budbo (BUBO)',
  },
  FLUZ: {
    Name: 'FLUZ',
    Symbol: 'FLUZ',
    CoinName: 'FluzFluz',
    FullName: 'FluzFluz (FLUZ)',
  },
  IPSX: {
    Name: 'IPSX',
    Symbol: 'IPSX',
    CoinName: 'IP Exchange',
    FullName: 'IP Exchange (IPSX)',
  },
  MIO: {
    Name: 'MIO',
    Symbol: 'MIO',
    CoinName: 'Miner One token',
    FullName: 'Miner One token (MIO)',
  },
  BNN: {
    Name: 'BNN',
    Symbol: 'BNN',
    CoinName: 'Banyan Network',
    FullName: 'Banyan Network (BNN)',
  },
  SPND: {
    Name: 'SPND',
    Symbol: 'SPND',
    CoinName: 'Spindle',
    FullName: 'Spindle (SPND)',
  },
  FNO: {
    Name: 'FNO',
    Symbol: 'FNO',
    CoinName: 'Fonero',
    FullName: 'Fonero (FNO)',
  },
  PAS: {
    Name: 'PAS',
    Symbol: 'PAS',
    CoinName: 'Passive Coin',
    FullName: 'Passive Coin (PAS)',
  },
  TENT: {
    Name: 'TENT',
    Symbol: 'TENT',
    CoinName: 'TENT',
    FullName: 'TENT (TENT)',
  },
  CVTC: {
    Name: 'CVTC',
    Symbol: 'CVTC',
    CoinName: 'CavatCoin',
    FullName: 'CavatCoin (CVTC)',
  },
  PLMT: {
    Name: 'PLMT',
    Symbol: 'PLMT',
    CoinName: 'Pallium',
    FullName: 'Pallium (PLMT)',
  },
  FARMC: {
    Name: 'FARMC',
    Symbol: 'FARMC',
    CoinName: 'FARM Coin',
    FullName: 'FARM Coin (FARMC)',
  },
  NEXT: {
    Name: 'NEXT',
    Symbol: 'NEXT',
    CoinName: 'Next.exchange Token',
    FullName: 'Next.exchange Token (NEXT)',
  },
  RNTB: {
    Name: 'RNTB',
    Symbol: 'RNTB',
    CoinName: 'BitRent',
    FullName: 'BitRent (RNTB)',
  },
  XCLR: {
    Name: 'XCLR',
    Symbol: 'XCLR',
    CoinName: 'ClearCoin',
    FullName: 'ClearCoin (XCLR)',
  },
  FDZ: {
    Name: 'FDZ',
    Symbol: 'FDZ',
    CoinName: 'Friendz',
    FullName: 'Friendz (FDZ)',
  },
  VTN: {
    Name: 'VTN',
    Symbol: 'VTN',
    CoinName: 'Voltroon',
    FullName: 'Voltroon (VTN)',
  },
  COINLION: {
    Name: 'COINLION',
    Symbol: 'COINLION',
    CoinName: 'CoinLion',
    FullName: 'CoinLion (COINLION)',
  },
  MASP: {
    Name: 'MASP',
    Symbol: 'MASP',
    CoinName: 'Market.space',
    FullName: 'Market.space (MASP)',
  },
  UCN: {
    Name: 'UCN',
    Symbol: 'UCN',
    CoinName: 'UC Coin',
    FullName: 'UC Coin (UCN)',
  },
  HUR: {
    Name: 'HUR',
    Symbol: 'HUR',
    CoinName: 'Hurify',
    FullName: 'Hurify (HUR)',
  },
  BRIA: {
    Name: 'BRIA',
    Symbol: 'BRIA',
    CoinName: 'Briacoin',
    FullName: 'Briacoin (BRIA)',
  },
  IC: {
    Name: 'IC',
    Symbol: 'IC',
    CoinName: 'Ignition',
    FullName: 'Ignition (IC)',
  },
  LATX: {
    Name: 'LATX',
    Symbol: 'LATX',
    CoinName: 'Latium',
    FullName: 'Latium (LATX)',
  },
  ROI: {
    Name: 'ROI',
    Symbol: 'ROI',
    CoinName: 'ROIcoin',
    FullName: 'ROIcoin (ROI)',
  },
  ETHPR: {
    Name: 'ETHPR',
    Symbol: 'ETHPR',
    CoinName: 'Ethereum Premium',
    FullName: 'Ethereum Premium (ETHPR)',
  },
  MNB: {
    Name: 'MNB',
    Symbol: 'MNB',
    CoinName: 'MoneyBag',
    FullName: 'MoneyBag (MNB)',
  },
  ACHC: {
    Name: 'ACHC',
    Symbol: 'ACHC',
    CoinName: 'AchieveCoin',
    FullName: 'AchieveCoin (ACHC)',
  },
  BITROLIUM: {
    Name: 'BITROLIUM',
    Symbol: 'BITROLIUM',
    CoinName: 'Bitrolium',
    FullName: 'Bitrolium (BITROLIUM)',
  },
  GOALBON: {
    Name: 'GOALBON',
    Symbol: 'GOALBON',
    CoinName: 'Goal Bonanza',
    FullName: 'Goal Bonanza (GOALBON)',
  },
  RAC: {
    Name: 'RAC',
    Symbol: 'RAC',
    CoinName: 'RAcoin',
    FullName: 'RAcoin (RAC)',
  },
  BEX: {
    Name: 'BEX',
    Symbol: 'BEX',
    CoinName: 'BEX token',
    FullName: 'BEX token (BEX)',
  },
  HOLD: {
    Name: 'HOLD',
    Symbol: 'HOLD',
    CoinName: 'HOLD',
    FullName: 'HOLD (HOLD)',
  },
  EZT: {
    Name: 'EZT',
    Symbol: 'EZT',
    CoinName: 'EZToken',
    FullName: 'EZToken (EZT)',
  },
  SOLA: {
    Name: 'SOLA',
    Symbol: 'SOLA',
    CoinName: 'Sola',
    FullName: 'Sola (SOLA)',
  },
  VIC: {
    Name: 'VIC',
    Symbol: 'VIC',
    CoinName: 'Victorium',
    FullName: 'Victorium (VIC)',
  },
  XCM: {
    Name: 'XCM',
    Symbol: 'XCM',
    CoinName: 'CoinMetro',
    FullName: 'CoinMetro (XCM)',
  },
  NFN: {
    Name: 'NFN',
    Symbol: 'NFN',
    CoinName: 'Nafen',
    FullName: 'Nafen (NFN)',
  },
  BCI: {
    Name: 'BCI',
    Symbol: 'BCI',
    CoinName: 'Bitcoin Interest',
    FullName: 'Bitcoin Interest (BCI)',
  },
  MEDIC: {
    Name: 'MEDIC',
    Symbol: 'MEDIC',
    CoinName: 'MedicCoin',
    FullName: 'MedicCoin (MEDIC)',
  },
  BBCT: {
    Name: 'BBCT',
    Symbol: 'BBCT',
    CoinName: 'TraDove B2BCoin',
    FullName: 'TraDove B2BCoin (BBCT)',
  },
  KWH: {
    Name: 'KWH',
    Symbol: 'KWH',
    CoinName: 'KWHCoin',
    FullName: 'KWHCoin (KWH)',
  },
  FTX: {
    Name: 'FTX',
    Symbol: 'FTX',
    CoinName: 'FintruX',
    FullName: 'FintruX (FTX)',
  },
  GSI: {
    Name: 'GSI',
    Symbol: 'GSI',
    CoinName: 'Globex SCI',
    FullName: 'Globex SCI (GSI)',
  },
  BIDI: {
    Name: 'BIDI',
    Symbol: 'BIDI',
    CoinName: 'Bidipass',
    FullName: 'Bidipass (BIDI)',
  },
  ALPS: {
    Name: 'ALPS',
    Symbol: 'ALPS',
    CoinName: 'Alpenschillling',
    FullName: 'Alpenschillling (ALPS)',
  },
  BKC: {
    Name: 'BKC',
    Symbol: 'BKC',
    CoinName: 'Balkancoin',
    FullName: 'Balkancoin (BKC)',
  },
  BITG: {
    Name: 'BITG',
    Symbol: 'BITG',
    CoinName: 'Bitcoin Green',
    FullName: 'Bitcoin Green (BITG)',
  },
  DEV: {
    Name: 'DEV',
    Symbol: 'DEV',
    CoinName: 'Deviant Coin',
    FullName: 'Deviant Coin (DEV)',
  },
  CHT: {
    Name: 'CHT',
    Symbol: 'CHT',
    CoinName: 'Countinghouse Fund',
    FullName: 'Countinghouse Fund (CHT)',
  },
  GREEN: {
    Name: 'GREEN',
    Symbol: 'GREEN',
    CoinName: 'GreenX',
    FullName: 'GreenX (GREEN)',
  },
  ABJ: {
    Name: 'ABJ',
    Symbol: 'ABJ',
    CoinName: 'Abjcoin',
    FullName: 'Abjcoin (ABJ)',
  },
  FTW: {
    Name: 'FTW',
    Symbol: 'FTW',
    CoinName: 'FutureWorks',
    FullName: 'FutureWorks (FTW)',
  },
  RAP: {
    Name: 'RAP',
    Symbol: 'RAP',
    CoinName: 'Rapture',
    FullName: 'Rapture (RAP)',
  },
  ARTE: {
    Name: 'ARTE',
    Symbol: 'ARTE',
    CoinName: 'Artemine',
    FullName: 'Artemine (ARTE)',
  },
  ANI: {
    Name: 'ANI',
    Symbol: 'ANI',
    CoinName: 'Animecoin',
    FullName: 'Animecoin (ANI)',
  },
  PHC: {
    Name: 'PHC',
    Symbol: 'PHC',
    CoinName: 'Profit Hunters Coin',
    FullName: 'Profit Hunters Coin (PHC)',
  },
  ETHM: {
    Name: 'ETHM',
    Symbol: 'ETHM',
    CoinName: 'Ethereum Meta',
    FullName: 'Ethereum Meta (ETHM)',
  },
  UBC: {
    Name: 'UBC',
    Symbol: 'UBC',
    CoinName: 'Ubcoin',
    FullName: 'Ubcoin (UBC)',
  },
  NOKU: {
    Name: 'NOKU',
    Symbol: 'NOKU',
    CoinName: 'NOKU Master token',
    FullName: 'NOKU Master token (NOKU)',
  },
  PAT: {
    Name: 'PAT',
    Symbol: 'PAT',
    CoinName: 'PATRON',
    FullName: 'PATRON (PAT)',
  },
  CHFN: {
    Name: 'CHFN',
    Symbol: 'CHFN',
    CoinName: 'NOKU CHF',
    FullName: 'NOKU CHF (CHFN)',
  },
  EURN: {
    Name: 'EURN',
    Symbol: 'EURN',
    CoinName: 'NOKU EUR',
    FullName: 'NOKU EUR (EURN)',
  },
  LEU: {
    Name: 'LEU',
    Symbol: 'LEU',
    CoinName: 'CryptoLEU',
    FullName: 'CryptoLEU (LEU)',
  },
  SWC: {
    Name: 'SWC',
    Symbol: 'SWC',
    CoinName: 'Scanetchain Token',
    FullName: 'Scanetchain Token (SWC)',
  },
  ORS: {
    Name: 'ORS',
    Symbol: 'ORS',
    CoinName: 'ORS Group',
    FullName: 'ORS Group (ORS)',
  },
  SEM: {
    Name: 'SEM',
    Symbol: 'SEM',
    CoinName: 'Semux',
    FullName: 'Semux (SEM)',
  },
  DARX: {
    Name: 'DARX',
    Symbol: 'DARX',
    CoinName: 'Bitdaric',
    FullName: 'Bitdaric (DARX)',
  },
  BBK: {
    Name: 'BBK',
    Symbol: 'BBK',
    CoinName: 'BitBlocks',
    FullName: 'BitBlocks (BBK)',
  },
  CPX: {
    Name: 'CPX',
    Symbol: 'CPX',
    CoinName: 'Apex Token',
    FullName: 'Apex Token (CPX)',
  },
  EQC: {
    Name: 'EQC',
    Symbol: 'EQC',
    CoinName: 'Ethereum Qchain Token',
    FullName: 'Ethereum Qchain Token (EQC)',
  },
  ADH: {
    Name: 'ADH',
    Symbol: 'ADH',
    CoinName: 'Adhive',
    FullName: 'Adhive (ADH)',
  },
  LIF: {
    Name: 'LIF',
    Symbol: 'LIF',
    CoinName: 'Winding Tree',
    FullName: 'Winding Tree (LIF)',
  },
  EFX: {
    Name: 'EFX',
    Symbol: 'EFX',
    CoinName: 'The Effect.ai',
    FullName: 'The Effect.ai (EFX)',
  },
  LND: {
    Name: 'LND',
    Symbol: 'LND',
    CoinName: 'Lendingblock',
    FullName: 'Lendingblock (LND)',
  },
  MNRB: {
    Name: 'MNRB',
    Symbol: 'MNRB',
    CoinName: 'MoneyRebel',
    FullName: 'MoneyRebel (MNRB)',
  },
  FTO: {
    Name: 'FTO',
    Symbol: 'FTO',
    CoinName: 'FuturoCoin',
    FullName: 'FuturoCoin (FTO)',
  },
  SIG: {
    Name: 'SIG',
    Symbol: 'SIG',
    CoinName: 'Signal',
    FullName: 'Signal (SIG)',
  },
  CARE: {
    Name: 'CARE',
    Symbol: 'CARE',
    CoinName: 'Carebit',
    FullName: 'Carebit (CARE)',
  },
  NZL: {
    Name: 'NZL',
    Symbol: 'NZL',
    CoinName: 'Zealium',
    FullName: 'Zealium (NZL)',
  },
  TBT: {
    Name: 'TBT',
    Symbol: 'TBT',
    CoinName: 'T-BOT',
    FullName: 'T-BOT (TBT)',
  },
  OAK: {
    Name: 'OAK',
    Symbol: 'OAK',
    CoinName: 'Acorn Collective',
    FullName: 'Acorn Collective (OAK)',
  },
  DML: {
    Name: 'DML',
    Symbol: 'DML',
    CoinName: 'Decentralized Machine Learning',
    FullName: 'Decentralized Machine Learning (DML)',
  },
  TIPS: {
    Name: 'TIPS',
    Symbol: 'TIPS',
    CoinName: 'FedoraCoin',
    FullName: 'FedoraCoin (TIPS)',
  },
  MOS: {
    Name: 'MOS',
    Symbol: 'MOS',
    CoinName: 'MOS Coin',
    FullName: 'MOS Coin (MOS)',
  },
  TBX: {
    Name: 'TBX',
    Symbol: 'TBX',
    CoinName: 'Tokenbox',
    FullName: 'Tokenbox (TBX)',
  },
  PENTA: {
    Name: 'PENTA',
    Symbol: 'PENTA',
    CoinName: 'Penta',
    FullName: 'Penta (PENTA)',
  },
  WCOIN: {
    Name: 'WCOIN',
    Symbol: 'WCOIN',
    CoinName: 'WCoin',
    FullName: 'WCoin (WCOIN)',
  },
  CHARM: {
    Name: 'CHARM',
    Symbol: 'CHARM',
    CoinName: 'Charm Coin',
    FullName: 'Charm Coin (CHARM)',
  },
  PROTON: {
    Name: 'PROTON',
    Symbol: 'PROTON',
    CoinName: 'Proton',
    FullName: 'Proton (PROTON)',
  },
  DEAL: {
    Name: 'DEAL',
    Symbol: 'DEAL',
    CoinName: 'iDealCash',
    FullName: 'iDealCash (DEAL)',
  },
  JUMP: {
    Name: 'JUMP',
    Symbol: 'JUMP',
    CoinName: 'Jumpcoin',
    FullName: 'Jumpcoin (JUMP)',
  },
  ZCO: {
    Name: 'ZCO',
    Symbol: 'ZCO',
    CoinName: 'Zebi Coin',
    FullName: 'Zebi Coin (ZCO)',
  },
  TRAXIA: {
    Name: 'TRAXIA',
    Symbol: 'TRAXIA',
    CoinName: 'Traxia Membership Token',
    FullName: 'Traxia Membership Token (TRAXIA)',
  },
  CHX: {
    Name: 'CHX',
    Symbol: 'CHX',
    CoinName: 'Own',
    FullName: 'Own (CHX)',
  },
  SS: {
    Name: 'SS',
    Symbol: 'SS',
    CoinName: 'Sharder',
    FullName: 'Sharder (SS)',
  },
  XMO: {
    Name: 'XMO',
    Symbol: 'XMO',
    CoinName: 'Monero Original',
    FullName: 'Monero Original (XMO)',
  },
  PCL: {
    Name: 'PCL',
    Symbol: 'PCL',
    CoinName: 'Peculium',
    FullName: 'Peculium (PCL)',
  },
  MITX: {
    Name: 'MITX',
    Symbol: 'MITX',
    CoinName: 'Morpheus Infrastructure Token',
    FullName: 'Morpheus Infrastructure Token (MITX)',
  },
  APH: {
    Name: 'APH',
    Symbol: 'APH',
    CoinName: 'Aphelion',
    FullName: 'Aphelion (APH)',
  },
  NBAI: {
    Name: 'NBAI',
    Symbol: 'NBAI',
    CoinName: 'Nebula AI',
    FullName: 'Nebula AI (NBAI)',
  },
  TUT: {
    Name: 'TUT',
    Symbol: 'TUT',
    CoinName: 'Tutellus',
    FullName: 'Tutellus (TUT)',
  },
  BETT: {
    Name: 'BETT',
    Symbol: 'BETT',
    CoinName: 'Bettium',
    FullName: 'Bettium (BETT)',
  },
  NOAH: {
    Name: 'NOAH',
    Symbol: 'NOAH',
    CoinName: 'NOAHCOIN',
    FullName: 'NOAHCOIN (NOAH)',
  },
  PAL: {
    Name: 'PAL',
    Symbol: 'PAL',
    CoinName: 'PolicyPal Network',
    FullName: 'PolicyPal Network (PAL)',
  },
  ENU: {
    Name: 'ENU',
    Symbol: 'ENU',
    CoinName: 'Enumivo',
    FullName: 'Enumivo (ENU)',
  },
  BFDT: {
    Name: 'BFDT',
    Symbol: 'BFDT',
    CoinName: 'Befund',
    FullName: 'Befund (BFDT)',
  },
  KEP: {
    Name: 'KEP',
    Symbol: 'KEP',
    CoinName: 'Kepler',
    FullName: 'Kepler (KEP)',
  },
  RUBY: {
    Name: 'RUBY',
    Symbol: 'RUBY',
    CoinName: 'Rubius',
    FullName: 'Rubius (RUBY)',
  },
  CTKN: {
    Name: 'CTKN',
    Symbol: 'CTKN',
    CoinName: 'Curaizon',
    FullName: 'Curaizon (CTKN)',
  },
  YUM: {
    Name: 'YUM',
    Symbol: 'YUM',
    CoinName: 'Yumerium',
    FullName: 'Yumerium (YUM)',
  },
  DESI: {
    Name: 'DESI',
    Symbol: 'DESI',
    CoinName: 'Desico',
    FullName: 'Desico (DESI)',
  },
  FNP: {
    Name: 'FNP',
    Symbol: 'FNP',
    CoinName: 'FlipNpik',
    FullName: 'FlipNpik (FNP)',
  },
  MTC: {
    Name: 'MTC',
    Symbol: 'MTC',
    CoinName: 'MEDICAL TOKEN CURRENCY',
    FullName: 'MEDICAL TOKEN CURRENCY (MTC)',
  },
  MT: {
    Name: 'MT',
    Symbol: 'MT',
    CoinName: 'MyToken',
    FullName: 'MyToken (MT)',
  },
  SSH: {
    Name: 'SSH',
    Symbol: 'SSH',
    CoinName: 'StreamSpace',
    FullName: 'StreamSpace (SSH)',
  },
  XBI: {
    Name: 'XBI',
    Symbol: 'XBI',
    CoinName: 'Bitcoin Incognito',
    FullName: 'Bitcoin Incognito (XBI)',
  },
  MRK: {
    Name: 'MRK',
    Symbol: 'MRK',
    CoinName: 'MARK.SPACE',
    FullName: 'MARK.SPACE (MRK)',
  },
  FRV: {
    Name: 'FRV',
    Symbol: 'FRV',
    CoinName: 'Fitrova',
    FullName: 'Fitrova (FRV)',
  },
  XES: {
    Name: 'XES',
    Symbol: 'XES',
    CoinName: 'Proxeus',
    FullName: 'Proxeus (XES)',
  },
  RTB: {
    Name: 'RTB',
    Symbol: 'RTB',
    CoinName: 'AB-CHAIN',
    FullName: 'AB-CHAIN (RTB)',
  },
  FXT: {
    Name: 'FXT',
    Symbol: 'FXT',
    CoinName: 'FuzeX',
    FullName: 'FuzeX (FXT)',
  },
  DXC: {
    Name: 'DXC',
    Symbol: 'DXC',
    CoinName: 'DixiCoin',
    FullName: 'DixiCoin (DXC)',
  },
  CHBR: {
    Name: 'CHBR',
    Symbol: 'CHBR',
    CoinName: 'CryptoHub',
    FullName: 'CryptoHub (CHBR)',
  },
  OWD: {
    Name: 'OWD',
    Symbol: 'OWD',
    CoinName: 'Owlstand',
    FullName: 'Owlstand (OWD)',
  },
  ELLI: {
    Name: 'ELLI',
    Symbol: 'ELLI',
    CoinName: 'ElliotCoin',
    FullName: 'ElliotCoin (ELLI)',
  },
  DAN: {
    Name: 'DAN',
    Symbol: 'DAN',
    CoinName: 'Daneel',
    FullName: 'Daneel (DAN)',
  },
  CSEN: {
    Name: 'CSEN',
    Symbol: 'CSEN',
    CoinName: 'Consensus',
    FullName: 'Consensus (CSEN)',
  },
  AMO: {
    Name: 'AMO',
    Symbol: 'AMO',
    CoinName: 'AMO Coin',
    FullName: 'AMO Coin (AMO)',
  },
  LIVESTARS: {
    Name: 'LIVESTARS',
    Symbol: 'LIVESTARS',
    CoinName: 'Live Stars',
    FullName: 'Live Stars (LIVESTARS)',
  },
  GBG: {
    Name: 'GBG',
    Symbol: 'GBG',
    CoinName: 'Golos Gold',
    FullName: 'Golos Gold (GBG)',
  },
  SHL: {
    Name: 'SHL',
    Symbol: 'SHL',
    CoinName: 'Oyster Shell',
    FullName: 'Oyster Shell (SHL)',
  },
  ETZ: {
    Name: 'ETZ',
    Symbol: 'ETZ',
    CoinName: 'EtherZero',
    FullName: 'EtherZero (ETZ)',
  },
  IVY: {
    Name: 'IVY',
    Symbol: 'IVY',
    CoinName: 'IvyKoin',
    FullName: 'IvyKoin (IVY)',
  },
  KEC: {
    Name: 'KEC',
    Symbol: 'KEC',
    CoinName: 'KEYCO',
    FullName: 'KEYCO (KEC)',
  },
  ODE: {
    Name: 'ODE',
    Symbol: 'ODE',
    CoinName: 'ODEM',
    FullName: 'ODEM (ODE)',
  },
  AMN: {
    Name: 'AMN',
    Symbol: 'AMN',
    CoinName: 'Amon',
    FullName: 'Amon (AMN)',
  },
  SABR: {
    Name: 'SABR',
    Symbol: 'SABR',
    CoinName: 'SABR Coin',
    FullName: 'SABR Coin (SABR)',
  },
  HWC: {
    Name: 'HWC',
    Symbol: 'HWC',
    CoinName: 'HollyWoodCoin',
    FullName: 'HollyWoodCoin (HWC)',
  },
  BITGOLD: {
    Name: 'BITGOLD',
    Symbol: 'BITGOLD',
    CoinName: 'bitGold',
    FullName: 'bitGold (BITGOLD)',
  },
  BITSILVER: {
    Name: 'BITSILVER',
    Symbol: 'BITSILVER',
    CoinName: 'bitSilver',
    FullName: 'bitSilver (BITSILVER)',
  },
  GIN: {
    Name: 'GIN',
    Symbol: 'GIN',
    CoinName: 'GINcoin',
    FullName: 'GINcoin (GIN)',
  },
  NLX: {
    Name: 'NLX',
    Symbol: 'NLX',
    CoinName: 'Nullex',
    FullName: 'Nullex (NLX)',
  },
  LNKC: {
    Name: 'LNKC',
    Symbol: 'LNKC',
    CoinName: 'Linker Coin',
    FullName: 'Linker Coin (LNKC)',
  },
  MNW: {
    Name: 'MNW',
    Symbol: 'MNW',
    CoinName: 'Morpheus Network',
    FullName: 'Morpheus Network (MNW)',
  },
  ITL: {
    Name: 'ITL',
    Symbol: 'ITL',
    CoinName: 'Italian Lira',
    FullName: 'Italian Lira (ITL)',
  },
  AITT: {
    Name: 'AITT',
    Symbol: 'AITT',
    CoinName: 'AITrading',
    FullName: 'AITrading (AITT)',
  },
  ITM: {
    Name: 'ITM',
    Symbol: 'ITM',
    CoinName: 'intimate.io',
    FullName: 'intimate.io (ITM)',
  },
  VID: {
    Name: 'VID',
    Symbol: 'VID',
    CoinName: 'VideoCoin',
    FullName: 'VideoCoin (VID)',
  },
  SNTR: {
    Name: 'SNTR',
    Symbol: 'SNTR',
    CoinName: 'Silent Notary',
    FullName: 'Silent Notary (SNTR)',
  },
  XMV: {
    Name: 'XMV',
    Symbol: 'XMV',
    CoinName: 'MoneroV',
    FullName: 'MoneroV (XMV)',
  },
  HER: {
    Name: 'HER',
    Symbol: 'HER',
    CoinName: 'Hero Node',
    FullName: 'Hero Node (HER)',
  },
  PARLAY: {
    Name: 'PARLAY',
    Symbol: 'PARLAY',
    CoinName: 'Parlay',
    FullName: 'Parlay (PARLAY)',
  },
  SLX: {
    Name: 'SLX',
    Symbol: 'SLX',
    CoinName: 'Slate',
    FullName: 'Slate (SLX)',
  },
  LTCC: {
    Name: 'LTCC',
    Symbol: 'LTCC',
    CoinName: 'Listerclassic Coin',
    FullName: 'Listerclassic Coin (LTCC)',
  },
  RST: {
    Name: 'RST',
    Symbol: 'RST',
    CoinName: 'REGA Risk Sharing Token',
    FullName: 'REGA Risk Sharing Token (RST)',
  },
  AMX: {
    Name: 'AMX',
    Symbol: 'AMX',
    CoinName: 'Amero',
    FullName: 'Amero (AMX)',
  },
  TFC: {
    Name: 'TFC',
    Symbol: 'TFC',
    CoinName: 'The Freedom Coin',
    FullName: 'The Freedom Coin (TFC)',
  },
  IRC: {
    Name: 'IRC',
    Symbol: 'IRC',
    CoinName: 'IRONCOIN',
    FullName: 'IRONCOIN (IRC)',
  },
  PLATC: {
    Name: 'PLATC',
    Symbol: 'PLATC',
    CoinName: 'PlatinCoin',
    FullName: 'PlatinCoin (PLATC)',
  },
  OIO: {
    Name: 'OIO',
    Symbol: 'OIO',
    CoinName: 'Online',
    FullName: 'Online (OIO)',
  },
  ANGL: {
    Name: 'ANGL',
    Symbol: 'ANGL',
    CoinName: 'Angel Token',
    FullName: 'Angel Token (ANGL)',
  },
  ANTS: {
    Name: 'ANTS',
    Symbol: 'ANTS',
    CoinName: 'ANTS Reloaded',
    FullName: 'ANTS Reloaded (ANTS)',
  },
  KNG: {
    Name: 'KNG',
    Symbol: 'KNG',
    CoinName: 'BetKings',
    FullName: 'BetKings (KNG)',
  },
  CMM: {
    Name: 'CMM',
    Symbol: 'CMM',
    CoinName: 'Commercium',
    FullName: 'Commercium (CMM)',
  },
  WYS: {
    Name: 'WYS',
    Symbol: 'WYS',
    CoinName: 'Wysker',
    FullName: 'Wysker (WYS)',
  },
  COG: {
    Name: 'COG',
    Symbol: 'COG',
    CoinName: 'Cognitio',
    FullName: 'Cognitio (COG)',
  },
  ZIPT: {
    Name: 'ZIPT',
    Symbol: 'ZIPT',
    CoinName: 'Zippie',
    FullName: 'Zippie (ZIPT)',
  },
  OSA: {
    Name: 'OSA',
    Symbol: 'OSA',
    CoinName: 'OSA Token',
    FullName: 'OSA Token (OSA)',
  },
  EXC: {
    Name: 'EXC',
    Symbol: 'EXC',
    CoinName: 'Eximchain',
    FullName: 'Eximchain (EXC)',
  },
  BEN: {
    Name: 'BEN',
    Symbol: 'BEN',
    CoinName: 'BitCOEN',
    FullName: 'BitCOEN (BEN)',
  },
  EDR: {
    Name: 'EDR',
    Symbol: 'EDR',
    CoinName: 'Endor Protocol Token',
    FullName: 'Endor Protocol Token (EDR)',
  },
  BCIO: {
    Name: 'BCIO',
    Symbol: 'BCIO',
    CoinName: 'Blockchain.io',
    FullName: 'Blockchain.io (BCIO)',
  },
  BMK: {
    Name: 'BMK',
    Symbol: 'BMK',
    CoinName: 'Benchmark',
    FullName: 'Benchmark (BMK)',
  },
  ROC: {
    Name: 'ROC',
    Symbol: 'ROC',
    CoinName: 'Rasputin Online Coin',
    FullName: 'Rasputin Online Coin (ROC)',
  },
  LYL: {
    Name: 'LYL',
    Symbol: 'LYL',
    CoinName: 'LoyalCoin',
    FullName: 'LoyalCoin (LYL)',
  },
  PHI: {
    Name: 'PHI',
    Symbol: 'PHI',
    CoinName: 'PHI Token',
    FullName: 'PHI Token (PHI)',
  },
  PMNT: {
    Name: 'PMNT',
    Symbol: 'PMNT',
    CoinName: 'Paymon',
    FullName: 'Paymon (PMNT)',
  },
  BNTN: {
    Name: 'BNTN',
    Symbol: 'BNTN',
    CoinName: 'Blocnation',
    FullName: 'Blocnation (BNTN)',
  },
  HYT: {
    Name: 'HYT',
    Symbol: 'HYT',
    CoinName: 'HoryouToken',
    FullName: 'HoryouToken (HYT)',
  },
  GRMD: {
    Name: 'GRMD',
    Symbol: 'GRMD',
    CoinName: 'GreenMed',
    FullName: 'GreenMed (GRMD)',
  },
  SSC: {
    Name: 'SSC',
    Symbol: 'SSC',
    CoinName: 'SelfSell',
    FullName: 'SelfSell (SSC)',
  },
  BKT: {
    Name: 'BKT',
    Symbol: 'BKT',
    CoinName: 'Blocktrade token',
    FullName: 'Blocktrade token (BKT)',
  },
  NCP: {
    Name: 'NCP',
    Symbol: 'NCP',
    CoinName: 'Newton Coin',
    FullName: 'Newton Coin (NCP)',
  },
  STAX: {
    Name: 'STAX',
    Symbol: 'STAX',
    CoinName: 'Staxcoin',
    FullName: 'Staxcoin (STAX)',
  },
  MRN: {
    Name: 'MRN',
    Symbol: 'MRN',
    CoinName: 'Mercoin',
    FullName: 'Mercoin (MRN)',
  },
  FOPA: {
    Name: 'FOPA',
    Symbol: 'FOPA',
    CoinName: 'Fopacoin',
    FullName: 'Fopacoin (FOPA)',
  },
  CBC: {
    Name: 'CBC',
    Symbol: 'CBC',
    CoinName: 'Casino Betting Coin',
    FullName: 'Casino Betting Coin (CBC)',
  },
  OOT: {
    Name: 'OOT',
    Symbol: 'OOT',
    CoinName: 'Utrum',
    FullName: 'Utrum (OOT)',
  },
  NBC: {
    Name: 'NBC',
    Symbol: 'NBC',
    CoinName: 'Niobium',
    FullName: 'Niobium (NBC)',
  },
  SIC: {
    Name: 'SIC',
    Symbol: 'SIC',
    CoinName: 'Swisscoin',
    FullName: 'Swisscoin (SIC)',
  },
  EXCC: {
    Name: 'EXCC',
    Symbol: 'EXCC',
    CoinName: 'ExchangeCoin',
    FullName: 'ExchangeCoin (EXCC)',
  },
  REL: {
    Name: 'REL',
    Symbol: 'REL',
    CoinName: 'Reliance',
    FullName: 'Reliance (REL)',
  },
  BTCN: {
    Name: 'BTCN',
    Symbol: 'BTCN',
    CoinName: 'BitcoiNote',
    FullName: 'BitcoiNote (BTCN)',
  },
  RAISE: {
    Name: 'RAISE',
    Symbol: 'RAISE',
    CoinName: 'Raise Token',
    FullName: 'Raise Token (RAISE)',
  },
  EJAC: {
    Name: 'EJAC',
    Symbol: 'EJAC',
    CoinName: 'EJA Coin',
    FullName: 'EJA Coin (EJAC)',
  },
  APIS: {
    Name: 'APIS',
    Symbol: 'APIS',
    CoinName: 'APIS',
    FullName: 'APIS (APIS)',
  },
  XT3: {
    Name: 'XT3',
    Symbol: 'XT3',
    CoinName: 'Xt3ch',
    FullName: 'Xt3ch (XT3)',
  },
  MGD: {
    Name: 'MGD',
    Symbol: 'MGD',
    CoinName: 'MassGrid',
    FullName: 'MassGrid (MGD)',
  },
  VIG: {
    Name: 'VIG',
    Symbol: 'VIG',
    CoinName: 'TheVig',
    FullName: 'TheVig (VIG)',
  },
  PLURA: {
    Name: 'PLURA',
    Symbol: 'PLURA',
    CoinName: 'PluraCoin',
    FullName: 'PluraCoin (PLURA)',
  },
  SWACH: {
    Name: 'SWACH',
    Symbol: 'SWACH',
    CoinName: 'Swachhcoin',
    FullName: 'Swachhcoin (SWACH)',
  },
  NWCN: {
    Name: 'NWCN',
    Symbol: 'NWCN',
    CoinName: 'NowCoin',
    FullName: 'NowCoin (NWCN)',
  },
  EMAR: {
    Name: 'EMAR',
    Symbol: 'EMAR',
    CoinName: 'EmaratCoin',
    FullName: 'EmaratCoin (EMAR)',
  },
  ICST: {
    Name: 'ICST',
    Symbol: 'ICST',
    CoinName: 'ICST',
    FullName: 'ICST (ICST)',
  },
  ROE: {
    Name: 'ROE',
    Symbol: 'ROE',
    CoinName: 'Rover Coin',
    FullName: 'Rover Coin (ROE)',
  },
  LTCP: {
    Name: 'LTCP',
    Symbol: 'LTCP',
    CoinName: 'LitecoinPro',
    FullName: 'LitecoinPro (LTCP)',
  },
  LYNX: {
    Name: 'LYNX',
    Symbol: 'LYNX',
    CoinName: 'Lynx',
    FullName: 'Lynx (LYNX)',
  },
  POSQ: {
    Name: 'POSQ',
    Symbol: 'POSQ',
    CoinName: 'Poseidon Quark',
    FullName: 'Poseidon Quark (POSQ)',
  },
  YCE: {
    Name: 'YCE',
    Symbol: 'YCE',
    CoinName: 'MYCE',
    FullName: 'MYCE (YCE)',
  },
  OCX: {
    Name: 'OCX',
    Symbol: 'OCX',
    CoinName: 'Original Crypto Coin',
    FullName: 'Original Crypto Coin (OCX)',
  },
  STOR: {
    Name: 'STOR',
    Symbol: 'STOR',
    CoinName: 'Self Storage Coin',
    FullName: 'Self Storage Coin (STOR)',
  },
  ARO: {
    Name: 'ARO',
    Symbol: 'ARO',
    CoinName: 'Arionum',
    FullName: 'Arionum (ARO)',
  },
  BWS: {
    Name: 'BWS',
    Symbol: 'BWS',
    CoinName: 'BitcoinWSpectrum',
    FullName: 'BitcoinWSpectrum (BWS)',
  },
  BTCC: {
    Name: 'BTCC',
    Symbol: 'BTCC',
    CoinName: 'Bitcoin Core',
    FullName: 'Bitcoin Core (BTCC)',
  },
  GOLF: {
    Name: 'GOLF',
    Symbol: 'GOLF',
    CoinName: 'GolfCoin',
    FullName: 'GolfCoin (GOLF)',
  },
  ORACLECHAIN: {
    Name: 'ORACLECHAIN',
    Symbol: 'ORACLECHAIN',
    CoinName: 'OracleChain',
    FullName: 'OracleChain (ORACLECHAIN)',
  },
  XCEL: {
    Name: 'XCEL',
    Symbol: 'XCEL',
    CoinName: 'XcelTrip',
    FullName: 'XcelTrip (XCEL)',
  },
  ECH: {
    Name: 'ECH',
    Symbol: 'ECH',
    CoinName: 'EthereCash',
    FullName: 'EthereCash (ECH)',
  },
  XMN: {
    Name: 'XMN',
    Symbol: 'XMN',
    CoinName: 'Motion',
    FullName: 'Motion (XMN)',
  },
  PLUS1: {
    Name: 'PLUS1',
    Symbol: 'PLUS1',
    CoinName: 'PlusOneCoin',
    FullName: 'PlusOneCoin (PLUS1)',
  },
  COI: {
    Name: 'COI',
    Symbol: 'COI',
    CoinName: 'Coinnec',
    FullName: 'Coinnec (COI)',
  },
  CANDY: {
    Name: 'CANDY',
    Symbol: 'CANDY',
    CoinName: 'UnicornGo Candy',
    FullName: 'UnicornGo Candy (CANDY)',
  },
  AXE: {
    Name: 'AXE',
    Symbol: 'AXE',
    CoinName: 'Axe',
    FullName: 'Axe (AXE)',
  },
  SHARD: {
    Name: 'SHARD',
    Symbol: 'SHARD',
    CoinName: 'ShardCoin',
    FullName: 'ShardCoin (SHARD)',
  },
  GMCN: {
    Name: 'GMCN',
    Symbol: 'GMCN',
    CoinName: 'GambleCoin',
    FullName: 'GambleCoin (GMCN)',
  },
  TRVC: {
    Name: 'TRVC',
    Symbol: 'TRVC',
    CoinName: 'Trivechain',
    FullName: 'Trivechain (TRVC)',
  },
  KRX: {
    Name: 'KRX',
    Symbol: 'KRX',
    CoinName: 'RAVN Korrax',
    FullName: 'RAVN Korrax (KRX)',
  },
  BITX: {
    Name: 'BITX',
    Symbol: 'BITX',
    CoinName: 'BitScreener',
    FullName: 'BitScreener (BITX)',
  },
  SKRB: {
    Name: 'SKRB',
    Symbol: 'SKRB',
    CoinName: 'Sakura Bloom',
    FullName: 'Sakura Bloom (SKRB)',
  },
  OOW: {
    Name: 'OOW',
    Symbol: 'OOW',
    CoinName: 'OPP Open WiFi',
    FullName: 'OPP Open WiFi (OOW)',
  },
  DTEM: {
    Name: 'DTEM',
    Symbol: 'DTEM',
    CoinName: 'Dystem',
    FullName: 'Dystem (DTEM)',
  },
  INO: {
    Name: 'INO',
    Symbol: 'INO',
    CoinName: 'Ino Coin',
    FullName: 'Ino Coin (INO)',
  },
  TIP: {
    Name: 'TIP',
    Symbol: 'TIP',
    CoinName: 'Tip Blockchain',
    FullName: 'Tip Blockchain (TIP)',
  },
  HB: {
    Name: 'HB',
    Symbol: 'HB',
    CoinName: 'HeartBout',
    FullName: 'HeartBout (HB)',
  },
  GIC: {
    Name: 'GIC',
    Symbol: 'GIC',
    CoinName: 'Giant',
    FullName: 'Giant (GIC)',
  },
  SPARTA: {
    Name: 'SPARTA',
    Symbol: 'SPARTA',
    CoinName: 'Spartan Protocol Token',
    FullName: 'Spartan Protocol Token (SPARTA)',
  },
  ZMN: {
    Name: 'ZMN',
    Symbol: 'ZMN',
    CoinName: 'ZMINE',
    FullName: 'ZMINE (ZMN)',
  },
  PNY: {
    Name: 'PNY',
    Symbol: 'PNY',
    CoinName: 'Peony Coin',
    FullName: 'Peony Coin (PNY)',
  },
  SAFE: {
    Name: 'SAFE',
    Symbol: 'SAFE',
    CoinName: 'SafeCoin',
    FullName: 'SafeCoin (SAFE)',
  },
  COU: {
    Name: 'COU',
    Symbol: 'COU',
    CoinName: 'Couchain',
    FullName: 'Couchain (COU)',
  },
  ATHE: {
    Name: 'ATHE',
    Symbol: 'ATHE',
    CoinName: 'Atheios',
    FullName: 'Atheios (ATHE)',
  },
  VITAE: {
    Name: 'VITAE',
    Symbol: 'VITAE',
    CoinName: 'Vitae',
    FullName: 'Vitae (VITAE)',
  },
  ETERNALT: {
    Name: 'ETERNALT',
    Symbol: 'ETERNALT',
    CoinName: 'Eternal Token',
    FullName: 'Eternal Token (ETERNALT)',
  },
  '0xDIARY': {
    Name: '0xDIARY',
    Symbol: '0xDIARY',
    CoinName: 'The 0xDiary Token',
    FullName: 'The 0xDiary Token (0xDIARY)',
  },
  BSPM: {
    Name: 'BSPM',
    Symbol: 'BSPM',
    CoinName: 'Bitcoin Supreme',
    FullName: 'Bitcoin Supreme (BSPM)',
  },
  TDP: {
    Name: 'TDP',
    Symbol: 'TDP',
    CoinName: 'TrueDeck',
    FullName: 'TrueDeck (TDP)',
  },
  BIM: {
    Name: 'BIM',
    Symbol: 'BIM',
    CoinName: 'BitminerCoin',
    FullName: 'BitminerCoin (BIM)',
  },
  WORX: {
    Name: 'WORX',
    Symbol: 'WORX',
    CoinName: 'Worx',
    FullName: 'Worx (WORX)',
  },
  Dow: {
    Name: 'Dow',
    Symbol: 'Dow',
    CoinName: 'DowCoin',
    FullName: 'DowCoin (Dow)',
  },
  HEXC: {
    Name: 'HEXC',
    Symbol: 'HEXC',
    CoinName: 'HexCoin',
    FullName: 'HexCoin (HEXC)',
  },
  PYT: {
    Name: 'PYT',
    Symbol: 'PYT',
    CoinName: 'Payther',
    FullName: 'Payther (PYT)',
  },
  DEI: {
    Name: 'DEI',
    Symbol: 'DEI',
    CoinName: 'Deimos',
    FullName: 'Deimos (DEI)',
  },
  TPCASH: {
    Name: 'TPCASH',
    Symbol: 'TPCASH',
    CoinName: 'TPCash',
    FullName: 'TPCash (TPCASH)',
  },
  OYS: {
    Name: 'OYS',
    Symbol: 'OYS',
    CoinName: 'Oyster Platform',
    FullName: 'Oyster Platform (OYS)',
  },
  WEBC: {
    Name: 'WEBC',
    Symbol: 'WEBC',
    CoinName: 'Webchain',
    FullName: 'Webchain (WEBC)',
  },
  JEX: {
    Name: 'JEX',
    Symbol: 'JEX',
    CoinName: 'JEX Token',
    FullName: 'JEX Token (JEX)',
  },
  ILK: {
    Name: 'ILK',
    Symbol: 'ILK',
    CoinName: 'Inlock',
    FullName: 'Inlock (ILK)',
  },
  RYO: {
    Name: 'RYO',
    Symbol: 'RYO',
    CoinName: 'Ryo',
    FullName: 'Ryo (RYO)',
  },
  MUSDCOIN: {
    Name: 'MUSDCOIN',
    Symbol: 'MUSDCOIN',
    CoinName: 'MUSDcoin',
    FullName: 'MUSDcoin (MUSDCOIN)',
  },
  MINDEX: {
    Name: 'MINDEX',
    Symbol: 'MINDEX',
    CoinName: 'Mindexcoin',
    FullName: 'Mindexcoin (MINDEX)',
  },
  URALS: {
    Name: 'URALS',
    Symbol: 'URALS',
    CoinName: 'Urals Coin',
    FullName: 'Urals Coin (URALS)',
  },
  QWC: {
    Name: 'QWC',
    Symbol: 'QWC',
    CoinName: 'Qwertycoin',
    FullName: 'Qwertycoin (QWC)',
  },
  BITN: {
    Name: 'BITN',
    Symbol: 'BITN',
    CoinName: 'Bitcoin Nova',
    FullName: 'Bitcoin Nova (BITN)',
  },
  AREN: {
    Name: 'AREN',
    Symbol: 'AREN',
    CoinName: 'Arenon',
    FullName: 'Arenon (AREN)',
  },
  DACASH: {
    Name: 'DACASH',
    Symbol: 'DACASH',
    CoinName: 'DACash',
    FullName: 'DACash (DACASH)',
  },
  EUNO: {
    Name: 'EUNO',
    Symbol: 'EUNO',
    CoinName: 'EUNO',
    FullName: 'EUNO (EUNO)',
  },
  KAAS: {
    Name: 'KAAS',
    Symbol: 'KAAS',
    CoinName: 'KAASY.AI',
    FullName: 'KAASY.AI (KAAS)',
  },
  MMO: {
    Name: 'MMO',
    Symbol: 'MMO',
    CoinName: 'MMOCoin',
    FullName: 'MMOCoin (MMO)',
  },
  MERCU: {
    Name: 'MERCU',
    Symbol: 'MERCU',
    CoinName: 'Merculet',
    FullName: 'Merculet (MERCU)',
  },
  DASC: {
    Name: 'DASC',
    Symbol: 'DASC',
    CoinName: 'DasCoin',
    FullName: 'DasCoin (DASC)',
  },
  PGTS: {
    Name: 'PGTS',
    Symbol: 'PGTS',
    CoinName: 'Puregold token',
    FullName: 'Puregold token (PGTS)',
  },
  SLST: {
    Name: 'SLST',
    Symbol: 'SLST',
    CoinName: 'SmartLands',
    FullName: 'SmartLands (SLST)',
  },
  TGAME: {
    Name: 'TGAME',
    Symbol: 'TGAME',
    CoinName: 'TrueGame',
    FullName: 'TrueGame (TGAME)',
  },
  SPN: {
    Name: 'SPN',
    Symbol: 'SPN',
    CoinName: 'Sapien Network',
    FullName: 'Sapien Network (SPN)',
  },
  ZINC: {
    Name: 'ZINC',
    Symbol: 'ZINC',
    CoinName: 'ZINC',
    FullName: 'ZINC (ZINC)',
  },
  KETAN: {
    Name: 'KETAN',
    Symbol: 'KETAN',
    CoinName: 'Ketan',
    FullName: 'Ketan (KETAN)',
  },
  KBC: {
    Name: 'KBC',
    Symbol: 'KBC',
    CoinName: 'Karatgold coin',
    FullName: 'Karatgold coin (KBC)',
  },
  INSURC: {
    Name: 'INSURC',
    Symbol: 'INSURC',
    CoinName: 'InsurChain Coin',
    FullName: 'InsurChain Coin (INSURC)',
  },
  NIX: {
    Name: 'NIX',
    Symbol: 'NIX',
    CoinName: 'NIX',
    FullName: 'NIX (NIX)',
  },
  FINOM: {
    Name: 'FINOM',
    Symbol: 'FINOM',
    CoinName: 'Finom FIN Token',
    FullName: 'Finom FIN Token (FINOM)',
  },
  RPM: {
    Name: 'RPM',
    Symbol: 'RPM',
    CoinName: 'Render Payment',
    FullName: 'Render Payment (RPM)',
  },
  DGX: {
    Name: 'DGX',
    Symbol: 'DGX',
    CoinName: 'Digix Gold token',
    FullName: 'Digix Gold token (DGX)',
  },
  NOM: {
    Name: 'NOM',
    Symbol: 'NOM',
    CoinName: 'Finom NOM Token',
    FullName: 'Finom NOM Token (NOM)',
  },
  XSTC: {
    Name: 'XSTC',
    Symbol: 'XSTC',
    CoinName: 'Safe Trade Coin',
    FullName: 'Safe Trade Coin (XSTC)',
  },
  EGCC: {
    Name: 'EGCC',
    Symbol: 'EGCC',
    CoinName: 'Engine',
    FullName: 'Engine (EGCC)',
  },
  FREC: {
    Name: 'FREC',
    Symbol: 'FREC',
    CoinName: 'Freyrchain',
    FullName: 'Freyrchain (FREC)',
  },
  DCC: {
    Name: 'DCC',
    Symbol: 'DCC',
    CoinName: 'Distributed Credit Chain',
    FullName: 'Distributed Credit Chain (DCC)',
  },
  MOTI: {
    Name: 'MOTI',
    Symbol: 'MOTI',
    CoinName: 'Motion',
    FullName: 'Motion (MOTI)',
  },
  PAI: {
    Name: 'PAI',
    Symbol: 'PAI',
    CoinName: 'Project Pai',
    FullName: 'Project Pai (PAI)',
  },
  CBRT: {
    Name: 'CBRT',
    Symbol: 'CBRT',
    CoinName: 'Cybereits Token',
    FullName: 'Cybereits Token (CBRT)',
  },
  MEET: {
    Name: 'MEET',
    Symbol: 'MEET',
    CoinName: 'CoinMeet',
    FullName: 'CoinMeet (MEET)',
  },
  BOE: {
    Name: 'BOE',
    Symbol: 'BOE',
    CoinName: 'Bodhi',
    FullName: 'Bodhi (BOE)',
  },
  RTE: {
    Name: 'RTE',
    Symbol: 'RTE',
    CoinName: 'Rate3',
    FullName: 'Rate3 (RTE)',
  },
  CAR: {
    Name: 'CAR',
    Symbol: 'CAR',
    CoinName: 'CarBlock',
    FullName: 'CarBlock (CAR)',
  },
  CPT: {
    Name: 'CPT',
    Symbol: 'CPT',
    CoinName: 'Cryptaur',
    FullName: 'Cryptaur (CPT)',
  },
  PCO: {
    Name: 'PCO',
    Symbol: 'PCO',
    CoinName: 'Pecunio',
    FullName: 'Pecunio (PCO)',
  },
  XPST: {
    Name: 'XPST',
    Symbol: 'XPST',
    CoinName: 'PokerSports',
    FullName: 'PokerSports (XPST)',
  },
  MCV: {
    Name: 'MCV',
    Symbol: 'MCV',
    CoinName: 'MCV Token',
    FullName: 'MCV Token (MCV)',
  },
  SCRL: {
    Name: 'SCRL',
    Symbol: 'SCRL',
    CoinName: 'Scroll',
    FullName: 'Scroll (SCRL)',
  },
  CONI: {
    Name: 'CONI',
    Symbol: 'CONI',
    CoinName: 'CoinBene',
    FullName: 'CoinBene (CONI)',
  },
  XPAT: {
    Name: 'XPAT',
    Symbol: 'XPAT',
    CoinName: 'Bitnation Pangea',
    FullName: 'Bitnation Pangea (XPAT)',
  },
  MBLC: {
    Name: 'MBLC',
    Symbol: 'MBLC',
    CoinName: 'Mont Blanc',
    FullName: 'Mont Blanc (MBLC)',
  },
  DIW: {
    Name: 'DIW',
    Symbol: 'DIW',
    CoinName: 'DIWtoken',
    FullName: 'DIWtoken (DIW)',
  },
  JOINT: {
    Name: 'JOINT',
    Symbol: 'JOINT',
    CoinName: 'Joint Ventures',
    FullName: 'Joint Ventures (JOINT)',
  },
  IDXM: {
    Name: 'IDXM',
    Symbol: 'IDXM',
    CoinName: 'IDEX Membership',
    FullName: 'IDEX Membership (IDXM)',
  },
  CCO: {
    Name: 'CCO',
    Symbol: 'CCO',
    CoinName: 'Ccore',
    FullName: 'Ccore (CCO)',
  },
  ATMI: {
    Name: 'ATMI',
    Symbol: 'ATMI',
    CoinName: 'Atonomi',
    FullName: 'Atonomi (ATMI)',
  },
  TKA: {
    Name: 'TKA',
    Symbol: 'TKA',
    CoinName: 'Tokia',
    FullName: 'Tokia (TKA)',
  },
  GETX: {
    Name: 'GETX',
    Symbol: 'GETX',
    CoinName: 'Guaranteed Ethurance Token Extra',
    FullName: 'Guaranteed Ethurance Token Extra (GETX)',
  },
  BWT: {
    Name: 'BWT',
    Symbol: 'BWT',
    CoinName: 'Bittwatt',
    FullName: 'Bittwatt (BWT)',
  },
  EMV: {
    Name: 'EMV',
    Symbol: 'EMV',
    CoinName: 'Ethereum Movie Venture',
    FullName: 'Ethereum Movie Venture (EMV)',
  },
  ESZ: {
    Name: 'ESZ',
    Symbol: 'ESZ',
    CoinName: 'EtherSportz',
    FullName: 'EtherSportz (ESZ)',
  },
  TRAK: {
    Name: 'TRAK',
    Symbol: 'TRAK',
    CoinName: 'TrakInvest',
    FullName: 'TrakInvest (TRAK)',
  },
  ZXC: {
    Name: 'ZXC',
    Symbol: 'ZXC',
    CoinName: 'Oxcert',
    FullName: 'Oxcert (ZXC)',
  },
  BTRN: {
    Name: 'BTRN',
    Symbol: 'BTRN',
    CoinName: 'Biotron',
    FullName: 'Biotron (BTRN)',
  },
  VME: {
    Name: 'VME',
    Symbol: 'VME',
    CoinName: 'TrueVett',
    FullName: 'TrueVett (VME)',
  },
  PERU: {
    Name: 'PERU',
    Symbol: 'PERU',
    CoinName: 'PeruCoin',
    FullName: 'PeruCoin (PERU)',
  },
  BBO: {
    Name: 'BBO',
    Symbol: 'BBO',
    CoinName: 'Bigbom',
    FullName: 'Bigbom (BBO)',
  },
  YUP: {
    Name: 'YUP',
    Symbol: 'YUP',
    CoinName: 'Crowdholding',
    FullName: 'Crowdholding (YUP)',
  },
  SNIP: {
    Name: 'SNIP',
    Symbol: 'SNIP',
    CoinName: 'LyrnAI',
    FullName: 'LyrnAI (SNIP)',
  },
  XDNA: {
    Name: 'XDNA',
    Symbol: 'XDNA',
    CoinName: 'XDNA',
    FullName: 'XDNA (XDNA)',
  },
  SAL: {
    Name: 'SAL',
    Symbol: 'SAL',
    CoinName: 'SalPay',
    FullName: 'SalPay (SAL)',
  },
  THRT: {
    Name: 'THRT',
    Symbol: 'THRT',
    CoinName: 'ThriveToken',
    FullName: 'ThriveToken (THRT)',
  },
  GTK: {
    Name: 'GTK',
    Symbol: 'GTK',
    CoinName: 'GoToken',
    FullName: 'GoToken (GTK)',
  },
  SKRP: {
    Name: 'SKRP',
    Symbol: 'SKRP',
    CoinName: 'Skraps',
    FullName: 'Skraps (SKRP)',
  },
  AVH: {
    Name: 'AVH',
    Symbol: 'AVH',
    CoinName: 'Animation Vision Cash',
    FullName: 'Animation Vision Cash (AVH)',
  },
  SCC: {
    Name: 'SCC',
    Symbol: 'SCC',
    CoinName: 'StockChain Coin',
    FullName: 'StockChain Coin (SCC)',
  },
  HALO: {
    Name: 'HALO',
    Symbol: 'HALO',
    CoinName: 'Halo Platform',
    FullName: 'Halo Platform (HALO)',
  },
  BSTN: {
    Name: 'BSTN',
    Symbol: 'BSTN',
    CoinName: 'BitStation',
    FullName: 'BitStation (BSTN)',
  },
  PITCH: {
    Name: 'PITCH',
    Symbol: 'PITCH',
    CoinName: 'PITCH',
    FullName: 'PITCH (PITCH)',
  },
  NANJ: {
    Name: 'NANJ',
    Symbol: 'NANJ',
    CoinName: 'NANJCOIN',
    FullName: 'NANJCOIN (NANJ)',
  },
  DIT: {
    Name: 'DIT',
    Symbol: 'DIT',
    CoinName: 'Ditcoin',
    FullName: 'Ditcoin (DIT)',
  },
  AZART: {
    Name: 'AZART',
    Symbol: 'AZART',
    CoinName: 'Azart',
    FullName: 'Azart (AZART)',
  },
  RDC: {
    Name: 'RDC',
    Symbol: 'RDC',
    CoinName: 'Ordocoin',
    FullName: 'Ordocoin (RDC)',
  },
  TTU: {
    Name: 'TTU',
    Symbol: 'TTU',
    CoinName: 'TaTaTu',
    FullName: 'TaTaTu (TTU)',
  },
  FREE: {
    Name: 'FREE',
    Symbol: 'FREE',
    CoinName: 'FREE coin',
    FullName: 'FREE coin (FREE)',
  },
  AOP: {
    Name: 'AOP',
    Symbol: 'AOP',
    CoinName: 'Averopay',
    FullName: 'Averopay (AOP)',
  },
  XAP: {
    Name: 'XAP',
    Symbol: 'XAP',
    CoinName: 'Apollon',
    FullName: 'Apollon (XAP)',
  },
  INTO: {
    Name: 'INTO',
    Symbol: 'INTO',
    CoinName: 'Influ Token',
    FullName: 'Influ Token (INTO)',
  },
  AIMS: {
    Name: 'AIMS',
    Symbol: 'AIMS',
    CoinName: 'HighCastle Token',
    FullName: 'HighCastle Token (AIMS)',
  },
  THUNDER: {
    Name: 'THUNDER',
    Symbol: 'THUNDER',
    CoinName: 'ThunderStake',
    FullName: 'ThunderStake (THUNDER)',
  },
  SEER: {
    Name: 'SEER',
    Symbol: 'SEER',
    CoinName: 'SEER',
    FullName: 'SEER (SEER)',
  },
  CMZ: {
    Name: 'CMZ',
    Symbol: 'CMZ',
    CoinName: 'CRYPTOMAGZ',
    FullName: 'CRYPTOMAGZ (CMZ)',
  },
  NOBS: {
    Name: '$NOBS',
    Symbol: 'NOBS',
    CoinName: 'No BS Crypto',
    FullName: 'No BS Crypto ($NOBS)',
  },
  HMN: {
    Name: 'HMN',
    Symbol: 'HMN',
    CoinName: 'Harvest Masternode Coin',
    FullName: 'Harvest Masternode Coin (HMN)',
  },
  MHP: {
    Name: 'MHP',
    Symbol: 'MHP',
    CoinName: 'MedicoHealth',
    FullName: 'MedicoHealth (MHP)',
  },
  HMD: {
    Name: 'HMD',
    Symbol: 'HMD',
    CoinName: 'Homelend',
    FullName: 'Homelend (HMD)',
  },
  JSE: {
    Name: 'JSE',
    Symbol: 'JSE',
    CoinName: 'JSEcoin',
    FullName: 'JSEcoin (JSE)',
  },
  IMGZ: {
    Name: 'IMGZ',
    Symbol: 'IMGZ',
    CoinName: 'Imigize',
    FullName: 'Imigize (IMGZ)',
  },
  NYN: {
    Name: 'NYN',
    Symbol: 'NYN',
    CoinName: 'NYNJA',
    FullName: 'NYNJA (NYN)',
  },
  IAM: {
    Name: 'IAM',
    Symbol: 'IAM',
    CoinName: 'IAME Identity',
    FullName: 'IAME Identity (IAM)',
  },
  CHART: {
    Name: 'CHART',
    Symbol: 'CHART',
    CoinName: 'BetOnChart',
    FullName: 'BetOnChart (CHART)',
  },
  WHEN: {
    Name: 'WHEN',
    Symbol: 'WHEN',
    CoinName: 'WhenHub',
    FullName: 'WhenHub (WHEN)',
  },
  SFT: {
    Name: 'SFT',
    Symbol: 'SFT',
    CoinName: 'SportsFix',
    FullName: 'SportsFix (SFT)',
  },
  ORBIS: {
    Name: 'ORBIS',
    Symbol: 'ORBIS',
    CoinName: 'Orbis',
    FullName: 'Orbis (ORBIS)',
  },
  BLKS: {
    Name: 'BLKS',
    Symbol: 'BLKS',
    CoinName: 'Blockshipping',
    FullName: 'Blockshipping (BLKS)',
  },
  ETRNT: {
    Name: 'ETRNT',
    Symbol: 'ETRNT',
    CoinName: 'Eternal Trusts',
    FullName: 'Eternal Trusts (ETRNT)',
  },
  ITR: {
    Name: 'ITR',
    Symbol: 'ITR',
    CoinName: 'INTRO',
    FullName: 'INTRO (ITR)',
  },
  CACHE: {
    Name: 'CACHE',
    Symbol: 'CACHE',
    CoinName: 'Cache',
    FullName: 'Cache (CACHE)',
  },
  RSC: {
    Name: 'RSC',
    Symbol: 'RSC',
    CoinName: 'Ronaldinho Soccer Coin',
    FullName: 'Ronaldinho Soccer Coin (RSC)',
  },
  ENTRY: {
    Name: 'ENTRY',
    Symbol: 'ENTRY',
    CoinName: 'ENTRY',
    FullName: 'ENTRY (ENTRY)',
  },
  PHTC: {
    Name: 'PHTC',
    Symbol: 'PHTC',
    CoinName: 'Photochain',
    FullName: 'Photochain (PHTC)',
  },
  AWORK: {
    Name: 'AWORK',
    Symbol: 'AWORK',
    CoinName: 'Aworker',
    FullName: 'Aworker (AWORK)',
  },
  ORGA: {
    Name: 'ORGA',
    Symbol: 'ORGA',
    CoinName: 'Organicco',
    FullName: 'Organicco (ORGA)',
  },
  DNET: {
    Name: 'DNET',
    Symbol: 'DNET',
    CoinName: 'DeNet',
    FullName: 'DeNet (DNET)',
  },
  IDAP: {
    Name: 'IDAP',
    Symbol: 'IDAP',
    CoinName: 'IDAP',
    FullName: 'IDAP (IDAP)',
  },
  HEAL: {
    Name: 'HEAL',
    Symbol: 'HEAL',
    CoinName: 'Etheal',
    FullName: 'Etheal (HEAL)',
  },
  OFCR: {
    Name: 'OFCR',
    Symbol: 'OFCR',
    CoinName: 'CryptoPolice',
    FullName: 'CryptoPolice (OFCR)',
  },
  TERAWATT: {
    Name: 'TERAWATT',
    Symbol: 'TERAWATT',
    CoinName: 'Terawatt',
    FullName: 'Terawatt (TERAWATT)',
  },
  RBDT: {
    Name: 'RBDT',
    Symbol: 'RBDT',
    CoinName: 'RoBust Defense Token',
    FullName: 'RoBust Defense Token (RBDT)',
  },
  SKYFT: {
    Name: 'SKYFT',
    Symbol: 'SKYFT',
    CoinName: 'SKYFchain',
    FullName: 'SKYFchain (SKYFT)',
  },
  FAN360: {
    Name: 'FAN360',
    Symbol: 'FAN360',
    CoinName: 'Fan360',
    FullName: 'Fan360 (FAN360)',
  },
  GBTC: {
    Name: 'GBTC',
    Symbol: 'GBTC',
    CoinName: 'GigTricks',
    FullName: 'GigTricks (GBTC)',
  },
  NBOX: {
    Name: 'NBOX',
    Symbol: 'NBOX',
    CoinName: 'Unboxed',
    FullName: 'Unboxed (NBOX)',
  },
  BUD: {
    Name: 'BUD',
    Symbol: 'BUD',
    CoinName: 'Buddy',
    FullName: 'Buddy (BUD)',
  },
  DBCCOIN: {
    Name: 'DBCCOIN',
    Symbol: 'DBCCOIN',
    CoinName: 'Datablockchain',
    FullName: 'Datablockchain (DBCCOIN)',
  },
  K2G: {
    Name: 'K2G',
    Symbol: 'K2G',
    CoinName: 'Kasko2go',
    FullName: 'Kasko2go (K2G)',
  },
  ARR: {
    Name: 'ARR',
    Symbol: 'ARR',
    CoinName: 'ARROUND',
    FullName: 'ARROUND (ARR)',
  },
  GAMB: {
    Name: 'GAMB',
    Symbol: 'GAMB',
    CoinName: 'GAMB',
    FullName: 'GAMB (GAMB)',
  },
  ETI: {
    Name: 'ETI',
    Symbol: 'ETI',
    CoinName: 'EtherInc',
    FullName: 'EtherInc (ETI)',
  },
  FRECNX: {
    Name: 'FRECNX',
    Symbol: 'FRECNX',
    CoinName: 'FreldoCoinX',
    FullName: 'FreldoCoinX (FRECNX)',
  },
  NOIA: {
    Name: 'NOIA',
    Symbol: 'NOIA',
    CoinName: 'Syntropy',
    FullName: 'Syntropy (NOIA)',
  },
  LAX: {
    Name: 'LAX',
    Symbol: 'LAX',
    CoinName: 'LAPO',
    FullName: 'LAPO (LAX)',
  },
  BOOSTO: {
    Name: 'BOOSTO',
    Symbol: 'BOOSTO',
    CoinName: 'BOOSTO',
    FullName: 'BOOSTO (BOOSTO)',
  },
  DREAM: {
    Name: 'DREAM',
    Symbol: 'DREAM',
    CoinName: 'DREAM',
    FullName: 'DREAM (DREAM)',
  },
  PTI: {
    Name: 'PTI',
    Symbol: 'PTI',
    CoinName: 'Paytomat',
    FullName: 'Paytomat (PTI)',
  },
  LPC: {
    Name: 'LPC',
    Symbol: 'LPC',
    CoinName: 'Little Phil',
    FullName: 'Little Phil (LPC)',
  },
  DYNO: {
    Name: 'DYNO',
    Symbol: 'DYNO',
    CoinName: 'DYNO',
    FullName: 'DYNO (DYNO)',
  },
  MFX: {
    Name: 'MFX',
    Symbol: 'MFX',
    CoinName: 'MFChain',
    FullName: 'MFChain (MFX)',
  },
  NOIZ: {
    Name: 'NOIZ',
    Symbol: 'NOIZ',
    CoinName: 'NOIZ',
    FullName: 'NOIZ (NOIZ)',
  },
  SPIKE: {
    Name: 'SPIKE',
    Symbol: 'SPIKE',
    CoinName: 'Spiking',
    FullName: 'Spiking (SPIKE)',
  },
  RAWG: {
    Name: 'RAWG',
    Symbol: 'RAWG',
    CoinName: 'RAWG',
    FullName: 'RAWG (RAWG)',
  },
  BDB: {
    Name: 'BDB',
    Symbol: 'BDB',
    CoinName: 'Big Data Block',
    FullName: 'Big Data Block (BDB)',
  },
  OPET: {
    Name: 'OPET',
    Symbol: 'OPET',
    CoinName: 'ÕpetFoundation',
    FullName: 'ÕpetFoundation (OPET)',
  },
  PSK: {
    Name: 'PSK',
    Symbol: 'PSK',
    CoinName: 'Pool of Stake',
    FullName: 'Pool of Stake (PSK)',
  },
  KVT: {
    Name: 'KVT',
    Symbol: 'KVT',
    CoinName: 'Kinesis Velocity Token',
    FullName: 'Kinesis Velocity Token (KVT)',
  },
  COT: {
    Name: 'COT',
    Symbol: 'COT',
    CoinName: 'CoTrader',
    FullName: 'CoTrader (COT)',
  },
  ARBT: {
    Name: 'ARBT',
    Symbol: 'ARBT',
    CoinName: 'ARBITRAGE',
    FullName: 'ARBITRAGE (ARBT)',
  },
  OMI: {
    Name: 'OMI',
    Symbol: 'OMI',
    CoinName: 'ECOMI',
    FullName: 'ECOMI (OMI)',
  },
  BILL: {
    Name: 'BILL',
    Symbol: 'BILL',
    CoinName: 'TillBilly',
    FullName: 'TillBilly (BILL)',
  },
  WBBC: {
    Name: 'WBBC',
    Symbol: 'WBBC',
    CoinName: 'Wibcoin',
    FullName: 'Wibcoin (WBBC)',
  },
  WPP: {
    Name: 'WPP',
    Symbol: 'WPP',
    CoinName: 'Green Energy Token',
    FullName: 'Green Energy Token (WPP)',
  },
  ASTON: {
    Name: 'ASTON',
    Symbol: 'ASTON',
    CoinName: 'Aston',
    FullName: 'Aston (ASTON)',
  },
  SLT: {
    Name: 'SLT',
    Symbol: 'SLT',
    CoinName: 'Social Lending Network',
    FullName: 'Social Lending Network (SLT)',
  },
  HDAC: {
    Name: 'HDAC',
    Symbol: 'HDAC',
    CoinName: 'Hdac',
    FullName: 'Hdac (HDAC)',
  },
  CCCX: {
    Name: 'CCCX',
    Symbol: 'CCCX',
    CoinName: 'Clipper Coin Capital',
    FullName: 'Clipper Coin Capital (CCCX)',
  },
  AEN: {
    Name: 'AEN',
    Symbol: 'AEN',
    CoinName: 'Aenco',
    FullName: 'Aenco (AEN)',
  },
  SOLID: {
    Name: 'SOLID',
    Symbol: 'SOLID',
    CoinName: 'Solidified',
    FullName: 'Solidified (SOLID)',
  },
  AIRE: {
    Name: 'AIRE',
    Symbol: 'AIRE',
    CoinName: 'Tokenaire',
    FullName: 'Tokenaire (AIRE)',
  },
  GMA: {
    Name: 'GMA',
    Symbol: 'GMA',
    CoinName: 'Goldchip Mining Asset',
    FullName: 'Goldchip Mining Asset (GMA)',
  },
  WMB: {
    Name: 'WMB',
    Symbol: 'WMB',
    CoinName: 'WatermelonBlock',
    FullName: 'WatermelonBlock (WMB)',
  },
  MVU: {
    Name: 'MVU',
    Symbol: 'MVU',
    CoinName: 'meVu',
    FullName: 'meVu (MVU)',
  },
  GLDR: {
    Name: 'GLDR',
    Symbol: 'GLDR',
    CoinName: 'Golder Coin',
    FullName: 'Golder Coin (GLDR)',
  },
  IMU: {
    Name: 'IMU',
    Symbol: 'IMU',
    CoinName: 'imusify',
    FullName: 'imusify (IMU)',
  },
  CRS: {
    Name: 'CRS',
    Symbol: 'CRS',
    CoinName: 'CRYSTALS',
    FullName: 'CRYSTALS (CRS)',
  },
  CRON: {
    Name: 'CRON',
    Symbol: 'CRON',
    CoinName: 'Cryptocean',
    FullName: 'Cryptocean (CRON)',
  },
  DIP: {
    Name: 'DIP',
    Symbol: 'DIP',
    CoinName: 'Etherisc',
    FullName: 'Etherisc (DIP)',
  },
  PROD: {
    Name: 'PROD',
    Symbol: 'PROD',
    CoinName: 'Productivist',
    FullName: 'Productivist (PROD)',
  },
  REDC: {
    Name: 'REDC',
    Symbol: 'REDC',
    CoinName: 'RedCab',
    FullName: 'RedCab (REDC)',
  },
  ZCHN: {
    Name: 'ZCHN',
    Symbol: 'ZCHN',
    CoinName: 'Zichain',
    FullName: 'Zichain (ZCHN)',
  },
  TTV: {
    Name: 'TTV',
    Symbol: 'TTV',
    CoinName: 'TV-TWO',
    FullName: 'TV-TWO (TTV)',
  },
  OICOIN: {
    Name: 'OICOIN',
    Symbol: 'OICOIN',
    CoinName: 'Osmium Investment Coin',
    FullName: 'Osmium Investment Coin (OICOIN)',
  },
  ENQ: {
    Name: 'ENQ',
    Symbol: 'ENQ',
    CoinName: 'Enecuum',
    FullName: 'Enecuum (ENQ)',
  },
  DTN: {
    Name: 'DTN',
    Symbol: 'DTN',
    CoinName: 'Datareum',
    FullName: 'Datareum (DTN)',
  },
  IDM: {
    Name: 'IDM',
    Symbol: 'IDM',
    CoinName: 'IDM',
    FullName: 'IDM (IDM)',
  },
  CDPT: {
    Name: 'CDPT',
    Symbol: 'CDPT',
    CoinName: 'Creditor Data Platform',
    FullName: 'Creditor Data Platform (CDPT)',
  },
  CRGO: {
    Name: 'CRGO',
    Symbol: 'CRGO',
    CoinName: 'CargoCoin',
    FullName: 'CargoCoin (CRGO)',
  },
  LANE: {
    Name: 'LANE',
    Symbol: 'LANE',
    CoinName: 'LaneAxis',
    FullName: 'LaneAxis (LANE)',
  },
  QRP: {
    Name: 'QRP',
    Symbol: 'QRP',
    CoinName: 'Cryptics',
    FullName: 'Cryptics (QRP)',
  },
  TIIM: {
    Name: 'TIIM',
    Symbol: 'TIIM',
    CoinName: 'TriipMiles',
    FullName: 'TriipMiles (TIIM)',
  },
  BNR: {
    Name: 'BNR',
    Symbol: 'BNR',
    CoinName: 'BiNeuro',
    FullName: 'BiNeuro (BNR)',
  },
  ZCC1: {
    Name: 'ZCC1',
    Symbol: 'ZCC1',
    CoinName: 'ZeroCarbon',
    FullName: 'ZeroCarbon (ZCC1)',
  },
  KRP: {
    Name: 'KRP',
    Symbol: 'KRP',
    CoinName: 'Kryptoin',
    FullName: 'Kryptoin (KRP)',
  },
  DAG: {
    Name: 'DAG',
    Symbol: 'DAG',
    CoinName: 'Constellation',
    FullName: 'Constellation (DAG)',
  },
  AMLT: {
    Name: 'AMLT',
    Symbol: 'AMLT',
    CoinName: 'AMLT',
    FullName: 'AMLT (AMLT)',
  },
  HGO: {
    Name: 'HGO',
    Symbol: 'HGO',
    CoinName: 'HireGo',
    FullName: 'HireGo (HGO)',
  },
  BZ: {
    Name: 'BZ',
    Symbol: 'BZ',
    CoinName: 'Bit-Z',
    FullName: 'Bit-Z (BZ)',
  },
  ZIP: {
    Name: 'ZIP',
    Symbol: 'ZIP',
    CoinName: 'Zipper',
    FullName: 'Zipper (ZIP)',
  },
  BTK: {
    Name: 'BTK',
    Symbol: 'BTK',
    CoinName: 'Bostoken',
    FullName: 'Bostoken (BTK)',
  },
  '1WO': {
    Name: '1WO',
    Symbol: '1WO',
    CoinName: '1World',
    FullName: '1World (1WO)',
  },
  BOUTS: {
    Name: 'BOUTS',
    Symbol: 'BOUTS',
    CoinName: 'BoutsPro',
    FullName: 'BoutsPro (BOUTS)',
  },
  OGSP: {
    Name: 'OGSP',
    Symbol: 'OGSP',
    CoinName: 'OriginSport',
    FullName: 'OriginSport (OGSP)',
  },
  EST: {
    Name: 'EST',
    Symbol: 'EST',
    CoinName: 'ESports Chain',
    FullName: 'ESports Chain (EST)',
  },
  MODEX: {
    Name: 'MODEX',
    Symbol: 'MODEX',
    CoinName: 'MODEX Token',
    FullName: 'MODEX Token (MODEX)',
  },
  OGT: {
    Name: 'OGT',
    Symbol: 'OGT',
    CoinName: 'One Game',
    FullName: 'One Game (OGT)',
  },
  PLAYC: {
    Name: 'PLAYC',
    Symbol: 'PLAYC',
    CoinName: 'PlayChip',
    FullName: 'PlayChip (PLAYC)',
  },
  NPER: {
    Name: 'NPER',
    Symbol: 'NPER',
    CoinName: 'NPER',
    FullName: 'NPER (NPER)',
  },
  ATON: {
    Name: 'ATON',
    Symbol: 'ATON',
    CoinName: 'Further Network',
    FullName: 'Further Network (ATON)',
  },
  XCG: {
    Name: 'XCG',
    Symbol: 'XCG',
    CoinName: 'Xchange',
    FullName: 'Xchange (XCG)',
  },
  BOONS: {
    Name: 'BOONS',
    Symbol: 'BOONS',
    CoinName: 'BOONSCoin',
    FullName: 'BOONSCoin (BOONS)',
  },
  PCH: {
    Name: 'PCH',
    Symbol: 'PCH',
    CoinName: 'POPCHAIN',
    FullName: 'POPCHAIN (PCH)',
  },
  WITCOIN: {
    Name: 'WITCOIN',
    Symbol: 'WITCOIN',
    CoinName: 'Witcoin',
    FullName: 'Witcoin (WITCOIN)',
  },
  OPU: {
    Name: 'OPU',
    Symbol: 'OPU',
    CoinName: 'Opu Coin',
    FullName: 'Opu Coin (OPU)',
  },
  ETALON: {
    Name: 'ETALON',
    Symbol: 'ETALON',
    CoinName: 'Etalon',
    FullName: 'Etalon (ETALON)',
  },
  ZPR: {
    Name: 'ZPR',
    Symbol: 'ZPR',
    CoinName: 'ZPER',
    FullName: 'ZPER (ZPR)',
  },
  EXLT: {
    Name: 'EXLT',
    Symbol: 'EXLT',
    CoinName: 'ExtraLovers',
    FullName: 'ExtraLovers (EXLT)',
  },
  ESTATE: {
    Name: 'ESTATE',
    Symbol: 'ESTATE',
    CoinName: 'AgentMile',
    FullName: 'AgentMile (ESTATE)',
  },
  BLV: {
    Name: 'BLV',
    Symbol: 'BLV',
    CoinName: 'Blockvest',
    FullName: 'Blockvest (BLV)',
  },
  RRC: {
    Name: 'RRC',
    Symbol: 'RRC',
    CoinName: 'Recycling Regeneration Chain',
    FullName: 'Recycling Regeneration Chain (RRC)',
  },
  MPG: {
    Name: 'MPG',
    Symbol: 'MPG',
    CoinName: 'Max Property Group',
    FullName: 'Max Property Group (MPG)',
  },
  QNTU: {
    Name: 'QNTU',
    Symbol: 'QNTU',
    CoinName: 'Quanta',
    FullName: 'Quanta (QNTU)',
  },
  IG: {
    Name: 'IG1',
    Symbol: 'IG',
    CoinName: 'IG Token ',
    FullName: 'IG Token  (IG1)',
  },
  FML: {
    Name: 'FML',
    Symbol: 'FML',
    CoinName: 'FormulA',
    FullName: 'FormulA (FML)',
  },
  PSM: {
    Name: 'PSM',
    Symbol: 'PSM',
    CoinName: 'Prasm',
    FullName: 'Prasm (PSM)',
  },
  AUDC: {
    Name: 'AUDC',
    Symbol: 'AUDC',
    CoinName: 'Aussie Digital',
    FullName: 'Aussie Digital (AUDC)',
  },
  NMH: {
    Name: 'NMH',
    Symbol: 'NMH',
    CoinName: 'Namahe',
    FullName: 'Namahe (NMH)',
  },
  KST: {
    Name: 'KST',
    Symbol: 'KST',
    CoinName: 'StarKST',
    FullName: 'StarKST (KST)',
  },
  PBLK: {
    Name: 'PBLK',
    Symbol: 'PBLK',
    CoinName: 'PayBlock',
    FullName: 'PayBlock (PBLK)',
  },
  MADANA: {
    Name: 'MADANA',
    Symbol: 'MADANA',
    CoinName: 'MADANA',
    FullName: 'MADANA (MADANA)',
  },
  PAPUSHA: {
    Name: 'PAPUSHA',
    Symbol: 'PAPUSHA',
    CoinName: 'Papusha',
    FullName: 'Papusha (PAPUSHA)',
  },
  COSM: {
    Name: 'COSM',
    Symbol: 'COSM',
    CoinName: 'CosmoChain',
    FullName: 'CosmoChain (COSM)',
  },
  GPPT: {
    Name: 'GPPT',
    Symbol: 'GPPT',
    CoinName: 'Pluto Project Coin',
    FullName: 'Pluto Project Coin (GPPT)',
  },
  LNL: {
    Name: 'LNL',
    Symbol: 'LNL',
    CoinName: 'LunarLink',
    FullName: 'LunarLink (LNL)',
  },
  BRNX: {
    Name: 'BRNX',
    Symbol: 'BRNX',
    CoinName: 'Bronix',
    FullName: 'Bronix (BRNX)',
  },
  SRCOIN: {
    Name: 'SRCOIN',
    Symbol: 'SRCOIN',
    CoinName: 'SRCoin',
    FullName: 'SRCoin (SRCOIN)',
  },
  ET: {
    Name: 'ET',
    Symbol: 'ET',
    CoinName: 'ENDO',
    FullName: 'ENDO (ET)',
  },
  MMTM: {
    Name: 'MMTM',
    Symbol: 'MMTM',
    CoinName: 'Momentum',
    FullName: 'Momentum (MMTM)',
  },
  DICEM: {
    Name: 'DICEM',
    Symbol: 'DICEM',
    CoinName: 'DICE Money',
    FullName: 'DICE Money (DICEM)',
  },
  PASS: {
    Name: 'PASS',
    Symbol: 'PASS',
    CoinName: 'Blockpass',
    FullName: 'Blockpass (PASS)',
  },
  HRO: {
    Name: 'HRO',
    Symbol: 'HRO',
    CoinName: 'HEROIC.com',
    FullName: 'HEROIC.com (HRO)',
  },
  BSCH: {
    Name: 'BSCH',
    Symbol: 'BSCH',
    CoinName: 'BitSchool',
    FullName: 'BitSchool (BSCH)',
  },
  PESA: {
    Name: 'PESA',
    Symbol: 'PESA',
    CoinName: 'Credible',
    FullName: 'Credible (PESA)',
  },
  CLPX: {
    Name: 'CLPX',
    Symbol: 'CLPX',
    CoinName: 'Chynge.net',
    FullName: 'Chynge.net (CLPX)',
  },
  DAOX: {
    Name: 'DAOX',
    Symbol: 'DAOX',
    CoinName: 'Daox',
    FullName: 'Daox (DAOX)',
  },
  GLN: {
    Name: 'GLN',
    Symbol: 'GLN',
    CoinName: 'Galion Token',
    FullName: 'Galion Token (GLN)',
  },
  AUK: {
    Name: 'AUK',
    Symbol: 'AUK',
    CoinName: 'Aukcecoin',
    FullName: 'Aukcecoin (AUK)',
  },
  PCCM: {
    Name: 'PCCM',
    Symbol: 'PCCM',
    CoinName: 'Poseidon Chain',
    FullName: 'Poseidon Chain (PCCM)',
  },
  PLAN: {
    Name: 'PLAN',
    Symbol: 'PLAN',
    CoinName: 'Plancoin',
    FullName: 'Plancoin (PLAN)',
  },
  EVERLIFE: {
    Name: 'EVERLIFE',
    Symbol: 'EVERLIFE',
    CoinName: 'EverLife.AI',
    FullName: 'EverLife.AI (EVERLIFE)',
  },
  TRAID: {
    Name: 'TRAID',
    Symbol: 'TRAID',
    CoinName: 'Traid',
    FullName: 'Traid (TRAID)',
  },
  BNTE: {
    Name: 'BNTE',
    Symbol: 'BNTE',
    CoinName: 'Bountie',
    FullName: 'Bountie (BNTE)',
  },
  MIB: {
    Name: 'MIB',
    Symbol: 'MIB',
    CoinName: 'Mobile Integrated Blockchain',
    FullName: 'Mobile Integrated Blockchain (MIB)',
  },
  BAAS: {
    Name: 'BAAS',
    Symbol: 'BAAS',
    CoinName: 'BaaSid',
    FullName: 'BaaSid (BAAS)',
  },
  LYNK: {
    Name: 'LYNK',
    Symbol: 'LYNK',
    CoinName: 'Lynked.World',
    FullName: 'Lynked.World (LYNK)',
  },
  GUSD: {
    Name: 'GUSD',
    Symbol: 'GUSD',
    CoinName: 'Gemini Dollar',
    FullName: 'Gemini Dollar (GUSD)',
  },
  CCL: {
    Name: 'CCL',
    Symbol: 'CCL',
    CoinName: 'CyClean',
    FullName: 'CyClean (CCL)',
  },
  HYC: {
    Name: 'HYC',
    Symbol: 'HYC',
    CoinName: 'HYCON',
    FullName: 'HYCON (HYC)',
  },
  TCX: {
    Name: 'TCX',
    Symbol: 'TCX',
    CoinName: 'T-Coin',
    FullName: 'T-Coin (TCX)',
  },
  HLD: {
    Name: 'HLD',
    Symbol: 'HLD',
    CoinName: 'HyperLending',
    FullName: 'HyperLending (HLD)',
  },
  NUSD: {
    Name: 'NUSD',
    Symbol: 'NUSD',
    CoinName: 'Nomin USD',
    FullName: 'Nomin USD (NUSD)',
  },
  TCHB: {
    Name: 'TCHB',
    Symbol: 'TCHB',
    CoinName: 'Teachers Blockchain',
    FullName: 'Teachers Blockchain (TCHB)',
  },
  DAX: {
    Name: 'DAX',
    Symbol: 'DAX',
    CoinName: 'DAEX',
    FullName: 'DAEX (DAX)',
  },
  BECH: {
    Name: 'BECH',
    Symbol: 'BECH',
    CoinName: 'Beauty Chain',
    FullName: 'Beauty Chain (BECH)',
  },
  VEEN: {
    Name: 'VEEN',
    Symbol: 'VEEN',
    CoinName: 'LIVEEN',
    FullName: 'LIVEEN (VEEN)',
  },
  CIC: {
    Name: 'CIC',
    Symbol: 'CIC',
    CoinName: 'CIChain',
    FullName: 'CIChain (CIC)',
  },
  MIODIO: {
    Name: 'MIODIO',
    Symbol: 'MIODIO',
    CoinName: 'MIODIOCOIN',
    FullName: 'MIODIOCOIN (MIODIO)',
  },
  MOV: {
    Name: 'MOV',
    Symbol: 'MOV',
    CoinName: 'MovieCoin',
    FullName: 'MovieCoin (MOV)',
  },
  CNAB: {
    Name: 'CNAB',
    Symbol: 'CNAB',
    CoinName: 'Cannabium',
    FullName: 'Cannabium (CNAB)',
  },
  SGP: {
    Name: 'SGP',
    Symbol: 'SGP',
    CoinName: 'SGPay',
    FullName: 'SGPay (SGP)',
  },
  LTPC: {
    Name: 'LTPC',
    Symbol: 'LTPC',
    CoinName: 'Lightpaycoin',
    FullName: 'Lightpaycoin (LTPC)',
  },
  HANA: {
    Name: 'HANA',
    Symbol: 'HANA',
    CoinName: 'Hanacoin',
    FullName: 'Hanacoin (HANA)',
  },
  BTV: {
    Name: 'BTV',
    Symbol: 'BTV',
    CoinName: 'Bitvote',
    FullName: 'Bitvote (BTV)',
  },
  SHE: {
    Name: 'SHE',
    Symbol: 'SHE',
    CoinName: 'Shine Chain',
    FullName: 'Shine Chain (SHE)',
  },
  IVN: {
    Name: 'IVN',
    Symbol: 'IVN',
    CoinName: 'IVN Security',
    FullName: 'IVN Security (IVN)',
  },
  DAV: {
    Name: 'DAV',
    Symbol: 'DAV',
    CoinName: 'DAV',
    FullName: 'DAV (DAV)',
  },
  ZAT: {
    Name: 'ZAT',
    Symbol: 'ZAT',
    CoinName: 'ZatGo',
    FullName: 'ZatGo (ZAT)',
  },
  ROBET: {
    Name: 'ROBET',
    Symbol: 'ROBET',
    CoinName: 'RoBet',
    FullName: 'RoBet (ROBET)',
  },
  CRYP: {
    Name: 'CRYP',
    Symbol: 'CRYP',
    CoinName: 'CrypticCoin',
    FullName: 'CrypticCoin (CRYP)',
  },
  BONIX: {
    Name: 'BONIX',
    Symbol: 'BONIX',
    CoinName: 'Blockonix',
    FullName: 'Blockonix (BONIX)',
  },
  BTXC: {
    Name: 'BTXC',
    Symbol: 'BTXC',
    CoinName: 'Bettex coin',
    FullName: 'Bettex coin (BTXC)',
  },
  DAPS: {
    Name: 'DAPS',
    Symbol: 'DAPS',
    CoinName: 'DAPS Coin',
    FullName: 'DAPS Coin (DAPS)',
  },
  ETE: {
    Name: 'ETE',
    Symbol: 'ETE',
    CoinName: 'EXTRADECOIN',
    FullName: 'EXTRADECOIN (ETE)',
  },
  NHCT: {
    Name: 'NHCT1',
    Symbol: 'NHCT',
    CoinName: 'Nano Healthcare Token',
    FullName: 'Nano Healthcare Token (NHCT1)',
  },
  AZ: {
    Name: 'AZ',
    Symbol: 'AZ',
    CoinName: 'Azbit',
    FullName: 'Azbit (AZ)',
  },
  SWA: {
    Name: 'SWA',
    Symbol: 'SWA',
    CoinName: 'Swace',
    FullName: 'Swace (SWA)',
  },
  IAG: {
    Name: 'IAG',
    Symbol: 'IAG',
    CoinName: 'IAGON',
    FullName: 'IAGON (IAG)',
  },
  STRS: {
    Name: 'STRS',
    Symbol: 'STRS',
    CoinName: 'STARS',
    FullName: 'STARS (STRS)',
  },
  MTCMN: {
    Name: 'MTCMN',
    Symbol: 'MTCMN',
    CoinName: 'MTC Mesh',
    FullName: 'MTC Mesh (MTCMN)',
  },
  ZEST: {
    Name: 'ZEST',
    Symbol: 'ZEST',
    CoinName: 'ZestCoin',
    FullName: 'ZestCoin (ZEST)',
  },
  HLM: {
    Name: 'HLM',
    Symbol: 'HLM',
    CoinName: 'Helium',
    FullName: 'Helium (HLM)',
  },
  CSP: {
    Name: 'CSP',
    Symbol: 'CSP',
    CoinName: 'Caspian',
    FullName: 'Caspian (CSP)',
  },
  ONGAS: {
    Name: 'ONGAS',
    Symbol: 'ONGAS',
    CoinName: 'Ontology Gas',
    FullName: 'Ontology Gas (ONGAS)',
  },
  NRVE: {
    Name: 'NRVE',
    Symbol: 'NRVE',
    CoinName: 'Narrative',
    FullName: 'Narrative (NRVE)',
  },
  BIP: {
    Name: 'BIP',
    Symbol: 'BIP',
    CoinName: 'Minter',
    FullName: 'Minter (BIP)',
  },
  XCASH: {
    Name: 'XCASH',
    Symbol: 'XCASH',
    CoinName: 'X-CASH',
    FullName: 'X-CASH (XCASH)',
  },
  RMESH: {
    Name: 'RMESH',
    Symbol: 'RMESH',
    CoinName: 'RightMesh',
    FullName: 'RightMesh (RMESH)',
  },
  HAND: {
    Name: 'HAND',
    Symbol: 'HAND',
    CoinName: 'ShowHand',
    FullName: 'ShowHand (HAND)',
  },
  GBXT: {
    Name: 'GBXT',
    Symbol: 'GBXT',
    CoinName: 'Globitex Token',
    FullName: 'Globitex Token (GBXT)',
  },
  ABCC: {
    Name: 'ABCC',
    Symbol: 'ABCC',
    CoinName: 'ABCC Token',
    FullName: 'ABCC Token (ABCC)',
  },
  BASIS: {
    Name: 'BASIS',
    Symbol: 'BASIS',
    CoinName: 'Basis',
    FullName: 'Basis (BASIS)',
  },
  JIB: {
    Name: 'JIB',
    Symbol: 'JIB',
    CoinName: 'Jibbit',
    FullName: 'Jibbit (JIB)',
  },
  PMTN: {
    Name: 'PMTN',
    Symbol: 'PMTN',
    CoinName: 'Peer Mountain',
    FullName: 'Peer Mountain (PMTN)',
  },
  SGR: {
    Name: 'SGR',
    Symbol: 'SGR',
    CoinName: 'Sogur Currency',
    FullName: 'Sogur Currency (SGR)',
  },
  PHM: {
    Name: 'PHM',
    Symbol: 'PHM',
    CoinName: 'Phomeum',
    FullName: 'Phomeum (PHM)',
  },
  CUSD: {
    Name: 'CUSD',
    Symbol: 'CUSD',
    CoinName: 'Carbon',
    FullName: 'Carbon (CUSD)',
  },
  KUSD: {
    Name: 'KUSD',
    Symbol: 'KUSD',
    CoinName: 'Kowala',
    FullName: 'Kowala (KUSD)',
  },
  GGR: {
    Name: 'GGR',
    Symbol: 'GGR',
    CoinName: 'GGRocket',
    FullName: 'GGRocket (GGR)',
  },
  MCN: {
    Name: 'MCN',
    Symbol: 'MCN',
    CoinName: 'mCoin',
    FullName: 'mCoin (MCN)',
  },
  MENLO: {
    Name: 'MENLO',
    Symbol: 'MENLO',
    CoinName: 'Menlo One',
    FullName: 'Menlo One (MENLO)',
  },
  METM: {
    Name: 'METM',
    Symbol: 'METM',
    CoinName: 'MetaMorph',
    FullName: 'MetaMorph (METM)',
  },
  USDP: {
    Name: 'USDP',
    Symbol: 'USDP',
    CoinName: 'Pax Dollar',
    FullName: 'Pax Dollar (USDP)',
  },
  ARAW: {
    Name: 'ARAW',
    Symbol: 'ARAW',
    CoinName: 'Araw',
    FullName: 'Araw (ARAW)',
  },
  BRAZ: {
    Name: 'BRAZ',
    Symbol: 'BRAZ',
    CoinName: 'Brazio',
    FullName: 'Brazio (BRAZ)',
  },
  TALAO: {
    Name: 'TALAO',
    Symbol: 'TALAO',
    CoinName: 'Talao',
    FullName: 'Talao (TALAO)',
  },
  IZX: {
    Name: 'IZX',
    Symbol: 'IZX',
    CoinName: 'IZX',
    FullName: 'IZX (IZX)',
  },
  HQT: {
    Name: 'HQT',
    Symbol: 'HQT',
    CoinName: 'HyperQuant',
    FullName: 'HyperQuant (HQT)',
  },
  W12: {
    Name: 'W12',
    Symbol: 'W12',
    CoinName: 'W12 Protocol',
    FullName: 'W12 Protocol (W12)',
  },
  NBAR: {
    Name: 'NBAR',
    Symbol: 'NBAR',
    CoinName: 'NOBAR',
    FullName: 'NOBAR (NBAR)',
  },
  KBX: {
    Name: 'KBX',
    Symbol: 'KBX',
    CoinName: 'KuBitX',
    FullName: 'KuBitX (KBX)',
  },
  MYDFS: {
    Name: 'MYDFS',
    Symbol: 'MYDFS',
    CoinName: 'MyDFS',
    FullName: 'MyDFS (MYDFS)',
  },
  BHPC: {
    Name: 'BHPC',
    Symbol: 'BHPC',
    CoinName: 'BHPCash',
    FullName: 'BHPCash (BHPC)',
  },
  VTOS: {
    Name: 'VTOS',
    Symbol: 'VTOS',
    CoinName: 'VTOS',
    FullName: 'VTOS (VTOS)',
  },
  M2O: {
    Name: 'M2O',
    Symbol: 'M2O',
    CoinName: 'M2O Token',
    FullName: 'M2O Token (M2O)',
  },
  UEC: {
    Name: 'UEC',
    Symbol: 'UEC',
    CoinName: 'United Emirates Coin',
    FullName: 'United Emirates Coin (UEC)',
  },
  BEAT: {
    Name: 'BEAT',
    Symbol: 'BEAT',
    CoinName: 'BEAT Token',
    FullName: 'BEAT Token (BEAT)',
  },
  MOLK: {
    Name: 'MOLK',
    Symbol: 'MOLK',
    CoinName: 'Mobilink Token',
    FullName: 'Mobilink Token (MOLK)',
  },
  MSD: {
    Name: 'MSD',
    Symbol: 'MSD',
    CoinName: 'MSD',
    FullName: 'MSD (MSD)',
  },
  SEED: {
    Name: 'SEED',
    Symbol: 'SEED',
    CoinName: 'Superbloom',
    FullName: 'Superbloom (SEED)',
  },
  SEALN: {
    Name: 'SEALN',
    Symbol: 'SEALN',
    CoinName: 'Seal Network',
    FullName: 'Seal Network (SEALN)',
  },
  GBO: {
    Name: 'GBO',
    Symbol: 'GBO',
    CoinName: 'Gabro.io',
    FullName: 'Gabro.io (GBO)',
  },
  DFXT: {
    Name: 'DFXT',
    Symbol: 'DFXT',
    CoinName: 'DigiFinexToken',
    FullName: 'DigiFinexToken (DFXT)',
  },
  NWP: {
    Name: 'NWP',
    Symbol: 'NWP',
    CoinName: 'NWPSolution',
    FullName: 'NWPSolution (NWP)',
  },
  BCDT: {
    Name: 'BCDT',
    Symbol: 'BCDT',
    CoinName: 'EvidenZ',
    FullName: 'EvidenZ (BCDT)',
  },
  EVOS: {
    Name: 'EVOS',
    Symbol: 'EVOS',
    CoinName: 'EVOS',
    FullName: 'EVOS (EVOS)',
  },
  DEEX: {
    Name: 'DEEX',
    Symbol: 'DEEX',
    CoinName: 'DEEX',
    FullName: 'DEEX (DEEX)',
  },
  ANON: {
    Name: 'ANON',
    Symbol: 'ANON',
    CoinName: 'ANON',
    FullName: 'ANON (ANON)',
  },
  LTZ: {
    Name: 'LTZ',
    Symbol: 'LTZ',
    CoinName: 'Litecoinz',
    FullName: 'Litecoinz (LTZ)',
  },
  MTZ: {
    Name: 'MTZ',
    Symbol: 'MTZ',
    CoinName: 'Monetizr',
    FullName: 'Monetizr (MTZ)',
  },
  TBL: {
    Name: 'TBL',
    Symbol: 'TBL',
    CoinName: 'Tombola',
    FullName: 'Tombola (TBL)',
  },
  BXY: {
    Name: 'BXY',
    Symbol: 'BXY',
    CoinName: 'Beaxy',
    FullName: 'Beaxy (BXY)',
  },
  KUE: {
    Name: 'KUE',
    Symbol: 'KUE',
    CoinName: 'Kuende',
    FullName: 'Kuende (KUE)',
  },
  ESN: {
    Name: 'ESN',
    Symbol: 'ESN',
    CoinName: 'Ethersocial',
    FullName: 'Ethersocial (ESN)',
  },
  H3O: {
    Name: 'H3O',
    Symbol: 'H3O',
    CoinName: 'Hydrominer',
    FullName: 'Hydrominer (H3O)',
  },
  BETHER: {
    Name: 'BETHER',
    Symbol: 'BETHER',
    CoinName: 'Bethereum',
    FullName: 'Bethereum (BETHER)',
  },
  ETHO: {
    Name: 'ETHO',
    Symbol: 'ETHO',
    CoinName: 'The Etho Protocol',
    FullName: 'The Etho Protocol (ETHO)',
  },
  WTL: {
    Name: 'WTL',
    Symbol: 'WTL',
    CoinName: 'Welltrado',
    FullName: 'Welltrado (WTL)',
  },
  HIH: {
    Name: 'HIH',
    Symbol: 'HIH',
    CoinName: 'HiHealth',
    FullName: 'HiHealth (HIH)',
  },
  ANGEL: {
    Name: 'ANGEL',
    Symbol: 'ANGEL',
    CoinName: 'Crypto Angel',
    FullName: 'Crypto Angel (ANGEL)',
  },
  CXA: {
    Name: 'CXA',
    Symbol: 'CXA',
    CoinName: 'CryptovationX',
    FullName: 'CryptovationX (CXA)',
  },
  BITTO: {
    Name: 'BITTO',
    Symbol: 'BITTO',
    CoinName: 'BITTO',
    FullName: 'BITTO (BITTO)',
  },
  UMK: {
    Name: 'UMK',
    Symbol: 'UMK',
    CoinName: 'UMKA',
    FullName: 'UMKA (UMK)',
  },
  VNX: {
    Name: 'VNX',
    Symbol: 'VNX',
    CoinName: 'VisionX',
    FullName: 'VisionX (VNX)',
  },
  MBTC: {
    Name: 'MBTC',
    Symbol: 'MBTC',
    CoinName: 'MicroBitcoin',
    FullName: 'MicroBitcoin (MBTC)',
  },
  OJX: {
    Name: 'OJX',
    Symbol: 'OJX',
    CoinName: 'Ojooo',
    FullName: 'Ojooo (OJX)',
  },
  CHW: {
    Name: 'CHW',
    Symbol: 'CHW',
    CoinName: 'Chrysalis Coin',
    FullName: 'Chrysalis Coin (CHW)',
  },
  CATT: {
    Name: 'CATT',
    Symbol: 'CATT',
    CoinName: 'Catex',
    FullName: 'Catex (CATT)',
  },
  VEX: {
    Name: 'VEX',
    Symbol: 'VEX',
    CoinName: 'Vexanium',
    FullName: 'Vexanium (VEX)',
  },
  LQDN: {
    Name: 'LQDN',
    Symbol: 'LQDN',
    CoinName: 'Liquidity Network',
    FullName: 'Liquidity Network (LQDN)',
  },
  BIOC: {
    Name: 'BIOC',
    Symbol: 'BIOC',
    CoinName: 'BioCrypt',
    FullName: 'BioCrypt (BIOC)',
  },
  FOREXCOIN: {
    Name: 'FOREXCOIN',
    Symbol: 'FOREXCOIN',
    CoinName: 'FOREXCOIN',
    FullName: 'FOREXCOIN (FOREXCOIN)',
  },
  CPLO: {
    Name: 'CPLO',
    Symbol: 'CPLO',
    CoinName: 'Cpollo',
    FullName: 'Cpollo (CPLO)',
  },
  RIPAX: {
    Name: 'RIPAX',
    Symbol: 'RIPAX',
    CoinName: 'RipaEx',
    FullName: 'RipaEx (RIPAX)',
  },
  HETA: {
    Name: 'HETA',
    Symbol: 'HETA',
    CoinName: 'HetaChain',
    FullName: 'HetaChain (HETA)',
  },
  ADAB: {
    Name: 'ADAB',
    Symbol: 'ADAB',
    CoinName: 'Adab Solutions',
    FullName: 'Adab Solutions (ADAB)',
  },
  CIX100: {
    Name: 'CIX100',
    Symbol: 'CIX100',
    CoinName: 'Cryptoindex',
    FullName: 'Cryptoindex (CIX100)',
  },
  FIH: {
    Name: 'FIH',
    Symbol: 'FIH',
    CoinName: 'Fidelity House',
    FullName: 'Fidelity House (FIH)',
  },
  MINX: {
    Name: 'MINX',
    Symbol: 'MINX',
    CoinName: 'InnovaMinex',
    FullName: 'InnovaMinex (MINX)',
  },
  MOBU: {
    Name: 'MOBU',
    Symbol: 'MOBU',
    CoinName: 'MOBU',
    FullName: 'MOBU (MOBU)',
  },
  NVDX: {
    Name: 'NVDX',
    Symbol: 'NVDX',
    CoinName: 'Nodvix',
    FullName: 'Nodvix (NVDX)',
  },
  COVEX: {
    Name: 'COVEX',
    Symbol: 'COVEX',
    CoinName: 'CoVEX',
    FullName: 'CoVEX (COVEX)',
  },
  F2K: {
    Name: 'F2K',
    Symbol: 'F2K',
    CoinName: 'Farm2Kitchen',
    FullName: 'Farm2Kitchen (F2K)',
  },
  GTX: {
    Name: 'GTX',
    Symbol: 'GTX',
    CoinName: 'GALLACTIC',
    FullName: 'GALLACTIC (GTX)',
  },
  B21: {
    Name: 'B21',
    Symbol: 'B21',
    CoinName: 'B21',
    FullName: 'B21 (B21)',
  },
  LK: {
    Name: 'LK',
    Symbol: 'LK',
    CoinName: 'Liker',
    FullName: 'Liker (LK)',
  },
  QOBI: {
    Name: 'QOBI',
    Symbol: 'QOBI',
    CoinName: 'Qobit',
    FullName: 'Qobit (QOBI)',
  },
  BVO: {
    Name: 'BVO',
    Symbol: 'BVO',
    CoinName: 'BRAVO Pay',
    FullName: 'BRAVO Pay (BVO)',
  },
  VENA: {
    Name: 'VENA',
    Symbol: 'VENA',
    CoinName: 'Vena Network',
    FullName: 'Vena Network (VENA)',
  },
  CDRX: {
    Name: 'CDRX',
    Symbol: 'CDRX',
    CoinName: 'CDRX',
    FullName: 'CDRX (CDRX)',
  },
  ELES: {
    Name: 'ELES',
    Symbol: 'ELES',
    CoinName: 'Elements Estates',
    FullName: 'Elements Estates (ELES)',
  },
  GEON: {
    Name: 'GEON',
    Symbol: 'GEON',
    CoinName: 'Geon',
    FullName: 'Geon (GEON)',
  },
  INVX: {
    Name: 'INVX',
    Symbol: 'INVX',
    CoinName: 'Investx',
    FullName: 'Investx (INVX)',
  },
  BETF: {
    Name: 'BETF',
    Symbol: 'BETF',
    CoinName: 'Betform',
    FullName: 'Betform (BETF)',
  },
  IMPCN: {
    Name: 'IMPCN',
    Symbol: 'IMPCN',
    CoinName: 'Brain Space',
    FullName: 'Brain Space (IMPCN)',
  },
  XPT: {
    Name: 'XPT',
    Symbol: 'XPT',
    CoinName: 'Cryptobuyer',
    FullName: 'Cryptobuyer (XPT)',
  },
  FORK: {
    Name: 'FORK',
    Symbol: 'FORK',
    CoinName: 'Gastro Advisor Token',
    FullName: 'Gastro Advisor Token (FORK)',
  },
  NMK: {
    Name: 'NMK',
    Symbol: 'NMK',
    CoinName: 'Namek',
    FullName: 'Namek (NMK)',
  },
  OUT: {
    Name: 'OUT',
    Symbol: 'OUT',
    CoinName: 'Netscouters',
    FullName: 'Netscouters (OUT)',
  },
  LPT: {
    Name: 'LPT',
    Symbol: 'LPT',
    CoinName: 'Livepeer',
    FullName: 'Livepeer (LPT)',
  },
  RAINC: {
    Name: 'RAINC',
    Symbol: 'RAINC',
    CoinName: 'RainCheck',
    FullName: 'RainCheck (RAINC)',
  },
  IOVT: {
    Name: 'IOVT',
    Symbol: 'IOVT',
    CoinName: 'IOV',
    FullName: 'IOV (IOVT)',
  },
  MYO: {
    Name: 'MYO',
    Symbol: 'MYO',
    CoinName: 'Mycro',
    FullName: 'Mycro (MYO)',
  },
  ORET: {
    Name: 'ORET',
    Symbol: 'ORET',
    CoinName: 'ORET Token',
    FullName: 'ORET Token (ORET)',
  },
  SEC: {
    Name: 'SEC',
    Symbol: 'SEC',
    CoinName: 'SecureCryptoPayments',
    FullName: 'SecureCryptoPayments (SEC)',
  },
  QUIZ: {
    Name: 'QUIZ',
    Symbol: 'QUIZ',
    CoinName: 'Quizando',
    FullName: 'Quizando (QUIZ)',
  },
  CYRS: {
    Name: 'CYRS',
    Symbol: 'CYRS',
    CoinName: 'Cyrus Token',
    FullName: 'Cyrus Token (CYRS)',
  },
  JOYS: {
    Name: 'JOYS',
    Symbol: 'JOYS',
    CoinName: 'JOYS',
    FullName: 'JOYS (JOYS)',
  },
  DACH: {
    Name: 'DACH',
    Symbol: 'DACH',
    CoinName: 'DACH Coin',
    FullName: 'DACH Coin (DACH)',
  },
  MNVM: {
    Name: 'MNVM',
    Symbol: 'MNVM',
    CoinName: 'Novam',
    FullName: 'Novam (MNVM)',
  },
  PLTX: {
    Name: 'PLTX',
    Symbol: 'PLTX',
    CoinName: 'PlutusX',
    FullName: 'PlutusX (PLTX)',
  },
  BTMG: {
    Name: 'BTMG',
    Symbol: 'BTMG',
    CoinName: 'Bitcademy Football',
    FullName: 'Bitcademy Football (BTMG)',
  },
  BRIK: {
    Name: 'BRIK',
    Symbol: 'BRIK',
    CoinName: 'BrikBit',
    FullName: 'BrikBit (BRIK)',
  },
  LUMA: {
    Name: 'LUMA',
    Symbol: 'LUMA',
    CoinName: 'LUMA Token',
    FullName: 'LUMA Token (LUMA)',
  },
  BTZN: {
    Name: 'BTZN',
    Symbol: 'BTZN',
    CoinName: 'Bitzon',
    FullName: 'Bitzon (BTZN)',
  },
  CLRTY: {
    Name: 'CLRTY',
    Symbol: 'CLRTY',
    CoinName: 'Clarity',
    FullName: 'Clarity (CLRTY)',
  },
  NAVIB: {
    Name: 'NAVIB',
    Symbol: 'NAVIB',
    CoinName: 'Navibration',
    FullName: 'Navibration (NAVIB)',
  },
  ARTP: {
    Name: 'ARTP',
    Symbol: 'ARTP',
    CoinName: 'ArtPro',
    FullName: 'ArtPro (ARTP)',
  },
  PLEO: {
    Name: 'PLEO',
    Symbol: 'PLEO',
    CoinName: 'Empleos',
    FullName: 'Empleos (PLEO)',
  },
  EGDC: {
    Name: 'EGDC',
    Symbol: 'EGDC',
    CoinName: 'EasyGuide',
    FullName: 'EasyGuide (EGDC)',
  },
  ENTT: {
    Name: 'ENTT',
    Symbol: 'ENTT',
    CoinName: 'Presale Ventures',
    FullName: 'Presale Ventures (ENTT)',
  },
  AURUM: {
    Name: 'AURUM',
    Symbol: 'AURUM',
    CoinName: 'Aurum',
    FullName: 'Aurum (AURUM)',
  },
  CRWD: {
    Name: 'CRWD',
    Symbol: 'CRWD',
    CoinName: 'CRWD Network',
    FullName: 'CRWD Network (CRWD)',
  },
  ENCN: {
    Name: 'ENCN',
    Symbol: 'ENCN',
    CoinName: 'EndChain',
    FullName: 'EndChain (ENCN)',
  },
  EYE: {
    Name: 'EYE',
    Symbol: 'EYE',
    CoinName: 'EYE Token',
    FullName: 'EYE Token (EYE)',
  },
  GTR: {
    Name: 'GTR',
    Symbol: 'GTR',
    CoinName: 'Gturbo',
    FullName: 'Gturbo (GTR)',
  },
  HXC: {
    Name: 'HXC',
    Symbol: 'HXC',
    CoinName: 'HexanCoin',
    FullName: 'HexanCoin (HXC)',
  },
  OPEX: {
    Name: 'OPEX',
    Symbol: 'OPEX',
    CoinName: 'Optherium Token',
    FullName: 'Optherium Token (OPEX)',
  },
  SKYM: {
    Name: 'SKYM',
    Symbol: 'SKYM',
    CoinName: 'SkyMap',
    FullName: 'SkyMap (SKYM)',
  },
  SCIA: {
    Name: 'SCIA',
    Symbol: 'SCIA',
    CoinName: 'Stem Cell',
    FullName: 'Stem Cell (SCIA)',
  },
  GPS: {
    Name: 'GPS',
    Symbol: 'GPS',
    CoinName: 'Triffic',
    FullName: 'Triffic (GPS)',
  },
  BBG: {
    Name: 'BBG',
    Symbol: 'BBG',
    CoinName: 'BigBang',
    FullName: 'BigBang (BBG)',
  },
  NZE: {
    Name: 'NZE',
    Symbol: 'NZE',
    CoinName: 'Nagezeni',
    FullName: 'Nagezeni (NZE)',
  },
  FIELD: {
    Name: 'FIELD',
    Symbol: 'FIELD',
    CoinName: 'Fieldcoin',
    FullName: 'Fieldcoin (FIELD)',
  },
  TWC: {
    Name: 'TWC',
    Symbol: 'TWC',
    CoinName: 'Twilight',
    FullName: 'Twilight (TWC)',
  },
  CTW: {
    Name: 'CTW',
    Symbol: 'CTW',
    CoinName: 'Citowise',
    FullName: 'Citowise (CTW)',
  },
  NAM: {
    Name: 'NAM',
    Symbol: 'NAM',
    CoinName: 'Namacoin',
    FullName: 'Namacoin (NAM)',
  },
  '2TF': {
    Name: '2TF',
    Symbol: '2TF',
    CoinName: '2TF',
    FullName: '2TF (2TF)',
  },
  BZKY: {
    Name: 'BZKY',
    Symbol: 'BZKY',
    CoinName: 'Bizkey',
    FullName: 'Bizkey (BZKY)',
  },
  CARAT: {
    Name: 'CARAT',
    Symbol: 'CARAT',
    CoinName: 'Carats Token',
    FullName: 'Carats Token (CARAT)',
  },
  MAEP: {
    Name: 'MAEP',
    Symbol: 'MAEP',
    CoinName: 'Maester Protocol',
    FullName: 'Maester Protocol (MAEP)',
  },
  DN8: {
    Name: 'DN8',
    Symbol: 'DN8',
    CoinName: 'Pldgr',
    FullName: 'Pldgr (DN8)',
  },
  XNT: {
    Name: 'XNT',
    Symbol: 'XNT',
    CoinName: 'Exenium',
    FullName: 'Exenium (XNT)',
  },
  PPOVR: {
    Name: 'PPOVR',
    Symbol: 'PPOVR',
    CoinName: 'POVR',
    FullName: 'POVR (PPOVR)',
  },
  LX: {
    Name: 'LX',
    Symbol: 'LX',
    CoinName: 'Moonlight',
    FullName: 'Moonlight (LX)',
  },
  AWAX: {
    Name: 'AWAX',
    Symbol: 'AWAX',
    CoinName: 'AWAX',
    FullName: 'AWAX (AWAX)',
  },
  BBOS: {
    Name: 'BBOS',
    Symbol: 'BBOS',
    CoinName: 'Blackbox Foundation',
    FullName: 'Blackbox Foundation (BBOS)',
  },
  BFEX: {
    Name: 'BFEX',
    Symbol: 'BFEX',
    CoinName: 'BFEX',
    FullName: 'BFEX (BFEX)',
  },
  HUS: {
    Name: 'HUS',
    Symbol: 'HUS',
    CoinName: 'HUSSY',
    FullName: 'HUSSY (HUS)',
  },
  APXT: {
    Name: 'APXT',
    Symbol: 'APXT',
    CoinName: 'ApolloX',
    FullName: 'ApolloX (APXT)',
  },
  IDORU: {
    Name: 'IDORU',
    Symbol: 'IDORU',
    CoinName: 'Vip2Fan',
    FullName: 'Vip2Fan (IDORU)',
  },
  BONA: {
    Name: 'BONA',
    Symbol: 'BONA',
    CoinName: 'Bonafi',
    FullName: 'Bonafi (BONA)',
  },
  HLDY: {
    Name: 'HLDY',
    Symbol: 'HLDY',
    CoinName: 'HOLIDAY',
    FullName: 'HOLIDAY (HLDY)',
  },
  CONT: {
    Name: 'CONT',
    Symbol: 'CONT',
    CoinName: 'Contentos',
    FullName: 'Contentos (CONT)',
  },
  EOSBLACK: {
    Name: 'EOSBLACK',
    Symbol: 'EOSBLACK',
    CoinName: 'eosBLACK',
    FullName: 'eosBLACK (EOSBLACK)',
  },
  HORUS: {
    Name: 'HORUS',
    Symbol: 'HORUS',
    CoinName: 'HorusPay',
    FullName: 'HorusPay (HORUS)',
  },
  MEETONE: {
    Name: 'MEETONE',
    Symbol: 'MEETONE',
    CoinName: 'MEET.ONE',
    FullName: 'MEET.ONE (MEETONE)',
  },
  IOTW: {
    Name: 'IOTW',
    Symbol: 'IOTW',
    CoinName: 'IOTW',
    FullName: 'IOTW (IOTW)',
  },
  EMPR: {
    Name: 'EMPR',
    Symbol: 'EMPR',
    CoinName: 'empowr',
    FullName: 'empowr (EMPR)',
  },
  MPAY: {
    Name: 'MPAY',
    Symbol: 'MPAY',
    CoinName: 'Menapay',
    FullName: 'Menapay (MPAY)',
  },
  AGM: {
    Name: 'AGM',
    Symbol: 'AGM',
    CoinName: 'Argoneum',
    FullName: 'Argoneum (AGM)',
  },
  MTCN: {
    Name: 'MTCN',
    Symbol: 'MTCN',
    CoinName: 'Multiven',
    FullName: 'Multiven (MTCN)',
  },
  PTO: {
    Name: 'PTO',
    Symbol: 'PTO',
    CoinName: 'Patentico',
    FullName: 'Patentico (PTO)',
  },
  AS: {
    Name: 'AS',
    Symbol: 'AS',
    CoinName: 'AmaStar',
    FullName: 'AmaStar (AS)',
  },
  OSF: {
    Name: 'OSF',
    Symbol: 'OSF',
    CoinName: 'One Solution',
    FullName: 'One Solution (OSF)',
  },
  DLPT: {
    Name: 'DLPT',
    Symbol: 'DLPT',
    CoinName: 'Deliverers Power Token',
    FullName: 'Deliverers Power Token (DLPT)',
  },
  GREENT: {
    Name: 'GREENT',
    Symbol: 'GREENT',
    CoinName: 'Greentoken',
    FullName: 'Greentoken (GREENT)',
  },
  OPCT: {
    Name: 'OPCT',
    Symbol: 'OPCT',
    CoinName: 'Opacity',
    FullName: 'Opacity (OPCT)',
  },
  RPB: {
    Name: 'RPB',
    Symbol: 'RPB',
    CoinName: 'Republia',
    FullName: 'Republia (RPB)',
  },
  DYNCOIN: {
    Name: 'DYNCOIN',
    Symbol: 'DYNCOIN',
    CoinName: 'Dyncoin',
    FullName: 'Dyncoin (DYNCOIN)',
  },
  PSF: {
    Name: 'PSF',
    Symbol: 'PSF',
    CoinName: 'Prime Shipping Foundation',
    FullName: 'Prime Shipping Foundation (PSF)',
  },
  LITION: {
    Name: 'LITION',
    Symbol: 'LITION',
    CoinName: 'Lition',
    FullName: 'Lition (LITION)',
  },
  MZG: {
    Name: 'MZG',
    Symbol: 'MZG',
    CoinName: 'Moozicore',
    FullName: 'Moozicore (MZG)',
  },
  BTZC: {
    Name: 'BTZC',
    Symbol: 'BTZC',
    CoinName: 'BeatzCoin',
    FullName: 'BeatzCoin (BTZC)',
  },
  ECR: {
    Name: 'ECR',
    Symbol: 'ECR',
    CoinName: 'EcoVerse',
    FullName: 'EcoVerse (ECR)',
  },
  RF: {
    Name: 'RF',
    Symbol: 'RF',
    CoinName: 'Raido Financial',
    FullName: 'Raido Financial (RF)',
  },
  ARMS: {
    Name: 'ARMS',
    Symbol: 'ARMS',
    CoinName: '2Acoin',
    FullName: '2Acoin (ARMS)',
  },
  MPXT: {
    Name: 'MPXT',
    Symbol: 'MPXT',
    CoinName: 'Myplacex',
    FullName: 'Myplacex (MPXT)',
  },
  XELS: {
    Name: 'XELS',
    Symbol: 'XELS',
    CoinName: 'XELS Coin',
    FullName: 'XELS Coin (XELS)',
  },
  PGF7T: {
    Name: 'PGF7T',
    Symbol: 'PGF7T',
    CoinName: 'PGF500',
    FullName: 'PGF500 (PGF7T)',
  },
  IDAC: {
    Name: 'IDAC',
    Symbol: 'IDAC',
    CoinName: 'IDAC',
    FullName: 'IDAC (IDAC)',
  },
  FTUM: {
    Name: 'FTUM',
    Symbol: 'FTUM',
    CoinName: 'Fatum',
    FullName: 'Fatum (FTUM)',
  },
  DLXV: {
    Name: 'DLXV',
    Symbol: 'DLXV',
    CoinName: 'Delta-X',
    FullName: 'Delta-X (DLXV)',
  },
  OCEANT: {
    Name: 'OCEANT',
    Symbol: 'OCEANT',
    CoinName: 'Poseidon Foundation',
    FullName: 'Poseidon Foundation (OCEANT)',
  },
  GOALS: {
    Name: 'GOALS',
    Symbol: 'GOALS',
    CoinName: 'UnitedFans',
    FullName: 'UnitedFans (GOALS)',
  },
  ETHIX: {
    Name: 'ETHIX',
    Symbol: 'ETHIX',
    CoinName: 'EthicHub',
    FullName: 'EthicHub (ETHIX)',
  },
  CHK: {
    Name: 'CHK',
    Symbol: 'CHK',
    CoinName: 'Chek',
    FullName: 'Chek (CHK)',
  },
  VLTX: {
    Name: 'VLTX',
    Symbol: 'VLTX',
    CoinName: 'Volentix',
    FullName: 'Volentix (VLTX)',
  },
  PRPT: {
    Name: 'PRPT',
    Symbol: 'PRPT',
    CoinName: 'Purple Token',
    FullName: 'Purple Token (PRPT)',
  },
  TREE: {
    Name: 'TREE',
    Symbol: 'TREE',
    CoinName: 'HyperionX',
    FullName: 'HyperionX (TREE)',
  },
  GDL: {
    Name: 'GDL',
    Symbol: 'GDL',
    CoinName: 'GodlyCoin',
    FullName: 'GodlyCoin (GDL)',
  },
  LITENETT: {
    Name: 'LITENETT',
    Symbol: 'LITENETT',
    CoinName: 'Litenett',
    FullName: 'Litenett (LITENETT)',
  },
  FTRC: {
    Name: 'FTRC',
    Symbol: 'FTRC',
    CoinName: 'FutureCoin',
    FullName: 'FutureCoin (FTRC)',
  },
  HBX: {
    Name: 'HBX',
    Symbol: 'HBX',
    CoinName: 'Hyperbridge',
    FullName: 'Hyperbridge (HBX)',
  },
  LAO: {
    Name: 'LAO',
    Symbol: 'LAO',
    CoinName: 'LC Token',
    FullName: 'LC Token (LAO)',
  },
  GOVT: {
    Name: 'GOVT',
    Symbol: 'GOVT',
    CoinName: 'The Government Network',
    FullName: 'The Government Network (GOVT)',
  },
  COGEN: {
    Name: 'COGEN',
    Symbol: 'COGEN',
    CoinName: 'Cogenero',
    FullName: 'Cogenero (COGEN)',
  },
  DAILY: {
    Name: 'DAILY',
    Symbol: 'DAILY',
    CoinName: 'Coindaily',
    FullName: 'Coindaily (DAILY)',
  },
  SREUR: {
    Name: 'SREUR',
    Symbol: 'SREUR',
    CoinName: 'SocialRemit',
    FullName: 'SocialRemit (SREUR)',
  },
  MAZC: {
    Name: 'MAZC',
    Symbol: 'MAZC',
    CoinName: 'MyMazzu',
    FullName: 'MyMazzu (MAZC)',
  },
  PLNX: {
    Name: 'PLNX',
    Symbol: 'PLNX',
    CoinName: 'Planumex',
    FullName: 'Planumex (PLNX)',
  },
  IPT: {
    Name: 'IPT',
    Symbol: 'IPT',
    CoinName: 'Crypt-ON',
    FullName: 'Crypt-ON (IPT)',
  },
  IGTT: {
    Name: 'IGTT',
    Symbol: 'IGTT',
    CoinName: 'IGT',
    FullName: 'IGT (IGTT)',
  },
  GZB: {
    Name: 'GZB',
    Symbol: 'GZB',
    CoinName: 'Gigzi',
    FullName: 'Gigzi (GZB)',
  },
  GGP: {
    Name: 'GGP',
    Symbol: 'GGP',
    CoinName: 'GGPro',
    FullName: 'GGPro (GGP)',
  },
  IFUM: {
    Name: 'IFUM',
    Symbol: 'IFUM',
    CoinName: 'Infleum',
    FullName: 'Infleum (IFUM)',
  },
  ATC: {
    Name: 'ATC',
    Symbol: 'ATC',
    CoinName: 'AutoBlock',
    FullName: 'AutoBlock (ATC)',
  },
  DOOH: {
    Name: 'DOOH',
    Symbol: 'DOOH',
    CoinName: 'Bidooh',
    FullName: 'Bidooh (DOOH)',
  },
  IOUX: {
    Name: 'IOUX',
    Symbol: 'IOUX',
    CoinName: 'IOU',
    FullName: 'IOU (IOUX)',
  },
  BQTX: {
    Name: 'BQTX',
    Symbol: 'BQTX',
    CoinName: 'BQT',
    FullName: 'BQT (BQTX)',
  },
  NVOY: {
    Name: 'NVOY',
    Symbol: 'NVOY',
    CoinName: 'Envoy',
    FullName: 'Envoy (NVOY)',
  },
  CYBR: {
    Name: 'CYBR',
    Symbol: 'CYBR',
    CoinName: 'CYBR',
    FullName: 'CYBR (CYBR)',
  },
  LLG: {
    Name: 'LLG',
    Symbol: 'LLG',
    CoinName: 'Loligo',
    FullName: 'Loligo (LLG)',
  },
  LCR: {
    Name: 'LCR',
    Symbol: 'LCR',
    CoinName: 'Lucre',
    FullName: 'Lucre (LCR)',
  },
  SNPC: {
    Name: 'SNPC',
    Symbol: 'SNPC',
    CoinName: 'SnapCoin',
    FullName: 'SnapCoin (SNPC)',
  },
  VTM: {
    Name: 'VTM',
    Symbol: 'VTM',
    CoinName: 'Victorieum',
    FullName: 'Victorieum (VTM)',
  },
  NRX: {
    Name: 'NRX',
    Symbol: 'NRX',
    CoinName: 'Neironix',
    FullName: 'Neironix (NRX)',
  },
  BCNA: {
    Name: 'BCNA',
    Symbol: 'BCNA',
    CoinName: 'BitCanna',
    FullName: 'BitCanna (BCNA)',
  },
  BTSG: {
    Name: 'BTSG',
    Symbol: 'BTSG',
    CoinName: 'BitSong',
    FullName: 'BitSong (BTSG)',
  },
  CINX: {
    Name: 'CINX',
    Symbol: 'CINX',
    CoinName: 'CINDX',
    FullName: 'CINDX (CINX)',
  },
  CCIN: {
    Name: 'CCIN',
    Symbol: 'CCIN',
    CoinName: 'Cryptocoin Insurance',
    FullName: 'Cryptocoin Insurance (CCIN)',
  },
  CCI: {
    Name: 'CCI',
    Symbol: 'CCI',
    CoinName: 'Cyber Capital Invest',
    FullName: 'Cyber Capital Invest (CCI)',
  },
  RDS: {
    Name: 'RDS',
    Symbol: 'RDS',
    CoinName: 'Reger Diamond',
    FullName: 'Reger Diamond (RDS)',
  },
  GMS: {
    Name: 'GMS',
    Symbol: 'GMS',
    CoinName: 'Gemstra',
    FullName: 'Gemstra (GMS)',
  },
  SILKT: {
    Name: 'SILKT',
    Symbol: 'SILKT',
    CoinName: 'SilkChain',
    FullName: 'SilkChain (SILKT)',
  },
  FIII: {
    Name: 'FIII',
    Symbol: 'FIII',
    CoinName: 'Fiii',
    FullName: 'Fiii (FIII)',
  },
  ICHN: {
    Name: 'ICHN',
    Symbol: 'ICHN',
    CoinName: 'i-chain',
    FullName: 'i-chain (ICHN)',
  },
  LVX: {
    Name: 'LVX',
    Symbol: 'LVX',
    CoinName: 'Level01',
    FullName: 'Level01 (LVX)',
  },
  AENT: {
    Name: 'AENT',
    Symbol: 'AENT',
    CoinName: 'AEN',
    FullName: 'AEN (AENT)',
  },
  LYFE: {
    Name: 'LYFE',
    Symbol: 'LYFE',
    CoinName: 'Lyfe',
    FullName: 'Lyfe (LYFE)',
  },
  REMCO: {
    Name: 'REMCO',
    Symbol: 'REMCO',
    CoinName: 'Remco',
    FullName: 'Remco (REMCO)',
  },
  SATT: {
    Name: 'SATT',
    Symbol: 'SATT',
    CoinName: 'SaTT',
    FullName: 'SaTT (SATT)',
  },
  GEMA: {
    Name: 'GEMA',
    Symbol: 'GEMA',
    CoinName: 'Gemera',
    FullName: 'Gemera (GEMA)',
  },
  SYLO: {
    Name: 'SYLO',
    Symbol: 'SYLO',
    CoinName: 'Sylo',
    FullName: 'Sylo (SYLO)',
  },
  AER: {
    Name: 'AER',
    Symbol: 'AER',
    CoinName: 'Aeryus',
    FullName: 'Aeryus (AER)',
  },
  AIBB: {
    Name: 'AIBB',
    Symbol: 'AIBB',
    CoinName: 'AiBB',
    FullName: 'AiBB (AIBB)',
  },
  ASQT: {
    Name: 'ASQT',
    Symbol: 'ASQT',
    CoinName: 'ASQ Protocol',
    FullName: 'ASQ Protocol (ASQT)',
  },
  BLKD: {
    Name: 'BLKD',
    Symbol: 'BLKD',
    CoinName: 'Blinked',
    FullName: 'Blinked (BLKD)',
  },
  CYS: {
    Name: 'CYS',
    Symbol: 'CYS',
    CoinName: 'BlooCYS',
    FullName: 'BlooCYS (CYS)',
  },
  CTY: {
    Name: 'CTY',
    Symbol: 'CTY',
    CoinName: 'Connecty',
    FullName: 'Connecty (CTY)',
  },
  DDL: {
    Name: 'DDL',
    Symbol: 'DDL',
    CoinName: 'Donocle',
    FullName: 'Donocle (DDL)',
  },
  COY: {
    Name: 'COY',
    Symbol: 'COY',
    CoinName: 'Coin Analyst',
    FullName: 'Coin Analyst (COY)',
  },
  FNL: {
    Name: 'FNL',
    Symbol: 'FNL',
    CoinName: 'Finlocale',
    FullName: 'Finlocale (FNL)',
  },
  B2G: {
    Name: 'B2G',
    Symbol: 'B2G',
    CoinName: 'Bitcoiin2Gen',
    FullName: 'Bitcoiin2Gen (B2G)',
  },
  CSQ: {
    Name: 'CSQ',
    Symbol: 'CSQ',
    CoinName: 'cosquare',
    FullName: 'cosquare (CSQ)',
  },
  HBE: {
    Name: 'HBE',
    Symbol: 'HBE',
    CoinName: 'healthbank',
    FullName: 'healthbank (HBE)',
  },
  ICT: {
    Name: 'ICT',
    Symbol: 'ICT',
    CoinName: 'Intrachain',
    FullName: 'Intrachain (ICT)',
  },
  KRATOS: {
    Name: 'KRATOS',
    Symbol: 'KRATOS',
    CoinName: 'KRATOS',
    FullName: 'KRATOS (KRATOS)',
  },
  CPROP: {
    Name: 'CPROP',
    Symbol: 'CPROP',
    CoinName: 'CPROP',
    FullName: 'CPROP (CPROP)',
  },
  MOOLYA: {
    Name: 'MOOLYA',
    Symbol: 'MOOLYA',
    CoinName: 'moolyacoin',
    FullName: 'moolyacoin (MOOLYA)',
  },
  PON: {
    Name: 'PON',
    Symbol: 'PON',
    CoinName: 'Ponder',
    FullName: 'Ponder (PON)',
  },
  CREV: {
    Name: 'CREV',
    Symbol: 'CREV',
    CoinName: 'CryptoRevolution',
    FullName: 'CryptoRevolution (CREV)',
  },
  IDC: {
    Name: 'IDC',
    Symbol: 'IDC',
    CoinName: 'IdealCoin',
    FullName: 'IdealCoin (IDC)',
  },
  LBR: {
    Name: 'LBR',
    Symbol: 'LBR',
    CoinName: 'LaborCrypto',
    FullName: 'LaborCrypto (LBR)',
  },
  EMX: {
    Name: 'EMX',
    Symbol: 'EMX',
    CoinName: 'EMX',
    FullName: 'EMX (EMX)',
  },
  XBASE: {
    Name: 'XBASE',
    Symbol: 'XBASE',
    CoinName: 'ETERBASE',
    FullName: 'ETERBASE (XBASE)',
  },
  LEN: {
    Name: 'LEN',
    Symbol: 'LEN',
    CoinName: 'Liqnet',
    FullName: 'Liqnet (LEN)',
  },
  KUBO: {
    Name: 'KUBO',
    Symbol: 'KUBO',
    CoinName: 'KUBO',
    FullName: 'KUBO (KUBO)',
  },
  FABA: {
    Name: 'FABA',
    Symbol: 'FABA',
    CoinName: 'Faba Invest',
    FullName: 'Faba Invest (FABA)',
  },
  LQ8: {
    Name: 'LQ8',
    Symbol: 'LQ8',
    CoinName: 'Liquid8',
    FullName: 'Liquid8 (LQ8)',
  },
  GC: {
    Name: 'GC',
    Symbol: 'GC',
    CoinName: 'Gric Coin',
    FullName: 'Gric Coin (GC)',
  },
  INFLR: {
    Name: 'INFLR',
    Symbol: 'INFLR',
    CoinName: 'Inflr',
    FullName: 'Inflr (INFLR)',
  },
  LIB: {
    Name: 'LIB',
    Symbol: 'LIB',
    CoinName: 'Libellum',
    FullName: 'Libellum (LIB)',
  },
  PERMIAN: {
    Name: 'PERMIAN',
    Symbol: 'PERMIAN',
    CoinName: 'Permian',
    FullName: 'Permian (PERMIAN)',
  },
  PETL: {
    Name: 'PETL',
    Symbol: 'PETL',
    CoinName: 'Petlife',
    FullName: 'Petlife (PETL)',
  },
  PPS: {
    Name: 'PPS',
    Symbol: 'PPS',
    CoinName: 'PopulStay',
    FullName: 'PopulStay (PPS)',
  },
  BCVB: {
    Name: 'BCVB',
    Symbol: 'BCVB',
    CoinName: 'BCV Blue Chip',
    FullName: 'BCV Blue Chip (BCVB)',
  },
  AUX: {
    Name: 'Auxilium',
    Symbol: 'AUX',
    CoinName: 'Auxilium',
    FullName: 'Auxilium (Auxilium)',
  },
  LYQD: {
    Name: 'LYQD',
    Symbol: 'LYQD',
    CoinName: 'eLYQD',
    FullName: 'eLYQD (LYQD)',
  },
  CBP: {
    Name: 'CBP',
    Symbol: 'CBP',
    CoinName: 'ComBox',
    FullName: 'ComBox (CBP)',
  },
  SMOKE: {
    Name: 'SMOKE',
    Symbol: 'SMOKE',
    CoinName: 'Smoke',
    FullName: 'Smoke (SMOKE)',
  },
  EDN: {
    Name: 'EDN',
    Symbol: 'EDN',
    CoinName: 'EdenChain',
    FullName: 'EdenChain (EDN)',
  },
  NOSN: {
    Name: 'NOSN',
    Symbol: 'NOSN',
    CoinName: 'nOS',
    FullName: 'nOS (NOSN)',
  },
  DT1: {
    Name: 'DT1',
    Symbol: 'DT1',
    CoinName: 'Dollar Token 1',
    FullName: 'Dollar Token 1 (DT1)',
  },
  FARMA: {
    Name: 'FARMA',
    Symbol: 'FARMA',
    CoinName: 'FarmaTrust',
    FullName: 'FarmaTrust (FARMA)',
  },
  STACS: {
    Name: 'STACS',
    Symbol: 'STACS',
    CoinName: 'STACS Token',
    FullName: 'STACS Token (STACS)',
  },
  JMC: {
    Name: 'JMC',
    Symbol: 'JMC',
    CoinName: 'Junson Ming Chan Coin',
    FullName: 'Junson Ming Chan Coin (JMC)',
  },
  FRED: {
    Name: 'FRED',
    Symbol: 'FRED',
    CoinName: 'FREDEnergy',
    FullName: 'FREDEnergy (FRED)',
  },
  CNCT: {
    Name: 'CNCT',
    Symbol: 'CNCT',
    CoinName: 'CONNECT',
    FullName: 'CONNECT (CNCT)',
  },
  ENGT: {
    Name: 'ENGT',
    Symbol: 'ENGT',
    CoinName: 'Engagement Token',
    FullName: 'Engagement Token (ENGT)',
  },
  VRTY: {
    Name: 'VRTY',
    Symbol: 'VRTY',
    CoinName: 'Verity',
    FullName: 'Verity (VRTY)',
  },
  ZND: {
    Name: 'ZND',
    Symbol: 'ZND',
    CoinName: 'Zenad',
    FullName: 'Zenad (ZND)',
  },
  '3XD': {
    Name: '3XD',
    Symbol: '3XD',
    CoinName: '3DChain',
    FullName: '3DChain (3XD)',
  },
  FPC: {
    Name: 'FPC',
    Symbol: 'FPC',
    CoinName: 'Futurepia',
    FullName: 'Futurepia (FPC)',
  },
  SYNCO: {
    Name: 'SYNCO',
    Symbol: 'SYNCO',
    CoinName: 'Synco',
    FullName: 'Synco (SYNCO)',
  },
  '77G': {
    Name: '77G',
    Symbol: '77G',
    CoinName: 'GraphenTech',
    FullName: 'GraphenTech (77G)',
  },
  HIDU: {
    Name: 'HIDU',
    Symbol: 'HIDU',
    CoinName: 'H-Education World',
    FullName: 'H-Education World (HIDU)',
  },
  USE: {
    Name: 'USE',
    Symbol: 'USE',
    CoinName: 'Usechain Token',
    FullName: 'Usechain Token (USE)',
  },
  NGIN: {
    Name: 'NGIN',
    Symbol: 'NGIN',
    CoinName: 'Ngin',
    FullName: 'Ngin (NGIN)',
  },
  KOTO: {
    Name: 'KOTO',
    Symbol: 'KOTO',
    CoinName: 'Koto',
    FullName: 'Koto (KOTO)',
  },
  GENXNET: {
    Name: 'GENXNET',
    Symbol: 'GENXNET',
    CoinName: 'Genesis Network',
    FullName: 'Genesis Network (GENXNET)',
  },
  XSC: {
    Name: 'XSC',
    Symbol: 'XSC',
    CoinName: 'Hyperspace',
    FullName: 'Hyperspace (XSC)',
  },
  VTL: {
    Name: 'VTL',
    Symbol: 'VTL',
    CoinName: 'Vertical',
    FullName: 'Vertical (VTL)',
  },
  SPRTZ: {
    Name: 'SPRTZ',
    Symbol: 'SPRTZ',
    CoinName: 'SpritzCoin',
    FullName: 'SpritzCoin (SPRTZ)',
  },
  C25: {
    Name: 'C25',
    Symbol: 'C25',
    CoinName: 'C25 Coin',
    FullName: 'C25 Coin (C25)',
  },
  LYN: {
    Name: 'LYN',
    Symbol: 'LYN',
    CoinName: 'LYNCHPIN Token',
    FullName: 'LYNCHPIN Token (LYN)',
  },
  STASH: {
    Name: 'STASH',
    Symbol: 'STASH',
    CoinName: 'BitStash',
    FullName: 'BitStash (STASH)',
  },
  HERB: {
    Name: 'HERB',
    Symbol: 'HERB',
    CoinName: 'HerbCoin',
    FullName: 'HerbCoin (HERB)',
  },
  AQUAC: {
    Name: 'AQUAC',
    Symbol: 'AQUAC',
    CoinName: 'Aquachain',
    FullName: 'Aquachain (AQUAC)',
  },
  XQR: {
    Name: 'XQR',
    Symbol: 'XQR',
    CoinName: 'Qredit',
    FullName: 'Qredit (XQR)',
  },
  URX: {
    Name: 'URX',
    Symbol: 'URX',
    CoinName: 'URANIUMX',
    FullName: 'URANIUMX (URX)',
  },
  KSYS: {
    Name: 'KSYS',
    Symbol: 'KSYS',
    CoinName: 'K-Systems',
    FullName: 'K-Systems (KSYS)',
  },
  MTEL: {
    Name: 'MTEL',
    Symbol: 'MTEL',
    CoinName: 'MEDoctor',
    FullName: 'MEDoctor (MTEL)',
  },
  MTT: {
    Name: 'MTT',
    Symbol: 'MTT',
    CoinName: 'MulTra',
    FullName: 'MulTra (MTT)',
  },
  MITC: {
    Name: 'MITC',
    Symbol: 'MITC',
    CoinName: 'MusicLife',
    FullName: 'MusicLife (MITC)',
  },
  BBTC: {
    Name: 'BBTC',
    Symbol: 'BBTC',
    CoinName: 'BlakeBitcoin',
    FullName: 'BlakeBitcoin (BBTC)',
  },
  UMO: {
    Name: 'UMO',
    Symbol: 'UMO',
    CoinName: 'Universal Molecule',
    FullName: 'Universal Molecule (UMO)',
  },
  LITHIUM: {
    Name: 'LITHIUM',
    Symbol: 'LITHIUM',
    CoinName: 'Lithium',
    FullName: 'Lithium (LITHIUM)',
  },
  MUST: {
    Name: 'MUST',
    Symbol: 'MUST',
    CoinName: 'MUST Protocol',
    FullName: 'MUST Protocol (MUST)',
  },
  XNB: {
    Name: 'XNB',
    Symbol: 'XNB',
    CoinName: 'Xeonbit',
    FullName: 'Xeonbit (XNB)',
  },
  RBTC: {
    Name: 'RBTC',
    Symbol: 'RBTC',
    CoinName: 'Smart Bitcoin',
    FullName: 'Smart Bitcoin (RBTC)',
  },
  BTCEX: {
    Name: 'BTCEX',
    Symbol: 'BTCEX',
    CoinName: 'BtcEX',
    FullName: 'BtcEX (BTCEX)',
  },
  PIRATE: {
    Name: 'PIRATE',
    Symbol: 'PIRATE',
    CoinName: 'PirateCash',
    FullName: 'PirateCash (PIRATE)',
  },
  EXO: {
    Name: 'EXO',
    Symbol: 'EXO',
    CoinName: 'Exosis',
    FullName: 'Exosis (EXO)',
  },
  ONAM: {
    Name: 'ONAM',
    Symbol: 'ONAM',
    CoinName: 'ONAM',
    FullName: 'ONAM (ONAM)',
  },
  BIH: {
    Name: 'BIH',
    Symbol: 'BIH',
    CoinName: 'BitHostCoin',
    FullName: 'BitHostCoin (BIH)',
  },
  KARMA: {
    Name: 'KARMA',
    Symbol: 'KARMA',
    CoinName: 'Karma',
    FullName: 'Karma (KARMA)',
  },
  CJR: {
    Name: 'CJR',
    Symbol: 'CJR',
    CoinName: 'Conjure',
    FullName: 'Conjure (CJR)',
  },
  BLTG: {
    Name: 'BLTG',
    Symbol: 'BLTG',
    CoinName: 'Block-Logic',
    FullName: 'Block-Logic (BLTG)',
  },
  ASGC: {
    Name: 'ASGC',
    Symbol: 'ASGC',
    CoinName: 'ASG',
    FullName: 'ASG (ASGC)',
  },
  MIMI: {
    Name: 'MIMI',
    Symbol: 'MIMI',
    CoinName: 'MIMI Money',
    FullName: 'MIMI Money (MIMI)',
  },
  PXG: {
    Name: 'PXG',
    Symbol: 'PXG',
    CoinName: 'PlayGame',
    FullName: 'PlayGame (PXG)',
  },
  ORM: {
    Name: 'ORM',
    Symbol: 'ORM',
    CoinName: 'ORIUM',
    FullName: 'ORIUM (ORM)',
  },
  TRET: {
    Name: 'TRET',
    Symbol: 'TRET',
    CoinName: 'Tourist Review',
    FullName: 'Tourist Review (TRET)',
  },
  BEER: {
    Name: 'BEER',
    Symbol: 'BEER',
    CoinName: 'BEER Coin',
    FullName: 'BEER Coin (BEER)',
  },
  TIMI: {
    Name: 'TIMI',
    Symbol: 'TIMI',
    CoinName: 'Timicoin',
    FullName: 'Timicoin (TIMI)',
  },
  NRP: {
    Name: 'NRP',
    Symbol: 'NRP',
    CoinName: 'Neural Protocol',
    FullName: 'Neural Protocol (NRP)',
  },
  SNTVT: {
    Name: 'SNTVT',
    Symbol: 'SNTVT',
    CoinName: 'Sentivate',
    FullName: 'Sentivate (SNTVT)',
  },
  CEN: {
    Name: 'CEN',
    Symbol: 'CEN',
    CoinName: 'Coinsuper Ecosystem Network',
    FullName: 'Coinsuper Ecosystem Network (CEN)',
  },
  GARD: {
    Name: 'GARD',
    Symbol: 'GARD',
    CoinName: 'Hashgard',
    FullName: 'Hashgard (GARD)',
  },
  OWC: {
    Name: 'OWC',
    Symbol: 'OWC',
    CoinName: 'Oduwa',
    FullName: 'Oduwa (OWC)',
  },
  THO: {
    Name: 'THO',
    Symbol: 'THO',
    CoinName: 'Athero',
    FullName: 'Athero (THO)',
  },
  KMX: {
    Name: 'KMX',
    Symbol: 'KMX',
    CoinName: 'KiMex',
    FullName: 'KiMex (KMX)',
  },
  SKI: {
    Name: 'SKI',
    Symbol: 'SKI',
    CoinName: 'Skillchain',
    FullName: 'Skillchain (SKI)',
  },
  SUNEX: {
    Name: 'SUNEX',
    Symbol: 'SUNEX',
    CoinName: 'The Sun Exchange',
    FullName: 'The Sun Exchange (SUNEX)',
  },
  ALUX: {
    Name: 'ALUX',
    Symbol: 'ALUX',
    CoinName: 'Alux Bank',
    FullName: 'Alux Bank (ALUX)',
  },
  XBOND: {
    Name: 'XBOND',
    Symbol: 'XBOND',
    CoinName: 'Bitacium',
    FullName: 'Bitacium (XBOND)',
  },
  BOSE: {
    Name: 'BOSE',
    Symbol: 'BOSE',
    CoinName: 'Bitbose',
    FullName: 'Bitbose (BOSE)',
  },
  JOY: {
    Name: 'JOY',
    Symbol: 'JOY',
    CoinName: 'Joycoin',
    FullName: 'Joycoin (JOY)',
  },
  GBE: {
    Name: 'GBE',
    Symbol: 'GBE',
    CoinName: 'Godbex',
    FullName: 'Godbex (GBE)',
  },
  HRBE: {
    Name: 'HRBE',
    Symbol: 'HRBE',
    CoinName: 'Harambee Token',
    FullName: 'Harambee Token (HRBE)',
  },
  MILC: {
    Name: 'MILC',
    Symbol: 'MILC',
    CoinName: 'MIcro Licensing Coin',
    FullName: 'MIcro Licensing Coin (MILC)',
  },
  PINMO: {
    Name: 'PINMO',
    Symbol: 'PINMO',
    CoinName: 'Pinmo',
    FullName: 'Pinmo (PINMO)',
  },
  POPC: {
    Name: 'POPC',
    Symbol: 'POPC',
    CoinName: 'PopChest',
    FullName: 'PopChest (POPC)',
  },
  RETAIL: {
    Name: 'RETAIL',
    Symbol: 'RETAIL',
    CoinName: 'Retail.Global',
    FullName: 'Retail.Global (RETAIL)',
  },
  BMG: {
    Name: 'BMG',
    Symbol: 'BMG',
    CoinName: 'Borneo',
    FullName: 'Borneo (BMG)',
  },
  OXY2: {
    Name: 'OXY2',
    Symbol: 'OXY2',
    CoinName: 'Cryptoxygen',
    FullName: 'Cryptoxygen (OXY2)',
  },
  FAIRC: {
    Name: 'FAIRC',
    Symbol: 'FAIRC',
    CoinName: 'Faireum Token',
    FullName: 'Faireum Token (FAIRC)',
  },
  BPN: {
    Name: 'BPN',
    Symbol: 'BPN',
    CoinName: 'beepnow',
    FullName: 'beepnow (BPN)',
  },
  DYC: {
    Name: 'DYC',
    Symbol: 'DYC',
    CoinName: 'Dycoin',
    FullName: 'Dycoin (DYC)',
  },
  LN: {
    Name: 'LN',
    Symbol: 'LN',
    CoinName: 'LINK',
    FullName: 'LINK (LN)',
  },
  FTR: {
    Name: 'FTR',
    Symbol: 'FTR',
    CoinName: 'FactR',
    FullName: 'FactR (FTR)',
  },
  RWE: {
    Name: 'RWE',
    Symbol: 'RWE',
    CoinName: 'Real-World Evidence',
    FullName: 'Real-World Evidence (RWE)',
  },
  TASH: {
    Name: 'TASH',
    Symbol: 'TASH',
    CoinName: 'Smart Trip Platform',
    FullName: 'Smart Trip Platform (TASH)',
  },
  AAT: {
    Name: 'AAT',
    Symbol: 'AAT',
    CoinName: 'Agricultural Trade Chain',
    FullName: 'Agricultural Trade Chain (AAT)',
  },
  AUPC: {
    Name: 'AUPC',
    Symbol: 'AUPC',
    CoinName: 'Authpaper',
    FullName: 'Authpaper (AUPC)',
  },
  COSX: {
    Name: 'COSX',
    Symbol: 'COSX',
    CoinName: 'Cosmecoin',
    FullName: 'Cosmecoin (COSX)',
  },
  DNTX: {
    Name: 'DNTX',
    Symbol: 'DNTX',
    CoinName: 'DNAtix',
    FullName: 'DNAtix (DNTX)',
  },
  HART: {
    Name: 'HART',
    Symbol: 'HART',
    CoinName: 'HARA',
    FullName: 'HARA (HART)',
  },
  KSS: {
    Name: 'KSS',
    Symbol: 'KSS',
    CoinName: 'Krosscoin',
    FullName: 'Krosscoin (KSS)',
  },
  LIPS: {
    Name: 'LIPS',
    Symbol: 'LIPS',
    CoinName: 'LipChain',
    FullName: 'LipChain (LIPS)',
  },
  MIBO: {
    Name: 'MIBO',
    Symbol: 'MIBO',
    CoinName: 'miBoodle',
    FullName: 'miBoodle (MIBO)',
  },
  BRIX: {
    Name: 'BRIX',
    Symbol: 'BRIX',
    CoinName: 'OpenBrix',
    FullName: 'OpenBrix (BRIX)',
  },
  NZO: {
    Name: 'NZO',
    Symbol: 'NZO',
    CoinName: 'NonZero',
    FullName: 'NonZero (NZO)',
  },
  PTT: {
    Name: 'PTT',
    Symbol: 'PTT',
    CoinName: 'Pink Taxi Token',
    FullName: 'Pink Taxi Token (PTT)',
  },
  RMOB: {
    Name: 'RMOB',
    Symbol: 'RMOB',
    CoinName: 'RewardMob',
    FullName: 'RewardMob (RMOB)',
  },
  PODIUM: {
    Name: 'PODIUM',
    Symbol: 'PODIUM',
    CoinName: 'Smart League',
    FullName: 'Smart League (PODIUM)',
  },
  WHO: {
    Name: 'WHO',
    Symbol: 'WHO',
    CoinName: 'Truwho',
    FullName: 'Truwho (WHO)',
  },
  WDX: {
    Name: 'WDX',
    Symbol: 'WDX',
    CoinName: 'WeiDex',
    FullName: 'WeiDex (WDX)',
  },
  AIOT: {
    Name: 'AIOT',
    Symbol: 'AIOT',
    CoinName: 'AIOT Token',
    FullName: 'AIOT Token (AIOT)',
  },
  AMOS: {
    Name: 'AMOS',
    Symbol: 'AMOS',
    CoinName: 'Amos',
    FullName: 'Amos (AMOS)',
  },
  ESW: {
    Name: 'ESW',
    Symbol: 'ESW',
    CoinName: 'eSwitch®',
    FullName: 'eSwitch® (ESW)',
  },
  OX: {
    Name: 'OX',
    Symbol: 'OX',
    CoinName: 'betbox',
    FullName: 'betbox (OX)',
  },
  KRO: {
    Name: 'KRO',
    Symbol: 'KRO',
    CoinName: 'Betoken',
    FullName: 'Betoken (KRO)',
  },
  CAID: {
    Name: 'CAID',
    Symbol: 'CAID',
    CoinName: 'ClearAid',
    FullName: 'ClearAid (CAID)',
  },
  GUAR: {
    Name: 'GUAR',
    Symbol: 'GUAR',
    CoinName: 'Guarium',
    FullName: 'Guarium (GUAR)',
  },
  LTE: {
    Name: 'LTE',
    Symbol: 'LTE',
    CoinName: 'Local Token Exchange',
    FullName: 'Local Token Exchange (LTE)',
  },
  NEXXO: {
    Name: 'NEXXO',
    Symbol: 'NEXXO',
    CoinName: 'Nexxo',
    FullName: 'Nexxo (NEXXO)',
  },
  QNTR: {
    Name: 'QNTR',
    Symbol: 'QNTR',
    CoinName: 'Quantor',
    FullName: 'Quantor (QNTR)',
  },
  BTCUS: {
    Name: 'BTCUS',
    Symbol: 'BTCUS',
    CoinName: 'Bitcoinus',
    FullName: 'Bitcoinus (BTCUS)',
  },
  RAYS: {
    Name: 'RAYS',
    Symbol: 'RAYS',
    CoinName: 'Rays Network',
    FullName: 'Rays Network (RAYS)',
  },
  MOL: {
    Name: 'MOL',
    Symbol: 'MOL',
    CoinName: 'Molecule',
    FullName: 'Molecule (MOL)',
  },
  REME: {
    Name: 'REME',
    Symbol: 'REME',
    CoinName: 'REME-Coin',
    FullName: 'REME-Coin (REME)',
  },
  RENC: {
    Name: 'RENC',
    Symbol: 'RENC',
    CoinName: 'RENC',
    FullName: 'RENC (RENC)',
  },
  EMOT: {
    Name: 'EMOT',
    Symbol: 'EMOT',
    CoinName: 'Sentigraph.io',
    FullName: 'Sentigraph.io (EMOT)',
  },
  USAT: {
    Name: 'USAT',
    Symbol: 'USAT',
    CoinName: 'USAT',
    FullName: 'USAT (USAT)',
  },
  AIRT: {
    Name: 'AIRT',
    Symbol: 'AIRT',
    CoinName: 'Aircraft',
    FullName: 'Aircraft (AIRT)',
  },
  GALI: {
    Name: 'GALI',
    Symbol: 'GALI',
    CoinName: 'Galilel',
    FullName: 'Galilel (GALI)',
  },
  PLAI: {
    Name: 'PLAI',
    Symbol: 'PLAI',
    CoinName: 'Plair',
    FullName: 'Plair (PLAI)',
  },
  ERE: {
    Name: 'ERE',
    Symbol: 'ERE',
    CoinName: 'Erecoin',
    FullName: 'Erecoin (ERE)',
  },
  BTU: {
    Name: 'BTU',
    Symbol: 'BTU',
    CoinName: 'BTU Protocol',
    FullName: 'BTU Protocol (BTU)',
  },
  APS: {
    Name: 'APS',
    Symbol: 'APS',
    CoinName: 'APRES',
    FullName: 'APRES (APS)',
  },
  XBX: {
    Name: 'XBX',
    Symbol: 'XBX',
    CoinName: 'BiteX',
    FullName: 'BiteX (XBX)',
  },
  FFUEL: {
    Name: 'FFUEL',
    Symbol: 'FFUEL',
    CoinName: 'getFIFO',
    FullName: 'getFIFO (FFUEL)',
  },
  NSP: {
    Name: 'NSP',
    Symbol: 'NSP',
    CoinName: 'NOMAD.space',
    FullName: 'NOMAD.space (NSP)',
  },
  BWT2: {
    Name: 'BWT2',
    Symbol: 'BWT2',
    CoinName: 'Bitwin 2.0',
    FullName: 'Bitwin 2.0 (BWT2)',
  },
  OATH: {
    Name: 'OATH',
    Symbol: 'OATH',
    CoinName: 'OATH Protocol',
    FullName: 'OATH Protocol (OATH)',
  },
  SBA: {
    Name: 'SBA',
    Symbol: 'SBA',
    CoinName: 'simplyBrand',
    FullName: 'simplyBrand (SBA)',
  },
  DXG: {
    Name: 'DXG',
    Symbol: 'DXG',
    CoinName: 'DexAge',
    FullName: 'DexAge (DXG)',
  },
  EXTP: {
    Name: 'EXTP',
    Symbol: 'EXTP',
    CoinName: 'TradePlace',
    FullName: 'TradePlace (EXTP)',
  },
  CBUK: {
    Name: 'CBUK',
    Symbol: 'CBUK',
    CoinName: 'CurveBlock',
    FullName: 'CurveBlock (CBUK)',
  },
  HIX: {
    Name: 'HIX',
    Symbol: 'HIX',
    CoinName: 'HELIX Orange',
    FullName: 'HELIX Orange (HIX)',
  },
  COGS: {
    Name: 'COGS',
    Symbol: 'COGS',
    CoinName: 'Cogmento',
    FullName: 'Cogmento (COGS)',
  },
  CCOIN: {
    Name: 'CCOIN',
    Symbol: 'CCOIN',
    CoinName: 'Creditcoin',
    FullName: 'Creditcoin (CCOIN)',
  },
  APZ: {
    Name: 'APZ',
    Symbol: 'APZ',
    CoinName: 'Alprockz',
    FullName: 'Alprockz (APZ)',
  },
  ICHX: {
    Name: 'ICHX',
    Symbol: 'ICHX',
    CoinName: 'IceChain',
    FullName: 'IceChain (ICHX)',
  },
  IMP: {
    Name: 'IMP',
    Symbol: 'IMP',
    CoinName: 'CoinIMP',
    FullName: 'CoinIMP (IMP)',
  },
  FORCE: {
    Name: 'FORCE',
    Symbol: 'FORCE',
    CoinName: 'TriForce Tokens',
    FullName: 'TriForce Tokens (FORCE)',
  },
  QUSD: {
    Name: 'QUSD',
    Symbol: 'QUSD',
    CoinName: 'QUSD',
    FullName: 'QUSD (QUSD)',
  },
  BTH: {
    Name: 'BTH',
    Symbol: 'BTH',
    CoinName: 'Bithereum',
    FullName: 'Bithereum (BTH)',
  },
  PLG: {
    Name: 'PLG',
    Symbol: 'PLG',
    CoinName: 'Pledgecamp',
    FullName: 'Pledgecamp (PLG)',
  },
  PVP: {
    Name: 'PVP',
    Symbol: 'PVP',
    CoinName: 'PVPChain',
    FullName: 'PVPChain (PVP)',
  },
  EMANATE: {
    Name: 'EMANATE',
    Symbol: 'EMANATE',
    CoinName: 'EMANATE',
    FullName: 'EMANATE (EMANATE)',
  },
  RAIZER: {
    Name: 'RAIZER',
    Symbol: 'RAIZER',
    CoinName: 'RAIZER',
    FullName: 'RAIZER (RAIZER)',
  },
  CRYPTOPRO: {
    Name: 'CRYPTOPRO',
    Symbol: 'CRYPTOPRO',
    CoinName: 'CryptoProfile',
    FullName: 'CryptoProfile (CRYPTOPRO)',
  },
  DAYTA: {
    Name: 'DAYTA',
    Symbol: 'DAYTA',
    CoinName: 'Dayta',
    FullName: 'Dayta (DAYTA)',
  },
  ORV: {
    Name: 'ORV',
    Symbol: 'ORV',
    CoinName: 'Orvium',
    FullName: 'Orvium (ORV)',
  },
  AQU: {
    Name: 'AQU',
    Symbol: 'AQU',
    CoinName: 'aQuest',
    FullName: 'aQuest (AQU)',
  },
  CXG: {
    Name: 'CXG',
    Symbol: 'CXG',
    CoinName: 'Coinxes',
    FullName: 'Coinxes (CXG)',
  },
  CHFT: {
    Name: 'CHFT',
    Symbol: 'CHFT',
    CoinName: 'Crypto Holding',
    FullName: 'Crypto Holding (CHFT)',
  },
  BITHER: {
    Name: 'BITHER',
    Symbol: 'BITHER',
    CoinName: 'Bither',
    FullName: 'Bither (BITHER)',
  },
  KLK: {
    Name: 'KLK',
    Symbol: 'KLK',
    CoinName: 'Klickzie',
    FullName: 'Klickzie (KLK)',
  },
  LVN: {
    Name: 'LVN',
    Symbol: 'LVN',
    CoinName: 'LivenPay',
    FullName: 'LivenPay (LVN)',
  },
  FFCT: {
    Name: 'FFCT',
    Symbol: 'FFCT',
    CoinName: 'FortFC',
    FullName: 'FortFC (FFCT)',
  },
  AZU: {
    Name: 'AZU',
    Symbol: 'AZU',
    CoinName: 'Azultec',
    FullName: 'Azultec (AZU)',
  },
  ARQ: {
    Name: 'ARQ',
    Symbol: 'ARQ',
    CoinName: 'ArQmA',
    FullName: 'ArQmA (ARQ)',
  },
  ZUC: {
    Name: 'ZUC',
    Symbol: 'ZUC',
    CoinName: 'Zeux',
    FullName: 'Zeux (ZUC)',
  },
  FFM: {
    Name: 'FFM',
    Symbol: 'FFM',
    CoinName: 'Files.fm Library',
    FullName: 'Files.fm Library (FFM)',
  },
  DRF: {
    Name: 'DRF',
    Symbol: 'DRF',
    CoinName: 'Drife',
    FullName: 'Drife (DRF)',
  },
  GTIB: {
    Name: 'GTIB',
    Symbol: 'GTIB',
    CoinName: 'Global Trust Coin',
    FullName: 'Global Trust Coin (GTIB)',
  },
  CR: {
    Name: 'CR',
    Symbol: 'CR',
    CoinName: 'CryptoRiyal',
    FullName: 'CryptoRiyal (CR)',
  },
  VEO: {
    Name: 'VEO',
    Symbol: 'VEO',
    CoinName: 'Amoveo',
    FullName: 'Amoveo (VEO)',
  },
  DLA: {
    Name: 'DLA',
    Symbol: 'DLA',
    CoinName: 'Dolla',
    FullName: 'Dolla (DLA)',
  },
  AFO: {
    Name: 'AFO',
    Symbol: 'AFO',
    CoinName: 'AllForOneBusiness',
    FullName: 'AllForOneBusiness (AFO)',
  },
  BB1: {
    Name: 'BB1',
    Symbol: 'BB1',
    CoinName: 'Bitbond',
    FullName: 'Bitbond (BB1)',
  },
  DAGT: {
    Name: 'DAGT',
    Symbol: 'DAGT',
    CoinName: 'Digital Asset Guarantee Token',
    FullName: 'Digital Asset Guarantee Token (DAGT)',
  },
  GVE: {
    Name: 'GVE',
    Symbol: 'GVE',
    CoinName: 'Globalvillage Ecosystem',
    FullName: 'Globalvillage Ecosystem (GVE)',
  },
  KUV: {
    Name: 'KUV',
    Symbol: 'KUV',
    CoinName: 'Kuverit',
    FullName: 'Kuverit (KUV)',
  },
  BOLTT: {
    Name: 'BOLTT',
    Symbol: 'BOLTT',
    CoinName: 'BolttCoin',
    FullName: 'BolttCoin (BOLTT)',
  },
  ENCX: {
    Name: 'ENCX',
    Symbol: 'ENCX',
    CoinName: 'Encrybit',
    FullName: 'Encrybit (ENCX)',
  },
  VALID: {
    Name: 'VALID',
    Symbol: 'VALID',
    CoinName: 'Validator Token',
    FullName: 'Validator Token (VALID)',
  },
  VENUS: {
    Name: 'VENUS',
    Symbol: 'VENUS',
    CoinName: 'VenusEnergy',
    FullName: 'VenusEnergy (VENUS)',
  },
  HYGH: {
    Name: 'HYGH',
    Symbol: 'HYGH',
    CoinName: 'HYGH',
    FullName: 'HYGH (HYGH)',
  },
  ALCE: {
    Name: 'ALCE',
    Symbol: 'ALCE',
    CoinName: 'Alcedo',
    FullName: 'Alcedo (ALCE)',
  },
  NODIS: {
    Name: 'NODIS',
    Symbol: 'NODIS',
    CoinName: 'Nodis',
    FullName: 'Nodis (NODIS)',
  },
  USDS: {
    Name: 'USDS',
    Symbol: 'USDS',
    CoinName: 'StableUSD',
    FullName: 'StableUSD (USDS)',
  },
  HVE: {
    Name: 'HVE',
    Symbol: 'HVE',
    CoinName: 'UHIVE',
    FullName: 'UHIVE (HVE)',
  },
  VALOR: {
    Name: 'VALOR',
    Symbol: 'VALOR',
    CoinName: 'Valor Token',
    FullName: 'Valor Token (VALOR)',
  },
  ALP: {
    Name: 'ALP',
    Symbol: 'ALP',
    CoinName: 'Alphacon',
    FullName: 'Alphacon (ALP)',
  },
  EMU: {
    Name: 'EMU',
    Symbol: 'EMU',
    CoinName: 'eMusic',
    FullName: 'eMusic (EMU)',
  },
  GAMESTARS: {
    Name: 'GAMESTARS',
    Symbol: 'GAMESTARS',
    CoinName: 'Game Stars',
    FullName: 'Game Stars (GAMESTARS)',
  },
  ARTC: {
    Name: 'ARTC',
    Symbol: 'ARTC',
    CoinName: 'Artcoin',
    FullName: 'Artcoin (ARTC)',
  },
  NRM: {
    Name: 'NRM',
    Symbol: 'NRM',
    CoinName: 'Neuromachine',
    FullName: 'Neuromachine (NRM)',
  },
  APOD: {
    Name: 'APOD',
    Symbol: 'APOD',
    CoinName: 'AirPod',
    FullName: 'AirPod (APOD)',
  },
  AX: {
    Name: 'AX',
    Symbol: 'AX',
    CoinName: 'AlphaX',
    FullName: 'AlphaX (AX)',
  },
  CWEX: {
    Name: 'CWEX',
    Symbol: 'CWEX',
    CoinName: 'Crypto Wine Exchange',
    FullName: 'Crypto Wine Exchange (CWEX)',
  },
  CLDX: {
    Name: 'CLDX',
    Symbol: 'CLDX',
    CoinName: 'Cloverdex',
    FullName: 'Cloverdex (CLDX)',
  },
  ECTE: {
    Name: 'ECTE',
    Symbol: 'ECTE',
    CoinName: 'EurocoinToken',
    FullName: 'EurocoinToken (ECTE)',
  },
  LABX: {
    Name: 'LABX',
    Symbol: 'LABX',
    CoinName: 'Stakinglab',
    FullName: 'Stakinglab (LABX)',
  },
  ENVIENTA: {
    Name: 'ENVIENTA',
    Symbol: 'ENVIENTA',
    CoinName: 'Envienta',
    FullName: 'Envienta (ENVIENTA)',
  },
  GEP: {
    Name: 'GEP',
    Symbol: 'GEP',
    CoinName: 'Gaia',
    FullName: 'Gaia (GEP)',
  },
  IZA: {
    Name: 'IZA',
    Symbol: 'IZA',
    CoinName: 'Inzura',
    FullName: 'Inzura (IZA)',
  },
  GBA: {
    Name: 'GBA',
    Symbol: 'GBA',
    CoinName: 'Geeba',
    FullName: 'Geeba (GBA)',
  },
  ITU: {
    Name: 'ITU',
    Symbol: 'ITU',
    CoinName: 'iTrue',
    FullName: 'iTrue (ITU)',
  },
  FANZ: {
    Name: 'FANZ',
    Symbol: 'FANZ',
    CoinName: 'FanChain',
    FullName: 'FanChain (FANZ)',
  },
  CSPN: {
    Name: 'CSPN',
    Symbol: 'CSPN',
    CoinName: 'Crypto Sports',
    FullName: 'Crypto Sports (CSPN)',
  },
  CCH: {
    Name: 'CCH',
    Symbol: 'CCH',
    CoinName: 'Coinchase',
    FullName: 'Coinchase (CCH)',
  },
  SHA: {
    Name: 'SHA',
    Symbol: 'SHA',
    CoinName: 'Safe Haven',
    FullName: 'Safe Haven (SHA)',
  },
  XOV: {
    Name: 'XOV',
    Symbol: 'XOV',
    CoinName: 'XOVBank',
    FullName: 'XOVBank (XOV)',
  },
  CURA: {
    Name: 'CURA',
    Symbol: 'CURA',
    CoinName: 'Cura Network',
    FullName: 'Cura Network (CURA)',
  },
  CREDIT: {
    Name: 'CREDIT',
    Symbol: 'CREDIT',
    CoinName: 'TerraCredit',
    FullName: 'TerraCredit (CREDIT)',
  },
  ERA: {
    Name: 'ERA',
    Symbol: 'ERA',
    CoinName: 'ETHA',
    FullName: 'ETHA (ERA)',
  },
  MAKE: {
    Name: 'MAKE',
    Symbol: 'MAKE',
    CoinName: 'MAKE',
    FullName: 'MAKE (MAKE)',
  },
  DISCOVERY: {
    Name: 'DISCOVERY',
    Symbol: 'DISCOVERY',
    CoinName: 'DiscoveryIoT',
    FullName: 'DiscoveryIoT (DISCOVERY)',
  },
  SCRIBE: {
    Name: 'SCRIBE',
    Symbol: 'SCRIBE',
    CoinName: 'Scribe Network',
    FullName: 'Scribe Network (SCRIBE)',
  },
  INXM: {
    Name: 'INXM',
    Symbol: 'INXM',
    CoinName: 'InMax',
    FullName: 'InMax (INXM)',
  },
  SQR: {
    Name: 'SQR',
    Symbol: 'SQR',
    CoinName: 'Squeezer',
    FullName: 'Squeezer (SQR)',
  },
  GNC: {
    Name: 'GNC',
    Symbol: 'GNC',
    CoinName: 'Greencoin',
    FullName: 'Greencoin (GNC)',
  },
  PHT: {
    Name: 'PHT',
    Symbol: 'PHT',
    CoinName: 'Photon Token',
    FullName: 'Photon Token (PHT)',
  },
  LYTX: {
    Name: 'LYTX',
    Symbol: 'LYTX',
    CoinName: 'LYTIX',
    FullName: 'LYTIX (LYTX)',
  },
  InBit: {
    Name: 'InBit',
    Symbol: 'InBit',
    CoinName: 'PrepayWay',
    FullName: 'PrepayWay (InBit)',
  },
  LIC: {
    Name: 'LIC',
    Symbol: 'LIC',
    CoinName: 'Ligercoin',
    FullName: 'Ligercoin (LIC)',
  },
  SCA: {
    Name: 'SCA',
    Symbol: 'SCA',
    CoinName: 'SiaClassic',
    FullName: 'SiaClassic (SCA)',
  },
  UGT: {
    Name: 'UGT',
    Symbol: 'UGT',
    CoinName: 'Universal Games Token',
    FullName: 'Universal Games Token (UGT)',
  },
  PARQ: {
    Name: 'PARQ',
    Symbol: 'PARQ',
    CoinName: 'PARQ',
    FullName: 'PARQ (PARQ)',
  },
  IZZY: {
    Name: 'IZZY',
    Symbol: 'IZZY',
    CoinName: 'Izzy',
    FullName: 'Izzy (IZZY)',
  },
  LUNES: {
    Name: 'LUNES',
    Symbol: 'LUNES',
    CoinName: 'Lunes',
    FullName: 'Lunes (LUNES)',
  },
  EDEXA: {
    Name: 'EDEXA',
    Symbol: 'EDEXA',
    CoinName: 'edeXa Security Token',
    FullName: 'edeXa Security Token (EDEXA)',
  },
  PPI: {
    Name: 'PPI',
    Symbol: 'PPI',
    CoinName: 'Primpy',
    FullName: 'Primpy (PPI)',
  },
  ANTE: {
    Name: 'ANTE',
    Symbol: 'ANTE',
    CoinName: 'ANTE',
    FullName: 'ANTE (ANTE)',
  },
  TRXDICE: {
    Name: 'TRXDICE',
    Symbol: 'TRXDICE',
    CoinName: 'TRONdice',
    FullName: 'TRONdice (TRXDICE)',
  },
  AFTT: {
    Name: 'AFTT',
    Symbol: 'AFTT',
    CoinName: 'Africa Trading Chain',
    FullName: 'Africa Trading Chain (AFTT)',
  },
  TRXWIN: {
    Name: 'TRXWIN',
    Symbol: 'TRXWIN',
    CoinName: 'TronWin',
    FullName: 'TronWin (TRXWIN)',
  },
  IGG: {
    Name: 'IGG',
    Symbol: 'IGG',
    CoinName: 'IG Gold',
    FullName: 'IG Gold (IGG)',
  },
  MIG: {
    Name: 'MIG',
    Symbol: 'MIG',
    CoinName: 'Migranet',
    FullName: 'Migranet (MIG)',
  },
  BWN: {
    Name: 'BWN',
    Symbol: 'BWN',
    CoinName: 'BitWings',
    FullName: 'BitWings (BWN)',
  },
  IPUX: {
    Name: 'IPUX',
    Symbol: 'IPUX',
    CoinName: 'IPUX',
    FullName: 'IPUX (IPUX)',
  },
  PCC: {
    Name: 'PCC',
    Symbol: 'PCC',
    CoinName: 'PCORE',
    FullName: 'PCORE (PCC)',
  },
  DARB: {
    Name: 'DARB',
    Symbol: 'DARB',
    CoinName: 'Darb Token',
    FullName: 'Darb Token (DARB)',
  },
  MBTX: {
    Name: 'MBTX',
    Symbol: 'MBTX',
    CoinName: 'MinedBlock',
    FullName: 'MinedBlock (MBTX)',
  },
  CFun: {
    Name: 'CFun',
    Symbol: 'CFun',
    CoinName: 'CFun',
    FullName: 'CFun (CFun)',
  },
  SLICEC: {
    Name: 'SLICEC',
    Symbol: 'SLICEC',
    CoinName: 'SLICE',
    FullName: 'SLICE (SLICEC)',
  },
  '2GT': {
    Name: '2GT',
    Symbol: '2GT',
    CoinName: '2GETHER',
    FullName: '2GETHER (2GT)',
  },
  VOLLAR: {
    Name: 'VOLLAR',
    Symbol: 'VOLLAR',
    CoinName: 'Vollar',
    FullName: 'Vollar (VOLLAR)',
  },
  DXN: {
    Name: 'DXN',
    Symbol: 'DXN',
    CoinName: 'DEXON',
    FullName: 'DEXON (DXN)',
  },
  BUYI: {
    Name: 'BUYI',
    Symbol: 'BUYI',
    CoinName: 'Buying.com',
    FullName: 'Buying.com (BUYI)',
  },
  AFCT: {
    Name: 'AFCT',
    Symbol: 'AFCT',
    CoinName: 'Allforcrypto',
    FullName: 'Allforcrypto (AFCT)',
  },
  REDI: {
    Name: 'REDI',
    Symbol: 'REDI',
    CoinName: 'REDi',
    FullName: 'REDi (REDI)',
  },
  INET: {
    Name: 'INET',
    Symbol: 'INET',
    CoinName: 'Insure Network',
    FullName: 'Insure Network (INET)',
  },
  ADUX: {
    Name: 'ADUX',
    Symbol: 'ADUX',
    CoinName: 'Adult X Token',
    FullName: 'Adult X Token (ADUX)',
  },
  HRD: {
    Name: 'HRD',
    Symbol: 'HRD',
    CoinName: 'Hoard',
    FullName: 'Hoard (HRD)',
  },
  QCO: {
    Name: 'QCO',
    Symbol: 'QCO',
    CoinName: 'Qravity',
    FullName: 'Qravity (QCO)',
  },
  ZEROB: {
    Name: 'ZEROB',
    Symbol: 'ZEROB',
    CoinName: 'ZeroBank',
    FullName: 'ZeroBank (ZEROB)',
  },
  ISG: {
    Name: 'ISG',
    Symbol: 'ISG',
    CoinName: 'ISG',
    FullName: 'ISG (ISG)',
  },
  GEC: {
    Name: 'GEC',
    Symbol: 'GEC',
    CoinName: 'Geco.one',
    FullName: 'Geco.one (GEC)',
  },
  MCRC: {
    Name: 'MCRC',
    Symbol: 'MCRC',
    CoinName: 'MyCreditChain',
    FullName: 'MyCreditChain (MCRC)',
  },
  ETGP: {
    Name: 'ETGP',
    Symbol: 'ETGP',
    CoinName: 'Ethereum Gold Project',
    FullName: 'Ethereum Gold Project (ETGP)',
  },
  GFCS: {
    Name: 'GFCS',
    Symbol: 'GFCS',
    CoinName: 'Global Funeral Care',
    FullName: 'Global Funeral Care (GFCS)',
  },
  REINDEER: {
    Name: 'REINDEER',
    Symbol: 'REINDEER',
    CoinName: 'Reindeer',
    FullName: 'Reindeer (REINDEER)',
  },
  IX: {
    Name: 'IX',
    Symbol: 'IX',
    CoinName: 'X-Block',
    FullName: 'X-Block (IX)',
  },
  ALIC: {
    Name: 'ALIC',
    Symbol: 'ALIC',
    CoinName: 'AliCoin',
    FullName: 'AliCoin (ALIC)',
  },
  HCXP: {
    Name: 'HCXP',
    Symbol: 'HCXP',
    CoinName: 'HCX PAY',
    FullName: 'HCX PAY (HCXP)',
  },
  AGRO: {
    Name: 'AGRO',
    Symbol: 'AGRO',
    CoinName: 'Bit Agro',
    FullName: 'Bit Agro (AGRO)',
  },
  BYTS: {
    Name: 'BYTS',
    Symbol: 'BYTS',
    CoinName: 'Bytus',
    FullName: 'Bytus (BYTS)',
  },
  EUCX: {
    Name: 'EUCX',
    Symbol: 'EUCX',
    CoinName: 'EUCX',
    FullName: 'EUCX (EUCX)',
  },
  MYTV: {
    Name: 'MYTV',
    Symbol: 'MYTV',
    CoinName: 'MyTVchain',
    FullName: 'MyTVchain (MYTV)',
  },
  LEVL: {
    Name: 'LEVL',
    Symbol: 'LEVL',
    CoinName: 'Levolution',
    FullName: 'Levolution (LEVL)',
  },
  PNP: {
    Name: 'PNP',
    Symbol: 'PNP',
    CoinName: 'LogisticsX',
    FullName: 'LogisticsX (PNP)',
  },
  PRY: {
    Name: 'PRY',
    Symbol: 'PRY',
    CoinName: 'PRIMARY',
    FullName: 'PRIMARY (PRY)',
  },
  MXM: {
    Name: 'MXM',
    Symbol: 'MXM',
    CoinName: 'Maximine',
    FullName: 'Maximine (MXM)',
  },
  TTC: {
    Name: 'TTC',
    Symbol: 'TTC',
    CoinName: 'TTC PROTOCOL',
    FullName: 'TTC PROTOCOL (TTC)',
  },
  BCNX: {
    Name: 'BCNX',
    Symbol: 'BCNX',
    CoinName: 'BCNEX',
    FullName: 'BCNEX (BCNX)',
  },
  EVED: {
    Name: 'EVED',
    Symbol: 'EVED',
    CoinName: 'Evedo',
    FullName: 'Evedo (EVED)',
  },
  HTER: {
    Name: 'HTER',
    Symbol: 'HTER',
    CoinName: 'Biogen',
    FullName: 'Biogen (HTER)',
  },
  GESE: {
    Name: 'GESE',
    Symbol: 'GESE',
    CoinName: 'Gese',
    FullName: 'Gese (GESE)',
  },
  OOKI: {
    Name: 'OOKI',
    Symbol: 'OOKI',
    CoinName: 'Ooki',
    FullName: 'Ooki (OOKI)',
  },
  GIF: {
    Name: 'GIF',
    Symbol: 'GIF',
    CoinName: 'Gift Token',
    FullName: 'Gift Token (GIF)',
  },
  '2KEY': {
    Name: '2KEY',
    Symbol: '2KEY',
    CoinName: '2key.network',
    FullName: '2key.network (2KEY)',
  },
  DEVX: {
    Name: 'DEVX',
    Symbol: 'DEVX',
    CoinName: 'Developeo',
    FullName: 'Developeo (DEVX)',
  },
  HBRS: {
    Name: 'HBRS',
    Symbol: 'HBRS',
    CoinName: 'HubrisOne',
    FullName: 'HubrisOne (HBRS)',
  },
  XPL: {
    Name: 'XPL',
    Symbol: 'XPL',
    CoinName: 'Exclusive Platform',
    FullName: 'Exclusive Platform (XPL)',
  },
  MTSH: {
    Name: 'MTSH',
    Symbol: 'MTSH',
    CoinName: 'Mitoshi',
    FullName: 'Mitoshi (MTSH)',
  },
  DAGO: {
    Name: 'DAGO',
    Symbol: 'DAGO',
    CoinName: 'Dago Mining',
    FullName: 'Dago Mining (DAGO)',
  },
  PPR: {
    Name: 'PPR',
    Symbol: 'PPR',
    CoinName: 'Papyrus',
    FullName: 'Papyrus (PPR)',
  },
  REW: {
    Name: 'REW',
    Symbol: 'REW',
    CoinName: 'Review.Network',
    FullName: 'Review.Network (REW)',
  },
  TELE: {
    Name: 'TELE',
    Symbol: 'TELE',
    CoinName: 'Miracle Tele',
    FullName: 'Miracle Tele (TELE)',
  },
  GYMREW: {
    Name: 'GYMREW',
    Symbol: 'GYMREW',
    CoinName: 'Gym Rewards',
    FullName: 'Gym Rewards (GYMREW)',
  },
  CRES: {
    Name: 'CRES',
    Symbol: 'CRES',
    CoinName: 'Cresio',
    FullName: 'Cresio (CRES)',
  },
  AES: {
    Name: 'AES',
    Symbol: 'AES',
    CoinName: 'Artis Aes Evolution',
    FullName: 'Artis Aes Evolution (AES)',
  },
  AIBK: {
    Name: 'AIBK',
    Symbol: 'AIBK',
    CoinName: 'AIB Utility Token',
    FullName: 'AIB Utility Token (AIBK)',
  },
  OILD: {
    Name: 'OILD',
    Symbol: 'OILD',
    CoinName: 'OilWellCoin',
    FullName: 'OilWellCoin (OILD)',
  },
  LOLC: {
    Name: 'LOLC',
    Symbol: 'LOLC',
    CoinName: 'LOL Coin',
    FullName: 'LOL Coin (LOLC)',
  },
  RFOX: {
    Name: 'RFOX',
    Symbol: 'RFOX',
    CoinName: 'RedFOX Labs',
    FullName: 'RedFOX Labs (RFOX)',
  },
  CTLX: {
    Name: 'CTLX',
    Symbol: 'CTLX',
    CoinName: 'Cash Telex',
    FullName: 'Cash Telex (CTLX)',
  },
  LOTES: {
    Name: 'LOTES',
    Symbol: 'LOTES',
    CoinName: 'Loteo',
    FullName: 'Loteo (LOTES)',
  },
  LOTEU: {
    Name: 'LOTEU',
    Symbol: 'LOTEU',
    CoinName: 'Loteo',
    FullName: 'Loteo (LOTEU)',
  },
  RVO: {
    Name: 'RVO',
    Symbol: 'RVO',
    CoinName: 'AhrvoDEEX',
    FullName: 'AhrvoDEEX (RVO)',
  },
  GTH: {
    Name: 'GTH',
    Symbol: 'GTH',
    CoinName: 'Gath3r',
    FullName: 'Gath3r (GTH)',
  },
  KBT: {
    Name: 'KBT',
    Symbol: 'KBT',
    CoinName: 'Kartblock',
    FullName: 'Kartblock (KBT)',
  },
  USDX: {
    Name: 'USDX',
    Symbol: 'USDX',
    CoinName: 'USDX Stablecoin',
    FullName: 'USDX Stablecoin (USDX)',
  },
  LHT: {
    Name: 'LHT',
    Symbol: 'LHT',
    CoinName: 'LHT Coin',
    FullName: 'LHT Coin (LHT)',
  },
  NYCREC: {
    Name: 'NYCREC',
    Symbol: 'NYCREC',
    CoinName: 'NYCREC',
    FullName: 'NYCREC (NYCREC)',
  },
  NSD: {
    Name: 'NSD',
    Symbol: 'NSD',
    CoinName: 'Nasdacoin',
    FullName: 'Nasdacoin (NSD)',
  },
  BOLT: {
    Name: 'BOLT',
    Symbol: 'BOLT',
    CoinName: 'Bolt',
    FullName: 'Bolt (BOLT)',
  },
  SPT: {
    Name: 'SPT',
    Symbol: 'SPT',
    CoinName: 'SPECTRUM',
    FullName: 'SPECTRUM (SPT)',
  },
  FBB: {
    Name: 'FBB',
    Symbol: 'FBB',
    CoinName: 'FilmBusinessBuster',
    FullName: 'FilmBusinessBuster (FBB)',
  },
  BSAFE: {
    Name: 'BSAFE',
    Symbol: 'BSAFE',
    CoinName: 'BlockSafe',
    FullName: 'BlockSafe (BSAFE)',
  },
  DBTN: {
    Name: 'DBTN',
    Symbol: 'DBTN',
    CoinName: 'Universa Native token',
    FullName: 'Universa Native token (DBTN)',
  },
  HET: {
    Name: 'HET',
    Symbol: 'HET',
    CoinName: 'HavEther',
    FullName: 'HavEther (HET)',
  },
  CMA: {
    Name: 'CMA',
    Symbol: 'CMA',
    CoinName: 'Crypto Market Ads',
    FullName: 'Crypto Market Ads (CMA)',
  },
  MAPR: {
    Name: 'MAPR',
    Symbol: 'MAPR',
    CoinName: 'Maya Preferred 223',
    FullName: 'Maya Preferred 223 (MAPR)',
  },
  PBET: {
    Name: 'PBET',
    Symbol: 'PBET',
    CoinName: 'PBET',
    FullName: 'PBET (PBET)',
  },
  PUX: {
    Name: 'PUX',
    Symbol: 'PUX',
    CoinName: 'pukkamex',
    FullName: 'pukkamex (PUX)',
  },
  RSF: {
    Name: 'RSF',
    Symbol: 'RSF',
    CoinName: 'Royal Sting',
    FullName: 'Royal Sting (RSF)',
  },
  TAS: {
    Name: 'TAS',
    Symbol: 'TAS',
    CoinName: 'TARUSH',
    FullName: 'TARUSH (TAS)',
  },
  COVA: {
    Name: 'COVA',
    Symbol: 'COVA',
    CoinName: 'COVA',
    FullName: 'COVA (COVA)',
  },
  FXC: {
    Name: 'FXC',
    Symbol: 'FXC',
    CoinName: 'Flexacoin',
    FullName: 'Flexacoin (FXC)',
  },
  GEX: {
    Name: 'GEX',
    Symbol: 'GEX',
    CoinName: 'Gexan',
    FullName: 'Gexan (GEX)',
  },
  DREP: {
    Name: 'DREP',
    Symbol: 'DREP',
    CoinName: 'DREP',
    FullName: 'DREP (DREP)',
  },
  VDL: {
    Name: 'VDL',
    Symbol: 'VDL',
    CoinName: 'Vidulum',
    FullName: 'Vidulum (VDL)',
  },
  TMN: {
    Name: 'TMN',
    Symbol: 'TMN',
    CoinName: 'TranslateMe',
    FullName: 'TranslateMe (TMN)',
  },
  TECRA: {
    Name: 'TECRA',
    Symbol: 'TECRA',
    CoinName: 'TecraCoin',
    FullName: 'TecraCoin (TECRA)',
  },
  ASST: {
    Name: 'ASST',
    Symbol: 'ASST',
    CoinName: 'AssetStream',
    FullName: 'AssetStream (ASST)',
  },
  IFX: {
    Name: 'IFX',
    Symbol: 'IFX',
    CoinName: 'IdeaFeX',
    FullName: 'IdeaFeX (IFX)',
  },
  MART: {
    Name: 'MART',
    Symbol: 'MART',
    CoinName: 'Monart',
    FullName: 'Monart (MART)',
  },
  DAPPT: {
    Name: 'DAPPT',
    Symbol: 'DAPPT',
    CoinName: 'Dapp Token',
    FullName: 'Dapp Token (DAPPT)',
  },
  TTN: {
    Name: 'TTN',
    Symbol: 'TTN',
    CoinName: 'Titan Coin',
    FullName: 'Titan Coin (TTN)',
  },
  GNTO: {
    Name: 'GNTO',
    Symbol: 'GNTO',
    CoinName: 'GoldeNugget Token',
    FullName: 'GoldeNugget Token (GNTO)',
  },
  LMXC: {
    Name: 'LMXC',
    Symbol: 'LMXC',
    CoinName: 'LimonX',
    FullName: 'LimonX (LMXC)',
  },
  GDR: {
    Name: 'GDR',
    Symbol: 'GDR',
    CoinName: 'Guider.Travel',
    FullName: 'Guider.Travel (GDR)',
  },
  LNX: {
    Name: 'LNX',
    Symbol: 'LNX',
    CoinName: 'Lunox Token',
    FullName: 'Lunox Token (LNX)',
  },
  ORIGIN: {
    Name: 'ORIGIN',
    Symbol: 'ORIGIN',
    CoinName: 'Origin Foundation',
    FullName: 'Origin Foundation (ORIGIN)',
  },
  NTO: {
    Name: 'NTO',
    Symbol: 'NTO',
    CoinName: 'Neutro Protocol',
    FullName: 'Neutro Protocol (NTO)',
  },
  TXT: {
    Name: 'TXT',
    Symbol: 'TXT',
    CoinName: 'TuneTrade',
    FullName: 'TuneTrade (TXT)',
  },
  SCONE: {
    Name: 'SCONE',
    Symbol: 'SCONE',
    CoinName: 'Sportcash One',
    FullName: 'Sportcash One (SCONE)',
  },
  OWNDATA: {
    Name: 'OWNDATA',
    Symbol: 'OWNDATA',
    CoinName: 'OWNDATA',
    FullName: 'OWNDATA (OWNDATA)',
  },
  VLS: {
    Name: 'VLS',
    Symbol: 'VLS',
    CoinName: 'Veles',
    FullName: 'Veles (VLS)',
  },
  AWR: {
    Name: 'AWR',
    Symbol: 'AWR',
    CoinName: 'Award',
    FullName: 'Award (AWR)',
  },
  QQQ: {
    Name: 'QQQ',
    Symbol: 'QQQ',
    CoinName: 'Poseidon Network',
    FullName: 'Poseidon Network (QQQ)',
  },
  KOZ: {
    Name: 'KOZ',
    Symbol: 'KOZ',
    CoinName: 'Kozjin',
    FullName: 'Kozjin (KOZ)',
  },
  SMAT: {
    Name: 'SMAT',
    Symbol: 'SMAT',
    CoinName: 'Smathium',
    FullName: 'Smathium (SMAT)',
  },
  IOWN: {
    Name: 'IOWN',
    Symbol: 'IOWN',
    CoinName: 'iOWN Token',
    FullName: 'iOWN Token (IOWN)',
  },
  QUBITICA: {
    Name: 'QUBITICA',
    Symbol: 'QUBITICA',
    CoinName: 'Qubitica',
    FullName: 'Qubitica (QUBITICA)',
  },
  BCX: {
    Name: 'BCX',
    Symbol: 'BCX',
    CoinName: 'BitcoinX',
    FullName: 'BitcoinX (BCX)',
  },
  BLOCKSTAMP: {
    Name: 'BLOCKSTAMP',
    Symbol: 'BLOCKSTAMP',
    CoinName: 'BlockStamp',
    FullName: 'BlockStamp (BLOCKSTAMP)',
  },
  AYA: {
    Name: 'AYA',
    Symbol: 'AYA',
    CoinName: 'Aryacoin',
    FullName: 'Aryacoin (AYA)',
  },
  BUSDC: {
    Name: 'BUSDC',
    Symbol: 'BUSDC',
    CoinName: 'BUSD',
    FullName: 'BUSD (BUSDC)',
  },
  BACOIN: {
    Name: 'BACOIN',
    Symbol: 'BACOIN',
    CoinName: 'BACoin',
    FullName: 'BACoin (BACOIN)',
  },
  BLAST: {
    Name: 'BLAST',
    Symbol: 'BLAST',
    CoinName: 'BLAST',
    FullName: 'BLAST (BLAST)',
  },
  PRDX: {
    Name: 'PRDX',
    Symbol: 'PRDX',
    CoinName: 'ParamountDax Token',
    FullName: 'ParamountDax Token (PRDX)',
  },
  FILM: {
    Name: 'FILM',
    Symbol: 'FILM',
    CoinName: 'Filmpass',
    FullName: 'Filmpass (FILM)',
  },
  LUT: {
    Name: 'LUT',
    Symbol: 'LUT',
    CoinName: 'Cinemadrom',
    FullName: 'Cinemadrom (LUT)',
  },
  VDX: {
    Name: 'VDX',
    Symbol: 'VDX',
    CoinName: 'Vodi X',
    FullName: 'Vodi X (VDX)',
  },
  CKUSD: {
    Name: 'CKUSD',
    Symbol: 'CKUSD',
    CoinName: 'CKUSD',
    FullName: 'CKUSD (CKUSD)',
  },
  CTPT: {
    Name: 'CTPT',
    Symbol: 'CTPT',
    CoinName: 'Contents Protocol',
    FullName: 'Contents Protocol (CTPT)',
  },
  ESBC: {
    Name: 'ESBC',
    Symbol: 'ESBC',
    CoinName: 'ESBC',
    FullName: 'ESBC (ESBC)',
  },
  GRAYLL: {
    Name: 'GRAYLL',
    Symbol: 'GRAYLL',
    CoinName: 'GRAYLL',
    FullName: 'GRAYLL (GRAYLL)',
  },
  SERV: {
    Name: 'SERV',
    Symbol: 'SERV',
    CoinName: 'Serve',
    FullName: 'Serve (SERV)',
  },
  PTON: {
    Name: 'PTON',
    Symbol: 'PTON',
    CoinName: 'Foresting',
    FullName: 'Foresting (PTON)',
  },
  DPN: {
    Name: 'DPN',
    Symbol: 'DPN',
    CoinName: 'DIPNET',
    FullName: 'DIPNET (DPN)',
  },
  VBK: {
    Name: 'VBK',
    Symbol: 'VBK',
    CoinName: 'VeriBlock',
    FullName: 'VeriBlock (VBK)',
  },
  THEMIS: {
    Name: 'THEMIS',
    Symbol: 'THEMIS',
    CoinName: 'Themis',
    FullName: 'Themis (THEMIS)',
  },
  BWX: {
    Name: 'BWX',
    Symbol: 'BWX',
    CoinName: 'Blue Whale',
    FullName: 'Blue Whale (BWX)',
  },
  BORA: {
    Name: 'BORA',
    Symbol: 'BORA',
    CoinName: 'BORA',
    FullName: 'BORA (BORA)',
  },
  WIB: {
    Name: 'WIB',
    Symbol: 'WIB',
    CoinName: 'Wibson',
    FullName: 'Wibson (WIB)',
  },
  BOXX: {
    Name: 'BOXX',
    Symbol: 'BOXX',
    CoinName: 'Blockparty',
    FullName: 'Blockparty (BOXX)',
  },
  UT: {
    Name: 'UT',
    Symbol: 'UT',
    CoinName: 'Ulord',
    FullName: 'Ulord (UT)',
  },
  BU: {
    Name: 'BU',
    Symbol: 'BU',
    CoinName: 'BUMO',
    FullName: 'BUMO (BU)',
  },
  WINT: {
    Name: 'WINT',
    Symbol: 'WINT',
    CoinName: 'WinToken',
    FullName: 'WinToken (WINT)',
  },
  OGO: {
    Name: 'OGO',
    Symbol: 'OGO',
    CoinName: 'Origo',
    FullName: 'Origo (OGO)',
  },
  DVT: {
    Name: 'DVT',
    Symbol: 'DVT',
    CoinName: 'DeVault',
    FullName: 'DeVault (DVT)',
  },
  BOMB: {
    Name: 'BOMB',
    Symbol: 'BOMB',
    CoinName: 'BOMB',
    FullName: 'BOMB (BOMB)',
  },
  BRYLL: {
    Name: 'BRYLL',
    Symbol: 'BRYLL',
    CoinName: 'Bryllite',
    FullName: 'Bryllite (BRYLL)',
  },
  BOXT: {
    Name: 'BOXT',
    Symbol: 'BOXT',
    CoinName: 'BOX Token',
    FullName: 'BOX Token (BOXT)',
  },
  SPENDC: {
    Name: 'SPENDC',
    Symbol: 'SPENDC',
    CoinName: 'SpendCoin',
    FullName: 'SpendCoin (SPENDC)',
  },
  BEST: {
    Name: 'BEST',
    Symbol: 'BEST',
    CoinName: 'Bitpanda Ecosystem Token',
    FullName: 'Bitpanda Ecosystem Token (BEST)',
  },
  CAM: {
    Name: 'CAM',
    Symbol: 'CAM',
    CoinName: 'Consumption Avatar Matrix',
    FullName: 'Consumption Avatar Matrix (CAM)',
  },
  BITNEW: {
    Name: 'BITNEW',
    Symbol: 'BITNEW',
    CoinName: 'BitNewChain',
    FullName: 'BitNewChain (BITNEW)',
  },
  BCV: {
    Name: 'BCV',
    Symbol: 'BCV',
    CoinName: 'BitCapitalVendor',
    FullName: 'BitCapitalVendor (BCV)',
  },
  FLC: {
    Name: 'FLC',
    Symbol: 'FLC',
    CoinName: 'FlowChainCoin',
    FullName: 'FlowChainCoin (FLC)',
  },
  INB: {
    Name: 'INB',
    Symbol: 'INB',
    CoinName: 'Insight Chain',
    FullName: 'Insight Chain (INB)',
  },
  WICC: {
    Name: 'WICC',
    Symbol: 'WICC',
    CoinName: 'WaykiChain',
    FullName: 'WaykiChain (WICC)',
  },
  BIHU: {
    Name: 'BIHU',
    Symbol: 'BIHU',
    CoinName: 'Key',
    FullName: 'Key (BIHU)',
  },
  '1SG': {
    Name: '1SG',
    Symbol: '1SG',
    CoinName: '1SG',
    FullName: '1SG (1SG)',
  },
  TOPN: {
    Name: 'TOPN',
    Symbol: 'TOPN',
    CoinName: 'TOP Network',
    FullName: 'TOP Network (TOPN)',
  },
  KT: {
    Name: 'KT',
    Symbol: 'KT',
    CoinName: 'Kuai Token',
    FullName: 'Kuai Token (KT)',
  },
  INE: {
    Name: 'INE',
    Symbol: 'INE',
    CoinName: 'IntelliShare',
    FullName: 'IntelliShare (INE)',
  },
  ARTF: {
    Name: 'ARTF',
    Symbol: 'ARTF',
    CoinName: 'Artfinity Token',
    FullName: 'Artfinity Token (ARTF)',
  },
  AT: {
    Name: 'AT',
    Symbol: 'AT',
    CoinName: 'AWARE',
    FullName: 'AWARE (AT)',
  },
  DEX: {
    Name: 'DEX',
    Symbol: 'DEX',
    CoinName: 'DEX',
    FullName: 'DEX (DEX)',
  },
  ELD: {
    Name: 'ELD',
    Symbol: 'ELD',
    CoinName: 'Electrum Dark',
    FullName: 'Electrum Dark (ELD)',
  },
  UOS: {
    Name: 'UOS',
    Symbol: 'UOS',
    CoinName: 'UOS',
    FullName: 'UOS (UOS)',
  },
  UND: {
    Name: 'UND',
    Symbol: 'UND',
    CoinName: 'United Network Distribution',
    FullName: 'United Network Distribution (UND)',
  },
  PEOS: {
    Name: 'PEOS',
    Symbol: 'PEOS',
    CoinName: 'pEOS',
    FullName: 'pEOS (PEOS)',
  },
  VIPS: {
    Name: 'VIPS',
    Symbol: 'VIPS',
    CoinName: 'Vipstar Coin',
    FullName: 'Vipstar Coin (VIPS)',
  },
  BBGC: {
    Name: 'BBGC',
    Symbol: 'BBGC',
    CoinName: 'BigBang Game',
    FullName: 'BigBang Game (BBGC)',
  },
  LINANET: {
    Name: 'LINANET',
    Symbol: 'LINANET',
    CoinName: 'Lina',
    FullName: 'Lina (LINANET)',
  },
  INFC: {
    Name: 'INFC',
    Symbol: 'INFC',
    CoinName: 'Influence Chain',
    FullName: 'Influence Chain (INFC)',
  },
  BTNT: {
    Name: 'BTNT',
    Symbol: 'BTNT',
    CoinName: 'BitNautic',
    FullName: 'BitNautic (BTNT)',
  },
  WGP: {
    Name: 'WGP',
    Symbol: 'WGP',
    CoinName: 'W Green Pay',
    FullName: 'W Green Pay (WGP)',
  },
  NPXSXEM: {
    Name: 'NPXSXEM',
    Symbol: 'NPXSXEM',
    CoinName: 'Pundi X NEM',
    FullName: 'Pundi X NEM (NPXSXEM)',
  },
  USCC: {
    Name: 'USCC',
    Symbol: 'USCC',
    CoinName: 'USC',
    FullName: 'USC (USCC)',
  },
  ANDC: {
    Name: 'ANDC',
    Symbol: 'ANDC',
    CoinName: 'Android chain',
    FullName: 'Android chain (ANDC)',
  },
  WEGEN: {
    Name: 'WEGEN',
    Symbol: 'WEGEN',
    CoinName: 'WeGen Platform',
    FullName: 'WeGen Platform (WEGEN)',
  },
  MRS: {
    Name: 'MRS',
    Symbol: 'MRS',
    CoinName: 'Marginless',
    FullName: 'Marginless (MRS)',
  },
  FUNDC: {
    Name: 'FUNDC',
    Symbol: 'FUNDC',
    CoinName: 'FUNDChains',
    FullName: 'FUNDChains (FUNDC)',
  },
  OCTOIN: {
    Name: 'OCTOIN',
    Symbol: 'OCTOIN',
    CoinName: 'Octoin Coin',
    FullName: 'Octoin Coin (OCTOIN)',
  },
  NNB: {
    Name: 'NNB',
    Symbol: 'NNB',
    CoinName: 'NNB Token',
    FullName: 'NNB Token (NNB)',
  },
  USDQ: {
    Name: 'USDQ',
    Symbol: 'USDQ',
    CoinName: 'USDQ',
    FullName: 'USDQ (USDQ)',
  },
  WWB: {
    Name: 'WWB',
    Symbol: 'WWB',
    CoinName: 'Wowbit',
    FullName: 'Wowbit (WWB)',
  },
  VANT: {
    Name: 'VANT',
    Symbol: 'VANT',
    CoinName: 'Vanta Network',
    FullName: 'Vanta Network (VANT)',
  },
  BTCB: {
    Name: 'BTCB',
    Symbol: 'BTCB',
    CoinName: 'Bitcoin BEP2',
    FullName: 'Bitcoin BEP2 (BTCB)',
  },
  IONC: {
    Name: 'IONC',
    Symbol: 'IONC',
    CoinName: 'IONChain',
    FullName: 'IONChain (IONC)',
  },
  CNUS: {
    Name: 'CNUS',
    Symbol: 'CNUS',
    CoinName: 'CoinUs',
    FullName: 'CoinUs (CNUS)',
  },
  SDA: {
    Name: 'SDA',
    Symbol: 'SDA',
    CoinName: 'SDChain',
    FullName: 'SDChain (SDA)',
  },
  SMARTUP: {
    Name: 'SMARTUP',
    Symbol: 'SMARTUP',
    CoinName: 'Smartup',
    FullName: 'Smartup (SMARTUP)',
  },
  UIP: {
    Name: 'UIP',
    Symbol: 'UIP',
    CoinName: 'UnlimitedIP',
    FullName: 'UnlimitedIP (UIP)',
  },
  MIRC: {
    Name: 'MIRC',
    Symbol: 'MIRC',
    CoinName: 'MIR COIN',
    FullName: 'MIR COIN (MIRC)',
  },
  JCT: {
    Name: 'JCT',
    Symbol: 'JCT',
    CoinName: 'Japan Content Token',
    FullName: 'Japan Content Token (JCT)',
  },
  DOS: {
    Name: 'DOS',
    Symbol: 'DOS',
    CoinName: 'DOS Network',
    FullName: 'DOS Network (DOS)',
  },
  DIEM: {
    Name: 'DIEM',
    Symbol: 'DIEM',
    CoinName: 'Facebook Diem',
    FullName: 'Facebook Diem (DIEM)',
  },
  DLO: {
    Name: 'DLO',
    Symbol: 'DLO',
    CoinName: 'Delio',
    FullName: 'Delio (DLO)',
  },
  DUOT: {
    Name: 'DUOT',
    Symbol: 'DUOT',
    CoinName: 'DUO Network',
    FullName: 'DUO Network (DUOT)',
  },
  OCE: {
    Name: 'OCE',
    Symbol: 'OCE',
    CoinName: 'OceanEX Token',
    FullName: 'OceanEX Token (OCE)',
  },
  AIRX: {
    Name: 'AIRX',
    Symbol: 'AIRX',
    CoinName: 'Aircoins',
    FullName: 'Aircoins (AIRX)',
  },
  RAVEN: {
    Name: 'RAVEN',
    Symbol: 'RAVEN',
    CoinName: 'Raven Protocol',
    FullName: 'Raven Protocol (RAVEN)',
  },
  MIN: {
    Name: 'MIN',
    Symbol: 'MIN',
    CoinName: 'MINDOL',
    FullName: 'MINDOL (MIN)',
  },
  B91: {
    Name: 'B91',
    Symbol: 'B91',
    CoinName: 'B91',
    FullName: 'B91 (B91)',
  },
  AIDT: {
    Name: 'AIDT',
    Symbol: 'AIDT',
    CoinName: 'AIDUS TOKEN',
    FullName: 'AIDUS TOKEN (AIDT)',
  },
  AILINK: {
    Name: 'AILINK',
    Symbol: 'AILINK',
    CoinName: 'AiLink Token',
    FullName: 'AiLink Token (AILINK)',
  },
  CLB: {
    Name: 'CLB',
    Symbol: 'CLB',
    CoinName: 'Cloudbric',
    FullName: 'Cloudbric (CLB)',
  },
  CZR: {
    Name: 'CZR',
    Symbol: 'CZR',
    CoinName: 'CanonChain',
    FullName: 'CanonChain (CZR)',
  },
  DELTAC: {
    Name: 'DELTAC',
    Symbol: 'DELTAC',
    CoinName: 'DeltaChain',
    FullName: 'DeltaChain (DELTAC)',
  },
  GSE: {
    Name: 'GSE',
    Symbol: 'GSE',
    CoinName: 'GSENetwork',
    FullName: 'GSENetwork (GSE)',
  },
  KNT: {
    Name: 'KNT',
    Symbol: 'KNT',
    CoinName: 'Knekted',
    FullName: 'Knekted (KNT)',
  },
  KWATT: {
    Name: 'KWATT',
    Symbol: 'KWATT',
    CoinName: '4New',
    FullName: '4New (KWATT)',
  },
  MAS: {
    Name: 'MAS',
    Symbol: 'MAS',
    CoinName: 'Midas Protocol',
    FullName: 'Midas Protocol (MAS)',
  },
  UCH: {
    Name: 'UCH',
    Symbol: 'UCH',
    CoinName: 'UChain',
    FullName: 'UChain (UCH)',
  },
  PRS: {
    Name: 'PRS',
    Symbol: 'PRS',
    CoinName: 'PressOne',
    FullName: 'PressOne (PRS)',
  },
  EHRT: {
    Name: 'EHRT',
    Symbol: 'EHRT',
    CoinName: 'Eight Hours Token',
    FullName: 'Eight Hours Token (EHRT)',
  },
  XRC: {
    Name: 'XRC',
    Symbol: 'XRC',
    CoinName: 'xRhodium',
    FullName: 'xRhodium (XRC)',
  },
  TERA: {
    Name: 'TERA',
    Symbol: 'TERA',
    CoinName: 'TERA',
    FullName: 'TERA (TERA)',
  },
  VOCO: {
    Name: 'VOCO',
    Symbol: 'VOCO',
    CoinName: 'Provoco',
    FullName: 'Provoco (VOCO)',
  },
  TNS: {
    Name: 'TNS',
    Symbol: 'TNS',
    CoinName: 'Transcodium',
    FullName: 'Transcodium (TNS)',
  },
  NBOT: {
    Name: 'NBOT',
    Symbol: 'NBOT',
    CoinName: 'Naka Bodhi Token',
    FullName: 'Naka Bodhi Token (NBOT)',
  },
  ROOBEE: {
    Name: 'ROOBEE',
    Symbol: 'ROOBEE',
    CoinName: 'ROOBEE',
    FullName: 'ROOBEE (ROOBEE)',
  },
  SOP: {
    Name: 'SOP',
    Symbol: 'SOP',
    CoinName: 'SoPay',
    FullName: 'SoPay (SOP)',
  },
  TYPE: {
    Name: 'TYPE',
    Symbol: 'TYPE',
    CoinName: 'Typerium',
    FullName: 'Typerium (TYPE)',
  },
  CYL: {
    Name: 'CYL',
    Symbol: 'CYL',
    CoinName: 'Crystal Token',
    FullName: 'Crystal Token (CYL)',
  },
  HLT: {
    Name: 'HLT',
    Symbol: 'HLT',
    CoinName: 'HyperLoot',
    FullName: 'HyperLoot (HLT)',
  },
  E2C: {
    Name: 'E2C',
    Symbol: 'E2C',
    CoinName: 'Electronic Energy Coin',
    FullName: 'Electronic Energy Coin (E2C)',
  },
  TAC: {
    Name: 'TAC',
    Symbol: 'TAC',
    CoinName: 'Traceability Chain',
    FullName: 'Traceability Chain (TAC)',
  },
  EKG: {
    Name: 'EKG',
    Symbol: 'EKG',
    CoinName: 'Ekon Gold',
    FullName: 'Ekon Gold (EKG)',
  },
  SILVERWAY: {
    Name: 'SILVERWAY',
    Symbol: 'SILVERWAY',
    CoinName: 'Silverway',
    FullName: 'Silverway (SILVERWAY)',
  },
  ERD: {
    Name: 'ERD',
    Symbol: 'ERD',
    CoinName: 'Elrond',
    FullName: 'Elrond (ERD)',
  },
  PHV: {
    Name: 'PHV',
    Symbol: 'PHV',
    CoinName: 'PATHHIVE',
    FullName: 'PATHHIVE (PHV)',
  },
  ONOT: {
    Name: 'ONOT',
    Symbol: 'ONOT',
    CoinName: 'ONO',
    FullName: 'ONO (ONOT)',
  },
  IMPT: {
    Name: 'IMPT',
    Symbol: 'IMPT',
    CoinName: 'Ether Kingdoms Token',
    FullName: 'Ether Kingdoms Token (IMPT)',
  },
  BITRUE: {
    Name: 'BITRUE',
    Symbol: 'BITRUE',
    CoinName: 'Bitrue Coin',
    FullName: 'Bitrue Coin (BITRUE)',
  },
  CONUN: {
    Name: 'CONUN',
    Symbol: 'CONUN',
    CoinName: 'CONUN',
    FullName: 'CONUN (CONUN)',
  },
  FNB: {
    Name: 'FNB',
    Symbol: 'FNB',
    CoinName: 'FNB protocol',
    FullName: 'FNB protocol (FNB)',
  },
  GNY: {
    Name: 'GNY',
    Symbol: 'GNY',
    CoinName: 'GNY',
    FullName: 'GNY (GNY)',
  },
  SRK: {
    Name: 'SRK',
    Symbol: 'SRK',
    CoinName: 'SparkPoint',
    FullName: 'SparkPoint (SRK)',
  },
  ACDC: {
    Name: 'ACDC',
    Symbol: 'ACDC',
    CoinName: 'Volt',
    FullName: 'Volt (ACDC)',
  },
  YEC: {
    Name: 'YEC',
    Symbol: 'YEC',
    CoinName: 'Ycash',
    FullName: 'Ycash (YEC)',
  },
  TOKO: {
    Name: 'TOKO',
    Symbol: 'TOKO',
    CoinName: 'ToKoin',
    FullName: 'ToKoin (TOKO)',
  },
  ECO: {
    Name: 'ECO',
    Symbol: 'ECO',
    CoinName: 'Ormeus Ecosystem',
    FullName: 'Ormeus Ecosystem (ECO)',
  },
  ADN: {
    Name: 'ADN',
    Symbol: 'ADN',
    CoinName: 'Aladdin',
    FullName: 'Aladdin (ADN)',
  },
  FTN: {
    Name: 'FTN',
    Symbol: 'FTN',
    CoinName: 'Fountain',
    FullName: 'Fountain (FTN)',
  },
  GRN: {
    Name: 'GRN',
    Symbol: 'GRN',
    CoinName: 'GreenPower',
    FullName: 'GreenPower (GRN)',
  },
  NTY: {
    Name: 'NTY',
    Symbol: 'NTY',
    CoinName: 'Nexty',
    FullName: 'Nexty (NTY)',
  },
  ADRX: {
    Name: 'ADRX',
    Symbol: 'ADRX',
    CoinName: 'Adrenaline Chain',
    FullName: 'Adrenaline Chain (ADRX)',
  },
  MESG: {
    Name: 'MESG',
    Symbol: 'MESG',
    CoinName: 'MESG',
    FullName: 'MESG (MESG)',
  },
  UPX: {
    Name: 'UPX',
    Symbol: 'UPX',
    CoinName: 'uPlexa',
    FullName: 'uPlexa (UPX)',
  },
  '1IRST': {
    Name: '1IRST',
    Symbol: '1IRST',
    CoinName: '1irstcoin',
    FullName: '1irstcoin (1IRST)',
  },
  TRTT: {
    Name: 'TRTT',
    Symbol: 'TRTT',
    CoinName: 'Trittium',
    FullName: 'Trittium (TRTT)',
  },
  TCH: {
    Name: 'TCH',
    Symbol: 'TCH',
    CoinName: 'Thorecash',
    FullName: 'Thorecash (TCH)',
  },
  ABX: {
    Name: 'ABX',
    Symbol: 'ABX',
    CoinName: 'Arbidex',
    FullName: 'Arbidex (ABX)',
  },
  MONT: {
    Name: 'MONT',
    Symbol: 'MONT',
    CoinName: 'Monarch Token',
    FullName: 'Monarch Token (MONT)',
  },
  NUT: {
    Name: 'NUT',
    Symbol: 'NUT',
    CoinName: 'Native Utility Token',
    FullName: 'Native Utility Token (NUT)',
  },
  TN: {
    Name: 'TN',
    Symbol: 'TN',
    CoinName: 'TurtleNetwork',
    FullName: 'TurtleNetwork (TN)',
  },
  PBQ: {
    Name: 'PBQ',
    Symbol: 'PBQ',
    CoinName: 'PUBLIQ',
    FullName: 'PUBLIQ (PBQ)',
  },
  ZDR: {
    Name: 'ZDR',
    Symbol: 'ZDR',
    CoinName: 'Zloadr',
    FullName: 'Zloadr (ZDR)',
  },
  DOCT: {
    Name: 'DOCT',
    Symbol: 'DOCT',
    CoinName: 'DocTailor',
    FullName: 'DocTailor (DOCT)',
  },
  DTEP: {
    Name: 'DTEP',
    Symbol: 'DTEP',
    CoinName: 'DECOIN',
    FullName: 'DECOIN (DTEP)',
  },
  TCHAIN: {
    Name: 'TCHAIN',
    Symbol: 'TCHAIN',
    CoinName: 'Tchain',
    FullName: 'Tchain (TCHAIN)',
  },
  BSOV: {
    Name: 'BSOV',
    Symbol: 'BSOV',
    CoinName: 'BitcoinSoV',
    FullName: 'BitcoinSoV (BSOV)',
  },
  PXL: {
    Name: 'PXL',
    Symbol: 'PXL',
    CoinName: 'PIXEL',
    FullName: 'PIXEL (PXL)',
  },
  NAT: {
    Name: 'NAT',
    Symbol: 'NAT',
    CoinName: 'Natmin',
    FullName: 'Natmin (NAT)',
  },
  RET: {
    Name: 'RET',
    Symbol: 'RET',
    CoinName: 'RealTract',
    FullName: 'RealTract (RET)',
  },
  GMAT: {
    Name: 'GMAT',
    Symbol: 'GMAT',
    CoinName: 'GoWithMi',
    FullName: 'GoWithMi (GMAT)',
  },
  LOL: {
    Name: 'LOL',
    Symbol: 'LOL',
    CoinName: 'EMOGI Network',
    FullName: 'EMOGI Network (LOL)',
  },
  BCAC: {
    Name: 'BCAC',
    Symbol: 'BCAC',
    CoinName: 'Business Credit Alliance Chain',
    FullName: 'Business Credit Alliance Chain (BCAC)',
  },
  XD: {
    Name: 'XD',
    Symbol: 'XD',
    CoinName: 'Data Transaction Token',
    FullName: 'Data Transaction Token (XD)',
  },
  BQQQ: {
    Name: 'BQQQ',
    Symbol: 'BQQQ',
    CoinName: 'Bitsdaq Token',
    FullName: 'Bitsdaq Token (BQQQ)',
  },
  LOCUS: {
    Name: 'LOCUS',
    Symbol: 'LOCUS',
    CoinName: 'Locus Chain',
    FullName: 'Locus Chain (LOCUS)',
  },
  COZP: {
    Name: 'COZP',
    Symbol: 'COZP',
    CoinName: 'COZPlus',
    FullName: 'COZPlus (COZP)',
  },
  OGOD: {
    Name: 'OGOD',
    Symbol: 'OGOD',
    CoinName: 'GOTOGOD',
    FullName: 'GOTOGOD (OGOD)',
  },
  FOIN: {
    Name: 'FOIN',
    Symbol: 'FOIN',
    CoinName: 'Foin',
    FullName: 'Foin (FOIN)',
  },
  GOLDENG: {
    Name: 'GOLDENG',
    Symbol: 'GOLDENG',
    CoinName: 'Golden Goose',
    FullName: 'Golden Goose (GOLDENG)',
  },
  YTA: {
    Name: 'YTA',
    Symbol: 'YTA',
    CoinName: 'YottaChain',
    FullName: 'YottaChain (YTA)',
  },
  LXT: {
    Name: 'LXT',
    Symbol: 'LXT',
    CoinName: 'LITEX',
    FullName: 'LITEX (LXT)',
  },
  IMG: {
    Name: 'IMG',
    Symbol: 'IMG',
    CoinName: 'ImageCoin',
    FullName: 'ImageCoin (IMG)',
  },
  TSHP: {
    Name: 'TSHP',
    Symbol: 'TSHP',
    CoinName: '12Ships',
    FullName: '12Ships (TSHP)',
  },
  BOTX: {
    Name: 'BOTX',
    Symbol: 'BOTX',
    CoinName: 'BOTXCOIN',
    FullName: 'BOTXCOIN (BOTX)',
  },
  DPT: {
    Name: 'DPT',
    Symbol: 'DPT',
    CoinName: 'Diamond Platform Token',
    FullName: 'Diamond Platform Token (DPT)',
  },
  TRAT: {
    Name: 'TRAT',
    Symbol: 'TRAT',
    CoinName: 'Tratok',
    FullName: 'Tratok (TRAT)',
  },
  OPNN: {
    Name: 'OPNN',
    Symbol: 'OPNN',
    CoinName: 'Opennity',
    FullName: 'Opennity (OPNN)',
  },
  SINS: {
    Name: 'SINS',
    Symbol: 'SINS',
    CoinName: 'SafeInsure',
    FullName: 'SafeInsure (SINS)',
  },
  HBC: {
    Name: 'HBC',
    Symbol: 'HBC',
    CoinName: 'HBTC Captain Token',
    FullName: 'HBTC Captain Token (HBC)',
  },
  BDX: {
    Name: 'BDX',
    Symbol: 'BDX',
    CoinName: 'Beldex',
    FullName: 'Beldex (BDX)',
  },
  DXR: {
    Name: 'DXR',
    Symbol: 'DXR',
    CoinName: 'DEXTER',
    FullName: 'DEXTER (DXR)',
  },
  PC: {
    Name: 'PC',
    Symbol: 'PC',
    CoinName: 'Promotion Coin',
    FullName: 'Promotion Coin (PC)',
  },
  GOS: {
    Name: 'GOS',
    Symbol: 'GOS',
    CoinName: 'Gosama',
    FullName: 'Gosama (GOS)',
  },
  DEFI: {
    Name: 'DEFI',
    Symbol: 'DEFI',
    CoinName: 'Defi',
    FullName: 'Defi (DEFI)',
  },
  KNOW: {
    Name: 'KNOW',
    Symbol: 'KNOW',
    CoinName: 'KNOW',
    FullName: 'KNOW (KNOW)',
  },
  WFX: {
    Name: 'WFX',
    Symbol: 'WFX',
    CoinName: 'WebFlix',
    FullName: 'WebFlix (WFX)',
  },
  XPC: {
    Name: 'XPC',
    Symbol: 'XPC',
    CoinName: 'eXPerience Chain',
    FullName: 'eXPerience Chain (XPC)',
  },
  COCOS: {
    Name: 'COCOS',
    Symbol: 'COCOS',
    CoinName: 'COCOS BCX',
    FullName: 'COCOS BCX (COCOS)',
  },
  ACD: {
    Name: 'ACD',
    Symbol: 'ACD',
    CoinName: 'Alliance Cargo Direct',
    FullName: 'Alliance Cargo Direct (ACD)',
  },
  AMON: {
    Name: 'AMON',
    Symbol: 'AMON',
    CoinName: 'AmonD',
    FullName: 'AmonD (AMON)',
  },
  BENZI: {
    Name: 'BENZI',
    Symbol: 'BENZI',
    CoinName: 'Ben Zi Token',
    FullName: 'Ben Zi Token (BENZI)',
  },
  BGBP: {
    Name: 'BGBP',
    Symbol: 'BGBP',
    CoinName: 'Binance GBP Stable Coin',
    FullName: 'Binance GBP Stable Coin (BGBP)',
  },
  BHP: {
    Name: 'BHP',
    Symbol: 'BHP',
    CoinName: 'Blockchain of Hash Power',
    FullName: 'Blockchain of Hash Power (BHP)',
  },
  BXA: {
    Name: 'BXA',
    Symbol: 'BXA',
    CoinName: 'Blockchain Exchange Alliance',
    FullName: 'Blockchain Exchange Alliance (BXA)',
  },
  CBNT: {
    Name: 'CBNT',
    Symbol: 'CBNT',
    CoinName: 'Create Breaking News Together',
    FullName: 'Create Breaking News Together (CBNT)',
  },
  DEEP: {
    Name: 'DEEP',
    Symbol: 'DEEP',
    CoinName: 'DeepCloud AI',
    FullName: 'DeepCloud AI (DEEP)',
  },
  DOC: {
    Name: 'DOC',
    Symbol: 'DOC',
    CoinName: 'Dochain',
    FullName: 'Dochain (DOC)',
  },
  DTC: {
    Name: 'DTC',
    Symbol: 'DTC',
    CoinName: 'Data Transaction',
    FullName: 'Data Transaction (DTC)',
  },
  DVP: {
    Name: 'DVP',
    Symbol: 'DVP',
    CoinName: 'Decentralized Vulnerability Platform',
    FullName: 'Decentralized Vulnerability Platform (DVP)',
  },
  DWC: {
    Name: 'DWC',
    Symbol: 'DWC',
    CoinName: 'Digital Wallet',
    FullName: 'Digital Wallet (DWC)',
  },
  ELAC: {
    Name: 'ELAC',
    Symbol: 'ELAC',
    CoinName: 'ELA Coin',
    FullName: 'ELA Coin (ELAC)',
  },
  ETSC: {
    Name: 'ETSC',
    Symbol: 'ETSC',
    CoinName: '​Ether star blockchain',
    FullName: '​Ether star blockchain (ETSC)',
  },
  FMEX: {
    Name: 'FMEX',
    Symbol: 'FMEX',
    CoinName: 'FMex',
    FullName: 'FMex (FMEX)',
  },
  FOR: {
    Name: 'FOR',
    Symbol: 'FOR',
    CoinName: 'ForTube',
    FullName: 'ForTube (FOR)',
  },
  FTK: {
    Name: 'FTK',
    Symbol: 'FTK',
    CoinName: 'FToken',
    FullName: 'FToken (FTK)',
  },
  FLEX: {
    Name: 'FLEX',
    Symbol: 'FLEX',
    CoinName: 'FLEX Coin',
    FullName: 'FLEX Coin (FLEX)',
  },
  GE: {
    Name: 'GE',
    Symbol: 'GE',
    CoinName: 'GEchain',
    FullName: 'GEchain (GE)',
  },
  GOT: {
    Name: 'GOT',
    Symbol: 'GOT',
    CoinName: 'ParkinGo',
    FullName: 'ParkinGo (GOT)',
  },
  INFT: {
    Name: 'INFT',
    Symbol: 'INFT',
    CoinName: 'Infinito',
    FullName: 'Infinito (INFT)',
  },
  ITOC: {
    Name: 'ITOC',
    Symbol: 'ITOC',
    CoinName: 'ITOChain',
    FullName: 'ITOChain (ITOC)',
  },
  JCB: {
    Name: 'JCB',
    Symbol: 'JCB',
    CoinName: 'Wine Chain',
    FullName: 'Wine Chain (JCB)',
  },
  LHD: {
    Name: 'LHD',
    Symbol: 'LHD',
    CoinName: 'LitecoinHD',
    FullName: 'LitecoinHD (LHD)',
  },
  LKN: {
    Name: 'LKN',
    Symbol: 'LKN',
    CoinName: 'LinkCoin Token',
    FullName: 'LinkCoin Token (LKN)',
  },
  LPK: {
    Name: 'LPK',
    Symbol: 'LPK',
    CoinName: 'Kripton',
    FullName: 'Kripton (LPK)',
  },
  MMT: {
    Name: 'MMT',
    Symbol: 'MMT',
    CoinName: 'Master MIX Token',
    FullName: 'Master MIX Token (MMT)',
  },
  OSC: {
    Name: 'OSC',
    Symbol: 'OSC',
    CoinName: 'iOscar',
    FullName: 'iOscar (OSC)',
  },
  PIB: {
    Name: 'PIB',
    Symbol: 'PIB',
    CoinName: 'Pibble',
    FullName: 'Pibble (PIB)',
  },
  PNK: {
    Name: 'PNK',
    Symbol: 'PNK',
    CoinName: 'Kleros',
    FullName: 'Kleros (PNK)',
  },
  PROM: {
    Name: 'PROM',
    Symbol: 'PROM',
    CoinName: 'Prometeus',
    FullName: 'Prometeus (PROM)',
  },
  PROT: {
    Name: 'PROT',
    Symbol: 'PROT',
    CoinName: 'PROT',
    FullName: 'PROT (PROT)',
  },
  QQBC: {
    Name: 'QQBC',
    Symbol: 'QQBC',
    CoinName: 'QQBC IPFS BLOCKCHAIN',
    FullName: 'QQBC IPFS BLOCKCHAIN (QQBC)',
  },
  SPIN: {
    Name: 'SPIN',
    Symbol: 'SPIN',
    CoinName: 'SPIN Protocol',
    FullName: 'SPIN Protocol (SPIN)',
  },
  TCNX: {
    Name: 'TCNX',
    Symbol: 'TCNX',
    CoinName: 'Tercet Network',
    FullName: 'Tercet Network (TCNX)',
  },
  THX: {
    Name: 'THX',
    Symbol: 'THX',
    CoinName: 'Thorenext',
    FullName: 'Thorenext (THX)',
  },
  THEX: {
    Name: 'THEX',
    Symbol: 'THEX',
    CoinName: 'Thore Exchange',
    FullName: 'Thore Exchange (THEX)',
  },
  TCHTRX: {
    Name: 'TCHTRX',
    Symbol: 'TCHTRX',
    CoinName: 'ThoreCashTRX',
    FullName: 'ThoreCashTRX (TCHTRX)',
  },
  UAT: {
    Name: 'UAT',
    Symbol: 'UAT',
    CoinName: 'UltrAlpha',
    FullName: 'UltrAlpha (UAT)',
  },
  UENC: {
    Name: 'UENC',
    Symbol: 'UENC',
    CoinName: 'UniversalEnergyChain',
    FullName: 'UniversalEnergyChain (UENC)',
  },
  URAC: {
    Name: 'URAC',
    Symbol: 'URAC',
    CoinName: 'Uranus',
    FullName: 'Uranus (URAC)',
  },
  USDSB: {
    Name: 'USDSB',
    Symbol: 'USDSB',
    CoinName: 'USDSB',
    FullName: 'USDSB (USDSB)',
  },
  VINCI: {
    Name: 'VINCI',
    Symbol: 'VINCI',
    CoinName: 'VINCI',
    FullName: 'VINCI (VINCI)',
  },
  WLO: {
    Name: 'WLO',
    Symbol: 'WLO',
    CoinName: 'WOLLO',
    FullName: 'WOLLO (WLO)',
  },
  XENO: {
    Name: 'XENO',
    Symbol: 'XENO',
    CoinName: 'Xenoverse',
    FullName: 'Xenoverse (XENO)',
  },
  NDAU: {
    Name: 'NDAU',
    Symbol: 'NDAU',
    CoinName: 'ndau',
    FullName: 'ndau (NDAU)',
  },
  XSR: {
    Name: 'XSR',
    Symbol: 'XSR',
    CoinName: 'Xensor',
    FullName: 'Xensor (XSR)',
  },
  ZAIF: {
    Name: 'ZAIF',
    Symbol: 'ZAIF',
    CoinName: 'Zaif Token',
    FullName: 'Zaif Token (ZAIF)',
  },
  ETM: {
    Name: 'ETM',
    Symbol: 'ETM',
    CoinName: 'En-Tan-Mo',
    FullName: 'En-Tan-Mo (ETM)',
  },
  FAB: {
    Name: 'FAB',
    Symbol: 'FAB',
    CoinName: 'FABRK Token',
    FullName: 'FABRK Token (FAB)',
  },
  NYE: {
    Name: 'NYE',
    Symbol: 'NYE',
    CoinName: 'NewYork Exchange',
    FullName: 'NewYork Exchange (NYE)',
  },
  STREAM: {
    Name: 'STREAM',
    Symbol: 'STREAM',
    CoinName: 'STREAMIT COIN',
    FullName: 'STREAMIT COIN (STREAM)',
  },
  BPRO: {
    Name: 'BPRO',
    Symbol: 'BPRO',
    CoinName: 'BitCloud Pro',
    FullName: 'BitCloud Pro (BPRO)',
  },
  TOL: {
    Name: 'TOL',
    Symbol: 'TOL',
    CoinName: 'Tolar',
    FullName: 'Tolar (TOL)',
  },
  CVCC: {
    Name: 'CVCC',
    Symbol: 'CVCC',
    CoinName: 'CryptoVerificationCoin',
    FullName: 'CryptoVerificationCoin (CVCC)',
  },
  EVT: {
    Name: 'EVT',
    Symbol: 'EVT',
    CoinName: 'EveriToken',
    FullName: 'EveriToken (EVT)',
  },
  NTBC: {
    Name: 'NTBC',
    Symbol: 'NTBC',
    CoinName: 'Note Blockchain',
    FullName: 'Note Blockchain (NTBC)',
  },
  ATN: {
    Name: 'ATN',
    Symbol: 'ATN',
    CoinName: 'ATN',
    FullName: 'ATN (ATN)',
  },
  NASH: {
    Name: 'NASH',
    Symbol: 'NASH',
    CoinName: 'NeoWorld Cash',
    FullName: 'NeoWorld Cash (NASH)',
  },
  CUST: {
    Name: 'CUST',
    Symbol: 'CUST',
    CoinName: 'Custody Token',
    FullName: 'Custody Token (CUST)',
  },
  QCH: {
    Name: 'QCH',
    Symbol: 'QCH',
    CoinName: 'QChi',
    FullName: 'QChi (QCH)',
  },
  FO: {
    Name: 'FO',
    Symbol: 'FO',
    CoinName: 'FIBOS',
    FullName: 'FIBOS (FO)',
  },
  SON: {
    Name: 'SON',
    Symbol: 'SON',
    CoinName: 'Simone',
    FullName: 'Simone (SON)',
  },
  BQT: {
    Name: 'BQT',
    Symbol: 'BQT',
    CoinName: 'Blockchain Quotations Index Token',
    FullName: 'Blockchain Quotations Index Token (BQT)',
  },
  SDS: {
    Name: 'SDS',
    Symbol: 'SDS',
    CoinName: 'Alchemint Standards',
    FullName: 'Alchemint Standards (SDS)',
  },
  ZT: {
    Name: 'ZT',
    Symbol: 'ZT',
    CoinName: 'ZBG Token',
    FullName: 'ZBG Token (ZT)',
  },
  GOM: {
    Name: 'GOM',
    Symbol: 'GOM',
    CoinName: 'Gomics',
    FullName: 'Gomics (GOM)',
  },
  OF: {
    Name: 'OF',
    Symbol: 'OF',
    CoinName: 'OFCOIN',
    FullName: 'OFCOIN (OF)',
  },
  FLETA: {
    Name: 'FLETA',
    Symbol: 'FLETA',
    CoinName: 'FLETA',
    FullName: 'FLETA (FLETA)',
  },
  CBM: {
    Name: 'CBM',
    Symbol: 'CBM',
    CoinName: 'CryptoBonusMiles',
    FullName: 'CryptoBonusMiles (CBM)',
  },
  EMRX: {
    Name: 'EMRX',
    Symbol: 'EMRX',
    CoinName: 'Emirex Token',
    FullName: 'Emirex Token (EMRX)',
  },
  UC: {
    Name: 'UC',
    Symbol: 'UC',
    CoinName: 'YouLive Coin',
    FullName: 'YouLive Coin (UC)',
  },
  TOSC: {
    Name: 'TOSC',
    Symbol: 'TOSC',
    CoinName: 'T.OS',
    FullName: 'T.OS (TOSC)',
  },
  OVC: {
    Name: 'OVC',
    Symbol: 'OVC',
    CoinName: 'OVCODE',
    FullName: 'OVCODE (OVC)',
  },
  MCC: {
    Name: 'MCC',
    Symbol: 'MCC',
    CoinName: 'Magic Cube Coin',
    FullName: 'Magic Cube Coin (MCC)',
  },
  HVNT: {
    Name: 'HVNT',
    Symbol: 'HVNT',
    CoinName: 'HiveNet Token',
    FullName: 'HiveNet Token (HVNT)',
  },
  MEXC: {
    Name: 'MEXC',
    Symbol: 'MEXC',
    CoinName: 'MEXC Token',
    FullName: 'MEXC Token (MEXC)',
  },
  NSS: {
    Name: 'NSS',
    Symbol: 'NSS',
    CoinName: 'NSS Coin',
    FullName: 'NSS Coin (NSS)',
  },
  TRP: {
    Name: 'TRP',
    Symbol: 'TRP',
    CoinName: 'Tronipay',
    FullName: 'Tronipay (TRP)',
  },
  MB: {
    Name: 'MB',
    Symbol: 'MB',
    CoinName: 'MineBee',
    FullName: 'MineBee (MB)',
  },
  CENT: {
    Name: 'CENT',
    Symbol: 'CENT',
    CoinName: 'CENTERCOIN',
    FullName: 'CENTERCOIN (CENT)',
  },
  MB8: {
    Name: 'MB8',
    Symbol: 'MB8',
    CoinName: 'MB8 Coin',
    FullName: 'MB8 Coin (MB8)',
  },
  HSN: {
    Name: 'HSN',
    Symbol: 'HSN',
    CoinName: 'Hyper Speed Network',
    FullName: 'Hyper Speed Network (HSN)',
  },
  ZUM: {
    Name: 'ZUM',
    Symbol: 'ZUM',
    CoinName: 'ZumCoin',
    FullName: 'ZumCoin (ZUM)',
  },
  PIPL: {
    Name: 'PIPL',
    Symbol: 'PIPL',
    CoinName: 'PiplCoin',
    FullName: 'PiplCoin (PIPL)',
  },
  BNANA: {
    Name: 'BNANA',
    Symbol: 'BNANA',
    CoinName: 'Chimpion',
    FullName: 'Chimpion (BNANA)',
  },
  VNDC: {
    Name: 'VNDC',
    Symbol: 'VNDC',
    CoinName: 'VNDC',
    FullName: 'VNDC (VNDC)',
  },
  GAP: {
    Name: 'GAP',
    Symbol: 'GAP',
    CoinName: 'Gaps Chain',
    FullName: 'Gaps Chain (GAP)',
  },
  DDAM: {
    Name: 'DDAM',
    Symbol: 'DDAM',
    CoinName: 'DDAM',
    FullName: 'DDAM (DDAM)',
  },
  PLAC: {
    Name: 'PLAC',
    Symbol: 'PLAC',
    CoinName: 'PLANET',
    FullName: 'PLANET (PLAC)',
  },
  MOGU: {
    Name: 'MOGU',
    Symbol: 'MOGU',
    CoinName: 'Mogu',
    FullName: 'Mogu (MOGU)',
  },
  CXCELL: {
    Name: 'CXCELL',
    Symbol: 'CXCELL',
    CoinName: 'CAPITAL X CELL',
    FullName: 'CAPITAL X CELL (CXCELL)',
  },
  BGONE: {
    Name: 'BGONE',
    Symbol: 'BGONE',
    CoinName: 'BigONE Token',
    FullName: 'BigONE Token (BGONE)',
  },
  FLAS: {
    Name: 'FLAS',
    Symbol: 'FLAS',
    CoinName: 'Flas Exchange Token',
    FullName: 'Flas Exchange Token (FLAS)',
  },
  DEQ: {
    Name: 'DEQ',
    Symbol: 'DEQ',
    CoinName: 'Dequant',
    FullName: 'Dequant (DEQ)',
  },
  BCB: {
    Name: 'BCB',
    Symbol: 'BCB',
    CoinName: 'BCB Blockchain',
    FullName: 'BCB Blockchain (BCB)',
  },
  LBK: {
    Name: 'LBK',
    Symbol: 'LBK',
    CoinName: 'LBK',
    FullName: 'LBK (LBK)',
  },
  GSTT: {
    Name: 'GSTT',
    Symbol: 'GSTT',
    CoinName: 'GSTT',
    FullName: 'GSTT (GSTT)',
  },
  DMS: {
    Name: 'DMS',
    Symbol: 'DMS',
    CoinName: 'Documentchain',
    FullName: 'Documentchain (DMS)',
  },
  SCTK: {
    Name: 'SCTK',
    Symbol: 'SCTK',
    CoinName: 'SharesChain',
    FullName: 'SharesChain (SCTK)',
  },
  RONCOIN: {
    Name: 'RONCOIN',
    Symbol: 'RONCOIN',
    CoinName: 'RON',
    FullName: 'RON (RONCOIN)',
  },
  SINX: {
    Name: 'SINX',
    Symbol: 'SINX',
    CoinName: 'SINX Token',
    FullName: 'SINX Token (SINX)',
  },
  HAZ: {
    Name: 'HAZ',
    Symbol: 'HAZ',
    CoinName: 'Hazza',
    FullName: 'Hazza (HAZ)',
  },
  AIPE: {
    Name: 'AIPE',
    Symbol: 'AIPE',
    CoinName: 'AI Prediction Ecosystem',
    FullName: 'AI Prediction Ecosystem (AIPE)',
  },
  MISS: {
    Name: 'MISS',
    Symbol: 'MISS',
    CoinName: 'MISS',
    FullName: 'MISS (MISS)',
  },
  DKKT: {
    Name: 'DKKT',
    Symbol: 'DKKT',
    CoinName: 'DKK Token',
    FullName: 'DKK Token (DKKT)',
  },
  BTY: {
    Name: 'BTY',
    Symbol: 'BTY',
    CoinName: 'Bityuan',
    FullName: 'Bityuan (BTY)',
  },
  CSAC: {
    Name: 'CSAC',
    Symbol: 'CSAC',
    CoinName: 'Credit Safe Application Chain',
    FullName: 'Credit Safe Application Chain (CSAC)',
  },
  TEM: {
    Name: 'TEM',
    Symbol: 'TEM',
    CoinName: 'Temtum',
    FullName: 'Temtum (TEM)',
  },
  DMC: {
    Name: 'DMC',
    Symbol: 'DMC',
    CoinName: 'Dream21',
    FullName: 'Dream21 (DMC)',
  },
  CKB: {
    Name: 'CKB',
    Symbol: 'CKB',
    CoinName: 'Nervos Network',
    FullName: 'Nervos Network (CKB)',
  },
  KISC: {
    Name: 'KISC',
    Symbol: 'KISC',
    CoinName: 'Kaiser',
    FullName: 'Kaiser (KISC)',
  },
  VBT: {
    Name: 'VBT',
    Symbol: 'VBT',
    CoinName: 'VB Token',
    FullName: 'VB Token (VBT)',
  },
  G50: {
    Name: 'G50',
    Symbol: 'G50',
    CoinName: 'G50',
    FullName: 'G50 (G50)',
  },
  SEOS: {
    Name: 'SEOS',
    Symbol: 'SEOS',
    CoinName: 'Smart Eye Operating System',
    FullName: 'Smart Eye Operating System (SEOS)',
  },
  ODC: {
    Name: 'ODC',
    Symbol: 'ODC',
    CoinName: 'Overseas Direct Certification',
    FullName: 'Overseas Direct Certification (ODC)',
  },
  GALT: {
    Name: 'GALT',
    Symbol: 'GALT',
    CoinName: 'Galtcoin',
    FullName: 'Galtcoin (GALT)',
  },
  LTBTC: {
    Name: 'LTBTC',
    Symbol: 'LTBTC',
    CoinName: 'Lightning Bitcoin',
    FullName: 'Lightning Bitcoin (LTBTC)',
  },
  XDGB: {
    Name: 'XDGB',
    Symbol: 'XDGB',
    CoinName: 'DigitalBits',
    FullName: 'DigitalBits (XDGB)',
  },
  SPLA: {
    Name: 'SPLA',
    Symbol: 'SPLA',
    CoinName: 'SmartPlay',
    FullName: 'SmartPlay (SPLA)',
  },
  UNICORN: {
    Name: 'UNICORN',
    Symbol: 'UNICORN',
    CoinName: 'UNICORN Token',
    FullName: 'UNICORN Token (UNICORN)',
  },
  EONC: {
    Name: 'EONC',
    Symbol: 'EONC',
    CoinName: 'Dimension',
    FullName: 'Dimension (EONC)',
  },
  PRCM: {
    Name: 'PRCM',
    Symbol: 'PRCM',
    CoinName: 'Precium',
    FullName: 'Precium (PRCM)',
  },
  BFCH: {
    Name: 'BFCH',
    Symbol: 'BFCH',
    CoinName: 'Big Fun Chain',
    FullName: 'Big Fun Chain (BFCH)',
  },
  LIGHT: {
    Name: 'LIGHT',
    Symbol: 'LIGHT',
    CoinName: 'LightChain',
    FullName: 'LightChain (LIGHT)',
  },
  CBFT: {
    Name: 'CBFT',
    Symbol: 'CBFT',
    CoinName: 'CoinBene Future Token',
    FullName: 'CoinBene Future Token (CBFT)',
  },
  TRDS: {
    Name: 'TRDS',
    Symbol: 'TRDS',
    CoinName: 'Traders Token',
    FullName: 'Traders Token (TRDS)',
  },
  ETHPLO: {
    Name: 'ETHPLO',
    Symbol: 'ETHPLO',
    CoinName: 'ETHplode',
    FullName: 'ETHplode (ETHPLO)',
  },
  YAP: {
    Name: 'YAP',
    Symbol: 'YAP',
    CoinName: 'Yap Stone',
    FullName: 'Yap Stone (YAP)',
  },
  LKU: {
    Name: 'LKU',
    Symbol: 'LKU',
    CoinName: 'Lukiu',
    FullName: 'Lukiu (LKU)',
  },
  SUTER: {
    Name: 'SUTER',
    Symbol: 'SUTER',
    CoinName: 'Suterusu',
    FullName: 'Suterusu (SUTER)',
  },
  LINKA: {
    Name: 'LINKA',
    Symbol: 'LINKA',
    CoinName: 'LINKA',
    FullName: 'LINKA (LINKA)',
  },
  ZVC: {
    Name: 'ZVC',
    Symbol: 'ZVC',
    CoinName: 'ZVCHAIN',
    FullName: 'ZVCHAIN (ZVC)',
  },
  OROX: {
    Name: 'OROX',
    Symbol: 'OROX',
    CoinName: 'Cointorox',
    FullName: 'Cointorox (OROX)',
  },
  AITHEON: {
    Name: 'AITHEON',
    Symbol: 'AITHEON',
    CoinName: 'Aitheon',
    FullName: 'Aitheon (AITHEON)',
  },
  OLXA: {
    Name: 'OLXA',
    Symbol: 'OLXA',
    CoinName: 'OLXA',
    FullName: 'OLXA (OLXA)',
  },
  WIX: {
    Name: 'WIX',
    Symbol: 'WIX',
    CoinName: 'Wixlar',
    FullName: 'Wixlar (WIX)',
  },
  YO: {
    Name: 'YO',
    Symbol: 'YO',
    CoinName: 'Yobit Token',
    FullName: 'Yobit Token (YO)',
  },
  BRZE: {
    Name: 'BRZE',
    Symbol: 'BRZE',
    CoinName: 'Breezecoin',
    FullName: 'Breezecoin (BRZE)',
  },
  BCZERO: {
    Name: 'BCZERO',
    Symbol: 'BCZERO',
    CoinName: 'Buggyra Coin Zero',
    FullName: 'Buggyra Coin Zero (BCZERO)',
  },
  ECOREAL: {
    Name: 'ECOREAL',
    Symbol: 'ECOREAL',
    CoinName: 'Ecoreal Estate',
    FullName: 'Ecoreal Estate (ECOREAL)',
  },
  S4F: {
    Name: 'S4F',
    Symbol: 'S4F',
    CoinName: 'S4FE',
    FullName: 'S4FE (S4F)',
  },
  BIPX: {
    Name: 'BIPX',
    Symbol: 'BIPX',
    CoinName: 'Bispex',
    FullName: 'Bispex (BIPX)',
  },
  BOK: {
    Name: 'BOK',
    Symbol: 'BOK',
    CoinName: 'Blockium',
    FullName: 'Blockium (BOK)',
  },
  TEP: {
    Name: 'TEP',
    Symbol: 'TEP',
    CoinName: 'Tepleton',
    FullName: 'Tepleton (TEP)',
  },
  TSR: {
    Name: 'TSR',
    Symbol: 'TSR',
    CoinName: 'Tesra',
    FullName: 'Tesra (TSR)',
  },
  HNB: {
    Name: 'HNB',
    Symbol: 'HNB',
    CoinName: 'HashNet BitEco',
    FullName: 'HashNet BitEco (HNB)',
  },
  DILI: {
    Name: 'DILI',
    Symbol: 'DILI',
    CoinName: 'D Community',
    FullName: 'D Community (DILI)',
  },
  CAI: {
    Name: 'CAI',
    Symbol: 'CAI',
    CoinName: 'Cai Token',
    FullName: 'Cai Token (CAI)',
  },
  FLG: {
    Name: 'FLG',
    Symbol: 'FLG',
    CoinName: 'Folgory Coin',
    FullName: 'Folgory Coin (FLG)',
  },
  MCH: {
    Name: 'MCH',
    Symbol: 'MCH',
    CoinName: 'Meconcash',
    FullName: 'Meconcash (MCH)',
  },
  '7E': {
    Name: '7E',
    Symbol: '7E',
    CoinName: '7ELEVEN',
    FullName: '7ELEVEN (7E)',
  },
  XTX: {
    Name: 'XTX',
    Symbol: 'XTX',
    CoinName: 'Xtock',
    FullName: 'Xtock (XTX)',
  },
  LOBS: {
    Name: 'LOBS',
    Symbol: 'LOBS',
    CoinName: 'Lobstex',
    FullName: 'Lobstex (LOBS)',
  },
  BUXCOIN: {
    Name: 'BUXCOIN',
    Symbol: 'BUXCOIN',
    CoinName: 'Buxcoin',
    FullName: 'Buxcoin (BUXCOIN)',
  },
  MGX: {
    Name: 'MGX',
    Symbol: 'MGX',
    CoinName: 'MargiX',
    FullName: 'MargiX (MGX)',
  },
  DAD: {
    Name: 'DAD',
    Symbol: 'DAD',
    CoinName: 'DAD',
    FullName: 'DAD (DAD)',
  },
  EUM: {
    Name: 'EUM',
    Symbol: 'EUM',
    CoinName: 'Elitium',
    FullName: 'Elitium (EUM)',
  },
  SOVE: {
    Name: 'SOVE',
    Symbol: 'SOVE',
    CoinName: 'Soverain',
    FullName: 'Soverain (SOVE)',
  },
  BCS: {
    Name: 'BCS',
    Symbol: 'BCS',
    CoinName: 'Business Credit Substitute',
    FullName: 'Business Credit Substitute (BCS)',
  },
  THP: {
    Name: 'THP',
    Symbol: 'THP',
    CoinName: 'TurboHigh Performance',
    FullName: 'TurboHigh Performance (THP)',
  },
  TKC: {
    Name: 'TKC',
    Symbol: 'TKC',
    CoinName: 'TurkeyChain',
    FullName: 'TurkeyChain (TKC)',
  },
  LT: {
    Name: 'LT',
    Symbol: 'LT',
    CoinName: 'Loctite Assets Token',
    FullName: 'Loctite Assets Token (LT)',
  },
  MSN: {
    Name: 'MSN',
    Symbol: 'MSN',
    CoinName: 'Manson Coin',
    FullName: 'Manson Coin (MSN)',
  },
  W1: {
    Name: 'W1',
    Symbol: 'W1',
    CoinName: 'W1',
    FullName: 'W1 (W1)',
  },
  OFBC: {
    Name: 'OFBC',
    Symbol: 'OFBC',
    CoinName: 'OneFinBank Coin',
    FullName: 'OneFinBank Coin (OFBC)',
  },
  CB: {
    Name: 'CB',
    Symbol: 'CB',
    CoinName: 'COINBIG',
    FullName: 'COINBIG (CB)',
  },
  TD: {
    Name: 'TD',
    Symbol: 'TD',
    CoinName: 'Trade Chain',
    FullName: 'Trade Chain (TD)',
  },
  DRINK: {
    Name: 'DRINK',
    Symbol: 'DRINK',
    CoinName: 'DrinkChain',
    FullName: 'DrinkChain (DRINK)',
  },
  SNL: {
    Name: 'SNL',
    Symbol: 'SNL',
    CoinName: 'Sport and Leisure',
    FullName: 'Sport and Leisure (SNL)',
  },
  GLOS: {
    Name: 'GLOS',
    Symbol: 'GLOS',
    CoinName: 'GLOS',
    FullName: 'GLOS (GLOS)',
  },
  SEA: {
    Name: 'SEA',
    Symbol: 'SEA',
    CoinName: 'Second Exchange Alliance',
    FullName: 'Second Exchange Alliance (SEA)',
  },
  CBE: {
    Name: 'CBE',
    Symbol: 'CBE',
    CoinName: 'The Chain of Business Entertainment',
    FullName: 'The Chain of Business Entertainment (CBE)',
  },
  DZCC: {
    Name: 'DZCC',
    Symbol: 'DZCC',
    CoinName: 'DZCC',
    FullName: 'DZCC (DZCC)',
  },
  TRCB: {
    Name: 'TRCB',
    Symbol: 'TRCB',
    CoinName: 'TRCB Chain',
    FullName: 'TRCB Chain (TRCB)',
  },
  TROY: {
    Name: 'TROY',
    Symbol: 'TROY',
    CoinName: 'Troy',
    FullName: 'Troy (TROY)',
  },
  MPLUS: {
    Name: 'MPLUS',
    Symbol: 'MPLUS',
    CoinName: 'M+Plus',
    FullName: 'M+Plus (MPLUS)',
  },
  UIN: {
    Name: 'UIN',
    Symbol: 'UIN',
    CoinName: 'Alliance Chain',
    FullName: 'Alliance Chain (UIN)',
  },
  USDN: {
    Name: 'USDN',
    Symbol: 'USDN',
    CoinName: 'Neutrino USD',
    FullName: 'Neutrino USD (USDN)',
  },
  GKI: {
    Name: 'GKI',
    Symbol: 'GKI',
    CoinName: 'GKi',
    FullName: 'GKi (GKI)',
  },
  FBN: {
    Name: 'FBN',
    Symbol: 'FBN',
    CoinName: 'Five balance',
    FullName: 'Five balance (FBN)',
  },
  EVY: {
    Name: 'EVY',
    Symbol: 'EVY',
    CoinName: 'EveryCoin',
    FullName: 'EveryCoin (EVY)',
  },
  PTN: {
    Name: 'PTN',
    Symbol: 'PTN',
    CoinName: 'PalletOneToken',
    FullName: 'PalletOneToken (PTN)',
  },
  TDE: {
    Name: 'TDE',
    Symbol: 'TDE',
    CoinName: 'Trade Ecology Token',
    FullName: 'Trade Ecology Token (TDE)',
  },
  ECP: {
    Name: 'ECP',
    Symbol: 'ECP',
    CoinName: 'ECP+ Technology',
    FullName: 'ECP+ Technology (ECP)',
  },
  XBG: {
    Name: 'XBG',
    Symbol: 'XBG',
    CoinName: 'BitGrin',
    FullName: 'BitGrin (XBG)',
  },
  PP: {
    Name: 'PP',
    Symbol: 'PP',
    CoinName: 'ProducePay Chain',
    FullName: 'ProducePay Chain (PP)',
  },
  CNTM: {
    Name: 'CNTM',
    Symbol: 'CNTM',
    CoinName: 'Connectome',
    FullName: 'Connectome (CNTM)',
  },
  SCAP: {
    Name: 'SCAP',
    Symbol: 'SCAP',
    CoinName: 'SafeCapital',
    FullName: 'SafeCapital (SCAP)',
  },
  FN: {
    Name: 'FN',
    Symbol: 'FN',
    CoinName: 'Filenet',
    FullName: 'Filenet (FN)',
  },
  DYNMT: {
    Name: 'DYNMT',
    Symbol: 'DYNMT',
    CoinName: 'Dynamite',
    FullName: 'Dynamite (DYNMT)',
  },
  MDM: {
    Name: 'MDM',
    Symbol: 'MDM',
    CoinName: 'Medium',
    FullName: 'Medium (MDM)',
  },
  DFP: {
    Name: 'DFP',
    Symbol: 'DFP',
    CoinName: 'Digital Fund Coin',
    FullName: 'Digital Fund Coin (DFP)',
  },
  QTCON: {
    Name: 'QTCON',
    Symbol: 'QTCON',
    CoinName: 'Quiztok',
    FullName: 'Quiztok (QTCON)',
  },
  GTSE: {
    Name: 'GTSE',
    Symbol: 'GTSE',
    CoinName: 'Global Tourism Sharing Ecology',
    FullName: 'Global Tourism Sharing Ecology (GTSE)',
  },
  API: {
    Name: 'API',
    Symbol: 'API',
    CoinName: 'Application Programming Interface',
    FullName: 'Application Programming Interface (API)',
  },
  RES: {
    Name: 'RES',
    Symbol: 'RES',
    CoinName: 'Resistance',
    FullName: 'Resistance (RES)',
  },
  AMAL: {
    Name: 'AMAL',
    Symbol: 'AMAL',
    CoinName: 'AMAL',
    FullName: 'AMAL (AMAL)',
  },
  BIUT: {
    Name: 'BIUT',
    Symbol: 'BIUT',
    CoinName: 'Bit Trust System',
    FullName: 'Bit Trust System (BIUT)',
  },
  MLGC: {
    Name: 'MLGC',
    Symbol: 'MLGC',
    CoinName: 'Marshal Lion Group Coin',
    FullName: 'Marshal Lion Group Coin (MLGC)',
  },
  PSC: {
    Name: 'PSC',
    Symbol: 'PSC',
    CoinName: 'PSC Token',
    FullName: 'PSC Token (PSC)',
  },
  XDC: {
    Name: 'XDC',
    Symbol: 'XDC',
    CoinName: 'Xinfin Network',
    FullName: 'Xinfin Network (XDC)',
  },
  ALN: {
    Name: 'ALN',
    Symbol: 'ALN',
    CoinName: 'Aluna',
    FullName: 'Aluna (ALN)',
  },
  DMTC: {
    Name: 'DMTC',
    Symbol: 'DMTC',
    CoinName: 'Demeter Chain',
    FullName: 'Demeter Chain (DMTC)',
  },
  TFBX: {
    Name: 'TFBX',
    Symbol: 'TFBX',
    CoinName: 'Truefeedback Token',
    FullName: 'Truefeedback Token (TFBX)',
  },
  DAMO: {
    Name: 'DAMO',
    Symbol: 'DAMO',
    CoinName: 'Coinzen',
    FullName: 'Coinzen (DAMO)',
  },
  XSPC: {
    Name: 'XSPC',
    Symbol: 'XSPC',
    CoinName: 'SpectreSecurityCoin',
    FullName: 'SpectreSecurityCoin (XSPC)',
  },
  OXT: {
    Name: 'OXT',
    Symbol: 'OXT',
    CoinName: 'Orchid Protocol',
    FullName: 'Orchid Protocol (OXT)',
  },
  USDG: {
    Name: 'USDG',
    Symbol: 'USDG',
    CoinName: 'USDG',
    FullName: 'USDG (USDG)',
  },
  DGLD: {
    Name: 'DGLD',
    Symbol: 'DGLD',
    CoinName: 'Digital Gold',
    FullName: 'Digital Gold (DGLD)',
  },
  MAP: {
    Name: 'MAP',
    Symbol: 'MAP',
    CoinName: 'MAP Protocol',
    FullName: 'MAP Protocol (MAP)',
  },
  LVIP: {
    Name: 'LVIP',
    Symbol: 'LVIP',
    CoinName: 'Limitless VIP',
    FullName: 'Limitless VIP (LVIP)',
  },
  PLF: {
    Name: 'PLF',
    Symbol: 'PLF',
    CoinName: 'PlayFuel',
    FullName: 'PlayFuel (PLF)',
  },
  CHARS: {
    Name: 'CHARS',
    Symbol: 'CHARS',
    CoinName: 'CHARS',
    FullName: 'CHARS (CHARS)',
  },
  TYT: {
    Name: 'TYT',
    Symbol: 'TYT',
    CoinName: 'Tianya Token',
    FullName: 'Tianya Token (TYT)',
  },
  NVL: {
    Name: 'NVL',
    Symbol: 'NVL',
    CoinName: 'Nevula',
    FullName: 'Nevula (NVL)',
  },
  CSAI: {
    Name: 'CSAI',
    Symbol: 'CSAI',
    CoinName: 'Compound SAI',
    FullName: 'Compound SAI (CSAI)',
  },
  CUSDC: {
    Name: 'CUSDC',
    Symbol: 'CUSDC',
    CoinName: 'Compound USD Coin',
    FullName: 'Compound USD Coin (CUSDC)',
  },
  CBAT: {
    Name: 'CBAT',
    Symbol: 'CBAT',
    CoinName: 'Compound Basic Attention Token',
    FullName: 'Compound Basic Attention Token (CBAT)',
  },
  CZRX: {
    Name: 'CZRX',
    Symbol: 'CZRX',
    CoinName: 'Compound 0x',
    FullName: 'Compound 0x (CZRX)',
  },
  CREP: {
    Name: 'CREP',
    Symbol: 'CREP',
    CoinName: 'Compound Augur',
    FullName: 'Compound Augur (CREP)',
  },
  CETH: {
    Name: 'CETH',
    Symbol: 'CETH',
    CoinName: 'Compound Ethereum',
    FullName: 'Compound Ethereum (CETH)',
  },
  CDAI: {
    Name: 'CDAI',
    Symbol: 'CDAI',
    CoinName: 'Compound Dai',
    FullName: 'Compound Dai (CDAI)',
  },
  FCQ: {
    Name: 'FCQ',
    Symbol: 'FCQ',
    CoinName: 'Fortem Capital',
    FullName: 'Fortem Capital (FCQ)',
  },
  BTCK: {
    Name: 'BTCK',
    Symbol: 'BTCK',
    CoinName: 'Bitcoin Turbo Koin',
    FullName: 'Bitcoin Turbo Koin (BTCK)',
  },
  DAVP: {
    Name: 'DAVP',
    Symbol: 'DAVP',
    CoinName: 'Davion',
    FullName: 'Davion (DAVP)',
  },
  XTP: {
    Name: 'XTP',
    Symbol: 'XTP',
    CoinName: 'Tap',
    FullName: 'Tap (XTP)',
  },
  ZYN: {
    Name: 'ZYN',
    Symbol: 'ZYN',
    CoinName: 'Zynecoin',
    FullName: 'Zynecoin (ZYN)',
  },
  BEPRO: {
    Name: 'BEPRO',
    Symbol: 'BEPRO',
    CoinName: 'BEPRO Network',
    FullName: 'BEPRO Network (BEPRO)',
  },
  CUT: {
    Name: 'CUT',
    Symbol: 'CUT',
    CoinName: 'CUTcoin',
    FullName: 'CUTcoin (CUT)',
  },
  VEN: {
    Name: 'VEN',
    Symbol: 'VEN',
    CoinName: 'VeChain Old',
    FullName: 'VeChain Old (VEN)',
  },
  LTBX: {
    Name: 'LTBX',
    Symbol: 'LTBX',
    CoinName: 'Litbinex Coin',
    FullName: 'Litbinex Coin (LTBX)',
  },
  ZANO: {
    Name: 'ZANO',
    Symbol: 'ZANO',
    CoinName: 'Zano',
    FullName: 'Zano (ZANO)',
  },
  HEX: {
    Name: 'HEX',
    Symbol: 'HEX',
    CoinName: 'HEX',
    FullName: 'HEX (HEX)',
  },
  CYBER: {
    Name: 'CYBER',
    Symbol: 'CYBER',
    CoinName: 'CyberWay',
    FullName: 'CyberWay (CYBER)',
  },
  NERVE: {
    Name: 'NERVE',
    Symbol: 'NERVE',
    CoinName: 'NERVE',
    FullName: 'NERVE (NERVE)',
  },
  RKN: {
    Name: 'RKN',
    Symbol: 'RKN',
    CoinName: 'RAKON',
    FullName: 'RAKON (RKN)',
  },
  KOK: {
    Name: 'KOK',
    Symbol: 'KOK',
    CoinName: 'KOK Coin',
    FullName: 'KOK Coin (KOK)',
  },
  KSH: {
    Name: 'KSH',
    Symbol: 'KSH',
    CoinName: 'Kahsh',
    FullName: 'Kahsh (KSH)',
  },
  HTDF: {
    Name: 'HTDF',
    Symbol: 'HTDF',
    CoinName: 'Orient Walt',
    FullName: 'Orient Walt (HTDF)',
  },
  NESTREE: {
    Name: 'NESTREE',
    Symbol: 'NESTREE',
    CoinName: 'Nestree',
    FullName: 'Nestree (NESTREE)',
  },
  KRT: {
    Name: 'KRT',
    Symbol: 'KRT',
    CoinName: 'TerraKRW',
    FullName: 'TerraKRW (KRT)',
  },
  N8V: {
    Name: 'N8V',
    Symbol: 'N8V',
    CoinName: 'NativeCoin',
    FullName: 'NativeCoin (N8V)',
  },
  EBK: {
    Name: 'EBK',
    Symbol: 'EBK',
    CoinName: 'Ebakus',
    FullName: 'Ebakus (EBK)',
  },
  PEG: {
    Name: 'PEG',
    Symbol: 'PEG',
    CoinName: 'PegNet',
    FullName: 'PegNet (PEG)',
  },
  ERK: {
    Name: 'ERK',
    Symbol: 'ERK',
    CoinName: 'Eureka Coin',
    FullName: 'Eureka Coin (ERK)',
  },
  BNP: {
    Name: 'BNP',
    Symbol: 'BNP',
    CoinName: 'BenePit',
    FullName: 'BenePit (BNP)',
  },
  TUDA: {
    Name: 'TUDA',
    Symbol: 'TUDA',
    CoinName: "Tutor's Diary",
    FullName: "Tutor's Diary (TUDA)",
  },
  BLTV: {
    Name: 'BLTV',
    Symbol: 'BLTV',
    CoinName: 'BLTV Token',
    FullName: 'BLTV Token (BLTV)',
  },
  APIX: {
    Name: 'APIX',
    Symbol: 'APIX',
    CoinName: 'APIX',
    FullName: 'APIX (APIX)',
  },
  RRB: {
    Name: 'RRB',
    Symbol: 'RRB',
    CoinName: 'Renrenbit',
    FullName: 'Renrenbit (RRB)',
  },
  MESH: {
    Name: 'MESH',
    Symbol: 'MESH',
    CoinName: 'MeshBox',
    FullName: 'MeshBox (MESH)',
  },
  WIKI: {
    Name: 'WIKI',
    Symbol: 'WIKI',
    CoinName: 'Wiki Token',
    FullName: 'Wiki Token (WIKI)',
  },
  HINT: {
    Name: 'HINT',
    Symbol: 'HINT',
    CoinName: 'Hintchain',
    FullName: 'Hintchain (HINT)',
  },
  PCI: {
    Name: 'PCI',
    Symbol: 'PCI',
    CoinName: 'PayProtocol Paycoin',
    FullName: 'PayProtocol Paycoin (PCI)',
  },
  BNA: {
    Name: 'BNA',
    Symbol: 'BNA',
    CoinName: 'BananaTok',
    FullName: 'BananaTok (BNA)',
  },
  AXIAL: {
    Name: 'AXIAL',
    Symbol: 'AXIAL',
    CoinName: 'AXiaL',
    FullName: 'AXiaL (AXIAL)',
  },
  GOD: {
    Name: 'GOD',
    Symbol: 'GOD',
    CoinName: 'Bitcoin God',
    FullName: 'Bitcoin God (GOD)',
  },
  ALY: {
    Name: 'ALY',
    Symbol: 'ALY',
    CoinName: 'Ally',
    FullName: 'Ally (ALY)',
  },
  SPOK: {
    Name: 'SPOK',
    Symbol: 'SPOK',
    CoinName: 'Spock',
    FullName: 'Spock (SPOK)',
  },
  USDH: {
    Name: 'USDH',
    Symbol: 'USDH',
    CoinName: 'HonestCoin',
    FullName: 'HonestCoin (USDH)',
  },
  KDA: {
    Name: 'KDA',
    Symbol: 'KDA',
    CoinName: 'Kadena',
    FullName: 'Kadena (KDA)',
  },
  GARK: {
    Name: 'GARK',
    Symbol: 'GARK',
    CoinName: 'Game Ark',
    FullName: 'Game Ark (GARK)',
  },
  IDHUB: {
    Name: 'IDHUB',
    Symbol: 'IDHUB',
    CoinName: 'IDHUB',
    FullName: 'IDHUB (IDHUB)',
  },
  IOEX: {
    Name: 'IOEX',
    Symbol: 'IOEX',
    CoinName: 'ioeX',
    FullName: 'ioeX (IOEX)',
  },
  LM: {
    Name: 'LM',
    Symbol: 'LM',
    CoinName: 'LM Token',
    FullName: 'LM Token (LM)',
  },
  BIKI: {
    Name: 'BIKI',
    Symbol: 'BIKI',
    CoinName: 'BIKI',
    FullName: 'BIKI (BIKI)',
  },
  DLX: {
    Name: 'DLX',
    Symbol: 'DLX',
    CoinName: 'DAppLinks',
    FullName: 'DAppLinks (DLX)',
  },
  DALI: {
    Name: 'DALI',
    Symbol: 'DALI',
    CoinName: 'Dalichain',
    FullName: 'Dalichain (DALI)',
  },
  FLDT: {
    Name: 'FLDT',
    Symbol: 'FLDT',
    CoinName: 'FairyLand',
    FullName: 'FairyLand (FLDT)',
  },
  TCO: {
    Name: 'TCO',
    Symbol: 'TCO',
    CoinName: 'ThinkCoin',
    FullName: 'ThinkCoin (TCO)',
  },
  ETY: {
    Name: 'ETY',
    Symbol: 'ETY',
    CoinName: 'Ethereum Cloud',
    FullName: 'Ethereum Cloud (ETY)',
  },
  LBXC: {
    Name: 'LBXC',
    Symbol: 'LBXC',
    CoinName: 'LUX BIO EXCHANGE COIN',
    FullName: 'LUX BIO EXCHANGE COIN (LBXC)',
  },
  JOB: {
    Name: 'JOB',
    Symbol: 'JOB',
    CoinName: 'Jobchain',
    FullName: 'Jobchain (JOB)',
  },
  VEIL: {
    Name: 'VEIL',
    Symbol: 'VEIL',
    CoinName: 'VEIL',
    FullName: 'VEIL (VEIL)',
  },
  BTBL: {
    Name: 'BTBL',
    Symbol: 'BTBL',
    CoinName: 'Bitball',
    FullName: 'Bitball (BTBL)',
  },
  MKEY: {
    Name: 'MKEY',
    Symbol: 'MKEY',
    CoinName: 'MEDIKEY',
    FullName: 'MEDIKEY (MKEY)',
  },
  TAUC: {
    Name: 'TAUC',
    Symbol: 'TAUC',
    CoinName: 'Taurus Coin',
    FullName: 'Taurus Coin (TAUC)',
  },
  GIB: {
    Name: 'GIB',
    Symbol: 'GIB',
    CoinName: 'Bible Coin',
    FullName: 'Bible Coin (GIB)',
  },
  SCDS: {
    Name: 'SCDS',
    Symbol: 'SCDS',
    CoinName: 'Shrine Cloud Storage Network',
    FullName: 'Shrine Cloud Storage Network (SCDS)',
  },
  CCX: {
    Name: 'CCX',
    Symbol: 'CCX',
    CoinName: 'Conceal',
    FullName: 'Conceal (CCX)',
  },
  SYM: {
    Name: 'SYM',
    Symbol: 'SYM',
    CoinName: 'SymVerse',
    FullName: 'SymVerse (SYM)',
  },
  ECT: {
    Name: 'ECT',
    Symbol: 'ECT',
    CoinName: 'SuperEdge',
    FullName: 'SuperEdge (ECT)',
  },
  GIX: {
    Name: 'GIX',
    Symbol: 'GIX',
    CoinName: 'GoldFinX',
    FullName: 'GoldFinX (GIX)',
  },
  SENNO: {
    Name: 'SENNO',
    Symbol: 'SENNO',
    CoinName: 'SENNO',
    FullName: 'SENNO (SENNO)',
  },
  BEP: {
    Name: 'BEP',
    Symbol: 'BEP',
    CoinName: 'Blucon',
    FullName: 'Blucon (BEP)',
  },
  GANA: {
    Name: 'GANA',
    Symbol: 'GANA',
    CoinName: 'GANA',
    FullName: 'GANA (GANA)',
  },
  KAL: {
    Name: 'KAL',
    Symbol: 'KAL',
    CoinName: 'Kaleido',
    FullName: 'Kaleido (KAL)',
  },
  FNK: {
    Name: 'FNK',
    Symbol: 'FNK',
    CoinName: 'FunKeyPay',
    FullName: 'FunKeyPay (FNK)',
  },
  TSF: {
    Name: 'TSF',
    Symbol: 'TSF',
    CoinName: 'Transaction Service Fee',
    FullName: 'Transaction Service Fee (TSF)',
  },
  AIDUS: {
    Name: 'AIDUS',
    Symbol: 'AIDUS',
    CoinName: 'AIDUS Token',
    FullName: 'AIDUS Token (AIDUS)',
  },
  STEEP: {
    Name: 'STEEP',
    Symbol: 'STEEP',
    CoinName: 'SteepCoin',
    FullName: 'SteepCoin (STEEP)',
  },
  ZOC: {
    Name: 'ZOC',
    Symbol: 'ZOC',
    CoinName: '01coin',
    FullName: '01coin (ZOC)',
  },
  YTN: {
    Name: 'YTN',
    Symbol: 'YTN',
    CoinName: 'YENTEN',
    FullName: 'YENTEN (YTN)',
  },
  SCRIV: {
    Name: 'SCRIV',
    Symbol: 'SCRIV',
    CoinName: 'SCRIV',
    FullName: 'SCRIV (SCRIV)',
  },
  AREPA: {
    Name: 'AREPA',
    Symbol: 'AREPA',
    CoinName: 'Arepacoin',
    FullName: 'Arepacoin (AREPA)',
  },
  CHEESE: {
    Name: 'CHEESE',
    Symbol: 'CHEESE',
    CoinName: 'CHEESE',
    FullName: 'CHEESE (CHEESE)',
  },
  PEPS: {
    Name: 'PEPS',
    Symbol: 'PEPS',
    CoinName: 'PEPS Coin',
    FullName: 'PEPS Coin (PEPS)',
  },
  NEETCOIN: {
    Name: 'NEETCOIN',
    Symbol: 'NEETCOIN',
    CoinName: 'Neetcoin',
    FullName: 'Neetcoin (NEETCOIN)',
  },
  OMEGA: {
    Name: 'OMEGA',
    Symbol: 'OMEGA',
    CoinName: 'OMEGA',
    FullName: 'OMEGA (OMEGA)',
  },
  BBS: {
    Name: 'BBS (1)',
    Symbol: 'BBS',
    CoinName: 'BBSCoin',
    FullName: 'BBSCoin (BBS (1))',
  },
  BZL: {
    Name: 'BZL',
    Symbol: 'BZL',
    CoinName: 'BZLCoin',
    FullName: 'BZLCoin (BZL)',
  },
  CRANEPAY: {
    Name: 'CRANEPAY',
    Symbol: 'CRANEPAY',
    CoinName: 'Cranepay',
    FullName: 'Cranepay (CRANEPAY)',
  },
  GSR: {
    Name: 'GSR',
    Symbol: 'GSR',
    CoinName: 'GeyserCoin',
    FullName: 'GeyserCoin (GSR)',
  },
  VARIUS: {
    Name: 'VARIUS',
    Symbol: 'VARIUS',
    CoinName: 'Varius',
    FullName: 'Varius (VARIUS)',
  },
  AGET: {
    Name: 'AGET',
    Symbol: 'AGET',
    CoinName: 'Agetron',
    FullName: 'Agetron (AGET)',
  },
  WRX: {
    Name: 'WRX',
    Symbol: 'WRX',
    CoinName: 'WazirX',
    FullName: 'WazirX (WRX)',
  },
  ZCR: {
    Name: 'ZCR',
    Symbol: 'ZCR',
    CoinName: 'ZCore',
    FullName: 'ZCore (ZCR)',
  },
  AEVO: {
    Name: 'AEVO',
    Symbol: 'AEVO',
    CoinName: 'Always Evolving',
    FullName: 'Always Evolving (AEVO)',
  },
  NAH: {
    Name: 'NAH',
    Symbol: 'NAH',
    CoinName: 'Strayacoin',
    FullName: 'Strayacoin (NAH)',
  },
  EGEM: {
    Name: 'EGEM',
    Symbol: 'EGEM',
    CoinName: 'EtherGem',
    FullName: 'EtherGem (EGEM)',
  },
  DXO: {
    Name: 'DXO',
    Symbol: 'DXO',
    CoinName: 'Dextro',
    FullName: 'Dextro (DXO)',
  },
  GOSS: {
    Name: 'GOSS',
    Symbol: 'GOSS',
    CoinName: 'GOSSIP-Coin',
    FullName: 'GOSSIP-Coin (GOSS)',
  },
  NYEX: {
    Name: 'NYEX',
    Symbol: 'NYEX',
    CoinName: 'Nyerium',
    FullName: 'Nyerium (NYEX)',
  },
  GIO: {
    Name: 'GIO',
    Symbol: 'GIO',
    CoinName: 'Graviocoin',
    FullName: 'Graviocoin (GIO)',
  },
  TELOS: {
    Name: 'TELOS',
    Symbol: 'TELOS',
    CoinName: 'Teloscoin',
    FullName: 'Teloscoin (TELOS)',
  },
  SIERRA: {
    Name: 'SIERRA',
    Symbol: 'SIERRA',
    CoinName: 'Sierracoin',
    FullName: 'Sierracoin (SIERRA)',
  },
  VIVID: {
    Name: 'VIVID',
    Symbol: 'VIVID',
    CoinName: 'Vivid Coin',
    FullName: 'Vivid Coin (VIVID)',
  },
  RPD: {
    Name: 'RPD',
    Symbol: 'RPD',
    CoinName: 'Rapids',
    FullName: 'Rapids (RPD)',
  },
  PENG: {
    Name: 'PENG',
    Symbol: 'PENG',
    CoinName: 'PENG',
    FullName: 'PENG (PENG)',
  },
  MERI: {
    Name: 'MERI',
    Symbol: 'MERI',
    CoinName: 'Merebel',
    FullName: 'Merebel (MERI)',
  },
  KTS: {
    Name: 'KTS',
    Symbol: 'KTS',
    CoinName: 'Klimatas',
    FullName: 'Klimatas (KTS)',
  },
  NOR: {
    Name: 'NOR',
    Symbol: 'NOR',
    CoinName: 'Noir',
    FullName: 'Noir (NOR)',
  },
  BITCOINV: {
    Name: 'BITCOINV',
    Symbol: 'BITCOINV',
    CoinName: 'BitcoinV',
    FullName: 'BitcoinV (BITCOINV)',
  },
  X42: {
    Name: 'X42',
    Symbol: 'X42',
    CoinName: 'X42 Protocol',
    FullName: 'X42 Protocol (X42)',
  },
  XWP: {
    Name: 'XWP',
    Symbol: 'XWP',
    CoinName: 'Swap',
    FullName: 'Swap (XWP)',
  },
  CSNP: {
    Name: 'CSNP',
    Symbol: 'CSNP',
    CoinName: 'CrowdSale Network',
    FullName: 'CrowdSale Network (CSNP)',
  },
  CALL: {
    Name: 'CALL',
    Symbol: 'CALL',
    CoinName: 'Global Crypto Alliance',
    FullName: 'Global Crypto Alliance (CALL)',
  },
  MOCO: {
    Name: 'MOCO',
    Symbol: 'MOCO',
    CoinName: 'MoCo',
    FullName: 'MoCo (MOCO)',
  },
  WBET: {
    Name: 'WBET',
    Symbol: 'WBET',
    CoinName: 'Wavesbet',
    FullName: 'Wavesbet (WBET)',
  },
  ARMR: {
    Name: 'ARMR',
    Symbol: 'ARMR',
    CoinName: 'ARMR',
    FullName: 'ARMR (ARMR)',
  },
  XSD: {
    Name: 'XSD',
    Symbol: 'XSD',
    CoinName: 'SounDAC',
    FullName: 'SounDAC (XSD)',
  },
  DIVO: {
    Name: 'DIVO',
    Symbol: 'DIVO',
    CoinName: 'DIVO Token',
    FullName: 'DIVO Token (DIVO)',
  },
  WCC: {
    Name: 'WCC',
    Symbol: 'WCC',
    CoinName: 'Wincash Coin',
    FullName: 'Wincash Coin (WCC)',
  },
  HUSL: {
    Name: 'HUSL',
    Symbol: 'HUSL',
    CoinName: 'Hustle Token',
    FullName: 'Hustle Token (HUSL)',
  },
  WLF: {
    Name: 'WLF',
    Symbol: 'WLF',
    CoinName: 'Wolfs Group',
    FullName: 'Wolfs Group (WLF)',
  },
  CNB: {
    Name: 'CNB',
    Symbol: 'CNB',
    CoinName: 'Coinsbit Token',
    FullName: 'Coinsbit Token (CNB)',
  },
  CTAG: {
    Name: 'CTAG',
    Symbol: 'CTAG',
    CoinName: 'CTAGtoken',
    FullName: 'CTAGtoken (CTAG)',
  },
  CWN: {
    Name: 'CWN',
    Symbol: 'CWN',
    CoinName: 'CryptoWorldNews',
    FullName: 'CryptoWorldNews (CWN)',
  },
  HNS: {
    Name: 'HNS',
    Symbol: 'HNS',
    CoinName: 'Handshake',
    FullName: 'Handshake (HNS)',
  },
  BLINK: {
    Name: 'BLINK',
    Symbol: 'BLINK',
    CoinName: 'BlockMason Link',
    FullName: 'BlockMason Link (BLINK)',
  },
  JUL: {
    Name: 'JUL',
    Symbol: 'JUL',
    CoinName: 'Joule',
    FullName: 'Joule (JUL)',
  },
  BC: {
    Name: 'BC',
    Symbol: 'BC',
    CoinName: 'Bitcoin Confidential',
    FullName: 'Bitcoin Confidential (BC)',
  },
  NCOV: {
    Name: 'NCOV',
    Symbol: 'NCOV',
    CoinName: 'CoronaCoin',
    FullName: 'CoronaCoin (NCOV)',
  },
  EER: {
    Name: 'EER',
    Symbol: 'EER',
    CoinName: 'Ethereum eRush',
    FullName: 'Ethereum eRush (EER)',
  },
  USDA: {
    Name: 'USDA',
    Symbol: 'USDA',
    CoinName: 'USDA',
    FullName: 'USDA (USDA)',
  },
  DBY: {
    Name: 'DBY',
    Symbol: 'DBY',
    CoinName: 'Dobuy',
    FullName: 'Dobuy (DBY)',
  },
  KAM: {
    Name: 'KAM',
    Symbol: 'KAM',
    CoinName: 'BitKAM',
    FullName: 'BitKAM (KAM)',
  },
  EBASE: {
    Name: 'EBASE',
    Symbol: 'EBASE',
    CoinName: 'EURBASE',
    FullName: 'EURBASE (EBASE)',
  },
  SWYFTT: {
    Name: 'SWYFTT',
    Symbol: 'SWYFTT',
    CoinName: 'SWYFT',
    FullName: 'SWYFT (SWYFTT)',
  },
  DOGZ: {
    Name: 'DOGZ',
    Symbol: 'DOGZ',
    CoinName: 'Dogz',
    FullName: 'Dogz (DOGZ)',
  },
  GFUN: {
    Name: 'GFUN',
    Symbol: 'GFUN',
    CoinName: 'GoldFund',
    FullName: 'GoldFund (GFUN)',
  },
  NWC: {
    Name: 'NWC',
    Symbol: 'NWC',
    CoinName: 'Newscrypto Coin',
    FullName: 'Newscrypto Coin (NWC)',
  },
  JMT: {
    Name: 'JMT',
    Symbol: 'JMT',
    CoinName: 'JMTIME',
    FullName: 'JMTIME (JMT)',
  },
  ABA: {
    Name: 'ABA',
    Symbol: 'ABA',
    CoinName: 'EcoBall',
    FullName: 'EcoBall (ABA)',
  },
  DSC: {
    Name: 'DSC',
    Symbol: 'DSC',
    CoinName: 'Dash Cash',
    FullName: 'Dash Cash (DSC)',
  },
  NAX: {
    Name: 'NAX',
    Symbol: 'NAX',
    CoinName: 'NextDAO',
    FullName: 'NextDAO (NAX)',
  },
  DAPP: {
    Name: 'DAPP',
    Symbol: 'DAPP',
    CoinName: 'LiquidApps',
    FullName: 'LiquidApps (DAPP)',
  },
  AMDC: {
    Name: 'AMDC',
    Symbol: 'AMDC',
    CoinName: 'Allmedi Coin',
    FullName: 'Allmedi Coin (AMDC)',
  },
  NNC: {
    Name: 'NNC',
    Symbol: 'NNC',
    CoinName: 'NEO Name Credit',
    FullName: 'NEO Name Credit (NNC)',
  },
  CCTN: {
    Name: 'CCTN',
    Symbol: 'CCTN',
    CoinName: 'Connectchain',
    FullName: 'Connectchain (CCTN)',
  },
  TWEE: {
    Name: 'TWEE',
    Symbol: 'TWEE',
    CoinName: 'TWEEBAA',
    FullName: 'TWEEBAA (TWEE)',
  },
  KBOT: {
    Name: 'KBOT',
    Symbol: 'KBOT',
    CoinName: 'Korbot',
    FullName: 'Korbot (KBOT)',
  },
  EUSD: {
    Name: 'EUSD',
    Symbol: 'EUSD',
    CoinName: 'Egoras Dollar',
    FullName: 'Egoras Dollar (EUSD)',
  },
  ALLBI: {
    Name: 'ALLBI',
    Symbol: 'ALLBI',
    CoinName: 'ALL BEST ICO',
    FullName: 'ALL BEST ICO (ALLBI)',
  },
  ETR: {
    Name: 'ETR',
    Symbol: 'ETR',
    CoinName: 'Electric Token',
    FullName: 'Electric Token (ETR)',
  },
  FK: {
    Name: 'FK',
    Symbol: 'FK',
    CoinName: 'FK Coin',
    FullName: 'FK Coin (FK)',
  },
  SOLO: {
    Name: 'SOLO',
    Symbol: 'SOLO',
    CoinName: 'Sologenic',
    FullName: 'Sologenic (SOLO)',
  },
  ERG: {
    Name: 'ERG',
    Symbol: 'ERG',
    CoinName: 'Ergo',
    FullName: 'Ergo (ERG)',
  },
  BIRDCHAIN: {
    Name: 'BIRDCHAIN',
    Symbol: 'BIRDCHAIN',
    CoinName: 'Birdchain',
    FullName: 'Birdchain (BIRDCHAIN)',
  },
  TCC: {
    Name: 'TCC',
    Symbol: 'TCC',
    CoinName: 'The ChampCoin',
    FullName: 'The ChampCoin (TCC)',
  },
  AUNIT: {
    Name: 'AUNIT',
    Symbol: 'AUNIT',
    CoinName: 'Aunit',
    FullName: 'Aunit (AUNIT)',
  },
  ODX: {
    Name: 'ODX',
    Symbol: 'ODX',
    CoinName: 'ODX Token',
    FullName: 'ODX Token (ODX)',
  },
  XHT: {
    Name: 'XHT',
    Symbol: 'XHT',
    CoinName: 'HollaEx',
    FullName: 'HollaEx (XHT)',
  },
  TRYB: {
    Name: 'TRYB',
    Symbol: 'TRYB',
    CoinName: 'BiLira',
    FullName: 'BiLira (TRYB)',
  },
  GOLOSBLOCKCHAIN: {
    Name: 'GOLOSBLOCKCHAIN',
    Symbol: 'GOLOSBLOCKCHAIN',
    CoinName: 'Golos Blockchain',
    FullName: 'Golos Blockchain (GOLOSBLOCKCHAIN)',
  },
  INS: {
    Name: 'INS',
    Symbol: 'INS',
    CoinName: 'Insolar (Old Chain)',
    FullName: 'Insolar (Old Chain) (INS)',
  },
  AAB: {
    Name: 'AAB',
    Symbol: 'AAB',
    CoinName: 'AAX Token',
    FullName: 'AAX Token (AAB)',
  },
  BKRW: {
    Name: 'BKRW',
    Symbol: 'BKRW',
    CoinName: 'Binance KRW',
    FullName: 'Binance KRW (BKRW)',
  },
  HBD: {
    Name: 'HBD',
    Symbol: 'HBD',
    CoinName: 'Hive Dollar',
    FullName: 'Hive Dollar (HBD)',
  },
  FRSP: {
    Name: 'FRSP',
    Symbol: 'FRSP',
    CoinName: 'Forkspot',
    FullName: 'Forkspot (FRSP)',
  },
  ELAMA: {
    Name: 'ELAMA',
    Symbol: 'ELAMA',
    CoinName: 'Elamachain',
    FullName: 'Elamachain (ELAMA)',
  },
  GOM2: {
    Name: 'GOM2',
    Symbol: 'GOM2',
    CoinName: 'GoMoney2',
    FullName: 'GoMoney2 (GOM2)',
  },
  ANCT: {
    Name: 'ANCT',
    Symbol: 'ANCT',
    CoinName: 'Anchor',
    FullName: 'Anchor (ANCT)',
  },
  USDJ: {
    Name: 'USDJ',
    Symbol: 'USDJ',
    CoinName: 'USDJ',
    FullName: 'USDJ (USDJ)',
  },
  QC: {
    Name: 'QC',
    Symbol: 'QC',
    CoinName: 'Qcash',
    FullName: 'Qcash (QC)',
  },
  ALV: {
    Name: 'ALV',
    Symbol: 'ALV',
    CoinName: 'Allive',
    FullName: 'Allive (ALV)',
  },
  GZE: {
    Name: 'GZE',
    Symbol: 'GZE',
    CoinName: 'GazeCoin',
    FullName: 'GazeCoin (GZE)',
  },
  DACS: {
    Name: 'DACS',
    Symbol: 'DACS',
    CoinName: 'Dacsee',
    FullName: 'Dacsee (DACS)',
  },
  NII: {
    Name: 'NII',
    Symbol: 'NII',
    CoinName: 'nahmii',
    FullName: 'nahmii (NII)',
  },
  UPEUR: {
    Name: 'UPEUR',
    Symbol: 'UPEUR',
    CoinName: 'Universal Euro',
    FullName: 'Universal Euro (UPEUR)',
  },
  UPUSD: {
    Name: 'UPUSD',
    Symbol: 'UPUSD',
    CoinName: 'Universal US Dollar',
    FullName: 'Universal US Dollar (UPUSD)',
  },
  UPT: {
    Name: 'UPT',
    Symbol: 'UPT',
    CoinName: 'Universal Protocol Token',
    FullName: 'Universal Protocol Token (UPT)',
  },
  CIM: {
    Name: 'CIM',
    Symbol: 'CIM',
    CoinName: 'COINCOME',
    FullName: 'COINCOME (CIM)',
  },
  EWT: {
    Name: 'EWT',
    Symbol: 'EWT',
    CoinName: 'Energy Web Token',
    FullName: 'Energy Web Token (EWT)',
  },
  HYPERD: {
    Name: 'HYPERD',
    Symbol: 'HYPERD',
    CoinName: 'HyperDAO',
    FullName: 'HyperDAO (HYPERD)',
  },
  BTCV: {
    Name: 'BTCV',
    Symbol: 'BTCV',
    CoinName: 'Bitcoin Vault',
    FullName: 'Bitcoin Vault (BTCV)',
  },
  HIVE: {
    Name: 'HIVE',
    Symbol: 'HIVE',
    CoinName: 'Hive',
    FullName: 'Hive (HIVE)',
  },
  ARE: {
    Name: 'ARE',
    Symbol: 'ARE',
    CoinName: 'Aurei',
    FullName: 'Aurei (ARE)',
  },
  COSP: {
    Name: 'COSP',
    Symbol: 'COSP',
    CoinName: 'Cosplay Token',
    FullName: 'Cosplay Token (COSP)',
  },
  HOTT: {
    Name: 'HOTT',
    Symbol: 'HOTT',
    CoinName: 'HOT Token',
    FullName: 'HOT Token (HOTT)',
  },
  MZK: {
    Name: 'MZK',
    Symbol: 'MZK',
    CoinName: 'Muzika Network',
    FullName: 'Muzika Network (MZK)',
  },
  QUROZ: {
    Name: 'QUROZ',
    Symbol: 'QUROZ',
    CoinName: 'Qfora',
    FullName: 'Qfora (QUROZ)',
  },
  HUNT: {
    Name: 'HUNT',
    Symbol: 'HUNT',
    CoinName: 'HUNT',
    FullName: 'HUNT (HUNT)',
  },
  VNXLU: {
    Name: 'VNXLU',
    Symbol: 'VNXLU',
    CoinName: 'VNX Exchange',
    FullName: 'VNX Exchange (VNXLU)',
  },
  AIN: {
    Name: 'AIN',
    Symbol: 'AIN',
    CoinName: 'AI Network',
    FullName: 'AI Network (AIN)',
  },
  '300F': {
    Name: '300F',
    Symbol: '300F',
    CoinName: '300FIT',
    FullName: '300FIT (300F)',
  },
  HKDX: {
    Name: 'HKDX',
    Symbol: 'HKDX',
    CoinName: 'eToro Hong Kong Dollar',
    FullName: 'eToro Hong Kong Dollar (HKDX)',
  },
  CNYX: {
    Name: 'CNYX',
    Symbol: 'CNYX',
    CoinName: 'eToro Chinese Yuan',
    FullName: 'eToro Chinese Yuan (CNYX)',
  },
  NZDX: {
    Name: 'NZDX',
    Symbol: 'NZDX',
    CoinName: 'eToro New Zealand Dollar',
    FullName: 'eToro New Zealand Dollar (NZDX)',
  },
  EURX: {
    Name: 'EURX',
    Symbol: 'EURX',
    CoinName: 'eToro Euro',
    FullName: 'eToro Euro (EURX)',
  },
  CHFX: {
    Name: 'CHFX',
    Symbol: 'CHFX',
    CoinName: 'eToro Swiss Franc',
    FullName: 'eToro Swiss Franc (CHFX)',
  },
  CADX: {
    Name: 'CADX',
    Symbol: 'CADX',
    CoinName: 'eToro Canadian Dollar',
    FullName: 'eToro Canadian Dollar (CADX)',
  },
  USDEX: {
    Name: 'USDEX',
    Symbol: 'USDEX',
    CoinName: 'eToro US Dollar',
    FullName: 'eToro US Dollar (USDEX)',
  },
  JPYX: {
    Name: 'JPYX',
    Symbol: 'JPYX',
    CoinName: 'eToro Japanese Yen',
    FullName: 'eToro Japanese Yen (JPYX)',
  },
  AUDX: {
    Name: 'AUDX',
    Symbol: 'AUDX',
    CoinName: 'eToro Australian Dollar',
    FullName: 'eToro Australian Dollar (AUDX)',
  },
  GOLDX: {
    Name: 'GOLDX',
    Symbol: 'GOLDX',
    CoinName: 'eToro Gold',
    FullName: 'eToro Gold (GOLDX)',
  },
  ZARX: {
    Name: 'ZARX',
    Symbol: 'ZARX',
    CoinName: 'eToro South African Rand',
    FullName: 'eToro South African Rand (ZARX)',
  },
  TRYX: {
    Name: 'TRYX',
    Symbol: 'TRYX',
    CoinName: 'eToro Turkish Lira',
    FullName: 'eToro Turkish Lira (TRYX)',
  },
  SGDX: {
    Name: 'SGDX',
    Symbol: 'SGDX',
    CoinName: 'eToro Singapore Dollar',
    FullName: 'eToro Singapore Dollar (SGDX)',
  },
  RUBX: {
    Name: 'RUBX',
    Symbol: 'RUBX',
    CoinName: 'eToro Russian Ruble',
    FullName: 'eToro Russian Ruble (RUBX)',
  },
  POLNX: {
    Name: 'POLNX',
    Symbol: 'POLNX',
    CoinName: 'eToro Polish Zloty',
    FullName: 'eToro Polish Zloty (POLNX)',
  },
  BCHC: {
    Name: 'BCHC',
    Symbol: 'BCHC',
    CoinName: 'BitCherry',
    FullName: 'BitCherry (BCHC)',
  },
  SLVX: {
    Name: 'SLVX',
    Symbol: 'SLVX',
    CoinName: 'eToro Silver',
    FullName: 'eToro Silver (SLVX)',
  },
  CTT: {
    Name: 'CTT',
    Symbol: 'CTT',
    CoinName: 'Castweet',
    FullName: 'Castweet (CTT)',
  },
  KDG: {
    Name: 'KDG',
    Symbol: 'KDG',
    CoinName: 'Kingdom Game 4.0',
    FullName: 'Kingdom Game 4.0 (KDG)',
  },
  HMR: {
    Name: 'HMR',
    Symbol: 'HMR',
    CoinName: 'Homeros',
    FullName: 'Homeros (HMR)',
  },
  KIM: {
    Name: 'KIM',
    Symbol: 'KIM',
    CoinName: 'King Money',
    FullName: 'King Money (KIM)',
  },
  LMCH: {
    Name: 'LMCH',
    Symbol: 'LMCH',
    CoinName: 'Latamcash',
    FullName: 'Latamcash (LMCH)',
  },
  SNB: {
    Name: 'SNB',
    Symbol: 'SNB',
    CoinName: 'SynchroBitcoin',
    FullName: 'SynchroBitcoin (SNB)',
  },
  CBUCKS: {
    Name: 'CBUCKS',
    Symbol: 'CBUCKS',
    CoinName: 'CRYPTOBUCKS',
    FullName: 'CRYPTOBUCKS (CBUCKS)',
  },
  LAR: {
    Name: 'LAR',
    Symbol: 'LAR',
    CoinName: 'LinkArt',
    FullName: 'LinkArt (LAR)',
  },
  EUCOIN: {
    Name: 'EUCOIN',
    Symbol: 'EUCOIN',
    CoinName: 'EU Coin',
    FullName: 'EU Coin (EUCOIN)',
  },
  QBZ: {
    Name: 'QBZ',
    Symbol: 'QBZ',
    CoinName: 'QUEENBEE',
    FullName: 'QUEENBEE (QBZ)',
  },
  FF1: {
    Name: 'FF1',
    Symbol: 'FF1',
    CoinName: 'Two Prime FF1 Token',
    FullName: 'Two Prime FF1 Token (FF1)',
  },
  ISIKC: {
    Name: 'ISIKC',
    Symbol: 'ISIKC',
    CoinName: 'Isiklar Coin',
    FullName: 'Isiklar Coin (ISIKC)',
  },
  ZFL: {
    Name: 'ZFL',
    Symbol: 'ZFL',
    CoinName: 'Zuflo Coin',
    FullName: 'Zuflo Coin (ZFL)',
  },
  PCX: {
    Name: 'PCX',
    Symbol: 'PCX',
    CoinName: 'ChainX',
    FullName: 'ChainX (PCX)',
  },
  CTSI: {
    Name: 'CTSI',
    Symbol: 'CTSI',
    CoinName: 'Cartesi',
    FullName: 'Cartesi (CTSI)',
  },
  MWC: {
    Name: 'MWC',
    Symbol: 'MWC',
    CoinName: 'MimbleWimbleCoin',
    FullName: 'MimbleWimbleCoin (MWC)',
  },
  IZER: {
    Name: 'IZER',
    Symbol: 'IZER',
    CoinName: 'IZEROIUM',
    FullName: 'IZEROIUM (IZER)',
  },
  XXA: {
    Name: 'XXA',
    Symbol: 'XXA',
    CoinName: 'Ixinium',
    FullName: 'Ixinium (XXA)',
  },
  SENSO: {
    Name: 'SENSO',
    Symbol: 'SENSO',
    CoinName: 'SENSO',
    FullName: 'SENSO (SENSO)',
  },
  STAKE: {
    Name: 'STAKE',
    Symbol: 'STAKE',
    CoinName: 'xDai Chain',
    FullName: 'xDai Chain (STAKE)',
  },
  SATX: {
    Name: 'SATX',
    Symbol: 'SATX',
    CoinName: 'SatoExchange Token',
    FullName: 'SatoExchange Token (SATX)',
  },
  UFOC: {
    Name: 'UFOC',
    Symbol: 'UFOC',
    CoinName: 'Unknown Fair Object',
    FullName: 'Unknown Fair Object (UFOC)',
  },
  BONO: {
    Name: 'BONO',
    Symbol: 'BONO',
    CoinName: 'Bonorum Coin',
    FullName: 'Bonorum Coin (BONO)',
  },
  ALA: {
    Name: 'ALA',
    Symbol: 'ALA',
    CoinName: 'ALA',
    FullName: 'ALA (ALA)',
  },
  EC: {
    Name: 'EC',
    Symbol: 'EC',
    CoinName: 'Echoin',
    FullName: 'Echoin (EC)',
  },
  BTCT: {
    Name: 'BTCT',
    Symbol: 'BTCT',
    CoinName: 'Bitcoin Token',
    FullName: 'Bitcoin Token (BTCT)',
  },
  NEWS: {
    Name: 'NEWS',
    Symbol: 'NEWS',
    CoinName: 'NewsTokens',
    FullName: 'NewsTokens (NEWS)',
  },
  RHP: {
    Name: 'RHP',
    Symbol: 'RHP',
    CoinName: 'Rhypton Club',
    FullName: 'Rhypton Club (RHP)',
  },
  DUC: {
    Name: 'DUC',
    Symbol: 'DUC',
    CoinName: 'DucatusCoin',
    FullName: 'DucatusCoin (DUC)',
  },
  CNRG: {
    Name: 'CNRG',
    Symbol: 'CNRG',
    CoinName: 'CryptoEnergy',
    FullName: 'CryptoEnergy (CNRG)',
  },
  ADAI: {
    Name: 'ADAI',
    Symbol: 'ADAI',
    CoinName: 'Aave DAI',
    FullName: 'Aave DAI (ADAI)',
  },
  ZNZ: {
    Name: 'ZNZ',
    Symbol: 'ZNZ',
    CoinName: 'ZENZO',
    FullName: 'ZENZO (ZNZ)',
  },
  NYZO: {
    Name: 'NYZO',
    Symbol: 'NYZO',
    CoinName: 'Nyzo',
    FullName: 'Nyzo (NYZO)',
  },
  ICH: {
    Name: 'ICH',
    Symbol: 'ICH',
    CoinName: 'IdeaChain',
    FullName: 'IdeaChain (ICH)',
  },
  GLEEC: {
    Name: 'GLEEC',
    Symbol: 'GLEEC',
    CoinName: 'Gleec Coin',
    FullName: 'Gleec Coin (GLEEC)',
  },
  LRG: {
    Name: 'LRG',
    Symbol: 'LRG',
    CoinName: 'Largo Coin',
    FullName: 'Largo Coin (LRG)',
  },
  RVX: {
    Name: 'RVX',
    Symbol: 'RVX',
    CoinName: 'Rivex',
    FullName: 'Rivex (RVX)',
  },
  ANJ: {
    Name: 'ANJ',
    Symbol: 'ANJ',
    CoinName: 'Aragon Court',
    FullName: 'Aragon Court (ANJ)',
  },
  WET: {
    Name: 'WET',
    Symbol: 'WET',
    CoinName: 'WeShow Token',
    FullName: 'WeShow Token (WET)',
  },
  ETHBN: {
    Name: 'ETHBN',
    Symbol: 'ETHBN',
    CoinName: 'EtherBone',
    FullName: 'EtherBone (ETHBN)',
  },
  PXP: {
    Name: 'PXP',
    Symbol: 'PXP',
    CoinName: 'PointPay',
    FullName: 'PointPay (PXP)',
  },
  '2LC': {
    Name: '2LC',
    Symbol: '2LC',
    CoinName: '2local',
    FullName: '2local (2LC)',
  },
  CWR: {
    Name: 'CWR',
    Symbol: 'CWR',
    CoinName: 'Cowrium',
    FullName: 'Cowrium (CWR)',
  },
  IBS: {
    Name: 'IBS',
    Symbol: 'IBS',
    CoinName: 'Irbis Network',
    FullName: 'Irbis Network (IBS)',
  },
  DGN: {
    Name: 'DGN',
    Symbol: 'DGN',
    CoinName: 'Diagon',
    FullName: 'Diagon (DGN)',
  },
  GLDS: {
    Name: 'GLDS',
    Symbol: 'GLDS',
    CoinName: 'Gdigit',
    FullName: 'Gdigit (GLDS)',
  },
  CTE: {
    Name: 'CTE',
    Symbol: 'CTE',
    CoinName: 'Crypto Tron',
    FullName: 'Crypto Tron (CTE)',
  },
  LLION: {
    Name: 'LLION',
    Symbol: 'LLION',
    CoinName: 'Lydian Lion',
    FullName: 'Lydian Lion (LLION)',
  },
  PWON: {
    Name: 'PWON',
    Symbol: 'PWON',
    CoinName: 'Personal Wager',
    FullName: 'Personal Wager (PWON)',
  },
  BRAND: {
    Name: 'BRAND',
    Symbol: 'BRAND',
    CoinName: 'BrandProtect',
    FullName: 'BrandProtect (BRAND)',
  },
  NTS: {
    Name: 'NTS',
    Symbol: 'NTS',
    CoinName: 'Notarised',
    FullName: 'Notarised (NTS)',
  },
  ENC: {
    Name: 'ENC',
    Symbol: 'ENC',
    CoinName: 'Encores Token',
    FullName: 'Encores Token (ENC)',
  },
  IGCH: {
    Name: 'IGCH',
    Symbol: 'IGCH',
    CoinName: 'IG-Crypto Holding',
    FullName: 'IG-Crypto Holding (IGCH)',
  },
  BNS: {
    Name: 'BNS',
    Symbol: 'BNS',
    CoinName: 'BNS token',
    FullName: 'BNS token (BNS)',
  },
  PXB: {
    Name: 'PXB',
    Symbol: 'PXB',
    CoinName: 'PixelBit',
    FullName: 'PixelBit (PXB)',
  },
  LUM: {
    Name: 'LUM',
    Symbol: 'LUM',
    CoinName: 'Illuminates',
    FullName: 'Illuminates (LUM)',
  },
  TYC: {
    Name: 'TYC',
    Symbol: 'TYC',
    CoinName: 'Tycoon',
    FullName: 'Tycoon (TYC)',
  },
  JUP: {
    Name: 'JUP',
    Symbol: 'JUP',
    CoinName: 'Jupiter',
    FullName: 'Jupiter (JUP)',
  },
  JACS: {
    Name: 'JACS',
    Symbol: 'JACS',
    CoinName: 'JACS',
    FullName: 'JACS (JACS)',
  },
  RWN: {
    Name: 'RWN',
    Symbol: 'RWN',
    CoinName: 'Rowan Token',
    FullName: 'Rowan Token (RWN)',
  },
  SETS: {
    Name: 'SETS',
    Symbol: 'SETS',
    CoinName: 'Sensitrust',
    FullName: 'Sensitrust (SETS)',
  },
  WRZ: {
    Name: 'WRZ',
    Symbol: 'WRZ',
    CoinName: 'Weriz',
    FullName: 'Weriz (WRZ)',
  },
  ITAM: {
    Name: 'ITAM',
    Symbol: 'ITAM',
    CoinName: 'ITAM Games',
    FullName: 'ITAM Games (ITAM)',
  },
  WOONK: {
    Name: 'WOONK',
    Symbol: 'WOONK',
    CoinName: 'Woonkly',
    FullName: 'Woonkly (WOONK)',
  },
  ESH: {
    Name: 'ESH',
    Symbol: 'ESH',
    CoinName: 'Switch',
    FullName: 'Switch (ESH)',
  },
  DNA: {
    Name: 'DNA',
    Symbol: 'DNA',
    CoinName: 'Metaverse',
    FullName: 'Metaverse (DNA)',
  },
  CPI: {
    Name: 'CPI',
    Symbol: 'CPI',
    CoinName: 'Crypto Price Index',
    FullName: 'Crypto Price Index (CPI)',
  },
  JUI: {
    Name: 'JUI',
    Symbol: 'JUI',
    CoinName: 'Juiice',
    FullName: 'Juiice (JUI)',
  },
  TWT: {
    Name: 'TWT',
    Symbol: 'TWT',
    CoinName: 'Trust Wallet Token',
    FullName: 'Trust Wallet Token (TWT)',
  },
  MNR: {
    Name: 'MNR',
    Symbol: 'MNR',
    CoinName: 'Mineral',
    FullName: 'Mineral (MNR)',
  },
  METAC: {
    Name: 'METAC',
    Symbol: 'METAC',
    CoinName: 'Metacoin',
    FullName: 'Metacoin (METAC)',
  },
  '1GOLD': {
    Name: '1GOLD',
    Symbol: '1GOLD',
    CoinName: '1irstGold',
    FullName: '1irstGold (1GOLD)',
  },
  LOON: {
    Name: 'LOON',
    Symbol: 'LOON',
    CoinName: 'Loon Network',
    FullName: 'Loon Network (LOON)',
  },
  CGLD: {
    Name: 'CGLD',
    Symbol: 'CGLD',
    CoinName: 'Celo Gold',
    FullName: 'Celo Gold (CGLD)',
  },
  NDN: {
    Name: 'NDN',
    Symbol: 'NDN',
    CoinName: 'NDN Link',
    FullName: 'NDN Link (NDN)',
  },
  GGC: {
    Name: 'GGC',
    Symbol: 'GGC',
    CoinName: 'Global Game Coin',
    FullName: 'Global Game Coin (GGC)',
  },
  BIZZ: {
    Name: 'BIZZ',
    Symbol: 'BIZZ',
    CoinName: 'BIZZCOIN',
    FullName: 'BIZZCOIN (BIZZ)',
  },
  XIO: {
    Name: 'XIO',
    Symbol: 'XIO',
    CoinName: 'XIO',
    FullName: 'XIO (XIO)',
  },
  '1UP': {
    Name: '1UP',
    Symbol: '1UP',
    CoinName: 'Uptrennd',
    FullName: 'Uptrennd (1UP)',
  },
  AFFC: {
    Name: 'AFFC',
    Symbol: 'AFFC',
    CoinName: 'Affil Coin',
    FullName: 'Affil Coin (AFFC)',
  },
  BBDT: {
    Name: 'BBDT',
    Symbol: 'BBDT',
    CoinName: 'BBD Token',
    FullName: 'BBD Token (BBDT)',
  },
  CDL: {
    Name: 'CDL',
    Symbol: 'CDL',
    CoinName: 'CoinDeal Token',
    FullName: 'CoinDeal Token (CDL)',
  },
  GLDY: {
    Name: 'GLDY',
    Symbol: 'GLDY',
    CoinName: 'Buzzshow',
    FullName: 'Buzzshow (GLDY)',
  },
  TERADYNE: {
    Name: 'TERADYNE',
    Symbol: 'TERADYNE',
    CoinName: 'Teradyne',
    FullName: 'Teradyne (TERADYNE)',
  },
  TRIPAD: {
    Name: 'TRIPAD',
    Symbol: 'TRIPAD',
    CoinName: 'TripAdvisor, Inc.',
    FullName: 'TripAdvisor, Inc. (TRIPAD)',
  },
  VI: {
    Name: 'VI',
    Symbol: 'VI',
    CoinName: 'Vid',
    FullName: 'Vid (VI)',
  },
  MXW: {
    Name: 'MXW',
    Symbol: 'MXW',
    CoinName: 'Maxonrow',
    FullName: 'Maxonrow (MXW)',
  },
  SDT: {
    Name: 'SDT',
    Symbol: 'SDT',
    CoinName: 'TerraSDT',
    FullName: 'TerraSDT (SDT)',
  },
  FORESTPLUS: {
    Name: 'FORESTPLUS',
    Symbol: 'FORESTPLUS',
    CoinName: 'The Forbidden Forest',
    FullName: 'The Forbidden Forest (FORESTPLUS)',
  },
  DDK: {
    Name: 'DDK',
    Symbol: 'DDK',
    CoinName: 'DDKoin',
    FullName: 'DDKoin (DDK)',
  },
  MTXLT: {
    Name: 'MTXLT',
    Symbol: 'MTXLT',
    CoinName: 'Tixl',
    FullName: 'Tixl (MTXLT)',
  },
  LQBTC: {
    Name: 'LQBTC',
    Symbol: 'LQBTC',
    CoinName: 'Liquid Bitcoin',
    FullName: 'Liquid Bitcoin (LQBTC)',
  },
  XPR: {
    Name: 'XPR',
    Symbol: 'XPR',
    CoinName: 'Proton',
    FullName: 'Proton (XPR)',
  },
  UTI: {
    Name: 'UTI',
    Symbol: 'UTI',
    CoinName: 'Unicorn Technology International',
    FullName: 'Unicorn Technology International (UTI)',
  },
  'EMN.CUR': {
    Name: 'EMN.CUR',
    Symbol: 'EMN.CUR',
    CoinName: 'Eastman Chemical',
    FullName: 'Eastman Chemical (EMN.CUR)',
  },
  AZBI: {
    Name: 'AZBI',
    Symbol: 'AZBI',
    CoinName: 'AZBI CORE',
    FullName: 'AZBI CORE (AZBI)',
  },
  DVX: {
    Name: 'DVX',
    Symbol: 'DVX',
    CoinName: 'Derivex',
    FullName: 'Derivex (DVX)',
  },
  ASM: {
    Name: 'ASM',
    Symbol: 'ASM',
    CoinName: 'Assemble Protocol',
    FullName: 'Assemble Protocol (ASM)',
  },
  GHOST: {
    Name: 'GHOST',
    Symbol: 'GHOST',
    CoinName: 'GhostbyMcAfee',
    FullName: 'GhostbyMcAfee (GHOST)',
  },
  RSV: {
    Name: 'RSV',
    Symbol: 'RSV',
    CoinName: 'Reserve',
    FullName: 'Reserve (RSV)',
  },
  '3FT': {
    Name: '3FT',
    Symbol: '3FT',
    CoinName: 'ThreeFold Token',
    FullName: 'ThreeFold Token (3FT)',
  },
  'ISRG.CUR': {
    Name: 'ISRG.CUR',
    Symbol: 'ISRG.CUR',
    CoinName: 'Intuitive Surgical, Inc.',
    FullName: 'Intuitive Surgical, Inc. (ISRG.CUR)',
  },
  DKA: {
    Name: 'DKA',
    Symbol: 'DKA',
    CoinName: 'dKargo',
    FullName: 'dKargo (DKA)',
  },
  RNX: {
    Name: 'RNX',
    Symbol: 'RNX',
    CoinName: 'ROONEX',
    FullName: 'ROONEX (RNX)',
  },
  KDAG: {
    Name: 'KDAG',
    Symbol: 'KDAG',
    CoinName: 'King DAG',
    FullName: 'King DAG (KDAG)',
  },
  XPN: {
    Name: 'XPN',
    Symbol: 'XPN',
    CoinName: 'PANTHEON X',
    FullName: 'PANTHEON X (XPN)',
  },
  KVI: {
    Name: 'KVI',
    Symbol: 'KVI',
    CoinName: 'KVI Chain',
    FullName: 'KVI Chain (KVI)',
  },
  DRM: {
    Name: 'DRM',
    Symbol: 'DRM',
    CoinName: 'DoDreamChain',
    FullName: 'DoDreamChain (DRM)',
  },
  PHNX: {
    Name: 'PHNX',
    Symbol: 'PHNX',
    CoinName: 'PhoenixDAO',
    FullName: 'PhoenixDAO (PHNX)',
  },
  MHLX: {
    Name: 'MHLX',
    Symbol: 'MHLX',
    CoinName: 'HelixNetwork',
    FullName: 'HelixNetwork (MHLX)',
  },
  SPICE: {
    Name: 'SPICE',
    Symbol: 'SPICE',
    CoinName: 'Spice',
    FullName: 'Spice (SPICE)',
  },
  GGOLD: {
    Name: 'GGOLD',
    Symbol: 'GGOLD',
    CoinName: 'GramGold Coin',
    FullName: 'GramGold Coin (GGOLD)',
  },
  ALCH: {
    Name: 'ALCH',
    Symbol: 'ALCH',
    CoinName: 'Alchemy',
    FullName: 'Alchemy (ALCH)',
  },
  SODA: {
    Name: 'SODA',
    Symbol: 'SODA',
    CoinName: 'SODA Coin',
    FullName: 'SODA Coin (SODA)',
  },
  BTMXBULL: {
    Name: 'BTMXBULL',
    Symbol: 'BTMXBULL',
    CoinName: '3X Long BitMax Token Token',
    FullName: '3X Long BitMax Token Token (BTMXBULL)',
  },
  CUSDT: {
    Name: 'CUSDT',
    Symbol: 'CUSDT',
    CoinName: 'cUSDT',
    FullName: 'cUSDT (CUSDT)',
  },
  BTRS: {
    Name: 'BTRS',
    Symbol: 'BTRS',
    CoinName: 'Bitball Treasure',
    FullName: 'Bitball Treasure (BTRS)',
  },
  D4RK: {
    Name: 'D4RK',
    Symbol: 'D4RK',
    CoinName: 'DarkPayCoin',
    FullName: 'DarkPayCoin (D4RK)',
  },
  RINGX: {
    Name: 'RINGX',
    Symbol: 'RINGX',
    CoinName: 'RING X PLATFORM',
    FullName: 'RING X PLATFORM (RINGX)',
  },
  IDK: {
    Name: 'IDK',
    Symbol: 'IDK',
    CoinName: 'IDK',
    FullName: 'IDK (IDK)',
  },
  BTSE: {
    Name: 'BTSE',
    Symbol: 'BTSE',
    CoinName: 'BTSE Token',
    FullName: 'BTSE Token (BTSE)',
  },
  PAMP: {
    Name: 'PAMP',
    Symbol: 'PAMP',
    CoinName: 'PAMP Network',
    FullName: 'PAMP Network (PAMP)',
  },
  CODEO: {
    Name: 'CODEO',
    Symbol: 'CODEO',
    CoinName: 'Codeo Token',
    FullName: 'Codeo Token (CODEO)',
  },
  MP: {
    Name: 'MP',
    Symbol: 'MP',
    CoinName: 'Membership Placeholders',
    FullName: 'Membership Placeholders (MP)',
  },
  MTRG: {
    Name: 'MTRG',
    Symbol: 'MTRG',
    CoinName: 'Meter',
    FullName: 'Meter (MTRG)',
  },
  BTE: {
    Name: 'BTE',
    Symbol: 'BTE',
    CoinName: 'BTEcoin',
    FullName: 'BTEcoin (BTE)',
  },
  LUCY: {
    Name: 'LUCY',
    Symbol: 'LUCY',
    CoinName: 'Lucy',
    FullName: 'Lucy (LUCY)',
  },
  POL: {
    Name: 'POL',
    Symbol: 'POL',
    CoinName: 'Pool-X',
    FullName: 'Pool-X (POL)',
  },
  ARX: {
    Name: 'ARX',
    Symbol: 'ARX',
    CoinName: 'ARCS',
    FullName: 'ARCS (ARX)',
  },
  PMGT: {
    Name: 'PMGT',
    Symbol: 'PMGT',
    CoinName: 'Perth Mint Gold Token',
    FullName: 'Perth Mint Gold Token (PMGT)',
  },
  PAZZI: {
    Name: 'PAZZI',
    Symbol: 'PAZZI',
    CoinName: 'Paparazzi',
    FullName: 'Paparazzi (PAZZI)',
  },
  PQT: {
    Name: 'PQT',
    Symbol: 'PQT',
    CoinName: 'Prediqt',
    FullName: 'Prediqt (PQT)',
  },
  ISP: {
    Name: 'ISP',
    Symbol: 'ISP',
    CoinName: 'Ispolink',
    FullName: 'Ispolink (ISP)',
  },
  SILKR: {
    Name: 'SILKR',
    Symbol: 'SILKR',
    CoinName: 'SilkRoadCoin',
    FullName: 'SilkRoadCoin (SILKR)',
  },
  CCOMM: {
    Name: 'CCOMM',
    Symbol: 'CCOMM',
    CoinName: 'Crypto Commonwealth',
    FullName: 'Crypto Commonwealth (CCOMM)',
  },
  LACCOIN: {
    Name: 'LACCOIN',
    Symbol: 'LACCOIN',
    CoinName: 'LocalAgro',
    FullName: 'LocalAgro (LACCOIN)',
  },
  KAI: {
    Name: 'KAI',
    Symbol: 'KAI',
    CoinName: 'KardiaChain',
    FullName: 'KardiaChain (KAI)',
  },
  BASIC: {
    Name: 'BASIC',
    Symbol: 'BASIC',
    CoinName: 'BASIC',
    FullName: 'BASIC (BASIC)',
  },
  CRD: {
    Name: 'CRD',
    Symbol: 'CRD',
    CoinName: 'CRD Network',
    FullName: 'CRD Network (CRD)',
  },
  TRCL: {
    Name: 'TRCL',
    Symbol: 'TRCL',
    CoinName: 'Treecle',
    FullName: 'Treecle (TRCL)',
  },
  EFK: {
    Name: 'EFK',
    Symbol: 'EFK',
    CoinName: 'ReFork',
    FullName: 'ReFork (EFK)',
  },
  INNOU: {
    Name: 'INNOU',
    Symbol: 'INNOU',
    CoinName: 'Innou',
    FullName: 'Innou (INNOU)',
  },
  CVS: {
    Name: 'CVS',
    Symbol: 'CVS',
    CoinName: 'CoinVisa',
    FullName: 'CoinVisa (CVS)',
  },
  JUR: {
    Name: 'JUR',
    Symbol: 'JUR',
    CoinName: 'Jur',
    FullName: 'Jur (JUR)',
  },
  OZP: {
    Name: 'OZP',
    Symbol: 'OZP',
    CoinName: 'OZAPHYRE',
    FullName: 'OZAPHYRE (OZP)',
  },
  SAC1: {
    Name: 'SAC1',
    Symbol: 'SAC1',
    CoinName: 'Sable Coin',
    FullName: 'Sable Coin (SAC1)',
  },
  SWAP: {
    Name: 'SWAP',
    Symbol: 'SWAP',
    CoinName: 'Trustswap',
    FullName: 'Trustswap (SWAP)',
  },
  KEEP: {
    Name: 'KEEP',
    Symbol: 'KEEP',
    CoinName: 'Keep Network',
    FullName: 'Keep Network (KEEP)',
  },
  BKS: {
    Name: 'BKS',
    Symbol: 'BKS',
    CoinName: 'Barkis Network',
    FullName: 'Barkis Network (BKS)',
  },
  CCXX: {
    Name: 'CCXX',
    Symbol: 'CCXX',
    CoinName: 'CounosX',
    FullName: 'CounosX (CCXX)',
  },
  BPS: {
    Name: 'BPS',
    Symbol: 'BPS',
    CoinName: 'BitcoinPoS',
    FullName: 'BitcoinPoS (BPS)',
  },
  RAKU: {
    Name: 'RAKU',
    Symbol: 'RAKU',
    CoinName: 'RAKUN',
    FullName: 'RAKUN (RAKU)',
  },
  DMCH: {
    Name: 'DMCH',
    Symbol: 'DMCH',
    CoinName: 'DARMA Cash',
    FullName: 'DARMA Cash (DMCH)',
  },
  PLAAS: {
    Name: 'PLAAS',
    Symbol: 'PLAAS',
    CoinName: 'PLAAS FARMERS TOKEN',
    FullName: 'PLAAS FARMERS TOKEN (PLAAS)',
  },
  NEAL: {
    Name: 'NEAL',
    Symbol: 'NEAL',
    CoinName: 'Coineal Token',
    FullName: 'Coineal Token (NEAL)',
  },
  AMATEN: {
    Name: 'AMATEN',
    Symbol: 'AMATEN',
    CoinName: 'Amaten',
    FullName: 'Amaten (AMATEN)',
  },
  WEST: {
    Name: 'WEST',
    Symbol: 'WEST',
    CoinName: 'Waves Enterprise',
    FullName: 'Waves Enterprise (WEST)',
  },
  BRTR: {
    Name: 'BRTR',
    Symbol: 'BRTR',
    CoinName: 'Barter',
    FullName: 'Barter (BRTR)',
  },
  UFC: {
    Name: 'UFC',
    Symbol: 'UFC',
    CoinName: 'Union Fair Coin',
    FullName: 'Union Fair Coin (UFC)',
  },
  TTM: {
    Name: 'TTM',
    Symbol: 'TTM',
    CoinName: 'To The Moon',
    FullName: 'To The Moon (TTM)',
  },
  IDX: {
    Name: 'IDX',
    Symbol: 'IDX',
    CoinName: 'Index Chain',
    FullName: 'Index Chain (IDX)',
  },
  FXF: {
    Name: 'FXF',
    Symbol: 'FXF',
    CoinName: 'Finxflo',
    FullName: 'Finxflo (FXF)',
  },
  OKS: {
    Name: 'OKS',
    Symbol: 'OKS',
    CoinName: 'Oikos',
    FullName: 'Oikos (OKS)',
  },
  XANK: {
    Name: 'XANK',
    Symbol: 'XANK',
    CoinName: 'Xank',
    FullName: 'Xank (XANK)',
  },
  FIO: {
    Name: 'FIO',
    Symbol: 'FIO',
    CoinName: 'FIO Protocol',
    FullName: 'FIO Protocol (FIO)',
  },
  BLX: {
    Name: 'BLX',
    Symbol: 'BLX',
    CoinName: 'Balloon-X',
    FullName: 'Balloon-X (BLX)',
  },
  DEXT: {
    Name: 'DEXT',
    Symbol: 'DEXT',
    CoinName: 'DEXTools',
    FullName: 'DEXTools (DEXT)',
  },
  FOUR: {
    Name: 'FOUR',
    Symbol: 'FOUR',
    CoinName: '4THPILLAR TECHNOLOGIES',
    FullName: '4THPILLAR TECHNOLOGIES (FOUR)',
  },
  ALEPH: {
    Name: 'ALEPH',
    Symbol: 'ALEPH',
    CoinName: 'Aleph.im',
    FullName: 'Aleph.im (ALEPH)',
  },
  STONK: {
    Name: 'STONK',
    Symbol: 'STONK',
    CoinName: 'STONK',
    FullName: 'STONK (STONK)',
  },
  NEXBT: {
    Name: 'NEXBT',
    Symbol: 'NEXBT',
    CoinName: 'Native XBTPro Exchange Token',
    FullName: 'Native XBTPro Exchange Token (NEXBT)',
  },
  DFI: {
    Name: 'DFI',
    Symbol: 'DFI',
    CoinName: 'DeFiChain',
    FullName: 'DeFiChain (DFI)',
  },
  SWINGBY: {
    Name: 'SWINGBY',
    Symbol: 'SWINGBY',
    CoinName: 'Swingby',
    FullName: 'Swingby (SWINGBY)',
  },
  DEC: {
    Name: 'DEC',
    Symbol: 'DEC',
    CoinName: 'Decentr',
    FullName: 'Decentr (DEC)',
  },
  ECOCH: {
    Name: 'ECOCH',
    Symbol: 'ECOCH',
    CoinName: 'ECOChain',
    FullName: 'ECOChain (ECOCH)',
  },
  VXV: {
    Name: 'VXV',
    Symbol: 'VXV',
    CoinName: 'Vectorspace AI',
    FullName: 'Vectorspace AI (VXV)',
  },
  XOR: {
    Name: 'XOR',
    Symbol: 'XOR',
    CoinName: 'Sora',
    FullName: 'Sora (XOR)',
  },
  HNT: {
    Name: 'HNT',
    Symbol: 'HNT',
    CoinName: 'Helium',
    FullName: 'Helium (HNT)',
  },
  PLTXYZ: {
    Name: 'PLTXYZ',
    Symbol: 'PLTXYZ',
    CoinName: 'Add.xyz',
    FullName: 'Add.xyz (PLTXYZ)',
  },
  DEMOS: {
    Name: 'DEMOS',
    Symbol: 'DEMOS',
    CoinName: 'DEMOS',
    FullName: 'DEMOS (DEMOS)',
  },
  NEST: {
    Name: 'NEST',
    Symbol: 'NEST',
    CoinName: 'Nest Protocol',
    FullName: 'Nest Protocol (NEST)',
  },
  HYBN: {
    Name: 'HYBN',
    Symbol: 'HYBN',
    CoinName: 'Hey Bitcoin',
    FullName: 'Hey Bitcoin (HYBN)',
  },
  UHP: {
    Name: 'UHP',
    Symbol: 'UHP',
    CoinName: 'Ulgen Hash Power',
    FullName: 'Ulgen Hash Power (UHP)',
  },
  RING: {
    Name: 'RING',
    Symbol: 'RING',
    CoinName: 'Darwinia Network',
    FullName: 'Darwinia Network (RING)',
  },
  IMST: {
    Name: 'IMST',
    Symbol: 'IMST',
    CoinName: 'Imsmart',
    FullName: 'Imsmart (IMST)',
  },
  GEODB: {
    Name: 'GEODB',
    Symbol: 'GEODB',
    CoinName: 'GeoDB',
    FullName: 'GeoDB (GEODB)',
  },
  DDRT: {
    Name: 'DDRT',
    Symbol: 'DDRT',
    CoinName: 'DigiDinar Token',
    FullName: 'DigiDinar Token (DDRT)',
  },
  MCB: {
    Name: 'MCB',
    Symbol: 'MCB',
    CoinName: 'MCDEX',
    FullName: 'MCDEX (MCB)',
  },
  HOMI: {
    Name: 'HOMI',
    Symbol: 'HOMI',
    CoinName: 'HOMIHELP',
    FullName: 'HOMIHELP (HOMI)',
  },
  CNS: {
    Name: 'CNS',
    Symbol: 'CNS',
    CoinName: 'Centric Cash',
    FullName: 'Centric Cash (CNS)',
  },
  TEND: {
    Name: 'TEND',
    Symbol: 'TEND',
    CoinName: 'Tendies',
    FullName: 'Tendies (TEND)',
  },
  MDU: {
    Name: 'MDU',
    Symbol: 'MDU',
    CoinName: 'MDUKEY',
    FullName: 'MDUKEY (MDU)',
  },
  XAMP: {
    Name: 'XAMP',
    Symbol: 'XAMP',
    CoinName: 'Antiample',
    FullName: 'Antiample (XAMP)',
  },
  FOCV: {
    Name: 'FOCV',
    Symbol: 'FOCV',
    CoinName: 'FOCV',
    FullName: 'FOCV (FOCV)',
  },
  INRT: {
    Name: 'INRT',
    Symbol: 'INRT',
    CoinName: 'INRToken',
    FullName: 'INRToken (INRT)',
  },
  LIDER: {
    Name: 'LIDER',
    Symbol: 'LIDER',
    CoinName: 'Lider Token',
    FullName: 'Lider Token (LIDER)',
  },
  UNITRADE: {
    Name: 'UNITRADE',
    Symbol: 'UNITRADE',
    CoinName: 'UniTrade',
    FullName: 'UniTrade (UNITRADE)',
  },
  KTON: {
    Name: 'KTON',
    Symbol: 'KTON',
    CoinName: 'Darwinia Commitment Token',
    FullName: 'Darwinia Commitment Token (KTON)',
  },
  FEX: {
    Name: 'FEX',
    Symbol: 'FEX',
    CoinName: 'FEX Token',
    FullName: 'FEX Token (FEX)',
  },
  AOS: {
    Name: 'AOS',
    Symbol: 'AOS',
    CoinName: 'AOS',
    FullName: 'AOS (AOS)',
  },
  AICO: {
    Name: 'AICO',
    Symbol: 'AICO',
    CoinName: 'AICON',
    FullName: 'AICON (AICO)',
  },
  FLS: {
    Name: 'FLS',
    Symbol: 'FLS',
    CoinName: 'Flits',
    FullName: 'Flits (FLS)',
  },
  ETHP: {
    Name: 'ETHP',
    Symbol: 'ETHP',
    CoinName: 'ETHPlus',
    FullName: 'ETHPlus (ETHP)',
  },
  MLK: {
    Name: 'MLK',
    Symbol: 'MLK',
    CoinName: 'MiL.k',
    FullName: 'MiL.k (MLK)',
  },
  LID: {
    Name: 'LID',
    Symbol: 'LID',
    CoinName: 'Liquidity Dividends Protocol',
    FullName: 'Liquidity Dividends Protocol (LID)',
  },
  GEEQ: {
    Name: 'GEEQ',
    Symbol: 'GEEQ',
    CoinName: 'Geeq',
    FullName: 'Geeq (GEEQ)',
  },
  STAMP: {
    Name: 'STAMP',
    Symbol: 'STAMP',
    CoinName: 'SafePost',
    FullName: 'SafePost (STAMP)',
  },
  BULLC: {
    Name: 'BULLC',
    Symbol: 'BULLC',
    CoinName: 'BuySell',
    FullName: 'BuySell (BULLC)',
  },
  DPIE: {
    Name: 'DPIE',
    Symbol: 'DPIE',
    CoinName: 'DeFiPie',
    FullName: 'DeFiPie (DPIE)',
  },
  RMPL: {
    Name: 'RMPL',
    Symbol: 'RMPL',
    CoinName: 'RMPL',
    FullName: 'RMPL (RMPL)',
  },
  XRT: {
    Name: 'XRT',
    Symbol: 'XRT',
    CoinName: 'Robonomics Network',
    FullName: 'Robonomics Network (XRT)',
  },
  MGP: {
    Name: 'MGP',
    Symbol: 'MGP',
    CoinName: 'MangoChain',
    FullName: 'MangoChain (MGP)',
  },
  JT: {
    Name: 'JT',
    Symbol: 'JT',
    CoinName: 'Jubi Token',
    FullName: 'Jubi Token (JT)',
  },
  ZNN: {
    Name: 'ZNN',
    Symbol: 'ZNN',
    CoinName: 'Zenon',
    FullName: 'Zenon (ZNN)',
  },
  EDGEW: {
    Name: 'EDGEW',
    Symbol: 'EDGEW',
    CoinName: 'Edgeware',
    FullName: 'Edgeware (EDGEW)',
  },
  ANW: {
    Name: 'ANW',
    Symbol: 'ANW',
    CoinName: 'Anchor Neural World',
    FullName: 'Anchor Neural World (ANW)',
  },
  BDCC: {
    Name: 'BDCC',
    Symbol: 'BDCC',
    CoinName: 'BDCC COIN',
    FullName: 'BDCC COIN (BDCC)',
  },
  CORX: {
    Name: 'CORX',
    Symbol: 'CORX',
    CoinName: 'CorionX',
    FullName: 'CorionX (CORX)',
  },
  PAR: {
    Name: 'PAR',
    Symbol: 'PAR',
    CoinName: 'Parachute',
    FullName: 'Parachute (PAR)',
  },
  BREE: {
    Name: 'BREE',
    Symbol: 'BREE',
    CoinName: 'CBDAO',
    FullName: 'CBDAO (BREE)',
  },
  PERX: {
    Name: 'PERX',
    Symbol: 'PERX',
    CoinName: 'PeerEx Network',
    FullName: 'PeerEx Network (PERX)',
  },
  PRQ: {
    Name: 'PRQ',
    Symbol: 'PRQ',
    CoinName: 'PARSIQ',
    FullName: 'PARSIQ (PRQ)',
  },
  BLY: {
    Name: 'BLY',
    Symbol: 'BLY',
    CoinName: 'Blocery',
    FullName: 'Blocery (BLY)',
  },
  YFV: {
    Name: 'YFV',
    Symbol: 'YFV',
    CoinName: 'YFValue',
    FullName: 'YFValue (YFV)',
  },
  ZZZ: {
    Name: 'ZZZ',
    Symbol: 'ZZZ',
    CoinName: 'zzz.finance',
    FullName: 'zzz.finance (ZZZ)',
  },
  '4ART': {
    Name: '4ART',
    Symbol: '4ART',
    CoinName: '4ART Coin',
    FullName: '4ART Coin (4ART)',
  },
  CAPT: {
    Name: 'CAPT',
    Symbol: 'CAPT',
    CoinName: 'Bitcoin Captain',
    FullName: 'Bitcoin Captain (CAPT)',
  },
  YAMV2: {
    Name: 'YAMV2',
    Symbol: 'YAMV2',
    CoinName: 'YAM v2',
    FullName: 'YAM v2 (YAMV2)',
  },
  TRUST: {
    Name: 'TRUST',
    Symbol: 'TRUST',
    CoinName: 'TrustDAO',
    FullName: 'TrustDAO (TRUST)',
  },
  YFL: {
    Name: 'YFL',
    Symbol: 'YFL',
    CoinName: 'YF Link',
    FullName: 'YF Link (YFL)',
  },
  MXX: {
    Name: 'MXX',
    Symbol: 'MXX',
    CoinName: 'Multiplier',
    FullName: 'Multiplier (MXX)',
  },
  ETHV: {
    Name: 'ETHV',
    Symbol: 'ETHV',
    CoinName: 'Ethverse',
    FullName: 'Ethverse (ETHV)',
  },
  BRDG: {
    Name: 'BRDG',
    Symbol: 'BRDG',
    CoinName: 'Bridge Protocol',
    FullName: 'Bridge Protocol (BRDG)',
  },
  DZI: {
    Name: 'DZI',
    Symbol: 'DZI',
    CoinName: 'DeFinition',
    FullName: 'DeFinition (DZI)',
  },
  BART: {
    Name: 'BART',
    Symbol: 'BART',
    CoinName: 'BarterTrade',
    FullName: 'BarterTrade (BART)',
  },
  XIOT: {
    Name: 'XIOT',
    Symbol: 'XIOT',
    CoinName: 'Xiotri',
    FullName: 'Xiotri (XIOT)',
  },
  LAYER: {
    Name: 'LAYER',
    Symbol: 'LAYER',
    CoinName: 'UniLayer',
    FullName: 'UniLayer (LAYER)',
  },
  FYZ: {
    Name: 'FYZ',
    Symbol: 'FYZ',
    CoinName: 'Fyooz',
    FullName: 'Fyooz (FYZ)',
  },
  HPX: {
    Name: 'HPX',
    Symbol: 'HPX',
    CoinName: 'HUPAYX',
    FullName: 'HUPAYX (HPX)',
  },
  RWS: {
    Name: 'RWS',
    Symbol: 'RWS',
    CoinName: 'Robonomics Web Services',
    FullName: 'Robonomics Web Services (RWS)',
  },
  KLP: {
    Name: 'KLP',
    Symbol: 'KLP',
    CoinName: 'Kulupu',
    FullName: 'Kulupu (KLP)',
  },
  ZPAE: {
    Name: 'ZPAE',
    Symbol: 'ZPAE',
    CoinName: 'ZelaaPayAE',
    FullName: 'ZelaaPayAE (ZPAE)',
  },
  FSW: {
    Name: 'FSW',
    Symbol: 'FSW',
    CoinName: 'Falconswap',
    FullName: 'Falconswap (FSW)',
  },
  HAKKA: {
    Name: 'HAKKA',
    Symbol: 'HAKKA',
    CoinName: 'Hakka Finance',
    FullName: 'Hakka Finance (HAKKA)',
  },
  STOP: {
    Name: 'STOP',
    Symbol: 'STOP',
    CoinName: 'SatoPay',
    FullName: 'SatoPay (STOP)',
  },
  HTN: {
    Name: 'HTN',
    Symbol: 'HTN',
    CoinName: 'Heart Number',
    FullName: 'Heart Number (HTN)',
  },
  DGVC: {
    Name: 'DGVC',
    Symbol: 'DGVC',
    CoinName: 'DegenVC',
    FullName: 'DegenVC (DGVC)',
  },
  PDF: {
    Name: 'PDF',
    Symbol: 'PDF',
    CoinName: 'Port of DeFi Network',
    FullName: 'Port of DeFi Network (PDF)',
  },
  RUG: {
    Name: 'RUG',
    Symbol: 'RUG',
    CoinName: 'Rug',
    FullName: 'Rug (RUG)',
  },
  CVPT: {
    Name: 'CVPT',
    Symbol: 'CVPT',
    CoinName: 'Concentrated Voting Power',
    FullName: 'Concentrated Voting Power (CVPT)',
  },
  CATX: {
    Name: 'CATX',
    Symbol: 'CATX',
    CoinName: 'CAT.trade Protocol',
    FullName: 'CAT.trade Protocol (CATX)',
  },
  DFIO: {
    Name: 'DFIO',
    Symbol: 'DFIO',
    CoinName: 'DeFi Omega',
    FullName: 'DeFi Omega (DFIO)',
  },
  NEWTON: {
    Name: 'NEWTON',
    Symbol: 'NEWTON',
    CoinName: 'Newtonium',
    FullName: 'Newtonium (NEWTON)',
  },
  QQQF: {
    Name: 'QQQF',
    Symbol: 'QQQF',
    CoinName: 'Standard Crypto Fund',
    FullName: 'Standard Crypto Fund (QQQF)',
  },
  LIBFX: {
    Name: 'LIBFX',
    Symbol: 'LIBFX',
    CoinName: 'Libfx',
    FullName: 'Libfx (LIBFX)',
  },
  LIEN: {
    Name: 'LIEN',
    Symbol: 'LIEN',
    CoinName: 'Lien',
    FullName: 'Lien (LIEN)',
  },
  FME: {
    Name: 'FME',
    Symbol: 'FME',
    CoinName: 'FME',
    FullName: 'FME (FME)',
  },
  $BASED: {
    Name: '$BASED',
    Symbol: '$BASED',
    CoinName: 'Based Money',
    FullName: 'Based Money ($BASED)',
  },
  YFFI: {
    Name: 'YFFI',
    Symbol: 'YFFI',
    CoinName: 'yffi finance',
    FullName: 'yffi finance (YFFI)',
  },
  KLV: {
    Name: 'KLV',
    Symbol: 'KLV',
    CoinName: 'Klever',
    FullName: 'Klever (KLV)',
  },
  LYRA: {
    Name: 'LYRA',
    Symbol: 'LYRA',
    CoinName: 'Scrypta',
    FullName: 'Scrypta (LYRA)',
  },
  STATERA: {
    Name: 'STATERA',
    Symbol: 'STATERA',
    CoinName: 'Statera',
    FullName: 'Statera (STATERA)',
  },
  PEARL: {
    Name: 'PEARL',
    Symbol: 'PEARL',
    CoinName: 'Pearl Finance',
    FullName: 'Pearl Finance (PEARL)',
  },
  TAI: {
    Name: 'TAI',
    Symbol: 'TAI',
    CoinName: 'tBridge Token',
    FullName: 'tBridge Token (TAI)',
  },
  DEXG: {
    Name: 'DEXG',
    Symbol: 'DEXG',
    CoinName: 'Dextoken Governance',
    FullName: 'Dextoken Governance (DEXG)',
  },
  CHAIN: {
    Name: 'CHAIN',
    Symbol: 'CHAIN',
    CoinName: 'Chain Games',
    FullName: 'Chain Games (CHAIN)',
  },
  OIN: {
    Name: 'OIN',
    Symbol: 'OIN',
    CoinName: 'OIN Finance',
    FullName: 'OIN Finance (OIN)',
  },
  SLINK: {
    Name: 'SLINK',
    Symbol: 'SLINK',
    CoinName: 'Soft Link',
    FullName: 'Soft Link (SLINK)',
  },
  DACC2: {
    Name: 'DACC2',
    Symbol: 'DACC2',
    CoinName: 'DACC2',
    FullName: 'DACC2 (DACC2)',
  },
  YFIVE: {
    Name: 'YFIVE',
    Symbol: 'YFIVE',
    CoinName: 'YFIVE FINANCE',
    FullName: 'YFIVE FINANCE (YFIVE)',
  },
  BAST: {
    Name: 'BAST',
    Symbol: 'BAST',
    CoinName: 'Bast',
    FullName: 'Bast (BAST)',
  },
  YFIE: {
    Name: 'YFIE',
    Symbol: 'YFIE',
    CoinName: 'YFIEXCHANGE.FINANCE',
    FullName: 'YFIEXCHANGE.FINANCE (YFIE)',
  },
  RELVT: {
    Name: 'RELVT',
    Symbol: 'RELVT',
    CoinName: 'Relevant',
    FullName: 'Relevant (RELVT)',
  },
  OPTC: {
    Name: 'OPTC',
    Symbol: 'OPTC',
    CoinName: 'Open Predict Token',
    FullName: 'Open Predict Token (OPTC)',
  },
  HAI: {
    Name: 'HAI',
    Symbol: 'HAI',
    CoinName: 'Hacken Token',
    FullName: 'Hacken Token (HAI)',
  },
  TLN: {
    Name: 'TLN',
    Symbol: 'TLN',
    CoinName: 'Trustlines Network',
    FullName: 'Trustlines Network (TLN)',
  },
  KARMAD: {
    Name: 'KARMAD',
    Symbol: 'KARMAD',
    CoinName: 'Karma DAO',
    FullName: 'Karma DAO (KARMAD)',
  },
  CORN: {
    Name: 'CORN',
    Symbol: 'CORN',
    CoinName: 'CORN',
    FullName: 'CORN (CORN)',
  },
  SALMON: {
    Name: 'SALMON',
    Symbol: 'SALMON',
    CoinName: 'Salmon',
    FullName: 'Salmon (SALMON)',
  },
  JFI: {
    Name: 'JFI',
    Symbol: 'JFI',
    CoinName: 'JackPool.finance',
    FullName: 'JackPool.finance (JFI)',
  },
  QOOB: {
    Name: 'QOOB',
    Symbol: 'QOOB',
    CoinName: 'QOOBER',
    FullName: 'QOOBER (QOOB)',
  },
  ADEL: {
    Name: 'ADEL',
    Symbol: 'ADEL',
    CoinName: 'Akropolis Delphi',
    FullName: 'Akropolis Delphi (ADEL)',
  },
  MATH: {
    Name: 'MATH',
    Symbol: 'MATH',
    CoinName: 'MATH',
    FullName: 'MATH (MATH)',
  },
  MEDICO: {
    Name: 'MEDICO',
    Symbol: 'MEDICO',
    CoinName: 'Mediconnect',
    FullName: 'Mediconnect (MEDICO)',
  },
  SEEDV: {
    Name: 'SEEDV',
    Symbol: 'SEEDV',
    CoinName: 'Seed Venture',
    FullName: 'Seed Venture (SEEDV)',
  },
  BTCAS: {
    Name: 'BTCAS',
    Symbol: 'BTCAS',
    CoinName: 'BitcoinAsia',
    FullName: 'BitcoinAsia (BTCAS)',
  },
  LGOT: {
    Name: 'LGOT',
    Symbol: 'LGOT',
    CoinName: 'LGO Token',
    FullName: 'LGO Token (LGOT)',
  },
  XFTC: {
    Name: 'XFTC',
    Symbol: 'XFTC',
    CoinName: 'Offshift',
    FullName: 'Offshift (XFTC)',
  },
  POWER: {
    Name: 'POWER',
    Symbol: 'POWER',
    CoinName: 'UniPower',
    FullName: 'UniPower (POWER)',
  },
  AMP: {
    Name: 'AMP',
    Symbol: 'AMP',
    CoinName: 'Amp',
    FullName: 'Amp (AMP)',
  },
  UMI: {
    Name: 'UMI',
    Symbol: 'UMI',
    CoinName: 'Universal Money Instrument',
    FullName: 'Universal Money Instrument (UMI)',
  },
  HGET: {
    Name: 'HGET',
    Symbol: 'HGET',
    CoinName: 'Hedget',
    FullName: 'Hedget (HGET)',
  },
  PHA: {
    Name: 'PHA',
    Symbol: 'PHA',
    CoinName: 'Phala Network',
    FullName: 'Phala Network (PHA)',
  },
  CRT: {
    Name: 'CRT',
    Symbol: 'CRT',
    CoinName: 'Carr.Finance',
    FullName: 'Carr.Finance (CRT)',
  },
  YSAFE: {
    Name: 'YSAFE',
    Symbol: 'YSAFE',
    CoinName: 'yieldfarming.insure',
    FullName: 'yieldfarming.insure (YSAFE)',
  },
  GOF: {
    Name: 'GOF',
    Symbol: 'GOF',
    CoinName: 'Golff',
    FullName: 'Golff (GOF)',
  },
  SASHIMI: {
    Name: 'SASHIMI',
    Symbol: 'SASHIMI',
    CoinName: 'Sashimi',
    FullName: 'Sashimi (SASHIMI)',
  },
  UNDB: {
    Name: 'UNDB',
    Symbol: 'UNDB',
    CoinName: 'unibot.cash',
    FullName: 'unibot.cash (UNDB)',
  },
  SAKE: {
    Name: 'SAKE',
    Symbol: 'SAKE',
    CoinName: 'SakeToken',
    FullName: 'SakeToken (SAKE)',
  },
  YFFC: {
    Name: 'YFFC',
    Symbol: 'YFFC',
    CoinName: 'yffc.finance',
    FullName: 'yffc.finance (YFFC)',
  },
  ZDEX: {
    Name: 'ZDEX',
    Symbol: 'ZDEX',
    CoinName: 'Zeedex',
    FullName: 'Zeedex (ZDEX)',
  },
  DPI: {
    Name: 'DPI',
    Symbol: 'DPI',
    CoinName: 'DeFiPulse Index',
    FullName: 'DeFiPulse Index (DPI)',
  },
  YFARM: {
    Name: 'YFARM',
    Symbol: 'YFARM',
    CoinName: 'YFARM Token',
    FullName: 'YFARM Token (YFARM)',
  },
  CHADS: {
    Name: 'CHADS',
    Symbol: 'CHADS',
    CoinName: 'CHADS VC',
    FullName: 'CHADS VC (CHADS)',
  },
  KATANA: {
    Name: 'KATANA',
    Symbol: 'KATANA',
    CoinName: 'Katana Finance',
    FullName: 'Katana Finance (KATANA)',
  },
  GHST: {
    Name: 'GHST',
    Symbol: 'GHST',
    CoinName: 'Aavegotchi',
    FullName: 'Aavegotchi (GHST)',
  },
  ANK: {
    Name: 'ANK',
    Symbol: 'ANK',
    CoinName: 'AlphaLink',
    FullName: 'AlphaLink (ANK)',
  },
  CNYT: {
    Name: 'CNYT',
    Symbol: 'CNYT',
    CoinName: 'CNY Tether',
    FullName: 'CNY Tether (CNYT)',
  },
  DEGO: {
    Name: 'DEGO',
    Symbol: 'DEGO',
    CoinName: 'Dego Finance',
    FullName: 'Dego Finance (DEGO)',
  },
  PRINT: {
    Name: 'PRINT',
    Symbol: 'PRINT',
    CoinName: 'Printer.Finance',
    FullName: 'Printer.Finance (PRINT)',
  },
  HYBRID: {
    Name: 'HYBRID',
    Symbol: 'HYBRID',
    CoinName: 'Hybrid Bank Cash',
    FullName: 'Hybrid Bank Cash (HYBRID)',
  },
  WNRZ: {
    Name: 'WNRZ',
    Symbol: 'WNRZ',
    CoinName: 'WinPlay',
    FullName: 'WinPlay (WNRZ)',
  },
  PFID: {
    Name: 'PFID',
    Symbol: 'PFID',
    CoinName: 'Pofid Dao',
    FullName: 'Pofid Dao (PFID)',
  },
  DBOX: {
    Name: 'DBOX',
    Symbol: 'DBOX',
    CoinName: 'DefiBox',
    FullName: 'DefiBox (DBOX)',
  },
  VYBE: {
    Name: 'VYBE',
    Symbol: 'VYBE',
    CoinName: 'Vybe',
    FullName: 'Vybe (VYBE)',
  },
  NUTS: {
    Name: 'NUTS',
    Symbol: 'NUTS',
    CoinName: 'Squirrel Finance',
    FullName: 'Squirrel Finance (NUTS)',
  },
  TRBT: {
    Name: 'TRBT',
    Symbol: 'TRBT',
    CoinName: 'Tribute',
    FullName: 'Tribute (TRBT)',
  },
  XETH: {
    Name: 'XETH',
    Symbol: 'XETH',
    CoinName: 'Xplosive Ethereum',
    FullName: 'Xplosive Ethereum (XETH)',
  },
  YFFII: {
    Name: 'YFFII',
    Symbol: 'YFFII',
    CoinName: 'YFFII Finance',
    FullName: 'YFFII Finance (YFFII)',
  },
  ETHPY: {
    Name: 'ETHPY',
    Symbol: 'ETHPY',
    CoinName: 'Etherpay',
    FullName: 'Etherpay (ETHPY)',
  },
  JIAOZI: {
    Name: 'JIAOZI',
    Symbol: 'JIAOZI',
    CoinName: 'Jiaozi',
    FullName: 'Jiaozi (JIAOZI)',
  },
  ON: {
    Name: 'ON',
    Symbol: 'ON',
    CoinName: 'OFIN Token',
    FullName: 'OFIN Token (ON)',
  },
  XSTAR: {
    Name: 'XSTAR',
    Symbol: 'XSTAR',
    CoinName: 'StarCurve',
    FullName: 'StarCurve (XSTAR)',
  },
  SOCKS: {
    Name: 'SOCKS',
    Symbol: 'SOCKS',
    CoinName: 'Unisocks',
    FullName: 'Unisocks (SOCKS)',
  },
  RARI: {
    Name: 'RARI',
    Symbol: 'RARI',
    CoinName: 'Rarible',
    FullName: 'Rarible (RARI)',
  },
  EVCC: {
    Name: 'EVCC',
    Symbol: 'EVCC',
    CoinName: 'Eco Value Coin',
    FullName: 'Eco Value Coin (EVCC)',
  },
  ARDX: {
    Name: 'ARDX',
    Symbol: 'ARDX',
    CoinName: 'ArdCoin',
    FullName: 'ArdCoin (ARDX)',
  },
  NSBT: {
    Name: 'NSBT',
    Symbol: 'NSBT',
    CoinName: 'Neutrino Token',
    FullName: 'Neutrino Token (NSBT)',
  },
  DOGEC: {
    Name: 'DOGEC',
    Symbol: 'DOGEC',
    CoinName: 'DogeCash',
    FullName: 'DogeCash (DOGEC)',
  },
  QAC: {
    Name: 'QAC',
    Symbol: 'QAC',
    CoinName: 'Quasarcoin',
    FullName: 'Quasarcoin (QAC)',
  },
  HTA: {
    Name: 'HTA',
    Symbol: 'HTA',
    CoinName: 'Historia',
    FullName: 'Historia (HTA)',
  },
  CR8: {
    Name: 'CR8',
    Symbol: 'CR8',
    CoinName: 'Crazy8Token',
    FullName: 'Crazy8Token (CR8)',
  },
  DIC: {
    Name: 'DIC',
    Symbol: 'DIC',
    CoinName: 'Daikicoin',
    FullName: 'Daikicoin (DIC)',
  },
  BAK: {
    Name: 'BAK',
    Symbol: 'BAK',
    CoinName: 'BaconCoin',
    FullName: 'BaconCoin (BAK)',
  },
  DVS: {
    Name: 'DVS',
    Symbol: 'DVS',
    CoinName: 'Davies',
    FullName: 'Davies (DVS)',
  },
  CRDT: {
    Name: 'CRDT',
    Symbol: 'CRDT',
    CoinName: 'CRDT',
    FullName: 'CRDT (CRDT)',
  },
  BCEO: {
    Name: 'BCEO',
    Symbol: 'BCEO',
    CoinName: 'bitCEO',
    FullName: 'bitCEO (BCEO)',
  },
  BITC: {
    Name: 'BITC',
    Symbol: 'BITC',
    CoinName: 'BitCash',
    FullName: 'BitCash (BITC)',
  },
  LOT: {
    Name: 'LOT',
    Symbol: 'LOT',
    CoinName: 'Lukki Operating Token',
    FullName: 'Lukki Operating Token (LOT)',
  },
  BITCOINC: {
    Name: 'BITCOINC',
    Symbol: 'BITCOINC',
    CoinName: 'Bitcoin Classic',
    FullName: 'Bitcoin Classic (BITCOINC)',
  },
  DSCP: {
    Name: 'DSCP',
    Symbol: 'DSCP',
    CoinName: 'Dreamscape',
    FullName: 'Dreamscape (DSCP)',
  },
  RC20: {
    Name: 'RC20',
    Symbol: 'RC20',
    CoinName: 'RoboCalls',
    FullName: 'RoboCalls (RC20)',
  },
  TOC: {
    Name: 'TOC',
    Symbol: 'TOC',
    CoinName: 'TouchCon',
    FullName: 'TouchCon (TOC)',
  },
  NBS: {
    Name: 'NBS',
    Symbol: 'NBS',
    CoinName: 'New BitShares',
    FullName: 'New BitShares (NBS)',
  },
  GSTC: {
    Name: 'GSTC',
    Symbol: 'GSTC',
    CoinName: 'GSTCOIN',
    FullName: 'GSTCOIN (GSTC)',
  },
  CHVF: {
    Name: 'CHVF',
    Symbol: 'CHVF',
    CoinName: 'Chives Finance',
    FullName: 'Chives Finance (CHVF)',
  },
  LGCY: {
    Name: 'LGCY',
    Symbol: 'LGCY',
    CoinName: 'LGCY Network',
    FullName: 'LGCY Network (LGCY)',
  },
  UCO: {
    Name: 'UCO',
    Symbol: 'UCO',
    CoinName: 'Uniris',
    FullName: 'Uniris (UCO)',
  },
  RIO: {
    Name: 'RIO',
    Symbol: 'RIO',
    CoinName: 'Realio Network',
    FullName: 'Realio Network (RIO)',
  },
  DHT: {
    Name: 'DHT',
    Symbol: 'DHT',
    CoinName: 'dHedge DAO',
    FullName: 'dHedge DAO (DHT)',
  },
  CHI: {
    Name: 'CHI',
    Symbol: 'CHI',
    CoinName: 'Chi Gastoken',
    FullName: 'Chi Gastoken (CHI)',
  },
  ROT: {
    Name: 'ROT',
    Symbol: 'ROT',
    CoinName: 'Rotten',
    FullName: 'Rotten (ROT)',
  },
  YAMV1: {
    Name: 'YAMV1',
    Symbol: 'YAMV1',
    CoinName: 'YAM v1',
    FullName: 'YAM v1 (YAMV1)',
  },
  SHROOM: {
    Name: 'SHROOM',
    Symbol: 'SHROOM',
    CoinName: 'Shroom.Finance',
    FullName: 'Shroom.Finance (SHROOM)',
  },
  YSR: {
    Name: 'YSR',
    Symbol: 'YSR',
    CoinName: 'Ystar',
    FullName: 'Ystar (YSR)',
  },
  FLOWP: {
    Name: 'FLOWP',
    Symbol: 'FLOWP',
    CoinName: 'Flow Protocol',
    FullName: 'Flow Protocol (FLOWP)',
  },
  KASSIAHOME: {
    Name: 'KASSIAHOME',
    Symbol: 'KASSIAHOME',
    CoinName: 'Kassia Home',
    FullName: 'Kassia Home (KASSIAHOME)',
  },
  DAB: {
    Name: 'DAB',
    Symbol: 'DAB',
    CoinName: 'DABANKING',
    FullName: 'DABANKING (DAB)',
  },
  YFF: {
    Name: 'YFF',
    Symbol: 'YFF',
    CoinName: 'YFF.Finance',
    FullName: 'YFF.Finance (YFF)',
  },
  MEXP: {
    Name: 'MEXP',
    Symbol: 'MEXP',
    CoinName: 'MOJI Experience Points',
    FullName: 'MOJI Experience Points (MEXP)',
  },
  DTOP: {
    Name: 'DTOP',
    Symbol: 'DTOP',
    CoinName: 'DTOP Token',
    FullName: 'DTOP Token (DTOP)',
  },
  GPKR: {
    Name: 'GPKR',
    Symbol: 'GPKR',
    CoinName: 'Gold Poker',
    FullName: 'Gold Poker (GPKR)',
  },
  MFC: {
    Name: 'MFC',
    Symbol: 'MFC',
    CoinName: 'MFCoin',
    FullName: 'MFCoin (MFC)',
  },
  SPRKL: {
    Name: 'SPRKL',
    Symbol: 'SPRKL',
    CoinName: 'Sparkle Loyalty',
    FullName: 'Sparkle Loyalty (SPRKL)',
  },
  SBE: {
    Name: 'SBE',
    Symbol: 'SBE',
    CoinName: 'Sombe',
    FullName: 'Sombe (SBE)',
  },
  STS: {
    Name: 'STS',
    Symbol: 'STS',
    CoinName: 'SBank',
    FullName: 'SBank (STS)',
  },
  FLM: {
    Name: 'FLM',
    Symbol: 'FLM',
    CoinName: 'Flamingo',
    FullName: 'Flamingo (FLM)',
  },
  YFBETA: {
    Name: 'YFBETA',
    Symbol: 'YFBETA',
    CoinName: 'yfBeta',
    FullName: 'yfBeta (YFBETA)',
  },
  LMY: {
    Name: 'LMY',
    Symbol: 'LMY',
    CoinName: 'Lunch Money',
    FullName: 'Lunch Money (LMY)',
  },
  DCASH: {
    Name: 'DCASH',
    Symbol: 'DCASH',
    CoinName: 'Diabolo',
    FullName: 'Diabolo (DCASH)',
  },
  PTF: {
    Name: 'PTF',
    Symbol: 'PTF',
    CoinName: 'PowerTrade Fuel',
    FullName: 'PowerTrade Fuel (PTF)',
  },
  TON: {
    Name: 'TON',
    Symbol: 'TON',
    CoinName: 'Tokamak Network',
    FullName: 'Tokamak Network (TON)',
  },
  AVAXIOU: {
    Name: 'AVAXIOU',
    Symbol: 'AVAXIOU',
    CoinName: 'Avalanche IOU',
    FullName: 'Avalanche IOU (AVAXIOU)',
  },
  MARO: {
    Name: 'MARO',
    Symbol: 'MARO',
    CoinName: 'Maro',
    FullName: 'Maro (MARO)',
  },
  $ROPE: {
    Name: '$ROPE',
    Symbol: '$ROPE',
    CoinName: 'Rope',
    FullName: 'Rope ($ROPE)',
  },
  VELO: {
    Name: 'VELO',
    Symbol: 'VELO',
    CoinName: 'Velo',
    FullName: 'Velo (VELO)',
  },
  BAKE: {
    Name: 'BAKE',
    Symbol: 'BAKE',
    CoinName: 'BakeryToken',
    FullName: 'BakeryToken (BAKE)',
  },
  MINI: {
    Name: 'MINI',
    Symbol: 'MINI',
    CoinName: 'Mini',
    FullName: 'Mini (MINI)',
  },
  RFUEL: {
    Name: 'RFUEL',
    Symbol: 'RFUEL',
    CoinName: 'Rio DeFi',
    FullName: 'Rio DeFi (RFUEL)',
  },
  AGS: {
    Name: 'AGS',
    Symbol: 'AGS',
    CoinName: 'Aegis',
    FullName: 'Aegis (AGS)',
  },
  CRU: {
    Name: 'CRU',
    Symbol: 'CRU',
    CoinName: 'Crust Network',
    FullName: 'Crust Network (CRU)',
  },
  POLS: {
    Name: 'POLS',
    Symbol: 'POLS',
    CoinName: 'Polkastarter',
    FullName: 'Polkastarter (POLS)',
  },
  WBNB: {
    Name: 'WBNB',
    Symbol: 'WBNB',
    CoinName: 'Wrapped BNB',
    FullName: 'Wrapped BNB (WBNB)',
  },
  LUA: {
    Name: 'LUA',
    Symbol: 'LUA',
    CoinName: 'Lua Token',
    FullName: 'Lua Token (LUA)',
  },
  AETH: {
    Name: 'AETH',
    Symbol: 'AETH',
    CoinName: 'Aave ETH',
    FullName: 'Aave ETH (AETH)',
  },
  ISDT: {
    Name: 'ISDT',
    Symbol: 'ISDT',
    CoinName: 'ISTARDUST',
    FullName: 'ISTARDUST (ISDT)',
  },
  BASID: {
    Name: 'BASID',
    Symbol: 'BASID',
    CoinName: 'Basid Coin',
    FullName: 'Basid Coin (BASID)',
  },
  BWF: {
    Name: 'BWF',
    Symbol: 'BWF',
    CoinName: 'Beowulf',
    FullName: 'Beowulf (BWF)',
  },
  YFBT: {
    Name: 'YFBT',
    Symbol: 'YFBT',
    CoinName: 'Yearn Finance Bit',
    FullName: 'Yearn Finance Bit (YFBT)',
  },
  MXT: {
    Name: 'MXT',
    Symbol: 'MXT',
    CoinName: 'MixTrust',
    FullName: 'MixTrust (MXT)',
  },
  FFYI: {
    Name: 'FFYI',
    Symbol: 'FFYI',
    CoinName: 'Fiscus FYI',
    FullName: 'Fiscus FYI (FFYI)',
  },
  UFFYI: {
    Name: 'UFFYI',
    Symbol: 'UFFYI',
    CoinName: 'Unlimited FiscusFYI',
    FullName: 'Unlimited FiscusFYI (UFFYI)',
  },
  FAG: {
    Name: 'FAG',
    Symbol: 'FAG',
    CoinName: 'PoorFag',
    FullName: 'PoorFag (FAG)',
  },
  NIN: {
    Name: 'NIN',
    Symbol: 'NIN',
    CoinName: 'Next Innovation',
    FullName: 'Next Innovation (NIN)',
  },
  DMD: {
    Name: 'DMD',
    Symbol: 'DMD',
    CoinName: 'DMD',
    FullName: 'DMD (DMD)',
  },
  AHT: {
    Name: 'AHT',
    Symbol: 'AHT',
    CoinName: 'AhaToken',
    FullName: 'AhaToken (AHT)',
  },
  SFG: {
    Name: 'SFG',
    Symbol: 'SFG',
    CoinName: 'S.Finance',
    FullName: 'S.Finance (SFG)',
  },
  VALUE: {
    Name: 'VALUE',
    Symbol: 'VALUE',
    CoinName: 'Value Liquidity',
    FullName: 'Value Liquidity (VALUE)',
  },
  PJM: {
    Name: 'PJM',
    Symbol: 'PJM',
    CoinName: 'Pajama.Finance',
    FullName: 'Pajama.Finance (PJM)',
  },
  PGT: {
    Name: 'PGT',
    Symbol: 'PGT',
    CoinName: 'Polyient Games Governance Token',
    FullName: 'Polyient Games Governance Token (PGT)',
  },
  XVS: {
    Name: 'XVS',
    Symbol: 'XVS',
    CoinName: 'Venus',
    FullName: 'Venus (XVS)',
  },
  XFI: {
    Name: 'XFI',
    Symbol: 'XFI',
    CoinName: 'Xfinance',
    FullName: 'Xfinance (XFI)',
  },
  KING: {
    Name: 'KING',
    Symbol: 'KING',
    CoinName: 'KingSwap',
    FullName: 'KingSwap (KING)',
  },
  DAOVC: {
    Name: 'DAOVC',
    Symbol: 'DAOVC',
    CoinName: 'DAO.VC',
    FullName: 'DAO.VC (DAOVC)',
  },
  HOTN: {
    Name: 'HOTN',
    Symbol: 'HOTN',
    CoinName: 'HotNow',
    FullName: 'HotNow (HOTN)',
  },
  HLPT: {
    Name: 'HLPT',
    Symbol: 'HLPT',
    CoinName: 'HLP Token',
    FullName: 'HLP Token (HLPT)',
  },
  DICETRX: {
    Name: 'DICETRX',
    Symbol: 'DICETRX',
    CoinName: 'TRONbetDice',
    FullName: 'TRONbetDice (DICETRX)',
  },
  WISE: {
    Name: 'WISE',
    Symbol: 'WISE',
    CoinName: 'Wise Token',
    FullName: 'Wise Token (WISE)',
  },
  TOMOE: {
    Name: 'TOMOE',
    Symbol: 'TOMOE',
    CoinName: 'TomoChain ERC20',
    FullName: 'TomoChain ERC20 (TOMOE)',
  },
  KOMP: {
    Name: 'KOMP',
    Symbol: 'KOMP',
    CoinName: 'Kompass',
    FullName: 'Kompass (KOMP)',
  },
  DODO: {
    Name: 'DODO',
    Symbol: 'DODO',
    CoinName: 'DODO',
    FullName: 'DODO (DODO)',
  },
  FIT: {
    Name: 'FIT',
    Symbol: 'FIT',
    CoinName: 'Financial Investment Token',
    FullName: 'Financial Investment Token (FIT)',
  },
  EPANUS: {
    Name: 'EPANUS',
    Symbol: 'EPANUS',
    CoinName: 'Epanus',
    FullName: 'Epanus (EPANUS)',
  },
  BBC: {
    Name: 'BBC',
    Symbol: 'BBC',
    CoinName: 'BigBang Core',
    FullName: 'BigBang Core (BBC)',
  },
  XSP: {
    Name: 'XSP',
    Symbol: 'XSP',
    CoinName: 'XSwap',
    FullName: 'XSwap (XSP)',
  },
  TRIX: {
    Name: 'TRIX',
    Symbol: 'TRIX',
    CoinName: 'TriumphX',
    FullName: 'TriumphX (TRIX)',
  },
  YFIII: {
    Name: 'YFIII',
    Symbol: 'YFIII',
    CoinName: 'Dify.Finance',
    FullName: 'Dify.Finance (YFIII)',
  },
  BUY: {
    Name: 'BUY',
    Symbol: 'BUY',
    CoinName: 'Burency',
    FullName: 'Burency (BUY)',
  },
  ECELL: {
    Name: 'ECELL',
    Symbol: 'ECELL',
    CoinName: 'Consensus Cell Network',
    FullName: 'Consensus Cell Network (ECELL)',
  },
  KIF: {
    Name: 'KIF',
    Symbol: 'KIF',
    CoinName: 'KittenFinance',
    FullName: 'KittenFinance (KIF)',
  },
  DUN: {
    Name: 'DUN',
    Symbol: 'DUN',
    CoinName: 'Dune',
    FullName: 'Dune (DUN)',
  },
  UCA: {
    Name: 'UCA',
    Symbol: 'UCA',
    CoinName: 'UCA Coin',
    FullName: 'UCA Coin (UCA)',
  },
  DDR: {
    Name: 'DDR',
    Symbol: 'DDR',
    CoinName: 'Digi Dinar',
    FullName: 'Digi Dinar (DDR)',
  },
  CUTE: {
    Name: 'CUTE',
    Symbol: 'CUTE',
    CoinName: 'Blockchain Cuties Universe',
    FullName: 'Blockchain Cuties Universe (CUTE)',
  },
  SYBC: {
    Name: 'SYBC',
    Symbol: 'SYBC',
    CoinName: 'SYB Coin',
    FullName: 'SYB Coin (SYBC)',
  },
  PLTC: {
    Name: 'PLTC',
    Symbol: 'PLTC',
    CoinName: 'PlatonCoin',
    FullName: 'PlatonCoin (PLTC)',
  },
  USG: {
    Name: 'USG',
    Symbol: 'USG',
    CoinName: 'USGold',
    FullName: 'USGold (USG)',
  },
  SEAL: {
    Name: 'SEAL',
    Symbol: 'SEAL',
    CoinName: 'Seal Finance',
    FullName: 'Seal Finance (SEAL)',
  },
  OCTO: {
    Name: 'OCTO',
    Symbol: 'OCTO',
    CoinName: 'OctoFi',
    FullName: 'OctoFi (OCTO)',
  },
  BTTR: {
    Name: 'BTTR',
    Symbol: 'BTTR',
    CoinName: 'BitTiger',
    FullName: 'BitTiger (BTTR)',
  },
  OWL: {
    Name: 'OWL',
    Symbol: 'OWL',
    CoinName: 'OWL Token',
    FullName: 'OWL Token (OWL)',
  },
  EZ: {
    Name: 'EZ',
    Symbol: 'EZ',
    CoinName: 'EasyFi V2',
    FullName: 'EasyFi V2 (EZ)',
  },
  ZEFU: {
    Name: 'ZEFU',
    Symbol: 'ZEFU',
    CoinName: 'Zenfuse',
    FullName: 'Zenfuse (ZEFU)',
  },
  STARP: {
    Name: 'STARP',
    Symbol: 'STARP',
    CoinName: 'Star Pacific Coin',
    FullName: 'Star Pacific Coin (STARP)',
  },
  POST: {
    Name: 'POST',
    Symbol: 'POST',
    CoinName: 'InterPlanetary Search Engine',
    FullName: 'InterPlanetary Search Engine (POST)',
  },
  NU: {
    Name: 'NU',
    Symbol: 'NU',
    CoinName: 'NuCypher',
    FullName: 'NuCypher (NU)',
  },
  HLX: {
    Name: 'HLX',
    Symbol: 'HLX',
    CoinName: 'Helex',
    FullName: 'Helex (HLX)',
  },
  UFT: {
    Name: 'UFT',
    Symbol: 'UFT',
    CoinName: 'UniLend Finance',
    FullName: 'UniLend Finance (UFT)',
  },
  AKT: {
    Name: 'AKT',
    Symbol: 'AKT',
    CoinName: 'Akash Network',
    FullName: 'Akash Network (AKT)',
  },
  ZEE: {
    Name: 'ZEE',
    Symbol: 'ZEE',
    CoinName: 'ZeroSwap',
    FullName: 'ZeroSwap (ZEE)',
  },
  NICE: {
    Name: 'NICE',
    Symbol: 'NICE',
    CoinName: 'Nice',
    FullName: 'Nice (NICE)',
  },
  UBXT: {
    Name: 'UBXT',
    Symbol: 'UBXT',
    CoinName: 'UpBots',
    FullName: 'UpBots (UBXT)',
  },
  LCG: {
    Name: 'LCG',
    Symbol: 'LCG',
    CoinName: 'LCG',
    FullName: 'LCG (LCG)',
  },
  SWAG: {
    Name: 'SWAG',
    Symbol: 'SWAG',
    CoinName: 'SWAG Finance',
    FullName: 'SWAG Finance (SWAG)',
  },
  UNCX: {
    Name: 'UNCX',
    Symbol: 'UNCX',
    CoinName: 'UniCrypt',
    FullName: 'UniCrypt (UNCX)',
  },
  ATRI: {
    Name: 'ATRI',
    Symbol: 'ATRI',
    CoinName: 'Atari Token',
    FullName: 'Atari Token (ATRI)',
  },
  HALFSHIT: {
    Name: 'HALFSHIT',
    Symbol: 'HALFSHIT',
    CoinName: '0.5X Long Shitcoin Index Token',
    FullName: '0.5X Long Shitcoin Index Token (HALFSHIT)',
  },
  HALF: {
    Name: 'HALF',
    Symbol: 'HALF',
    CoinName: '0.5X Long Bitcoin Token',
    FullName: '0.5X Long Bitcoin Token (HALF)',
  },
  DMGBULL: {
    Name: 'DMGBULL',
    Symbol: 'DMGBULL',
    CoinName: '3X Long DMM Governance Token',
    FullName: '3X Long DMM Governance Token (DMGBULL)',
  },
  CUSDTBULL: {
    Name: 'CUSDTBULL',
    Symbol: 'CUSDTBULL',
    CoinName: '3X Long Compound USDT Token',
    FullName: '3X Long Compound USDT Token (CUSDTBULL)',
  },
  TBTC: {
    Name: 'TBTC',
    Symbol: 'TBTC',
    CoinName: 'tBTC',
    FullName: 'tBTC (TBTC)',
  },
  FIS: {
    Name: 'FIS',
    Symbol: 'FIS',
    CoinName: 'Stafi',
    FullName: 'Stafi (FIS)',
  },
  HMT: {
    Name: 'HMT',
    Symbol: 'HMT',
    CoinName: 'HUMAN Token',
    FullName: 'HUMAN Token (HMT)',
  },
  HEARTBOUT: {
    Name: 'HEARTBOUT',
    Symbol: 'HEARTBOUT',
    CoinName: 'HeartBout Pay',
    FullName: 'HeartBout Pay (HEARTBOUT)',
  },
  HNY: {
    Name: 'HNY',
    Symbol: 'HNY',
    CoinName: 'Honey',
    FullName: 'Honey (HNY)',
  },
  CAMP: {
    Name: 'CAMP',
    Symbol: 'CAMP',
    CoinName: 'Camp',
    FullName: 'Camp (CAMP)',
  },
  KORE: {
    Name: 'KORE',
    Symbol: 'KORE',
    CoinName: 'KORE Vault',
    FullName: 'KORE Vault (KORE)',
  },
  RAMP: {
    Name: 'RAMP',
    Symbol: 'RAMP',
    CoinName: 'RAMP',
    FullName: 'RAMP (RAMP)',
  },
  WINR: {
    Name: 'WINR',
    Symbol: 'WINR',
    CoinName: 'JustBet',
    FullName: 'JustBet (WINR)',
  },
  DIGITAL: {
    Name: 'DIGITAL',
    Symbol: 'DIGITAL',
    CoinName: 'Digital Reserve Currency',
    FullName: 'Digital Reserve Currency (DIGITAL)',
  },
  PRIA: {
    Name: 'PRIA',
    Symbol: 'PRIA',
    CoinName: 'PRIA',
    FullName: 'PRIA (PRIA)',
  },
  DAM: {
    Name: 'DAM',
    Symbol: 'DAM',
    CoinName: 'Datamine',
    FullName: 'Datamine (DAM)',
  },
  AUDIO: {
    Name: 'AUDIO',
    Symbol: 'AUDIO',
    CoinName: 'Audius',
    FullName: 'Audius (AUDIO)',
  },
  AKN: {
    Name: 'AKN',
    Symbol: 'AKN',
    CoinName: 'Akoin',
    FullName: 'Akoin (AKN)',
  },
  RUC: {
    Name: 'RUC',
    Symbol: 'RUC',
    CoinName: 'Rush',
    FullName: 'Rush (RUC)',
  },
  AXIAV3: {
    Name: 'AXIAV3',
    Symbol: 'AXIAV3',
    CoinName: 'Axia',
    FullName: 'Axia (AXIAV3)',
  },
  WON: {
    Name: 'WON',
    Symbol: 'WON',
    CoinName: 'WeBlock',
    FullName: 'WeBlock (WON)',
  },
  BOR: {
    Name: 'BOR',
    Symbol: 'BOR',
    CoinName: 'BoringDAO',
    FullName: 'BoringDAO (BOR)',
  },
  XLT: {
    Name: 'XLT',
    Symbol: 'XLT',
    CoinName: 'Nexalt',
    FullName: 'Nexalt (XLT)',
  },
  STPL: {
    Name: 'STPL',
    Symbol: 'STPL',
    CoinName: 'Stream Protocol',
    FullName: 'Stream Protocol (STPL)',
  },
  IBP: {
    Name: 'IBP',
    Symbol: 'IBP',
    CoinName: 'Innovation Blockchain Payment',
    FullName: 'Innovation Blockchain Payment (IBP)',
  },
  PERP: {
    Name: 'PERP',
    Symbol: 'PERP',
    CoinName: 'Perpetual Protocol',
    FullName: 'Perpetual Protocol (PERP)',
  },
  CCOMP: {
    Name: 'CCOMP',
    Symbol: 'CCOMP',
    CoinName: 'cCOMP',
    FullName: 'cCOMP (CCOMP)',
  },
  AG8: {
    Name: 'AG8',
    Symbol: 'AG8',
    CoinName: 'ATROMG8',
    FullName: 'ATROMG8 (AG8)',
  },
  COFIX: {
    Name: 'COFIX',
    Symbol: 'COFIX',
    CoinName: 'CoFIX',
    FullName: 'CoFIX (COFIX)',
  },
  WOO: {
    Name: 'WOO',
    Symbol: 'WOO',
    CoinName: 'WOO Network',
    FullName: 'WOO Network (WOO)',
  },
  ECU: {
    Name: 'ECU',
    Symbol: 'ECU',
    CoinName: 'ECOSC',
    FullName: 'ECOSC (ECU)',
  },
  DUCATO: {
    Name: 'DUCATO',
    Symbol: 'DUCATO',
    CoinName: 'Ducato Protocol Token',
    FullName: 'Ducato Protocol Token (DUCATO)',
  },
  IZE: {
    Name: 'IZE',
    Symbol: 'IZE',
    CoinName: 'IZE',
    FullName: 'IZE (IZE)',
  },
  CNTR: {
    Name: 'CNTR',
    Symbol: 'CNTR',
    CoinName: 'Centaur',
    FullName: 'Centaur (CNTR)',
  },
  CIPHC: {
    Name: 'CIPHC',
    Symbol: 'CIPHC',
    CoinName: 'Cipher Core Token',
    FullName: 'Cipher Core Token (CIPHC)',
  },
  BHAO: {
    Name: 'BHAO',
    Symbol: 'BHAO',
    CoinName: 'Bithao',
    FullName: 'Bithao (BHAO)',
  },
  SURF: {
    Name: 'SURF',
    Symbol: 'SURF',
    CoinName: 'Surf.Finance',
    FullName: 'Surf.Finance (SURF)',
  },
  PTERIA: {
    Name: 'PTERIA',
    Symbol: 'PTERIA',
    CoinName: 'Pteria',
    FullName: 'Pteria (PTERIA)',
  },
  N0031: {
    Name: 'N0031',
    Symbol: 'N0031',
    CoinName: 'nYFI',
    FullName: 'nYFI (N0031)',
  },
  SUP8EME: {
    Name: 'SUP8EME',
    Symbol: 'SUP8EME',
    CoinName: 'SUP8EME Token',
    FullName: 'SUP8EME Token (SUP8EME)',
  },
  KP4R: {
    Name: 'KP4R',
    Symbol: 'KP4R',
    CoinName: 'Keep4r',
    FullName: 'Keep4r (KP4R)',
  },
  AUSCM: {
    Name: 'AUSCM',
    Symbol: 'AUSCM',
    CoinName: 'Auric Network',
    FullName: 'Auric Network (AUSCM)',
  },
  XFYI: {
    Name: 'XFYI',
    Symbol: 'XFYI',
    CoinName: 'XCredit',
    FullName: 'XCredit (XFYI)',
  },
  MOONDAY: {
    Name: 'MOONDAY',
    Symbol: 'MOONDAY',
    CoinName: 'Moonday Finance',
    FullName: 'Moonday Finance (MOONDAY)',
  },
  HD: {
    Name: 'HD',
    Symbol: 'HD',
    CoinName: 'HubDao',
    FullName: 'HubDao (HD)',
  },
  SPORE: {
    Name: 'SPORE',
    Symbol: 'SPORE',
    CoinName: 'Enoki Finance',
    FullName: 'Enoki Finance (SPORE)',
  },
  ELAD: {
    Name: 'ELAD',
    Symbol: 'ELAD',
    CoinName: 'ELAD Network',
    FullName: 'ELAD Network (ELAD)',
  },
  DFD: {
    Name: 'DFD',
    Symbol: 'DFD',
    CoinName: 'DefiDollar DAO',
    FullName: 'DefiDollar DAO (DFD)',
  },
  MSB: {
    Name: 'MSB',
    Symbol: 'MSB',
    CoinName: 'Misbloc',
    FullName: 'Misbloc (MSB)',
  },
  XAT: {
    Name: 'XAT',
    Symbol: 'XAT',
    CoinName: 'ShareAt',
    FullName: 'ShareAt (XAT)',
  },
  XDOT: {
    Name: 'XDOT',
    Symbol: 'XDOT',
    CoinName: 'DotBased',
    FullName: 'DotBased (XDOT)',
  },
  BOOB: {
    Name: 'BOOB',
    Symbol: 'BOOB',
    CoinName: 'BooBank',
    FullName: 'BooBank (BOOB)',
  },
  GBK: {
    Name: 'GBK',
    Symbol: 'GBK',
    CoinName: 'Goldblock',
    FullName: 'Goldblock (GBK)',
  },
  TAT: {
    Name: 'TAT',
    Symbol: 'TAT',
    CoinName: 'Tatcoin',
    FullName: 'Tatcoin (TAT)',
  },
  GSWAP: {
    Name: 'GSWAP',
    Symbol: 'GSWAP',
    CoinName: 'Gameswap',
    FullName: 'Gameswap (GSWAP)',
  },
  YYFI: {
    Name: 'YYFI',
    Symbol: 'YYFI',
    CoinName: 'YYFI.Protocol',
    FullName: 'YYFI.Protocol (YYFI)',
  },
  DFGL: {
    Name: 'DFGL',
    Symbol: 'DFGL',
    CoinName: 'DeFi Gold',
    FullName: 'DeFi Gold (DFGL)',
  },
  NVA: {
    Name: 'NVA',
    Symbol: 'NVA',
    CoinName: 'Neeva Defi',
    FullName: 'Neeva Defi (NVA)',
  },
  LEX: {
    Name: 'LEX',
    Symbol: 'LEX',
    CoinName: 'Elxis',
    FullName: 'Elxis (LEX)',
  },
  BLOODY: {
    Name: 'BLOODY',
    Symbol: 'BLOODY',
    CoinName: 'Bloody Token',
    FullName: 'Bloody Token (BLOODY)',
  },
  ASP: {
    Name: 'ASP',
    Symbol: 'ASP',
    CoinName: 'Aspire',
    FullName: 'Aspire (ASP)',
  },
  SYNLEV: {
    Name: 'SYNLEV',
    Symbol: 'SYNLEV',
    CoinName: 'SynLev',
    FullName: 'SynLev (SYNLEV)',
  },
  YSEC: {
    Name: 'YSEC',
    Symbol: 'YSEC',
    CoinName: 'Yearn Secure',
    FullName: 'Yearn Secure (YSEC)',
  },
  SMPL: {
    Name: 'SMPL',
    Symbol: 'SMPL',
    CoinName: 'SMPL Foundation',
    FullName: 'SMPL Foundation (SMPL)',
  },
  DOGDEFI: {
    Name: 'DOGDEFI',
    Symbol: 'DOGDEFI',
    CoinName: 'DogDeFiCoin',
    FullName: 'DogDeFiCoin (DOGDEFI)',
  },
  DVI: {
    Name: 'DVI',
    Symbol: 'DVI',
    CoinName: 'Dvision Network',
    FullName: 'Dvision Network (DVI)',
  },
  SPKL: {
    Name: 'SPKL',
    Symbol: 'SPKL',
    CoinName: 'SpokLottery',
    FullName: 'SpokLottery (SPKL)',
  },
  SMARTCREDIT: {
    Name: 'SMARTCREDIT',
    Symbol: 'SMARTCREDIT',
    CoinName: 'SmartCredit Token',
    FullName: 'SmartCredit Token (SMARTCREDIT)',
  },
  JEM: {
    Name: 'JEM',
    Symbol: 'JEM',
    CoinName: 'Jem',
    FullName: 'Jem (JEM)',
  },
  WCCX: {
    Name: 'WCCX',
    Symbol: 'WCCX',
    CoinName: 'Wrapped Conceal',
    FullName: 'Wrapped Conceal (WCCX)',
  },
  NAMI: {
    Name: 'NAMI',
    Symbol: 'NAMI',
    CoinName: 'Tsunami finance',
    FullName: 'Tsunami finance (NAMI)',
  },
  FNX: {
    Name: 'FNX',
    Symbol: 'FNX',
    CoinName: 'FinNexus',
    FullName: 'FinNexus (FNX)',
  },
  UTU: {
    Name: 'UTU',
    Symbol: 'UTU',
    CoinName: 'UTU Protocol',
    FullName: 'UTU Protocol (UTU)',
  },
  BITF: {
    Name: 'BITF',
    Symbol: 'BITF',
    CoinName: 'Bit Financial',
    FullName: 'Bit Financial (BITF)',
  },
  HEZ: {
    Name: 'HEZ',
    Symbol: 'HEZ',
    CoinName: 'Hermez Network Token',
    FullName: 'Hermez Network Token (HEZ)',
  },
  I9C: {
    Name: 'I9C',
    Symbol: 'I9C',
    CoinName: 'i9 Coin',
    FullName: 'i9 Coin (I9C)',
  },
  ALBT: {
    Name: 'ALBT',
    Symbol: 'ALBT',
    CoinName: 'AllianceBlock',
    FullName: 'AllianceBlock (ALBT)',
  },
  FIN: {
    Name: 'FIN',
    Symbol: 'FIN',
    CoinName: 'DeFiner',
    FullName: 'DeFiner (FIN)',
  },
  DMX: {
    Name: 'DMX',
    Symbol: 'DMX',
    CoinName: 'Dymmax',
    FullName: 'Dymmax (DMX)',
  },
  BAMBOO: {
    Name: 'BAMBOO',
    Symbol: 'BAMBOO',
    CoinName: 'BambooDeFi',
    FullName: 'BambooDeFi (BAMBOO)',
  },
  SAV3: {
    Name: 'SAV3',
    Symbol: 'SAV3',
    CoinName: 'SAV3',
    FullName: 'SAV3 (SAV3)',
  },
  PLOT: {
    Name: 'PLOT',
    Symbol: 'PLOT',
    CoinName: 'PlotX',
    FullName: 'PlotX (PLOT)',
  },
  SFI: {
    Name: 'SFI',
    Symbol: 'SFI',
    CoinName: 'Saffron.finance',
    FullName: 'Saffron.finance (SFI)',
  },
  WIS: {
    Name: 'WIS',
    Symbol: 'WIS',
    CoinName: 'Experty Wisdom Token',
    FullName: 'Experty Wisdom Token (WIS)',
  },
  GASP: {
    Name: 'GASP',
    Symbol: 'GASP',
    CoinName: 'gAsp',
    FullName: 'gAsp (GASP)',
  },
  DOUGH: {
    Name: 'DOUGH',
    Symbol: 'DOUGH',
    CoinName: 'PieDAO v2 (DOUGH)',
    FullName: 'PieDAO v2 (DOUGH) (DOUGH)',
  },
  IDEFI: {
    Name: 'IDEFI',
    Symbol: 'IDEFI',
    CoinName: 'Inverse DeFi Index',
    FullName: 'Inverse DeFi Index (IDEFI)',
  },
  HEGIC: {
    Name: 'HEGIC',
    Symbol: 'HEGIC',
    CoinName: 'Hegic',
    FullName: 'Hegic (HEGIC)',
  },
  OROP: {
    Name: 'OROP',
    Symbol: 'OROP',
    CoinName: 'ORO',
    FullName: 'ORO (OROP)',
  },
  BIND: {
    Name: 'BIND',
    Symbol: 'BIND',
    CoinName: 'Compendia',
    FullName: 'Compendia (BIND)',
  },
  WBIND: {
    Name: 'WBIND',
    Symbol: 'WBIND',
    CoinName: 'Wrapped BIND',
    FullName: 'Wrapped BIND (WBIND)',
  },
  UBX: {
    Name: 'UBX',
    Symbol: 'UBX',
    CoinName: 'UBIX Network',
    FullName: 'UBIX Network (UBX)',
  },
  NSURE: {
    Name: 'NSURE',
    Symbol: 'NSURE',
    CoinName: 'Nsure Network',
    FullName: 'Nsure Network (NSURE)',
  },
  KFC: {
    Name: 'KFC',
    Symbol: 'KFC',
    CoinName: 'Chicken',
    FullName: 'Chicken (KFC)',
  },
  KIMCHI: {
    Name: 'KIMCHI',
    Symbol: 'KIMCHI',
    CoinName: 'KIMCHI.finance',
    FullName: 'KIMCHI.finance (KIMCHI)',
  },
  SPA: {
    Name: 'SPA',
    Symbol: 'SPA',
    CoinName: 'Sperax',
    FullName: 'Sperax (SPA)',
  },
  SUPERTX: {
    Name: 'SUPERTX',
    Symbol: 'SUPERTX',
    CoinName: 'SuperTX',
    FullName: 'SuperTX (SUPERTX)',
  },
  METER: {
    Name: 'METER',
    Symbol: 'METER',
    CoinName: 'Meter Stable',
    FullName: 'Meter Stable (METER)',
  },
  MPH: {
    Name: 'MPH',
    Symbol: 'MPH',
    CoinName: 'Morpher',
    FullName: 'Morpher (MPH)',
  },
  PROXI: {
    Name: 'PROXI',
    Symbol: 'PROXI',
    CoinName: 'PROXI',
    FullName: 'PROXI (PROXI)',
  },
  API3: {
    Name: 'API3',
    Symbol: 'API3',
    CoinName: 'API3',
    FullName: 'API3 (API3)',
  },
  AXIS: {
    Name: 'AXIS',
    Symbol: 'AXIS',
    CoinName: 'Axis DeFi',
    FullName: 'Axis DeFi (AXIS)',
  },
  ETHY: {
    Name: 'ETHY',
    Symbol: 'ETHY',
    CoinName: 'Ethereum Yield',
    FullName: 'Ethereum Yield (ETHY)',
  },
  BNF: {
    Name: 'BNF',
    Symbol: 'BNF',
    CoinName: 'BonFi',
    FullName: 'BonFi (BNF)',
  },
  TMT: {
    Name: 'TMT',
    Symbol: 'TMT',
    CoinName: 'Tamy Token',
    FullName: 'Tamy Token (TMT)',
  },
  BSYS: {
    Name: 'BSYS',
    Symbol: 'BSYS',
    CoinName: 'BSYS',
    FullName: 'BSYS (BSYS)',
  },
  MTTCOIN: {
    Name: 'MTTCOIN',
    Symbol: 'MTTCOIN',
    CoinName: 'Money of Tommorow, Today',
    FullName: 'Money of Tommorow, Today (MTTCOIN)',
  },
  MW: {
    Name: 'MW',
    Symbol: 'MW',
    CoinName: 'MasterWin Coin',
    FullName: 'MasterWin Coin (MW)',
  },
  '88MPH': {
    Name: '88MPH',
    Symbol: '88MPH',
    CoinName: '88mph',
    FullName: '88mph (88MPH)',
  },
  BMP: {
    Name: 'BMP',
    Symbol: 'BMP',
    CoinName: 'Brother Music Platform',
    FullName: 'Brother Music Platform (BMP)',
  },
  GXT: {
    Name: 'GXT',
    Symbol: 'GXT',
    CoinName: 'Gem Exchange And Trading',
    FullName: 'Gem Exchange And Trading (GXT)',
  },
  HSS: {
    Name: 'HSS',
    Symbol: 'HSS',
    CoinName: 'Hashshare',
    FullName: 'Hashshare (HSS)',
  },
  YFPRO: {
    Name: 'YFPRO',
    Symbol: 'YFPRO',
    CoinName: 'YFPRO Finance',
    FullName: 'YFPRO Finance (YFPRO)',
  },
  CCE: {
    Name: 'CCE',
    Symbol: 'CCE',
    CoinName: 'CloudCoin',
    FullName: 'CloudCoin (CCE)',
  },
  RPZX: {
    Name: 'RPZX',
    Symbol: 'RPZX',
    CoinName: 'Rapidz',
    FullName: 'Rapidz (RPZX)',
  },
  GBCR: {
    Name: 'GBCR',
    Symbol: 'GBCR',
    CoinName: 'Gold BCR',
    FullName: 'Gold BCR (GBCR)',
  },
  TESTA: {
    Name: 'TESTA',
    Symbol: 'TESTA',
    CoinName: 'Testa',
    FullName: 'Testa (TESTA)',
  },
  ZLOT: {
    Name: 'ZLOT',
    Symbol: 'ZLOT',
    CoinName: 'zLOT Finance',
    FullName: 'zLOT Finance (ZLOT)',
  },
  EL: {
    Name: 'EL',
    Symbol: 'EL',
    CoinName: 'ELYSIA',
    FullName: 'ELYSIA (EL)',
  },
  GALATA: {
    Name: 'GALATA',
    Symbol: 'GALATA',
    CoinName: 'Galatasaray Fan Token',
    FullName: 'Galatasaray Fan Token (GALATA)',
  },
  NVZN: {
    Name: 'NVZN',
    Symbol: 'NVZN',
    CoinName: 'INVIZION',
    FullName: 'INVIZION (NVZN)',
  },
  BWB: {
    Name: 'BWB',
    Symbol: 'BWB',
    CoinName: 'Bit World Token',
    FullName: 'Bit World Token (BWB)',
  },
  FR: {
    Name: 'FR',
    Symbol: 'FR',
    CoinName: 'Freedom Reserve',
    FullName: 'Freedom Reserve (FR)',
  },
  MINDS: {
    Name: 'MINDS',
    Symbol: 'MINDS',
    CoinName: 'Minds',
    FullName: 'Minds (MINDS)',
  },
  WOZX: {
    Name: 'WOZX',
    Symbol: 'WOZX',
    CoinName: 'Efforce',
    FullName: 'Efforce (WOZX)',
  },
  MIR: {
    Name: 'MIR',
    Symbol: 'MIR',
    CoinName: 'Mirror Protocol',
    FullName: 'Mirror Protocol (MIR)',
  },
  BASE: {
    Name: 'BASE',
    Symbol: 'BASE',
    CoinName: 'Base Protocol',
    FullName: 'Base Protocol (BASE)',
  },
  SORA: {
    Name: 'SORA',
    Symbol: 'SORA',
    CoinName: 'Sora Validator Token',
    FullName: 'Sora Validator Token (SORA)',
  },
  SWFL: {
    Name: 'SWFL',
    Symbol: 'SWFL',
    CoinName: 'Swapfolio',
    FullName: 'Swapfolio (SWFL)',
  },
  COVER: {
    Name: 'COVER',
    Symbol: 'COVER',
    CoinName: 'Cover Protocol',
    FullName: 'Cover Protocol (COVER)',
  },
  EXRD: {
    Name: 'EXRD',
    Symbol: 'EXRD',
    CoinName: 'Radix',
    FullName: 'Radix (EXRD)',
  },
  WUST: {
    Name: 'WUST',
    Symbol: 'WUST',
    CoinName: 'Wrapped UST Token',
    FullName: 'Wrapped UST Token (WUST)',
  },
  BFI: {
    Name: 'BFI',
    Symbol: 'BFI',
    CoinName: 'BitDefi',
    FullName: 'BitDefi (BFI)',
  },
  WZEC: {
    Name: 'WZEC',
    Symbol: 'WZEC',
    CoinName: 'Wrapped Zcash',
    FullName: 'Wrapped Zcash (WZEC)',
  },
  WFIL: {
    Name: 'WFIL',
    Symbol: 'WFIL',
    CoinName: 'Wrapped Filecoin',
    FullName: 'Wrapped Filecoin (WFIL)',
  },
  ROOK: {
    Name: 'ROOK',
    Symbol: 'ROOK',
    CoinName: 'KeeperDAO',
    FullName: 'KeeperDAO (ROOK)',
  },
  CTI: {
    Name: 'CTI',
    Symbol: 'CTI',
    CoinName: 'ClinTex CTi',
    FullName: 'ClinTex CTi (CTI)',
  },
  BADGER: {
    Name: 'BADGER',
    Symbol: 'BADGER',
    CoinName: 'Badger DAO',
    FullName: 'Badger DAO (BADGER)',
  },
  RFI: {
    Name: 'RFI',
    Symbol: 'RFI',
    CoinName: 'reflect.finance',
    FullName: 'reflect.finance (RFI)',
  },
  XCUR: {
    Name: 'XCUR',
    Symbol: 'XCUR',
    CoinName: 'Curate',
    FullName: 'Curate (XCUR)',
  },
  DEFLCT: {
    Name: 'DEFLCT',
    Symbol: 'DEFLCT',
    CoinName: 'Deflect',
    FullName: 'Deflect (DEFLCT)',
  },
  GPT: {
    Name: 'GPT',
    Symbol: 'GPT',
    CoinName: 'Grace Period Token',
    FullName: 'Grace Period Token (GPT)',
  },
  CRYPTOE: {
    Name: 'CRYPTOE',
    Symbol: 'CRYPTOE',
    CoinName: 'Cryptoenter',
    FullName: 'Cryptoenter (CRYPTOE)',
  },
  YLD: {
    Name: 'YLD',
    Symbol: 'YLD',
    CoinName: 'YIELD App',
    FullName: 'YIELD App (YLD)',
  },
  GRT: {
    Name: 'GRT',
    Symbol: 'GRT',
    CoinName: 'The Graph',
    FullName: 'The Graph (GRT)',
  },
  POCKET: {
    Name: 'POCKET',
    Symbol: 'POCKET',
    CoinName: 'XPocket',
    FullName: 'XPocket (POCKET)',
  },
  OPENDAO: {
    Name: 'OPENDAO',
    Symbol: 'OPENDAO',
    CoinName: 'OPEN Governance Token',
    FullName: 'OPEN Governance Token (OPENDAO)',
  },
  AXN: {
    Name: 'AXN',
    Symbol: 'AXN',
    CoinName: 'Axion',
    FullName: 'Axion (AXN)',
  },
  GZIL: {
    Name: 'GZIL',
    Symbol: 'GZIL',
    CoinName: 'governance ZIL',
    FullName: 'governance ZIL (GZIL)',
  },
  NFXC: {
    Name: 'NFXC',
    Symbol: 'NFXC',
    CoinName: 'NFX Coin',
    FullName: 'NFX Coin (NFXC)',
  },
  KVNT: {
    Name: 'KVNT',
    Symbol: 'KVNT',
    CoinName: 'KVANT',
    FullName: 'KVANT (KVNT)',
  },
  BOND: {
    Name: 'BOND',
    Symbol: 'BOND',
    CoinName: 'BarnBridge',
    FullName: 'BarnBridge (BOND)',
  },
  POND: {
    Name: 'POND',
    Symbol: 'POND',
    CoinName: 'Marlin',
    FullName: 'Marlin (POND)',
  },
  '2BASED': {
    Name: '2BASED',
    Symbol: '2BASED',
    CoinName: '2Based Finance',
    FullName: '2Based Finance (2BASED)',
  },
  COINDEFI: {
    Name: 'COINDEFI',
    Symbol: 'COINDEFI',
    CoinName: 'Coin',
    FullName: 'Coin (COINDEFI)',
  },
  BVND: {
    Name: 'BVND',
    Symbol: 'BVND',
    CoinName: 'Binance VND',
    FullName: 'Binance VND (BVND)',
  },
  UNN: {
    Name: 'UNN',
    Symbol: 'UNN',
    CoinName: 'UNION Protocol Governance Token',
    FullName: 'UNION Protocol Governance Token (UNN)',
  },
  R34P: {
    Name: 'R34P',
    Symbol: 'R34P',
    CoinName: 'R34P',
    FullName: 'R34P (R34P)',
  },
  GRPL: {
    Name: 'GRPL',
    Symbol: 'GRPL',
    CoinName: 'Golden Ratio Per Liquidity',
    FullName: 'Golden Ratio Per Liquidity (GRPL)',
  },
  $ANRX: {
    Name: '$ANRX',
    Symbol: '$ANRX',
    CoinName: 'AnRKey X',
    FullName: 'AnRKey X ($ANRX)',
  },
  RFCTR: {
    Name: 'RFCTR',
    Symbol: 'RFCTR',
    CoinName: 'Reflector.Finance',
    FullName: 'Reflector.Finance (RFCTR)',
  },
  YVS: {
    Name: 'YVS',
    Symbol: 'YVS',
    CoinName: 'YVS.Finance',
    FullName: 'YVS.Finance (YVS)',
  },
  NHBTC: {
    Name: 'NHBTC',
    Symbol: 'NHBTC',
    CoinName: 'NEST Protocol',
    FullName: 'NEST Protocol (NHBTC)',
  },
  DXF: {
    Name: 'DXF',
    Symbol: 'DXF',
    CoinName: 'Dexfin',
    FullName: 'Dexfin (DXF)',
  },
  PYRK: {
    Name: 'PYRK',
    Symbol: 'PYRK',
    CoinName: 'Pyrk',
    FullName: 'Pyrk (PYRK)',
  },
  TBB: {
    Name: 'TBB',
    Symbol: 'TBB',
    CoinName: 'Trade Butler Bot',
    FullName: 'Trade Butler Bot (TBB)',
  },
  DYP: {
    Name: 'DYP',
    Symbol: 'DYP',
    CoinName: 'DeFi Yield Protocol',
    FullName: 'DeFi Yield Protocol (DYP)',
  },
  CHAL: {
    Name: 'CHAL',
    Symbol: 'CHAL',
    CoinName: 'Chalice Finance',
    FullName: 'Chalice Finance (CHAL)',
  },
  STETH: {
    Name: 'STETH',
    Symbol: 'STETH',
    CoinName: 'Staked Ether',
    FullName: 'Staked Ether (STETH)',
  },
  ATM: {
    Name: 'ATM',
    Symbol: 'ATM',
    CoinName: 'Atletico de Madrid Fan Token',
    FullName: 'Atletico de Madrid Fan Token (ATM)',
  },
  WCELO: {
    Name: 'WCELO',
    Symbol: 'WCELO',
    CoinName: 'Wrapped Celo',
    FullName: 'Wrapped Celo (WCELO)',
  },
  WCUSD: {
    Name: 'WCUSD',
    Symbol: 'WCUSD',
    CoinName: 'Wrapped Celo Dollar',
    FullName: 'Wrapped Celo Dollar (WCUSD)',
  },
  SPDR: {
    Name: 'SPDR',
    Symbol: 'SPDR',
    CoinName: 'SpiderDAO',
    FullName: 'SpiderDAO (SPDR)',
  },
  KCH: {
    Name: 'KCH',
    Symbol: 'KCH',
    CoinName: 'Keep Calm and Hodl',
    FullName: 'Keep Calm and Hodl (KCH)',
  },
  SKEY: {
    Name: 'SKEY',
    Symbol: 'SKEY',
    CoinName: 'SmartKey',
    FullName: 'SmartKey (SKEY)',
  },
  ESD: {
    Name: 'ESD',
    Symbol: 'ESD',
    CoinName: 'Empty Set Dollar',
    FullName: 'Empty Set Dollar (ESD)',
  },
  PYLON: {
    Name: 'PYLON',
    Symbol: 'PYLON',
    CoinName: 'Pylon Finance',
    FullName: 'Pylon Finance (PYLON)',
  },
  FLASH: {
    Name: 'FLASH',
    Symbol: 'FLASH',
    CoinName: 'Flashstake',
    FullName: 'Flashstake (FLASH)',
  },
  FRAX: {
    Name: 'FRAX',
    Symbol: 'FRAX',
    CoinName: 'Frax',
    FullName: 'Frax (FRAX)',
  },
  FXS: {
    Name: 'FXS',
    Symbol: 'FXS',
    CoinName: 'Frax Share',
    FullName: 'Frax Share (FXS)',
  },
  MIS: {
    Name: 'MIS',
    Symbol: 'MIS',
    CoinName: 'Mithril Share',
    FullName: 'Mithril Share (MIS)',
  },
  DSD: {
    Name: 'DSD',
    Symbol: 'DSD',
    CoinName: 'Dynamic Set Dollar',
    FullName: 'Dynamic Set Dollar (DSD)',
  },
  WIFI: {
    Name: 'WIFI',
    Symbol: 'WIFI',
    CoinName: 'Wifi Coin',
    FullName: 'Wifi Coin (WIFI)',
  },
  SPI: {
    Name: 'SPI',
    Symbol: 'SPI',
    CoinName: 'Shopping.io',
    FullName: 'Shopping.io (SPI)',
  },
  BAS: {
    Name: 'BAS',
    Symbol: 'BAS',
    CoinName: 'Basis Share',
    FullName: 'Basis Share (BAS)',
  },
  BAC: {
    Name: 'BAC',
    Symbol: 'BAC',
    CoinName: 'Basis Cash',
    FullName: 'Basis Cash (BAC)',
  },
  ONS: {
    Name: 'ONS',
    Symbol: 'ONS',
    CoinName: 'One Share',
    FullName: 'One Share (ONS)',
  },
  AME: {
    Name: 'AME',
    Symbol: 'AME',
    CoinName: 'Amepay',
    FullName: 'Amepay (AME)',
  },
  XED: {
    Name: 'XED',
    Symbol: 'XED',
    CoinName: 'Exeedme',
    FullName: 'Exeedme (XED)',
  },
  PIS: {
    Name: 'PIS',
    Symbol: 'PIS',
    CoinName: 'Polkainsure Finance',
    FullName: 'Polkainsure Finance (PIS)',
  },
  DGCL: {
    Name: 'DGCL',
    Symbol: 'DGCL',
    CoinName: 'DigiCol Token',
    FullName: 'DigiCol Token (DGCL)',
  },
  COVEROLD: {
    Name: 'COVEROLD',
    Symbol: 'COVEROLD',
    CoinName: ' Cover Protocol (old)',
    FullName: ' Cover Protocol (old) (COVEROLD)',
  },
  NFTX: {
    Name: 'NFTX',
    Symbol: 'NFTX',
    CoinName: 'NFTX',
    FullName: 'NFTX (NFTX)',
  },
  MCP: {
    Name: 'MCP',
    Symbol: 'MCP',
    CoinName: 'My Crypto Play',
    FullName: 'My Crypto Play (MCP)',
  },
  BAO: {
    Name: 'BAO',
    Symbol: 'BAO',
    CoinName: 'Bao Finance',
    FullName: 'Bao Finance (BAO)',
  },
  TSD: {
    Name: 'TSD',
    Symbol: 'TSD',
    CoinName: 'True Seigniorage Dollar',
    FullName: 'True Seigniorage Dollar (TSD)',
  },
  CKEK: {
    Name: 'CKEK',
    Symbol: 'CKEK',
    CoinName: 'CryptoKek',
    FullName: 'CryptoKek (CKEK)',
  },
  SAS: {
    Name: 'SAS',
    Symbol: 'SAS',
    CoinName: 'Stand Share',
    FullName: 'Stand Share (SAS)',
  },
  MSWAP: {
    Name: 'MSWAP',
    Symbol: 'MSWAP',
    CoinName: 'MoneySwap',
    FullName: 'MoneySwap (MSWAP)',
  },
  ZAI: {
    Name: 'ZAI',
    Symbol: 'ZAI',
    CoinName: 'Zero Collateral Dai',
    FullName: 'Zero Collateral Dai (ZAI)',
  },
  KOBE: {
    Name: 'KOBE',
    Symbol: 'KOBE',
    CoinName: 'Shabu Shabu',
    FullName: 'Shabu Shabu (KOBE)',
  },
  DNS: {
    Name: 'DNS',
    Symbol: 'DNS',
    CoinName: 'BitDNS',
    FullName: 'BitDNS (DNS)',
  },
  WHITE: {
    Name: 'WHITE',
    Symbol: 'WHITE',
    CoinName: 'Whiteheart',
    FullName: 'Whiteheart (WHITE)',
  },
  DFSOCIAL: {
    Name: 'DFSOCIAL',
    Symbol: 'DFSOCIAL',
    CoinName: 'DefiSocial (OLD)',
    FullName: 'DefiSocial (OLD) (DFSOCIAL)',
  },
  IGI: {
    Name: 'IGI',
    Symbol: 'IGI',
    CoinName: 'Igi',
    FullName: 'Igi (IGI)',
  },
  MERIDIAN: {
    Name: 'MERIDIAN',
    Symbol: 'MERIDIAN',
    CoinName: 'Meridian Network LOCK',
    FullName: 'Meridian Network LOCK (MERIDIAN)',
  },
  DVC: {
    Name: 'DVC',
    Symbol: 'DVC',
    CoinName: 'DragonVein',
    FullName: 'DragonVein (DVC)',
  },
  ARCA: {
    Name: 'ARCA',
    Symbol: 'ARCA',
    CoinName: 'Arca',
    FullName: 'Arca (ARCA)',
  },
  MIC: {
    Name: 'MIC',
    Symbol: 'MIC',
    CoinName: 'Mithril Cash',
    FullName: 'Mithril Cash (MIC)',
  },
  FORTUNE: {
    Name: 'FORTUNE',
    Symbol: 'FORTUNE',
    CoinName: 'Fortune',
    FullName: 'Fortune (FORTUNE)',
  },
  GUSDT: {
    Name: 'GUSDT',
    Symbol: 'GUSDT',
    CoinName: 'Global Utility Smart Digital Token',
    FullName: 'Global Utility Smart Digital Token (GUSDT)',
  },
  XDEF2: {
    Name: 'XDEF2',
    Symbol: 'XDEF2',
    CoinName: 'Xdef Finance',
    FullName: 'Xdef Finance (XDEF2)',
  },
  IBETH: {
    Name: 'IBETH',
    Symbol: 'IBETH',
    CoinName: 'Interest Bearing ETH',
    FullName: 'Interest Bearing ETH (IBETH)',
  },
  BRI: {
    Name: 'BRI',
    Symbol: 'BRI',
    CoinName: 'Baroin',
    FullName: 'Baroin (BRI)',
  },
  APECOIN: {
    Name: 'APECOIN',
    Symbol: 'APECOIN',
    CoinName: 'Asia Pacific Electronic Coin',
    FullName: 'Asia Pacific Electronic Coin (APECOIN)',
  },
  DAIQ: {
    Name: 'DAIQ',
    Symbol: 'DAIQ',
    CoinName: 'Daiquilibrium',
    FullName: 'Daiquilibrium (DAIQ)',
  },
  EAURIC: {
    Name: 'EAURIC',
    Symbol: 'EAURIC',
    CoinName: 'Eauric',
    FullName: 'Eauric (EAURIC)',
  },
  YETU: {
    Name: 'YETU',
    Symbol: 'YETU',
    CoinName: 'Yetucoin',
    FullName: 'Yetucoin (YETU)',
  },
  'TERN.ETH': {
    Name: 'TERN.ETH',
    Symbol: 'TERN.ETH',
    CoinName: 'Ternio ERC20',
    FullName: 'Ternio ERC20 (TERN.ETH)',
  },
  QARK: {
    Name: 'QARK',
    Symbol: 'QARK',
    CoinName: 'QANplatform',
    FullName: 'QANplatform (QARK)',
  },
  CUDOS: {
    Name: 'CUDOS',
    Symbol: 'CUDOS',
    CoinName: 'Cudos',
    FullName: 'Cudos (CUDOS)',
  },
  HGOLD: {
    Name: 'HGOLD',
    Symbol: 'HGOLD',
    CoinName: 'HollyGold',
    FullName: 'HollyGold (HGOLD)',
  },
  UMX: {
    Name: 'UMX',
    Symbol: 'UMX',
    CoinName: 'UniMex Network',
    FullName: 'UniMex Network (UMX)',
  },
  DSTR: {
    Name: 'DSTR',
    Symbol: 'DSTR',
    CoinName: 'Dynamic Supply Tracker',
    FullName: 'Dynamic Supply Tracker (DSTR)',
  },
  UNIDX: {
    Name: 'UNIDX',
    Symbol: 'UNIDX',
    CoinName: 'UniDex',
    FullName: 'UniDex (UNIDX)',
  },
  STBU: {
    Name: 'STBU',
    Symbol: 'STBU',
    CoinName: 'Stobox Token',
    FullName: 'Stobox Token (STBU)',
  },
  KCAL: {
    Name: 'KCAL',
    Symbol: 'KCAL',
    CoinName: 'Phantasma Energy',
    FullName: 'Phantasma Energy (KCAL)',
  },
  SPC: {
    Name: 'SPC',
    Symbol: 'SPC',
    CoinName: 'SpaceChain ERC20',
    FullName: 'SpaceChain ERC20 (SPC)',
  },
  BAGS: {
    Name: 'BAGS',
    Symbol: 'BAGS',
    CoinName: 'Basis Gold Share',
    FullName: 'Basis Gold Share (BAGS)',
  },
  PUSD: {
    Name: 'PUSD',
    Symbol: 'PUSD',
    CoinName: 'PegsUSD',
    FullName: 'PegsUSD (PUSD)',
  },
  GERA: {
    Name: 'GERA',
    Symbol: 'GERA',
    CoinName: 'Gera Coin',
    FullName: 'Gera Coin (GERA)',
  },
  HELMET: {
    Name: 'HELMET',
    Symbol: 'HELMET',
    CoinName: 'Helmet Insure',
    FullName: 'Helmet Insure (HELMET)',
  },
  COMBO: {
    Name: 'COMBO',
    Symbol: 'COMBO',
    CoinName: 'Furucombo',
    FullName: 'Furucombo (COMBO)',
  },
  YFTE: {
    Name: 'YFTE',
    Symbol: 'YFTE',
    CoinName: 'YFTether',
    FullName: 'YFTether (YFTE)',
  },
  VRX: {
    Name: 'VRX',
    Symbol: 'VRX',
    CoinName: 'Verox',
    FullName: 'Verox (VRX)',
  },
  GLCH: {
    Name: 'GLCH',
    Symbol: 'GLCH',
    CoinName: 'Glitch',
    FullName: 'Glitch (GLCH)',
  },
  YOP: {
    Name: 'YOP',
    Symbol: 'YOP',
    CoinName: 'Yield Optimization Platform & Protocol',
    FullName: 'Yield Optimization Platform & Protocol (YOP)',
  },
  ROYA: {
    Name: 'ROYA',
    Symbol: 'ROYA',
    CoinName: 'Royale',
    FullName: 'Royale (ROYA)',
  },
  TRU: {
    Name: 'TRU',
    Symbol: 'TRU',
    CoinName: 'TrueFi',
    FullName: 'TrueFi (TRU)',
  },
  RIGEL: {
    Name: 'RIGEL',
    Symbol: 'RIGEL',
    CoinName: 'Rigel Finance',
    FullName: 'Rigel Finance (RIGEL)',
  },
  NDX: {
    Name: 'NDX',
    Symbol: 'NDX',
    CoinName: 'Indexed Finance',
    FullName: 'Indexed Finance (NDX)',
  },
  PEGS: {
    Name: 'PEGS',
    Symbol: 'PEGS',
    CoinName: 'PegShares',
    FullName: 'PegShares (PEGS)',
  },
  CSX: {
    Name: 'CSX',
    Symbol: 'CSX',
    CoinName: 'Coinstox',
    FullName: 'Coinstox (CSX)',
  },
  LKR: {
    Name: 'LKR',
    Symbol: 'LKR',
    CoinName: 'Polkalokr',
    FullName: 'Polkalokr (LKR)',
  },
  DLPD: {
    Name: 'DLPD',
    Symbol: 'DLPD',
    CoinName: 'DLP Duck Token',
    FullName: 'DLP Duck Token (DLPD)',
  },
  YFO: {
    Name: 'YFO',
    Symbol: 'YFO',
    CoinName: 'YFIONE',
    FullName: 'YFIONE (YFO)',
  },
  DEFI5: {
    Name: 'DEFI5',
    Symbol: 'DEFI5',
    CoinName: 'DEFI Top 5 Tokens Index',
    FullName: 'DEFI Top 5 Tokens Index (DEFI5)',
  },
  CURRY: {
    Name: 'CURRY',
    Symbol: 'CURRY',
    CoinName: 'CurrySwap',
    FullName: 'CurrySwap (CURRY)',
  },
  USDFL: {
    Name: 'USDFL',
    Symbol: 'USDFL',
    CoinName: 'USDFreeLiquidity',
    FullName: 'USDFreeLiquidity (USDFL)',
  },
  FL: {
    Name: 'FL',
    Symbol: 'FL',
    CoinName: 'Freeliquid',
    FullName: 'Freeliquid (FL)',
  },
  XSGD: {
    Name: 'XSGD',
    Symbol: 'XSGD',
    CoinName: 'XSGD',
    FullName: 'XSGD (XSGD)',
  },
  KOIN: {
    Name: 'KOIN',
    Symbol: 'KOIN',
    CoinName: 'Koinos',
    FullName: 'Koinos (KOIN)',
  },
  NTB: {
    Name: 'NTB',
    Symbol: 'NTB',
    CoinName: 'TokenAsset',
    FullName: 'TokenAsset (NTB)',
  },
  INFI: {
    Name: 'INFI',
    Symbol: 'INFI',
    CoinName: 'Insured Finance',
    FullName: 'Insured Finance (INFI)',
  },
  BSGS: {
    Name: 'BSGS',
    Symbol: 'BSGS',
    CoinName: 'Basis Gold Share',
    FullName: 'Basis Gold Share (BSGS)',
  },
  NORD: {
    Name: 'NORD',
    Symbol: 'NORD',
    CoinName: 'Nord Finance',
    FullName: 'Nord Finance (NORD)',
  },
  NIF: {
    Name: 'NIF',
    Symbol: 'NIF',
    CoinName: 'Unifty',
    FullName: 'Unifty (NIF)',
  },
  GFARM2: {
    Name: 'GFARM2',
    Symbol: 'GFARM2',
    CoinName: 'Gains V2',
    FullName: 'Gains V2 (GFARM2)',
  },
  ELX: {
    Name: 'ELX',
    Symbol: 'ELX',
    CoinName: 'Energy Ledger',
    FullName: 'Energy Ledger (ELX)',
  },
  MASS: {
    Name: 'MASS',
    Symbol: 'MASS',
    CoinName: 'MASS',
    FullName: 'MASS (MASS)',
  },
  PHOON: {
    Name: 'PHOON',
    Symbol: 'PHOON',
    CoinName: 'Typhoon Cash',
    FullName: 'Typhoon Cash (PHOON)',
  },
  CVR: {
    Name: 'CVR',
    Symbol: 'CVR',
    CoinName: 'Polkacover',
    FullName: 'Polkacover (CVR)',
  },
  CAVO: {
    Name: 'CAVO',
    Symbol: 'CAVO',
    CoinName: 'Excavo Finance',
    FullName: 'Excavo Finance (CAVO)',
  },
  UNDG: {
    Name: 'UNDG',
    Symbol: 'UNDG',
    CoinName: 'UniDexGas',
    FullName: 'UniDexGas (UNDG)',
  },
  CC10: {
    Name: 'CC10',
    Symbol: 'CC10',
    CoinName: 'Cryptocurrency Top 10 Tokens Index',
    FullName: 'Cryptocurrency Top 10 Tokens Index (CC10)',
  },
  MANDALA: {
    Name: 'MANDALA',
    Symbol: 'MANDALA',
    CoinName: 'Mandala Exchange Token',
    FullName: 'Mandala Exchange Token (MANDALA)',
  },
  ARMOR: {
    Name: 'ARMOR',
    Symbol: 'ARMOR',
    CoinName: 'ARMOR',
    FullName: 'ARMOR (ARMOR)',
  },
  ARNXM: {
    Name: 'ARNXM',
    Symbol: 'ARNXM',
    CoinName: 'Armor NXM',
    FullName: 'Armor NXM (ARNXM)',
  },
  SNOW: {
    Name: 'SNOW',
    Symbol: 'SNOW',
    CoinName: 'Snowswap',
    FullName: 'Snowswap (SNOW)',
  },
  DIGG: {
    Name: 'DIGG',
    Symbol: 'DIGG',
    CoinName: 'DIGG',
    FullName: 'DIGG (DIGG)',
  },
  DUELERS: {
    Name: 'DUELERS',
    Symbol: 'DUELERS',
    CoinName: 'Block Duelers',
    FullName: 'Block Duelers (DUELERS)',
  },
  BTNYX: {
    Name: 'BTNYX',
    Symbol: 'BTNYX',
    CoinName: 'BitOnyx Token',
    FullName: 'BitOnyx Token (BTNYX)',
  },
  PBR: {
    Name: 'PBR',
    Symbol: 'PBR',
    CoinName: 'PolkaBridge',
    FullName: 'PolkaBridge (PBR)',
  },
  FEY: {
    Name: 'FEY',
    Symbol: 'FEY',
    CoinName: 'Feyorra',
    FullName: 'Feyorra (FEY)',
  },
  HH: {
    Name: 'HH',
    Symbol: 'HH',
    CoinName: 'Holyheld',
    FullName: 'Holyheld (HH)',
  },
  DBUND: {
    Name: 'DBUND',
    Symbol: 'DBUND',
    CoinName: 'DarkBundles',
    FullName: 'DarkBundles (DBUND)',
  },
  DOGY: {
    Name: 'DOGY',
    Symbol: 'DOGY',
    CoinName: 'DogeYield',
    FullName: 'DogeYield (DOGY)',
  },
  NC: {
    Name: 'NC',
    Symbol: 'NC',
    CoinName: 'Nayuta Coin',
    FullName: 'Nayuta Coin (NC)',
  },
  R3FI: {
    Name: 'R3FI',
    Symbol: 'R3FI',
    CoinName: 'r3fi.finance',
    FullName: 'r3fi.finance (R3FI)',
  },
  PAID: {
    Name: 'PAID',
    Symbol: 'PAID',
    CoinName: 'PAID Network',
    FullName: 'PAID Network (PAID)',
  },
  TROP: {
    Name: 'TROP',
    Symbol: 'TROP',
    CoinName: 'Interop',
    FullName: 'Interop (TROP)',
  },
  STING: {
    Name: 'STING',
    Symbol: 'STING',
    CoinName: 'Sting',
    FullName: 'Sting (STING)',
  },
  POC: {
    Name: 'POC',
    Symbol: 'POC',
    CoinName: 'POC Blockchain',
    FullName: 'POC Blockchain (POC)',
  },
  NAWA: {
    Name: 'NAWA',
    Symbol: 'NAWA',
    CoinName: 'Narwhale.finance',
    FullName: 'Narwhale.finance (NAWA)',
  },
  YPIE: {
    Name: 'YPIE',
    Symbol: 'YPIE',
    CoinName: 'PieDAO Yearn Ecosystem Pie',
    FullName: 'PieDAO Yearn Ecosystem Pie (YPIE)',
  },
  EOX: {
    Name: 'EOX',
    Symbol: 'EOX',
    CoinName: 'EXTRA ORDINARY',
    FullName: 'EXTRA ORDINARY (EOX)',
  },
  QFI: {
    Name: 'QFI',
    Symbol: 'QFI',
    CoinName: 'QFinance',
    FullName: 'QFinance (QFI)',
  },
  YFDAI: {
    Name: 'YFDAI',
    Symbol: 'YFDAI',
    CoinName: 'YfDAI.finance',
    FullName: 'YfDAI.finance (YFDAI)',
  },
  LOOT: {
    Name: 'LOOT',
    Symbol: 'LOOT',
    CoinName: 'NFTLootBox',
    FullName: 'NFTLootBox (LOOT)',
  },
  BIXB: {
    Name: 'BIXB',
    Symbol: 'BIXB',
    CoinName: 'BIXBCOIN',
    FullName: 'BIXBCOIN (BIXB)',
  },
  MAPS: {
    Name: 'MAPS',
    Symbol: 'MAPS',
    CoinName: 'MAPS',
    FullName: 'MAPS (MAPS)',
  },
  XFUND: {
    Name: 'XFUND',
    Symbol: 'XFUND',
    CoinName: 'xFund',
    FullName: 'xFund (XFUND)',
  },
  SX: {
    Name: 'SX',
    Symbol: 'SX',
    CoinName: 'SX Network',
    FullName: 'SX Network (SX)',
  },
  TKMN: {
    Name: 'TKMN',
    Symbol: 'TKMN',
    CoinName: 'Tokemon',
    FullName: 'Tokemon (TKMN)',
  },
  WSCRT: {
    Name: 'WSCRT',
    Symbol: 'WSCRT',
    CoinName: 'Secret ERC20',
    FullName: 'Secret ERC20 (WSCRT)',
  },
  U8D: {
    Name: 'U8D',
    Symbol: 'U8D',
    CoinName: 'Universal Dollar',
    FullName: 'Universal Dollar (U8D)',
  },
  PGU: {
    Name: 'PGU',
    Symbol: 'PGU',
    CoinName: 'Polyient Games Unity',
    FullName: 'Polyient Games Unity (PGU)',
  },
  FAMILY: {
    Name: 'FAMILY',
    Symbol: 'FAMILY',
    CoinName: 'The Bitcoin Family',
    FullName: 'The Bitcoin Family (FAMILY)',
  },
  KHM: {
    Name: 'KHM',
    Symbol: 'KHM',
    CoinName: 'Kohima',
    FullName: 'Kohima (KHM)',
  },
  OPIUM: {
    Name: 'OPIUM',
    Symbol: 'OPIUM',
    CoinName: 'Opium',
    FullName: 'Opium (OPIUM)',
  },
  RBIS: {
    Name: 'RBIS',
    Symbol: 'RBIS',
    CoinName: 'ArbiSmart',
    FullName: 'ArbiSmart (RBIS)',
  },
  GASG: {
    Name: 'GASG',
    Symbol: 'GASG',
    CoinName: 'Gasgains',
    FullName: 'Gasgains (GASG)',
  },
  LDFI: {
    Name: 'LDFI',
    Symbol: 'LDFI',
    CoinName: 'LenDeFi Token',
    FullName: 'LenDeFi Token (LDFI)',
  },
  KBTC: {
    Name: 'KBTC',
    Symbol: 'KBTC',
    CoinName: 'Klondike BTC',
    FullName: 'Klondike BTC (KBTC)',
  },
  KLON: {
    Name: 'KLON',
    Symbol: 'KLON',
    CoinName: 'Klondike Finance',
    FullName: 'Klondike Finance (KLON)',
  },
  KBOND: {
    Name: 'KBOND',
    Symbol: 'KBOND',
    CoinName: 'Klondike Bond',
    FullName: 'Klondike Bond (KBOND)',
  },
  IDEA: {
    Name: 'IDEA',
    Symbol: 'IDEA',
    CoinName: 'Ideaology',
    FullName: 'Ideaology (IDEA)',
  },
  IMPULSE: {
    Name: 'IMPULSE',
    Symbol: 'IMPULSE',
    CoinName: 'IMPULSE by FDR',
    FullName: 'IMPULSE by FDR (IMPULSE)',
  },
  MYID: {
    Name: 'MYID',
    Symbol: 'MYID',
    CoinName: 'My Identity Coin',
    FullName: 'My Identity Coin (MYID)',
  },
  NMX: {
    Name: 'NMX',
    Symbol: 'NMX',
    CoinName: 'Nominex Token',
    FullName: 'Nominex Token (NMX)',
  },
  XLAB: {
    Name: 'XLAB',
    Symbol: 'XLAB',
    CoinName: 'Xceltoken Plus',
    FullName: 'Xceltoken Plus (XLAB)',
  },
  ALH: {
    Name: 'ALH',
    Symbol: 'ALH',
    CoinName: 'AlloHash',
    FullName: 'AlloHash (ALH)',
  },
  LIT: {
    Name: 'LIT',
    Symbol: 'LIT',
    CoinName: 'Litentry',
    FullName: 'Litentry (LIT)',
  },
  BNIX: {
    Name: 'BNIX',
    Symbol: 'BNIX',
    CoinName: 'BNIX Token',
    FullName: 'BNIX Token (BNIX)',
  },
  GROWTH: {
    Name: 'GROWTH',
    Symbol: 'GROWTH',
    CoinName: 'GROWTH DeFi',
    FullName: 'GROWTH DeFi (GROWTH)',
  },
  IPDN: {
    Name: 'IPDN',
    Symbol: 'IPDN',
    CoinName: 'IPDnetwork',
    FullName: 'IPDnetwork (IPDN)',
  },
  BXF: {
    Name: 'BXF',
    Symbol: 'BXF',
    CoinName: 'BlackFort Token',
    FullName: 'BlackFort Token (BXF)',
  },
  DCX: {
    Name: 'DCX',
    Symbol: 'DCX',
    CoinName: 'DeCEX',
    FullName: 'DeCEX (DCX)',
  },
  KWIK: {
    Name: 'KWIK',
    Symbol: 'KWIK',
    CoinName: 'KwikSwap',
    FullName: 'KwikSwap (KWIK)',
  },
  XTM: {
    Name: 'XTM',
    Symbol: 'XTM',
    CoinName: 'TORUM',
    FullName: 'TORUM (XTM)',
  },
  CLIQ: {
    Name: 'CLIQ',
    Symbol: 'CLIQ',
    CoinName: 'DefiCliq',
    FullName: 'DefiCliq (CLIQ)',
  },
  UOP: {
    Name: 'UOP',
    Symbol: 'UOP',
    CoinName: 'Utopia Genesis Foundation',
    FullName: 'Utopia Genesis Foundation (UOP)',
  },
  APY: {
    Name: 'APY',
    Symbol: 'APY',
    CoinName: 'APY.Finance',
    FullName: 'APY.Finance (APY)',
  },
  RAZOR: {
    Name: 'RAZOR',
    Symbol: 'RAZOR',
    CoinName: 'Razor Network',
    FullName: 'Razor Network (RAZOR)',
  },
  NUX: {
    Name: 'NUX',
    Symbol: 'NUX',
    CoinName: 'Peanut',
    FullName: 'Peanut (NUX)',
  },
  UMB: {
    Name: 'UMB',
    Symbol: 'UMB',
    CoinName: 'Umbrella Network',
    FullName: 'Umbrella Network (UMB)',
  },
  CARR: {
    Name: 'CARR',
    Symbol: 'CARR',
    CoinName: 'Carnomaly',
    FullName: 'Carnomaly (CARR)',
  },
  AXNT: {
    Name: 'AXNT',
    Symbol: 'AXNT',
    CoinName: 'Axentro',
    FullName: 'Axentro (AXNT)',
  },
  COINSL: {
    Name: 'COINSL',
    Symbol: 'COINSL',
    CoinName: 'CoinsLoot',
    FullName: 'CoinsLoot (COINSL)',
  },
  MDH: {
    Name: 'MDH',
    Symbol: 'MDH',
    CoinName: 'Telemedicoin',
    FullName: 'Telemedicoin (MDH)',
  },
  VAIOT: {
    Name: 'VAIOT',
    Symbol: 'VAIOT',
    CoinName: 'VAIOT',
    FullName: 'VAIOT (VAIOT)',
  },
  JUN: {
    Name: 'JUN',
    Symbol: 'JUN',
    CoinName: 'Jun "M" Coin',
    FullName: 'Jun "M" Coin (JUN)',
  },
  CIVIT: {
    Name: 'CIVIT',
    Symbol: 'CIVIT',
    CoinName: 'Civitas Protocol',
    FullName: 'Civitas Protocol (CIVIT)',
  },
  WORLD: {
    Name: 'WORLD',
    Symbol: 'WORLD',
    CoinName: 'World Token',
    FullName: 'World Token (WORLD)',
  },
  BMI: {
    Name: 'BMI',
    Symbol: 'BMI',
    CoinName: 'Bridge Mutual',
    FullName: 'Bridge Mutual (BMI)',
  },
  CWS: {
    Name: 'CWS',
    Symbol: 'CWS',
    CoinName: 'Crowns',
    FullName: 'Crowns (CWS)',
  },
  $TRDL: {
    Name: '$TRDL',
    Symbol: '$TRDL',
    CoinName: 'Strudel Finance',
    FullName: 'Strudel Finance ($TRDL)',
  },
  GUM: {
    Name: 'GUM',
    Symbol: 'GUM',
    CoinName: 'Gourmet Galaxy',
    FullName: 'Gourmet Galaxy (GUM)',
  },
  XNO: {
    Name: 'XNO',
    Symbol: 'XNO',
    CoinName: 'Xeno Token',
    FullName: 'Xeno Token (XNO)',
  },
  GOGO: {
    Name: 'GOGO',
    Symbol: 'GOGO',
    CoinName: 'GOGO Finance',
    FullName: 'GOGO Finance (GOGO)',
  },
  REST: {
    Name: 'REST',
    Symbol: 'REST',
    CoinName: 'Restore',
    FullName: 'Restore (REST)',
  },
  CARROT: {
    Name: 'CARROT',
    Symbol: 'CARROT',
    CoinName: 'CarrotSwap',
    FullName: 'CarrotSwap (CARROT)',
  },
  DVG: {
    Name: 'DVG',
    Symbol: 'DVG',
    CoinName: 'DAOventures',
    FullName: 'DAOventures (DVG)',
  },
  CURIO: {
    Name: 'CURIO',
    Symbol: 'CURIO',
    CoinName: 'Curio Governance',
    FullName: 'Curio Governance (CURIO)',
  },
  BFLY: {
    Name: 'BFLY',
    Symbol: 'BFLY',
    CoinName: 'Butterfly Protocol',
    FullName: 'Butterfly Protocol (BFLY)',
  },
  PMEER: {
    Name: 'PMEER',
    Symbol: 'PMEER',
    CoinName: 'Qitmeer',
    FullName: 'Qitmeer (PMEER)',
  },
  METH: {
    Name: 'METH',
    Symbol: 'METH',
    CoinName: 'Farming Bad',
    FullName: 'Farming Bad (METH)',
  },
  RGP: {
    Name: 'RGP',
    Symbol: 'RGP',
    CoinName: 'Rigel Protocol',
    FullName: 'Rigel Protocol (RGP)',
  },
  ATR: {
    Name: 'ATR',
    Symbol: 'ATR',
    CoinName: 'Ather',
    FullName: 'Ather (ATR)',
  },
  MP3: {
    Name: 'MP3',
    Symbol: 'MP3',
    CoinName: 'MP3',
    FullName: 'MP3 (MP3)',
  },
  DEFLA: {
    Name: 'DEFLA',
    Symbol: 'DEFLA',
    CoinName: 'Defla',
    FullName: 'Defla (DEFLA)',
  },
  FEG: {
    Name: 'FEG',
    Symbol: 'FEG',
    CoinName: 'FEG Token',
    FullName: 'FEG Token (FEG)',
  },
  BUILDIN: {
    Name: 'BUILDIN',
    Symbol: 'BUILDIN',
    CoinName: 'Buildin Token',
    FullName: 'Buildin Token (BUILDIN)',
  },
  SVX: {
    Name: 'SVX',
    Symbol: 'SVX',
    CoinName: 'Savix',
    FullName: 'Savix (SVX)',
  },
  ALIAS: {
    Name: 'ALIAS',
    Symbol: 'ALIAS',
    CoinName: 'Alias',
    FullName: 'Alias (ALIAS)',
  },
  HFI: {
    Name: 'HFI',
    Symbol: 'HFI',
    CoinName: 'Holder Finance',
    FullName: 'Holder Finance (HFI)',
  },
  AGT: {
    Name: 'AGT',
    Symbol: 'AGT',
    CoinName: 'aGifttoken',
    FullName: 'aGifttoken (AGT)',
  },
  XGT: {
    Name: 'XGT',
    Symbol: 'XGT',
    CoinName: 'Xion Finance',
    FullName: 'Xion Finance (XGT)',
  },
  BT: {
    Name: 'BT',
    Symbol: 'BT',
    CoinName: 'BT.Finance',
    FullName: 'BT.Finance (BT)',
  },
  TFT: {
    Name: 'TFT',
    Symbol: 'TFT',
    CoinName: 'The Famous Token',
    FullName: 'The Famous Token (TFT)',
  },
  ETH2: {
    Name: 'ETH2',
    Symbol: 'ETH2',
    CoinName: 'Eth 2.0 Staking by Pool-X',
    FullName: 'Eth 2.0 Staking by Pool-X (ETH2)',
  },
  CTASK: {
    Name: 'CTASK',
    Symbol: 'CTASK',
    CoinName: 'CryptoTask',
    FullName: 'CryptoTask (CTASK)',
  },
  DAO: {
    Name: 'DAO',
    Symbol: 'DAO',
    CoinName: 'DAO Maker',
    FullName: 'DAO Maker (DAO)',
  },
  ZYTARA: {
    Name: 'ZYTARA',
    Symbol: 'ZYTARA',
    CoinName: 'Zytara dollar',
    FullName: 'Zytara dollar (ZYTARA)',
  },
  RPT: {
    Name: 'RPT',
    Symbol: 'RPT',
    CoinName: 'Rug Proof',
    FullName: 'Rug Proof (RPT)',
  },
  GOVI: {
    Name: 'GOVI',
    Symbol: 'GOVI',
    CoinName: 'Govi',
    FullName: 'Govi (GOVI)',
  },
  NFTXHI: {
    Name: 'NFTXHI',
    Symbol: 'NFTXHI',
    CoinName: 'NFTX Hashmasks Index',
    FullName: 'NFTX Hashmasks Index (NFTXHI)',
  },
  KTT: {
    Name: 'KTT',
    Symbol: 'KTT',
    CoinName: 'K-Tune',
    FullName: 'K-Tune (KTT)',
  },
  STRONG: {
    Name: 'STRONG',
    Symbol: 'STRONG',
    CoinName: 'Strong',
    FullName: 'Strong (STRONG)',
  },
  OUR: {
    Name: 'OUR',
    Symbol: 'OUR',
    CoinName: 'Our Pay',
    FullName: 'Our Pay (OUR)',
  },
  SOAK: {
    Name: 'SOAK',
    Symbol: 'SOAK',
    CoinName: 'Soak Token',
    FullName: 'Soak Token (SOAK)',
  },
  FAI: {
    Name: 'FAI',
    Symbol: 'FAI',
    CoinName: 'Fairum',
    FullName: 'Fairum (FAI)',
  },
  JULD: {
    Name: 'JULD',
    Symbol: 'JULD',
    CoinName: 'JulSwap',
    FullName: 'JulSwap (JULD)',
  },
  PICA: {
    Name: 'PICA',
    Symbol: 'PICA',
    CoinName: 'PicaArtMoney',
    FullName: 'PicaArtMoney (PICA)',
  },
  SST: {
    Name: 'SST',
    Symbol: 'SST',
    CoinName: 'SIMBA Storage Token',
    FullName: 'SIMBA Storage Token (SST)',
  },
  RISEP: {
    Name: 'RISEP',
    Symbol: 'RISEP',
    CoinName: 'Rise Protocol',
    FullName: 'Rise Protocol (RISEP)',
  },
  MDX: {
    Name: 'MDX',
    Symbol: 'MDX',
    CoinName: 'Mdex',
    FullName: 'Mdex (MDX)',
  },
  RGT: {
    Name: 'RGT',
    Symbol: 'RGT',
    CoinName: 'Rari Governance Token',
    FullName: 'Rari Governance Token (RGT)',
  },
  ID: {
    Name: 'ID',
    Symbol: 'ID',
    CoinName: 'Everest',
    FullName: 'Everest (ID)',
  },
  UNISTAKE: {
    Name: 'UNISTAKE',
    Symbol: 'UNISTAKE',
    CoinName: 'Unistake',
    FullName: 'Unistake (UNISTAKE)',
  },
  FDR: {
    Name: 'FDR',
    Symbol: 'FDR',
    CoinName: 'French Digital Reserve',
    FullName: 'French Digital Reserve (FDR)',
  },
  PREMIA: {
    Name: 'PREMIA',
    Symbol: 'PREMIA',
    CoinName: 'Premia',
    FullName: 'Premia (PREMIA)',
  },
  SGT: {
    Name: 'SGT',
    Symbol: 'SGT',
    CoinName: 'SharedStake Governance Token',
    FullName: 'SharedStake Governance Token (SGT)',
  },
  BDAY: {
    Name: 'BDAY',
    Symbol: 'BDAY',
    CoinName: 'Birthday Cake',
    FullName: 'Birthday Cake (BDAY)',
  },
  B20: {
    Name: 'B20',
    Symbol: 'B20',
    CoinName: 'B20',
    FullName: 'B20 (B20)',
  },
  BRY: {
    Name: 'BRY',
    Symbol: 'BRY',
    CoinName: 'Berry Data',
    FullName: 'Berry Data (BRY)',
  },
  BREW: {
    Name: 'BREW',
    Symbol: 'BREW',
    CoinName: 'CafeSwap Token',
    FullName: 'CafeSwap Token (BREW)',
  },
  OLY: {
    Name: 'OLY',
    Symbol: 'OLY',
    CoinName: 'Olyseum',
    FullName: 'Olyseum (OLY)',
  },
  PRVS: {
    Name: 'PRVS',
    Symbol: 'PRVS',
    CoinName: 'Previse',
    FullName: 'Previse (PRVS)',
  },
  MFI: {
    Name: 'MFI',
    Symbol: 'MFI',
    CoinName: 'Marginswap',
    FullName: 'Marginswap (MFI)',
  },
  QUICK: {
    Name: 'QUICK',
    Symbol: 'QUICK',
    CoinName: 'Quickswap',
    FullName: 'Quickswap (QUICK)',
  },
  DDS: {
    Name: 'DDS',
    Symbol: 'DDS',
    CoinName: 'DDS.Store',
    FullName: 'DDS.Store (DDS)',
  },
  DEXM: {
    Name: 'DEXM',
    Symbol: 'DEXM',
    CoinName: 'Dexmex',
    FullName: 'Dexmex (DEXM)',
  },
  FYZNFT: {
    Name: 'FYZNFT',
    Symbol: 'FYZNFT',
    CoinName: 'Fyooz NFT',
    FullName: 'Fyooz NFT (FYZNFT)',
  },
  PBASE: {
    Name: 'PBASE',
    Symbol: 'PBASE',
    CoinName: 'Polkabase',
    FullName: 'Polkabase (PBASE)',
  },
  MOD: {
    Name: 'MOD',
    Symbol: 'MOD',
    CoinName: 'Modefi',
    FullName: 'Modefi (MOD)',
  },
  VSP: {
    Name: 'VSP',
    Symbol: 'VSP',
    CoinName: 'Vesper Finance',
    FullName: 'Vesper Finance (VSP)',
  },
  POD: {
    Name: 'POD',
    Symbol: 'POD',
    CoinName: 'Podo Point',
    FullName: 'Podo Point (POD)',
  },
  SKLAY: {
    Name: 'SKLAY',
    Symbol: 'SKLAY',
    CoinName: 'sKLAY',
    FullName: 'sKLAY (SKLAY)',
  },
  LHB: {
    Name: 'LHB',
    Symbol: 'LHB',
    CoinName: 'Lendhub',
    FullName: 'Lendhub (LHB)',
  },
  SINE: {
    Name: 'SINE',
    Symbol: 'SINE',
    CoinName: 'Sinelock',
    FullName: 'Sinelock (SINE)',
  },
  STAKEDETH: {
    Name: 'STAKEDETH',
    Symbol: 'STAKEDETH',
    CoinName: 'StakeHound Staked Ether',
    FullName: 'StakeHound Staked Ether (STAKEDETH)',
  },
  TORN: {
    Name: 'TORN',
    Symbol: 'TORN',
    CoinName: 'Tornado Cash',
    FullName: 'Tornado Cash (TORN)',
  },
  RAI: {
    Name: 'RAI',
    Symbol: 'RAI',
    CoinName: 'Rai Reflex Index',
    FullName: 'Rai Reflex Index (RAI)',
  },
  QISWAP: {
    Name: 'QISWAP',
    Symbol: 'QISWAP',
    CoinName: 'QiSwap',
    FullName: 'QiSwap (QISWAP)',
  },
  BITT: {
    Name: 'BITT',
    Symbol: 'BITT',
    CoinName: 'BiTToken',
    FullName: 'BiTToken (BITT)',
  },
  XMON: {
    Name: 'XMON',
    Symbol: 'XMON',
    CoinName: 'XMON',
    FullName: 'XMON (XMON)',
  },
  RAY: {
    Name: 'RAY',
    Symbol: 'RAY',
    CoinName: 'Raydium',
    FullName: 'Raydium (RAY)',
  },
  QTF: {
    Name: 'QTF',
    Symbol: 'QTF',
    CoinName: 'Quantfury',
    FullName: 'Quantfury (QTF)',
  },
  BONDLY: {
    Name: 'BONDLY',
    Symbol: 'BONDLY',
    CoinName: 'Bondly',
    FullName: 'Bondly (BONDLY)',
  },
  SLNV2: {
    Name: 'SLNV2',
    Symbol: 'SLNV2',
    CoinName: 'SLNV2',
    FullName: 'SLNV2 (SLNV2)',
  },
  LPOOL: {
    Name: 'LPOOL',
    Symbol: 'LPOOL',
    CoinName: 'Launchpool',
    FullName: 'Launchpool (LPOOL)',
  },
  ELCASH: {
    Name: 'ELCASH',
    Symbol: 'ELCASH',
    CoinName: 'Electric Cash',
    FullName: 'Electric Cash (ELCASH)',
  },
  HBO: {
    Name: 'HBO',
    Symbol: 'HBO',
    CoinName: 'Hash Bridge Oracle',
    FullName: 'Hash Bridge Oracle (HBO)',
  },
  WHALE: {
    Name: 'WHALE',
    Symbol: 'WHALE',
    CoinName: 'WHALE',
    FullName: 'WHALE (WHALE)',
  },
  DG: {
    Name: 'DG',
    Symbol: 'DG',
    CoinName: 'Decentral Games',
    FullName: 'Decentral Games (DG)',
  },
  DOKI: {
    Name: 'DOKI',
    Symbol: 'DOKI',
    CoinName: 'Doki Doki Finance',
    FullName: 'Doki Doki Finance (DOKI)',
  },
  SEEN: {
    Name: 'SEEN',
    Symbol: 'SEEN',
    CoinName: 'SEEN',
    FullName: 'SEEN (SEEN)',
  },
  AZUKI: {
    Name: 'AZUKI',
    Symbol: 'AZUKI',
    CoinName: 'Azuki',
    FullName: 'Azuki (AZUKI)',
  },
  NUA: {
    Name: 'NUA',
    Symbol: 'NUA',
    CoinName: 'Neulaut Token',
    FullName: 'Neulaut Token (NUA)',
  },
  MUSE: {
    Name: 'MUSE',
    Symbol: 'MUSE',
    CoinName: 'Muse DAO',
    FullName: 'Muse DAO (MUSE)',
  },
  MONAV: {
    Name: 'MONAV',
    Symbol: 'MONAV',
    CoinName: 'Monavale',
    FullName: 'Monavale (MONAV)',
  },
  NAMEC: {
    Name: 'NAMEC',
    Symbol: 'NAMEC',
    CoinName: 'Name Change Token',
    FullName: 'Name Change Token (NAMEC)',
  },
  POLK: {
    Name: 'POLK',
    Symbol: 'POLK',
    CoinName: 'Polkamarkets',
    FullName: 'Polkamarkets (POLK)',
  },
  SOTA: {
    Name: 'SOTA',
    Symbol: 'SOTA',
    CoinName: 'SOTA Finance',
    FullName: 'SOTA Finance (SOTA)',
  },
  POLC: {
    Name: 'POLC',
    Symbol: 'POLC',
    CoinName: 'Polka City',
    FullName: 'Polka City (POLC)',
  },
  ALOHA: {
    Name: 'ALOHA',
    Symbol: 'ALOHA',
    CoinName: 'Aloha',
    FullName: 'Aloha (ALOHA)',
  },
  BSP: {
    Name: 'BSP',
    Symbol: 'BSP',
    CoinName: 'BallSwap',
    FullName: 'BallSwap (BSP)',
  },
  XTK: {
    Name: 'XTK',
    Symbol: 'XTK',
    CoinName: 'xToken',
    FullName: 'xToken (XTK)',
  },
  ACM: {
    Name: 'ACM',
    Symbol: 'ACM',
    CoinName: 'AC Milan Fan Token',
    FullName: 'AC Milan Fan Token (ACM)',
  },
  EGG: {
    Name: 'EGG',
    Symbol: 'EGG',
    CoinName: 'Goose Finance',
    FullName: 'Goose Finance (EGG)',
  },
  HETH: {
    Name: 'HETH',
    Symbol: 'HETH',
    CoinName: 'Huobi Ethereum',
    FullName: 'Huobi Ethereum (HETH)',
  },
  HOPR: {
    Name: 'HOPR',
    Symbol: 'HOPR',
    CoinName: 'HOPR',
    FullName: 'HOPR (HOPR)',
  },
  PPBLZ: {
    Name: 'PPBLZ',
    Symbol: 'PPBLZ',
    CoinName: 'Pepemon Pepeballs',
    FullName: 'Pepemon Pepeballs (PPBLZ)',
  },
  NDR: {
    Name: 'NDR',
    Symbol: 'NDR',
    CoinName: 'Node Runners',
    FullName: 'Node Runners (NDR)',
  },
  CHONK: {
    Name: 'CHONK',
    Symbol: 'CHONK',
    CoinName: 'Chonk',
    FullName: 'Chonk (CHONK)',
  },
  WOA: {
    Name: 'WOA',
    Symbol: 'WOA',
    CoinName: 'Wrapped Origin Axie',
    FullName: 'Wrapped Origin Axie (WOA)',
  },
  WAIF: {
    Name: 'WAIF',
    Symbol: 'WAIF',
    CoinName: 'Waifu Token',
    FullName: 'Waifu Token (WAIF)',
  },
  UNIQUE: {
    Name: 'UNIQUE',
    Symbol: 'UNIQUE',
    CoinName: 'Unique One',
    FullName: 'Unique One (UNIQUE)',
  },
  COKE: {
    Name: 'COKE',
    Symbol: 'COKE',
    CoinName: 'Cocaine Cowboy Shards',
    FullName: 'Cocaine Cowboy Shards (COKE)',
  },
  DYT: {
    Name: 'DYT',
    Symbol: 'DYT',
    CoinName: 'DoYourTip',
    FullName: 'DoYourTip (DYT)',
  },
  RSIN: {
    Name: 'RSIN',
    Symbol: 'RSIN',
    CoinName: 'Roketsin',
    FullName: 'Roketsin (RSIN)',
  },
  RECOM: {
    Name: 'RECOM',
    Symbol: 'RECOM',
    CoinName: 'Recom',
    FullName: 'Recom (RECOM)',
  },
  ALCX: {
    Name: 'ALCX',
    Symbol: 'ALCX',
    CoinName: 'Alchemix',
    FullName: 'Alchemix (ALCX)',
  },
  BMXX: {
    Name: 'BMXX',
    Symbol: 'BMXX',
    CoinName: 'Multiplier',
    FullName: 'Multiplier (BMXX)',
  },
  DWZ: {
    Name: 'DWZ',
    Symbol: 'DWZ',
    CoinName: 'DeFi Wizard',
    FullName: 'DeFi Wizard (DWZ)',
  },
  VKNF: {
    Name: 'VKNF',
    Symbol: 'VKNF',
    CoinName: 'VKENAF',
    FullName: 'VKENAF (VKNF)',
  },
  TOWER: {
    Name: 'TOWER',
    Symbol: 'TOWER',
    CoinName: 'Tower',
    FullName: 'Tower (TOWER)',
  },
  FONT: {
    Name: 'FONT',
    Symbol: 'FONT',
    CoinName: 'Font',
    FullName: 'Font (FONT)',
  },
  MLA: {
    Name: 'MLA',
    Symbol: 'MLA',
    CoinName: 'Moola',
    FullName: 'Moola (MLA)',
  },
  DOWS: {
    Name: 'DOWS',
    Symbol: 'DOWS',
    CoinName: 'Shadows',
    FullName: 'Shadows (DOWS)',
  },
  BSCPAD: {
    Name: 'BSCPAD',
    Symbol: 'BSCPAD',
    CoinName: 'BSCPAD',
    FullName: 'BSCPAD (BSCPAD)',
  },
  HFIL: {
    Name: 'HFIL',
    Symbol: 'HFIL',
    CoinName: 'Huobi Fil',
    FullName: 'Huobi Fil (HFIL)',
  },
  DEGOV: {
    Name: 'DEGOV',
    Symbol: 'DEGOV',
    CoinName: 'Degov',
    FullName: 'Degov (DEGOV)',
  },
  DEBASE: {
    Name: 'DEBASE',
    Symbol: 'DEBASE',
    CoinName: 'Debase',
    FullName: 'Debase (DEBASE)',
  },
  KYL: {
    Name: 'KYL',
    Symbol: 'KYL',
    CoinName: 'Kylin Network',
    FullName: 'Kylin Network (KYL)',
  },
  SLICE: {
    Name: 'SLICE',
    Symbol: 'SLICE',
    CoinName: 'Tranche Finance',
    FullName: 'Tranche Finance (SLICE)',
  },
  BINTEX: {
    Name: 'BINTEX',
    Symbol: 'BINTEX',
    CoinName: 'Bintex Futures',
    FullName: 'Bintex Futures (BINTEX)',
  },
  ALPA: {
    Name: 'ALPA',
    Symbol: 'ALPA',
    CoinName: 'Alpaca',
    FullName: 'Alpaca (ALPA)',
  },
  BLANK: {
    Name: 'BLANK',
    Symbol: 'BLANK',
    CoinName: 'Blank Token',
    FullName: 'Blank Token (BLANK)',
  },
  BALPHA: {
    Name: 'BALPHA',
    Symbol: 'BALPHA',
    CoinName: 'bAlpha',
    FullName: 'bAlpha (BALPHA)',
  },
  ALPACA: {
    Name: 'ALPACA',
    Symbol: 'ALPACA',
    CoinName: 'Alpaca Finance',
    FullName: 'Alpaca Finance (ALPACA)',
  },
  SI: {
    Name: 'SI',
    Symbol: 'SI',
    CoinName: 'Siren',
    FullName: 'Siren (SI)',
  },
  EHASH: {
    Name: 'EHASH',
    Symbol: 'EHASH',
    CoinName: 'EHash',
    FullName: 'EHash (EHASH)',
  },
  DEOR: {
    Name: 'DEOR',
    Symbol: 'DEOR',
    CoinName: 'Decentralized Oracle',
    FullName: 'Decentralized Oracle (DEOR)',
  },
  ETHA: {
    Name: 'ETHA',
    Symbol: 'ETHA',
    CoinName: 'ETHA Lend',
    FullName: 'ETHA Lend (ETHA)',
  },
  AVAL: {
    Name: 'AVAL',
    Symbol: 'AVAL',
    CoinName: 'Avaluse',
    FullName: 'Avaluse (AVAL)',
  },
  WMT: {
    Name: 'WMT',
    Symbol: 'WMT',
    CoinName: 'World Mobile Token',
    FullName: 'World Mobile Token (WMT)',
  },
  SYNC: {
    Name: 'SYNC',
    Symbol: 'SYNC',
    CoinName: 'Sync Network',
    FullName: 'Sync Network (SYNC)',
  },
  BDP: {
    Name: 'BDP',
    Symbol: 'BDP',
    CoinName: 'Big Data Protocol',
    FullName: 'Big Data Protocol (BDP)',
  },
  FOTO: {
    Name: 'FOTO',
    Symbol: 'FOTO',
    CoinName: 'Unique Photo',
    FullName: 'Unique Photo (FOTO)',
  },
  AAPX: {
    Name: 'AAPX',
    Symbol: 'AAPX',
    CoinName: 'AMPnet',
    FullName: 'AMPnet (AAPX)',
  },
  REHAB: {
    Name: 'REHAB',
    Symbol: 'REHAB',
    CoinName: 'NFT Rehab',
    FullName: 'NFT Rehab (REHAB)',
  },
  CHOW: {
    Name: 'CHOW',
    Symbol: 'CHOW',
    CoinName: 'Chow Chow Finance',
    FullName: 'Chow Chow Finance (CHOW)',
  },
  YIELD: {
    Name: 'YIELD',
    Symbol: 'YIELD',
    CoinName: 'Yield Protocol',
    FullName: 'Yield Protocol (YIELD)',
  },
  DOLA: {
    Name: 'DOLA',
    Symbol: 'DOLA',
    CoinName: 'Dola USD Stablecoin',
    FullName: 'Dola USD Stablecoin (DOLA)',
  },
  IFUND: {
    Name: 'IFUND',
    Symbol: 'IFUND',
    CoinName: 'Unifund',
    FullName: 'Unifund (IFUND)',
  },
  VSD: {
    Name: 'VSD',
    Symbol: 'VSD',
    CoinName: 'Value Set Dollar',
    FullName: 'Value Set Dollar (VSD)',
  },
  CGG: {
    Name: 'CGG',
    Symbol: 'CGG',
    CoinName: 'Chain Guardians',
    FullName: 'Chain Guardians (CGG)',
  },
  TOOLS: {
    Name: 'TOOLS',
    Symbol: 'TOOLS',
    CoinName: 'TOOLS',
    FullName: 'TOOLS (TOOLS)',
  },
  ARGON: {
    Name: 'ARGON',
    Symbol: 'ARGON',
    CoinName: 'Argon',
    FullName: 'Argon (ARGON)',
  },
  GSPI: {
    Name: 'GSPI',
    Symbol: 'GSPI',
    CoinName: 'GSPI',
    FullName: 'GSPI (GSPI)',
  },
  PCNT: {
    Name: 'PCNT',
    Symbol: 'PCNT',
    CoinName: 'Playcent',
    FullName: 'Playcent (PCNT)',
  },
  WHIRL: {
    Name: 'WHIRL',
    Symbol: 'WHIRL',
    CoinName: 'Whirl Finance',
    FullName: 'Whirl Finance (WHIRL)',
  },
  KINE: {
    Name: 'KINE',
    Symbol: 'KINE',
    CoinName: 'Kine Protocol',
    FullName: 'Kine Protocol (KINE)',
  },
  DIESEL: {
    Name: 'DIESEL',
    Symbol: 'DIESEL',
    CoinName: 'Diesel',
    FullName: 'Diesel (DIESEL)',
  },
  CTF: {
    Name: 'CTF',
    Symbol: 'CTF',
    CoinName: 'CyberTime Finance',
    FullName: 'CyberTime Finance (CTF)',
  },
  TANGO: {
    Name: 'TANGO',
    Symbol: 'TANGO',
    CoinName: 'keyTango',
    FullName: 'keyTango (TANGO)',
  },
  RBUNNY: {
    Name: 'RBUNNY',
    Symbol: 'RBUNNY',
    CoinName: 'Rocket Bunny',
    FullName: 'Rocket Bunny (RBUNNY)',
  },
  BCP: {
    Name: 'BCP',
    Symbol: 'BCP',
    CoinName: 'BitcashPay',
    FullName: 'BitcashPay (BCP)',
  },
  UNORE: {
    Name: 'UNORE',
    Symbol: 'UNORE',
    CoinName: 'UnoRe',
    FullName: 'UnoRe (UNORE)',
  },
  ALICE: {
    Name: 'ALICE',
    Symbol: 'ALICE',
    CoinName: 'My Neighbor Alice',
    FullName: 'My Neighbor Alice (ALICE)',
  },
  MCAT20: {
    Name: 'MCAT20',
    Symbol: 'MCAT20',
    CoinName: 'Wrapped Moon Cats',
    FullName: 'Wrapped Moon Cats (MCAT20)',
  },
  RAD: {
    Name: 'RAD',
    Symbol: 'RAD',
    CoinName: 'Radicle',
    FullName: 'Radicle (RAD)',
  },
  HOGE: {
    Name: 'HOGE',
    Symbol: 'HOGE',
    CoinName: 'Hoge Finance',
    FullName: 'Hoge Finance (HOGE)',
  },
  EVRICE: {
    Name: 'EVRICE',
    Symbol: 'EVRICE',
    CoinName: 'Evrice',
    FullName: 'Evrice (EVRICE)',
  },
  EDDA: {
    Name: 'EDDA',
    Symbol: 'EDDA',
    CoinName: 'EDDASwap',
    FullName: 'EDDASwap (EDDA)',
  },
  B26: {
    Name: 'B26',
    Symbol: 'B26',
    CoinName: 'B26 Finance',
    FullName: 'B26 Finance (B26)',
  },
  VISR: {
    Name: 'VISR',
    Symbol: 'VISR',
    CoinName: 'Visor',
    FullName: 'Visor (VISR)',
  },
  GRUMPY: {
    Name: 'GRUMPY',
    Symbol: 'GRUMPY',
    CoinName: 'Grumpy Finance',
    FullName: 'Grumpy Finance (GRUMPY)',
  },
  JULB: {
    Name: 'JULB',
    Symbol: 'JULB',
    CoinName: 'JustLiquidity Binance',
    FullName: 'JustLiquidity Binance (JULB)',
  },
  'DUK+': {
    Name: 'DUK+',
    Symbol: 'DUK+',
    CoinName: 'Dukascoin',
    FullName: 'Dukascoin (DUK+)',
  },
  SCAT: {
    Name: 'SCAT',
    Symbol: 'SCAT',
    CoinName: 'Sad Cat Token',
    FullName: 'Sad Cat Token (SCAT)',
  },
  ZERO: {
    Name: 'ZERO',
    Symbol: 'ZERO',
    CoinName: 'Zero Tech',
    FullName: 'Zero Tech (ZERO)',
  },
  BIFI: {
    Name: 'BIFI',
    Symbol: 'BIFI',
    CoinName: 'Beefy.Finance',
    FullName: 'Beefy.Finance (BIFI)',
  },
  OXY: {
    Name: 'OXY',
    Symbol: 'OXY',
    CoinName: 'Oxygen',
    FullName: 'Oxygen (OXY)',
  },
  XEND: {
    Name: 'XEND',
    Symbol: 'XEND',
    CoinName: 'Xend Finance',
    FullName: 'Xend Finance (XEND)',
  },
  L3P: {
    Name: 'L3P',
    Symbol: 'L3P',
    CoinName: 'Lepricon',
    FullName: 'Lepricon (L3P)',
  },
  BRICK: {
    Name: 'BRICK',
    Symbol: 'BRICK',
    CoinName: 'Brickchain FInance',
    FullName: 'Brickchain FInance (BRICK)',
  },
  BSCV: {
    Name: 'BSCV',
    Symbol: 'BSCV',
    CoinName: 'Bscview',
    FullName: 'Bscview (BSCV)',
  },
  QUAM: {
    Name: 'QUAM',
    Symbol: 'QUAM',
    CoinName: 'Quam Network',
    FullName: 'Quam Network (QUAM)',
  },
  ELAND: {
    Name: 'ELAND',
    Symbol: 'ELAND',
    CoinName: 'Etherland',
    FullName: 'Etherland (ELAND)',
  },
  DAFI: {
    Name: 'DAFI',
    Symbol: 'DAFI',
    CoinName: 'Dafi Protocol',
    FullName: 'Dafi Protocol (DAFI)',
  },
  BCUG: {
    Name: 'BCUG',
    Symbol: 'BCUG',
    CoinName: 'Blockchain Cuties Universe Governance',
    FullName: 'Blockchain Cuties Universe Governance (BCUG)',
  },
  PAINT: {
    Name: 'PAINT',
    Symbol: 'PAINT',
    CoinName: 'MurAll',
    FullName: 'MurAll (PAINT)',
  },
  PKF: {
    Name: 'PKF',
    Symbol: 'PKF',
    CoinName: 'PolkaFoundry',
    FullName: 'PolkaFoundry (PKF)',
  },
  NFY: {
    Name: 'NFY',
    Symbol: 'NFY',
    CoinName: 'Non-Fungible Yearn',
    FullName: 'Non-Fungible Yearn (NFY)',
  },
  NFTP: {
    Name: 'NFTP',
    Symbol: 'NFTP',
    CoinName: 'NFT',
    FullName: 'NFT (NFTP)',
  },
  KWD: {
    Name: 'KWD',
    Symbol: 'KWD',
    CoinName: 'KIWI DEFI',
    FullName: 'KIWI DEFI (KWD)',
  },
  XYM: {
    Name: 'XYM',
    Symbol: 'XYM',
    CoinName: 'Symbol',
    FullName: 'Symbol (XYM)',
  },
  ANDX: {
    Name: 'ANDX',
    Symbol: 'ANDX',
    CoinName: 'Arrano',
    FullName: 'Arrano (ANDX)',
  },
  CCAKE: {
    Name: 'CCAKE',
    Symbol: 'CCAKE',
    CoinName: 'CheeseCake Swap',
    FullName: 'CheeseCake Swap (CCAKE)',
  },
  DORA: {
    Name: 'DORA',
    Symbol: 'DORA',
    CoinName: 'Dora Factory',
    FullName: 'Dora Factory (DORA)',
  },
  INV: {
    Name: 'INV',
    Symbol: 'INV',
    CoinName: 'Inverse Finance',
    FullName: 'Inverse Finance (INV)',
  },
  EPS: {
    Name: 'EPS',
    Symbol: 'EPS',
    CoinName: 'Ellipsis (OLD)',
    FullName: 'Ellipsis (OLD) (EPS)',
  },
  ATD: {
    Name: 'ATD',
    Symbol: 'ATD',
    CoinName: 'A2DAO',
    FullName: 'A2DAO (ATD)',
  },
  FMG: {
    Name: 'FMG',
    Symbol: 'FMG',
    CoinName: 'FM Gallery',
    FullName: 'FM Gallery (FMG)',
  },
  WOOP: {
    Name: 'WOOP',
    Symbol: 'WOOP',
    CoinName: 'Woonkly Power',
    FullName: 'Woonkly Power (WOOP)',
  },
  ADD: {
    Name: 'ADD',
    Symbol: 'ADD',
    CoinName: 'ADD.xyz',
    FullName: 'ADD.xyz (ADD)',
  },
  ICAP: {
    Name: 'ICAP',
    Symbol: 'ICAP',
    CoinName: 'ICAP Token',
    FullName: 'ICAP Token (ICAP)',
  },
  WOWS: {
    Name: 'WOWS',
    Symbol: 'WOWS',
    CoinName: 'Wolves of Wall Street',
    FullName: 'Wolves of Wall Street (WOWS)',
  },
  CAT: {
    Name: 'CAT',
    Symbol: 'CAT',
    CoinName: 'Cat Token',
    FullName: 'Cat Token (CAT)',
  },
  MUTE: {
    Name: 'MUTE',
    Symbol: 'MUTE',
    CoinName: 'Mute',
    FullName: 'Mute (MUTE)',
  },
  ZUT: {
    Name: 'ZUT',
    Symbol: 'ZUT',
    CoinName: 'Zero Utility Token',
    FullName: 'Zero Utility Token (ZUT)',
  },
  NFTI: {
    Name: 'NFTI',
    Symbol: 'NFTI',
    CoinName: 'NFT Index',
    FullName: 'NFT Index (NFTI)',
  },
  BLES: {
    Name: 'BLES',
    Symbol: 'BLES',
    CoinName: 'Blind Boxes',
    FullName: 'Blind Boxes (BLES)',
  },
  DELTA: {
    Name: 'DELTA',
    Symbol: 'DELTA',
    CoinName: 'Delta Financial',
    FullName: 'Delta Financial (DELTA)',
  },
  CASHT: {
    Name: 'CASHT',
    Symbol: 'CASHT',
    CoinName: 'Cash Tech',
    FullName: 'Cash Tech (CASHT)',
  },
  BLZD: {
    Name: 'BLZD',
    Symbol: 'BLZD',
    CoinName: 'Blizzard.money',
    FullName: 'Blizzard.money (BLZD)',
  },
  LAS: {
    Name: 'LAS',
    Symbol: 'LAS',
    CoinName: 'LNAsolution Coin',
    FullName: 'LNAsolution Coin (LAS)',
  },
  SFUND: {
    Name: 'SFUND',
    Symbol: 'SFUND',
    CoinName: 'Seedify.fund',
    FullName: 'Seedify.fund (SFUND)',
  },
  OHM: {
    Name: 'OHM',
    Symbol: 'OHM',
    CoinName: 'Olympus',
    FullName: 'Olympus (OHM)',
  },
  RUGZ: {
    Name: 'RUGZ',
    Symbol: 'RUGZ',
    CoinName: 'pulltherug.finance',
    FullName: 'pulltherug.finance (RUGZ)',
  },
  FUD: {
    Name: 'FUD',
    Symbol: 'FUD',
    CoinName: 'FUD.finance',
    FullName: 'FUD.finance (FUD)',
  },
  GFX: {
    Name: 'GFX',
    Symbol: 'GFX',
    CoinName: 'GamyFi Token',
    FullName: 'GamyFi Token (GFX)',
  },
  ETNA: {
    Name: 'ETNA',
    Symbol: 'ETNA',
    CoinName: 'ETNA Network',
    FullName: 'ETNA Network (ETNA)',
  },
  SAFEBTC: {
    Name: 'SAFEBTC',
    Symbol: 'SAFEBTC',
    CoinName: 'SafeBTC',
    FullName: 'SafeBTC (SAFEBTC)',
  },
  TIDAL: {
    Name: 'TIDAL',
    Symbol: 'TIDAL',
    CoinName: 'Tidal Finance',
    FullName: 'Tidal Finance (TIDAL)',
  },
  KPAD: {
    Name: 'KPAD',
    Symbol: 'KPAD',
    CoinName: 'KickPad',
    FullName: 'KickPad (KPAD)',
  },
  GLQ: {
    Name: 'GLQ',
    Symbol: 'GLQ',
    CoinName: 'GraphLinq Protocol',
    FullName: 'GraphLinq Protocol (GLQ)',
  },
  BITSZ: {
    Name: 'BITSZ',
    Symbol: 'BITSZ',
    CoinName: 'Bitsz',
    FullName: 'Bitsz (BITSZ)',
  },
  PUNDIX: {
    Name: 'PUNDIX',
    Symbol: 'PUNDIX',
    CoinName: 'Pundi X',
    FullName: 'Pundi X (PUNDIX)',
  },
  AWS: {
    Name: 'AWS',
    Symbol: 'AWS',
    CoinName: 'AurusSILVER',
    FullName: 'AurusSILVER (AWS)',
  },
  CUE: {
    Name: 'CUE',
    Symbol: 'CUE',
    CoinName: 'CUE Protocol',
    FullName: 'CUE Protocol (CUE)',
  },
  FST: {
    Name: 'FST',
    Symbol: 'FST',
    CoinName: 'Futureswap',
    FullName: 'Futureswap (FST)',
  },
  ILV: {
    Name: 'ILV',
    Symbol: 'ILV',
    CoinName: 'Illuvium',
    FullName: 'Illuvium (ILV)',
  },
  MORA: {
    Name: 'MORA',
    Symbol: 'MORA',
    CoinName: 'Meliora',
    FullName: 'Meliora (MORA)',
  },
  POLAR: {
    Name: 'POLAR',
    Symbol: 'POLAR',
    CoinName: 'Polaris',
    FullName: 'Polaris (POLAR)',
  },
  MRCH: {
    Name: 'MRCH',
    Symbol: 'MRCH',
    CoinName: 'MerchDAO',
    FullName: 'MerchDAO (MRCH)',
  },
  HAUS: {
    Name: 'HAUS',
    Symbol: 'HAUS',
    CoinName: 'DAOhaus',
    FullName: 'DAOhaus (HAUS)',
  },
  MARSH: {
    Name: 'MARSH',
    Symbol: 'MARSH',
    CoinName: 'Unmarshal',
    FullName: 'Unmarshal (MARSH)',
  },
  COPE: {
    Name: 'COPE',
    Symbol: 'COPE',
    CoinName: 'Cope',
    FullName: 'Cope (COPE)',
  },
  UDT: {
    Name: 'UDT',
    Symbol: 'UDT',
    CoinName: 'Unlock Protocol',
    FullName: 'Unlock Protocol (UDT)',
  },
  WXDAI: {
    Name: 'WXDAI',
    Symbol: 'WXDAI',
    CoinName: 'Wrapped XDAI',
    FullName: 'Wrapped XDAI (WXDAI)',
  },
  MARK: {
    Name: 'MARK',
    Symbol: 'MARK',
    CoinName: 'Benchmark Protocol',
    FullName: 'Benchmark Protocol (MARK)',
  },
  CBDC: {
    Name: 'CBDC',
    Symbol: 'CBDC',
    CoinName: 'CannaBCoin',
    FullName: 'CannaBCoin (CBDC)',
  },
  STN: {
    Name: 'STN',
    Symbol: 'STN',
    CoinName: 'Stone Token',
    FullName: 'Stone Token (STN)',
  },
  AIOZ: {
    Name: 'AIOZ',
    Symbol: 'AIOZ',
    CoinName: 'AIOZ Network',
    FullName: 'AIOZ Network (AIOZ)',
  },
  MOB: {
    Name: 'MOB',
    Symbol: 'MOB',
    CoinName: 'MobileCoin',
    FullName: 'MobileCoin (MOB)',
  },
  SHOPX: {
    Name: 'SHOPX',
    Symbol: 'SHOPX',
    CoinName: 'Splyt',
    FullName: 'Splyt (SHOPX)',
  },
  PMON: {
    Name: 'PMON',
    Symbol: 'PMON',
    CoinName: 'Polkamon',
    FullName: 'Polkamon (PMON)',
  },
  METI: {
    Name: 'METI',
    Symbol: 'METI',
    CoinName: 'Metis',
    FullName: 'Metis (METI)',
  },
  '10SET': {
    Name: '10SET',
    Symbol: '10SET',
    CoinName: 'Tenset',
    FullName: 'Tenset (10SET)',
  },
  MTHD: {
    Name: 'MTHD',
    Symbol: 'MTHD',
    CoinName: 'Method Finance',
    FullName: 'Method Finance (MTHD)',
  },
  XBE: {
    Name: 'XBE',
    Symbol: 'XBE',
    CoinName: 'XBE Token',
    FullName: 'XBE Token (XBE)',
  },
  ALCHE: {
    Name: 'ALCHE',
    Symbol: 'ALCHE',
    CoinName: 'Alchemist',
    FullName: 'Alchemist (ALCHE)',
  },
  ROPE: {
    Name: 'ROPE',
    Symbol: 'ROPE',
    CoinName: 'Rope Token',
    FullName: 'Rope Token (ROPE)',
  },
  AUTO: {
    Name: 'AUTO',
    Symbol: 'AUTO',
    CoinName: 'Auto',
    FullName: 'Auto (AUTO)',
  },
  COVIR: {
    Name: 'COVIR',
    Symbol: 'COVIR',
    CoinName: 'COVIR',
    FullName: 'COVIR (COVIR)',
  },
  MCO2: {
    Name: 'MCO2',
    Symbol: 'MCO2',
    CoinName: 'Moss Carbon Credit',
    FullName: 'Moss Carbon Credit (MCO2)',
  },
  WQT: {
    Name: 'WQT',
    Symbol: 'WQT',
    CoinName: 'Work Quest',
    FullName: 'Work Quest (WQT)',
  },
  LQTY: {
    Name: 'LQTY',
    Symbol: 'LQTY',
    CoinName: 'Liquity',
    FullName: 'Liquity (LQTY)',
  },
  LTX: {
    Name: 'LTX',
    Symbol: 'LTX',
    CoinName: 'Lattice Token',
    FullName: 'Lattice Token (LTX)',
  },
  ARNO: {
    Name: 'ARNO',
    Symbol: 'ARNO',
    CoinName: 'ARNO',
    FullName: 'ARNO (ARNO)',
  },
  LUSD: {
    Name: 'LUSD',
    Symbol: 'LUSD',
    CoinName: 'Liquity USD',
    FullName: 'Liquity USD (LUSD)',
  },
  '8PAY': {
    Name: '8PAY',
    Symbol: '8PAY',
    CoinName: '8Pay',
    FullName: '8Pay (8PAY)',
  },
  MCAU: {
    Name: 'MCAU',
    Symbol: 'MCAU',
    CoinName: 'Meld Gold',
    FullName: 'Meld Gold (MCAU)',
  },
  STRK: {
    Name: 'STRK',
    Symbol: 'STRK',
    CoinName: 'Strike',
    FullName: 'Strike (STRK)',
  },
  BDPI: {
    Name: 'BDPI',
    Symbol: 'BDPI',
    CoinName: 'Interest Bearing Defi Pulse Index',
    FullName: 'Interest Bearing Defi Pulse Index (BDPI)',
  },
  CARDS: {
    Name: 'CARDS',
    Symbol: 'CARDS',
    CoinName: 'Cardstarter',
    FullName: 'Cardstarter (CARDS)',
  },
  TOTM: {
    Name: 'TOTM',
    Symbol: 'TOTM',
    CoinName: 'Totem',
    FullName: 'Totem (TOTM)',
  },
  CERE: {
    Name: 'CERE',
    Symbol: 'CERE',
    CoinName: 'Cere Network',
    FullName: 'Cere Network (CERE)',
  },
  KTN: {
    Name: 'KTN',
    Symbol: 'KTN',
    CoinName: 'Kattana',
    FullName: 'Kattana (KTN)',
  },
  OCTI: {
    Name: 'OCTI',
    Symbol: 'OCTI',
    CoinName: 'Oction',
    FullName: 'Oction (OCTI)',
  },
  PUSH: {
    Name: 'PUSH',
    Symbol: 'PUSH',
    CoinName: 'Ethereum Push Notification Service',
    FullName: 'Ethereum Push Notification Service (PUSH)',
  },
  TKO: {
    Name: 'TKO',
    Symbol: 'TKO',
    CoinName: 'Tokocrypto',
    FullName: 'Tokocrypto (TKO)',
  },
  GMEE: {
    Name: 'GMEE',
    Symbol: 'GMEE',
    CoinName: 'GAMEE',
    FullName: 'GAMEE (GMEE)',
  },
  TLM: {
    Name: 'TLM',
    Symbol: 'TLM',
    CoinName: 'Alien Worlds',
    FullName: 'Alien Worlds (TLM)',
  },
  TCAP: {
    Name: 'TCAP',
    Symbol: 'TCAP',
    CoinName: 'Total Crypto Market Cap',
    FullName: 'Total Crypto Market Cap (TCAP)',
  },
  CPCOIN: {
    Name: 'CPCOIN',
    Symbol: 'CPCOIN',
    CoinName: 'CPCoin',
    FullName: 'CPCoin (CPCOIN)',
  },
  RBC: {
    Name: 'RBC',
    Symbol: 'RBC',
    CoinName: 'Rubic',
    FullName: 'Rubic (RBC)',
  },
  MBOX: {
    Name: 'MBOX',
    Symbol: 'MBOX',
    CoinName: 'MOBOX',
    FullName: 'MOBOX (MBOX)',
  },
  GHX: {
    Name: 'GHX',
    Symbol: 'GHX',
    CoinName: 'GamerCoin',
    FullName: 'GamerCoin (GHX)',
  },
  COM: {
    Name: 'COM',
    Symbol: 'COM',
    CoinName: 'Coliseum',
    FullName: 'Coliseum (COM)',
  },
  UBA: {
    Name: 'UBA',
    Symbol: 'UBA',
    CoinName: 'Unbox.Art',
    FullName: 'Unbox.Art (UBA)',
  },
  PFL: {
    Name: 'PFL',
    Symbol: 'PFL',
    CoinName: 'Professional Fighters League Fan Token',
    FullName: 'Professional Fighters League Fan Token (PFL)',
  },
  FIDA: {
    Name: 'FIDA',
    Symbol: 'FIDA',
    CoinName: 'Bonfida',
    FullName: 'Bonfida (FIDA)',
  },
  INNBC: {
    Name: 'INNBC',
    Symbol: 'INNBC',
    CoinName: 'Innovative Bioresearch Coin',
    FullName: 'Innovative Bioresearch Coin (INNBC)',
  },
  TOS: {
    Name: 'TOS',
    Symbol: 'TOS',
    CoinName: 'ThingsOperatingSystem',
    FullName: 'ThingsOperatingSystem (TOS)',
  },
  MTLX: {
    Name: 'MTLX',
    Symbol: 'MTLX',
    CoinName: 'Mettalex',
    FullName: 'Mettalex (MTLX)',
  },
  BOSON: {
    Name: 'BOSON',
    Symbol: 'BOSON',
    CoinName: 'Boson Protocol',
    FullName: 'Boson Protocol (BOSON)',
  },
  BAG: {
    Name: 'BAG',
    Symbol: 'BAG',
    CoinName: 'BondAppetit',
    FullName: 'BondAppetit (BAG)',
  },
  REVO: {
    Name: 'REVO',
    Symbol: 'REVO',
    CoinName: 'Revomon',
    FullName: 'Revomon (REVO)',
  },
  SOBA: {
    Name: 'SOBA',
    Symbol: 'SOBA',
    CoinName: 'SOBA Token',
    FullName: 'SOBA Token (SOBA)',
  },
  EQZ: {
    Name: 'EQZ',
    Symbol: 'EQZ',
    CoinName: 'Equalizer',
    FullName: 'Equalizer (EQZ)',
  },
  SEPA: {
    Name: 'SEPA',
    Symbol: 'SEPA',
    CoinName: 'Secure Pad',
    FullName: 'Secure Pad (SEPA)',
  },
  DFY: {
    Name: 'DFY',
    Symbol: 'DFY',
    CoinName: 'Defi For You',
    FullName: 'Defi For You (DFY)',
  },
  K21: {
    Name: 'K21',
    Symbol: 'K21',
    CoinName: 'K21',
    FullName: 'K21 (K21)',
  },
  FMT: {
    Name: 'FMT',
    Symbol: 'FMT',
    CoinName: 'Finminity',
    FullName: 'Finminity (FMT)',
  },
  CODEX: {
    Name: 'CODEX',
    Symbol: 'CODEX',
    CoinName: 'CODEX Finance',
    FullName: 'CODEX Finance (CODEX)',
  },
  DEXTF: {
    Name: 'DEXTF',
    Symbol: 'DEXTF',
    CoinName: 'DEXTF',
    FullName: 'DEXTF (DEXTF)',
  },
  BYN: {
    Name: 'BYN',
    Symbol: 'BYN',
    CoinName: 'Beyond Finance',
    FullName: 'Beyond Finance (BYN)',
  },
  RVP: {
    Name: 'RVP',
    Symbol: 'RVP',
    CoinName: 'Revolution Populi',
    FullName: 'Revolution Populi (RVP)',
  },
  GAINS: {
    Name: 'GAINS',
    Symbol: 'GAINS',
    CoinName: 'Gains',
    FullName: 'Gains (GAINS)',
  },
  MVI: {
    Name: 'MVI',
    Symbol: 'MVI',
    CoinName: 'Metaverse Index',
    FullName: 'Metaverse Index (MVI)',
  },
  WOMI: {
    Name: 'WOMI',
    Symbol: 'WOMI',
    CoinName: 'Wrapped ECOMI',
    FullName: 'Wrapped ECOMI (WOMI)',
  },
  VNTW: {
    Name: 'VNTW',
    Symbol: 'VNTW',
    CoinName: 'Value Network Token',
    FullName: 'Value Network Token (VNTW)',
  },
  MOFI: {
    Name: 'MOFI',
    Symbol: 'MOFI',
    CoinName: 'MobiFi',
    FullName: 'MobiFi (MOFI)',
  },
  SATA: {
    Name: 'SATA',
    Symbol: 'SATA',
    CoinName: 'Signata',
    FullName: 'Signata (SATA)',
  },
  BSKT: {
    Name: 'BSKT',
    Symbol: 'BSKT',
    CoinName: 'BasketCoin',
    FullName: 'BasketCoin (BSKT)',
  },
  WXTZ: {
    Name: 'WXTZ',
    Symbol: 'WXTZ',
    CoinName: 'Wrapped Tezos',
    FullName: 'Wrapped Tezos (WXTZ)',
  },
  FROGE: {
    Name: 'FROGE',
    Symbol: 'FROGE',
    CoinName: 'Froge Finance',
    FullName: 'Froge Finance (FROGE)',
  },
  ARTEON: {
    Name: 'ARTEON',
    Symbol: 'ARTEON',
    CoinName: 'Arteon',
    FullName: 'Arteon (ARTEON)',
  },
  CLVA: {
    Name: 'CLVA',
    Symbol: 'CLVA',
    CoinName: 'Clever DeFi',
    FullName: 'Clever DeFi (CLVA)',
  },
  SHD: {
    Name: 'SHD',
    Symbol: 'SHD',
    CoinName: 'ShardingDAO',
    FullName: 'ShardingDAO (SHD)',
  },
  KYTE: {
    Name: 'KYTE',
    Symbol: 'KYTE',
    CoinName: 'Kambria Yield Tuning Engine',
    FullName: 'Kambria Yield Tuning Engine (KYTE)',
  },
  GS: {
    Name: 'GS',
    Symbol: 'GS',
    CoinName: 'Genesis Shards',
    FullName: 'Genesis Shards (GS)',
  },
  ZIG: {
    Name: 'ZIG',
    Symbol: 'ZIG',
    CoinName: 'Zignaly',
    FullName: 'Zignaly (ZIG)',
  },
  ZCX: {
    Name: 'ZCX',
    Symbol: 'ZCX',
    CoinName: 'Unizen',
    FullName: 'Unizen (ZCX)',
  },
  DDOS: {
    Name: 'DDOS',
    Symbol: 'DDOS',
    CoinName: 'disBalancer',
    FullName: 'disBalancer (DDOS)',
  },
  SAR: {
    Name: 'SAR',
    Symbol: 'SAR',
    CoinName: 'Saren',
    FullName: 'Saren (SAR)',
  },
  KXUSD: {
    Name: 'KXUSD',
    Symbol: 'KXUSD',
    CoinName: 'kxUSD',
    FullName: 'kxUSD (KXUSD)',
  },
  MUNCH: {
    Name: 'MUNCH',
    Symbol: 'MUNCH',
    CoinName: 'Munch Token',
    FullName: 'Munch Token (MUNCH)',
  },
  WANATHA: {
    Name: 'WANATHA',
    Symbol: 'WANATHA',
    CoinName: 'Wrapped ANATHA',
    FullName: 'Wrapped ANATHA (WANATHA)',
  },
  PDEX: {
    Name: 'PDEX',
    Symbol: 'PDEX',
    CoinName: 'Polkadex',
    FullName: 'Polkadex (PDEX)',
  },
  NAME: {
    Name: 'NAME',
    Symbol: 'NAME',
    CoinName: 'PolkaDomain',
    FullName: 'PolkaDomain (NAME)',
  },
  CYC: {
    Name: 'CYC',
    Symbol: 'CYC',
    CoinName: 'Cyclone Protocol',
    FullName: 'Cyclone Protocol (CYC)',
  },
  LEASH: {
    Name: 'LEASH',
    Symbol: 'LEASH',
    CoinName: 'Doge Killer',
    FullName: 'Doge Killer (LEASH)',
  },
  DOGIRA: {
    Name: 'DOGIRA',
    Symbol: 'DOGIRA',
    CoinName: 'Dogira',
    FullName: 'Dogira (DOGIRA)',
  },
  DXH: {
    Name: 'DXH',
    Symbol: 'DXH',
    CoinName: 'Daxhund',
    FullName: 'Daxhund (DXH)',
  },
  BDOG: {
    Name: 'BDOG',
    Symbol: 'BDOG',
    CoinName: 'Bulldog Token',
    FullName: 'Bulldog Token (BDOG)',
  },
  BCMC1: {
    Name: 'BCMC1',
    Symbol: 'BCMC1',
    CoinName: 'BeforeCoinMarketCap',
    FullName: 'BeforeCoinMarketCap (BCMC1)',
  },
  HUSKY: {
    Name: 'HUSKY',
    Symbol: 'HUSKY',
    CoinName: 'Husky',
    FullName: 'Husky (HUSKY)',
  },
  CHS: {
    Name: 'CHS',
    Symbol: 'CHS',
    CoinName: 'Chainsquare',
    FullName: 'Chainsquare (CHS)',
  },
  PNGN: {
    Name: 'PNGN',
    Symbol: 'PNGN',
    CoinName: 'SpacePenguin',
    FullName: 'SpacePenguin (PNGN)',
  },
  STOGE: {
    Name: 'STOGE',
    Symbol: 'STOGE',
    CoinName: 'Stoner Doge Finance',
    FullName: 'Stoner Doge Finance (STOGE)',
  },
  APN: {
    Name: 'APN',
    Symbol: 'APN',
    CoinName: 'Apron',
    FullName: 'Apron (APN)',
  },
  SYL: {
    Name: 'SYL',
    Symbol: 'SYL',
    CoinName: 'XSL Labs',
    FullName: 'XSL Labs (SYL)',
  },
  XFIT: {
    Name: 'XFIT',
    Symbol: 'XFIT',
    CoinName: 'Xfit',
    FullName: 'Xfit (XFIT)',
  },
  SBGO: {
    Name: 'SBGO',
    Symbol: 'SBGO',
    CoinName: 'Bingo Share',
    FullName: 'Bingo Share (SBGO)',
  },
  DVPN: {
    Name: 'DVPN',
    Symbol: 'DVPN',
    CoinName: 'Sentinel',
    FullName: 'Sentinel (DVPN)',
  },
  DART: {
    Name: 'DART',
    Symbol: 'DART',
    CoinName: 'dART Insurance',
    FullName: 'dART Insurance (DART)',
  },
  GOL: {
    Name: 'GOL',
    Symbol: 'GOL',
    CoinName: 'GogolCoin',
    FullName: 'GogolCoin (GOL)',
  },
  LANDB: {
    Name: 'LANDB',
    Symbol: 'LANDB',
    CoinName: 'LandBox',
    FullName: 'LandBox (LANDB)',
  },
  KEL: {
    Name: 'KEL',
    Symbol: 'KEL',
    CoinName: 'KelVPN',
    FullName: 'KelVPN (KEL)',
  },
  ANS: {
    Name: 'ANS',
    Symbol: 'ANS',
    CoinName: 'ANS Crypto Coin',
    FullName: 'ANS Crypto Coin (ANS)',
  },
  DAIN: {
    Name: 'DAIN',
    Symbol: 'DAIN',
    CoinName: 'Dain Token',
    FullName: 'Dain Token (DAIN)',
  },
  METEOR: {
    Name: 'METEOR',
    Symbol: 'METEOR',
    CoinName: 'Meteorite Network',
    FullName: 'Meteorite Network (METEOR)',
  },
  DHV: {
    Name: 'DHV',
    Symbol: 'DHV',
    CoinName: 'DeHive',
    FullName: 'DeHive (DHV)',
  },
  EQO: {
    Name: 'EQO',
    Symbol: 'EQO',
    CoinName: 'EQO',
    FullName: 'EQO (EQO)',
  },
  GETH: {
    Name: 'GETH',
    Symbol: 'GETH',
    CoinName: 'Guarded Ether',
    FullName: 'Guarded Ether (GETH)',
  },
  DAFT: {
    Name: 'DAFT',
    Symbol: 'DAFT',
    CoinName: 'DaftCoin',
    FullName: 'DaftCoin (DAFT)',
  },
  UMBR: {
    Name: 'UMBR',
    Symbol: 'UMBR',
    CoinName: 'Umbria Network',
    FullName: 'Umbria Network (UMBR)',
  },
  LABRA: {
    Name: 'LABRA',
    Symbol: 'LABRA',
    CoinName: 'LabraCoin',
    FullName: 'LabraCoin (LABRA)',
  },
  SAITO: {
    Name: 'SAITO',
    Symbol: 'SAITO',
    CoinName: 'Saito',
    FullName: 'Saito (SAITO)',
  },
  CBSN: {
    Name: 'CBSN',
    Symbol: 'CBSN',
    CoinName: 'BlockSwap Network',
    FullName: 'BlockSwap Network (CBSN)',
  },
  SDX: {
    Name: 'SDX',
    Symbol: 'SDX',
    CoinName: 'SwapDEX',
    FullName: 'SwapDEX (SDX)',
  },
  SUNI: {
    Name: 'SUNI',
    Symbol: 'SUNI',
    CoinName: 'SUNI',
    FullName: 'SUNI (SUNI)',
  },
  FOXD: {
    Name: 'FOXD',
    Symbol: 'FOXD',
    CoinName: 'Foxdcoin',
    FullName: 'Foxdcoin (FOXD)',
  },
  GAZE: {
    Name: 'GAZE',
    Symbol: 'GAZE',
    CoinName: 'GazeTV',
    FullName: 'GazeTV (GAZE)',
  },
  VCK: {
    Name: 'VCK',
    Symbol: 'VCK',
    CoinName: '28VCK',
    FullName: '28VCK (VCK)',
  },
  ELON: {
    Name: 'ELON',
    Symbol: 'ELON',
    CoinName: 'Dogelon Mars',
    FullName: 'Dogelon Mars (ELON)',
  },
  TOKEN: {
    Name: 'TOKEN',
    Symbol: 'TOKEN',
    CoinName: 'Chainswap',
    FullName: 'Chainswap (TOKEN)',
  },
  LLAND: {
    Name: 'LLAND',
    Symbol: 'LLAND',
    CoinName: 'Lyfe Land',
    FullName: 'Lyfe Land (LLAND)',
  },
  SAFEMARS: {
    Name: 'SAFEMARS',
    Symbol: 'SAFEMARS',
    CoinName: 'Safemars',
    FullName: 'Safemars (SAFEMARS)',
  },
  FASTMOON: {
    Name: 'FASTMOON',
    Symbol: 'FASTMOON',
    CoinName: 'FastMoon',
    FullName: 'FastMoon (FASTMOON)',
  },
  FLX: {
    Name: 'FLX',
    Symbol: 'FLX',
    CoinName: 'Reflexer Ungovernance Token',
    FullName: 'Reflexer Ungovernance Token (FLX)',
  },
  HORD: {
    Name: 'HORD',
    Symbol: 'HORD',
    CoinName: 'Hord',
    FullName: 'Hord (HORD)',
  },
  BAR: {
    Name: 'BAR',
    Symbol: 'BAR',
    CoinName: 'FC Barcelona Fan Token',
    FullName: 'FC Barcelona Fan Token (BAR)',
  },
  LEMD: {
    Name: 'LEMD',
    Symbol: 'LEMD',
    CoinName: 'Lemond',
    FullName: 'Lemond (LEMD)',
  },
  FOXF: {
    Name: 'FOXF',
    Symbol: 'FOXF',
    CoinName: 'Fox Finance',
    FullName: 'Fox Finance (FOXF)',
  },
  MOMA: {
    Name: 'MOMA',
    Symbol: 'MOMA',
    CoinName: 'Mochi Market',
    FullName: 'Mochi Market (MOMA)',
  },
  HAKA: {
    Name: 'HAKA',
    Symbol: 'HAKA',
    CoinName: 'TribeOne',
    FullName: 'TribeOne (HAKA)',
  },
  GASTRO: {
    Name: 'GASTRO',
    Symbol: 'GASTRO',
    CoinName: 'GastroCoin',
    FullName: 'GastroCoin (GASTRO)',
  },
  MUSK: {
    Name: 'MUSK',
    Symbol: 'MUSK',
    CoinName: 'Musk',
    FullName: 'Musk (MUSK)',
  },
  INSUR: {
    Name: 'INSUR',
    Symbol: 'INSUR',
    CoinName: 'InsurAce',
    FullName: 'InsurAce (INSUR)',
  },
  FINE: {
    Name: 'FINE',
    Symbol: 'FINE',
    CoinName: 'Refinable',
    FullName: 'Refinable (FINE)',
  },
  BSCS: {
    Name: 'BSCS',
    Symbol: 'BSCS',
    CoinName: 'BSC Station',
    FullName: 'BSC Station (BSCS)',
  },
  C3: {
    Name: 'C3',
    Symbol: 'C3',
    CoinName: 'Charli3',
    FullName: 'Charli3 (C3)',
  },
  COPS: {
    Name: 'COPS',
    Symbol: 'COPS',
    CoinName: 'Cops Finance',
    FullName: 'Cops Finance (COPS)',
  },
  SMTY: {
    Name: 'SMTY',
    Symbol: 'SMTY',
    CoinName: 'Smoothy',
    FullName: 'Smoothy (SMTY)',
  },
  CRWNY: {
    Name: 'CRWNY',
    Symbol: 'CRWNY',
    CoinName: 'Crowny Token',
    FullName: 'Crowny Token (CRWNY)',
  },
  FEI: {
    Name: 'FEI',
    Symbol: 'FEI',
    CoinName: 'Fei Protocol',
    FullName: 'Fei Protocol (FEI)',
  },
  PYE: {
    Name: 'PYE',
    Symbol: 'PYE',
    CoinName: 'CreamPYE',
    FullName: 'CreamPYE (PYE)',
  },
  PENDLE: {
    Name: 'PENDLE',
    Symbol: 'PENDLE',
    CoinName: 'Pendle',
    FullName: 'Pendle (PENDLE)',
  },
  TOZ: {
    Name: 'TOZ',
    Symbol: 'TOZ',
    CoinName: 'Tozex',
    FullName: 'Tozex (TOZ)',
  },
  BBANK: {
    Name: 'BBANK',
    Symbol: 'BBANK',
    CoinName: 'BlockBank',
    FullName: 'BlockBank (BBANK)',
  },
  ERROR: {
    Name: 'ERROR',
    Symbol: 'ERROR',
    CoinName: '484 Fund',
    FullName: '484 Fund (ERROR)',
  },
  ALPHR: {
    Name: 'ALPHR',
    Symbol: 'ALPHR',
    CoinName: 'Alphr',
    FullName: 'Alphr (ALPHR)',
  },
  ENX: {
    Name: 'ENX',
    Symbol: 'ENX',
    CoinName: 'ENEX',
    FullName: 'ENEX (ENX)',
  },
  MOAR: {
    Name: 'MOAR',
    Symbol: 'MOAR',
    CoinName: 'Moar Finance',
    FullName: 'Moar Finance (MOAR)',
  },
  SHO: {
    Name: 'SHO',
    Symbol: 'SHO',
    CoinName: 'Showcase Token',
    FullName: 'Showcase Token (SHO)',
  },
  CFXQ: {
    Name: 'CFXQ',
    Symbol: 'CFXQ',
    CoinName: 'CFX Quantum',
    FullName: 'CFX Quantum (CFXQ)',
  },
  STACK: {
    Name: 'STACK',
    Symbol: 'STACK',
    CoinName: 'StackOS',
    FullName: 'StackOS (STACK)',
  },
  KFI: {
    Name: 'KFI',
    Symbol: 'KFI',
    CoinName: 'Klever Finance',
    FullName: 'Klever Finance (KFI)',
  },
  EMB: {
    Name: 'EMB',
    Symbol: 'EMB',
    CoinName: 'Overline Emblem',
    FullName: 'Overline Emblem (EMB)',
  },
  PIPI: {
    Name: 'PIPI',
    Symbol: 'PIPI',
    CoinName: 'Pippi Finance',
    FullName: 'Pippi Finance (PIPI)',
  },
  SHEESHA: {
    Name: 'SHEESHA',
    Symbol: 'SHEESHA',
    CoinName: 'Sheesha Finance',
    FullName: 'Sheesha Finance (SHEESHA)',
  },
  ARA: {
    Name: 'ARA',
    Symbol: 'ARA',
    CoinName: 'Ara Token',
    FullName: 'Ara Token (ARA)',
  },
  STND: {
    Name: 'STND',
    Symbol: 'STND',
    CoinName: 'Standard Protocol',
    FullName: 'Standard Protocol (STND)',
  },
  DSCVR: {
    Name: 'DSCVR',
    Symbol: 'DSCVR',
    CoinName: 'DSCVR.Finance',
    FullName: 'DSCVR.Finance (DSCVR)',
  },
  TCP: {
    Name: 'TCP',
    Symbol: 'TCP',
    CoinName: 'The Crypto Prophecies',
    FullName: 'The Crypto Prophecies (TCP)',
  },
  'POP!': {
    Name: 'POP!',
    Symbol: 'POP!',
    CoinName: 'POP',
    FullName: 'POP (POP!)',
  },
  NAOS: {
    Name: 'NAOS',
    Symbol: 'NAOS',
    CoinName: 'NAOS Finance',
    FullName: 'NAOS Finance (NAOS)',
  },
  SOKU: {
    Name: 'SOKU',
    Symbol: 'SOKU',
    CoinName: 'Soku Swap',
    FullName: 'Soku Swap (SOKU)',
  },
  EPK: {
    Name: 'EPK',
    Symbol: 'EPK',
    CoinName: 'EpiK Protocol',
    FullName: 'EpiK Protocol (EPK)',
  },
  RNB: {
    Name: 'RNB',
    Symbol: 'RNB',
    CoinName: 'Rentible',
    FullName: 'Rentible (RNB)',
  },
  LOCG: {
    Name: 'LOCG',
    Symbol: 'LOCG',
    CoinName: 'LOCGame',
    FullName: 'LOCGame (LOCG)',
  },
  KOKO: {
    Name: 'KOKO',
    Symbol: 'KOKO',
    CoinName: 'KokoSwap',
    FullName: 'KokoSwap (KOKO)',
  },
  XCH: {
    Name: 'XCH',
    Symbol: 'XCH',
    CoinName: 'Chia',
    FullName: 'Chia (XCH)',
  },
  PUSSY: {
    Name: 'PUSSY',
    Symbol: 'PUSSY',
    CoinName: 'Pussy Financial',
    FullName: 'Pussy Financial (PUSSY)',
  },
  ARES: {
    Name: 'ARES',
    Symbol: 'ARES',
    CoinName: 'Ares Protocol',
    FullName: 'Ares Protocol (ARES)',
  },
  ORAO: {
    Name: 'ORAO',
    Symbol: 'ORAO',
    CoinName: 'ORAO Network',
    FullName: 'ORAO Network (ORAO)',
  },
  YVBOOST: {
    Name: 'YVBOOST',
    Symbol: 'YVBOOST',
    CoinName: 'Yearn Compounding veCRV yVault',
    FullName: 'Yearn Compounding veCRV yVault (YVBOOST)',
  },
  COSHI: {
    Name: 'COSHI',
    Symbol: 'COSHI',
    CoinName: 'CoShi Inu',
    FullName: 'CoShi Inu (COSHI)',
  },
  MYL: {
    Name: 'MYL',
    Symbol: 'MYL',
    CoinName: 'MyLottoCoin',
    FullName: 'MyLottoCoin (MYL)',
  },
  PEKC: {
    Name: 'PEKC',
    Symbol: 'PEKC',
    CoinName: 'Peacock Coin',
    FullName: 'Peacock Coin (PEKC)',
  },
  PLE: {
    Name: 'PLE',
    Symbol: 'PLE',
    CoinName: 'Plethori',
    FullName: 'Plethori (PLE)',
  },
  COMFI: {
    Name: 'COMFI',
    Symbol: 'COMFI',
    CoinName: 'CompliFi',
    FullName: 'CompliFi (COMFI)',
  },
  BANK: {
    Name: 'BANK',
    Symbol: 'BANK',
    CoinName: 'Float Protocol',
    FullName: 'Float Protocol (BANK)',
  },
  OIL: {
    Name: 'OIL',
    Symbol: 'OIL',
    CoinName: 'Oiler',
    FullName: 'Oiler (OIL)',
  },
  CTX: {
    Name: 'CTX',
    Symbol: 'CTX',
    CoinName: 'Cryptex',
    FullName: 'Cryptex (CTX)',
  },
  COMB: {
    Name: 'COMB',
    Symbol: 'COMB',
    CoinName: 'Combo',
    FullName: 'Combo (COMB)',
  },
  LABS: {
    Name: 'LABS',
    Symbol: 'LABS',
    CoinName: 'LABS Group',
    FullName: 'LABS Group (LABS)',
  },
  ACE: {
    Name: 'ACE',
    Symbol: 'ACE',
    CoinName: 'Acent',
    FullName: 'Acent (ACE)',
  },
  ZJLT: {
    Name: 'ZJLT',
    Symbol: 'ZJLT',
    CoinName: 'ZJLT Distributed Factoring Network',
    FullName: 'ZJLT Distributed Factoring Network (ZJLT)',
  },
  ZEP: {
    Name: 'ZEP',
    Symbol: 'ZEP',
    CoinName: 'Zeppelin Dao',
    FullName: 'Zeppelin Dao (ZEP)',
  },
  HBT: {
    Name: 'HBT',
    Symbol: 'HBT',
    CoinName: 'Habitat',
    FullName: 'Habitat (HBT)',
  },
  UBI: {
    Name: 'UBI',
    Symbol: 'UBI',
    CoinName: 'Universal Basic Income',
    FullName: 'Universal Basic Income (UBI)',
  },
  TRR: {
    Name: 'TRR',
    Symbol: 'TRR',
    CoinName: 'Terran Coin',
    FullName: 'Terran Coin (TRR)',
  },
  LOWB: {
    Name: 'LOWB',
    Symbol: 'LOWB',
    CoinName: 'Loser Coin',
    FullName: 'Loser Coin (LOWB)',
  },
  DFND: {
    Name: 'DFND',
    Symbol: 'DFND',
    CoinName: 'dFund',
    FullName: 'dFund (DFND)',
  },
  SHIH: {
    Name: 'SHIH',
    Symbol: 'SHIH',
    CoinName: 'Shih Tzu',
    FullName: 'Shih Tzu (SHIH)',
  },
  POLX: {
    Name: 'POLX',
    Symbol: 'POLX',
    CoinName: 'Polylastic',
    FullName: 'Polylastic (POLX)',
  },
  GYEN: {
    Name: 'GYEN',
    Symbol: 'GYEN',
    CoinName: 'GYEN',
    FullName: 'GYEN (GYEN)',
  },
  BIFIF: {
    Name: 'BIFIF',
    Symbol: 'BIFIF',
    CoinName: 'BiFi',
    FullName: 'BiFi (BIFIF)',
  },
  CEUR: {
    Name: 'CEUR',
    Symbol: 'CEUR',
    CoinName: 'Celo Euro',
    FullName: 'Celo Euro (CEUR)',
  },
  ZUSD: {
    Name: 'ZUSD',
    Symbol: 'ZUSD',
    CoinName: 'ZUSD',
    FullName: 'ZUSD (ZUSD)',
  },
  FCL: {
    Name: 'FCL',
    Symbol: 'FCL',
    CoinName: 'Fractal',
    FullName: 'Fractal (FCL)',
  },
  ZWAP: {
    Name: 'ZWAP',
    Symbol: 'ZWAP',
    CoinName: 'ZilSwap',
    FullName: 'ZilSwap (ZWAP)',
  },
  COCK: {
    Name: 'COCK',
    Symbol: 'COCK',
    CoinName: 'Shibacock',
    FullName: 'Shibacock (COCK)',
  },
  DFYN: {
    Name: 'DFYN',
    Symbol: 'DFYN',
    CoinName: 'Dfyn Network',
    FullName: 'Dfyn Network (DFYN)',
  },
  JINDOGE: {
    Name: 'JINDOGE',
    Symbol: 'JINDOGE',
    CoinName: 'Jindoge',
    FullName: 'Jindoge (JINDOGE)',
  },
  GDT: {
    Name: 'GDT',
    Symbol: 'GDT',
    CoinName: 'Globe Derivative Exchange',
    FullName: 'Globe Derivative Exchange (GDT)',
  },
  RAIF: {
    Name: 'RAIF',
    Symbol: 'RAIF',
    CoinName: 'RAI Finance',
    FullName: 'RAI Finance (RAIF)',
  },
  CSPR: {
    Name: 'CSPR',
    Symbol: 'CSPR',
    CoinName: 'Casper Network',
    FullName: 'Casper Network (CSPR)',
  },
  LAT: {
    Name: 'LAT',
    Symbol: 'LAT',
    CoinName: 'PlatON Network',
    FullName: 'PlatON Network (LAT)',
  },
  SANSHU: {
    Name: 'SANSHU',
    Symbol: 'SANSHU',
    CoinName: 'Sanshu Inu',
    FullName: 'Sanshu Inu (SANSHU)',
  },
  ARGO: {
    Name: 'ARGO',
    Symbol: 'ARGO',
    CoinName: 'ArGoApp',
    FullName: 'ArGoApp (ARGO)',
  },
  WOOFY: {
    Name: 'WOOFY',
    Symbol: 'WOOFY',
    CoinName: 'Woofy',
    FullName: 'Woofy (WOOFY)',
  },
  WAR: {
    Name: 'WAR',
    Symbol: 'WAR',
    CoinName: 'WeStarter',
    FullName: 'WeStarter (WAR)',
  },
  KKO: {
    Name: 'KKO',
    Symbol: 'KKO',
    CoinName: 'Kineko',
    FullName: 'Kineko (KKO)',
  },
  DOP: {
    Name: 'DOP',
    Symbol: 'DOP',
    CoinName: 'Drops',
    FullName: 'Drops (DOP)',
  },
  CSS: {
    Name: 'CSS',
    Symbol: 'CSS',
    CoinName: 'CoinSwap Token',
    FullName: 'CoinSwap Token (CSS)',
  },
  PHIBA: {
    Name: 'PHIBA',
    Symbol: 'PHIBA',
    CoinName: 'Papa Shiba',
    FullName: 'Papa Shiba (PHIBA)',
  },
  'ETH2X-FLI': {
    Name: 'ETH2X-FLI',
    Symbol: 'ETH2X-FLI',
    CoinName: 'ETH 2x Flexible Leverage Index',
    FullName: 'ETH 2x Flexible Leverage Index (ETH2X-FLI)',
  },
  DOGEDAO: {
    Name: 'DOGEDAO',
    Symbol: 'DOGEDAO',
    CoinName: 'DogeDao',
    FullName: 'DogeDao (DOGEDAO)',
  },
  EMAX: {
    Name: 'EMAX',
    Symbol: 'EMAX',
    CoinName: 'EthereumMax',
    FullName: 'EthereumMax (EMAX)',
  },
  PNODE: {
    Name: 'PNODE',
    Symbol: 'PNODE',
    CoinName: 'Pinknode',
    FullName: 'Pinknode (PNODE)',
  },
  KLEE: {
    Name: 'KLEE',
    Symbol: 'KLEE',
    CoinName: 'KleeKai',
    FullName: 'KleeKai (KLEE)',
  },
  HNZO: {
    Name: 'HNZO',
    Symbol: 'HNZO',
    CoinName: 'Hanzo Inu',
    FullName: 'Hanzo Inu (HNZO)',
  },
  GARUDA: {
    Name: 'GARUDA',
    Symbol: 'GARUDA',
    CoinName: 'GarudaSwap',
    FullName: 'GarudaSwap (GARUDA)',
  },
  CLS: {
    Name: 'CLS',
    Symbol: 'CLS',
    CoinName: 'Coldstack',
    FullName: 'Coldstack (CLS)',
  },
  WSDOGE: {
    Name: 'WSDOGE',
    Symbol: 'WSDOGE',
    CoinName: 'Doge of Woof Street',
    FullName: 'Doge of Woof Street (WSDOGE)',
  },
  MALLY: {
    Name: 'MALLY',
    Symbol: 'MALLY',
    CoinName: 'Malamute Finance',
    FullName: 'Malamute Finance (MALLY)',
  },
  FOGE: {
    Name: 'FOGE',
    Symbol: 'FOGE',
    CoinName: 'Fat Doge',
    FullName: 'Fat Doge (FOGE)',
  },
  ELONGD: {
    Name: 'ELONGD',
    Symbol: 'ELONGD',
    CoinName: 'Elongate Deluxe',
    FullName: 'Elongate Deluxe (ELONGD)',
  },
  EROTICA: {
    Name: 'EROTICA',
    Symbol: 'EROTICA',
    CoinName: 'Erotica',
    FullName: 'Erotica (EROTICA)',
  },
  PAND: {
    Name: 'PAND',
    Symbol: 'PAND',
    CoinName: 'Panda Finance',
    FullName: 'Panda Finance (PAND)',
  },
  CYBERD: {
    Name: 'CYBERD',
    Symbol: 'CYBERD',
    CoinName: 'Cyber Doge',
    FullName: 'Cyber Doge (CYBERD)',
  },
  CHIWAWA: {
    Name: 'CHIWAWA',
    Symbol: 'CHIWAWA',
    CoinName: 'Chiwawa',
    FullName: 'Chiwawa (CHIWAWA)',
  },
  DUCK: {
    Name: 'DUCK',
    Symbol: 'DUCK',
    CoinName: 'Unit Protocol New',
    FullName: 'Unit Protocol New (DUCK)',
  },
  GERO: {
    Name: 'GERO',
    Symbol: 'GERO',
    CoinName: 'GeroWallet',
    FullName: 'GeroWallet (GERO)',
  },
  WILC: {
    Name: 'WILC',
    Symbol: 'WILC',
    CoinName: 'Wrapped ILCOIN',
    FullName: 'Wrapped ILCOIN (WILC)',
  },
  XKI: {
    Name: 'XKI',
    Symbol: 'XKI',
    CoinName: 'Ki',
    FullName: 'Ki (XKI)',
  },
  CVX: {
    Name: 'CVX',
    Symbol: 'CVX',
    CoinName: 'Convex Finance',
    FullName: 'Convex Finance (CVX)',
  },
  EMON: {
    Name: 'EMON',
    Symbol: 'EMON',
    CoinName: 'Ethermon',
    FullName: 'Ethermon (EMON)',
  },
  RUSH: {
    Name: 'RUSH',
    Symbol: 'RUSH',
    CoinName: 'RUSH COIN',
    FullName: 'RUSH COIN (RUSH)',
  },
  BIST: {
    Name: 'BIST',
    Symbol: 'BIST',
    CoinName: 'Bistroo',
    FullName: 'Bistroo (BIST)',
  },
  LOCC: {
    Name: 'LOCC',
    Symbol: 'LOCC',
    CoinName: 'Low Orbit Crypto Cannon',
    FullName: 'Low Orbit Crypto Cannon (LOCC)',
  },
  BEZOGE: {
    Name: 'BEZOGE',
    Symbol: 'BEZOGE',
    CoinName: 'Bezoge Earth',
    FullName: 'Bezoge Earth (BEZOGE)',
  },
  CHIHUA: {
    Name: 'CHIHUA',
    Symbol: 'CHIHUA',
    CoinName: 'Chihua Token',
    FullName: 'Chihua Token (CHIHUA)',
  },
  CORGI: {
    Name: 'CORGI',
    Symbol: 'CORGI',
    CoinName: 'Corgi Inu',
    FullName: 'Corgi Inu (CORGI)',
  },
  MFS: {
    Name: 'MFS',
    Symbol: 'MFS',
    CoinName: 'Moonbase File System',
    FullName: 'Moonbase File System (MFS)',
  },
  CLU: {
    Name: 'CLU',
    Symbol: 'CLU',
    CoinName: 'CluCoin',
    FullName: 'CluCoin (CLU)',
  },
  YFX: {
    Name: 'YFX',
    Symbol: 'YFX',
    CoinName: 'Your Futures Exchange',
    FullName: 'Your Futures Exchange (YFX)',
  },
  USHIBA: {
    Name: 'USHIBA',
    Symbol: 'USHIBA',
    CoinName: 'American Shiba',
    FullName: 'American Shiba (USHIBA)',
  },
  APW: {
    Name: 'APW',
    Symbol: 'APW',
    CoinName: 'APWine',
    FullName: 'APWine (APW)',
  },
  LUNE: {
    Name: 'LUNE',
    Symbol: 'LUNE',
    CoinName: 'Luneko',
    FullName: 'Luneko (LUNE)',
  },
  MGUL: {
    Name: 'MGUL',
    Symbol: 'MGUL',
    CoinName: 'Mogul Coin',
    FullName: 'Mogul Coin (MGUL)',
  },
  DOGACOIN: {
    Name: 'DOGACOIN',
    Symbol: 'DOGACOIN',
    CoinName: 'DogaCoin',
    FullName: 'DogaCoin (DOGACOIN)',
  },
  DEFT: {
    Name: 'DEFT',
    Symbol: 'DEFT',
    CoinName: 'DeFi Factory Token',
    FullName: 'DeFi Factory Token (DEFT)',
  },
  ZOOT: {
    Name: 'ZOOT',
    Symbol: 'ZOOT',
    CoinName: 'Zoo Token',
    FullName: 'Zoo Token (ZOOT)',
  },
  TKINU: {
    Name: 'TKINU',
    Symbol: 'TKINU',
    CoinName: 'Tsuki Inu',
    FullName: 'Tsuki Inu (TKINU)',
  },
  XAI: {
    Name: 'XAI',
    Symbol: 'XAI',
    CoinName: 'SideShift Token',
    FullName: 'SideShift Token (XAI)',
  },
  KEI: {
    Name: 'KEI',
    Symbol: 'KEI',
    CoinName: 'Keisuke Inu',
    FullName: 'Keisuke Inu (KEI)',
  },
  KALLY: {
    Name: 'KALLY',
    Symbol: 'KALLY',
    CoinName: 'Polkally',
    FullName: 'Polkally (KALLY)',
  },
  HOTCROSS: {
    Name: 'HOTCROSS',
    Symbol: 'HOTCROSS',
    CoinName: 'Hot Cross',
    FullName: 'Hot Cross (HOTCROSS)',
  },
  KUB: {
    Name: 'KUB',
    Symbol: 'KUB',
    CoinName: 'Bitkub Coin',
    FullName: 'Bitkub Coin (KUB)',
  },
  NOA: {
    Name: 'NOA',
    Symbol: 'NOA',
    CoinName: 'NOA PLAY',
    FullName: 'NOA PLAY (NOA)',
  },
  BSL: {
    Name: 'BSL',
    Symbol: 'BSL',
    CoinName: 'BankSocial',
    FullName: 'BankSocial (BSL)',
  },
  FFA: {
    Name: 'FFA',
    Symbol: 'FFA',
    CoinName: 'Cryptofifa',
    FullName: 'Cryptofifa (FFA)',
  },
  NMT: {
    Name: 'NMT',
    Symbol: 'NMT',
    CoinName: 'NFTMart Token',
    FullName: 'NFTMart Token (NMT)',
  },
  CHINU: {
    Name: 'CHINU',
    Symbol: 'CHINU',
    CoinName: 'Chubby Inu',
    FullName: 'Chubby Inu (CHINU)',
  },
  PIZZA: {
    Name: 'PIZZA',
    Symbol: 'PIZZA',
    CoinName: 'PizzaSwap',
    FullName: 'PizzaSwap (PIZZA)',
  },
  MEDIA: {
    Name: 'MEDIA',
    Symbol: 'MEDIA',
    CoinName: 'Media Network',
    FullName: 'Media Network (MEDIA)',
  },
  MER: {
    Name: 'MER',
    Symbol: 'MER',
    CoinName: 'Mercurial Finance',
    FullName: 'Mercurial Finance (MER)',
  },
  GTC: {
    Name: 'GTC',
    Symbol: 'GTC',
    CoinName: 'Gitcoin',
    FullName: 'Gitcoin (GTC)',
  },
  PNL: {
    Name: 'PNL',
    Symbol: 'PNL',
    CoinName: 'True PNL',
    FullName: 'True PNL (PNL)',
  },
  ZCOR: {
    Name: 'ZCOR',
    Symbol: 'ZCOR',
    CoinName: 'Zrocor',
    FullName: 'Zrocor (ZCOR)',
  },
  USDAP: {
    Name: 'USDAP',
    Symbol: 'USDAP',
    CoinName: 'Bond Appetite USD',
    FullName: 'Bond Appetite USD (USDAP)',
  },
  UPCO2: {
    Name: 'UPCO2',
    Symbol: 'UPCO2',
    CoinName: 'Universal Carbon',
    FullName: 'Universal Carbon (UPCO2)',
  },
  CAIZ: {
    Name: 'CAIZ',
    Symbol: 'CAIZ',
    CoinName: 'Caizcoin',
    FullName: 'Caizcoin (CAIZ)',
  },
  O3: {
    Name: 'O3',
    Symbol: 'O3',
    CoinName: 'O3 Swap',
    FullName: 'O3 Swap (O3)',
  },
  CHEX: {
    Name: 'CHEX',
    Symbol: 'CHEX',
    CoinName: 'Chintai',
    FullName: 'Chintai (CHEX)',
  },
  FLY: {
    Name: 'FLY',
    Symbol: 'FLY',
    CoinName: 'Franklin',
    FullName: 'Franklin (FLY)',
  },
  HYVE: {
    Name: 'HYVE',
    Symbol: 'HYVE',
    CoinName: 'Hyve',
    FullName: 'Hyve (HYVE)',
  },
  BLKC: {
    Name: 'BLKC',
    Symbol: 'BLKC',
    CoinName: 'BlackHat Coin',
    FullName: 'BlackHat Coin (BLKC)',
  },
  ELONONE: {
    Name: 'ELONONE',
    Symbol: 'ELONONE',
    CoinName: 'AstroElon',
    FullName: 'AstroElon (ELONONE)',
  },
  FINU: {
    Name: 'FINU',
    Symbol: 'FINU',
    CoinName: 'Fire Inu',
    FullName: 'Fire Inu (FINU)',
  },
  DEK: {
    Name: 'DEK',
    Symbol: 'DEK',
    CoinName: 'DekBox',
    FullName: 'DekBox (DEK)',
  },
  IONX: {
    Name: 'IONX',
    Symbol: 'IONX',
    CoinName: 'Charged Particles',
    FullName: 'Charged Particles (IONX)',
  },
  HINA: {
    Name: 'HINA',
    Symbol: 'HINA',
    CoinName: 'Hina Inu',
    FullName: 'Hina Inu (HINA)',
  },
  CAPS: {
    Name: 'CAPS',
    Symbol: 'CAPS',
    CoinName: 'Ternoa',
    FullName: 'Ternoa (CAPS)',
  },
  GEMS: {
    Name: 'GEMS',
    Symbol: 'GEMS',
    CoinName: 'Carbon GEMS',
    FullName: 'Carbon GEMS (GEMS)',
  },
  JEJUDOGE: {
    Name: 'JEJUDOGE',
    Symbol: 'JEJUDOGE',
    CoinName: 'Jejudoge',
    FullName: 'Jejudoge (JEJUDOGE)',
  },
  OPS: {
    Name: 'OPS',
    Symbol: 'OPS',
    CoinName: 'Octopus Protocol',
    FullName: 'Octopus Protocol (OPS)',
  },
  ARTDECO: {
    Name: 'ARTDECO',
    Symbol: 'ARTDECO',
    CoinName: 'ARTDECO',
    FullName: 'ARTDECO (ARTDECO)',
  },
  ATA: {
    Name: 'ATA',
    Symbol: 'ATA',
    CoinName: 'Automata',
    FullName: 'Automata (ATA)',
  },
  WDR: {
    Name: 'WDR',
    Symbol: 'WDR',
    CoinName: 'Wider Coin',
    FullName: 'Wider Coin (WDR)',
  },
  VEED: {
    Name: 'VEED',
    Symbol: 'VEED',
    CoinName: 'VEED',
    FullName: 'VEED (VEED)',
  },
  FIRU: {
    Name: 'FIRU',
    Symbol: 'FIRU',
    CoinName: 'Firulais Finance',
    FullName: 'Firulais Finance (FIRU)',
  },
  N1: {
    Name: 'N1',
    Symbol: 'N1',
    CoinName: 'NFTify',
    FullName: 'NFTify (N1)',
  },
  LESS: {
    Name: 'LESS',
    Symbol: 'LESS',
    CoinName: 'Less Network',
    FullName: 'Less Network (LESS)',
  },
  LSS: {
    Name: 'LSS',
    Symbol: 'LSS',
    CoinName: 'Lossless',
    FullName: 'Lossless (LSS)',
  },
  NIIFI: {
    Name: 'NIIFI',
    Symbol: 'NIIFI',
    CoinName: 'NiiFi',
    FullName: 'NiiFi (NIIFI)',
  },
  MYOBU: {
    Name: 'MYOBU',
    Symbol: 'MYOBU',
    CoinName: 'Myōbu',
    FullName: 'Myōbu (MYOBU)',
  },
  KDOGE: {
    Name: 'KDOGE',
    Symbol: 'KDOGE',
    CoinName: 'KingDoge',
    FullName: 'KingDoge (KDOGE)',
  },
  TINKU: {
    Name: 'TINKU',
    Symbol: 'TINKU',
    CoinName: 'TinkuCoin',
    FullName: 'TinkuCoin (TINKU)',
  },
  CAVA: {
    Name: 'CAVA',
    Symbol: 'CAVA',
    CoinName: 'Cavapoo',
    FullName: 'Cavapoo (CAVA)',
  },
  SAFEHAMSTERS: {
    Name: 'SAFEHAMSTERS',
    Symbol: 'SAFEHAMSTERS',
    CoinName: 'SafeHamsters',
    FullName: 'SafeHamsters (SAFEHAMSTERS)',
  },
  PUGL: {
    Name: 'PUGL',
    Symbol: 'PUGL',
    CoinName: 'PugLife',
    FullName: 'PugLife (PUGL)',
  },
  YUANG: {
    Name: 'YUANG',
    Symbol: 'YUANG',
    CoinName: 'Yuang Coin',
    FullName: 'Yuang Coin (YUANG)',
  },
  SSGT: {
    Name: 'SSGT',
    Symbol: 'SSGT',
    CoinName: 'Safeswap',
    FullName: 'Safeswap (SSGT)',
  },
  SCOIN: {
    Name: 'SCOIN',
    Symbol: 'SCOIN',
    CoinName: 'ShinCoin',
    FullName: 'ShinCoin (SCOIN)',
  },
  BUFFDOGE: {
    Name: 'BUFFDOGE',
    Symbol: 'BUFFDOGE',
    CoinName: 'Buff Doge',
    FullName: 'Buff Doge (BUFFDOGE)',
  },
  MOZ: {
    Name: 'MOZ',
    Symbol: 'MOZ',
    CoinName: 'Mozik',
    FullName: 'Mozik (MOZ)',
  },
  BKING: {
    Name: 'BKING',
    Symbol: 'BKING',
    CoinName: 'King Arthur',
    FullName: 'King Arthur (BKING)',
  },
  BIDCOM: {
    Name: 'BIDCOM',
    Symbol: 'BIDCOM',
    CoinName: 'Bidcommerce',
    FullName: 'Bidcommerce (BIDCOM)',
  },
  IOI: {
    Name: 'IOI',
    Symbol: 'IOI',
    CoinName: 'IOI Token',
    FullName: 'IOI Token (IOI)',
  },
  AI: {
    Name: 'AI',
    Symbol: 'AI',
    CoinName: 'Multiverse',
    FullName: 'Multiverse (AI)',
  },
  KGO: {
    Name: 'KGO',
    Symbol: 'KGO',
    CoinName: 'Kiwigo',
    FullName: 'Kiwigo (KGO)',
  },
  QUARASHI: {
    Name: 'QUARASHI',
    Symbol: 'QUARASHI',
    CoinName: 'Quarashi Network',
    FullName: 'Quarashi Network (QUARASHI)',
  },
  SWISE: {
    Name: 'SWISE',
    Symbol: 'SWISE',
    CoinName: 'StakeWise',
    FullName: 'StakeWise (SWISE)',
  },
  IRON: {
    Name: 'IRON',
    Symbol: 'IRON',
    CoinName: 'Iron BSC',
    FullName: 'Iron BSC (IRON)',
  },
  ROSN: {
    Name: 'ROSN',
    Symbol: 'ROSN',
    CoinName: 'Roseon Finance',
    FullName: 'Roseon Finance (ROSN)',
  },
  BUGG: {
    Name: 'BUGG',
    Symbol: 'BUGG',
    CoinName: 'Bugg Inu',
    FullName: 'Bugg Inu (BUGG)',
  },
  GNBT: {
    Name: 'GNBT',
    Symbol: 'GNBT',
    CoinName: 'Genebank Token',
    FullName: 'Genebank Token (GNBT)',
  },
  ANV: {
    Name: 'ANV',
    Symbol: 'ANV',
    CoinName: 'Aniverse',
    FullName: 'Aniverse (ANV)',
  },
  PDOG: {
    Name: 'PDOG',
    Symbol: 'PDOG',
    CoinName: 'Polkadog',
    FullName: 'Polkadog (PDOG)',
  },
  VBSC: {
    Name: 'VBSC',
    Symbol: 'VBSC',
    CoinName: 'Votechain',
    FullName: 'Votechain (VBSC)',
  },
  KEYFI: {
    Name: 'KEYFI',
    Symbol: 'KEYFI',
    CoinName: 'KeyFi',
    FullName: 'KeyFi (KEYFI)',
  },
  HITBTC: {
    Name: 'HITBTC',
    Symbol: 'HITBTC',
    CoinName: 'HitBTC Token',
    FullName: 'HitBTC Token (HITBTC)',
  },
  FORM: {
    Name: 'FORM',
    Symbol: 'FORM',
    CoinName: 'Formation FI',
    FullName: 'Formation FI (FORM)',
  },
  MRNA: {
    Name: 'MRNA',
    Symbol: 'MRNA',
    CoinName: 'Moderna',
    FullName: 'Moderna (MRNA)',
  },
  CQT: {
    Name: 'CQT',
    Symbol: 'CQT',
    CoinName: 'Covalent',
    FullName: 'Covalent (CQT)',
  },
  BZZ: {
    Name: 'BZZ',
    Symbol: 'BZZ',
    CoinName: 'Swarmv',
    FullName: 'Swarmv (BZZ)',
  },
  NABOX: {
    Name: 'NABOX',
    Symbol: 'NABOX',
    CoinName: 'Nabox',
    FullName: 'Nabox (NABOX)',
  },
  OMT: {
    Name: 'OMT',
    Symbol: 'OMT',
    CoinName: 'Mars Token',
    FullName: 'Mars Token (OMT)',
  },
  COOP: {
    Name: 'COOP',
    Symbol: 'COOP',
    CoinName: 'Coop Network',
    FullName: 'Coop Network (COOP)',
  },
  MONARCH: {
    Name: 'MONARCH',
    Symbol: 'MONARCH',
    CoinName: 'TRUEMONARCH',
    FullName: 'TRUEMONARCH (MONARCH)',
  },
  INST: {
    Name: 'INST',
    Symbol: 'INST',
    CoinName: 'Instadapp',
    FullName: 'Instadapp (INST)',
  },
  PEA: {
    Name: 'PEA',
    Symbol: 'PEA',
    CoinName: 'Pea Farm',
    FullName: 'Pea Farm (PEA)',
  },
  DPX: {
    Name: 'DPX',
    Symbol: 'DPX',
    CoinName: 'Dopex',
    FullName: 'Dopex (DPX)',
  },
  RDPX: {
    Name: 'RDPX',
    Symbol: 'RDPX',
    CoinName: 'Dopex Rebate Token',
    FullName: 'Dopex Rebate Token (RDPX)',
  },
  TONTOKEN: {
    Name: 'TONTOKEN',
    Symbol: 'TONTOKEN',
    CoinName: 'TONToken',
    FullName: 'TONToken (TONTOKEN)',
  },
  LION: {
    Name: 'LION',
    Symbol: 'LION',
    CoinName: 'Lion Token',
    FullName: 'Lion Token (LION)',
  },
  FOODC: {
    Name: 'FOODC',
    Symbol: 'FOODC',
    CoinName: 'Food Club',
    FullName: 'Food Club (FOODC)',
  },
  NEFTIPEDIA: {
    Name: 'NEFTIPEDIA',
    Symbol: 'NEFTIPEDIA',
    CoinName: 'NEFTiPEDiA',
    FullName: 'NEFTiPEDiA (NEFTIPEDIA)',
  },
  BABYFLOKI: {
    Name: 'BABYFLOKI',
    Symbol: 'BABYFLOKI',
    CoinName: 'BabyFloki',
    FullName: 'BabyFloki (BABYFLOKI)',
  },
  SFUEL: {
    Name: 'SFUEL',
    Symbol: 'SFUEL',
    CoinName: 'SparkPoint Fuel',
    FullName: 'SparkPoint Fuel (SFUEL)',
  },
  PANGEA: {
    Name: 'PANGE',
    Symbol: 'PANGEA',
    CoinName: 'PANGEA',
    FullName: 'PANGEA (PANGE)',
  },
  PYQ: {
    Name: 'PYQ',
    Symbol: 'PYQ',
    CoinName: 'PolyQuity',
    FullName: 'PolyQuity (PYQ)',
  },
  PAPADOGE: {
    Name: 'PAPADOGE',
    Symbol: 'PAPADOGE',
    CoinName: 'Papa Doge',
    FullName: 'Papa Doge (PAPADOGE)',
  },
  PEEPS: {
    Name: 'PEEPS',
    Symbol: 'PEEPS',
    CoinName: 'The People’s Coin',
    FullName: 'The People’s Coin (PEEPS)',
  },
  FLOKI: {
    Name: 'FLOKI',
    Symbol: 'FLOKI',
    CoinName: 'Floki Inu',
    FullName: 'Floki Inu (FLOKI)',
  },
  EVZ: {
    Name: 'EVZ',
    Symbol: 'EVZ',
    CoinName: 'Electric Vehicle Zone',
    FullName: 'Electric Vehicle Zone (EVZ)',
  },
  MVC: {
    Name: 'MVC',
    Symbol: 'MVC',
    CoinName: 'MileVerse',
    FullName: 'MileVerse (MVC)',
  },
  MM: {
    Name: 'MM',
    Symbol: 'MM',
    CoinName: 'Millimeter',
    FullName: 'Millimeter (MM)',
  },
  XAVA: {
    Name: 'XAVA',
    Symbol: 'XAVA',
    CoinName: 'Avalaunch',
    FullName: 'Avalaunch (XAVA)',
  },
  MEM: {
    Name: 'MEM',
    Symbol: 'MEM',
    CoinName: 'Memecoin',
    FullName: 'Memecoin (MEM)',
  },
  GENS: {
    Name: 'GENS',
    Symbol: 'GENS',
    CoinName: 'Genshiro',
    FullName: 'Genshiro (GENS)',
  },
  TRIBE: {
    Name: 'TRIBE',
    Symbol: 'TRIBE',
    CoinName: 'Tribe',
    FullName: 'Tribe (TRIBE)',
  },
  AIDI: {
    Name: 'AIDI',
    Symbol: 'AIDI',
    CoinName: 'Aidi Inu',
    FullName: 'Aidi Inu (AIDI)',
  },
  NBNG: {
    Name: 'NBNG',
    Symbol: 'NBNG',
    CoinName: 'Nobunaga Token',
    FullName: 'Nobunaga Token (NBNG)',
  },
  MATPAD: {
    Name: 'MATPAD',
    Symbol: 'MATPAD',
    CoinName: 'MaticPad',
    FullName: 'MaticPad (MATPAD)',
  },
  BMON: {
    Name: 'BMON',
    Symbol: 'BMON',
    CoinName: 'Binamon',
    FullName: 'Binamon (BMON)',
  },
  COGE: {
    Name: 'COGE',
    Symbol: 'COGE',
    CoinName: 'Cogecoin',
    FullName: 'Cogecoin (COGE)',
  },
  INARI: {
    Name: 'INARI',
    Symbol: 'INARI',
    CoinName: 'Inari',
    FullName: 'Inari (INARI)',
  },
  PIN: {
    Name: 'PIN',
    Symbol: 'PIN',
    CoinName: 'Pin',
    FullName: 'Pin (PIN)',
  },
  FOREVERUP: {
    Name: 'FOREVERUP',
    Symbol: 'FOREVERUP',
    CoinName: 'ForeverUp',
    FullName: 'ForeverUp (FOREVERUP)',
  },
  BAKT: {
    Name: 'BAKT',
    Symbol: 'BAKT',
    CoinName: 'Backed Protocol',
    FullName: 'Backed Protocol (BAKT)',
  },
  ARTG: {
    Name: 'ARTG',
    Symbol: 'ARTG',
    CoinName: 'Goya Giant Token',
    FullName: 'Goya Giant Token (ARTG)',
  },
  RAPDOGE: {
    Name: 'RAPDOGE',
    Symbol: 'RAPDOGE',
    CoinName: 'RapDoge',
    FullName: 'RapDoge (RAPDOGE)',
  },
  RNBW: {
    Name: 'RNBW',
    Symbol: 'RNBW',
    CoinName: 'Rainbow Token',
    FullName: 'Rainbow Token (RNBW)',
  },
  '2CRZ': {
    Name: '2CRZ',
    Symbol: '2CRZ',
    CoinName: '2crazyNFT',
    FullName: '2crazyNFT (2CRZ)',
  },
  WCS: {
    Name: 'WCS',
    Symbol: 'WCS',
    CoinName: 'Weecoins',
    FullName: 'Weecoins (WCS)',
  },
  SMBSWAP: {
    Name: 'SMBSWAP',
    Symbol: 'SMBSWAP',
    CoinName: 'SimbCoin Swap',
    FullName: 'SimbCoin Swap (SMBSWAP)',
  },
  STARL: {
    Name: 'STARL',
    Symbol: 'STARL',
    CoinName: 'StarLink',
    FullName: 'StarLink (STARL)',
  },
  DRCT: {
    Name: 'DRCT',
    Symbol: 'DRCT',
    CoinName: 'Ally Direct',
    FullName: 'Ally Direct (DRCT)',
  },
  WLITI: {
    Name: 'WLITI',
    Symbol: 'WLITI',
    CoinName: 'wLITI',
    FullName: 'wLITI (WLITI)',
  },
  STEP: {
    Name: 'STEP',
    Symbol: 'STEP',
    CoinName: 'Step Finance',
    FullName: 'Step Finance (STEP)',
  },
  F9: {
    Name: 'F9',
    Symbol: 'F9',
    CoinName: 'Falcon Nine',
    FullName: 'Falcon Nine (F9)',
  },
  PDOGE: {
    Name: 'PDOGE',
    Symbol: 'PDOGE',
    CoinName: 'PolkaDoge',
    FullName: 'PolkaDoge (PDOGE)',
  },
  RAIL: {
    Name: 'RAIL',
    Symbol: 'RAIL',
    CoinName: 'Railgun',
    FullName: 'Railgun (RAIL)',
  },
  PSWAP: {
    Name: 'PSWAP',
    Symbol: 'PSWAP',
    CoinName: 'Polkaswap',
    FullName: 'Polkaswap (PSWAP)',
  },
  DFC: {
    Name: 'DFC',
    Symbol: 'DFC',
    CoinName: 'DeFiScale',
    FullName: 'DeFiScale (DFC)',
  },
  BYTZ: {
    Name: 'BYTZ',
    Symbol: 'BYTZ',
    CoinName: 'BYTZ',
    FullName: 'BYTZ (BYTZ)',
  },
  MO: {
    Name: 'MO',
    Symbol: 'MO',
    CoinName: 'Morality',
    FullName: 'Morality (MO)',
  },
  SUPERBID: {
    Name: 'SUPERBID',
    Symbol: 'SUPERBID',
    CoinName: 'SuperBid',
    FullName: 'SuperBid (SUPERBID)',
  },
  GOC: {
    Name: 'GOC',
    Symbol: 'GOC',
    CoinName: 'GoCrypto',
    FullName: 'GoCrypto (GOC)',
  },
  HUB: {
    Name: 'HUB',
    Symbol: 'HUB',
    CoinName: 'Hub Token',
    FullName: 'Hub Token (HUB)',
  },
  MG: {
    Name: 'MG',
    Symbol: 'MG',
    CoinName: 'MinerGate Token',
    FullName: 'MinerGate Token (MG)',
  },
  OCC: {
    Name: 'OCC',
    Symbol: 'OCC',
    CoinName: 'OccamFi',
    FullName: 'OccamFi (OCC)',
  },
  AIM: {
    Name: 'AIM',
    Symbol: 'AIM',
    CoinName: 'ModiHost',
    FullName: 'ModiHost (AIM)',
  },
  MISHKA: {
    Name: 'MISHKA',
    Symbol: 'MISHKA',
    CoinName: 'Mishka Token',
    FullName: 'Mishka Token (MISHKA)',
  },
  XRUNE: {
    Name: 'XRUNE',
    Symbol: 'XRUNE',
    CoinName: 'Thorstarter',
    FullName: 'Thorstarter (XRUNE)',
  },
  ASTROLION: {
    Name: 'ASTROLION',
    Symbol: 'ASTROLION',
    CoinName: 'AstroLion',
    FullName: 'AstroLion (ASTROLION)',
  },
  DOOR: {
    Name: 'DOOR',
    Symbol: 'DOOR',
    CoinName: 'DOOR',
    FullName: 'DOOR (DOOR)',
  },
  NPLC: {
    Name: 'NPLC',
    Symbol: 'NPLC',
    CoinName: 'Plus Coin',
    FullName: 'Plus Coin (NPLC)',
  },
  FESS: {
    Name: 'FESS',
    Symbol: 'FESS',
    CoinName: 'Fesschain',
    FullName: 'Fesschain (FESS)',
  },
  DDRST: {
    Name: 'DDRST',
    Symbol: 'DDRST',
    CoinName: 'DigiDinar StableToken',
    FullName: 'DigiDinar StableToken (DDRST)',
  },
  G999: {
    Name: 'G999',
    Symbol: 'G999',
    CoinName: 'G999',
    FullName: 'G999 (G999)',
  },
  GREARN: {
    Name: 'GREARN',
    Symbol: 'GREARN',
    CoinName: 'GrEarn',
    FullName: 'GrEarn (GREARN)',
  },
  CLT: {
    Name: 'CLT',
    Symbol: 'CLT',
    CoinName: 'CoinLoan',
    FullName: 'CoinLoan (CLT)',
  },
  MNST: {
    Name: 'MNST',
    Symbol: 'MNST',
    CoinName: 'MoonStarter',
    FullName: 'MoonStarter (MNST)',
  },
  IQQ: {
    Name: 'IQQ',
    Symbol: 'IQQ',
    CoinName: 'Iqoniq',
    FullName: 'Iqoniq (IQQ)',
  },
  ACXT: {
    Name: 'ACXT',
    Symbol: 'ACXT',
    CoinName: 'ACDX Exchange Token',
    FullName: 'ACDX Exchange Token (ACXT)',
  },
  BST: {
    Name: 'BST',
    Symbol: 'BST',
    CoinName: 'Beshare Token',
    FullName: 'Beshare Token (BST)',
  },
  STAK: {
    Name: 'STAK',
    Symbol: 'STAK',
    CoinName: 'Jigstack',
    FullName: 'Jigstack (STAK)',
  },
  SLRS: {
    Name: 'SLRS',
    Symbol: 'SLRS',
    CoinName: 'Solrise Finance',
    FullName: 'Solrise Finance (SLRS)',
  },
  SGE: {
    Name: 'SGE',
    Symbol: 'SGE',
    CoinName: 'Society of Galactic Exploration',
    FullName: 'Society of Galactic Exploration (SGE)',
  },
  KELPIE: {
    Name: 'KELPIE',
    Symbol: 'KELPIE',
    CoinName: 'Kelpie Inu',
    FullName: 'Kelpie Inu (KELPIE)',
  },
  MINIDOGE: {
    Name: 'MINIDOGE',
    Symbol: 'MINIDOGE',
    CoinName: 'MiniDOGE',
    FullName: 'MiniDOGE (MINIDOGE)',
  },
  PONZU: {
    Name: 'PONZU',
    Symbol: 'PONZU',
    CoinName: 'Ponzu Inu',
    FullName: 'Ponzu Inu (PONZU)',
  },
  DRE: {
    Name: 'DRE',
    Symbol: 'DRE',
    CoinName: 'DoRen',
    FullName: 'DoRen (DRE)',
  },
  ANDROTTWEILER: {
    Name: 'ANDROTTWEILER',
    Symbol: 'ANDROTTWEILER',
    CoinName: 'Androttweiler Token',
    FullName: 'Androttweiler Token (ANDROTTWEILER)',
  },
  GTON: {
    Name: 'GTON',
    Symbol: 'GTON',
    CoinName: 'GTON Capital',
    FullName: 'GTON Capital (GTON)',
  },
  CFF: {
    Name: 'CFF',
    Symbol: 'CFF',
    CoinName: 'Coffe',
    FullName: 'Coffe (CFF)',
  },
  FEVR: {
    Name: 'FEVR',
    Symbol: 'FEVR',
    CoinName: 'RealFevr',
    FullName: 'RealFevr (FEVR)',
  },
  ZORT: {
    Name: 'ZORT',
    Symbol: 'ZORT',
    CoinName: 'Zort',
    FullName: 'Zort (ZORT)',
  },
  YLDY: {
    Name: 'YLDY',
    Symbol: 'YLDY',
    CoinName: 'Yieldly',
    FullName: 'Yieldly (YLDY)',
  },
  OFC: {
    Name: 'OFC',
    Symbol: 'OFC',
    CoinName: '$OFC Coin',
    FullName: '$OFC Coin (OFC)',
  },
  MOK: {
    Name: 'MOK',
    Symbol: 'MOK',
    CoinName: 'MocktailSwap',
    FullName: 'MocktailSwap (MOK)',
  },
  AXC: {
    Name: 'AXC',
    Symbol: 'AXC',
    CoinName: 'AXIA Coin',
    FullName: 'AXIA Coin (AXC)',
  },
  SHIBMERICAN: {
    Name: 'SHIBMERICAN',
    Symbol: 'SHIBMERICAN',
    CoinName: 'Shibmerican',
    FullName: 'Shibmerican (SHIBMERICAN)',
  },
  BABYELON: {
    Name: 'BABYELON',
    Symbol: 'BABYELON',
    CoinName: 'BabyElon',
    FullName: 'BabyElon (BABYELON)',
  },
  GREY: {
    Name: 'GREY',
    Symbol: 'GREY',
    CoinName: 'Grey Token',
    FullName: 'Grey Token (GREY)',
  },
  EFFT: {
    Name: 'EFFT',
    Symbol: 'EFFT',
    CoinName: 'Effort Economy ',
    FullName: 'Effort Economy  (EFFT)',
  },
  PROGE: {
    Name: 'PROGE',
    Symbol: 'PROGE',
    CoinName: 'Protector Roge',
    FullName: 'Protector Roge (PROGE)',
  },
  WPE: {
    Name: 'WPE',
    Symbol: 'WPE',
    CoinName: 'OPES (Wrapped PE)',
    FullName: 'OPES (Wrapped PE) (WPE)',
  },
  QNX: {
    Name: 'QNX',
    Symbol: 'QNX',
    CoinName: 'QueenDex Coin',
    FullName: 'QueenDex Coin (QNX)',
  },
  DAWGS: {
    Name: 'DAWGS',
    Symbol: 'DAWGS',
    CoinName: 'SpaceDawgs',
    FullName: 'SpaceDawgs (DAWGS)',
  },
  CFG: {
    Name: 'CFG',
    Symbol: 'CFG',
    CoinName: 'Centrifuge',
    FullName: 'Centrifuge (CFG)',
  },
  SIMPLE: {
    Name: 'SIMPLE',
    Symbol: 'SIMPLE',
    CoinName: 'SimpleChain',
    FullName: 'SimpleChain (SIMPLE)',
  },
  LIVE: {
    Name: 'LIVE',
    Symbol: 'LIVE',
    CoinName: 'TRONbetLive',
    FullName: 'TRONbetLive (LIVE)',
  },
  WCFG: {
    Name: 'WCFG',
    Symbol: 'WCFG',
    CoinName: 'Wrapped Centrifuge',
    FullName: 'Wrapped Centrifuge (WCFG)',
  },
  ARW: {
    Name: 'ARW',
    Symbol: 'ARW',
    CoinName: 'Arowana Token',
    FullName: 'Arowana Token (ARW)',
  },
  JEFF: {
    Name: 'JEFF',
    Symbol: 'JEFF',
    CoinName: 'Jeff in Space',
    FullName: 'Jeff in Space (JEFF)',
  },
  SHFL: {
    Name: 'SHFL',
    Symbol: 'SHFL',
    CoinName: 'SHUFFLE!',
    FullName: 'SHUFFLE! (SHFL)',
  },
  ARTEX: {
    Name: 'ARTEX',
    Symbol: 'ARTEX',
    CoinName: 'Artex',
    FullName: 'Artex (ARTEX)',
  },
  CHY: {
    Name: 'CHY',
    Symbol: 'CHY',
    CoinName: 'Concern Poverty Chain',
    FullName: 'Concern Poverty Chain (CHY)',
  },
  COLLG: {
    Name: 'COLLG',
    Symbol: 'COLLG',
    CoinName: 'Collateral Pay Governance',
    FullName: 'Collateral Pay Governance (COLLG)',
  },
  MINUTE: {
    Name: 'MINUTE',
    Symbol: 'MINUTE',
    CoinName: 'MINUTE Vault (NFTX)',
    FullName: 'MINUTE Vault (NFTX) (MINUTE)',
  },
  CLV: {
    Name: 'CLV',
    Symbol: 'CLV',
    CoinName: 'Clover Finance',
    FullName: 'Clover Finance (CLV)',
  },
  DINO: {
    Name: 'DINO',
    Symbol: 'DINO',
    CoinName: 'DinoSwap',
    FullName: 'DinoSwap (DINO)',
  },
  SBRT: {
    Name: 'SBRT',
    Symbol: 'SBRT',
    CoinName: 'SaveBritney',
    FullName: 'SaveBritney (SBRT)',
  },
  EXIP: {
    Name: 'EXIP',
    Symbol: 'EXIP',
    CoinName: 'EXIP',
    FullName: 'EXIP (EXIP)',
  },
  DIAMND: {
    Name: 'DIAMND',
    Symbol: 'DIAMND',
    CoinName: 'Projekt Diamond',
    FullName: 'Projekt Diamond (DIAMND)',
  },
  CVAG: {
    Name: 'CVAG',
    Symbol: 'CVAG',
    CoinName: 'Crypto Village Accelerator CVAG',
    FullName: 'Crypto Village Accelerator CVAG (CVAG)',
  },
  XWIN: {
    Name: 'XWIN',
    Symbol: 'XWIN',
    CoinName: 'xWIN Finance',
    FullName: 'xWIN Finance (XWIN)',
  },
  TENSHI: {
    Name: 'TENSHI',
    Symbol: 'TENSHI',
    CoinName: 'Tenshi',
    FullName: 'Tenshi (TENSHI)',
  },
  MCONTENT: {
    Name: 'MCONTENT',
    Symbol: 'MCONTENT',
    CoinName: 'MContent',
    FullName: 'MContent (MCONTENT)',
  },
  TOKAU: {
    Name: 'TOKAU',
    Symbol: 'TOKAU',
    CoinName: 'Tokyo AU',
    FullName: 'Tokyo AU (TOKAU)',
  },
  '1TRC': {
    Name: '1TRC',
    Symbol: '1TRC',
    CoinName: '1TRONIC',
    FullName: '1TRONIC (1TRC)',
  },
  ZOE: {
    Name: 'ZOE',
    Symbol: 'ZOE',
    CoinName: 'Zoe Cash',
    FullName: 'Zoe Cash (ZOE)',
  },
  WOLFY: {
    Name: 'WOLFY',
    Symbol: 'WOLFY',
    CoinName: 'WOLFY',
    FullName: 'WOLFY (WOLFY)',
  },
  KAR: {
    Name: 'KAR',
    Symbol: 'KAR',
    CoinName: 'Karura',
    FullName: 'Karura (KAR)',
  },
  RELOADED: {
    Name: 'RELOADED',
    Symbol: 'RELOADED',
    CoinName: 'Doge Reloaded',
    FullName: 'Doge Reloaded (RELOADED)',
  },
  CMERGE: {
    Name: 'CMERGE',
    Symbol: 'CMERGE',
    CoinName: 'CoinMerge',
    FullName: 'CoinMerge (CMERGE)',
  },
  XYZ: {
    Name: 'XYZ',
    Symbol: 'XYZ',
    CoinName: 'Universe.XYZ',
    FullName: 'Universe.XYZ (XYZ)',
  },
  GODL: {
    Name: 'GODL',
    Symbol: 'GODL',
    CoinName: 'GODL',
    FullName: 'GODL (GODL)',
  },
  BABYCUBAN: {
    Name: 'BABYCUBAN',
    Symbol: 'BABYCUBAN',
    CoinName: 'Baby Cuban',
    FullName: 'Baby Cuban (BABYCUBAN)',
  },
  NVX: {
    Name: 'NVX',
    Symbol: 'NVX',
    CoinName: 'Novax Coin',
    FullName: 'Novax Coin (NVX)',
  },
  TKG: {
    Name: 'TKG',
    Symbol: 'TKG',
    CoinName: 'Takamaka Green Coin',
    FullName: 'Takamaka Green Coin (TKG)',
  },
  HANU: {
    Name: 'HANU',
    Symbol: 'HANU',
    CoinName: 'Hanu Yokia',
    FullName: 'Hanu Yokia (HANU)',
  },
  KIRBY: {
    Name: 'KIRBY',
    Symbol: 'KIRBY',
    CoinName: 'Kirby Inu',
    FullName: 'Kirby Inu (KIRBY)',
  },
  TAIYO: {
    Name: 'TAIYO',
    Symbol: 'TAIYO',
    CoinName: 'Taiyo',
    FullName: 'Taiyo (TAIYO)',
  },
  SHON: {
    Name: 'SHON',
    Symbol: 'SHON',
    CoinName: 'ShonToken',
    FullName: 'ShonToken (SHON)',
  },
  INVESTEL: {
    Name: 'INVESTEL',
    Symbol: 'INVESTEL',
    CoinName: 'Investelly token',
    FullName: 'Investelly token (INVESTEL)',
  },
  CFLO: {
    Name: 'CFLO',
    Symbol: 'CFLO',
    CoinName: 'Chain Flowers',
    FullName: 'Chain Flowers (CFLO)',
  },
  C98: {
    Name: 'C98',
    Symbol: 'C98',
    CoinName: 'Coin98',
    FullName: 'Coin98 (C98)',
  },
  UCT: {
    Name: 'UCT',
    Symbol: 'UCT',
    CoinName: 'UnitedCrowd',
    FullName: 'UnitedCrowd (UCT)',
  },
  ANB: {
    Name: 'ANB',
    Symbol: 'ANB',
    CoinName: 'Angryb',
    FullName: 'Angryb (ANB)',
  },
  BSCGIRL: {
    Name: 'BSCGIRL',
    Symbol: 'BSCGIRL',
    CoinName: 'Binance Smart Chain Girl',
    FullName: 'Binance Smart Chain Girl (BSCGIRL)',
  },
  JPYC: {
    Name: 'JPYC',
    Symbol: 'JPYC',
    CoinName: 'JPYC',
    FullName: 'JPYC (JPYC)',
  },
  GSHIBA: {
    Name: 'GSHIBA',
    Symbol: 'GSHIBA',
    CoinName: 'Gambler Shiba',
    FullName: 'Gambler Shiba (GSHIBA)',
  },
  IBEUR: {
    Name: 'IBEUR',
    Symbol: 'IBEUR',
    CoinName: 'Iron Bank EURO',
    FullName: 'Iron Bank EURO (IBEUR)',
  },
  MFUND: {
    Name: 'MFUND',
    Symbol: 'MFUND',
    CoinName: 'Memefund',
    FullName: 'Memefund (MFUND)',
  },
  IPAD: {
    Name: 'IPAD',
    Symbol: 'IPAD',
    CoinName: 'Infinity Pad',
    FullName: 'Infinity Pad (IPAD)',
  },
  LKT: {
    Name: 'LKT',
    Symbol: 'LKT',
    CoinName: 'Locklet',
    FullName: 'Locklet (LKT)',
  },
  SPS: {
    Name: 'SPS',
    Symbol: 'SPS',
    CoinName: 'Splinterlands',
    FullName: 'Splinterlands (SPS)',
  },
  BURP: {
    Name: 'BURP',
    Symbol: 'BURP',
    CoinName: 'CoinBurp',
    FullName: 'CoinBurp (BURP)',
  },
  INVI: {
    Name: 'INVI',
    Symbol: 'INVI',
    CoinName: 'INVI Token',
    FullName: 'INVI Token (INVI)',
  },
  YINBI: {
    Name: 'YINBI',
    Symbol: 'YINBI',
    CoinName: 'Yinbi',
    FullName: 'Yinbi (YINBI)',
  },
  PCHS: {
    Name: 'PCHS',
    Symbol: 'PCHS',
    CoinName: 'Peaches.Finance',
    FullName: 'Peaches.Finance (PCHS)',
  },
  LCMS: {
    Name: 'LCMS',
    Symbol: 'LCMS',
    CoinName: 'LCMS',
    FullName: 'LCMS (LCMS)',
  },
  LMAO: {
    Name: 'LMAO',
    Symbol: 'LMAO',
    CoinName: 'LMAO Finance',
    FullName: 'LMAO Finance (LMAO)',
  },
  CASPER: {
    Name: 'CASPER',
    Symbol: 'CASPER',
    CoinName: 'Casper DeFi',
    FullName: 'Casper DeFi (CASPER)',
  },
  MBCC: {
    Name: 'MBCC',
    Symbol: 'MBCC',
    CoinName: 'Blockchain-Based Distributed Super Computing Platform',
    FullName: 'Blockchain-Based Distributed Super Computing Platform (MBCC)',
  },
  ALITA: {
    Name: 'ALITA',
    Symbol: 'ALITA',
    CoinName: 'Alita Network',
    FullName: 'Alita Network (ALITA)',
  },
  PYRAM: {
    Name: 'PYRAM',
    Symbol: 'PYRAM',
    CoinName: 'Pyram Token',
    FullName: 'Pyram Token (PYRAM)',
  },
  SPACE: {
    Name: 'SPACE',
    Symbol: 'SPACE',
    CoinName: 'Spacelens',
    FullName: 'Spacelens (SPACE)',
  },
  DAF: {
    Name: 'DAF',
    Symbol: 'DAF',
    CoinName: 'DaFIN',
    FullName: 'DaFIN (DAF)',
  },
  UFI: {
    Name: 'UFI',
    Symbol: 'UFI',
    CoinName: 'PureFi',
    FullName: 'PureFi (UFI)',
  },
  DADDYDOGE: {
    Name: 'DADDYDOGE',
    Symbol: 'DADDYDOGE',
    CoinName: 'Daddy Doge',
    FullName: 'Daddy Doge (DADDYDOGE)',
  },
  '1-UP': {
    Name: '1-UP',
    Symbol: '1-UP',
    CoinName: '1-UP',
    FullName: '1-UP (1-UP)',
  },
  F7: {
    Name: 'F7',
    Symbol: 'F7',
    CoinName: 'Five7',
    FullName: 'Five7 (F7)',
  },
  DBZ: {
    Name: 'DBZ',
    Symbol: 'DBZ',
    CoinName: 'Diamond Boyz Coin',
    FullName: 'Diamond Boyz Coin (DBZ)',
  },
  BANKETH: {
    Name: 'BANKETH',
    Symbol: 'BANKETH',
    CoinName: 'BankEth',
    FullName: 'BankEth (BANKETH)',
  },
  VICEX: {
    Name: 'VICEX',
    Symbol: 'VICEX',
    CoinName: 'ViceToken',
    FullName: 'ViceToken (VICEX)',
  },
  FIG: {
    Name: 'FIG',
    Symbol: 'FIG',
    CoinName: 'FlowCom',
    FullName: 'FlowCom (FIG)',
  },
  ZENIQ: {
    Name: 'ZENIQ',
    Symbol: 'ZENIQ',
    CoinName: 'Zeniq Coin',
    FullName: 'Zeniq Coin (ZENIQ)',
  },
  BORING: {
    Name: 'BORING',
    Symbol: 'BORING',
    CoinName: 'BoringDAO',
    FullName: 'BoringDAO (BORING)',
  },
  DERC: {
    Name: 'DERC',
    Symbol: 'DERC',
    CoinName: 'DeRace',
    FullName: 'DeRace (DERC)',
  },
  VAB: {
    Name: 'VAB',
    Symbol: 'VAB',
    CoinName: 'Vabble',
    FullName: 'Vabble (VAB)',
  },
  SHB4: {
    Name: 'SHB4',
    Symbol: 'SHB4',
    CoinName: 'Super Heavy Booster 4',
    FullName: 'Super Heavy Booster 4 (SHB4)',
  },
  PVU: {
    Name: 'PVU',
    Symbol: 'PVU',
    CoinName: 'Plant vs Undead Token',
    FullName: 'Plant vs Undead Token (PVU)',
  },
  EVAULT: {
    Name: 'EVAULT',
    Symbol: 'EVAULT',
    CoinName: 'EthereumVault',
    FullName: 'EthereumVault (EVAULT)',
  },
  EFI: {
    Name: 'EFI',
    Symbol: 'EFI',
    CoinName: 'Efinity',
    FullName: 'Efinity (EFI)',
  },
  COVIDTOKEN: {
    Name: 'COVIDTOKEN',
    Symbol: 'COVIDTOKEN',
    CoinName: 'Covid Token',
    FullName: 'Covid Token (COVIDTOKEN)',
  },
  BSATOSHI: {
    Name: 'BSATOSHI',
    Symbol: 'BSATOSHI',
    CoinName: 'BabySatoshi',
    FullName: 'BabySatoshi (BSATOSHI)',
  },
  LITH: {
    Name: 'LITH',
    Symbol: 'LITH',
    CoinName: 'Lithium Finance',
    FullName: 'Lithium Finance (LITH)',
  },
  XSUSHI: {
    Name: 'XSUSHI',
    Symbol: 'XSUSHI',
    CoinName: 'xSUSHI',
    FullName: 'xSUSHI (XSUSHI)',
  },
  CVXCRV: {
    Name: 'CVXCRV',
    Symbol: 'CVXCRV',
    CoinName: 'Convex CRV',
    FullName: 'Convex CRV (CVXCRV)',
  },
  MIM: {
    Name: 'MIM',
    Symbol: 'MIM',
    CoinName: 'Magic Internet Money',
    FullName: 'Magic Internet Money (MIM)',
  },
  FLOAT: {
    Name: 'FLOAT',
    Symbol: 'FLOAT',
    CoinName: 'Float Protocol',
    FullName: 'Float Protocol (FLOAT)',
  },
  TOKE: {
    Name: 'TOKE',
    Symbol: 'TOKE',
    CoinName: 'Tokemak',
    FullName: 'Tokemak (TOKE)',
  },
  AQUARI: {
    Name: 'AQUARI',
    Symbol: 'AQUARI',
    CoinName: 'Aquari',
    FullName: 'Aquari (AQUARI)',
  },
  ENERGYX: {
    Name: 'ENERGYX',
    Symbol: 'ENERGYX',
    CoinName: 'Safe Energy',
    FullName: 'Safe Energy (ENERGYX)',
  },
  MPL: {
    Name: 'MPL',
    Symbol: 'MPL',
    CoinName: 'Maple',
    FullName: 'Maple (MPL)',
  },
  HI: {
    Name: 'HI',
    Symbol: 'HI',
    CoinName: 'hi Dollar',
    FullName: 'hi Dollar (HI)',
  },
  PIPT: {
    Name: 'PIPT',
    Symbol: 'PIPT',
    CoinName: 'Power Index Pool Token',
    FullName: 'Power Index Pool Token (PIPT)',
  },
  WASABI: {
    Name: 'WASABI',
    Symbol: 'WASABI',
    CoinName: 'WasabiX',
    FullName: 'WasabiX (WASABI)',
  },
  RULER: {
    Name: 'RULER',
    Symbol: 'RULER',
    CoinName: 'Ruler Protocol',
    FullName: 'Ruler Protocol (RULER)',
  },
  $MAID: {
    Name: '$MAID',
    Symbol: '$MAID',
    CoinName: 'MaidCoin',
    FullName: 'MaidCoin ($MAID)',
  },
  IDLE: {
    Name: 'IDLE',
    Symbol: 'IDLE',
    CoinName: 'IDLE',
    FullName: 'IDLE (IDLE)',
  },
  BASK: {
    Name: 'BASK',
    Symbol: 'BASK',
    CoinName: 'BasketDAO',
    FullName: 'BasketDAO (BASK)',
  },
  BPT: {
    Name: 'BPT',
    Symbol: 'BPT',
    CoinName: 'BlackPool Token',
    FullName: 'BlackPool Token (BPT)',
  },
  GENIX: {
    Name: 'GENIX',
    Symbol: 'GENIX',
    CoinName: 'Genix',
    FullName: 'Genix (GENIX)',
  },
  ZCON: {
    Name: 'ZCON',
    Symbol: 'ZCON',
    CoinName: 'Zcon Protocol',
    FullName: 'Zcon Protocol (ZCON)',
  },
  BABYSAITAMA: {
    Name: 'BABYSAITAMA',
    Symbol: 'BABYSAITAMA',
    CoinName: 'Baby Saitama',
    FullName: 'Baby Saitama (BABYSAITAMA)',
  },
  ETHPAD: {
    Name: 'ETHPAD',
    Symbol: 'ETHPAD',
    CoinName: 'ETHPad',
    FullName: 'ETHPad (ETHPAD)',
  },
  LANC: {
    Name: 'LANC',
    Symbol: 'LANC',
    CoinName: 'Lanceria',
    FullName: 'Lanceria (LANC)',
  },
  EQX: {
    Name: 'EQX',
    Symbol: 'EQX',
    CoinName: 'EQIFi',
    FullName: 'EQIFi (EQX)',
  },
  MCF: {
    Name: 'MCF',
    Symbol: 'MCF',
    CoinName: 'MCFinance',
    FullName: 'MCFinance (MCF)',
  },
  SUNC: {
    Name: 'SUNC',
    Symbol: 'SUNC',
    CoinName: 'Sunrise',
    FullName: 'Sunrise (SUNC)',
  },
  PORT: {
    Name: 'PORT',
    Symbol: 'PORT',
    CoinName: 'Port Finance',
    FullName: 'Port Finance (PORT)',
  },
  BRIGHTU: {
    Name: 'BRIGHTU',
    Symbol: 'BRIGHTU',
    CoinName: 'Bright Union',
    FullName: 'Bright Union (BRIGHTU)',
  },
  KITSU: {
    Name: 'KITSU',
    Symbol: 'KITSU',
    CoinName: 'Kitsune Inu',
    FullName: 'Kitsune Inu (KITSU)',
  },
  CFL365: {
    Name: 'CFL365',
    Symbol: 'CFL365',
    CoinName: 'CFL365 Finance',
    FullName: 'CFL365 Finance (CFL365)',
  },
  BUNNY: {
    Name: 'BUNNY',
    Symbol: 'BUNNY',
    CoinName: 'Pancake Bunny',
    FullName: 'Pancake Bunny (BUNNY)',
  },
  A5T: {
    Name: 'A5T',
    Symbol: 'A5T',
    CoinName: 'Alpha5',
    FullName: 'Alpha5 (A5T)',
  },
  ETNY: {
    Name: 'ETNY',
    Symbol: 'ETNY',
    CoinName: 'Ethernity Cloud',
    FullName: 'Ethernity Cloud (ETNY)',
  },
  MSC: {
    Name: 'MSC',
    Symbol: 'MSC',
    CoinName: 'Miningwatchdog Smartchain',
    FullName: 'Miningwatchdog Smartchain (MSC)',
  },
  SWOP: {
    Name: 'SWOP',
    Symbol: 'SWOP',
    CoinName: 'Swop',
    FullName: 'Swop (SWOP)',
  },
  CIV: {
    Name: 'CIV',
    Symbol: 'CIV',
    CoinName: 'Civilization',
    FullName: 'Civilization (CIV)',
  },
  CYCLUB: {
    Name: 'CYCLUB',
    Symbol: 'CYCLUB',
    CoinName: 'Cyclub',
    FullName: 'Cyclub (CYCLUB)',
  },
  AWO: {
    Name: 'AWO',
    Symbol: 'AWO',
    CoinName: 'AiWork',
    FullName: 'AiWork (AWO)',
  },
  CBANK: {
    Name: 'CBANK',
    Symbol: 'CBANK',
    CoinName: 'Crypto Bank',
    FullName: 'Crypto Bank (CBANK)',
  },
  FDO: {
    Name: 'FDO',
    Symbol: 'FDO',
    CoinName: 'Firdaos',
    FullName: 'Firdaos (FDO)',
  },
  SUM: {
    Name: 'SUM',
    Symbol: 'SUM',
    CoinName: 'SumSwap',
    FullName: 'SumSwap (SUM)',
  },
  MMUI: {
    Name: 'MMUI',
    Symbol: 'MMUI',
    CoinName: 'MetaMUI',
    FullName: 'MetaMUI (MMUI)',
  },
  BOOST: {
    Name: 'BOOST',
    Symbol: 'BOOST',
    CoinName: 'Boost',
    FullName: 'Boost (BOOST)',
  },
  DID: {
    Name: 'DID',
    Symbol: 'DID',
    CoinName: 'Didcoin',
    FullName: 'Didcoin (DID)',
  },
  PPN: {
    Name: 'PPN',
    Symbol: 'PPN',
    CoinName: 'Puppies Network',
    FullName: 'Puppies Network (PPN)',
  },
  SKT: {
    Name: 'SKT',
    Symbol: 'SKT',
    CoinName: 'Sukhavati Network',
    FullName: 'Sukhavati Network (SKT)',
  },
  RFT: {
    Name: 'RFT',
    Symbol: 'RFT',
    CoinName: 'Rangers Fan Token',
    FullName: 'Rangers Fan Token (RFT)',
  },
  LTRBT: {
    Name: 'LTRBT',
    Symbol: 'LTRBT',
    CoinName: 'Little Rabbit',
    FullName: 'Little Rabbit (LTRBT)',
  },
  RYOSHI: {
    Name: 'RYOSHI',
    Symbol: 'RYOSHI',
    CoinName: 'Ryoshis Vision',
    FullName: 'Ryoshis Vision (RYOSHI)',
  },
  WNCG: {
    Name: 'WNCG',
    Symbol: 'WNCG',
    CoinName: 'Wrapped NCG',
    FullName: 'Wrapped NCG (WNCG)',
  },
  ASG: {
    Name: 'ASG',
    Symbol: 'ASG',
    CoinName: 'Asgard',
    FullName: 'Asgard (ASG)',
  },
  SPHRI: {
    Name: 'SPHRI',
    Symbol: 'SPHRI',
    CoinName: 'Spherium',
    FullName: 'Spherium (SPHRI)',
  },
  NGL: {
    Name: 'NGL',
    Symbol: 'NGL',
    CoinName: 'Gold Fever',
    FullName: 'Gold Fever (NGL)',
  },
  YAY: {
    Name: 'YAY',
    Symbol: 'YAY',
    CoinName: 'YAY Games',
    FullName: 'YAY Games (YAY)',
  },
  WLUNA: {
    Name: 'WLUNA',
    Symbol: 'WLUNA',
    CoinName: 'Wrapped LUNA Token',
    FullName: 'Wrapped LUNA Token (WLUNA)',
  },
  $TREAM: {
    Name: '$TREAM',
    Symbol: '$TREAM',
    CoinName: 'World Stream Finance',
    FullName: 'World Stream Finance ($TREAM)',
  },
  SKU: {
    Name: 'SKU',
    Symbol: 'SKU',
    CoinName: 'Sakura',
    FullName: 'Sakura (SKU)',
  },
  CW: {
    Name: 'CW',
    Symbol: 'CW',
    CoinName: 'CardWallet',
    FullName: 'CardWallet (CW)',
  },
  AGPC: {
    Name: 'AGPC',
    Symbol: 'AGPC',
    CoinName: 'AGPC',
    FullName: 'AGPC (AGPC)',
  },
  FB: {
    Name: 'FB',
    Symbol: 'FB',
    CoinName: 'Fenerbahçe Token',
    FullName: 'Fenerbahçe Token (FB)',
  },
  DNXC: {
    Name: 'DNXC',
    Symbol: 'DNXC',
    CoinName: 'DinoX',
    FullName: 'DinoX (DNXC)',
  },
  CIRUS: {
    Name: 'CIRUS',
    Symbol: 'CIRUS',
    CoinName: 'Cirus',
    FullName: 'Cirus (CIRUS)',
  },
  TXA: {
    Name: 'TXA',
    Symbol: 'TXA',
    CoinName: 'TXA',
    FullName: 'TXA (TXA)',
  },
  DBUY: {
    Name: 'DBUY',
    Symbol: 'DBUY',
    CoinName: 'Doont Buy',
    FullName: 'Doont Buy (DBUY)',
  },
  CSWAP: {
    Name: 'CSWAP',
    Symbol: 'CSWAP',
    CoinName: 'CardSwap',
    FullName: 'CardSwap (CSWAP)',
  },
  BORED: {
    Name: 'BORED',
    Symbol: 'BORED',
    CoinName: 'Bored Museum',
    FullName: 'Bored Museum (BORED)',
  },
  XRD: {
    Name: 'XRD',
    Symbol: 'XRD',
    CoinName: 'Radix',
    FullName: 'Radix (XRD)',
  },
  MGAMES: {
    Name: 'MGAMES',
    Symbol: 'MGAMES',
    CoinName: 'Meme Games',
    FullName: 'Meme Games (MGAMES)',
  },
  RELAY: {
    Name: 'RELAY',
    Symbol: 'RELAY',
    CoinName: 'Relay Token',
    FullName: 'Relay Token (RELAY)',
  },
  BORUTO: {
    Name: 'BORUTO',
    Symbol: 'BORUTO',
    CoinName: 'Boruto Inu',
    FullName: 'Boruto Inu (BORUTO)',
  },
  TSA: {
    Name: 'TSA',
    Symbol: 'TSA',
    CoinName: 'Teaswap Art',
    FullName: 'Teaswap Art (TSA)',
  },
  KALYCOIN: {
    Name: 'KALYCOIN',
    Symbol: 'KALYCOIN',
    CoinName: 'KalyCoin',
    FullName: 'KalyCoin (KALYCOIN)',
  },
  BRNK: {
    Name: 'BRNK',
    Symbol: 'BRNK',
    CoinName: 'Brank',
    FullName: 'Brank (BRNK)',
  },
  PSLIP: {
    Name: 'PSLIP',
    Symbol: 'PSLIP',
    CoinName: 'Pinkslip Finance',
    FullName: 'Pinkslip Finance (PSLIP)',
  },
  KAIKEN: {
    Name: 'KAIKEN',
    Symbol: 'KAIKEN',
    CoinName: 'Kaiken Shiba',
    FullName: 'Kaiken Shiba (KAIKEN)',
  },
  MINTYS: {
    Name: 'MINTYS',
    Symbol: 'MINTYS',
    CoinName: 'MintySwap',
    FullName: 'MintySwap (MINTYS)',
  },
  FLRS: {
    Name: 'FLRS',
    Symbol: 'FLRS',
    CoinName: 'Flourish Coin',
    FullName: 'Flourish Coin (FLRS)',
  },
  MIA: {
    Name: 'MIA',
    Symbol: 'MIA',
    CoinName: 'MiamiCoin',
    FullName: 'MiamiCoin (MIA)',
  },
  FRTS: {
    Name: 'FRTS',
    Symbol: 'FRTS',
    CoinName: 'Fruits',
    FullName: 'Fruits (FRTS)',
  },
  TXL: {
    Name: 'TXL',
    Symbol: 'TXL',
    CoinName: 'Tixl',
    FullName: 'Tixl (TXL)',
  },
  KUMA: {
    Name: 'KUMA',
    Symbol: 'KUMA',
    CoinName: 'Kuma Inu',
    FullName: 'Kuma Inu (KUMA)',
  },
  DPET: {
    Name: 'DPET',
    Symbol: 'DPET',
    CoinName: 'My DeFi Pet',
    FullName: 'My DeFi Pet (DPET)',
  },
  BP: {
    Name: 'BP',
    Symbol: 'BP',
    CoinName: 'BunnyPark',
    FullName: 'BunnyPark (BP)',
  },
  STOS: {
    Name: 'STOS',
    Symbol: 'STOS',
    CoinName: 'Stratos',
    FullName: 'Stratos (STOS)',
  },
  RTM: {
    Name: 'RTM',
    Symbol: 'RTM',
    CoinName: 'Raptoreum',
    FullName: 'Raptoreum (RTM)',
  },
  ALUSD: {
    Name: 'ALUSD',
    Symbol: 'ALUSD',
    CoinName: 'Alchemix USD',
    FullName: 'Alchemix USD (ALUSD)',
  },
  MOVR: {
    Name: 'MOVR',
    Symbol: 'MOVR',
    CoinName: 'Moonriver',
    FullName: 'Moonriver (MOVR)',
  },
  CYCE: {
    Name: 'CYCE',
    Symbol: 'CYCE',
    CoinName: 'Crypto Carbon Energy',
    FullName: 'Crypto Carbon Energy (CYCE)',
  },
  'BFT.BITCI': {
    Name: 'BFT.BITCI',
    Symbol: 'BFT.BITCI',
    CoinName: 'Brazil Fan Token',
    FullName: 'Brazil Fan Token (BFT.BITCI)',
  },
  'SNFT.BITCI': {
    Name: 'SNFT.BITCI',
    Symbol: 'SNFT.BITCI',
    CoinName: 'Spanish National Team Fan Token',
    FullName: 'Spanish National Team Fan Token (SNFT.BITCI)',
  },
  'ANKA.BITCI': {
    Name: 'ANKA.BITCI',
    Symbol: 'ANKA.BITCI',
    CoinName: 'Ankaragücü Fan Token',
    FullName: 'Ankaragücü Fan Token (ANKA.BITCI)',
  },
  'DNZ.BITCI': {
    Name: 'DNZ.BITCI',
    Symbol: 'DNZ.BITCI',
    CoinName: 'Denizlispor Fan Token',
    FullName: 'Denizlispor Fan Token (DNZ.BITCI)',
  },
  'ESES.BITCI': {
    Name: 'ESES.BITCI',
    Symbol: 'ESES.BITCI',
    CoinName: 'Eskişehir Fan Tokens',
    FullName: 'Eskişehir Fan Tokens (ESES.BITCI)',
  },
  'KSK.BITCI': {
    Name: 'KSK.BITCI',
    Symbol: 'KSK.BITCI',
    CoinName: 'Karsiyaka Taraftar Token',
    FullName: 'Karsiyaka Taraftar Token (KSK.BITCI)',
  },
  'KSTT.BITCI': {
    Name: 'KSTT.BITCI',
    Symbol: 'KSTT.BITCI',
    CoinName: 'Kocaelispor Fan Token',
    FullName: 'Kocaelispor Fan Token (KSTT.BITCI)',
  },
  EDEN: {
    Name: 'EDEN',
    Symbol: 'EDEN',
    CoinName: 'EDEN',
    FullName: 'EDEN (EDEN)',
  },
  'NR1.BITCI': {
    Name: 'NR1.BITCI',
    Symbol: 'NR1.BITCI',
    CoinName: 'Number 1 Token',
    FullName: 'Number 1 Token (NR1.BITCI)',
  },
  SDN: {
    Name: 'SDN',
    Symbol: 'SDN',
    CoinName: 'Shiden Network',
    FullName: 'Shiden Network (SDN)',
  },
  FARA: {
    Name: 'FARA',
    Symbol: 'FARA',
    CoinName: 'FaraLand',
    FullName: 'FaraLand (FARA)',
  },
  NIFTSY: {
    Name: 'NIFTSY',
    Symbol: 'NIFTSY',
    CoinName: 'Envelop',
    FullName: 'Envelop (NIFTSY)',
  },
  DDX: {
    Name: 'DDX',
    Symbol: 'DDX',
    CoinName: 'DerivaDAO',
    FullName: 'DerivaDAO (DDX)',
  },
  SAFESTAR: {
    Name: 'SAFESTAR',
    Symbol: 'SAFESTAR',
    CoinName: 'Safe Star',
    FullName: 'Safe Star (SAFESTAR)',
  },
  AGLD: {
    Name: 'AGLD',
    Symbol: 'AGLD',
    CoinName: 'Adventure Gold',
    FullName: 'Adventure Gold (AGLD)',
  },
  BGLD: {
    Name: 'BGLD',
    Symbol: 'BGLD',
    CoinName: 'Based Gold',
    FullName: 'Based Gold (BGLD)',
  },
  PAPER: {
    Name: 'PAPER',
    Symbol: 'PAPER',
    CoinName: 'Dope Wars Paper',
    FullName: 'Dope Wars Paper (PAPER)',
  },
  TALK: {
    Name: 'TALK',
    Symbol: 'TALK',
    CoinName: 'Talken',
    FullName: 'Talken (TALK)',
  },
  BARK: {
    Name: 'BARK',
    Symbol: 'BARK',
    CoinName: 'Bored Ark',
    FullName: 'Bored Ark (BARK)',
  },
  FLURRY: {
    Name: 'FLURRY',
    Symbol: 'FLURRY',
    CoinName: 'Flurry Finance',
    FullName: 'Flurry Finance (FLURRY)',
  },
  FIFTY: {
    Name: 'FIFTY',
    Symbol: 'FIFTY',
    CoinName: 'FIFTYONEFIFTY',
    FullName: 'FIFTYONEFIFTY (FIFTY)',
  },
  PETN: {
    Name: 'PETN',
    Symbol: 'PETN',
    CoinName: 'Pylon Eco Token',
    FullName: 'Pylon Eco Token (PETN)',
  },
  LSP: {
    Name: 'LSP',
    Symbol: 'LSP',
    CoinName: 'Lumenswap',
    FullName: 'Lumenswap (LSP)',
  },
  POOL: {
    Name: 'POOL',
    Symbol: 'POOL',
    CoinName: 'PoolTogether',
    FullName: 'PoolTogether (POOL)',
  },
  VIDYX: {
    Name: 'VIDYX',
    Symbol: 'VIDYX',
    CoinName: 'VidyX',
    FullName: 'VidyX (VIDYX)',
  },
  HERO: {
    Name: 'HERO',
    Symbol: 'HERO',
    CoinName: 'Metahero',
    FullName: 'Metahero (HERO)',
  },
  SHARE: {
    Name: 'SHARE',
    Symbol: 'SHARE',
    CoinName: 'Seigniorage Shares',
    FullName: 'Seigniorage Shares (SHARE)',
  },
  ONC: {
    Name: 'ONC',
    Symbol: 'ONC',
    CoinName: 'One Cash',
    FullName: 'One Cash (ONC)',
  },
  PIG: {
    Name: 'PIG',
    Symbol: 'PIG',
    CoinName: 'Pig Finance',
    FullName: 'Pig Finance (PIG)',
  },
  SNY: {
    Name: 'SNY',
    Symbol: 'SNY',
    CoinName: 'Synthetify ',
    FullName: 'Synthetify  (SNY)',
  },
  TORG: {
    Name: 'TORG',
    Symbol: 'TORG',
    CoinName: 'TORG',
    FullName: 'TORG (TORG)',
  },
  ONEX: {
    Name: 'ONEX',
    Symbol: 'ONEX',
    CoinName: 'ONE TECH',
    FullName: 'ONE TECH (ONEX)',
  },
  BTRST: {
    Name: 'BTRST',
    Symbol: 'BTRST',
    CoinName: 'Braintrust',
    FullName: 'Braintrust (BTRST)',
  },
  ATLAS: {
    Name: 'ATLAS',
    Symbol: 'ATLAS',
    CoinName: 'Star Atlas',
    FullName: 'Star Atlas (ATLAS)',
  },
  ATTR: {
    Name: 'ATTR',
    Symbol: 'ATTR',
    CoinName: 'Attrace',
    FullName: 'Attrace (ATTR)',
  },
  YAXIS: {
    Name: 'YAXIS',
    Symbol: 'YAXIS',
    CoinName: 'yAxis',
    FullName: 'yAxis (YAXIS)',
  },
  PBX: {
    Name: 'PBX',
    Symbol: 'PBX',
    CoinName: 'Paribus',
    FullName: 'Paribus (PBX)',
  },
  XNL: {
    Name: 'XNL',
    Symbol: 'XNL',
    CoinName: 'Chronicle',
    FullName: 'Chronicle (XNL)',
  },
  LARIX: {
    Name: 'LARIX',
    Symbol: 'LARIX',
    CoinName: 'Larix',
    FullName: 'Larix (LARIX)',
  },
  PLGR: {
    Name: 'PLGR',
    Symbol: 'PLGR',
    CoinName: 'Pledge Finance',
    FullName: 'Pledge Finance (PLGR)',
  },
  STF: {
    Name: 'STF',
    Symbol: 'STF',
    CoinName: 'Structure Finance',
    FullName: 'Structure Finance (STF)',
  },
  DOGGY: {
    Name: 'DOGGY',
    Symbol: 'DOGGY',
    CoinName: 'Doggy',
    FullName: 'Doggy (DOGGY)',
  },
  GRAVITYF: {
    Name: 'GRAVITYF',
    Symbol: 'GRAVITYF',
    CoinName: 'Gravity Finance',
    FullName: 'Gravity Finance (GRAVITYF)',
  },
  XIASI: {
    Name: 'XIASI',
    Symbol: 'XIASI',
    CoinName: 'Xiasi Inu',
    FullName: 'Xiasi Inu (XIASI)',
  },
  KEANU: {
    Name: 'KEANU',
    Symbol: 'KEANU',
    CoinName: 'Keanu Inu',
    FullName: 'Keanu Inu (KEANU)',
  },
  NAFT: {
    Name: 'NAFT',
    Symbol: 'NAFT',
    CoinName: 'Nafter',
    FullName: 'Nafter (NAFT)',
  },
  SKILL: {
    Name: 'SKILL',
    Symbol: 'SKILL',
    CoinName: 'CryptoBlades',
    FullName: 'CryptoBlades (SKILL)',
  },
  ZOON: {
    Name: 'ZOON',
    Symbol: 'ZOON',
    CoinName: 'CryptoZoon',
    FullName: 'CryptoZoon (ZOON)',
  },
  BINEM: {
    Name: 'BINEM',
    Symbol: 'BINEM',
    CoinName: 'Binemon',
    FullName: 'Binemon (BINEM)',
  },
  HOD: {
    Name: 'HOD',
    Symbol: 'HOD',
    CoinName: 'HoDooi.com',
    FullName: 'HoDooi.com (HOD)',
  },
  SMG: {
    Name: 'SMG',
    Symbol: 'SMG',
    CoinName: 'Smaugs NFT',
    FullName: 'Smaugs NFT (SMG)',
  },
  WSG: {
    Name: 'WSG',
    Symbol: 'WSG',
    CoinName: 'Wall Street Games',
    FullName: 'Wall Street Games (WSG)',
  },
  HNST: {
    Name: 'HNST',
    Symbol: 'HNST',
    CoinName: 'Honest',
    FullName: 'Honest (HNST)',
  },
  PWAR: {
    Name: 'PWAR',
    Symbol: 'PWAR',
    CoinName: 'PolkaWar',
    FullName: 'PolkaWar (PWAR)',
  },
  TSX: {
    Name: 'TSX',
    Symbol: 'TSX',
    CoinName: 'TradeStars',
    FullName: 'TradeStars (TSX)',
  },
  KCCPAD: {
    Name: 'KCCPAD',
    Symbol: 'KCCPAD',
    CoinName: 'KCCPad',
    FullName: 'KCCPad (KCCPAD)',
  },
  TPAD: {
    Name: 'TPAD',
    Symbol: 'TPAD',
    CoinName: 'TrustPad',
    FullName: 'TrustPad (TPAD)',
  },
  SISHI: {
    Name: 'SISHI',
    Symbol: 'SISHI',
    CoinName: 'Sishi Finance',
    FullName: 'Sishi Finance (SISHI)',
  },
  FEED: {
    Name: 'FEED',
    Symbol: 'FEED',
    CoinName: 'Feeder Finance',
    FullName: 'Feeder Finance (FEED)',
  },
  PET: {
    Name: 'PET',
    Symbol: 'PET',
    CoinName: 'Battle Pets',
    FullName: 'Battle Pets (PET)',
  },
  COOK: {
    Name: 'COOK',
    Symbol: 'COOK',
    CoinName: 'Cook',
    FullName: 'Cook (COOK)',
  },
  PACOCA: {
    Name: 'PACOCA',
    Symbol: 'PACOCA',
    CoinName: 'Pacoca',
    FullName: 'Pacoca (PACOCA)',
  },
  HZN: {
    Name: 'HZN',
    Symbol: 'HZN',
    CoinName: 'Horizon Protocol',
    FullName: 'Horizon Protocol (HZN)',
  },
  HUNNY: {
    Name: 'HUNNY',
    Symbol: 'HUNNY',
    CoinName: 'Pancake Hunny',
    FullName: 'Pancake Hunny (HUNNY)',
  },
  RABBIT: {
    Name: 'RABBIT',
    Symbol: 'RABBIT',
    CoinName: 'Rabbit Finance',
    FullName: 'Rabbit Finance (RABBIT)',
  },
  ALLEY: {
    Name: 'ALLEY',
    Symbol: 'ALLEY',
    CoinName: 'NFT Alley',
    FullName: 'NFT Alley (ALLEY)',
  },
  TENFI: {
    Name: 'TENFI',
    Symbol: 'TENFI',
    CoinName: 'TEN',
    FullName: 'TEN (TENFI)',
  },
  HEROES: {
    Name: 'HEROES',
    Symbol: 'HEROES',
    CoinName: 'Dehero Community Token',
    FullName: 'Dehero Community Token (HEROES)',
  },
  UNCL: {
    Name: 'UNCL',
    Symbol: 'UNCL',
    CoinName: 'UNCL',
    FullName: 'UNCL (UNCL)',
  },
  WHEEL: {
    Name: 'WHEEL',
    Symbol: 'WHEEL',
    CoinName: 'Wheelers',
    FullName: 'Wheelers (WHEEL)',
  },
  SHIBACASH: {
    Name: 'SHIBACASH',
    Symbol: 'SHIBACASH',
    CoinName: 'ShibaCash',
    FullName: 'ShibaCash (SHIBACASH)',
  },
  LONG: {
    Name: 'LONG',
    Symbol: 'LONG',
    CoinName: 'Longdrink Finance',
    FullName: 'Longdrink Finance (LONG)',
  },
  QBIT: {
    Name: 'QBIT',
    Symbol: 'QBIT',
    CoinName: 'Project Quantum',
    FullName: 'Project Quantum (QBIT)',
  },
  EGGP: {
    Name: 'EGGP',
    Symbol: 'EGGP',
    CoinName: 'Eggplant Finance',
    FullName: 'Eggplant Finance (EGGP)',
  },
  EIFI: {
    Name: 'EIFI',
    Symbol: 'EIFI',
    CoinName: 'EIFI FINANCE',
    FullName: 'EIFI FINANCE (EIFI)',
  },
  TENDIE: {
    Name: 'TENDIE',
    Symbol: 'TENDIE',
    CoinName: 'TendieSwap',
    FullName: 'TendieSwap (TENDIE)',
  },
  COSMIC: {
    Name: 'COSMIC',
    Symbol: 'COSMIC',
    CoinName: 'CosmicSwap',
    FullName: 'CosmicSwap (COSMIC)',
  },
  VTX: {
    Name: 'VTX',
    Symbol: 'VTX',
    CoinName: 'Vortex DeFi',
    FullName: 'Vortex DeFi (VTX)',
  },
  OVO: {
    Name: 'OVO',
    Symbol: 'OVO',
    CoinName: 'Ovato',
    FullName: 'Ovato (OVO)',
  },
  VENTION: {
    Name: 'VENTION',
    Symbol: 'VENTION',
    CoinName: 'Vention',
    FullName: 'Vention (VENTION)',
  },
  BBADGER: {
    Name: 'BBADGER',
    Symbol: 'BBADGER',
    CoinName: 'Badger Sett Badger',
    FullName: 'Badger Sett Badger (BBADGER)',
  },
  WOW: {
    Name: 'WOW',
    Symbol: 'WOW',
    CoinName: 'WOWswap',
    FullName: 'WOWswap (WOW)',
  },
  DCB: {
    Name: 'DCB',
    Symbol: 'DCB',
    CoinName: 'Decubate',
    FullName: 'Decubate (DCB)',
  },
  BEAST: {
    Name: 'BEAST',
    Symbol: 'BEAST',
    CoinName: 'CryptoBeast',
    FullName: 'CryptoBeast (BEAST)',
  },
  BETU: {
    Name: 'BETU',
    Symbol: 'BETU',
    CoinName: 'Betu',
    FullName: 'Betu (BETU)',
  },
  IBG: {
    Name: 'IBG',
    Symbol: 'IBG',
    CoinName: 'iBG Token',
    FullName: 'iBG Token (IBG)',
  },
  WENLAMBO: {
    Name: 'WENLAMBO',
    Symbol: 'WENLAMBO',
    CoinName: 'Wenlambo',
    FullName: 'Wenlambo (WENLAMBO)',
  },
  RICE: {
    Name: 'RICE',
    Symbol: 'RICE',
    CoinName: 'RiceFarm',
    FullName: 'RiceFarm (RICE)',
  },
  SHEESH: {
    Name: 'SHEESH',
    Symbol: 'SHEESH',
    CoinName: 'Sheesh it is bussin bussin',
    FullName: 'Sheesh it is bussin bussin (SHEESH)',
  },
  RELI: {
    Name: 'RELI',
    Symbol: 'RELI',
    CoinName: 'Relite Finance',
    FullName: 'Relite Finance (RELI)',
  },
  PETG: {
    Name: 'PETG',
    Symbol: 'PETG',
    CoinName: 'Pet Games',
    FullName: 'Pet Games (PETG)',
  },
  CHECOIN: {
    Name: 'CHECOIN',
    Symbol: 'CHECOIN',
    CoinName: 'CheCoin',
    FullName: 'CheCoin (CHECOIN)',
  },
  NSFW: {
    Name: 'NSFW',
    Symbol: 'NSFW',
    CoinName: 'xxxNifty',
    FullName: 'xxxNifty (NSFW)',
  },
  ECOFI: {
    Name: 'ECOFI',
    Symbol: 'ECOFI',
    CoinName: 'EcoFi',
    FullName: 'EcoFi (ECOFI)',
  },
  INCAKE: {
    Name: 'INCAKE',
    Symbol: 'INCAKE',
    CoinName: 'InfinityCAKE',
    FullName: 'InfinityCAKE (INCAKE)',
  },
  MRF: {
    Name: 'MRF',
    Symbol: 'MRF',
    CoinName: 'Moonradar.finance',
    FullName: 'Moonradar.finance (MRF)',
  },
  GOMT: {
    Name: 'GOMT',
    Symbol: 'GOMT',
    CoinName: 'GoMeat',
    FullName: 'GoMeat (GOMT)',
  },
  GEMG: {
    Name: 'GEMG',
    Symbol: 'GEMG',
    CoinName: 'GemGuardian',
    FullName: 'GemGuardian (GEMG)',
  },
  KLO: {
    Name: 'KLO',
    Symbol: 'KLO',
    CoinName: 'Kalao',
    FullName: 'Kalao (KLO)',
  },
  FOREVERFOMO: {
    Name: 'FOREVERFOMO',
    Symbol: 'FOREVERFOMO',
    CoinName: 'ForeverFOMO',
    FullName: 'ForeverFOMO (FOREVERFOMO)',
  },
  RVST: {
    Name: 'RVST',
    Symbol: 'RVST',
    CoinName: 'Revest Finance',
    FullName: 'Revest Finance (RVST)',
  },
  DOGEGF: {
    Name: 'DOGEGF',
    Symbol: 'DOGEGF',
    CoinName: 'DogeGF',
    FullName: 'DogeGF (DOGEGF)',
  },
  XBN: {
    Name: 'XBN',
    Symbol: 'XBN',
    CoinName: 'Elastic BNB',
    FullName: 'Elastic BNB (XBN)',
  },
  CXPAD: {
    Name: 'CXPAD',
    Symbol: 'CXPAD',
    CoinName: 'CoinxPad',
    FullName: 'CoinxPad (CXPAD)',
  },
  POODL: {
    Name: 'POODL',
    Symbol: 'POODL',
    CoinName: 'Poodl',
    FullName: 'Poodl (POODL)',
  },
  KWS: {
    Name: 'KWS',
    Symbol: 'KWS',
    CoinName: 'Knight War Spirits',
    FullName: 'Knight War Spirits (KWS)',
  },
  KRN: {
    Name: 'KRN',
    Symbol: 'KRN',
    CoinName: 'KRYZA Network',
    FullName: 'KRYZA Network (KRN)',
  },
  RMRK: {
    Name: 'RMRK',
    Symbol: 'RMRK',
    CoinName: 'RMRK.app',
    FullName: 'RMRK.app (RMRK)',
  },
  KOL: {
    Name: 'KOL',
    Symbol: 'KOL',
    CoinName: 'Kollect',
    FullName: 'Kollect (KOL)',
  },
  BLADE: {
    Name: 'BLADE',
    Symbol: 'BLADE',
    CoinName: 'BladeWarrior',
    FullName: 'BladeWarrior (BLADE)',
  },
  DIVER: {
    Name: 'DIVER',
    Symbol: 'DIVER',
    CoinName: 'Divergence Protocol',
    FullName: 'Divergence Protocol (DIVER)',
  },
  PFY: {
    Name: 'PFY',
    Symbol: 'PFY',
    CoinName: 'Portify',
    FullName: 'Portify (PFY)',
  },
  CHOPPER: {
    Name: 'CHOPPER',
    Symbol: 'CHOPPER',
    CoinName: 'Chopper Inu',
    FullName: 'Chopper Inu (CHOPPER)',
  },
  DOGEX: {
    Name: 'DOGEX',
    Symbol: 'DOGEX',
    CoinName: 'DogeHouse Capital',
    FullName: 'DogeHouse Capital (DOGEX)',
  },
  RICKMORTY: {
    Name: 'RICKMORTY',
    Symbol: 'RICKMORTY',
    CoinName: 'Rick And Morty',
    FullName: 'Rick And Morty (RICKMORTY)',
  },
  UZUMAKI: {
    Name: 'UZUMAKI',
    Symbol: 'UZUMAKI',
    CoinName: 'Uzumaki Inu',
    FullName: 'Uzumaki Inu (UZUMAKI)',
  },
  FATCAKE: {
    Name: 'FATCAKE',
    Symbol: 'FATCAKE',
    CoinName: 'FatCake',
    FullName: 'FatCake (FATCAKE)',
  },
  WHEAT: {
    Name: 'WHEAT',
    Symbol: 'WHEAT',
    CoinName: 'Wheat Token',
    FullName: 'Wheat Token (WHEAT)',
  },
  YDR: {
    Name: 'YDR',
    Symbol: 'YDR',
    CoinName: 'YDragon',
    FullName: 'YDragon (YDR)',
  },
  ARATA: {
    Name: 'ARATA',
    Symbol: 'ARATA',
    CoinName: 'Arata',
    FullName: 'Arata (ARATA)',
  },
  BHO: {
    Name: 'BHO',
    Symbol: 'BHO',
    CoinName: 'Bholdus Token',
    FullName: 'Bholdus Token (BHO)',
  },
  JED: {
    Name: 'JED',
    Symbol: 'JED',
    CoinName: 'JEDSTAR',
    FullName: 'JEDSTAR (JED)',
  },
  SPHYNX: {
    Name: 'SPHYNX',
    Symbol: 'SPHYNX',
    CoinName: 'Sphynx Token',
    FullName: 'Sphynx Token (SPHYNX)',
  },
  BKR: {
    Name: 'BKR',
    Symbol: 'BKR',
    CoinName: 'Balkari Token',
    FullName: 'Balkari Token (BKR)',
  },
  ZMBE: {
    Name: 'ZMBE',
    Symbol: 'ZMBE',
    CoinName: 'RugZombie',
    FullName: 'RugZombie (ZMBE)',
  },
  LPI: {
    Name: 'LPI',
    Symbol: 'LPI',
    CoinName: 'LPI DAO',
    FullName: 'LPI DAO (LPI)',
  },
  RAINBOW: {
    Name: 'RAINBOW',
    Symbol: 'RAINBOW',
    CoinName: 'Rainbow Token',
    FullName: 'Rainbow Token (RAINBOW)',
  },
  STEPH: {
    Name: 'STEPH',
    Symbol: 'STEPH',
    CoinName: 'Step Hero',
    FullName: 'Step Hero (STEPH)',
  },
  BETA: {
    Name: 'BETA',
    Symbol: 'BETA',
    CoinName: 'Beta Finance',
    FullName: 'Beta Finance (BETA)',
  },
  SRP: {
    Name: 'SRP',
    Symbol: 'SRP',
    CoinName: 'Starpunk',
    FullName: 'Starpunk (SRP)',
  },
  ENTR: {
    Name: 'ENTR',
    Symbol: 'ENTR',
    CoinName: 'EnterDAO',
    FullName: 'EnterDAO (ENTR)',
  },
  BOG: {
    Name: 'BOG',
    Symbol: 'BOG',
    CoinName: 'Bogged Finance',
    FullName: 'Bogged Finance (BOG)',
  },
  OPUL: {
    Name: 'OPUL',
    Symbol: 'OPUL',
    CoinName: 'Opulous',
    FullName: 'Opulous (OPUL)',
  },
  TRGI: {
    Name: 'TRGI',
    Symbol: 'TRGI',
    CoinName: 'The Real Golden Inu',
    FullName: 'The Real Golden Inu (TRGI)',
  },
  RICH: {
    Name: 'RICH',
    Symbol: 'RICH',
    CoinName: 'Richie',
    FullName: 'Richie (RICH)',
  },
  ODDZ: {
    Name: 'ODDZ',
    Symbol: 'ODDZ',
    CoinName: 'Oddz',
    FullName: 'Oddz (ODDZ)',
  },
  PRV: {
    Name: 'PRV',
    Symbol: 'PRV',
    CoinName: 'PrivacySwap',
    FullName: 'PrivacySwap (PRV)',
  },
  JADEC: {
    Name: 'JADEC',
    Symbol: 'JADEC',
    CoinName: 'Jade Currency',
    FullName: 'Jade Currency (JADEC)',
  },
  PKD: {
    Name: 'PKD',
    Symbol: 'PKD',
    CoinName: 'PetKingdom',
    FullName: 'PetKingdom (PKD)',
  },
  BMARS: {
    Name: 'BMARS',
    Symbol: 'BMARS',
    CoinName: 'Binamars',
    FullName: 'Binamars (BMARS)',
  },
  DOGO: {
    Name: 'DOGO',
    Symbol: 'DOGO',
    CoinName: 'DogemonGo',
    FullName: 'DogemonGo (DOGO)',
  },
  BUMN: {
    Name: 'BUMN',
    Symbol: 'BUMN',
    CoinName: 'BUMooN',
    FullName: 'BUMooN (BUMN)',
  },
  OSWAP: {
    Name: 'OSWAP',
    Symbol: 'OSWAP',
    CoinName: 'OpenSwap',
    FullName: 'OpenSwap (OSWAP)',
  },
  BPAY: {
    Name: 'BPAY',
    Symbol: 'BPAY',
    CoinName: 'BNBPay',
    FullName: 'BNBPay (BPAY)',
  },
  DMOD: {
    Name: 'DMOD',
    Symbol: 'DMOD',
    CoinName: 'Demodyfi Token',
    FullName: 'Demodyfi Token (DMOD)',
  },
  CRDN: {
    Name: 'CRDN',
    Symbol: 'CRDN',
    CoinName: 'Cardence',
    FullName: 'Cardence (CRDN)',
  },
  DXT: {
    Name: 'DXT',
    Symbol: 'DXT',
    CoinName: 'Dexit Finance',
    FullName: 'Dexit Finance (DXT)',
  },
  LOUD: {
    Name: 'LOUD',
    Symbol: 'LOUD',
    CoinName: 'Loud Market',
    FullName: 'Loud Market (LOUD)',
  },
  DXB: {
    Name: 'DXB',
    Symbol: 'DXB',
    CoinName: 'DefiXBet',
    FullName: 'DefiXBet (DXB)',
  },
  TOK: {
    Name: 'TOK',
    Symbol: 'TOK',
    CoinName: 'Tokenplace',
    FullName: 'Tokenplace (TOK)',
  },
  SALE: {
    Name: 'SALE',
    Symbol: 'SALE',
    CoinName: 'DxSale Network',
    FullName: 'DxSale Network (SALE)',
  },
  ARV: {
    Name: 'ARV',
    Symbol: 'ARV',
    CoinName: 'Ariva',
    FullName: 'Ariva (ARV)',
  },
  MOONSHOT: {
    Name: 'MOONSHOT',
    Symbol: 'MOONSHOT',
    CoinName: 'Moonshot',
    FullName: 'Moonshot (MOONSHOT)',
  },
  POLIS: {
    Name: 'POLIS',
    Symbol: 'POLIS',
    CoinName: 'Star Atlas DAO',
    FullName: 'Star Atlas DAO (POLIS)',
  },
  ADAT: {
    Name: 'ADAT',
    Symbol: 'ADAT',
    CoinName: 'Adadex Tools',
    FullName: 'Adadex Tools (ADAT)',
  },
  BNBCH: {
    Name: 'BNBCH',
    Symbol: 'BNBCH',
    CoinName: 'BNB Cash',
    FullName: 'BNB Cash (BNBCH)',
  },
  CAKEMOON: {
    Name: 'CAKEMOON',
    Symbol: 'CAKEMOON',
    CoinName: 'CakeMoon',
    FullName: 'CakeMoon (CAKEMOON)',
  },
  MILK: {
    Name: 'MILK',
    Symbol: 'MILK',
    CoinName: 'Milkshake Swap',
    FullName: 'Milkshake Swap (MILK)',
  },
  ELM: {
    Name: 'ELM',
    Symbol: 'ELM',
    CoinName: 'Elements Play',
    FullName: 'Elements Play (ELM)',
  },
  MND: {
    Name: 'MND',
    Symbol: 'MND',
    CoinName: 'Mound Token',
    FullName: 'Mound Token (MND)',
  },
  MOONLIGHT: {
    Name: 'MOONLIGHT',
    Symbol: 'MOONLIGHT',
    CoinName: 'Moonlight Token',
    FullName: 'Moonlight Token (MOONLIGHT)',
  },
  FOC: {
    Name: 'FOC',
    Symbol: 'FOC',
    CoinName: 'TheForce Trade',
    FullName: 'TheForce Trade (FOC)',
  },
  LEOS: {
    Name: 'LEOS',
    Symbol: 'LEOS',
    CoinName: 'Leonicorn Swap',
    FullName: 'Leonicorn Swap (LEOS)',
  },
  MOONARCH: {
    Name: 'MOONARCH',
    Symbol: 'MOONARCH',
    CoinName: 'Moonarch',
    FullName: 'Moonarch (MOONARCH)',
  },
  BZZONE: {
    Name: 'BZZONE',
    Symbol: 'BZZONE',
    CoinName: 'Bzzone',
    FullName: 'Bzzone (BZZONE)',
  },
  EXZO: {
    Name: 'EXZO',
    Symbol: 'EXZO',
    CoinName: 'ExzoCoin 2.0',
    FullName: 'ExzoCoin 2.0 (EXZO)',
  },
  MNG: {
    Name: 'MNG',
    Symbol: 'MNG',
    CoinName: 'Moon Nation Game',
    FullName: 'Moon Nation Game (MNG)',
  },
  GENX: {
    Name: 'GENX',
    Symbol: 'GENX',
    CoinName: 'Genx Token',
    FullName: 'Genx Token (GENX)',
  },
  OWN: {
    Name: 'OWN',
    Symbol: 'OWN',
    CoinName: 'Ownly',
    FullName: 'Ownly (OWN)',
  },
  RARE: {
    Name: 'RARE',
    Symbol: 'RARE',
    CoinName: 'SuperRare',
    FullName: 'SuperRare (RARE)',
  },
  EFIL: {
    Name: 'EFIL',
    Symbol: 'EFIL',
    CoinName: 'Ethereum Wrapped Filecoin',
    FullName: 'Ethereum Wrapped Filecoin (EFIL)',
  },
  CHE: {
    Name: 'CHE',
    Symbol: 'CHE',
    CoinName: 'CherrySwap',
    FullName: 'CherrySwap (CHE)',
  },
  EBOX: {
    Name: 'EBOX',
    Symbol: 'EBOX',
    CoinName: 'Ethbox Token',
    FullName: 'Ethbox Token (EBOX)',
  },
  YAG: {
    Name: 'YAG',
    Symbol: 'YAG',
    CoinName: 'Yaki Gold',
    FullName: 'Yaki Gold (YAG)',
  },
  ROOM: {
    Name: 'ROOM',
    Symbol: 'ROOM',
    CoinName: 'OptionRoom',
    FullName: 'OptionRoom (ROOM)',
  },
  WSIENNA: {
    Name: 'WSIENNA',
    Symbol: 'WSIENNA',
    CoinName: 'Sienna ERC20',
    FullName: 'Sienna ERC20 (WSIENNA)',
  },
  SQG: {
    Name: 'SQG',
    Symbol: 'SQG',
    CoinName: 'Squid Token',
    FullName: 'Squid Token (SQG)',
  },
  WELD: {
    Name: 'WELD',
    Symbol: 'WELD',
    CoinName: 'Weld',
    FullName: 'Weld (WELD)',
  },
  WTK: {
    Name: 'WTK',
    Symbol: 'WTK',
    CoinName: 'WadzPay Token',
    FullName: 'WadzPay Token (WTK)',
  },
  SMILE: {
    Name: 'SMILE',
    Symbol: 'SMILE',
    CoinName: 'Smile Token',
    FullName: 'Smile Token (SMILE)',
  },
  FOREVERPUMP: {
    Name: 'FOREVERPUMP',
    Symbol: 'FOREVERPUMP',
    CoinName: 'Forever Pump',
    FullName: 'Forever Pump (FOREVERPUMP)',
  },
  BLOK: {
    Name: 'BLOK',
    Symbol: 'BLOK',
    CoinName: 'Bloktopia',
    FullName: 'Bloktopia (BLOK)',
  },
  LAIKA: {
    Name: 'LAIKA',
    Symbol: 'LAIKA',
    CoinName: 'Laika Protocol',
    FullName: 'Laika Protocol (LAIKA)',
  },
  VFOX: {
    Name: 'VFOX',
    Symbol: 'VFOX',
    CoinName: 'VFOX',
    FullName: 'VFOX (VFOX)',
  },
  SWAPZ: {
    Name: 'SWAPZ',
    Symbol: 'SWAPZ',
    CoinName: 'SWAPZ.app',
    FullName: 'SWAPZ.app (SWAPZ)',
  },
  OCTAX: {
    Name: 'OCTAX',
    Symbol: 'OCTAX',
    CoinName: 'OctaX',
    FullName: 'OctaX (OCTAX)',
  },
  COLL: {
    Name: 'COLL',
    Symbol: 'COLL',
    CoinName: 'Collateral Pay',
    FullName: 'Collateral Pay (COLL)',
  },
  BUFF: {
    Name: 'BUFF',
    Symbol: 'BUFF',
    CoinName: 'Buffalo Swap',
    FullName: 'Buffalo Swap (BUFF)',
  },
  SAFEBULL: {
    Name: 'SAFEBULL',
    Symbol: 'SAFEBULL',
    CoinName: 'SafeBull',
    FullName: 'SafeBull (SAFEBULL)',
  },
  BURNDOGE: {
    Name: 'BURNDOGE',
    Symbol: 'BURNDOGE',
    CoinName: 'BurnDoge',
    FullName: 'BurnDoge (BURNDOGE)',
  },
  WANA: {
    Name: 'WANA',
    Symbol: 'WANA',
    CoinName: 'Wanaka Farm',
    FullName: 'Wanaka Farm (WANA)',
  },
  FOX: {
    Name: 'FOX',
    Symbol: 'FOX',
    CoinName: 'ShapeShift FOX Token',
    FullName: 'ShapeShift FOX Token (FOX)',
  },
  SAFES: {
    Name: 'SAFES',
    Symbol: 'SAFES',
    CoinName: 'SafeSwap',
    FullName: 'SafeSwap (SAFES)',
  },
  SHIELDNET: {
    Name: 'SHIELDNET',
    Symbol: 'SHIELDNET',
    CoinName: 'Shield Network',
    FullName: 'Shield Network (SHIELDNET)',
  },
  SCIX: {
    Name: 'SCIX',
    Symbol: 'SCIX',
    CoinName: 'Scientix',
    FullName: 'Scientix (SCIX)',
  },
  OBS: {
    Name: 'OBS',
    Symbol: 'OBS',
    CoinName: 'One Basis Cash',
    FullName: 'One Basis Cash (OBS)',
  },
  MELLO: {
    Name: 'MELLO',
    Symbol: 'MELLO',
    CoinName: 'Mello Token',
    FullName: 'Mello Token (MELLO)',
  },
  SPE: {
    Name: 'SPE',
    Symbol: 'SPE',
    CoinName: 'SavePlanetEarth',
    FullName: 'SavePlanetEarth (SPE)',
  },
  LUCKY: {
    Name: 'LUCKY',
    Symbol: 'LUCKY',
    CoinName: 'Lucky Lion',
    FullName: 'Lucky Lion (LUCKY)',
  },
  IRT: {
    Name: 'IRT',
    Symbol: 'IRT',
    CoinName: 'Infinity Rocket',
    FullName: 'Infinity Rocket (IRT)',
  },
  DOGEBNB: {
    Name: 'DOGEBNB',
    Symbol: 'DOGEBNB',
    CoinName: 'DogeBNB',
    FullName: 'DogeBNB (DOGEBNB)',
  },
  PRINTS: {
    Name: 'PRINTS',
    Symbol: 'PRINTS',
    CoinName: 'FingerprintsDAO',
    FullName: 'FingerprintsDAO (PRINTS)',
  },
  ITGR: {
    Name: 'ITGR',
    Symbol: 'ITGR',
    CoinName: 'Integral',
    FullName: 'Integral (ITGR)',
  },
  CUEX: {
    Name: 'CUEX',
    Symbol: 'CUEX',
    CoinName: 'Cuex',
    FullName: 'Cuex (CUEX)',
  },
  RFG: {
    Name: 'RFG',
    Symbol: 'RFG',
    CoinName: 'Refugees Token',
    FullName: 'Refugees Token (RFG)',
  },
  SIL: {
    Name: 'SIL',
    Symbol: 'SIL',
    CoinName: 'SIL Finance Token V2',
    FullName: 'SIL Finance Token V2 (SIL)',
  },
  BONUSCAKE: {
    Name: 'BONUSCAKE',
    Symbol: 'BONUSCAKE',
    CoinName: 'Bonus Cake',
    FullName: 'Bonus Cake (BONUSCAKE)',
  },
  TFI: {
    Name: 'TFI',
    Symbol: 'TFI',
    CoinName: 'TrustFi Network Token',
    FullName: 'TrustFi Network Token (TFI)',
  },
  SCH: {
    Name: 'SCH',
    Symbol: 'SCH',
    CoinName: 'SoccerHub',
    FullName: 'SoccerHub (SCH)',
  },
  DARKEN: {
    Name: 'DARKEN',
    Symbol: 'DARKEN',
    CoinName: 'Dark Energy Crystals',
    FullName: 'Dark Energy Crystals (DARKEN)',
  },
  AUR: {
    Name: 'AUR',
    Symbol: 'AUR',
    CoinName: 'AUREO',
    FullName: 'AUREO (AUR)',
  },
  BUNNYROCKET: {
    Name: 'BUNNYROCKET',
    Symbol: 'BUNNYROCKET',
    CoinName: 'BunnyRocket',
    FullName: 'BunnyRocket (BUNNYROCKET)',
  },
  CPAN: {
    Name: 'CPAN',
    Symbol: 'CPAN',
    CoinName: 'CryptoPlanes',
    FullName: 'CryptoPlanes (CPAN)',
  },
  ZABAKU: {
    Name: 'ZABAKU',
    Symbol: 'ZABAKU',
    CoinName: 'Zabaku Inu',
    FullName: 'Zabaku Inu (ZABAKU)',
  },
  BSCM: {
    Name: 'BSCM',
    Symbol: 'BSCM',
    CoinName: 'BSC MemePad',
    FullName: 'BSC MemePad (BSCM)',
  },
  PIKACHU: {
    Name: 'PIKACHU',
    Symbol: 'PIKACHU',
    CoinName: 'Pikachu Inu',
    FullName: 'Pikachu Inu (PIKACHU)',
  },
  GOTEM: {
    Name: 'GOTEM',
    Symbol: 'GOTEM',
    CoinName: 'gotEM',
    FullName: 'gotEM (GOTEM)',
  },
  SLAM: {
    Name: 'SLAM',
    Symbol: 'SLAM',
    CoinName: 'Slam Token',
    FullName: 'Slam Token (SLAM)',
  },
  AU: {
    Name: 'AU',
    Symbol: 'AU',
    CoinName: 'AutoCrypto',
    FullName: 'AutoCrypto (AU)',
  },
  DDN: {
    Name: 'DDN',
    Symbol: 'DDN',
    CoinName: 'Den Domains',
    FullName: 'Den Domains (DDN)',
  },
  DEFIL: {
    Name: 'DEFIL',
    Symbol: 'DEFIL',
    CoinName: 'DeFIL',
    FullName: 'DeFIL (DEFIL)',
  },
  SOV: {
    Name: 'SOV',
    Symbol: 'SOV',
    CoinName: 'Sovryn',
    FullName: 'Sovryn (SOV)',
  },
  MANGA: {
    Name: 'MANGA',
    Symbol: 'MANGA',
    CoinName: 'Manga Token',
    FullName: 'Manga Token (MANGA)',
  },
  AKTIO: {
    Name: 'AKTIO',
    Symbol: 'AKTIO',
    CoinName: 'AKTIO Coin',
    FullName: 'AKTIO Coin (AKTIO)',
  },
  SLIM: {
    Name: 'SLIM',
    Symbol: 'SLIM',
    CoinName: 'Solanium',
    FullName: 'Solanium (SLIM)',
  },
  FILST: {
    Name: 'FILST',
    Symbol: 'FILST',
    CoinName: 'Filecoin Standard Hashrate Token',
    FullName: 'Filecoin Standard Hashrate Token (FILST)',
  },
  KISHIMOTO: {
    Name: 'KISHIMOTO',
    Symbol: 'KISHIMOTO',
    CoinName: 'Kishimoto Inu',
    FullName: 'Kishimoto Inu (KISHIMOTO)',
  },
  GFN: {
    Name: 'GFN',
    Symbol: 'GFN',
    CoinName: 'Graphene',
    FullName: 'Graphene (GFN)',
  },
  PFT: {
    Name: 'PFT',
    Symbol: 'PFT',
    CoinName: 'Pitch Finance Token',
    FullName: 'Pitch Finance Token (PFT)',
  },
  TORII: {
    Name: 'TORII',
    Symbol: 'TORII',
    CoinName: 'Torii Finance',
    FullName: 'Torii Finance (TORII)',
  },
  GOMA: {
    Name: 'GOMA',
    Symbol: 'GOMA',
    CoinName: 'GOMA Finance',
    FullName: 'GOMA Finance (GOMA)',
  },
  GAFI: {
    Name: 'GAFI',
    Symbol: 'GAFI',
    CoinName: 'GameFi',
    FullName: 'GameFi (GAFI)',
  },
  SBR: {
    Name: 'SBR',
    Symbol: 'SBR',
    CoinName: 'Saber',
    FullName: 'Saber (SBR)',
  },
  JOE: {
    Name: 'JOE',
    Symbol: 'JOE',
    CoinName: 'JOE',
    FullName: 'JOE (JOE)',
  },
  METIS: {
    Name: 'METIS',
    Symbol: 'METIS',
    CoinName: 'Metis Token',
    FullName: 'Metis Token (METIS)',
  },
  XWG: {
    Name: 'XWG',
    Symbol: 'XWG',
    CoinName: 'X World Games',
    FullName: 'X World Games (XWG)',
  },
  SHELL: {
    Name: 'SHELL',
    Symbol: 'SHELL',
    CoinName: 'Shell Token',
    FullName: 'Shell Token (SHELL)',
  },
  POK: {
    Name: 'POK',
    Symbol: 'POK',
    CoinName: 'Pokmonsters',
    FullName: 'Pokmonsters (POK)',
  },
  KALA: {
    Name: 'KALA',
    Symbol: 'KALA',
    CoinName: 'Kalata Protocol',
    FullName: 'Kalata Protocol (KALA)',
  },
  NFTART: {
    Name: 'NFTART',
    Symbol: 'NFTART',
    CoinName: 'NFT Art Finance',
    FullName: 'NFT Art Finance (NFTART)',
  },
  EPAN: {
    Name: 'EPAN',
    Symbol: 'EPAN',
    CoinName: 'Paypolitan Token',
    FullName: 'Paypolitan Token (EPAN)',
  },
  BABYFB: {
    Name: 'BABYFB',
    Symbol: 'BABYFB',
    CoinName: 'Baby Floki Billionaire',
    FullName: 'Baby Floki Billionaire (BABYFB)',
  },
  VLXPAD: {
    Name: 'VLXPAD',
    Symbol: 'VLXPAD',
    CoinName: 'VelasPad',
    FullName: 'VelasPad (VLXPAD)',
  },
  FWB: {
    Name: 'FWB',
    Symbol: 'FWB',
    CoinName: 'Friends With Benefits Pro',
    FullName: 'Friends With Benefits Pro (FWB)',
  },
  BATH: {
    Name: 'BATH',
    Symbol: 'BATH',
    CoinName: 'Battle Hero',
    FullName: 'Battle Hero (BATH)',
  },
  EVRY: {
    Name: 'EVRY',
    Symbol: 'EVRY',
    CoinName: 'Evrynet',
    FullName: 'Evrynet (EVRY)',
  },
  CHLT: {
    Name: 'CHLT',
    Symbol: 'CHLT',
    CoinName: 'Chellitcoin',
    FullName: 'Chellitcoin (CHLT)',
  },
  LAZIO: {
    Name: 'LAZIO',
    Symbol: 'LAZIO',
    CoinName: 'Lazio Fan Token',
    FullName: 'Lazio Fan Token (LAZIO)',
  },
  CMCX: {
    Name: 'CMCX',
    Symbol: 'CMCX',
    CoinName: 'CORE MultiChain',
    FullName: 'CORE MultiChain (CMCX)',
  },
  HPAD: {
    Name: 'HPAD',
    Symbol: 'HPAD',
    CoinName: 'HarmonyPad',
    FullName: 'HarmonyPad (HPAD)',
  },
  PARA: {
    Name: 'PARA',
    Symbol: 'PARA',
    CoinName: 'Paralink Network',
    FullName: 'Paralink Network (PARA)',
  },
  SLA: {
    Name: 'SLA',
    Symbol: 'SLA',
    CoinName: 'SUPERLAUNCH',
    FullName: 'SUPERLAUNCH (SLA)',
  },
  EBSC: {
    Name: 'EBSC',
    Symbol: 'EBSC',
    CoinName: 'EarlyBSC',
    FullName: 'EarlyBSC (EBSC)',
  },
  GRIMEX: {
    Name: 'GRIMEX',
    Symbol: 'GRIMEX',
    CoinName: 'SpaceGrime',
    FullName: 'SpaceGrime (GRIMEX)',
  },
  RSUN: {
    Name: 'RSUN',
    Symbol: 'RSUN',
    CoinName: 'RisingSun',
    FullName: 'RisingSun (RSUN)',
  },
  APAD: {
    Name: 'APAD',
    Symbol: 'APAD',
    CoinName: 'Anypad',
    FullName: 'Anypad (APAD)',
  },
  QUARTZ: {
    Name: 'QUARTZ',
    Symbol: 'QUARTZ',
    CoinName: 'Sandclock',
    FullName: 'Sandclock (QUARTZ)',
  },
  TZKI: {
    Name: 'TZKI',
    Symbol: 'TZKI',
    CoinName: 'Tsuzuki Inu',
    FullName: 'Tsuzuki Inu (TZKI)',
  },
  WOLVERINU: {
    Name: 'WOLVERINU',
    Symbol: 'WOLVERINU',
    CoinName: 'WOLVERINU',
    FullName: 'WOLVERINU (WOLVERINU)',
  },
  INUYASHA: {
    Name: 'INUYASHA',
    Symbol: 'INUYASHA',
    CoinName: 'Inuyasha',
    FullName: 'Inuyasha (INUYASHA)',
  },
  MINISHIB: {
    Name: 'MINISHIB',
    Symbol: 'MINISHIB',
    CoinName: 'miniSHIB ETH',
    FullName: 'miniSHIB ETH (MINISHIB)',
  },
  PINU: {
    Name: 'PINU',
    Symbol: 'PINU',
    CoinName: 'Piccolo Inu',
    FullName: 'Piccolo Inu (PINU)',
  },
  NAUSICAA: {
    Name: 'NAUSICAA',
    Symbol: 'NAUSICAA',
    CoinName: 'Nausicaa-Inu',
    FullName: 'Nausicaa-Inu (NAUSICAA)',
  },
  SAKATA: {
    Name: 'SAKATA',
    Symbol: 'SAKATA',
    CoinName: 'Sakata Inu',
    FullName: 'Sakata Inu (SAKATA)',
  },
  BRTX: {
    Name: 'BRTX',
    Symbol: 'BRTX',
    CoinName: 'Bertinity',
    FullName: 'Bertinity (BRTX)',
  },
  YOCO: {
    Name: 'YOCO',
    Symbol: 'YOCO',
    CoinName: 'YocoinYOCO',
    FullName: 'YocoinYOCO (YOCO)',
  },
  RBN: {
    Name: 'RBN',
    Symbol: 'RBN',
    CoinName: 'Ribbon Finance',
    FullName: 'Ribbon Finance (RBN)',
  },
  KITTY: {
    Name: 'KITTY',
    Symbol: 'KITTY',
    CoinName: 'Kitty Inu',
    FullName: 'Kitty Inu (KITTY)',
  },
  NXMC: {
    Name: 'NXMC',
    Symbol: 'NXMC',
    CoinName: 'NextMindCoin',
    FullName: 'NextMindCoin (NXMC)',
  },
  OH: {
    Name: 'OH',
    Symbol: 'OH',
    CoinName: 'Oh! Finance',
    FullName: 'Oh! Finance (OH)',
  },
  DINGER: {
    Name: 'DINGER',
    Symbol: 'DINGER',
    CoinName: 'Dinger Token',
    FullName: 'Dinger Token (DINGER)',
  },
  JRIT: {
    Name: 'JRIT',
    Symbol: 'JRIT',
    CoinName: 'JERITEX',
    FullName: 'JERITEX (JRIT)',
  },
  KAC: {
    Name: 'KAC',
    Symbol: 'KAC',
    CoinName: 'KACO Finance',
    FullName: 'KACO Finance (KAC)',
  },
  ASS: {
    Name: 'ASS',
    Symbol: 'ASS',
    CoinName: 'Australian Safe Shepherd',
    FullName: 'Australian Safe Shepherd (ASS)',
  },
  TRONPAD: {
    Name: 'TRONPAD',
    Symbol: 'TRONPAD',
    CoinName: 'TRONPAD',
    FullName: 'TRONPAD (TRONPAD)',
  },
  SWAPP: {
    Name: 'SWAPP',
    Symbol: 'SWAPP',
    CoinName: 'SWAPP Protocol',
    FullName: 'SWAPP Protocol (SWAPP)',
  },
  INU: {
    Name: 'INU',
    Symbol: 'INU',
    CoinName: 'INU Token',
    FullName: 'INU Token (INU)',
  },
  BCHA: {
    Name: 'BCHA',
    Symbol: 'BCHA',
    CoinName: 'Bitcoin Cash ABC',
    FullName: 'Bitcoin Cash ABC (BCHA)',
  },
  MEWTWO: {
    Name: 'MEWTWO',
    Symbol: 'MEWTWO',
    CoinName: 'Mewtwo Inu',
    FullName: 'Mewtwo Inu (MEWTWO)',
  },
  MSA: {
    Name: 'MSA',
    Symbol: 'MSA',
    CoinName: 'My Shiba Academia',
    FullName: 'My Shiba Academia (MSA)',
  },
  JPAW: {
    Name: 'JPAW',
    Symbol: 'JPAW',
    CoinName: 'Jpaw Inu',
    FullName: 'Jpaw Inu (JPAW)',
  },
  L: {
    Name: 'L',
    Symbol: 'L',
    CoinName: 'L inu',
    FullName: 'L inu (L)',
  },
  $TIME: {
    Name: 'STIME',
    Symbol: '$TIME',
    CoinName: 'Madagascar Token',
    FullName: 'Madagascar Token (STIME)',
  },
  LUMI: {
    Name: 'LUMI',
    Symbol: 'LUMI',
    CoinName: 'LUMI Credits',
    FullName: 'LUMI Credits (LUMI)',
  },
  IDIA: {
    Name: 'IDIA',
    Symbol: 'IDIA',
    CoinName: 'Impossible Finance Launchpad',
    FullName: 'Impossible Finance Launchpad (IDIA)',
  },
  CGS: {
    Name: 'CGS',
    Symbol: 'CGS',
    CoinName: 'Crypto Gladiator Shards',
    FullName: 'Crypto Gladiator Shards (CGS)',
  },
  TUP: {
    Name: 'TUP',
    Symbol: 'TUP',
    CoinName: 'Tenup',
    FullName: 'Tenup (TUP)',
  },
  SCLP: {
    Name: 'SCLP',
    Symbol: 'SCLP',
    CoinName: 'Scallop',
    FullName: 'Scallop (SCLP)',
  },
  SGB: {
    Name: 'SGB',
    Symbol: 'SGB',
    CoinName: 'Songbird',
    FullName: 'Songbird (SGB)',
  },
  FOL: {
    Name: 'FOL',
    Symbol: 'FOL',
    CoinName: 'Folder Protocol',
    FullName: 'Folder Protocol (FOL)',
  },
  RIL: {
    Name: 'RIL',
    Symbol: 'RIL',
    CoinName: 'Rilcoin',
    FullName: 'Rilcoin (RIL)',
  },
  KAREN: {
    Name: 'KAREN',
    Symbol: 'KAREN',
    CoinName: 'KarenCoin',
    FullName: 'KarenCoin (KAREN)',
  },
  SAMO: {
    Name: 'SAMO',
    Symbol: 'SAMO',
    CoinName: 'Samoyedcoin',
    FullName: 'Samoyedcoin (SAMO)',
  },
  SQUID: {
    Name: 'SQUID',
    Symbol: 'SQUID',
    CoinName: 'Squid Game',
    FullName: 'Squid Game (SQUID)',
  },
  CATE: {
    Name: 'CATE',
    Symbol: 'CATE',
    CoinName: 'CateCoin',
    FullName: 'CateCoin (CATE)',
  },
  CATGIRL: {
    Name: 'CATGIRL',
    Symbol: 'CATGIRL',
    CoinName: 'Catgirl',
    FullName: 'Catgirl (CATGIRL)',
  },
  DOGEZILLA: {
    Name: 'DOGEZILLA',
    Symbol: 'DOGEZILLA',
    CoinName: 'DogeZilla',
    FullName: 'DogeZilla (DOGEZILLA)',
  },
  AURY: {
    Name: 'AURY',
    Symbol: 'AURY',
    CoinName: 'Aurory',
    FullName: 'Aurory (AURY)',
  },
  GINUX: {
    Name: 'GINUX',
    Symbol: 'GINUX',
    CoinName: 'Green Shiba Inu',
    FullName: 'Green Shiba Inu (GINUX)',
  },
  SPWN: {
    Name: 'SPWN',
    Symbol: 'SPWN',
    CoinName: 'Bitspawn',
    FullName: 'Bitspawn (SPWN)',
  },
  PIST: {
    Name: 'PIST',
    Symbol: 'PIST',
    CoinName: 'Pist Trust',
    FullName: 'Pist Trust (PIST)',
  },
  PANDO: {
    Name: 'PANDO',
    Symbol: 'PANDO',
    CoinName: 'Pando',
    FullName: 'Pando (PANDO)',
  },
  SIP: {
    Name: 'SIP',
    Symbol: 'SIP',
    CoinName: 'Space SIP',
    FullName: 'Space SIP (SIP)',
  },
  MONONOKEINU: {
    Name: 'MONONOKEINU',
    Symbol: 'MONONOKEINU',
    CoinName: 'Mononoke Inu',
    FullName: 'Mononoke Inu (MONONOKEINU)',
  },
  VPP: {
    Name: 'VPP',
    Symbol: 'VPP',
    CoinName: 'Virtue Poker Points',
    FullName: 'Virtue Poker Points (VPP)',
  },
  BPRIVA: {
    Name: 'BPRIVA',
    Symbol: 'BPRIVA',
    CoinName: 'Privapp Network',
    FullName: 'Privapp Network (BPRIVA)',
  },
  POCO: {
    Name: 'POCO',
    Symbol: 'POCO',
    CoinName: 'Pocoland',
    FullName: 'Pocoland (POCO)',
  },
  SLOKI: {
    Name: 'SLOKI',
    Symbol: 'SLOKI',
    CoinName: 'Super Floki',
    FullName: 'Super Floki (SLOKI)',
  },
  ENV: {
    Name: 'ENV',
    Symbol: 'ENV',
    CoinName: 'ENVOY',
    FullName: 'ENVOY (ENV)',
  },
  AQUAP: {
    Name: 'AQUAP',
    Symbol: 'AQUAP',
    CoinName: 'Planet Finance',
    FullName: 'Planet Finance (AQUAP)',
  },
  NEET: {
    Name: 'NEET',
    Symbol: 'NEET',
    CoinName: 'NEET Finance',
    FullName: 'NEET Finance (NEET)',
  },
  IMX: {
    Name: 'IMX',
    Symbol: 'IMX',
    CoinName: 'Immutable X',
    FullName: 'Immutable X (IMX)',
  },
  MC: {
    Name: 'MC',
    Symbol: 'MC',
    CoinName: 'Merit Circle',
    FullName: 'Merit Circle (MC)',
  },
  MIST: {
    Name: 'MIST',
    Symbol: 'MIST',
    CoinName: 'Mist',
    FullName: 'Mist (MIST)',
  },
  HCT: {
    Name: 'HCT',
    Symbol: 'HCT',
    CoinName: 'HurricaneSwap Token',
    FullName: 'HurricaneSwap Token (HCT)',
  },
  NBL: {
    Name: 'NBL',
    Symbol: 'NBL',
    CoinName: 'Nobility',
    FullName: 'Nobility (NBL)',
  },
  WIFEDOGE: {
    Name: 'WIFEDOGE',
    Symbol: 'WIFEDOGE',
    CoinName: 'Wifedoge',
    FullName: 'Wifedoge (WIFEDOGE)',
  },
  MST: {
    Name: 'MST',
    Symbol: 'MST',
    CoinName: 'Idle Mystic',
    FullName: 'Idle Mystic (MST)',
  },
  RD: {
    Name: 'RD',
    Symbol: 'RD',
    CoinName: 'Round Dollar',
    FullName: 'Round Dollar (RD)',
  },
  BTR: {
    Name: 'BTR',
    Symbol: 'BTR',
    CoinName: 'BTRIPS',
    FullName: 'BTRIPS (BTR)',
  },
  STRAY: {
    Name: 'STRAY',
    Symbol: 'STRAY',
    CoinName: 'Animal Token',
    FullName: 'Animal Token (STRAY)',
  },
  IDTT: {
    Name: 'IDTT',
    Symbol: 'IDTT',
    CoinName: 'Identity',
    FullName: 'Identity (IDTT)',
  },
  WOOF: {
    Name: 'WOOF',
    Symbol: 'WOOF',
    CoinName: 'Shibance Token',
    FullName: 'Shibance Token (WOOF)',
  },
  RDT: {
    Name: 'RDT',
    Symbol: 'RDT',
    CoinName: 'Ridotto',
    FullName: 'Ridotto (RDT)',
  },
  NASADOGE: {
    Name: 'NASADOGE',
    Symbol: 'NASADOGE',
    CoinName: 'Nasa Doge',
    FullName: 'Nasa Doge (NASADOGE)',
  },
  FAST: {
    Name: 'FAST',
    Symbol: 'FAST',
    CoinName: 'Fastswap',
    FullName: 'Fastswap (FAST)',
  },
  ATH: {
    Name: 'ATH',
    Symbol: 'ATH',
    CoinName: 'AetherV2',
    FullName: 'AetherV2 (ATH)',
  },
  TIKI: {
    Name: 'TIKI',
    Symbol: 'TIKI',
    CoinName: 'Tiki Token',
    FullName: 'Tiki Token (TIKI)',
  },
  PARAB: {
    Name: 'PARAB',
    Symbol: 'PARAB',
    CoinName: 'Parabolic',
    FullName: 'Parabolic (PARAB)',
  },
  LZ: {
    Name: 'LZ',
    Symbol: 'LZ',
    CoinName: 'LaunchZone',
    FullName: 'LaunchZone (LZ)',
  },
  IBNB: {
    Name: 'IBNB',
    Symbol: 'IBNB',
    CoinName: 'iBNB',
    FullName: 'iBNB (IBNB)',
  },
  BRKL: {
    Name: 'BRKL',
    Symbol: 'BRKL',
    CoinName: 'Brokoli Token',
    FullName: 'Brokoli Token (BRKL)',
  },
  MWAR: {
    Name: 'MWAR',
    Symbol: 'MWAR',
    CoinName: 'MemeWars (MWAR)',
    FullName: 'MemeWars (MWAR) (MWAR)',
  },
  CBRL: {
    Name: 'CBRL',
    Symbol: 'CBRL',
    CoinName: 'Crypto BRL',
    FullName: 'Crypto BRL (CBRL)',
  },
  APYS: {
    Name: 'APYS',
    Symbol: 'APYS',
    CoinName: 'APYSwap',
    FullName: 'APYSwap (APYS)',
  },
  AVXL: {
    Name: 'AVXL',
    Symbol: 'AVXL',
    CoinName: 'Avaxlauncher',
    FullName: 'Avaxlauncher (AVXL)',
  },
  PERA: {
    Name: 'PERA',
    Symbol: 'PERA',
    CoinName: 'Pera Finance',
    FullName: 'Pera Finance (PERA)',
  },
  SHILL: {
    Name: 'SHILL',
    Symbol: 'SHILL',
    CoinName: 'SHILL Token',
    FullName: 'SHILL Token (SHILL)',
  },
  AGF: {
    Name: 'AGF',
    Symbol: 'AGF',
    CoinName: 'Augmented Finance',
    FullName: 'Augmented Finance (AGF)',
  },
  WARS: {
    Name: 'WARS',
    Symbol: 'WARS',
    CoinName: 'MetaWars',
    FullName: 'MetaWars (WARS)',
  },
  WONDER: {
    Name: 'WONDER',
    Symbol: 'WONDER',
    CoinName: 'Wonderland',
    FullName: 'Wonderland (WONDER)',
  },
  POLL: {
    Name: 'POLL',
    Symbol: 'POLL',
    CoinName: 'Pollchain',
    FullName: 'Pollchain (POLL)',
  },
  LC: {
    Name: 'LC',
    Symbol: 'LC',
    CoinName: 'Lotus Capital',
    FullName: 'Lotus Capital (LC)',
  },
  BNX: {
    Name: 'BNX',
    Symbol: 'BNX',
    CoinName: 'BinaryX',
    FullName: 'BinaryX (BNX)',
  },
  DBA: {
    Name: 'DBA',
    Symbol: 'DBA',
    CoinName: 'Digital Bank of Africa',
    FullName: 'Digital Bank of Africa (DBA)',
  },
  SHIBAMOM: {
    Name: 'SHIBAMOM',
    Symbol: 'SHIBAMOM',
    CoinName: 'Shiba Mom',
    FullName: 'Shiba Mom (SHIBAMOM)',
  },
  CELL: {
    Name: 'CELL',
    Symbol: 'CELL',
    CoinName: 'Cellframe',
    FullName: 'Cellframe (CELL)',
  },
  YAE: {
    Name: 'YAE',
    Symbol: 'YAE',
    CoinName: 'Cryptonovae',
    FullName: 'Cryptonovae (YAE)',
  },
  ORT: {
    Name: 'ORT',
    Symbol: 'ORT',
    CoinName: 'Omni Real Estate Token',
    FullName: 'Omni Real Estate Token (ORT)',
  },
  ECC: {
    Name: 'ECC',
    Symbol: 'ECC',
    CoinName: 'Etherconnect',
    FullName: 'Etherconnect (ECC)',
  },
  BLS: {
    Name: 'BLS',
    Symbol: 'BLS',
    CoinName: 'Blocks Space',
    FullName: 'Blocks Space (BLS)',
  },
  CATZ: {
    Name: 'CATZ',
    Symbol: 'CATZ',
    CoinName: 'CatzCoin',
    FullName: 'CatzCoin (CATZ)',
  },
  FNDZ: {
    Name: 'FNDZ',
    Symbol: 'FNDZ',
    CoinName: 'FNDZ Token',
    FullName: 'FNDZ Token (FNDZ)',
  },
  KCCM: {
    Name: 'KCCM',
    Symbol: 'KCCM',
    CoinName: 'KCC MemePad',
    FullName: 'KCC MemePad (KCCM)',
  },
  FBNB: {
    Name: 'FBNB',
    Symbol: 'FBNB',
    CoinName: 'ForeverBNB',
    FullName: 'ForeverBNB (FBNB)',
  },
  QDX: {
    Name: 'QDX',
    Symbol: 'QDX',
    CoinName: 'Quidax',
    FullName: 'Quidax (QDX)',
  },
  EMPIRE: {
    Name: 'EMPIRE',
    Symbol: 'EMPIRE',
    CoinName: 'Empire Token',
    FullName: 'Empire Token (EMPIRE)',
  },
  SOUND: {
    Name: 'SOUND',
    Symbol: 'SOUND',
    CoinName: 'Sound Coin',
    FullName: 'Sound Coin (SOUND)',
  },
  SNK: {
    Name: 'SNK',
    Symbol: 'SNK',
    CoinName: 'Snake Token',
    FullName: 'Snake Token (SNK)',
  },
  NOTE: {
    Name: 'NOTE',
    Symbol: 'NOTE',
    CoinName: 'Notional Finance',
    FullName: 'Notional Finance (NOTE)',
  },
  BNPL: {
    Name: 'BNPL',
    Symbol: 'BNPL',
    CoinName: 'BNPL Pay',
    FullName: 'BNPL Pay (BNPL)',
  },
  CWAR: {
    Name: 'CWAR',
    Symbol: 'CWAR',
    CoinName: 'Cryowar Token',
    FullName: 'Cryowar Token (CWAR)',
  },
  FF: {
    Name: 'FF',
    Symbol: 'FF',
    CoinName: 'Forefront',
    FullName: 'Forefront (FF)',
  },
  DFSG: {
    Name: 'DFSG',
    Symbol: 'DFSG',
    CoinName: 'DFSocial Gaming',
    FullName: 'DFSocial Gaming (DFSG)',
  },
  SLND: {
    Name: 'SLND',
    Symbol: 'SLND',
    CoinName: 'Solend',
    FullName: 'Solend (SLND)',
  },
  WILD: {
    Name: 'WILD',
    Symbol: 'WILD',
    CoinName: 'Wilder World',
    FullName: 'Wilder World (WILD)',
  },
  ZUNA: {
    Name: 'ZUNA',
    Symbol: 'ZUNA',
    CoinName: 'ZUNA',
    FullName: 'ZUNA (ZUNA)',
  },
  LOCK: {
    Name: 'LOCK',
    Symbol: 'LOCK',
    CoinName: 'Contracto',
    FullName: 'Contracto (LOCK)',
  },
  MERKLE: {
    Name: 'MERKLE',
    Symbol: 'MERKLE',
    CoinName: 'Merkle Network',
    FullName: 'Merkle Network (MERKLE)',
  },
  BNBH: {
    Name: 'BNBH',
    Symbol: 'BNBH',
    CoinName: 'BnbHeroes Token',
    FullName: 'BnbHeroes Token (BNBH)',
  },
  REU: {
    Name: 'REU',
    Symbol: 'REU',
    CoinName: 'REUCOIN',
    FullName: 'REUCOIN (REU)',
  },
  ETERNAL: {
    Name: 'ETERNAL',
    Symbol: 'ETERNAL',
    CoinName: 'CryptoMines Eternal',
    FullName: 'CryptoMines Eternal (ETERNAL)',
  },
  DOG: {
    Name: 'DOG',
    Symbol: 'DOG',
    CoinName: 'The Doge NFT',
    FullName: 'The Doge NFT (DOG)',
  },
  STKATOM: {
    Name: 'STKATOM',
    Symbol: 'STKATOM',
    CoinName: 'pSTAKE Staked ATOM',
    FullName: 'pSTAKE Staked ATOM (STKATOM)',
  },
  BACON: {
    Name: 'BACON',
    Symbol: 'BACON',
    CoinName: 'BaconDAO (BACON)',
    FullName: 'BaconDAO (BACON) (BACON)',
  },
  STKXPRT: {
    Name: 'STKXPRT',
    Symbol: 'STKXPRT',
    CoinName: 'pSTAKE Staked XPRT',
    FullName: 'pSTAKE Staked XPRT (STKXPRT)',
  },
  DXCT: {
    Name: 'DXCT',
    Symbol: 'DXCT',
    CoinName: 'DNAxCAT',
    FullName: 'DNAxCAT (DXCT)',
  },
  XDEFI: {
    Name: 'XDEFI',
    Symbol: 'XDEFI',
    CoinName: 'XDEFI',
    FullName: 'XDEFI (XDEFI)',
  },
  DOGEDASH: {
    Name: 'DOGEDASH',
    Symbol: 'DOGEDASH',
    CoinName: 'Doge Dash',
    FullName: 'Doge Dash (DOGEDASH)',
  },
  BLOCKS: {
    Name: 'BLOCKS',
    Symbol: 'BLOCKS',
    CoinName: 'BLOCKS',
    FullName: 'BLOCKS (BLOCKS)',
  },
  GM: {
    Name: 'GM',
    Symbol: 'GM',
    CoinName: 'GM',
    FullName: 'GM (GM)',
  },
  BOBA: {
    Name: 'BOBA',
    Symbol: 'BOBA',
    CoinName: 'Boba Network',
    FullName: 'Boba Network (BOBA)',
  },
  AGEUR: {
    Name: 'AGEUR',
    Symbol: 'AGEUR',
    CoinName: 'agEUR',
    FullName: 'agEUR (AGEUR)',
  },
  ANGLE: {
    Name: 'ANGLE',
    Symbol: 'ANGLE',
    CoinName: 'ANGLE',
    FullName: 'ANGLE (ANGLE)',
  },
  NFD: {
    Name: 'NFD',
    Symbol: 'NFD',
    CoinName: 'Feisty Doge NFT',
    FullName: 'Feisty Doge NFT (NFD)',
  },
  METACAT: {
    Name: 'METACAT',
    Symbol: 'METACAT',
    CoinName: 'MetaCat',
    FullName: 'MetaCat (METACAT)',
  },
  PAD: {
    Name: 'PAD',
    Symbol: 'PAD',
    CoinName: 'NearPad',
    FullName: 'NearPad (PAD)',
  },
  LEAG: {
    Name: 'LEAG',
    Symbol: 'LEAG',
    CoinName: 'LeagueDAO Governance Token',
    FullName: 'LeagueDAO Governance Token (LEAG)',
  },
  SNOOP: {
    Name: 'SNOOP',
    Symbol: 'SNOOP',
    CoinName: 'SnoopDAO',
    FullName: 'SnoopDAO (SNOOP)',
  },
  YEL: {
    Name: 'YEL',
    Symbol: 'YEL',
    CoinName: 'Yel.Finance',
    FullName: 'Yel.Finance (YEL)',
  },
  MARS4: {
    Name: 'MARS4',
    Symbol: 'MARS4',
    CoinName: 'MARS4',
    FullName: 'MARS4 (MARS4)',
  },
  STANDARD: {
    Name: 'STANDARD',
    Symbol: 'STANDARD',
    CoinName: 'Stakeborg DAO',
    FullName: 'Stakeborg DAO (STANDARD)',
  },
  STRP: {
    Name: 'STRP',
    Symbol: 'STRP',
    CoinName: 'Strips Finance',
    FullName: 'Strips Finance (STRP)',
  },
  FOLD: {
    Name: 'FOLD',
    Symbol: 'FOLD',
    CoinName: 'Manifold Finance',
    FullName: 'Manifold Finance (FOLD)',
  },
  SWD: {
    Name: 'SWD',
    Symbol: 'SWD',
    CoinName: 'SW DAO',
    FullName: 'SW DAO (SWD)',
  },
  LMT: {
    Name: 'LMT',
    Symbol: 'LMT',
    CoinName: 'Lympo Market Token',
    FullName: 'Lympo Market Token (LMT)',
  },
  GORILLAINU: {
    Name: 'GORILLAINU',
    Symbol: 'GORILLAINU',
    CoinName: 'Gorilla Inu',
    FullName: 'Gorilla Inu (GORILLAINU)',
  },
  SPAY: {
    Name: 'SPAY',
    Symbol: 'SPAY',
    CoinName: 'SpaceY 2025',
    FullName: 'SpaceY 2025 (SPAY)',
  },
  DMLG: {
    Name: 'DMLG',
    Symbol: 'DMLG',
    CoinName: 'Demole',
    FullName: 'Demole (DMLG)',
  },
  OPA: {
    Name: 'OPA',
    Symbol: 'OPA',
    CoinName: 'Option Panda Platform',
    FullName: 'Option Panda Platform (OPA)',
  },
  HERA: {
    Name: 'HERA',
    Symbol: 'HERA',
    CoinName: 'Hero Arena',
    FullName: 'Hero Arena (HERA)',
  },
  AVN: {
    Name: 'AVN',
    Symbol: 'AVN',
    CoinName: 'AVNRich',
    FullName: 'AVNRich (AVN)',
  },
  WAI: {
    Name: 'WAI',
    Symbol: 'WAI',
    CoinName: 'Wanaka Farm WAIRERE Token',
    FullName: 'Wanaka Farm WAIRERE Token (WAI)',
  },
  NORA: {
    Name: 'NORA',
    Symbol: 'NORA',
    CoinName: 'SnowCrash Token',
    FullName: 'SnowCrash Token (NORA)',
  },
  GODZ: {
    Name: 'GODZ',
    Symbol: 'GODZ',
    CoinName: 'Cryptogodz',
    FullName: 'Cryptogodz (GODZ)',
  },
  DNFT: {
    Name: 'DNFT',
    Symbol: 'DNFT',
    CoinName: 'DareNFT',
    FullName: 'DareNFT (DNFT)',
  },
  PSP: {
    Name: 'PSP',
    Symbol: 'PSP',
    CoinName: 'ParaSwap',
    FullName: 'ParaSwap (PSP)',
  },
  REVA: {
    Name: 'REVA',
    Symbol: 'REVA',
    CoinName: 'Revault Network',
    FullName: 'Revault Network (REVA)',
  },
  CHAMP: {
    Name: 'CHAMP',
    Symbol: 'CHAMP',
    CoinName: 'NFT Champions',
    FullName: 'NFT Champions (CHAMP)',
  },
  XNFT: {
    Name: 'XNFT',
    Symbol: 'XNFT',
    CoinName: 'xNFT Protocol',
    FullName: 'xNFT Protocol (XNFT)',
  },
  KFT: {
    Name: 'KFT',
    Symbol: 'KFT',
    CoinName: 'Knit Finance',
    FullName: 'Knit Finance (KFT)',
  },
  ROCKI: {
    Name: 'ROCKI',
    Symbol: 'ROCKI',
    CoinName: 'Rocki',
    FullName: 'Rocki (ROCKI)',
  },
  GENE: {
    Name: 'GENE',
    Symbol: 'GENE',
    CoinName: 'Genopets',
    FullName: 'Genopets (GENE)',
  },
  BIRD: {
    Name: 'BIRD',
    Symbol: 'BIRD',
    CoinName: 'Bird.Money',
    FullName: 'Bird.Money (BIRD)',
  },
  SUNNY: {
    Name: 'SUNNY',
    Symbol: 'SUNNY',
    CoinName: 'Sunny Aggregator',
    FullName: 'Sunny Aggregator (SUNNY)',
  },
  GNT: {
    Name: 'GNT',
    Symbol: 'GNT',
    CoinName: 'GreenTrust',
    FullName: 'GreenTrust (GNT)',
  },
  PLUGCN: {
    Name: 'PLUGCN',
    Symbol: 'PLUGCN',
    CoinName: 'Plug Chain',
    FullName: 'Plug Chain (PLUGCN)',
  },
  MARSRISE: {
    Name: 'MARSRISE',
    Symbol: 'MARSRISE',
    CoinName: 'MarsRise',
    FullName: 'MarsRise (MARSRISE)',
  },
  YIN: {
    Name: 'YIN',
    Symbol: 'YIN',
    CoinName: 'YIN Finance',
    FullName: 'YIN Finance (YIN)',
  },
  RAM: {
    Name: 'RAM',
    Symbol: 'RAM',
    CoinName: 'Ramifi Protocol',
    FullName: 'Ramifi Protocol (RAM)',
  },
  SHIBELON: {
    Name: 'SHIBELON',
    Symbol: 'SHIBELON',
    CoinName: 'ShibElon',
    FullName: 'ShibElon (SHIBELON)',
  },
  CPRX: {
    Name: 'CPRX',
    Symbol: 'CPRX',
    CoinName: 'Crypto Perx',
    FullName: 'Crypto Perx (CPRX)',
  },
  MELD: {
    Name: 'MELD',
    Symbol: 'MELD',
    CoinName: 'Melodity',
    FullName: 'Melodity (MELD)',
  },
  BOSU: {
    Name: 'BOSU',
    Symbol: 'BOSU',
    CoinName: 'Bosu Inu',
    FullName: 'Bosu Inu (BOSU)',
  },
  PTU: {
    Name: 'PTU',
    Symbol: 'PTU',
    CoinName: 'Pintu Token',
    FullName: 'Pintu Token (PTU)',
  },
  NCR: {
    Name: 'NCR',
    Symbol: 'NCR',
    CoinName: 'Neos Credits',
    FullName: 'Neos Credits (NCR)',
  },
  AUSD: {
    Name: 'AUSD',
    Symbol: 'AUSD',
    CoinName: 'Appeal dollar',
    FullName: 'Appeal dollar (AUSD)',
  },
  WNYC: {
    Name: 'WNYC',
    Symbol: 'WNYC',
    CoinName: 'Wrapped NewYorkCoin',
    FullName: 'Wrapped NewYorkCoin (WNYC)',
  },
  BICO: {
    Name: 'BICO',
    Symbol: 'BICO',
    CoinName: 'Biconomy',
    FullName: 'Biconomy (BICO)',
  },
  SHIRYOINU: {
    Name: 'SHIRYOINU',
    Symbol: 'SHIRYOINU',
    CoinName: 'Shiryo-Inu',
    FullName: 'Shiryo-Inu (SHIRYOINU)',
  },
  FREN: {
    Name: 'FREN',
    Symbol: 'FREN',
    CoinName: 'FREN',
    FullName: 'FREN (FREN)',
  },
  MILK2: {
    Name: 'MILK2',
    Symbol: 'MILK2',
    CoinName: 'Spaceswap MILK2',
    FullName: 'Spaceswap MILK2 (MILK2)',
  },
  SHAKE: {
    Name: 'SHAKE',
    Symbol: 'SHAKE',
    CoinName: 'Spaceswap SHAKE',
    FullName: 'Spaceswap SHAKE (SHAKE)',
  },
  LTNM: {
    Name: 'LTNM',
    Symbol: 'LTNM',
    CoinName: 'Bitcoin Latinum',
    FullName: 'Bitcoin Latinum (LTNM)',
  },
  OMIC: {
    Name: 'OMIC',
    Symbol: 'OMIC',
    CoinName: 'Omicron',
    FullName: 'Omicron (OMIC)',
  },
  KMA: {
    Name: 'KMA',
    Symbol: 'KMA',
    CoinName: 'Calamari Network',
    FullName: 'Calamari Network (KMA)',
  },
  BKN: {
    Name: 'BKN',
    Symbol: 'BKN',
    CoinName: 'Brickken',
    FullName: 'Brickken (BKN)',
  },
  XTAG: {
    Name: 'XTAG',
    Symbol: 'XTAG',
    CoinName: 'xHashtag',
    FullName: 'xHashtag (XTAG)',
  },
  CCAR: {
    Name: 'CCAR',
    Symbol: 'CCAR',
    CoinName: 'CryptoCars',
    FullName: 'CryptoCars (CCAR)',
  },
  FLAME: {
    Name: 'FLAME',
    Symbol: 'FLAME',
    CoinName: 'FireStarter',
    FullName: 'FireStarter (FLAME)',
  },
  UNIC: {
    Name: 'UNIC',
    Symbol: 'UNIC',
    CoinName: 'Unicly',
    FullName: 'Unicly (UNIC)',
  },
  KILT: {
    Name: 'KILT',
    Symbol: 'KILT',
    CoinName: 'KILT Protocol',
    FullName: 'KILT Protocol (KILT)',
  },
  BTN: {
    Name: 'BTN',
    Symbol: 'BTN',
    CoinName: 'Bittron',
    FullName: 'Bittron (BTN)',
  },
  SRX: {
    Name: 'SRX',
    Symbol: 'SRX',
    CoinName: 'StorX',
    FullName: 'StorX (SRX)',
  },
  DEXA: {
    Name: 'DEXA',
    Symbol: 'DEXA',
    CoinName: 'DEXA COIN',
    FullName: 'DEXA COIN (DEXA)',
  },
  PERI: {
    Name: 'PERI',
    Symbol: 'PERI',
    CoinName: 'PERI Finance',
    FullName: 'PERI Finance (PERI)',
  },
  MIMO: {
    Name: 'MIMO',
    Symbol: 'MIMO',
    CoinName: 'MIMO Parallel Governance Token',
    FullName: 'MIMO Parallel Governance Token (MIMO)',
  },
  DS: {
    Name: 'DS',
    Symbol: 'DS',
    CoinName: 'DeStorage',
    FullName: 'DeStorage (DS)',
  },
  MOCHI: {
    Name: 'MOCHI',
    Symbol: 'MOCHI',
    CoinName: 'Mochiswap',
    FullName: 'Mochiswap (MOCHI)',
  },
  GN: {
    Name: 'GN',
    Symbol: 'GN',
    CoinName: 'GN',
    FullName: 'GN (GN)',
  },
  DOE: {
    Name: 'DOE',
    Symbol: 'DOE',
    CoinName: 'Dogs Of Elon',
    FullName: 'Dogs Of Elon (DOE)',
  },
  TRL: {
    Name: 'TRL',
    Symbol: 'TRL',
    CoinName: 'Triall',
    FullName: 'Triall (TRL)',
  },
  HKC: {
    Name: 'HKC',
    Symbol: 'HKC',
    CoinName: 'HK Coin',
    FullName: 'HK Coin (HKC)',
  },
  PEOPLE: {
    Name: 'PEOPLE',
    Symbol: 'PEOPLE',
    CoinName: 'ConstitutionDAO',
    FullName: 'ConstitutionDAO (PEOPLE)',
  },
  DOSE: {
    Name: 'DOSE',
    Symbol: 'DOSE',
    CoinName: 'DOSE',
    FullName: 'DOSE (DOSE)',
  },
  POLYDOGE: {
    Name: 'POLYDOGE',
    Symbol: 'POLYDOGE',
    CoinName: 'PolyDoge',
    FullName: 'PolyDoge (POLYDOGE)',
  },
  LATTE: {
    Name: 'LATTE',
    Symbol: 'LATTE',
    CoinName: 'LatteSwap',
    FullName: 'LatteSwap (LATTE)',
  },
  MEMEINU: {
    Name: 'MEMEINU',
    Symbol: 'MEMEINU',
    CoinName: 'Meme Inu',
    FullName: 'Meme Inu (MEMEINU)',
  },
  CHARIZARD: {
    Name: 'CHARIZARD',
    Symbol: 'CHARIZARD',
    CoinName: 'Charizard Inu',
    FullName: 'Charizard Inu (CHARIZARD)',
  },
  XPNET: {
    Name: 'XPNET',
    Symbol: 'XPNET',
    CoinName: 'XP Network',
    FullName: 'XP Network (XPNET)',
  },
  SCAR: {
    Name: 'SCAR',
    Symbol: 'SCAR',
    CoinName: 'Velhalla',
    FullName: 'Velhalla (SCAR)',
  },
  VVS: {
    Name: 'VVS',
    Symbol: 'VVS',
    CoinName: 'VVS Finance',
    FullName: 'VVS Finance (VVS)',
  },
  REALM: {
    Name: 'REALM',
    Symbol: 'REALM',
    CoinName: 'Realm',
    FullName: 'Realm (REALM)',
  },
  GAMEX: {
    Name: 'GAMEX',
    Symbol: 'GAMEX',
    CoinName: 'GameX',
    FullName: 'GameX (GAMEX)',
  },
  PARAL: {
    Name: 'PARAL',
    Symbol: 'PARAL',
    CoinName: 'Parallel',
    FullName: 'Parallel (PARAL)',
  },
  MOFOLD: {
    Name: 'MOFOLD',
    Symbol: 'MOFOLD',
    CoinName: 'Molecular Future (ERC20)',
    FullName: 'Molecular Future (ERC20) (MOFOLD)',
  },
  MONO: {
    Name: 'MONO',
    Symbol: 'MONO',
    CoinName: 'MonoX',
    FullName: 'MonoX (MONO)',
  },
  GMMT: {
    Name: 'GMMT',
    Symbol: 'GMMT',
    CoinName: 'Green Mining Movement Token',
    FullName: 'Green Mining Movement Token (GMMT)',
  },
  sOHM: {
    Name: 'sOHM',
    Symbol: 'sOHM',
    CoinName: 'Staked Olympus',
    FullName: 'Staked Olympus (sOHM)',
  },
  wsOHM: {
    Name: 'wsOHM',
    Symbol: 'wsOHM',
    CoinName: 'Wrapped Staked Olympus',
    FullName: 'Wrapped Staked Olympus (wsOHM)',
  },
  QUBE: {
    Name: 'QUBE',
    Symbol: 'QUBE',
    CoinName: 'Qube',
    FullName: 'Qube (QUBE)',
  },
  ENEDEX: {
    Name: 'ENEDEX',
    Symbol: 'ENEDEX',
    CoinName: 'Enedex',
    FullName: 'Enedex (ENEDEX)',
  },
  XPAY: {
    Name: 'XPAY',
    Symbol: 'XPAY',
    CoinName: 'Wallet Pay',
    FullName: 'Wallet Pay (XPAY)',
  },
  LUDO: {
    Name: 'LUDO',
    Symbol: 'LUDO',
    CoinName: 'Ludo',
    FullName: 'Ludo (LUDO)',
  },
  CWT: {
    Name: 'CWT',
    Symbol: 'CWT',
    CoinName: 'CrossWallet',
    FullName: 'CrossWallet (CWT)',
  },
  TSC: {
    Name: 'TSC',
    Symbol: 'TSC',
    CoinName: 'TrusterCoin',
    FullName: 'TrusterCoin (TSC)',
  },
  OHMV2: {
    Name: 'OHMv2',
    Symbol: 'OHMV2',
    CoinName: 'Olympus v2',
    FullName: 'Olympus v2 (OHMv2)',
  },
  NETCOIN: {
    Name: 'NETCOIN',
    Symbol: 'NETCOIN',
    CoinName: 'Netcoincapital',
    FullName: 'Netcoincapital (NETCOIN)',
  },
  LEOPARD: {
    Name: 'LEOPARD',
    Symbol: 'LEOPARD',
    CoinName: 'Leopard',
    FullName: 'Leopard (LEOPARD)',
  },
  MNET: {
    Name: 'MNET',
    Symbol: 'MNET',
    CoinName: 'MINE Network',
    FullName: 'MINE Network (MNET)',
  },
  LACE: {
    Name: 'LACE',
    Symbol: 'LACE',
    CoinName: 'Lovelace World',
    FullName: 'Lovelace World (LACE)',
  },
  VOXEL: {
    Name: 'VOXEL',
    Symbol: 'VOXEL',
    CoinName: 'Voxies',
    FullName: 'Voxies (VOXEL)',
  },
  RIFI: {
    Name: 'RIFI',
    Symbol: 'RIFI',
    CoinName: 'Rikkei Finance',
    FullName: 'Rikkei Finance (RIFI)',
  },
  UNB: {
    Name: 'UNB',
    Symbol: 'UNB',
    CoinName: 'Unbound Finance',
    FullName: 'Unbound Finance (UNB)',
  },
  ZMT: {
    Name: 'ZMT',
    Symbol: 'ZMT',
    CoinName: 'Zipmex Token',
    FullName: 'Zipmex Token (ZMT)',
  },
  SPOOL: {
    Name: 'SPOOL',
    Symbol: 'SPOOL',
    CoinName: 'Spool DAO Token',
    FullName: 'Spool DAO Token (SPOOL)',
  },
  CLNY: {
    Name: 'CLNY',
    Symbol: 'CLNY',
    CoinName: 'Colony',
    FullName: 'Colony (CLNY)',
  },
  FRDX: {
    Name: 'FRDX',
    Symbol: 'FRDX',
    CoinName: 'Frodo Tech',
    FullName: 'Frodo Tech (FRDX)',
  },
  TRVL: {
    Name: 'TRVL',
    Symbol: 'TRVL',
    CoinName: 'DTravel',
    FullName: 'DTravel (TRVL)',
  },
  ST: {
    Name: 'ST',
    Symbol: 'ST',
    CoinName: 'Skippy Token',
    FullName: 'Skippy Token (ST)',
  },
  ENO: {
    Name: 'ENO',
    Symbol: 'ENO',
    CoinName: 'Enotoken',
    FullName: 'Enotoken (ENO)',
  },
  CEM: {
    Name: 'CEM',
    Symbol: 'CEM',
    CoinName: 'Crypto Emergency',
    FullName: 'Crypto Emergency (CEM)',
  },
  CLVX: {
    Name: 'CLVX',
    Symbol: 'CLVX',
    CoinName: 'Calvex',
    FullName: 'Calvex (CLVX)',
  },
  CINU: {
    Name: 'CINU',
    Symbol: 'CINU',
    CoinName: 'CHEEMS INU',
    FullName: 'CHEEMS INU (CINU)',
  },
  RUX: {
    Name: 'RUX',
    Symbol: 'RUX',
    CoinName: 'Gacrux NFT',
    FullName: 'Gacrux NFT (RUX)',
  },
  TR3: {
    Name: 'TR3',
    Symbol: 'TR3',
    CoinName: 'Tr3zor',
    FullName: 'Tr3zor (TR3)',
  },
  AGV: {
    Name: 'AGV',
    Symbol: 'AGV',
    CoinName: 'Astra Guild Ventures',
    FullName: 'Astra Guild Ventures (AGV)',
  },
  BTRFLY: {
    Name: 'BTRFLY',
    Symbol: 'BTRFLY',
    CoinName: 'Redacted Cartel',
    FullName: 'Redacted Cartel (BTRFLY)',
  },
  ETHOS: {
    Name: 'ETHOS',
    Symbol: 'ETHOS',
    CoinName: 'Ethos Project',
    FullName: 'Ethos Project (ETHOS)',
  },
  BRGX: {
    Name: 'BRGX',
    Symbol: 'BRGX',
    CoinName: 'Bridge$',
    FullName: 'Bridge$ (BRGX)',
  },
  DESO: {
    Name: 'DESO',
    Symbol: 'DESO',
    CoinName: 'Decentralized Social',
    FullName: 'Decentralized Social (DESO)',
  },
  VLC: {
    Name: 'VLC',
    Symbol: 'VLC',
    CoinName: 'Volcano Uni',
    FullName: 'Volcano Uni (VLC)',
  },
  BTCBR: {
    Name: 'BTCBR',
    Symbol: 'BTCBR',
    CoinName: 'Bitcoin BR',
    FullName: 'Bitcoin BR (BTCBR)',
  },
  GOG: {
    Name: 'GOG',
    Symbol: 'GOG',
    CoinName: 'Guild of Guardians',
    FullName: 'Guild of Guardians (GOG)',
  },
  JFIN: {
    Name: 'JFIN',
    Symbol: 'JFIN',
    CoinName: 'JFIN Coin',
    FullName: 'JFIN Coin (JFIN)',
  },
  MCS: {
    Name: 'MCS',
    Symbol: 'MCS',
    CoinName: 'MCS Token',
    FullName: 'MCS Token (MCS)',
  },
  PDATA: {
    Name: 'PDATA',
    Symbol: 'PDATA',
    CoinName: 'PDATA',
    FullName: 'PDATA (PDATA)',
  },
  KIRO: {
    Name: 'KIRO',
    Symbol: 'KIRO',
    CoinName: 'Kirobo',
    FullName: 'Kirobo (KIRO)',
  },
  MNS: {
    Name: 'MNS',
    Symbol: 'MNS',
    CoinName: 'Monnos',
    FullName: 'Monnos (MNS)',
  },
  MHT: {
    Name: 'MHT',
    Symbol: 'MHT',
    CoinName: 'Mouse Haunt',
    FullName: 'Mouse Haunt (MHT)',
  },
  HP: {
    Name: 'HP',
    Symbol: 'HP',
    CoinName: 'HeroPark',
    FullName: 'HeroPark (HP)',
  },
  SFM: {
    Name: 'SFM',
    Symbol: 'SFM',
    CoinName: 'SafeMoon V2',
    FullName: 'SafeMoon V2 (SFM)',
  },
  HIGH: {
    Name: 'HIGH',
    Symbol: 'HIGH',
    CoinName: 'Highstreet',
    FullName: 'Highstreet (HIGH)',
  },
  E21: {
    Name: 'E21',
    Symbol: 'E21',
    CoinName: 'E21 Coin',
    FullName: 'E21 Coin (E21)',
  },
  NRV: {
    Name: 'NRV',
    Symbol: 'NRV',
    CoinName: 'Nerve Finance',
    FullName: 'Nerve Finance (NRV)',
  },
  WSB: {
    Name: 'WSB',
    Symbol: 'WSB',
    CoinName: 'WallStreetBets DApp',
    FullName: 'WallStreetBets DApp (WSB)',
  },
  GF: {
    Name: 'GF',
    Symbol: 'GF',
    CoinName: 'GuildFi',
    FullName: 'GuildFi (GF)',
  },
  DEVT: {
    Name: 'DEVT',
    Symbol: 'DEVT',
    CoinName: 'DeHorizon',
    FullName: 'DeHorizon (DEVT)',
  },
  STKAAVE: {
    Name: 'STKAAVE',
    Symbol: 'STKAAVE',
    CoinName: 'Staked Aave',
    FullName: 'Staked Aave (STKAAVE)',
  },
  STZEN: {
    Name: 'STZEN',
    Symbol: 'STZEN',
    CoinName: 'StakedZEN',
    FullName: 'StakedZEN (STZEN)',
  },
  AAA: {
    Name: 'AAA',
    Symbol: 'AAA',
    CoinName: 'Moon Rabbit',
    FullName: 'Moon Rabbit (AAA)',
  },
  MBS: {
    Name: 'MBS',
    Symbol: 'MBS',
    CoinName: 'MonkeyBall',
    FullName: 'MonkeyBall (MBS)',
  },
  DFL: {
    Name: 'DFL',
    Symbol: 'DFL',
    CoinName: 'DeFi Land',
    FullName: 'DeFi Land (DFL)',
  },
  IBFR: {
    Name: 'IBFR',
    Symbol: 'IBFR',
    CoinName: 'iBuffer Token',
    FullName: 'iBuffer Token (IBFR)',
  },
  CRF: {
    Name: 'CRF',
    Symbol: 'CRF',
    CoinName: 'Crafting Finance',
    FullName: 'Crafting Finance (CRF)',
  },
  R1: {
    Name: 'R1',
    Symbol: 'R1',
    CoinName: 'Recast1',
    FullName: 'Recast1 (R1)',
  },
  SHIBO: {
    Name: 'SHIBO',
    Symbol: 'SHIBO',
    CoinName: 'ShiBonk',
    FullName: 'ShiBonk (SHIBO)',
  },
  MSOL: {
    Name: 'MSOL',
    Symbol: 'MSOL',
    CoinName: 'Marinade Staked SOL',
    FullName: 'Marinade Staked SOL (MSOL)',
  },
  SECO: {
    Name: 'SECO',
    Symbol: 'SECO',
    CoinName: 'Serum Ecosystem Token',
    FullName: 'Serum Ecosystem Token (SECO)',
  },
  STSOL: {
    Name: 'STSOL',
    Symbol: 'STSOL',
    CoinName: 'Lido Staked SOL',
    FullName: 'Lido Staked SOL (STSOL)',
  },
  BLT: {
    Name: 'BLT',
    Symbol: 'BLT',
    CoinName: 'Blocto Token',
    FullName: 'Blocto Token (BLT)',
  },
  GATE: {
    Name: 'GATE',
    Symbol: 'GATE',
    CoinName: 'GATENet',
    FullName: 'GATENet (GATE)',
  },
  CPH: {
    Name: 'CPH',
    Symbol: 'CPH',
    CoinName: 'Cypherium',
    FullName: 'Cypherium (CPH)',
  },
  LUNR: {
    Name: 'LUNR',
    Symbol: 'LUNR',
    CoinName: 'Lunr Token',
    FullName: 'Lunr Token (LUNR)',
  },
  DIKO: {
    Name: 'DIKO',
    Symbol: 'DIKO',
    CoinName: 'Arkadiko',
    FullName: 'Arkadiko (DIKO)',
  },
  REALY: {
    Name: 'REALY',
    Symbol: 'REALY',
    CoinName: 'Realy Metaverse',
    FullName: 'Realy Metaverse (REALY)',
  },
  TULIP: {
    Name: 'TULIP',
    Symbol: 'TULIP',
    CoinName: 'Tulip Protocol',
    FullName: 'Tulip Protocol (TULIP)',
  },
  WOD: {
    Name: 'WOD',
    Symbol: 'WOD',
    CoinName: 'World of Defish',
    FullName: 'World of Defish (WOD)',
  },
  HACHIKO: {
    Name: 'HACHIKO',
    Symbol: 'HACHIKO',
    CoinName: 'Hachiko Inu Token',
    FullName: 'Hachiko Inu Token (HACHIKO)',
  },
  PONYO: {
    Name: 'PONYO',
    Symbol: 'PONYO',
    CoinName: 'Ponyo Impact',
    FullName: 'Ponyo Impact (PONYO)',
  },
  ASIA: {
    Name: 'ASIA',
    Symbol: 'ASIA',
    CoinName: 'Asia Coin',
    FullName: 'Asia Coin (ASIA)',
  },
  ZEROEX: {
    Name: 'ZEROEX',
    Symbol: 'ZEROEX',
    CoinName: '0.exchange',
    FullName: '0.exchange (ZEROEX)',
  },
  VPAD: {
    Name: 'VPAD',
    Symbol: 'VPAD',
    CoinName: 'VLaunch',
    FullName: 'VLaunch (VPAD)',
  },
  BTTOLD: {
    Name: 'BTTOLD',
    Symbol: 'BTTOLD',
    CoinName: 'BitTorrent',
    FullName: 'BitTorrent (BTTOLD)',
  },
  NYANTE: {
    Name: 'NYANTE',
    Symbol: 'NYANTE',
    CoinName: 'Nyantereum International',
    FullName: 'Nyantereum International (NYANTE)',
  },
  NNI: {
    Name: 'NNI',
    Symbol: 'NNI',
    CoinName: 'NeoNomad Exchange',
    FullName: 'NeoNomad Exchange (NNI)',
  },
  IMPACTXP: {
    Name: 'IMPACTXP',
    Symbol: 'IMPACTXP',
    CoinName: 'ImpactXP',
    FullName: 'ImpactXP (IMPACTXP)',
  },
  VADER: {
    Name: 'VADER',
    Symbol: 'VADER',
    CoinName: 'Vader Protocol',
    FullName: 'Vader Protocol (VADER)',
  },
  KASTA: {
    Name: 'KASTA',
    Symbol: 'KASTA',
    CoinName: 'Kasta',
    FullName: 'Kasta (KASTA)',
  },
  RDR: {
    Name: 'RDR',
    Symbol: 'RDR',
    CoinName: 'Rise of Defenders',
    FullName: 'Rise of Defenders (RDR)',
  },
  LUS: {
    Name: 'LUS',
    Symbol: 'LUS',
    CoinName: 'Luna Rush',
    FullName: 'Luna Rush (LUS)',
  },
  WNK: {
    Name: 'WNK',
    Symbol: 'WNK',
    CoinName: 'The Winkyverse',
    FullName: 'The Winkyverse (WNK)',
  },
  F2C: {
    Name: 'F2C',
    Symbol: 'F2C',
    CoinName: 'Ftribe Fighters',
    FullName: 'Ftribe Fighters (F2C)',
  },
  COVN: {
    Name: 'COVN',
    Symbol: 'COVN',
    CoinName: 'Covenant',
    FullName: 'Covenant (COVN)',
  },
  PHONON: {
    Name: 'PHONON',
    Symbol: 'PHONON',
    CoinName: 'Phonon DAO ',
    FullName: 'Phonon DAO  (PHONON)',
  },
  WEB3: {
    Name: 'WEB3',
    Symbol: 'WEB3',
    CoinName: 'WEB3 Inu',
    FullName: 'WEB3 Inu (WEB3)',
  },
  GAMMA: {
    Name: 'GAMMA',
    Symbol: 'GAMMA',
    CoinName: 'Gamma Strategies',
    FullName: 'Gamma Strategies (GAMMA)',
  },
  '1ART': {
    Name: '1ART',
    Symbol: '1ART',
    CoinName: 'ArtWallet',
    FullName: 'ArtWallet (1ART)',
  },
  COMT: {
    Name: 'COMT',
    Symbol: 'COMT',
    CoinName: 'Community Token',
    FullName: 'Community Token (COMT)',
  },
  GLMR: {
    Name: 'GLMR',
    Symbol: 'GLMR',
    CoinName: 'Moonbeam',
    FullName: 'Moonbeam (GLMR)',
  },
  ACCEL: {
    Name: 'ACCEL',
    Symbol: 'ACCEL',
    CoinName: 'Accel Defi',
    FullName: 'Accel Defi (ACCEL)',
  },
  OCT: {
    Name: 'OCT',
    Symbol: 'OCT',
    CoinName: 'Octopus Network',
    FullName: 'Octopus Network (OCT)',
  },
  KATA: {
    Name: 'KATA',
    Symbol: 'KATA',
    CoinName: 'Katana Inu',
    FullName: 'Katana Inu (KATA)',
  },
  GFI: {
    Name: 'GFI',
    Symbol: 'GFI',
    CoinName: 'Goldfinch',
    FullName: 'Goldfinch (GFI)',
  },
  KINT: {
    Name: 'KINT',
    Symbol: 'KINT',
    CoinName: 'Kintsugi',
    FullName: 'Kintsugi (KINT)',
  },
  ATOLO: {
    Name: 'ATOLO',
    Symbol: 'ATOLO',
    CoinName: 'RIZON',
    FullName: 'RIZON (ATOLO)',
  },
  WND: {
    Name: 'WND',
    Symbol: 'WND',
    CoinName: 'WonderHero',
    FullName: 'WonderHero (WND)',
  },
  SLC: {
    Name: 'SLC',
    Symbol: 'SLC',
    CoinName: 'Solice',
    FullName: 'Solice (SLC)',
  },
  POKT: {
    Name: 'POKT',
    Symbol: 'POKT',
    CoinName: 'Pocket Network',
    FullName: 'Pocket Network (POKT)',
  },
  SSG: {
    Name: 'SSG',
    Symbol: 'SSG',
    CoinName: 'Surviving Soldiers',
    FullName: 'Surviving Soldiers (SSG)',
  },
  JBX: {
    Name: 'JBX',
    Symbol: 'JBX',
    CoinName: 'Juicebox',
    FullName: 'Juicebox (JBX)',
  },
  ONLY: {
    Name: 'ONLY',
    Symbol: 'ONLY',
    CoinName: 'OnlyCam',
    FullName: 'OnlyCam (ONLY)',
  },
  VGO: {
    Name: 'VGO',
    Symbol: 'VGO',
    CoinName: 'Vagabond',
    FullName: 'Vagabond (VGO)',
  },
  DPS: {
    Name: 'DPS',
    Symbol: 'DPS',
    CoinName: 'DEEPSPACE',
    FullName: 'DEEPSPACE (DPS)',
  },
  BED: {
    Name: 'BED',
    Symbol: 'BED',
    CoinName: 'Bankless BED Index',
    FullName: 'Bankless BED Index (BED)',
  },
  NS2DRP: {
    Name: 'NS2DRP',
    Symbol: 'NS2DRP',
    CoinName: 'New Silver Series 2 DROP',
    FullName: 'New Silver Series 2 DROP (NS2DRP)',
  },
  ZAM: {
    Name: 'ZAM',
    Symbol: 'ZAM',
    CoinName: 'Zamio',
    FullName: 'Zamio (ZAM)',
  },
  TRADE: {
    Name: 'TRADE',
    Symbol: 'TRADE',
    CoinName: 'Polytrade',
    FullName: 'Polytrade (TRADE)',
  },
  LIFE: {
    Name: 'LIFE',
    Symbol: 'LIFE',
    CoinName: 'Life Crypto',
    FullName: 'Life Crypto (LIFE)',
  },
  ASTRO: {
    Name: 'ASTRO',
    Symbol: 'ASTRO',
    CoinName: 'AstroSwap',
    FullName: 'AstroSwap (ASTRO)',
  },
  EGC: {
    Name: 'EGC',
    Symbol: 'EGC',
    CoinName: 'EverGrowCoin',
    FullName: 'EverGrowCoin (EGC)',
  },
  MTBC: {
    Name: 'MTBC',
    Symbol: 'MTBC',
    CoinName: 'Metabolic',
    FullName: 'Metabolic (MTBC)',
  },
  SHINJA: {
    Name: 'SHINJA',
    Symbol: 'SHINJA',
    CoinName: 'Shibnobi',
    FullName: 'Shibnobi (SHINJA)',
  },
  AVO: {
    Name: 'AVO',
    Symbol: 'AVO',
    CoinName: 'Avoteo',
    FullName: 'Avoteo (AVO)',
  },
  SATS: {
    Name: 'SATS',
    Symbol: 'SATS',
    CoinName: 'Satoshi',
    FullName: 'Satoshi (SATS)',
  },
  IONZ: {
    Name: 'IONZ',
    Symbol: 'IONZ',
    CoinName: 'IONZ',
    FullName: 'IONZ (IONZ)',
  },
  SOLARIX: {
    Name: 'SOLARIX',
    Symbol: 'SOLARIX',
    CoinName: 'SOLARIX',
    FullName: 'SOLARIX (SOLARIX)',
  },
  LOOKS: {
    Name: 'LOOKS',
    Symbol: 'LOOKS',
    CoinName: 'LooksRare',
    FullName: 'LooksRare (LOOKS)',
  },
  OSMO: {
    Name: 'OSMO',
    Symbol: 'OSMO',
    CoinName: 'Osmosis',
    FullName: 'Osmosis (OSMO)',
  },
  OXD: {
    Name: 'OXD',
    Symbol: 'OXD',
    CoinName: '0xDAO',
    FullName: '0xDAO (OXD)',
  },
  BLOCK: {
    Name: 'BLOCK',
    Symbol: 'BLOCK',
    CoinName: 'Blockasset',
    FullName: 'Blockasset (BLOCK)',
  },
  ASH: {
    Name: 'ASH',
    Symbol: 'ASH',
    CoinName: 'ASH',
    FullName: 'ASH (ASH)',
  },
  ABEY: {
    Name: 'ABEY',
    Symbol: 'ABEY',
    CoinName: 'Abey',
    FullName: 'Abey (ABEY)',
  },
  PLI: {
    Name: 'PLI',
    Symbol: 'PLI',
    CoinName: 'Plugin',
    FullName: 'Plugin (PLI)',
  },
  VOY: {
    Name: 'VOY',
    Symbol: 'VOY',
    CoinName: 'enVoy DeFi',
    FullName: 'enVoy DeFi (VOY)',
  },
  ACA: {
    Name: 'ACA',
    Symbol: 'ACA',
    CoinName: 'Acala',
    FullName: 'Acala (ACA)',
  },
  ASTR: {
    Name: 'ASTR',
    Symbol: 'ASTR',
    CoinName: 'Astar',
    FullName: 'Astar (ASTR)',
  },
  PROP: {
    Name: 'PROP',
    Symbol: 'PROP',
    CoinName: 'Propeller',
    FullName: 'Propeller (PROP)',
  },
  LOVE: {
    Name: 'LOVE',
    Symbol: 'LOVE',
    CoinName: 'Deesse',
    FullName: 'Deesse (LOVE)',
  },
  GARI: {
    Name: 'GARI',
    Symbol: 'GARI',
    CoinName: 'Gari Network',
    FullName: 'Gari Network (GARI)',
  },
  MPT: {
    Name: 'MPT',
    Symbol: 'MPT',
    CoinName: 'Meetple',
    FullName: 'Meetple (MPT)',
  },
  CHMB: {
    Name: 'CHMB',
    Symbol: 'CHMB',
    CoinName: 'Chumbi Valley',
    FullName: 'Chumbi Valley (CHMB)',
  },
  FREEROSS: {
    Name: 'FREEROSS',
    Symbol: 'FREEROSS',
    CoinName: 'FreeRossDAO',
    FullName: 'FreeRossDAO (FREEROSS)',
  },
  REAL: {
    Name: 'REAL',
    Symbol: 'REAL',
    CoinName: 'RealLink',
    FullName: 'RealLink (REAL)',
  },
  IXS: {
    Name: 'IXS',
    Symbol: 'IXS',
    CoinName: 'IX Swap',
    FullName: 'IX Swap (IXS)',
  },
  GMTT: {
    Name: 'GMTT',
    Symbol: 'GMTT',
    CoinName: 'GMT Token',
    FullName: 'GMT Token (GMTT)',
  },
  MYCE: {
    Name: 'MYCE',
    Symbol: 'MYCE',
    CoinName: 'MY Ceremonial Event',
    FullName: 'MY Ceremonial Event (MYCE)',
  },
  ARKER: {
    Name: 'ARKER',
    Symbol: 'ARKER',
    CoinName: 'Arker',
    FullName: 'Arker (ARKER)',
  },
  MAKI: {
    Name: 'MAKI',
    Symbol: 'MAKI',
    CoinName: 'MakiSwap',
    FullName: 'MakiSwap (MAKI)',
  },
  NTVRK: {
    Name: 'NTVRK',
    Symbol: 'NTVRK',
    CoinName: 'Netvrk',
    FullName: 'Netvrk (NTVRK)',
  },
  FTG: {
    Name: 'FTG',
    Symbol: 'FTG',
    CoinName: 'fantomGO',
    FullName: 'fantomGO (FTG)',
  },
  ZKT: {
    Name: 'ZKT',
    Symbol: 'ZKT',
    CoinName: 'zkTube',
    FullName: 'zkTube (ZKT)',
  },
  PEL: {
    Name: 'PEL',
    Symbol: 'PEL',
    CoinName: 'Propel Token',
    FullName: 'Propel Token (PEL)',
  },
  SOLR: {
    Name: 'SOLR',
    Symbol: 'SOLR',
    CoinName: 'SolRazr',
    FullName: 'SolRazr (SOLR)',
  },
  CLH: {
    Name: 'CLH',
    Symbol: 'CLH',
    CoinName: 'ClearDAO',
    FullName: 'ClearDAO (CLH)',
  },
  CREDI: {
    Name: 'CREDI',
    Symbol: 'CREDI',
    CoinName: 'Credefi',
    FullName: 'Credefi (CREDI)',
  },
  RACEFI: {
    Name: 'RACEFI',
    Symbol: 'RACEFI',
    CoinName: 'RaceFi',
    FullName: 'RaceFi (RACEFI)',
  },
  CWEB: {
    Name: 'CWEB',
    Symbol: 'CWEB',
    CoinName: 'Coinweb',
    FullName: 'Coinweb (CWEB)',
  },
  DAPPX: {
    Name: 'DAPPX',
    Symbol: 'DAPPX',
    CoinName: 'dAppstore',
    FullName: 'dAppstore (DAPPX)',
  },
  DRONE: {
    Name: 'DRONE',
    Symbol: 'DRONE',
    CoinName: 'Drone Coin',
    FullName: 'Drone Coin (DRONE)',
  },
  ORCA: {
    Name: 'ORCA',
    Symbol: 'ORCA',
    CoinName: 'Orca',
    FullName: 'Orca (ORCA)',
  },
  '1PECO': {
    Name: '1PECO',
    Symbol: '1PECO',
    CoinName: '1peco',
    FullName: '1peco (1PECO)',
  },
  APXP: {
    Name: 'APXP',
    Symbol: 'APXP',
    CoinName: 'APEX Protocol',
    FullName: 'APEX Protocol (APXP)',
  },
  ARTII: {
    Name: 'ARTII',
    Symbol: 'ARTII',
    CoinName: 'ARTII Token',
    FullName: 'ARTII Token (ARTII)',
  },
  B2M: {
    Name: 'B2M',
    Symbol: 'B2M',
    CoinName: 'Bit2Me',
    FullName: 'Bit2Me (B2M)',
  },
  CHN: {
    Name: 'CHN',
    Symbol: 'CHN',
    CoinName: 'Chain',
    FullName: 'Chain (CHN)',
  },
  CHNG: {
    Name: 'CHNG',
    Symbol: 'CHNG',
    CoinName: 'Chainge Finance',
    FullName: 'Chainge Finance (CHNG)',
  },
  CIND: {
    Name: 'CIND',
    Symbol: 'CIND',
    CoinName: 'Cindrum',
    FullName: 'Cindrum (CIND)',
  },
  CRFI: {
    Name: 'CRFI',
    Symbol: 'CRFI',
    CoinName: 'CrossFi',
    FullName: 'CrossFi (CRFI)',
  },
  LPNT: {
    Name: 'LPNT',
    Symbol: 'LPNT',
    CoinName: 'Luxurious Pro Network Token',
    FullName: 'Luxurious Pro Network Token (LPNT)',
  },
  MBET: {
    Name: 'MBET',
    Symbol: 'MBET',
    CoinName: 'MoonBet',
    FullName: 'MoonBet (MBET)',
  },
  TONIC: {
    Name: 'TONIC',
    Symbol: 'TONIC',
    CoinName: 'Tectonic',
    FullName: 'Tectonic (TONIC)',
  },
  SNS: {
    Name: 'SNS',
    Symbol: 'SNS',
    CoinName: 'Synesis One',
    FullName: 'Synesis One (SNS)',
  },
  HBB: {
    Name: 'HBB',
    Symbol: 'HBB',
    CoinName: 'Hubble',
    FullName: 'Hubble (HBB)',
  },
  ATS: {
    Name: 'ATS',
    Symbol: 'ATS',
    CoinName: 'Atlas DEX',
    FullName: 'Atlas DEX (ATS)',
  },
  TRACE: {
    Name: 'TRACE',
    Symbol: 'TRACE',
    CoinName: 'Trace Network Labs',
    FullName: 'Trace Network Labs (TRACE)',
  },
  NT: {
    Name: 'NT',
    Symbol: 'NT',
    CoinName: 'NEXTYPE Finance',
    FullName: 'NEXTYPE Finance (NT)',
  },
  WALLET: {
    Name: 'WALLET',
    Symbol: 'WALLET',
    CoinName: 'Ambire Wallet',
    FullName: 'Ambire Wallet (WALLET)',
  },
  LOOTEX: {
    Name: 'LOOTEX',
    Symbol: 'LOOTEX',
    CoinName: 'Lootex',
    FullName: 'Lootex (LOOTEX)',
  },
  NINKY: {
    Name: 'NINKY',
    Symbol: 'NINKY',
    CoinName: 'Ninky',
    FullName: 'Ninky (NINKY)',
  },
  BPAD: {
    Name: 'BPAD',
    Symbol: 'BPAD',
    CoinName: 'BlokPad',
    FullName: 'BlokPad (BPAD)',
  },
  XAEAXII: {
    Name: 'XAEAXII',
    Symbol: 'XAEAXII',
    CoinName: 'XAEA-Xii Token',
    FullName: 'XAEA-Xii Token (XAEAXII)',
  },
  DIFX: {
    Name: 'DIFX',
    Symbol: 'DIFX',
    CoinName: 'Digital Financial Exchange',
    FullName: 'Digital Financial Exchange (DIFX)',
  },
  BLINU: {
    Name: 'BLINU',
    Symbol: 'BLINU',
    CoinName: 'Baby Lambo Inu',
    FullName: 'Baby Lambo Inu (BLINU)',
  },
  CHEESUS: {
    Name: 'CHEESUS',
    Symbol: 'CHEESUS',
    CoinName: 'Cheesus',
    FullName: 'Cheesus (CHEESUS)',
  },
  RADR: {
    Name: 'RADR',
    Symbol: 'RADR',
    CoinName: 'CoinRadr',
    FullName: 'CoinRadr (RADR)',
  },
  BAOE: {
    Name: 'BAOE',
    Symbol: 'BAOE',
    CoinName: 'Business Age of Empires',
    FullName: 'Business Age of Empires (BAOE)',
  },
  PERC: {
    Name: 'PERC',
    Symbol: 'PERC',
    CoinName: 'Perion',
    FullName: 'Perion (PERC)',
  },
  ONSTON: {
    Name: 'ONSTON',
    Symbol: 'ONSTON',
    CoinName: 'Onston',
    FullName: 'Onston (ONSTON)',
  },
  XLD: {
    Name: 'XLD',
    Symbol: 'XLD',
    CoinName: 'Xcel Defi',
    FullName: 'Xcel Defi (XLD)',
  },
  ENTC: {
    Name: 'ENTC',
    Symbol: 'ENTC',
    CoinName: 'EnterButton',
    FullName: 'EnterButton (ENTC)',
  },
  KOM: {
    Name: 'KOM',
    Symbol: 'KOM',
    CoinName: 'Kommunitas',
    FullName: 'Kommunitas (KOM)',
  },
  CFXT: {
    Name: 'CFXT',
    Symbol: 'CFXT',
    CoinName: 'Chainflix',
    FullName: 'Chainflix (CFXT)',
  },
  KINGSHIB: {
    Name: 'KINGSHIB',
    Symbol: 'KINGSHIB',
    CoinName: 'King Shiba',
    FullName: 'King Shiba (KINGSHIB)',
  },
  PAF: {
    Name: 'PAF',
    Symbol: 'PAF',
    CoinName: 'Pacific',
    FullName: 'Pacific (PAF)',
  },
  CLIFF: {
    Name: 'CLIFF',
    Symbol: 'CLIFF',
    CoinName: 'Clifford Inu',
    FullName: 'Clifford Inu (CLIFF)',
  },
  FTS: {
    Name: 'FTS',
    Symbol: 'FTS',
    CoinName: 'Fortress Lending',
    FullName: 'Fortress Lending (FTS)',
  },
  CTPL: {
    Name: 'CTPL',
    Symbol: 'CTPL',
    CoinName: 'Cultiplan',
    FullName: 'Cultiplan (CTPL)',
  },
  EPTT: {
    Name: 'EPTT',
    Symbol: 'EPTT',
    CoinName: 'Evident Proof Transaction Token',
    FullName: 'Evident Proof Transaction Token (EPTT)',
  },
  EWC: {
    Name: 'EWC',
    Symbol: 'EWC',
    CoinName: 'Erugo World Coin',
    FullName: 'Erugo World Coin (EWC)',
  },
  ALKI: {
    Name: 'ALKI',
    Symbol: 'ALKI',
    CoinName: 'Alkimi',
    FullName: 'Alkimi (ALKI)',
  },
  LIKE: {
    Name: 'LIKE',
    Symbol: 'LIKE',
    CoinName: 'Only1',
    FullName: 'Only1 (LIKE)',
  },
  VDV: {
    Name: 'VDV',
    Symbol: 'VDV',
    CoinName: 'VDV Token',
    FullName: 'VDV Token (VDV)',
  },
  TYCOON: {
    Name: 'TYCOON',
    Symbol: 'TYCOON',
    CoinName: 'CryptoTycoon',
    FullName: 'CryptoTycoon (TYCOON)',
  },
  JCC: {
    Name: 'JCC',
    Symbol: 'JCC',
    CoinName: 'Junca Cash',
    FullName: 'Junca Cash (JCC)',
  },
  ICA: {
    Name: 'ICA',
    Symbol: 'ICA',
    CoinName: 'Icarus Network',
    FullName: 'Icarus Network (ICA)',
  },
  KRRX: {
    Name: 'KRRX',
    Symbol: 'KRRX',
    CoinName: 'Kyrrex',
    FullName: 'Kyrrex (KRRX)',
  },
  MF1: {
    Name: 'MF1',
    Symbol: 'MF1',
    CoinName: 'Meta Finance',
    FullName: 'Meta Finance (MF1)',
  },
  MQL: {
    Name: 'MQL',
    Symbol: 'MQL',
    CoinName: 'MiraQle',
    FullName: 'MiraQle (MQL)',
  },
  NKCLC: {
    Name: 'NKCLC',
    Symbol: 'NKCLC',
    CoinName: 'NKCL Classic',
    FullName: 'NKCL Classic (NKCLC)',
  },
  SBT: {
    Name: 'SBT',
    Symbol: 'SBT',
    CoinName: 'SOLBIT',
    FullName: 'SOLBIT (SBT)',
  },
  YEFI: {
    Name: 'YEFI',
    Symbol: 'YEFI',
    CoinName: 'YeFi',
    FullName: 'YeFi (YEFI)',
  },
  ZPTC: {
    Name: 'ZPTC',
    Symbol: 'ZPTC',
    CoinName: 'Zeptacoin',
    FullName: 'Zeptacoin (ZPTC)',
  },
  EGI: {
    Name: 'EGI',
    Symbol: 'EGI',
    CoinName: 'eGame',
    FullName: 'eGame (EGI)',
  },
  MIKS: {
    Name: 'MIKS',
    Symbol: 'MIKS',
    CoinName: 'MIKS COIN',
    FullName: 'MIKS COIN (MIKS)',
  },
  SURV: {
    Name: 'SURV',
    Symbol: 'SURV',
    CoinName: 'Survival Game Online',
    FullName: 'Survival Game Online (SURV)',
  },
  FALCONS: {
    Name: 'FALCONS',
    Symbol: 'FALCONS',
    CoinName: 'Falcon Swaps',
    FullName: 'Falcon Swaps (FALCONS)',
  },
  ROAR: {
    Name: 'ROAR',
    Symbol: 'ROAR',
    CoinName: 'Alpha DEX',
    FullName: 'Alpha DEX (ROAR)',
  },
  MTS: {
    Name: 'MTS',
    Symbol: 'MTS',
    CoinName: 'Metastrike',
    FullName: 'Metastrike (MTS)',
  },
  ERTHA: {
    Name: 'ERTHA',
    Symbol: 'ERTHA',
    CoinName: 'Ertha',
    FullName: 'Ertha (ERTHA)',
  },
  MMPRO: {
    Name: 'MMPRO',
    Symbol: 'MMPRO',
    CoinName: 'Market Making Pro',
    FullName: 'Market Making Pro (MMPRO)',
  },
  FSHN: {
    Name: 'FSHN',
    Symbol: 'FSHN',
    CoinName: 'Fashion Coin',
    FullName: 'Fashion Coin (FSHN)',
  },
  SVS: {
    Name: 'SVS',
    Symbol: 'SVS',
    CoinName: 'GivingToServices SVS',
    FullName: 'GivingToServices SVS (SVS)',
  },
  STON: {
    Name: 'STON',
    Symbol: 'STON',
    CoinName: 'Ston',
    FullName: 'Ston (STON)',
  },
  DHS: {
    Name: 'DHS',
    Symbol: 'DHS',
    CoinName: 'Dirham Crypto',
    FullName: 'Dirham Crypto (DHS)',
  },
  MVP: {
    Name: 'MVP',
    Symbol: 'MVP',
    CoinName: 'MVP Coin',
    FullName: 'MVP Coin (MVP)',
  },
  DEPO: {
    Name: 'DEPO',
    Symbol: 'DEPO',
    CoinName: 'Depo',
    FullName: 'Depo (DEPO)',
  },
  MONS: {
    Name: 'MONS',
    Symbol: 'MONS',
    CoinName: 'Monsters Clan',
    FullName: 'Monsters Clan (MONS)',
  },
  PLASTIK: {
    Name: 'PLASTIK',
    Symbol: 'PLASTIK',
    CoinName: 'Plastiks',
    FullName: 'Plastiks (PLASTIK)',
  },
  LBLOCK: {
    Name: 'LBLOCK',
    Symbol: 'LBLOCK',
    CoinName: 'Lucky Block',
    FullName: 'Lucky Block (LBLOCK)',
  },
  TRAVA: {
    Name: 'TRAVA',
    Symbol: 'TRAVA',
    CoinName: 'Trava Finance',
    FullName: 'Trava Finance (TRAVA)',
  },
  ONLYCUMIES: {
    Name: 'ONLYCUMIES',
    Symbol: 'ONLYCUMIES',
    CoinName: 'OnlyCumies',
    FullName: 'OnlyCumies (ONLYCUMIES)',
  },
  OPENRI: {
    Name: 'OPENRI',
    Symbol: 'OPENRI',
    CoinName: 'Open Rights Exchange',
    FullName: 'Open Rights Exchange (OPENRI)',
  },
  YUMMY: {
    Name: 'YUMMY',
    Symbol: 'YUMMY',
    CoinName: 'Yummy',
    FullName: 'Yummy (YUMMY)',
  },
  BRISE: {
    Name: 'BRISE',
    Symbol: 'BRISE',
    CoinName: 'Bitgert',
    FullName: 'Bitgert (BRISE)',
  },
  VODKA: {
    Name: 'VODKA',
    Symbol: 'VODKA',
    CoinName: 'Vodka Token',
    FullName: 'Vodka Token (VODKA)',
  },
  MRFOX: {
    Name: 'MRFOX',
    Symbol: 'MRFOX',
    CoinName: 'Mr.FOX Token',
    FullName: 'Mr.FOX Token (MRFOX)',
  },
  LEXI: {
    Name: 'LEXI',
    Symbol: 'LEXI',
    CoinName: 'LEXIT',
    FullName: 'LEXIT (LEXI)',
  },
  LOVELY: {
    Name: 'LOVELY',
    Symbol: 'LOVELY',
    CoinName: 'Lovely Inu finance',
    FullName: 'Lovely Inu finance (LOVELY)',
  },
  AFEN: {
    Name: 'AFEN',
    Symbol: 'AFEN',
    CoinName: 'AFEN Blockchain',
    FullName: 'AFEN Blockchain (AFEN)',
  },
  TCY: {
    Name: 'TCY',
    Symbol: 'TCY',
    CoinName: 'The Crypto You',
    FullName: 'The Crypto You (TCY)',
  },
  AVG: {
    Name: 'AVG',
    Symbol: 'AVG',
    CoinName: 'Avocado DAO',
    FullName: 'Avocado DAO (AVG)',
  },
  WRK: {
    Name: 'WRK',
    Symbol: 'WRK',
    CoinName: 'BlockWRK',
    FullName: 'BlockWRK (WRK)',
  },
  CODI: {
    Name: 'CODI',
    Symbol: 'CODI',
    CoinName: 'Codi Finance',
    FullName: 'Codi Finance (CODI)',
  },
  ACU: {
    Name: 'ACU',
    Symbol: 'ACU',
    CoinName: 'ACU Platform',
    FullName: 'ACU Platform (ACU)',
  },
  BEPR: {
    Name: 'BEPR',
    Symbol: 'BEPR',
    CoinName: 'Blockchain Euro Project',
    FullName: 'Blockchain Euro Project (BEPR)',
  },
  APP: {
    Name: 'APP',
    Symbol: 'APP',
    CoinName: 'SappChat',
    FullName: 'SappChat (APP)',
  },
  THT: {
    Name: 'THT',
    Symbol: 'THT',
    CoinName: 'Thought',
    FullName: 'Thought (THT)',
  },
  WEC: {
    Name: 'WEC',
    Symbol: 'WEC',
    CoinName: 'Whole Earth Coin',
    FullName: 'Whole Earth Coin (WEC)',
  },
  ZONO: {
    Name: 'ZONO',
    Symbol: 'ZONO',
    CoinName: 'Zono Swap',
    FullName: 'Zono Swap (ZONO)',
  },
  NFTYP: {
    Name: 'NFTYP',
    Symbol: 'NFTYP',
    CoinName: 'NFTY DeFi Protocol',
    FullName: 'NFTY DeFi Protocol (NFTYP)',
  },
  BHAX: {
    Name: 'BHAX',
    Symbol: 'BHAX',
    CoinName: 'Bithashex',
    FullName: 'Bithashex (BHAX)',
  },
  YUCT: {
    Name: 'YUCT',
    Symbol: 'YUCT',
    CoinName: 'Yucreat',
    FullName: 'Yucreat (YUCT)',
  },
  CSOV: {
    Name: 'CSOV',
    Symbol: 'CSOV',
    CoinName: 'Crown Sovereign',
    FullName: 'Crown Sovereign (CSOV)',
  },
  CART: {
    Name: 'CART',
    Symbol: 'CART',
    CoinName: 'CryptoArt.Ai',
    FullName: 'CryptoArt.Ai (CART)',
  },
  PDX: {
    Name: 'PDX',
    Symbol: 'PDX',
    CoinName: 'PDX Coin',
    FullName: 'PDX Coin (PDX)',
  },
  USNOTA: {
    Name: 'USNOTA',
    Symbol: 'USNOTA',
    CoinName: 'NOTA',
    FullName: 'NOTA (USNOTA)',
  },
  VIBLO: {
    Name: 'VIBLO',
    Symbol: 'VIBLO',
    CoinName: 'VIBLO',
    FullName: 'VIBLO (VIBLO)',
  },
  MGT: {
    Name: 'MGT',
    Symbol: 'MGT',
    CoinName: 'Megatech',
    FullName: 'Megatech (MGT)',
  },
  SHOP: {
    Name: 'SHOP',
    Symbol: 'SHOP',
    CoinName: 'Shoppi Coin',
    FullName: 'Shoppi Coin (SHOP)',
  },
  UPI: {
    Name: 'UPI',
    Symbol: 'UPI',
    CoinName: 'Pawtocol',
    FullName: 'Pawtocol (UPI)',
  },
  VOX: {
    Name: 'VOX',
    Symbol: 'VOX',
    CoinName: 'Vox.Finance',
    FullName: 'Vox.Finance (VOX)',
  },
  UNW: {
    Name: 'UNW',
    Symbol: 'UNW',
    CoinName: 'UniWorld',
    FullName: 'UniWorld (UNW)',
  },
  KALAM: {
    Name: 'KALAM',
    Symbol: 'KALAM',
    CoinName: 'Kalamint',
    FullName: 'Kalamint (KALAM)',
  },
  SAP: {
    Name: 'SAP',
    Symbol: 'SAP',
    CoinName: 'SwapAll',
    FullName: 'SwapAll (SAP)',
  },
  GNNX: {
    Name: 'GNNX',
    Symbol: 'GNNX',
    CoinName: 'Gennix',
    FullName: 'Gennix (GNNX)',
  },
  PLEX: {
    Name: 'PLEX',
    Symbol: 'PLEX',
    CoinName: 'PLEX',
    FullName: 'PLEX (PLEX)',
  },
  KAINET: {
    Name: 'KAINET',
    Symbol: 'KAINET',
    CoinName: 'KAINET',
    FullName: 'KAINET (KAINET)',
  },
  UDO: {
    Name: 'UDO',
    Symbol: 'UDO',
    CoinName: 'Unido',
    FullName: 'Unido (UDO)',
  },
  I7: {
    Name: 'I7',
    Symbol: 'I7',
    CoinName: 'ImpulseVen',
    FullName: 'ImpulseVen (I7)',
  },
  PTD: {
    Name: 'PTD',
    Symbol: 'PTD',
    CoinName: 'Pilot',
    FullName: 'Pilot (PTD)',
  },
  REFI: {
    Name: 'REFI',
    Symbol: 'REFI',
    CoinName: 'Realfinance Network',
    FullName: 'Realfinance Network (REFI)',
  },
  UPR: {
    Name: 'UPR',
    Symbol: 'UPR',
    CoinName: 'Upfire',
    FullName: 'Upfire (UPR)',
  },
  OPENDAOSOS: {
    Name: 'OPENDAOSOS',
    Symbol: 'OPENDAOSOS',
    CoinName: 'OpenDAO',
    FullName: 'OpenDAO (OPENDAOSOS)',
  },
  HEP: {
    Name: 'HEP',
    Symbol: 'HEP',
    CoinName: 'Health Potion',
    FullName: 'Health Potion (HEP)',
  },
  REVON: {
    Name: 'REVON',
    Symbol: 'REVON',
    CoinName: 'RevoNetwork',
    FullName: 'RevoNetwork (REVON)',
  },
  AWT: {
    Name: 'AWT',
    Symbol: 'AWT',
    CoinName: 'Airdrop World',
    FullName: 'Airdrop World (AWT)',
  },
  VERA: {
    Name: 'VERA',
    Symbol: 'VERA',
    CoinName: 'Vera',
    FullName: 'Vera (VERA)',
  },
  GHD: {
    Name: 'GHD',
    Symbol: 'GHD',
    CoinName: 'Giftedhands',
    FullName: 'Giftedhands (GHD)',
  },
  ANTEX: {
    Name: 'ANTEX',
    Symbol: 'ANTEX',
    CoinName: 'Antex',
    FullName: 'Antex (ANTEX)',
  },
  MEL: {
    Name: 'MEL',
    Symbol: 'MEL',
    CoinName: 'MELX',
    FullName: 'MELX (MEL)',
  },
  KIAN: {
    Name: 'KIAN',
    Symbol: 'KIAN',
    CoinName: 'Porta',
    FullName: 'Porta (KIAN)',
  },
  ZUKI: {
    Name: 'ZUKI',
    Symbol: 'ZUKI',
    CoinName: 'Zuki Moba',
    FullName: 'Zuki Moba (ZUKI)',
  },
  OOGI: {
    Name: 'OOGI',
    Symbol: 'OOGI',
    CoinName: 'OOGI',
    FullName: 'OOGI (OOGI)',
  },
  TWIN: {
    Name: 'TWIN',
    Symbol: 'TWIN',
    CoinName: 'Twinci',
    FullName: 'Twinci (TWIN)',
  },
  IJC: {
    Name: 'IJC',
    Symbol: 'IJC',
    CoinName: 'IjasCoin',
    FullName: 'IjasCoin (IJC)',
  },
  PIGGY: {
    Name: 'PIGGY',
    Symbol: 'PIGGY',
    CoinName: 'Piggy',
    FullName: 'Piggy (PIGGY)',
  },
  UNBNK: {
    Name: 'UNBNK',
    Symbol: 'UNBNK',
    CoinName: 'Unbanked',
    FullName: 'Unbanked (UNBNK)',
  },
  ALD: {
    Name: 'ALD',
    Symbol: 'ALD',
    CoinName: 'AladdinDAO',
    FullName: 'AladdinDAO (ALD)',
  },
  TKING: {
    Name: 'TKING',
    Symbol: 'TKING',
    CoinName: 'Tiger King',
    FullName: 'Tiger King (TKING)',
  },
  LORD: {
    Name: 'LORD',
    Symbol: 'LORD',
    CoinName: 'Overlord',
    FullName: 'Overlord (LORD)',
  },
  DDDD: {
    Name: 'DDDD',
    Symbol: 'DDDD',
    CoinName: "People's Punk",
    FullName: "People's Punk (DDDD)",
  },
  INFTT: {
    Name: 'INFTT',
    Symbol: 'INFTT',
    CoinName: 'iNFT Token',
    FullName: 'iNFT Token (INFTT)',
  },
  DASHD: {
    Name: 'DASHD',
    Symbol: 'DASHD',
    CoinName: 'Dash Diamond',
    FullName: 'Dash Diamond (DASHD)',
  },
  MOO: {
    Name: 'MOO',
    Symbol: 'MOO',
    CoinName: 'MooMonster',
    FullName: 'MooMonster (MOO)',
  },
  NEXM: {
    Name: 'NEXM',
    Symbol: 'NEXM',
    CoinName: 'Nexum',
    FullName: 'Nexum (NEXM)',
  },
  PKN: {
    Name: 'PKN',
    Symbol: 'PKN',
    CoinName: 'Poken',
    FullName: 'Poken (PKN)',
  },
  HEC: {
    Name: 'HEC',
    Symbol: 'HEC',
    CoinName: 'Hector Finance',
    FullName: 'Hector Finance (HEC)',
  },
  VINU: {
    Name: 'VINU',
    Symbol: 'VINU',
    CoinName: 'Vita Inu',
    FullName: 'Vita Inu (VINU)',
  },
  RADAR: {
    Name: 'RADAR',
    Symbol: 'RADAR',
    CoinName: 'DappRadar',
    FullName: 'DappRadar (RADAR)',
  },
  BLUESPARROW: {
    Name: 'BLUESPARROW',
    Symbol: 'BLUESPARROW',
    CoinName: 'BlueSparrow Token',
    FullName: 'BlueSparrow Token (BLUESPARROW)',
  },
  ASPO: {
    Name: 'ASPO',
    Symbol: 'ASPO',
    CoinName: 'ASPO Shards',
    FullName: 'ASPO Shards (ASPO)',
  },
  SENATE: {
    Name: 'SENATE',
    Symbol: 'SENATE',
    CoinName: 'SENATE',
    FullName: 'SENATE (SENATE)',
  },
  BSI: {
    Name: 'BSI',
    Symbol: 'BSI',
    CoinName: 'Bali Social Integrated',
    FullName: 'Bali Social Integrated (BSI)',
  },
  VYNC: {
    Name: 'VYNC',
    Symbol: 'VYNC',
    CoinName: 'VYNK Chain',
    FullName: 'VYNK Chain (VYNC)',
  },
  LIQUI: {
    Name: 'LIQUI',
    Symbol: 'LIQUI',
    CoinName: 'Liquidus',
    FullName: 'Liquidus (LIQUI)',
  },
  EGR: {
    Name: 'EGR',
    Symbol: 'EGR',
    CoinName: 'Egoras Rights',
    FullName: 'Egoras Rights (EGR)',
  },
  GAMINGSHIBA: {
    Name: 'GAMINGSHIBA',
    Symbol: 'GAMINGSHIBA',
    CoinName: 'GamingShiba',
    FullName: 'GamingShiba (GAMINGSHIBA)',
  },
  DEVO: {
    Name: 'DEVO',
    Symbol: 'DEVO',
    CoinName: 'DeVolution',
    FullName: 'DeVolution (DEVO)',
  },
  RETIRE: {
    Name: 'RETIRE',
    Symbol: 'RETIRE',
    CoinName: 'Retire Token',
    FullName: 'Retire Token (RETIRE)',
  },
  T: {
    Name: 'T',
    Symbol: 'T',
    CoinName: 'Threshold Network Token',
    FullName: 'Threshold Network Token (T)',
  },
  ERON: {
    Name: 'ERON',
    Symbol: 'ERON',
    CoinName: 'ERON',
    FullName: 'ERON (ERON)',
  },
  ZLDA: {
    Name: 'ZLDA',
    Symbol: 'ZLDA',
    CoinName: 'Zelda Inu',
    FullName: 'Zelda Inu (ZLDA)',
  },
  QRX: {
    Name: 'QRX',
    Symbol: 'QRX',
    CoinName: 'QuiverX',
    FullName: 'QuiverX (QRX)',
  },
  BLXM: {
    Name: 'BLXM',
    Symbol: 'BLXM',
    CoinName: 'bloXmove Token',
    FullName: 'bloXmove Token (BLXM)',
  },
  SANA: {
    Name: 'SANA',
    Symbol: 'SANA',
    CoinName: 'Storage Area Network Anywhere',
    FullName: 'Storage Area Network Anywhere (SANA)',
  },
  SDOG: {
    Name: 'SDOG',
    Symbol: 'SDOG',
    CoinName: 'Small Doge',
    FullName: 'Small Doge (SDOG)',
  },
  LORDZ: {
    Name: 'LORDZ',
    Symbol: 'LORDZ',
    CoinName: 'Meme Lordz',
    FullName: 'Meme Lordz (LORDZ)',
  },
  DOME: {
    Name: 'DOME',
    Symbol: 'DOME',
    CoinName: 'Everdome',
    FullName: 'Everdome (DOME)',
  },
  SAPP: {
    Name: 'SAPP',
    Symbol: 'SAPP',
    CoinName: 'Sapphire',
    FullName: 'Sapphire (SAPP)',
  },
  GTFO: {
    Name: 'GTFO',
    Symbol: 'GTFO',
    CoinName: 'DumpBuster',
    FullName: 'DumpBuster (GTFO)',
  },
  AFIN: {
    Name: 'AFIN',
    Symbol: 'AFIN',
    CoinName: 'Asian Fintech',
    FullName: 'Asian Fintech (AFIN)',
  },
  DNF: {
    Name: 'DNF',
    Symbol: 'DNF',
    CoinName: 'DNFT Protocol',
    FullName: 'DNFT Protocol (DNF)',
  },
  MVRS: {
    Name: 'MVRS',
    Symbol: 'MVRS',
    CoinName: 'Meta MVRS',
    FullName: 'Meta MVRS (MVRS)',
  },
  FUKU: {
    Name: 'FUKU',
    Symbol: 'FUKU',
    CoinName: 'Furukuru',
    FullName: 'Furukuru (FUKU)',
  },
  XIV: {
    Name: 'XIV',
    Symbol: 'XIV',
    CoinName: 'Project Inverse',
    FullName: 'Project Inverse (XIV)',
  },
  XET: {
    Name: 'XET',
    Symbol: 'XET',
    CoinName: 'Xfinite Entertainment Token',
    FullName: 'Xfinite Entertainment Token (XET)',
  },
  AQUA: {
    Name: 'AQUA',
    Symbol: 'AQUA',
    CoinName: 'Aquarius',
    FullName: 'Aquarius (AQUA)',
  },
  TDROP: {
    Name: 'TDROP',
    Symbol: 'TDROP',
    CoinName: 'ThetaDrop',
    FullName: 'ThetaDrop (TDROP)',
  },
  ANTIS: {
    Name: 'ANTIS',
    Symbol: 'ANTIS',
    CoinName: 'Antis Inu',
    FullName: 'Antis Inu (ANTIS)',
  },
  DODI: {
    Name: 'DODI',
    Symbol: 'DODI',
    CoinName: 'DoubleDice',
    FullName: 'DoubleDice (DODI)',
  },
  GRC: {
    Name: 'GRC',
    Symbol: 'GRC',
    CoinName: 'GreenCoin.AI',
    FullName: 'GreenCoin.AI (GRC)',
  },
  RAVE: {
    Name: 'RAVE',
    Symbol: 'RAVE',
    CoinName: 'Ravendex',
    FullName: 'Ravendex (RAVE)',
  },
  DBD: {
    Name: 'DBD',
    Symbol: 'DBD',
    CoinName: 'Day By Day',
    FullName: 'Day By Day (DBD)',
  },
  IMC: {
    Name: 'IMC',
    Symbol: 'IMC',
    CoinName: 'i Money Crypto',
    FullName: 'i Money Crypto (IMC)',
  },
  ACYC: {
    Name: 'ACYC',
    Symbol: 'ACYC',
    CoinName: 'All Coins Yield Capital',
    FullName: 'All Coins Yield Capital (ACYC)',
  },
  SAUNA: {
    Name: 'SAUNA',
    Symbol: 'SAUNA',
    CoinName: 'SaunaFinance Token',
    FullName: 'SaunaFinance Token (SAUNA)',
  },
  BHC: {
    Name: 'BHC',
    Symbol: 'BHC',
    CoinName: 'BillionHappiness',
    FullName: 'BillionHappiness (BHC)',
  },
  OUD: {
    Name: 'OUD',
    Symbol: 'OUD',
    CoinName: 'OUD',
    FullName: 'OUD (OUD)',
  },
  PIZA: {
    Name: 'PIZA',
    Symbol: 'PIZA',
    CoinName: 'Half Pizza',
    FullName: 'Half Pizza (PIZA)',
  },
  RBT: {
    Name: 'RBT',
    Symbol: 'RBT',
    CoinName: 'Rabet',
    FullName: 'Rabet (RBT)',
  },
  MRCR: {
    Name: 'MRCR',
    Symbol: 'MRCR',
    CoinName: 'Mercor Finance',
    FullName: 'Mercor Finance (MRCR)',
  },
  MIMATIC: {
    Name: 'MIMATIC',
    Symbol: 'MIMATIC',
    CoinName: 'MAI',
    FullName: 'MAI (MIMATIC)',
  },
  NEOM: {
    Name: 'NEOM',
    Symbol: 'NEOM',
    CoinName: 'New Earth Order Money',
    FullName: 'New Earth Order Money (NEOM)',
  },
  LFW: {
    Name: 'LFW',
    Symbol: 'LFW',
    CoinName: 'Legend of Fantasy War',
    FullName: 'Legend of Fantasy War (LFW)',
  },
  DOGECOLA: {
    Name: 'DOGECOLA',
    Symbol: 'DOGECOLA',
    CoinName: 'DogeCola',
    FullName: 'DogeCola (DOGECOLA)',
  },
  DVDX: {
    Name: 'DVDX',
    Symbol: 'DVDX',
    CoinName: 'Derived',
    FullName: 'Derived (DVDX)',
  },
  RTT: {
    Name: 'RTT',
    Symbol: 'RTT',
    CoinName: 'Restore Truth Token',
    FullName: 'Restore Truth Token (RTT)',
  },
  GELATO: {
    Name: 'GELATO',
    Symbol: 'GELATO',
    CoinName: 'Gelato',
    FullName: 'Gelato (GELATO)',
  },
  YARL: {
    Name: 'YARL',
    Symbol: 'YARL',
    CoinName: 'Yarloo',
    FullName: 'Yarloo (YARL)',
  },
  GFCE: {
    Name: 'GFCE',
    Symbol: 'GFCE',
    CoinName: 'GFORCE',
    FullName: 'GFORCE (GFCE)',
  },
  NXD: {
    Name: 'NXD',
    Symbol: 'NXD',
    CoinName: 'Nexus Dubai',
    FullName: 'Nexus Dubai (NXD)',
  },
  WTON: {
    Name: 'WTON',
    Symbol: 'WTON',
    CoinName: 'Wrapped TON Crystal',
    FullName: 'Wrapped TON Crystal (WTON)',
  },
  PKR: {
    Name: 'PKR',
    Symbol: 'PKR',
    CoinName: 'Polker',
    FullName: 'Polker (PKR)',
  },
  ZENITH: {
    Name: 'ZENITH',
    Symbol: 'ZENITH',
    CoinName: 'Zenith Chain',
    FullName: 'Zenith Chain (ZENITH)',
  },
  LILFLOKI: {
    Name: 'LILFLOKI',
    Symbol: 'LILFLOKI',
    CoinName: 'Lil Floki',
    FullName: 'Lil Floki (LILFLOKI)',
  },
  GRO: {
    Name: 'GRO',
    Symbol: 'GRO',
    CoinName: 'Gro DAO Token',
    FullName: 'Gro DAO Token (GRO)',
  },
  MR: {
    Name: 'MR',
    Symbol: 'MR',
    CoinName: 'Meta Ruffy',
    FullName: 'Meta Ruffy (MR)',
  },
  WELT: {
    Name: 'WELT',
    Symbol: 'WELT',
    CoinName: 'Fabwelt',
    FullName: 'Fabwelt (WELT)',
  },
  SRWD: {
    Name: 'SRWD',
    Symbol: 'SRWD',
    CoinName: 'ShibRWD',
    FullName: 'ShibRWD (SRWD)',
  },
  MRXB: {
    Name: 'MRXB',
    Symbol: 'MRXB',
    CoinName: 'Wrapped Metrix',
    FullName: 'Wrapped Metrix (MRXB)',
  },
  MYNE: {
    Name: 'MYNE',
    Symbol: 'MYNE',
    CoinName: 'ITSMYNE',
    FullName: 'ITSMYNE (MYNE)',
  },
  SMD: {
    Name: 'SMD',
    Symbol: 'SMD',
    CoinName: 'SMD Coin',
    FullName: 'SMD Coin (SMD)',
  },
  TEDDY: {
    Name: 'TEDDY',
    Symbol: 'TEDDY',
    CoinName: 'Teddy Doge',
    FullName: 'Teddy Doge (TEDDY)',
  },
  PPAY: {
    Name: 'PPAY',
    Symbol: 'PPAY',
    CoinName: 'Plasma Finance',
    FullName: 'Plasma Finance (PPAY)',
  },
  UNQ: {
    Name: 'UNQ',
    Symbol: 'UNQ',
    CoinName: 'UNQ',
    FullName: 'UNQ (UNQ)',
  },
  GMR: {
    Name: 'GMR',
    Symbol: 'GMR',
    CoinName: 'GAMER',
    FullName: 'GAMER (GMR)',
  },
  REC: {
    Name: 'REC',
    Symbol: 'REC',
    CoinName: 'Rec Token (REC)',
    FullName: 'Rec Token (REC) (REC)',
  },
  WAGG: {
    Name: 'WAGG',
    Symbol: 'WAGG',
    CoinName: 'Waggle Network',
    FullName: 'Waggle Network (WAGG)',
  },
  RENA: {
    Name: 'RENA',
    Symbol: 'RENA',
    CoinName: 'Warena',
    FullName: 'Warena (RENA)',
  },
  ARTI: {
    Name: 'ARTI',
    Symbol: 'ARTI',
    CoinName: 'Arti Project',
    FullName: 'Arti Project (ARTI)',
  },
  WEVE: {
    Name: 'WEVE',
    Symbol: 'WEVE',
    CoinName: 'veDAO',
    FullName: 'veDAO (WEVE)',
  },
  CRBN: {
    Name: 'CRBN',
    Symbol: 'CRBN',
    CoinName: 'Carbon',
    FullName: 'Carbon (CRBN)',
  },
  LIME: {
    Name: 'LIME',
    Symbol: 'LIME',
    CoinName: 'iMe Lab',
    FullName: 'iMe Lab (LIME)',
  },
  CTR: {
    Name: 'CTR',
    Symbol: 'CTR',
    CoinName: 'Creator Platform',
    FullName: 'Creator Platform (CTR)',
  },
  BLIN: {
    Name: 'BLIN',
    Symbol: 'BLIN',
    CoinName: 'Blin Metaverse',
    FullName: 'Blin Metaverse (BLIN)',
  },
  MEAN: {
    Name: 'MEAN',
    Symbol: 'MEAN',
    CoinName: 'Meanfi',
    FullName: 'Meanfi (MEAN)',
  },
  SHOE: {
    Name: 'SHOE',
    Symbol: 'SHOE',
    CoinName: 'ShoeFy',
    FullName: 'ShoeFy (SHOE)',
  },
  NFTL: {
    Name: 'NFTL',
    Symbol: 'NFTL',
    CoinName: 'NFTLaunch',
    FullName: 'NFTLaunch (NFTL)',
  },
  AART: {
    Name: 'AART',
    Symbol: 'AART',
    CoinName: 'ALL.ART',
    FullName: 'ALL.ART (AART)',
  },
  LAVA: {
    Name: 'LAVA',
    Symbol: 'LAVA',
    CoinName: 'Lavaswap',
    FullName: 'Lavaswap (LAVA)',
  },
  TITA: {
    Name: 'TITA',
    Symbol: 'TITA',
    CoinName: 'Titan Hunters',
    FullName: 'Titan Hunters (TITA)',
  },
  GZONE: {
    Name: 'GZONE',
    Symbol: 'GZONE',
    CoinName: 'GameZone',
    FullName: 'GameZone (GZONE)',
  },
  BOO: {
    Name: 'BOO',
    Symbol: 'BOO',
    CoinName: 'Spookyswap',
    FullName: 'Spookyswap (BOO)',
  },
  SUPE: {
    Name: 'SUPE',
    Symbol: 'SUPE',
    CoinName: 'Supe Infinity',
    FullName: 'Supe Infinity (SUPE)',
  },
  SPO: {
    Name: 'SPO',
    Symbol: 'SPO',
    CoinName: 'Spores Network',
    FullName: 'Spores Network (SPO)',
  },
  XCV: {
    Name: 'XCV',
    Symbol: 'XCV',
    CoinName: 'XCarnival',
    FullName: 'XCarnival (XCV)',
  },
  GZLR: {
    Name: 'GZLR',
    Symbol: 'GZLR',
    CoinName: 'Guzzler',
    FullName: 'Guzzler (GZLR)',
  },
  BMIC: {
    Name: 'BMIC',
    Symbol: 'BMIC',
    CoinName: 'Bitmic',
    FullName: 'Bitmic (BMIC)',
  },
  SHAMAN: {
    Name: 'SHAMAN',
    Symbol: 'SHAMAN',
    CoinName: 'Shaman King Inu',
    FullName: 'Shaman King Inu (SHAMAN)',
  },
  ZAMZAM: {
    Name: 'ZAMZAM',
    Symbol: 'ZAMZAM',
    CoinName: 'ZAMZAM',
    FullName: 'ZAMZAM (ZAMZAM)',
  },
  BSW: {
    Name: 'BSW',
    Symbol: 'BSW',
    CoinName: 'Biswap',
    FullName: 'Biswap (BSW)',
  },
  OBROK: {
    Name: 'OBROK',
    Symbol: 'OBROK',
    CoinName: 'OBRok',
    FullName: 'OBRok (OBROK)',
  },
  CYFI: {
    Name: 'CYFI',
    Symbol: 'CYFI',
    CoinName: 'cYFI',
    FullName: 'cYFI (CYFI)',
  },
  CSUSHI: {
    Name: 'CSUSHI',
    Symbol: 'CSUSHI',
    CoinName: 'cSUSHI',
    FullName: 'cSUSHI (CSUSHI)',
  },
  CMKR: {
    Name: 'CMKR',
    Symbol: 'CMKR',
    CoinName: 'cMKR',
    FullName: 'cMKR (CMKR)',
  },
  CLINK: {
    Name: 'CLINK',
    Symbol: 'CLINK',
    CoinName: 'cLINK',
    FullName: 'cLINK (CLINK)',
  },
  CAAVE: {
    Name: 'CAAVE',
    Symbol: 'CAAVE',
    CoinName: 'cAAVE',
    FullName: 'cAAVE (CAAVE)',
  },
  UNQT: {
    Name: 'UNQT',
    Symbol: 'UNQT',
    CoinName: 'Unique Utility Token',
    FullName: 'Unique Utility Token (UNQT)',
  },
  WKD: {
    Name: 'WKD',
    Symbol: 'WKD',
    CoinName: 'Wakanda Inu',
    FullName: 'Wakanda Inu (WKD)',
  },
  HPL: {
    Name: 'HPL',
    Symbol: 'HPL',
    CoinName: 'HappyLand (HPL)',
    FullName: 'HappyLand (HPL) (HPL)',
  },
  SHIELD: {
    Name: 'SHIELD',
    Symbol: 'SHIELD',
    CoinName: 'Crypto Shield',
    FullName: 'Crypto Shield (SHIELD)',
  },
  LAVAX: {
    Name: 'LAVAX',
    Symbol: 'LAVAX',
    CoinName: 'LavaX Labs',
    FullName: 'LavaX Labs (LAVAX)',
  },
  UNIX: {
    Name: 'UNIX',
    Symbol: 'UNIX',
    CoinName: 'UniX',
    FullName: 'UniX (UNIX)',
  },
  TOMB: {
    Name: 'TOMB',
    Symbol: 'TOMB',
    CoinName: 'Tomb',
    FullName: 'Tomb (TOMB)',
  },
  DOGESWAP: {
    Name: 'DOGESWAP',
    Symbol: 'DOGESWAP',
    CoinName: 'Dogeswap Token (HECO)',
    FullName: 'Dogeswap Token (HECO) (DOGESWAP)',
  },
  DEPTH: {
    Name: 'DEPTH',
    Symbol: 'DEPTH',
    CoinName: 'Depth Token',
    FullName: 'Depth Token (DEPTH)',
  },
  MOONEY: {
    Name: 'MOONEY',
    Symbol: 'MOONEY',
    CoinName: 'Moon DAO',
    FullName: 'Moon DAO (MOONEY)',
  },
  LQR: {
    Name: 'LQR',
    Symbol: 'LQR',
    CoinName: 'Laqira Protocol',
    FullName: 'Laqira Protocol (LQR)',
  },
  GAMEIN: {
    Name: 'GAMEIN',
    Symbol: 'GAMEIN',
    CoinName: 'Game Infinity',
    FullName: 'Game Infinity (GAMEIN)',
  },
  VCG: {
    Name: 'VCG',
    Symbol: 'VCG',
    CoinName: 'VCGamers',
    FullName: 'VCGamers (VCG)',
  },
  MRHB: {
    Name: 'MRHB',
    Symbol: 'MRHB',
    CoinName: 'MarhabaDeFi',
    FullName: 'MarhabaDeFi (MRHB)',
  },
  X2Y2: {
    Name: 'X2Y2',
    Symbol: 'X2Y2',
    CoinName: 'X2Y2',
    FullName: 'X2Y2 (X2Y2)',
  },
  PHCR: {
    Name: 'PHCR',
    Symbol: 'PHCR',
    CoinName: 'PhotoChromic',
    FullName: 'PhotoChromic (PHCR)',
  },
  SPELLFIRE: {
    Name: 'SPELLFIRE',
    Symbol: 'SPELLFIRE',
    CoinName: 'Spellfire',
    FullName: 'Spellfire (SPELLFIRE)',
  },
  GCOIN: {
    Name: 'GCOIN',
    Symbol: 'GCOIN',
    CoinName: 'Galaxy Fight Club',
    FullName: 'Galaxy Fight Club (GCOIN)',
  },
  STARLY: {
    Name: 'STARLY',
    Symbol: 'STARLY',
    CoinName: 'Starly',
    FullName: 'Starly (STARLY)',
  },
  PSTAKE: {
    Name: 'PSTAKE',
    Symbol: 'PSTAKE',
    CoinName: 'pSTAKE Finance',
    FullName: 'pSTAKE Finance (PSTAKE)',
  },
  GEAR: {
    Name: 'GEAR',
    Symbol: 'GEAR',
    CoinName: 'MetaGear',
    FullName: 'MetaGear (GEAR)',
  },
  MGG: {
    Name: 'MGG',
    Symbol: 'MGG',
    CoinName: 'MetaGaming Guild',
    FullName: 'MetaGaming Guild (MGG)',
  },
  ARKN: {
    Name: 'ARKN',
    Symbol: 'ARKN',
    CoinName: 'Ark Rivals',
    FullName: 'Ark Rivals (ARKN)',
  },
  PHAE: {
    Name: 'PHAE',
    Symbol: 'PHAE',
    CoinName: 'Phaeton',
    FullName: 'Phaeton (PHAE)',
  },
  QUIDD: {
    Name: 'QUIDD',
    Symbol: 'QUIDD',
    CoinName: 'Quidd',
    FullName: 'Quidd (QUIDD)',
  },
  MOLA: {
    Name: 'MOLA',
    Symbol: 'MOLA',
    CoinName: 'MoonLana',
    FullName: 'MoonLana (MOLA)',
  },
  JEWEL: {
    Name: 'JEWEL',
    Symbol: 'JEWEL',
    CoinName: 'DeFi Kingdoms',
    FullName: 'DeFi Kingdoms (JEWEL)',
  },
  CGU: {
    Name: 'CGU',
    Symbol: 'CGU',
    CoinName: 'Crypto Gaming United',
    FullName: 'Crypto Gaming United (CGU)',
  },
  SHIBTC: {
    Name: 'SHIBTC',
    Symbol: 'SHIBTC',
    CoinName: 'Shibabitcoin',
    FullName: 'Shibabitcoin (SHIBTC)',
  },
  MOVD: {
    Name: 'MOVD',
    Symbol: 'MOVD',
    CoinName: 'MOVE Network',
    FullName: 'MOVE Network (MOVD)',
  },
  ZYR: {
    Name: 'ZYR',
    Symbol: 'ZYR',
    CoinName: 'Zyrri',
    FullName: 'Zyrri (ZYR)',
  },
  APE: {
    Name: 'APE',
    Symbol: 'APE',
    CoinName: 'ApeCoin',
    FullName: 'ApeCoin (APE)',
  },
  ESGC: {
    Name: 'ESGC',
    Symbol: 'ESGC',
    CoinName: 'ESG Chain',
    FullName: 'ESG Chain (ESGC)',
  },
  HDRN: {
    Name: 'HDRN',
    Symbol: 'HDRN',
    CoinName: 'Hedron',
    FullName: 'Hedron (HDRN)',
  },
  KUNCI: {
    Name: 'KUNCI',
    Symbol: 'KUNCI',
    CoinName: 'Kunci Coin',
    FullName: 'Kunci Coin (KUNCI)',
  },
  AIMX: {
    Name: 'AIMX',
    Symbol: 'AIMX',
    CoinName: 'Aimedis',
    FullName: 'Aimedis (AIMX)',
  },
  SMARTLOX: {
    Name: 'SMARTLOX',
    Symbol: 'SMARTLOX',
    CoinName: 'SmartLOX',
    FullName: 'SmartLOX (SMARTLOX)',
  },
  CALI: {
    Name: 'CALI',
    Symbol: 'CALI',
    CoinName: 'CaliCoin',
    FullName: 'CaliCoin (CALI)',
  },
  BRCP: {
    Name: 'BRCP',
    Symbol: 'BRCP',
    CoinName: 'BRCP Token',
    FullName: 'BRCP Token (BRCP)',
  },
  NTX: {
    Name: 'NTX',
    Symbol: 'NTX',
    CoinName: 'NitroEX',
    FullName: 'NitroEX (NTX)',
  },
  SOURCE: {
    Name: 'SOURCE',
    Symbol: 'SOURCE',
    CoinName: 'ReSource Protocol',
    FullName: 'ReSource Protocol (SOURCE)',
  },
  GAN: {
    Name: 'GAN',
    Symbol: 'GAN',
    CoinName: 'Galactic Arena: The NFTverse',
    FullName: 'Galactic Arena: The NFTverse (GAN)',
  },
  FROYO: {
    Name: 'FROYO',
    Symbol: 'FROYO',
    CoinName: 'Froyo Games',
    FullName: 'Froyo Games (FROYO)',
  },
  BENT: {
    Name: 'BENT',
    Symbol: 'BENT',
    CoinName: 'Bent Finance',
    FullName: 'Bent Finance (BENT)',
  },
  ROCO: {
    Name: 'ROCO',
    Symbol: 'ROCO',
    CoinName: 'ROCO FINANCE',
    FullName: 'ROCO FINANCE (ROCO)',
  },
  ARCANE: {
    Name: 'ARCANE',
    Symbol: 'ARCANE',
    CoinName: 'Arcane Token',
    FullName: 'Arcane Token (ARCANE)',
  },
  FABRIC: {
    Name: 'FABRIC',
    Symbol: 'FABRIC',
    CoinName: 'MetaFabric',
    FullName: 'MetaFabric (FABRIC)',
  },
  NVIR: {
    Name: 'NVIR',
    Symbol: 'NVIR',
    CoinName: 'NvirWorld',
    FullName: 'NvirWorld (NVIR)',
  },
  ULTGG: {
    Name: 'ULTGG',
    Symbol: 'ULTGG',
    CoinName: 'UltimoGG',
    FullName: 'UltimoGG (ULTGG)',
  },
  CKT: {
    Name: 'CKT',
    Symbol: 'CKT',
    CoinName: 'Caketools',
    FullName: 'Caketools (CKT)',
  },
  ANN: {
    Name: 'ANN',
    Symbol: 'ANN',
    CoinName: 'Annex Finance',
    FullName: 'Annex Finance (ANN)',
  },
  FROG: {
    Name: 'FROG',
    Symbol: 'FROG',
    CoinName: 'FrogSwap',
    FullName: 'FrogSwap (FROG)',
  },
  JUSTICE: {
    Name: 'JUSTICE',
    Symbol: 'JUSTICE',
    CoinName: 'AssangeDAO',
    FullName: 'AssangeDAO (JUSTICE)',
  },
  ALIX: {
    Name: 'ALIX',
    Symbol: 'ALIX',
    CoinName: 'AlinX',
    FullName: 'AlinX (ALIX)',
  },
  GDOGE: {
    Name: 'GDOGE',
    Symbol: 'GDOGE',
    CoinName: 'Golden Doge',
    FullName: 'Golden Doge (GDOGE)',
  },
  MQST: {
    Name: 'MQST',
    Symbol: 'MQST',
    CoinName: 'MonsterQuest',
    FullName: 'MonsterQuest (MQST)',
  },
  MOOI: {
    Name: 'MOOI',
    Symbol: 'MOOI',
    CoinName: 'Moonai',
    FullName: 'Moonai (MOOI)',
  },
  AIR: {
    Name: 'AIR',
    Symbol: 'AIR',
    CoinName: 'Altair',
    FullName: 'Altair (AIR)',
  },
  BXC: {
    Name: 'BXC',
    Symbol: 'BXC',
    CoinName: 'BonusCloud',
    FullName: 'BonusCloud (BXC)',
  },
  BCOIN: {
    Name: 'BCOIN',
    Symbol: 'BCOIN',
    CoinName: 'Bombcrypto',
    FullName: 'Bombcrypto (BCOIN)',
  },
  BLACK: {
    Name: 'BLACK',
    Symbol: 'BLACK',
    CoinName: 'BLACKHOLE PROTOCOL',
    FullName: 'BLACKHOLE PROTOCOL (BLACK)',
  },
  BRIGHT: {
    Name: 'BRIGHT',
    Symbol: 'BRIGHT',
    CoinName: 'Bright Token',
    FullName: 'Bright Token (BRIGHT)',
  },
  DRAGONMA: {
    Name: 'DRAGONMA',
    Symbol: 'DRAGONMA',
    CoinName: 'Dragon Mainland Shards',
    FullName: 'Dragon Mainland Shards (DRAGONMA)',
  },
  CP: {
    Name: 'CP',
    Symbol: 'CP',
    CoinName: 'CoPuppy',
    FullName: 'CoPuppy (CP)',
  },
  CRAFT: {
    Name: 'CRAFT',
    Symbol: 'CRAFT',
    CoinName: 'TaleCraft',
    FullName: 'TaleCraft (CRAFT)',
  },
  DES: {
    Name: 'DES',
    Symbol: 'DES',
    CoinName: 'DeSpace Protocol',
    FullName: 'DeSpace Protocol (DES)',
  },
  DIS: {
    Name: 'DIS',
    Symbol: 'DIS',
    CoinName: 'TosDis',
    FullName: 'TosDis (DIS)',
  },
  FAN: {
    Name: 'FAN',
    Symbol: 'FAN',
    CoinName: 'Fanadise',
    FullName: 'Fanadise (FAN)',
  },
  GYM: {
    Name: 'GYM',
    Symbol: 'GYM',
    CoinName: 'GYM Token',
    FullName: 'GYM Token (GYM)',
  },
  BERRY: {
    Name: 'BERRY',
    Symbol: 'BERRY',
    CoinName: 'Berry',
    FullName: 'Berry (BERRY)',
  },
  WEX: {
    Name: 'WEX',
    Symbol: 'WEX',
    CoinName: 'WaultSwap',
    FullName: 'WaultSwap (WEX)',
  },
  GASDAO: {
    Name: 'GASDAO',
    Symbol: 'GASDAO',
    CoinName: 'Gas DAO',
    FullName: 'Gas DAO (GASDAO)',
  },
  GSX: {
    Name: 'GSX',
    Symbol: 'GSX',
    CoinName: 'Gold Secured Currency',
    FullName: 'Gold Secured Currency (GSX)',
  },
  GHC: {
    Name: 'GHC',
    Symbol: 'GHC',
    CoinName: 'Galaxy Heroes Coin',
    FullName: 'Galaxy Heroes Coin (GHC)',
  },
  GOAL: {
    Name: 'GOAL',
    Symbol: 'GOAL',
    CoinName: 'GOAL token',
    FullName: 'GOAL token (GOAL)',
  },
  GLS: {
    Name: 'GLS',
    Symbol: 'GLS',
    CoinName: 'Glass Chain',
    FullName: 'Glass Chain (GLS)',
  },
  GOLDMIN: {
    Name: 'GOLDMIN',
    Symbol: 'GOLDMIN',
    CoinName: 'GoldMiner',
    FullName: 'GoldMiner (GOLDMIN)',
  },
  BEYOND: {
    Name: 'BEYOND',
    Symbol: 'BEYOND',
    CoinName: 'Beyond Protocol',
    FullName: 'Beyond Protocol (BEYOND)',
  },
  PRT: {
    Name: 'PRT',
    Symbol: 'PRT',
    CoinName: 'Parrot Protocol',
    FullName: 'Parrot Protocol (PRT)',
  },
  PSY: {
    Name: 'PSY',
    Symbol: 'PSY',
    CoinName: 'PsyOptions',
    FullName: 'PsyOptions (PSY)',
  },
  PSB: {
    Name: 'PSB',
    Symbol: 'PSB',
    CoinName: 'Planet Sandbox',
    FullName: 'Planet Sandbox (PSB)',
  },
  TCR: {
    Name: 'TCR',
    Symbol: 'TCR',
    CoinName: 'Tracer DAO',
    FullName: 'Tracer DAO (TCR)',
  },
  FLUXT: {
    Name: 'FLUXT',
    Symbol: 'FLUXT',
    CoinName: 'Flux Token',
    FullName: 'Flux Token (FLUXT)',
  },
  LED: {
    Name: 'LED',
    Symbol: 'LED',
    CoinName: 'LEDGIS',
    FullName: 'LEDGIS (LED)',
  },
  NFTY: {
    Name: 'NFTY',
    Symbol: 'NFTY',
    CoinName: 'NFTY Token',
    FullName: 'NFTY Token (NFTY)',
  },
  SSS: {
    Name: 'SSS',
    Symbol: 'SSS',
    CoinName: 'StarSharks',
    FullName: 'StarSharks (SSS)',
  },
  VOLT: {
    Name: 'VOLT',
    Symbol: 'VOLT',
    CoinName: 'Volt Inu',
    FullName: 'Volt Inu (VOLT)',
  },
  ORO: {
    Name: 'ORO',
    Symbol: 'ORO',
    CoinName: 'Operon Origins',
    FullName: 'Operon Origins (ORO)',
  },
  PEX: {
    Name: 'PEX',
    Symbol: 'PEX',
    CoinName: 'Pexcoin',
    FullName: 'Pexcoin (PEX)',
  },
  PLT: {
    Name: 'PLT',
    Symbol: 'PLT',
    CoinName: 'Poollotto.finance',
    FullName: 'Poollotto.finance (PLT)',
  },
  PLM: {
    Name: 'PLM',
    Symbol: 'PLM',
    CoinName: 'Plasmonics',
    FullName: 'Plasmonics (PLM)',
  },
  ACETH: {
    Name: 'ACETH',
    Symbol: 'ACETH',
    CoinName: 'Acether',
    FullName: 'Acether (ACETH)',
  },
  CNDL: {
    Name: 'CNDL',
    Symbol: 'CNDL',
    CoinName: 'Candle',
    FullName: 'Candle (CNDL)',
  },
  RISE: {
    Name: 'RISE',
    Symbol: 'RISE',
    CoinName: 'EverRise',
    FullName: 'EverRise (RISE)',
  },
  STA: {
    Name: 'STA',
    Symbol: 'STA',
    CoinName: 'Defi STOA',
    FullName: 'Defi STOA (STA)',
  },
  XDAG: {
    Name: 'XDAG',
    Symbol: 'XDAG',
    CoinName: 'Dagger',
    FullName: 'Dagger (XDAG)',
  },
  NBT: {
    Name: 'NBT',
    Symbol: 'NBT',
    CoinName: 'NanoByte',
    FullName: 'NanoByte (NBT)',
  },
  TOR: {
    Name: 'TOR',
    Symbol: 'TOR',
    CoinName: 'TOR',
    FullName: 'TOR (TOR)',
  },
  VRN: {
    Name: 'VRN',
    Symbol: 'VRN',
    CoinName: 'Varen',
    FullName: 'Varen (VRN)',
  },
  WGC: {
    Name: 'WGC',
    Symbol: 'WGC',
    CoinName: 'Green Climate World',
    FullName: 'Green Climate World (WGC)',
  },
  NYM: {
    Name: 'NYM',
    Symbol: 'NYM',
    CoinName: 'Nym Token',
    FullName: 'Nym Token (NYM)',
  },
  WIT: {
    Name: 'WIT',
    Symbol: 'WIT',
    CoinName: 'Witnet',
    FullName: 'Witnet (WIT)',
  },
  TERAR: {
    Name: 'TERAR',
    Symbol: 'TERAR',
    CoinName: 'Terareum',
    FullName: 'Terareum (TERAR)',
  },
  BTSC: {
    Name: 'BTSC',
    Symbol: 'BTSC',
    CoinName: 'BTS Chain',
    FullName: 'BTS Chain (BTSC)',
  },
  FTB: {
    Name: 'FTB',
    Symbol: 'FTB',
    CoinName: 'Fit&Beat',
    FullName: 'Fit&Beat (FTB)',
  },
  VENT: {
    Name: 'VENT',
    Symbol: 'VENT',
    CoinName: 'Vent Finance',
    FullName: 'Vent Finance (VENT)',
  },
  EYES: {
    Name: 'EYES',
    Symbol: 'EYES',
    CoinName: 'Eyes Protocol',
    FullName: 'Eyes Protocol (EYES)',
  },
  MSU: {
    Name: 'MSU',
    Symbol: 'MSU',
    CoinName: 'MetaSoccer',
    FullName: 'MetaSoccer (MSU)',
  },
  EVA: {
    Name: 'EVA',
    Symbol: 'EVA',
    CoinName: 'Evanesco Network',
    FullName: 'Evanesco Network (EVA)',
  },
  AVINOC: {
    Name: 'AVINOC',
    Symbol: 'AVINOC',
    CoinName: 'AVINOC',
    FullName: 'AVINOC (AVINOC)',
  },
  RAZE: {
    Name: 'RAZE',
    Symbol: 'RAZE',
    CoinName: 'Raze Network',
    FullName: 'Raze Network (RAZE)',
  },
  GAD: {
    Name: 'GAD',
    Symbol: 'GAD',
    CoinName: 'Green App Development',
    FullName: 'Green App Development (GAD)',
  },
  BLUT: {
    Name: 'BLUT',
    Symbol: 'BLUT',
    CoinName: 'Bluetherium',
    FullName: 'Bluetherium (BLUT)',
  },
  MOM: {
    Name: 'MOM',
    Symbol: 'MOM',
    CoinName: 'Mother of Memes',
    FullName: 'Mother of Memes (MOM)',
  },
  KUR: {
    Name: 'KUR',
    Symbol: 'KUR',
    CoinName: 'Kuro',
    FullName: 'Kuro (KUR)',
  },
  TFS: {
    Name: 'TFS',
    Symbol: 'TFS',
    CoinName: 'TFS Token',
    FullName: 'TFS Token (TFS)',
  },
  TAUR: {
    Name: 'TAUR',
    Symbol: 'TAUR',
    CoinName: 'Marnotaur',
    FullName: 'Marnotaur (TAUR)',
  },
  AWNEX: {
    Name: 'AWNEX',
    Symbol: 'AWNEX',
    CoinName: 'AWNEX token',
    FullName: 'AWNEX token (AWNEX)',
  },
  TCANDY: {
    Name: 'TCANDY',
    Symbol: 'TCANDY',
    CoinName: 'TripCandy',
    FullName: 'TripCandy (TCANDY)',
  },
  LOX: {
    Name: 'LOX',
    Symbol: 'LOX',
    CoinName: 'Lox Network',
    FullName: 'Lox Network (LOX)',
  },
  LOV: {
    Name: 'LOV',
    Symbol: 'LOV',
    CoinName: 'LoveChain',
    FullName: 'LoveChain (LOV)',
  },
  LCMG: {
    Name: 'LCMG',
    Symbol: 'LCMG',
    CoinName: 'ElysiumG',
    FullName: 'ElysiumG (LCMG)',
  },
  FAYRE: {
    Name: 'FAYRE',
    Symbol: 'FAYRE',
    CoinName: 'Fayre',
    FullName: 'Fayre (FAYRE)',
  },
  AAG: {
    Name: 'AAG',
    Symbol: 'AAG',
    CoinName: 'AAG Ventures',
    FullName: 'AAG Ventures (AAG)',
  },
  VXL: {
    Name: 'VXL',
    Symbol: 'VXL',
    CoinName: 'Voxel X Network',
    FullName: 'Voxel X Network (VXL)',
  },
  RETH2: {
    Name: 'RETH2',
    Symbol: 'RETH2',
    CoinName: 'rETH2',
    FullName: 'rETH2 (RETH2)',
  },
  ELV: {
    Name: 'ELV',
    Symbol: 'ELV',
    CoinName: 'Elvantis',
    FullName: 'Elvantis (ELV)',
  },
  ISA: {
    Name: 'ISA',
    Symbol: 'ISA',
    CoinName: 'Islander',
    FullName: 'Islander (ISA)',
  },
  TPC: {
    Name: 'TPC',
    Symbol: 'TPC',
    CoinName: 'Techpay',
    FullName: 'Techpay (TPC)',
  },
  KPC: {
    Name: 'KPC',
    Symbol: 'KPC',
    CoinName: 'KEEPs Coin',
    FullName: 'KEEPs Coin (KPC)',
  },
  STG: {
    Name: 'STG',
    Symbol: 'STG',
    CoinName: 'Stargate Finance',
    FullName: 'Stargate Finance (STG)',
  },
  BASHOS: {
    Name: 'BASHOS',
    Symbol: 'BASHOS',
    CoinName: 'Bashoswap',
    FullName: 'Bashoswap (BASHOS)',
  },
  HE: {
    Name: 'HE',
    Symbol: 'HE',
    CoinName: 'Heroes & Empires',
    FullName: 'Heroes & Empires (HE)',
  },
  IB: {
    Name: 'IB',
    Symbol: 'IB',
    CoinName: 'Iron Bank',
    FullName: 'Iron Bank (IB)',
  },
  JONES: {
    Name: 'JONES',
    Symbol: 'JONES',
    CoinName: 'Jones DAO',
    FullName: 'Jones DAO (JONES)',
  },
  DSG: {
    Name: 'DSG',
    Symbol: 'DSG',
    CoinName: 'Dinosaureggs',
    FullName: 'Dinosaureggs (DSG)',
  },
  ARCONA: {
    Name: 'ARCONA',
    Symbol: 'ARCONA',
    CoinName: 'Arcona',
    FullName: 'Arcona (ARCONA)',
  },
  KSHIB: {
    Name: 'KSHIB',
    Symbol: 'KSHIB',
    CoinName: 'Kilo Shiba Inu',
    FullName: 'Kilo Shiba Inu (KSHIB)',
  },
  NTR: {
    Name: 'NTR',
    Symbol: 'NTR',
    CoinName: 'Nether',
    FullName: 'Nether (NTR)',
  },
  HVE2: {
    Name: 'HVE2',
    Symbol: 'HVE2',
    CoinName: 'Uhive',
    FullName: 'Uhive (HVE2)',
  },
  FUS: {
    Name: 'FUS',
    Symbol: 'FUS',
    CoinName: 'Fus',
    FullName: 'Fus (FUS)',
  },
  XQC: {
    Name: 'XQC',
    Symbol: 'XQC',
    CoinName: 'Quras Token',
    FullName: 'Quras Token (XQC)',
  },
  PKT: {
    Name: 'PKT',
    Symbol: 'PKT',
    CoinName: 'PKT',
    FullName: 'PKT (PKT)',
  },
  REFLECTO: {
    Name: 'REFLECTO',
    Symbol: 'REFLECTO',
    CoinName: 'Reflecto',
    FullName: 'Reflecto (REFLECTO)',
  },
  GLD: {
    Name: 'GLD',
    Symbol: 'GLD',
    CoinName: 'Goldario',
    FullName: 'Goldario (GLD)',
  },
  TRUEBIT: {
    Name: 'TRUEBIT',
    Symbol: 'TRUEBIT',
    CoinName: 'Truebit Protocol',
    FullName: 'Truebit Protocol (TRUEBIT)',
  },
  XUP: {
    Name: 'XUP',
    Symbol: 'XUP',
    CoinName: 'UPGRADE',
    FullName: 'UPGRADE (XUP)',
  },
  XIDO: {
    Name: 'XIDO',
    Symbol: 'XIDO',
    CoinName: 'Xido Finance',
    FullName: 'Xido Finance (XIDO)',
  },
  AFX: {
    Name: 'AFX',
    Symbol: 'AFX',
    CoinName: 'Afrix',
    FullName: 'Afrix (AFX)',
  },
  DFIS: {
    Name: 'DFIS',
    Symbol: 'DFIS',
    CoinName: 'DfiStarter',
    FullName: 'DfiStarter (DFIS)',
  },
  PEPPER: {
    Name: 'PEPPER',
    Symbol: 'PEPPER',
    CoinName: 'Pepper Token',
    FullName: 'Pepper Token (PEPPER)',
  },
  SMARTNFT: {
    Name: 'SMARTNFT',
    Symbol: 'SMARTNFT',
    CoinName: 'SmartNFT',
    FullName: 'SmartNFT (SMARTNFT)',
  },
  USDU: {
    Name: 'USDU',
    Symbol: 'USDU',
    CoinName: 'Upper Dollar',
    FullName: 'Upper Dollar (USDU)',
  },
  EURU: {
    Name: 'EURU',
    Symbol: 'EURU',
    CoinName: 'Upper Euro',
    FullName: 'Upper Euro (EURU)',
  },
  MULTI: {
    Name: 'MULTI',
    Symbol: 'MULTI',
    CoinName: 'Multichain',
    FullName: 'Multichain (MULTI)',
  },
  APED: {
    Name: 'APED',
    Symbol: 'APED',
    CoinName: 'Baddest Alpha Ape Bundle',
    FullName: 'Baddest Alpha Ape Bundle (APED)',
  },
  LORDS: {
    Name: 'LORDS',
    Symbol: 'LORDS',
    CoinName: 'LORDS',
    FullName: 'LORDS (LORDS)',
  },
  JADE: {
    Name: 'JADE',
    Symbol: 'JADE',
    CoinName: 'Jade Protocol',
    FullName: 'Jade Protocol (JADE)',
  },
  GAMEFI: {
    Name: 'GAMEFI',
    Symbol: 'GAMEFI',
    CoinName: 'GameFi Token',
    FullName: 'GameFi Token (GAMEFI)',
  },
  ACET: {
    Name: 'ACET',
    Symbol: 'ACET',
    CoinName: 'Acet',
    FullName: 'Acet (ACET)',
  },
  GORILLA: {
    Name: 'GORILLA',
    Symbol: 'GORILLA',
    CoinName: 'Gorilla Diamond',
    FullName: 'Gorilla Diamond (GORILLA)',
  },
  XPROT: {
    Name: 'XPROT',
    Symbol: 'XPROT',
    CoinName: 'X Protocol',
    FullName: 'X Protocol (XPROT)',
  },
  XDATA: {
    Name: 'XDATA',
    Symbol: 'XDATA',
    CoinName: 'Streamr XDATA',
    FullName: 'Streamr XDATA (XDATA)',
  },
  POKEM: {
    Name: 'POKEM',
    Symbol: 'POKEM',
    CoinName: 'Pokemonio',
    FullName: 'Pokemonio (POKEM)',
  },
  MGLD: {
    Name: 'MGLD',
    Symbol: 'MGLD',
    CoinName: 'Metallurgy',
    FullName: 'Metallurgy (MGLD)',
  },
  FFN: {
    Name: 'FFN',
    Symbol: 'FFN',
    CoinName: 'Fairy Forest',
    FullName: 'Fairy Forest (FFN)',
  },
  ZIRVE: {
    Name: 'ZIRVE',
    Symbol: 'ZIRVE',
    CoinName: 'Zirve Coin',
    FullName: 'Zirve Coin (ZIRVE)',
  },
  HEROESC: {
    Name: 'HEROESC',
    Symbol: 'HEROESC',
    CoinName: 'HeroesChained',
    FullName: 'HeroesChained (HEROESC)',
  },
  LIQ: {
    Name: 'LIQ',
    Symbol: 'LIQ',
    CoinName: 'LIQ Protocol',
    FullName: 'LIQ Protocol (LIQ)',
  },
  INFO: {
    Name: 'INFO',
    Symbol: 'INFO',
    CoinName: 'Infomatix',
    FullName: 'Infomatix (INFO)',
  },
  MASTER: {
    Name: 'MASTER',
    Symbol: 'MASTER',
    CoinName: 'Mastercoin',
    FullName: 'Mastercoin (MASTER)',
  },
  QUA: {
    Name: 'QUA',
    Symbol: 'QUA',
    CoinName: 'Quantum Tech',
    FullName: 'Quantum Tech (QUA)',
  },
  PROBIN: {
    Name: 'PROBIN',
    Symbol: 'PROBIN',
    CoinName: 'Probinex',
    FullName: 'Probinex (PROBIN)',
  },
  BRMV: {
    Name: 'BRMV',
    Symbol: 'BRMV',
    CoinName: 'BRMV Token',
    FullName: 'BRMV Token (BRMV)',
  },
  STAR: {
    Name: 'STAR',
    Symbol: 'STAR',
    CoinName: 'FileStar',
    FullName: 'FileStar (STAR)',
  },
  GP: {
    Name: 'GP',
    Symbol: 'GP',
    CoinName: 'Wizards And Dragons',
    FullName: 'Wizards And Dragons (GP)',
  },
  LAND: {
    Name: 'LAND',
    Symbol: 'LAND',
    CoinName: 'Landshare',
    FullName: 'Landshare (LAND)',
  },
  MILLI: {
    Name: 'MILLI',
    Symbol: 'MILLI',
    CoinName: 'Million',
    FullName: 'Million (MILLI)',
  },
  NOS: {
    Name: 'NOS',
    Symbol: 'NOS',
    CoinName: 'Nosana',
    FullName: 'Nosana (NOS)',
  },
  WAG: {
    Name: 'WAG',
    Symbol: 'WAG',
    CoinName: 'WagyuSwap',
    FullName: 'WagyuSwap (WAG)',
  },
  MNY: {
    Name: 'MNY',
    Symbol: 'MNY',
    CoinName: 'MoonieNFT',
    FullName: 'MoonieNFT (MNY)',
  },
  GAME: {
    Name: 'GAME',
    Symbol: 'GAME',
    CoinName: 'Gamestarter',
    FullName: 'Gamestarter (GAME)',
  },
  BTL: {
    Name: 'BTL',
    Symbol: 'BTL',
    CoinName: 'Bitlocus',
    FullName: 'Bitlocus (BTL)',
  },
  FOREX: {
    Name: 'FOREX',
    Symbol: 'FOREX',
    CoinName: 'handle.fi',
    FullName: 'handle.fi (FOREX)',
  },
  GOLD: {
    Name: 'GOLD',
    Symbol: 'GOLD',
    CoinName: 'CyberDragon Gold',
    FullName: 'CyberDragon Gold (GOLD)',
  },
  WZENIQ: {
    Name: 'WZENIQ',
    Symbol: 'WZENIQ',
    CoinName: 'Wrapped Zeniq (ETH)',
    FullName: 'Wrapped Zeniq (ETH) (WZENIQ)',
  },
  BZENIQ: {
    Name: 'BZENIQ',
    Symbol: 'BZENIQ',
    CoinName: 'Wrapped Zeniq (BNB)',
    FullName: 'Wrapped Zeniq (BNB) (BZENIQ)',
  },
  TMED: {
    Name: 'TMED',
    Symbol: 'TMED',
    CoinName: 'MDsquare',
    FullName: 'MDsquare (TMED)',
  },
  EPIC: {
    Name: 'EPIC',
    Symbol: 'EPIC',
    CoinName: 'Epic Cash',
    FullName: 'Epic Cash (EPIC)',
  },
  QUASA: {
    Name: 'QUASA',
    Symbol: 'QUASA',
    CoinName: 'Quasacoin',
    FullName: 'Quasacoin (QUASA)',
  },
  SFX: {
    Name: 'SFX',
    Symbol: 'SFX',
    CoinName: 'SUBX FINANCE LAB',
    FullName: 'SUBX FINANCE LAB (SFX)',
  },
  KPOP: {
    Name: 'KPOP',
    Symbol: 'KPOP',
    CoinName: 'KPOP Coin',
    FullName: 'KPOP Coin (KPOP)',
  },
  ZBC: {
    Name: 'ZBC',
    Symbol: 'ZBC',
    CoinName: 'Zebec Protocol',
    FullName: 'Zebec Protocol (ZBC)',
  },
  KTX: {
    Name: 'KTX',
    Symbol: 'KTX',
    CoinName: 'KwikTrust',
    FullName: 'KwikTrust (KTX)',
  },
  TITANO: {
    Name: 'TITANO',
    Symbol: 'TITANO',
    CoinName: 'Titano',
    FullName: 'Titano (TITANO)',
  },
  SCROOGE: {
    Name: 'SCROOGE',
    Symbol: 'SCROOGE',
    CoinName: 'Scrooge',
    FullName: 'Scrooge (SCROOGE)',
  },
  MDN: {
    Name: 'MDN',
    Symbol: 'MDN',
    CoinName: 'Modicoin',
    FullName: 'Modicoin (MDN)',
  },
  CHAINCADE: {
    Name: 'CHAINCADE',
    Symbol: 'CHAINCADE',
    CoinName: 'ChainCade',
    FullName: 'ChainCade (CHAINCADE)',
  },
  TRYC: {
    Name: 'TRYC',
    Symbol: 'TRYC',
    CoinName: 'TRYC',
    FullName: 'TRYC (TRYC)',
  },
  ALI: {
    Name: 'ALI',
    Symbol: 'ALI',
    CoinName: 'Alethea Artificial Liquid Intelligence Token',
    FullName: 'Alethea Artificial Liquid Intelligence Token (ALI)',
  },
  CZZ: {
    Name: 'CZZ',
    Symbol: 'CZZ',
    CoinName: 'ClassZZ',
    FullName: 'ClassZZ (CZZ)',
  },
  FUEL: {
    Name: 'FUEL',
    Symbol: 'FUEL',
    CoinName: 'Jetfuel Finance',
    FullName: 'Jetfuel Finance (FUEL)',
  },
  IMI: {
    Name: 'IMI',
    Symbol: 'IMI',
    CoinName: 'Influencer',
    FullName: 'Influencer (IMI)',
  },
  BOJI: {
    Name: 'BOJI',
    Symbol: 'BOJI',
    CoinName: 'BOJI Token',
    FullName: 'BOJI Token (BOJI)',
  },
  BOBC: {
    Name: 'BOBC',
    Symbol: 'BOBC',
    CoinName: 'Bobcoin',
    FullName: 'Bobcoin (BOBC)',
  },
  GWT: {
    Name: 'GWT',
    Symbol: 'GWT',
    CoinName: 'Galaxy War',
    FullName: 'Galaxy War (GWT)',
  },
  UPO: {
    Name: 'UPO',
    Symbol: 'UPO',
    CoinName: 'UpOnly',
    FullName: 'UpOnly (UPO)',
  },
  LIST: {
    Name: 'KLIST',
    Symbol: 'LIST',
    CoinName: 'KList Protocol',
    FullName: 'KList Protocol (KLIST)',
  },
  MBT: {
    Name: 'MBT',
    Symbol: 'MBT',
    CoinName: 'Metablackout',
    FullName: 'Metablackout (MBT)',
  },
  TANK: {
    Name: 'TANK',
    Symbol: 'TANK',
    CoinName: 'CryptoTanks',
    FullName: 'CryptoTanks (TANK)',
  },
  MDAO: {
    Name: 'MDAO',
    Symbol: 'MDAO',
    CoinName: 'MarsDAO',
    FullName: 'MarsDAO (MDAO)',
  },
  SEON: {
    Name: 'SEON',
    Symbol: 'SEON',
    CoinName: 'Seedon',
    FullName: 'Seedon (SEON)',
  },
  HBTC: {
    Name: 'HBTC',
    Symbol: 'HBTC',
    CoinName: 'Huobi BTC',
    FullName: 'Huobi BTC (HBTC)',
  },
  METAN: {
    Name: 'METAN',
    Symbol: 'METAN',
    CoinName: 'Metan Evolutions',
    FullName: 'Metan Evolutions (METAN)',
  },
  NEKO: {
    Name: 'NEKO',
    Symbol: 'NEKO',
    CoinName: 'The Neko',
    FullName: 'The Neko (NEKO)',
  },
  GR: {
    Name: 'GR',
    Symbol: 'GR',
    CoinName: 'GROM',
    FullName: 'GROM (GR)',
  },
  HMRN: {
    Name: 'HMRN',
    Symbol: 'HMRN',
    CoinName: 'Homerun',
    FullName: 'Homerun (HMRN)',
  },
  RBIF: {
    Name: 'RBIF',
    Symbol: 'RBIF',
    CoinName: 'Robo Inu Finance',
    FullName: 'Robo Inu Finance (RBIF)',
  },
  CHEDDA: {
    Name: 'CHEDDA',
    Symbol: 'CHEDDA',
    CoinName: 'Chedda',
    FullName: 'Chedda (CHEDDA)',
  },
  SOLAR: {
    Name: 'SOLAR',
    Symbol: 'SOLAR',
    CoinName: 'Solar',
    FullName: 'Solar (SOLAR)',
  },
  DINU: {
    Name: 'DINU',
    Symbol: 'DINU',
    CoinName: 'Dogey-Inu',
    FullName: 'Dogey-Inu (DINU)',
  },
  METADOGEV2: {
    Name: 'METADOGEV2',
    Symbol: 'METADOGEV2',
    CoinName: 'MetaDoge V2',
    FullName: 'MetaDoge V2 (METADOGEV2)',
  },
  NUNET: {
    Name: 'NUNET',
    Symbol: 'NUNET',
    CoinName: 'NuNet',
    FullName: 'NuNet (NUNET)',
  },
  ENDLESS: {
    Name: 'ENDLESS',
    Symbol: 'ENDLESS',
    CoinName: 'Endless Board Game',
    FullName: 'Endless Board Game (ENDLESS)',
  },
  ARTY: {
    Name: 'ARTY',
    Symbol: 'ARTY',
    CoinName: 'Artyfact',
    FullName: 'Artyfact (ARTY)',
  },
  MMF: {
    Name: 'MMF',
    Symbol: 'MMF',
    CoinName: 'MMFinance',
    FullName: 'MMFinance (MMF)',
  },
  PHBD: {
    Name: 'PHBD',
    Symbol: 'PHBD',
    CoinName: 'Polygon HBD',
    FullName: 'Polygon HBD (PHBD)',
  },
  LMR: {
    Name: 'LMR',
    Symbol: 'LMR',
    CoinName: 'Lumerin',
    FullName: 'Lumerin (LMR)',
  },
  SCREAM: {
    Name: 'SCREAM',
    Symbol: 'SCREAM',
    CoinName: 'Scream',
    FullName: 'Scream (SCREAM)',
  },
  BRWL: {
    Name: 'BRWL',
    Symbol: 'BRWL',
    CoinName: 'Blockchain Brawlers',
    FullName: 'Blockchain Brawlers (BRWL)',
  },
  VISION: {
    Name: 'VISION',
    Symbol: 'VISION',
    CoinName: 'VisionGame',
    FullName: 'VisionGame (VISION)',
  },
  HOLY: {
    Name: 'HOLY',
    Symbol: 'HOLY',
    CoinName: 'Holy Trinity',
    FullName: 'Holy Trinity (HOLY)',
  },
  WFLOW: {
    Name: 'WFLOW',
    Symbol: 'WFLOW',
    CoinName: 'Wrapped Flow',
    FullName: 'Wrapped Flow (WFLOW)',
  },
  HDAO: {
    Name: 'HDAO',
    Symbol: 'HDAO',
    CoinName: 'Hkd.com Dao',
    FullName: 'Hkd.com Dao (HDAO)',
  },
  INDI: {
    Name: 'INDI',
    Symbol: 'INDI',
    CoinName: 'IndiGG',
    FullName: 'IndiGG (INDI)',
  },
  BLURT: {
    Name: 'BLURT',
    Symbol: 'BLURT',
    CoinName: 'Blurt',
    FullName: 'Blurt (BLURT)',
  },
  ROCK: {
    Name: 'ROCK',
    Symbol: 'ROCK',
    CoinName: 'Bedrock',
    FullName: 'Bedrock (ROCK)',
  },
  CAW: {
    Name: 'CAW',
    Symbol: 'CAW',
    CoinName: 'A Hunters Dream',
    FullName: 'A Hunters Dream (CAW)',
  },
  CHIRP: {
    Name: 'CHIRP',
    Symbol: 'CHIRP',
    CoinName: 'Chirp',
    FullName: 'Chirp (CHIRP)',
  },
  QMALL: {
    Name: 'QMALL',
    Symbol: 'QMALL',
    CoinName: 'QMALL TOKEN',
    FullName: 'QMALL TOKEN (QMALL)',
  },
  SQUAWK: {
    Name: 'SQUAWK',
    Symbol: 'SQUAWK',
    CoinName: 'Squawk',
    FullName: 'Squawk (SQUAWK)',
  },
  DOLZ: {
    Name: 'DOLZ',
    Symbol: 'DOLZ',
    CoinName: 'DOLZ',
    FullName: 'DOLZ (DOLZ)',
  },
  GHCOLD: {
    Name: 'GHCOLD',
    Symbol: 'GHCOLD',
    CoinName: 'Galaxy Heroes Coin',
    FullName: 'Galaxy Heroes Coin (GHCOLD)',
  },
  C2X: {
    Name: 'C2X',
    Symbol: 'C2X',
    CoinName: 'C2X',
    FullName: 'C2X (C2X)',
  },
  KBOX: {
    Name: 'KBOX',
    Symbol: 'KBOX',
    CoinName: 'The Killbox',
    FullName: 'The Killbox (KBOX)',
  },
  SKET: {
    Name: 'SKET',
    Symbol: 'SKET',
    CoinName: 'Sketch coin',
    FullName: 'Sketch coin (SKET)',
  },
  GDX: {
    Name: 'GDX',
    Symbol: 'GDX',
    CoinName: 'VanEck Vectors Gold Miners Etf',
    FullName: 'VanEck Vectors Gold Miners Etf (GDX)',
  },
  JCR: {
    Name: 'JCR',
    Symbol: 'JCR',
    CoinName: 'JustCarbon Removal',
    FullName: 'JustCarbon Removal (JCR)',
  },
  MMETA: {
    Name: 'MMETA',
    Symbol: 'MMETA',
    CoinName: 'Duckie Land Multi Metaverse',
    FullName: 'Duckie Land Multi Metaverse (MMETA)',
  },
  BOT: {
    Name: 'BOT',
    Symbol: 'BOT',
    CoinName: 'Bot Planet',
    FullName: 'Bot Planet (BOT)',
  },
  ORB: {
    Name: 'ORB',
    Symbol: 'ORB',
    CoinName: 'KlayCity ORB',
    FullName: 'KlayCity ORB (ORB)',
  },
  FINA: {
    Name: 'FINA',
    Symbol: 'FINA',
    CoinName: 'Defina Finance',
    FullName: 'Defina Finance (FINA)',
  },
  TAKI: {
    Name: 'TAKI',
    Symbol: 'TAKI',
    CoinName: 'Taki',
    FullName: 'Taki (TAKI)',
  },
  PHB: {
    Name: 'PHB',
    Symbol: 'PHB',
    CoinName: 'Phoenix Global [v2]',
    FullName: 'Phoenix Global [v2] (PHB)',
  },
  AFIT: {
    Name: 'AFIT',
    Symbol: 'AFIT',
    CoinName: 'Actifit',
    FullName: 'Actifit (AFIT)',
  },
  MEVR: {
    Name: 'MEVR',
    Symbol: 'MEVR',
    CoinName: 'Metaverse VR',
    FullName: 'Metaverse VR (MEVR)',
  },
  CATCOIN: {
    Name: 'CATCOIN',
    Symbol: 'CATCOIN',
    CoinName: 'CatCoin Cash',
    FullName: 'CatCoin Cash (CATCOIN)',
  },
  MU: {
    Name: 'MU',
    Symbol: 'MU',
    CoinName: 'Miracle Universe',
    FullName: 'Miracle Universe (MU)',
  },
  MSQ: {
    Name: 'MSQ',
    Symbol: 'MSQ',
    CoinName: 'MSquare Global',
    FullName: 'MSquare Global (MSQ)',
  },
  H2O: {
    Name: 'H2O',
    Symbol: 'H2O',
    CoinName: 'H2O Dao',
    FullName: 'H2O Dao (H2O)',
  },
  CSR: {
    Name: 'CSR',
    Symbol: 'CSR',
    CoinName: 'Cashera',
    FullName: 'Cashera (CSR)',
  },
  VTG: {
    Name: 'VTG',
    Symbol: 'VTG',
    CoinName: 'Victory Gem',
    FullName: 'Victory Gem (VTG)',
  },
  MISA: {
    Name: 'MISA',
    Symbol: 'MISA',
    CoinName: 'Sangkara',
    FullName: 'Sangkara (MISA)',
  },
  XRUN: {
    Name: 'XRUN',
    Symbol: 'XRUN',
    CoinName: 'XRun',
    FullName: 'XRun (XRUN)',
  },
  PLSD: {
    Name: 'PLSD',
    Symbol: 'PLSD',
    CoinName: 'PulseDogecoin',
    FullName: 'PulseDogecoin (PLSD)',
  },
  JTS: {
    Name: 'JTS',
    Symbol: 'JTS',
    CoinName: 'Jetset',
    FullName: 'Jetset (JTS)',
  },
  J9BC: {
    Name: 'J9BC',
    Symbol: 'J9BC',
    CoinName: 'J9CASINO',
    FullName: 'J9CASINO (J9BC)',
  },
  SOY: {
    Name: 'SOY',
    Symbol: 'SOY',
    CoinName: 'Soy Finance',
    FullName: 'Soy Finance (SOY)',
  },
  MOVEY: {
    Name: 'MOVEY',
    Symbol: 'MOVEY',
    CoinName: 'Movey',
    FullName: 'Movey (MOVEY)',
  },
  CREO: {
    Name: 'CREO',
    Symbol: 'CREO',
    CoinName: 'Creo Engine',
    FullName: 'Creo Engine (CREO)',
  },
  BONTE: {
    Name: 'BONTE',
    Symbol: 'BONTE',
    CoinName: 'Bontecoin',
    FullName: 'Bontecoin (BONTE)',
  },
  USDD: {
    Name: 'USDD',
    Symbol: 'USDD',
    CoinName: 'USDD',
    FullName: 'USDD (USDD)',
  },
  KRD: {
    Name: 'KRD',
    Symbol: 'KRD',
    CoinName: 'Krypton DAO',
    FullName: 'Krypton DAO (KRD)',
  },
  UNDEAD: {
    Name: 'UNDEAD',
    Symbol: 'UNDEAD',
    CoinName: 'Undead Blocks',
    FullName: 'Undead Blocks (UNDEAD)',
  },
  SLEEPEE: {
    Name: 'SLEEPEE',
    Symbol: 'SLEEPEE',
    CoinName: 'SleepFuture',
    FullName: 'SleepFuture (SLEEPEE)',
  },
  PETO: {
    Name: 'PETO',
    Symbol: 'PETO',
    CoinName: 'Petoverse',
    FullName: 'Petoverse (PETO)',
  },
  FLD: {
    Name: 'FLD',
    Symbol: 'FLD',
    CoinName: 'FLUID',
    FullName: 'FLUID (FLD)',
  },
  GARTS: {
    Name: 'GARTS',
    Symbol: 'GARTS',
    CoinName: 'Glink Arts Share',
    FullName: 'Glink Arts Share (GARTS)',
  },
  TWD: {
    Name: 'TWD',
    Symbol: 'TWD',
    CoinName: 'Terra World Token',
    FullName: 'Terra World Token (TWD)',
  },
  JM: {
    Name: 'JM',
    Symbol: 'JM',
    CoinName: 'JustMoney',
    FullName: 'JustMoney (JM)',
  },
  FITFI: {
    Name: 'FITFI',
    Symbol: 'FITFI',
    CoinName: 'Step App',
    FullName: 'Step App (FITFI)',
  },
  SIPHER: {
    Name: 'SIPHER',
    Symbol: 'SIPHER',
    CoinName: 'Sipher',
    FullName: 'Sipher (SIPHER)',
  },
  GRVE: {
    Name: 'GRVE',
    Symbol: 'GRVE',
    CoinName: 'Grave',
    FullName: 'Grave (GRVE)',
  },
  BNSOLD: {
    Name: 'BNSOLD',
    Symbol: 'BNSOLD',
    CoinName: 'BNS token ',
    FullName: 'BNS token  (BNSOLD)',
  },
  ZONX: {
    Name: 'ZONX',
    Symbol: 'ZONX',
    CoinName: 'METAZONX',
    FullName: 'METAZONX (ZONX)',
  },
  OMNIA: {
    Name: 'OMNIA',
    Symbol: 'OMNIA',
    CoinName: 'OmniaVerse',
    FullName: 'OmniaVerse (OMNIA)',
  },
  ASUNA: {
    Name: 'ASUNA',
    Symbol: 'ASUNA',
    CoinName: 'Asuna Hentai',
    FullName: 'Asuna Hentai (ASUNA)',
  },
  N3DR: {
    Name: 'N3DR',
    Symbol: 'N3DR',
    CoinName: 'NeorderDAO ',
    FullName: 'NeorderDAO  (N3DR)',
  },
  THRY: {
    Name: 'THRY',
    Symbol: 'THRY',
    CoinName: 'THEORY',
    FullName: 'THEORY (THRY)',
  },
  METAPK: {
    Name: 'METAPK',
    Symbol: 'METAPK',
    CoinName: 'Metapocket',
    FullName: 'Metapocket (METAPK)',
  },
  ELONCAT: {
    Name: 'ELONCAT',
    Symbol: 'ELONCAT',
    CoinName: 'ELON CAT COIN',
    FullName: 'ELON CAT COIN (ELONCAT)',
  },
  FLOVM: {
    Name: 'FLOVM',
    Symbol: 'FLOVM',
    CoinName: 'FLOV MARKET',
    FullName: 'FLOV MARKET (FLOVM)',
  },
  KS2: {
    Name: 'KS2',
    Symbol: 'KS2',
    CoinName: 'Kingdomswap',
    FullName: 'Kingdomswap (KS2)',
  },
  HONOR: {
    Name: 'HONOR',
    Symbol: 'HONOR',
    CoinName: 'HonorLand',
    FullName: 'HonorLand (HONOR)',
  },
  CRA: {
    Name: 'CRA',
    Symbol: 'CRA',
    CoinName: 'Crabada',
    FullName: 'Crabada (CRA)',
  },
  SLEEP: {
    Name: 'SLEEP',
    Symbol: 'SLEEP',
    CoinName: 'Sleep Ecosystem',
    FullName: 'Sleep Ecosystem (SLEEP)',
  },
  MDB: {
    Name: 'MDB',
    Symbol: 'MDB',
    CoinName: 'Million Dollar Baby',
    FullName: 'Million Dollar Baby (MDB)',
  },
  MOVON: {
    Name: 'MOVON',
    Symbol: 'MOVON',
    CoinName: 'MovingOn Finance',
    FullName: 'MovingOn Finance (MOVON)',
  },
  FURY: {
    Name: 'FURY',
    Symbol: 'FURY',
    CoinName: 'Engines of Fury',
    FullName: 'Engines of Fury (FURY)',
  },
  SFD: {
    Name: 'SFD',
    Symbol: 'SFD',
    CoinName: 'SafeDeal',
    FullName: 'SafeDeal (SFD)',
  },
  CPD: {
    Name: 'CPD',
    Symbol: 'CPD',
    CoinName: 'CoinsPaid',
    FullName: 'CoinsPaid (CPD)',
  },
  BHEROES: {
    Name: 'BHEROES',
    Symbol: 'BHEROES',
    CoinName: 'BombHeroes coin',
    FullName: 'BombHeroes coin (BHEROES)',
  },
  MAPE: {
    Name: 'MAPE',
    Symbol: 'MAPE',
    CoinName: 'Mecha Morphing',
    FullName: 'Mecha Morphing (MAPE)',
  },
  EVMOS: {
    Name: 'EVMOS',
    Symbol: 'EVMOS',
    CoinName: 'Evmos',
    FullName: 'Evmos (EVMOS)',
  },
  AETHC: {
    Name: 'AETHC',
    Symbol: 'AETHC',
    CoinName: 'Ankr Reward-Bearing Staked ETH',
    FullName: 'Ankr Reward-Bearing Staked ETH (AETHC)',
  },
  SVT: {
    Name: 'SVT',
    Symbol: 'SVT',
    CoinName: 'Solvent',
    FullName: 'Solvent (SVT)',
  },
  KZEN: {
    Name: 'KZEN',
    Symbol: 'KZEN',
    CoinName: 'Kaizen',
    FullName: 'Kaizen (KZEN)',
  },
  HDV: {
    Name: 'HDV',
    Symbol: 'HDV',
    CoinName: 'Hydraverse',
    FullName: 'Hydraverse (HDV)',
  },
  EGS: {
    Name: 'EGS',
    Symbol: 'EGS',
    CoinName: 'EdgeSwap',
    FullName: 'EdgeSwap (EGS)',
  },
  DOGA: {
    Name: 'DOGA',
    Symbol: 'DOGA',
    CoinName: 'Dogami',
    FullName: 'Dogami (DOGA)',
  },
  POLYPAD: {
    Name: 'POLYPAD',
    Symbol: 'POLYPAD',
    CoinName: 'PolyPad',
    FullName: 'PolyPad (POLYPAD)',
  },
  DOMI: {
    Name: 'DOMI',
    Symbol: 'DOMI',
    CoinName: 'Domi',
    FullName: 'Domi (DOMI)',
  },
  METO: {
    Name: 'METO',
    Symbol: 'METO',
    CoinName: 'Metafluence',
    FullName: 'Metafluence (METO)',
  },
  RBLS: {
    Name: 'RBLS',
    Symbol: 'RBLS',
    CoinName: 'Rebel Bots',
    FullName: 'Rebel Bots (RBLS)',
  },
  ELU: {
    Name: 'ELU',
    Symbol: 'ELU',
    CoinName: 'Elumia',
    FullName: 'Elumia (ELU)',
  },
  SAO: {
    Name: 'SAO',
    Symbol: 'SAO',
    CoinName: 'Sator',
    FullName: 'Sator (SAO)',
  },
  ALTB: {
    Name: 'ALTB',
    Symbol: 'ALTB',
    CoinName: 'Altbase',
    FullName: 'Altbase (ALTB)',
  },
  ADAPAD: {
    Name: 'ADAPAD',
    Symbol: 'ADAPAD',
    CoinName: 'ADAPad',
    FullName: 'ADAPad (ADAPAD)',
  },
  KART: {
    Name: 'KART',
    Symbol: 'KART',
    CoinName: 'Dragon Kart',
    FullName: 'Dragon Kart (KART)',
  },
  CHICKS: {
    Name: 'CHICKS',
    Symbol: 'CHICKS',
    CoinName: 'SolChicks',
    FullName: 'SolChicks (CHICKS)',
  },
  '1EARTH': {
    Name: '1EARTH',
    Symbol: '1EARTH',
    CoinName: 'EarthFund',
    FullName: 'EarthFund (1EARTH)',
  },
  DEHUB: {
    Name: 'DEHUB',
    Symbol: 'DEHUB',
    CoinName: 'DeHub',
    FullName: 'DeHub (DEHUB)',
  },
  AOG: {
    Name: 'AOG',
    Symbol: 'AOG',
    CoinName: 'AgeOfGods',
    FullName: 'AgeOfGods (AOG)',
  },
  MYRA: {
    Name: 'MYRA',
    Symbol: 'MYRA',
    CoinName: 'Mytheria',
    FullName: 'Mytheria (MYRA)',
  },
  DANA: {
    Name: 'DANA',
    Symbol: 'DANA',
    CoinName: 'Ardana',
    FullName: 'Ardana (DANA)',
  },
  MODA: {
    Name: 'MODA',
    Symbol: 'MODA',
    CoinName: 'MODA DAO',
    FullName: 'MODA DAO (MODA)',
  },
  DAL: {
    Name: 'DAL',
    Symbol: 'DAL',
    CoinName: 'DAOLaunch',
    FullName: 'DAOLaunch (DAL)',
  },
  SWAY: {
    Name: 'SWAY',
    Symbol: 'SWAY',
    CoinName: 'Sway Social',
    FullName: 'Sway Social (SWAY)',
  },
  ICONS: {
    Name: 'ICONS',
    Symbol: 'ICONS',
    CoinName: 'SportsIcon',
    FullName: 'SportsIcon (ICONS)',
  },
  WEAR: {
    Name: 'WEAR',
    Symbol: 'WEAR',
    CoinName: 'MetaWear',
    FullName: 'MetaWear (WEAR)',
  },
  MHUNT: {
    Name: 'MHUNT',
    Symbol: 'MHUNT',
    CoinName: 'MetaShooter',
    FullName: 'MetaShooter (MHUNT)',
  },
  GQ: {
    Name: 'GQ',
    Symbol: 'GQ',
    CoinName: 'Galactic Quadrant',
    FullName: 'Galactic Quadrant (GQ)',
  },
  SCY: {
    Name: 'SCY',
    Symbol: 'SCY',
    CoinName: 'Synchrony',
    FullName: 'Synchrony (SCY)',
  },
  ZLK: {
    Name: 'ZLK',
    Symbol: 'ZLK',
    CoinName: 'Zenlink Network',
    FullName: 'Zenlink Network (ZLK)',
  },
  BAKED: {
    Name: 'BAKED',
    Symbol: 'BAKED',
    CoinName: 'Baked',
    FullName: 'Baked (BAKED)',
  },
  SPIRIT: {
    Name: 'SPIRIT',
    Symbol: 'SPIRIT',
    CoinName: 'SpiritSwap',
    FullName: 'SpiritSwap (SPIRIT)',
  },
  SONAR: {
    Name: 'SONAR',
    Symbol: 'SONAR',
    CoinName: 'SonarWatch',
    FullName: 'SonarWatch (SONAR)',
  },
  ENNO: {
    Name: 'ENNO',
    Symbol: 'ENNO',
    CoinName: 'ENNO Cash',
    FullName: 'ENNO Cash (ENNO)',
  },
  EJS: {
    Name: 'EJS',
    Symbol: 'EJS',
    CoinName: 'Enjinstarter',
    FullName: 'Enjinstarter (EJS)',
  },
  CHEQ: {
    Name: 'CHEQ',
    Symbol: 'CHEQ',
    CoinName: 'CHEQD Network',
    FullName: 'CHEQD Network (CHEQ)',
  },
  NRFB: {
    Name: 'NRFB',
    Symbol: 'NRFB',
    CoinName: 'NuriFootBall',
    FullName: 'NuriFootBall (NRFB)',
  },
  FIWA: {
    Name: 'FIWA',
    Symbol: 'FIWA',
    CoinName: 'Defi Warrior',
    FullName: 'Defi Warrior (FIWA)',
  },
  EDT: {
    Name: 'EDT',
    Symbol: 'EDT',
    CoinName: 'E-Drive Token',
    FullName: 'E-Drive Token (EDT)',
  },
  VST: {
    Name: 'VST',
    Symbol: 'VST',
    CoinName: 'VentiSwap',
    FullName: 'VentiSwap (VST)',
  },
  BUSY: {
    Name: 'BUSY',
    Symbol: 'BUSY',
    CoinName: 'Busy DAO',
    FullName: 'Busy DAO (BUSY)',
  },
  HID: {
    Name: 'HID',
    Symbol: 'HID',
    CoinName: 'Hypersign Identity',
    FullName: 'Hypersign Identity (HID)',
  },
  BLOCKIFY: {
    Name: 'BLOCKIFY',
    Symbol: 'BLOCKIFY',
    CoinName: 'Blockify.Games',
    FullName: 'Blockify.Games (BLOCKIFY)',
  },
  GRBE: {
    Name: 'GRBE',
    Symbol: 'GRBE',
    CoinName: 'Green Beli',
    FullName: 'Green Beli (GRBE)',
  },
  ATK: {
    Name: 'ATK',
    Symbol: 'ATK',
    CoinName: 'Attack Wagon',
    FullName: 'Attack Wagon (ATK)',
  },
  AFK: {
    Name: 'AFK',
    Symbol: 'AFK',
    CoinName: 'AFKDAO',
    FullName: 'AFKDAO (AFK)',
  },
  MENGO: {
    Name: 'MENGO',
    Symbol: 'MENGO',
    CoinName: 'Flamengo Fan Token',
    FullName: 'Flamengo Fan Token (MENGO)',
  },
  PIXEL: {
    Name: 'PIXEL',
    Symbol: 'PIXEL',
    CoinName: 'PixelVerse',
    FullName: 'PixelVerse (PIXEL)',
  },
  JCG: {
    Name: 'JCG',
    Symbol: 'JCG',
    CoinName: 'JustCarbon Governance',
    FullName: 'JustCarbon Governance (JCG)',
  },
  GDAO: {
    Name: 'GDAO',
    Symbol: 'GDAO',
    CoinName: 'Governor DAO',
    FullName: 'Governor DAO (GDAO)',
  },
  MOOO: {
    Name: 'MOOO',
    Symbol: 'MOOO',
    CoinName: 'Hashtagger',
    FullName: 'Hashtagger (MOOO)',
  },
  NSDX: {
    Name: 'NSDX',
    Symbol: 'NSDX',
    CoinName: 'NASDEX',
    FullName: 'NASDEX (NSDX)',
  },
  XPRESS: {
    Name: 'XPRESS',
    Symbol: 'XPRESS',
    CoinName: 'CryptoXpress',
    FullName: 'CryptoXpress (XPRESS)',
  },
  HIBIKI: {
    Name: 'HIBIKI',
    Symbol: 'HIBIKI',
    CoinName: 'Hibiki Finance',
    FullName: 'Hibiki Finance (HIBIKI)',
  },
  DEUS: {
    Name: 'DEUS',
    Symbol: 'DEUS',
    CoinName: 'DEUS Finance',
    FullName: 'DEUS Finance (DEUS)',
  },
  SAVG: {
    Name: 'SAVG',
    Symbol: 'SAVG',
    CoinName: 'SAVAGE',
    FullName: 'SAVAGE (SAVG)',
  },
  PRIDE: {
    Name: 'PRIDE',
    Symbol: 'PRIDE',
    CoinName: 'Nomad Exiles',
    FullName: 'Nomad Exiles (PRIDE)',
  },
  PPAD: {
    Name: 'PPAD',
    Symbol: 'PPAD',
    CoinName: 'PlayPad',
    FullName: 'PlayPad (PPAD)',
  },
  HATI: {
    Name: 'HATI',
    Symbol: 'HATI',
    CoinName: 'Hati',
    FullName: 'Hati (HATI)',
  },
  BUC: {
    Name: 'BUC',
    Symbol: 'BUC',
    CoinName: 'Beau Cat',
    FullName: 'Beau Cat (BUC)',
  },
  VDR: {
    Name: 'VDR',
    Symbol: 'VDR',
    CoinName: 'Vodra',
    FullName: 'Vodra (VDR)',
  },
  KCT: {
    Name: 'KCT',
    Symbol: 'KCT',
    CoinName: 'Konnect',
    FullName: 'Konnect (KCT)',
  },
  ZONE: {
    Name: 'ZONE',
    Symbol: 'ZONE',
    CoinName: 'Zone',
    FullName: 'Zone (ZONE)',
  },
  AMZE: {
    Name: 'AMZE',
    Symbol: 'AMZE',
    CoinName: 'The Amaze World',
    FullName: 'The Amaze World (AMZE)',
  },
  SINGLE: {
    Name: 'SINGLE',
    Symbol: 'SINGLE',
    CoinName: 'Single Finance',
    FullName: 'Single Finance (SINGLE)',
  },
  TTK: {
    Name: 'TTK',
    Symbol: 'TTK',
    CoinName: 'The Three Kingdoms',
    FullName: 'The Three Kingdoms (TTK)',
  },
  SPG: {
    Name: 'SPG',
    Symbol: 'SPG',
    CoinName: 'Space Crypto',
    FullName: 'Space Crypto (SPG)',
  },
  WAM: {
    Name: 'WAM',
    Symbol: 'WAM',
    CoinName: 'Wam',
    FullName: 'Wam (WAM)',
  },
  PLSPAD: {
    Name: 'PLSPAD',
    Symbol: 'PLSPAD',
    CoinName: 'PulsePad',
    FullName: 'PulsePad (PLSPAD)',
  },
  WOJ: {
    Name: 'WOJ',
    Symbol: 'WOJ',
    CoinName: 'Wojak Finance',
    FullName: 'Wojak Finance (WOJ)',
  },
  MPC: {
    Name: 'MPC',
    Symbol: 'MPC',
    CoinName: 'Metaplace',
    FullName: 'Metaplace (MPC)',
  },
  ARCX: {
    Name: 'ARCX',
    Symbol: 'ARCX',
    CoinName: 'ARC Governance',
    FullName: 'ARC Governance (ARCX)',
  },
  NATION: {
    Name: 'NATION',
    Symbol: 'NATION',
    CoinName: 'Nation3',
    FullName: 'Nation3 (NATION)',
  },
  NFUP: {
    Name: 'NFUP',
    Symbol: 'NFUP',
    CoinName: 'Natural Farm Union Protocol',
    FullName: 'Natural Farm Union Protocol (NFUP)',
  },
  ALPH: {
    Name: 'ALPH',
    Symbol: 'ALPH',
    CoinName: 'Alephium',
    FullName: 'Alephium (ALPH)',
  },
  CHER: {
    Name: 'CHER',
    Symbol: 'CHER',
    CoinName: 'Cherry Network',
    FullName: 'Cherry Network (CHER)',
  },
  SEAT: {
    Name: 'SEAT',
    Symbol: 'SEAT',
    CoinName: 'SeatlabNFT',
    FullName: 'SeatlabNFT (SEAT)',
  },
  MBP: {
    Name: 'MBP',
    Symbol: 'MBP',
    CoinName: 'MobiPad',
    FullName: 'MobiPad (MBP)',
  },
  CLOUD: {
    Name: 'CLOUD',
    Symbol: 'CLOUD',
    CoinName: 'Metacloud',
    FullName: 'Metacloud (CLOUD)',
  },
  BXH: {
    Name: 'BXH',
    Symbol: 'BXH',
    CoinName: 'BXH',
    FullName: 'BXH (BXH)',
  },
  FTRB: {
    Name: 'FTRB',
    Symbol: 'FTRB',
    CoinName: 'Faith Tribe',
    FullName: 'Faith Tribe (FTRB)',
  },
  TBE: {
    Name: 'TBE',
    Symbol: 'TBE',
    CoinName: 'TrustBase',
    FullName: 'TrustBase (TBE)',
  },
  PLACE: {
    Name: 'PLACE',
    Symbol: 'PLACE',
    CoinName: 'PlaceWar Governance',
    FullName: 'PlaceWar Governance (PLACE)',
  },
  PLATO: {
    Name: 'PLATO',
    Symbol: 'PLATO',
    CoinName: 'Plato Game',
    FullName: 'Plato Game (PLATO)',
  },
  LUNA: {
    Name: 'LUNA',
    Symbol: 'LUNA',
    CoinName: 'Terra',
    FullName: 'Terra (LUNA)',
  },
  DRC: {
    Name: 'DRC',
    Symbol: 'DRC',
    CoinName: 'DRC Mobility',
    FullName: 'DRC Mobility (DRC)',
  },
  DV: {
    Name: 'DV',
    Symbol: 'DV',
    CoinName: 'Dreamverse',
    FullName: 'Dreamverse (DV)',
  },
  ZODI: {
    Name: 'ZODI',
    Symbol: 'ZODI',
    CoinName: 'Zodium',
    FullName: 'Zodium (ZODI)',
  },
  FNCY: {
    Name: 'FNCY',
    Symbol: 'FNCY',
    CoinName: 'Fancy That',
    FullName: 'Fancy That (FNCY)',
  },
  ESG: {
    Name: 'ESG',
    Symbol: 'ESG',
    CoinName: 'ESG',
    FullName: 'ESG (ESG)',
  },
  MELI: {
    Name: 'MELI',
    Symbol: 'MELI',
    CoinName: 'Meli Games',
    FullName: 'Meli Games (MELI)',
  },
  GMM: {
    Name: 'GMM',
    Symbol: 'GMM',
    CoinName: 'Gamium',
    FullName: 'Gamium (GMM)',
  },
  PRARE: {
    Name: 'PRARE',
    Symbol: 'PRARE',
    CoinName: 'Polkarare',
    FullName: 'Polkarare (PRARE)',
  },
  EVOL: {
    Name: 'EVOL',
    Symbol: 'EVOL',
    CoinName: 'EVOL NETWORK',
    FullName: 'EVOL NETWORK (EVOL)',
  },
  RITE: {
    Name: 'RITE',
    Symbol: 'RITE',
    CoinName: 'ritestream',
    FullName: 'ritestream (RITE)',
  },
  KBD: {
    Name: 'KBD',
    Symbol: 'KBD',
    CoinName: 'Kyberdyne',
    FullName: 'Kyberdyne (KBD)',
  },
  ISKY: {
    Name: 'ISKY',
    Symbol: 'ISKY',
    CoinName: 'Infinity Skies',
    FullName: 'Infinity Skies (ISKY)',
  },
  SCNSOL: {
    Name: 'SCNSOL',
    Symbol: 'SCNSOL',
    CoinName: 'Socean Staked Sol',
    FullName: 'Socean Staked Sol (SCNSOL)',
  },
  RUBYEX: {
    Name: 'RUBYEX',
    Symbol: 'RUBYEX',
    CoinName: 'Ruby.Exchange',
    FullName: 'Ruby.Exchange (RUBYEX)',
  },
  '3ULL': {
    Name: '3ULL',
    Symbol: '3ULL',
    CoinName: '3ULL Coin',
    FullName: '3ULL Coin (3ULL)',
  },
  FIC: {
    Name: 'FIC',
    Symbol: 'FIC',
    CoinName: 'Filecash',
    FullName: 'Filecash (FIC)',
  },
  STZ: {
    Name: 'STZ',
    Symbol: 'STZ',
    CoinName: '99Starz',
    FullName: '99Starz (STZ)',
  },
  RIN: {
    Name: 'RIN',
    Symbol: 'RIN',
    CoinName: 'Aldrin',
    FullName: 'Aldrin (RIN)',
  },
  CANTI: {
    Name: 'CANTI',
    Symbol: 'CANTI',
    CoinName: 'Cantina Royale',
    FullName: 'Cantina Royale (CANTI)',
  },
  BLA: {
    Name: 'BLA',
    Symbol: 'BLA',
    CoinName: 'BlaBlaGame',
    FullName: 'BlaBlaGame (BLA)',
  },
  BCMC: {
    Name: 'BCMC',
    Symbol: 'BCMC',
    CoinName: 'Blockchain Monster Hunt',
    FullName: 'Blockchain Monster Hunt (BCMC)',
  },
  ARTEM: {
    Name: 'ARTEM',
    Symbol: 'ARTEM',
    CoinName: 'Artem',
    FullName: 'Artem (ARTEM)',
  },
  BBF: {
    Name: 'BBF',
    Symbol: 'BBF',
    CoinName: 'Bubblefong',
    FullName: 'Bubblefong (BBF)',
  },
  BRZN: {
    Name: 'BRZN',
    Symbol: 'BRZN',
    CoinName: 'Brayzin',
    FullName: 'Brayzin (BRZN)',
  },
  POLI: {
    Name: 'POLI',
    Symbol: 'POLI',
    CoinName: 'Polinate',
    FullName: 'Polinate (POLI)',
  },
  SKYRIM: {
    Name: 'SKYRIM',
    Symbol: 'SKYRIM',
    CoinName: 'Skyrim Finance',
    FullName: 'Skyrim Finance (SKYRIM)',
  },
  WOLFILAND: {
    Name: 'WOLFILAND',
    Symbol: 'WOLFILAND',
    CoinName: 'Wolfiland',
    FullName: 'Wolfiland (WOLFILAND)',
  },
  PCR: {
    Name: 'PCR',
    Symbol: 'PCR',
    CoinName: 'Paycer Protocol',
    FullName: 'Paycer Protocol (PCR)',
  },
  BATS: {
    Name: 'BATS',
    Symbol: 'BATS',
    CoinName: 'Batcoin',
    FullName: 'Batcoin (BATS)',
  },
  WNZ: {
    Name: 'WNZ',
    Symbol: 'WNZ',
    CoinName: 'Winerz',
    FullName: 'Winerz (WNZ)',
  },
  CBOT: {
    Name: 'CBOT',
    Symbol: 'CBOT',
    CoinName: 'C-BOT',
    FullName: 'C-BOT (CBOT)',
  },
  FAR: {
    Name: 'FAR',
    Symbol: 'FAR',
    CoinName: 'Farmland Protocol',
    FullName: 'Farmland Protocol (FAR)',
  },
  MCASH: {
    Name: 'MCASH',
    Symbol: 'MCASH',
    CoinName: 'Monsoon Finance',
    FullName: 'Monsoon Finance (MCASH)',
  },
  MIMIR: {
    Name: 'MIMIR',
    Symbol: 'MIMIR',
    CoinName: 'Mimir',
    FullName: 'Mimir (MIMIR)',
  },
  VELOX: {
    Name: 'VELOX',
    Symbol: 'VELOX',
    CoinName: 'Velox',
    FullName: 'Velox (VELOX)',
  },
  POG: {
    Name: 'POG',
    Symbol: 'POG',
    CoinName: 'PolygonumOnline',
    FullName: 'PolygonumOnline (POG)',
  },
  NBP: {
    Name: 'NBP',
    Symbol: 'NBP',
    CoinName: 'NFTBomb',
    FullName: 'NFTBomb (NBP)',
  },
  SANDWICH: {
    Name: 'SANDWICH',
    Symbol: 'SANDWICH',
    CoinName: ' Sandwich Network',
    FullName: ' Sandwich Network (SANDWICH)',
  },
  UBXS: {
    Name: 'UBXS',
    Symbol: 'UBXS',
    CoinName: 'UBXS',
    FullName: 'UBXS (UBXS)',
  },
  MDF: {
    Name: 'MDF',
    Symbol: 'MDF',
    CoinName: 'MatrixETF',
    FullName: 'MatrixETF (MDF)',
  },
  ZEUM: {
    Name: 'ZEUM',
    Symbol: 'ZEUM',
    CoinName: 'Colizeum',
    FullName: 'Colizeum (ZEUM)',
  },
  VSO: {
    Name: 'VSO',
    Symbol: 'VSO',
    CoinName: 'Verso',
    FullName: 'Verso (VSO)',
  },
  SB: {
    Name: 'SB',
    Symbol: 'SB',
    CoinName: 'DragonSB',
    FullName: 'DragonSB (SB)',
  },
  QTC: {
    Name: 'QTC',
    Symbol: 'QTC',
    CoinName: 'Qitcoin',
    FullName: 'Qitcoin (QTC)',
  },
  IOEN: {
    Name: 'IOEN',
    Symbol: 'IOEN',
    CoinName: 'Internet of Energy Network',
    FullName: 'Internet of Energy Network (IOEN)',
  },
  GOFX: {
    Name: 'GOFX',
    Symbol: 'GOFX',
    CoinName: 'GooseFX',
    FullName: 'GooseFX (GOFX)',
  },
  DELFI: {
    Name: 'DELFI',
    Symbol: 'DELFI',
    CoinName: 'DeltaFi',
    FullName: 'DeltaFi (DELFI)',
  },
  HVT: {
    Name: 'HVT',
    Symbol: 'HVT',
    CoinName: 'HyperVerse',
    FullName: 'HyperVerse (HVT)',
  },
  BAXS: {
    Name: 'BAXS',
    Symbol: 'BAXS',
    CoinName: 'BoxAxis',
    FullName: 'BoxAxis (BAXS)',
  },
  BABL: {
    Name: 'BABL',
    Symbol: 'BABL',
    CoinName: 'Babylon Finance',
    FullName: 'Babylon Finance (BABL)',
  },
  NEWO: {
    Name: 'NEWO',
    Symbol: 'NEWO',
    CoinName: 'New Order',
    FullName: 'New Order (NEWO)',
  },
  ZTC: {
    Name: 'ZTC',
    Symbol: 'ZTC',
    CoinName: 'ZeTo',
    FullName: 'ZeTo (ZTC)',
  },
  DEG: {
    Name: 'DEG',
    Symbol: 'DEG',
    CoinName: 'Degis',
    FullName: 'Degis (DEG)',
  },
  PDT: {
    Name: 'PDT',
    Symbol: 'PDT',
    CoinName: 'ParagonsDAO',
    FullName: 'ParagonsDAO (PDT)',
  },
  GNS: {
    Name: 'GNS',
    Symbol: 'GNS',
    CoinName: 'Gains Network',
    FullName: 'Gains Network (GNS)',
  },
  BLWA: {
    Name: 'BLWA',
    Symbol: 'BLWA',
    CoinName: 'BlockWarrior',
    FullName: 'BlockWarrior (BLWA)',
  },
  EMBR: {
    Name: 'EMBR',
    Symbol: 'EMBR',
    CoinName: 'Embr',
    FullName: 'Embr (EMBR)',
  },
  FBX: {
    Name: 'FBX',
    Symbol: 'FBX',
    CoinName: 'ForthBox',
    FullName: 'ForthBox (FBX)',
  },
  ANSR: {
    Name: 'ANSR',
    Symbol: 'ANSR',
    CoinName: 'Answerly',
    FullName: 'Answerly (ANSR)',
  },
  PILOT: {
    Name: 'PILOT',
    Symbol: 'PILOT',
    CoinName: 'Unipilot',
    FullName: 'Unipilot (PILOT)',
  },
  FNC: {
    Name: 'FNC',
    Symbol: 'FNC',
    CoinName: 'Fancy Games',
    FullName: 'Fancy Games (FNC)',
  },
  AQUAGOAT: {
    Name: 'AQUAGOAT',
    Symbol: 'AQUAGOAT',
    CoinName: 'Aqua Goat',
    FullName: 'Aqua Goat (AQUAGOAT)',
  },
  UM: {
    Name: 'UM',
    Symbol: 'UM',
    CoinName: 'UncleMine',
    FullName: 'UncleMine (UM)',
  },
  SILO: {
    Name: 'SILO',
    Symbol: 'SILO',
    CoinName: 'Silo Finance',
    FullName: 'Silo Finance (SILO)',
  },
  BFLOKI: {
    Name: 'BFLOKI',
    Symbol: 'BFLOKI',
    CoinName: 'BurnFloki',
    FullName: 'BurnFloki (BFLOKI)',
  },
  ELEN: {
    Name: 'ELEN',
    Symbol: 'ELEN',
    CoinName: 'Everlens',
    FullName: 'Everlens (ELEN)',
  },
  CLIST: {
    Name: 'CLIST',
    Symbol: 'CLIST',
    CoinName: 'Chainlist',
    FullName: 'Chainlist (CLIST)',
  },
  DTO: {
    Name: 'DTO',
    Symbol: 'DTO',
    CoinName: 'DotOracle',
    FullName: 'DotOracle (DTO)',
  },
  BEETS: {
    Name: 'BEETS',
    Symbol: 'BEETS',
    CoinName: 'Beethoven X',
    FullName: 'Beethoven X (BEETS)',
  },
  WIRTUAL: {
    Name: 'WIRTUAL',
    Symbol: 'WIRTUAL',
    CoinName: 'Wirtual',
    FullName: 'Wirtual (WIRTUAL)',
  },
  KYOKO: {
    Name: 'KYOKO',
    Symbol: 'KYOKO',
    CoinName: 'Kyoko',
    FullName: 'Kyoko (KYOKO)',
  },
  SMI: {
    Name: 'SMI',
    Symbol: 'SMI',
    CoinName: 'SafeMoon Inu',
    FullName: 'SafeMoon Inu (SMI)',
  },
  STRNGR: {
    Name: 'STRNGR',
    Symbol: 'STRNGR',
    CoinName: 'Stronger',
    FullName: 'Stronger (STRNGR)',
  },
  DFIAT: {
    Name: 'DFIAT',
    Symbol: 'DFIAT',
    CoinName: 'DeFiato',
    FullName: 'DeFiato (DFIAT)',
  },
  SMON: {
    Name: 'SMON',
    Symbol: 'SMON',
    CoinName: 'StarMon',
    FullName: 'StarMon (SMON)',
  },
  WAS: {
    Name: 'WAS',
    Symbol: 'WAS',
    CoinName: 'Wasder',
    FullName: 'Wasder (WAS)',
  },
  FWW: {
    Name: 'FWW',
    Symbol: 'FWW',
    CoinName: 'Farmers World Wood',
    FullName: 'Farmers World Wood (FWW)',
  },
  ONI: {
    Name: 'ONI',
    Symbol: 'ONI',
    CoinName: 'ONINO',
    FullName: 'ONINO (ONI)',
  },
  THOR: {
    Name: 'THOR',
    Symbol: 'THOR',
    CoinName: 'THORSwap',
    FullName: 'THORSwap (THOR)',
  },
  RPS: {
    Name: 'RPS',
    Symbol: 'RPS',
    CoinName: 'Rps League',
    FullName: 'Rps League (RPS)',
  },
  CMN: {
    Name: 'CMN',
    Symbol: 'CMN',
    CoinName: 'Crypto Media Network',
    FullName: 'Crypto Media Network (CMN)',
  },
  WAMPL: {
    Name: 'WAMPL',
    Symbol: 'WAMPL',
    CoinName: 'Wrapped Ampleforth',
    FullName: 'Wrapped Ampleforth (WAMPL)',
  },
  RIP: {
    Name: 'RIP',
    Symbol: 'RIP',
    CoinName: 'Fantom Doge',
    FullName: 'Fantom Doge (RIP)',
  },
  COLA: {
    Name: 'COLA',
    Symbol: 'COLA',
    CoinName: 'Cola',
    FullName: 'Cola (COLA)',
  },
  NRGY: {
    Name: 'NRGY',
    Symbol: 'NRGY',
    CoinName: 'NRGY Defi',
    FullName: 'NRGY Defi (NRGY)',
  },
  DUET: {
    Name: 'DUET',
    Symbol: 'DUET',
    CoinName: 'Duet Protocol',
    FullName: 'Duet Protocol (DUET)',
  },
  LENDA: {
    Name: 'LENDA',
    Symbol: 'LENDA',
    CoinName: 'Lenda',
    FullName: 'Lenda (LENDA)',
  },
  LFT: {
    Name: 'LFT',
    Symbol: 'LFT',
    CoinName: 'Lend Flare Dao',
    FullName: 'Lend Flare Dao (LFT)',
  },
  PLUG: {
    Name: 'PLUG',
    Symbol: 'PLUG',
    CoinName: 'PL^Gnet',
    FullName: 'PL^Gnet (PLUG)',
  },
  FADO: {
    Name: 'FADO',
    Symbol: 'FADO',
    CoinName: 'FADO Go',
    FullName: 'FADO Go (FADO)',
  },
  BREED: {
    Name: 'BREED',
    Symbol: 'BREED',
    CoinName: 'BreederDAO',
    FullName: 'BreederDAO (BREED)',
  },
  DFX: {
    Name: 'DFX',
    Symbol: 'DFX',
    CoinName: 'DFX Finance',
    FullName: 'DFX Finance (DFX)',
  },
  GMI: {
    Name: 'GMI',
    Symbol: 'GMI',
    CoinName: 'GamiFi',
    FullName: 'GamiFi (GMI)',
  },
  FLAG: {
    Name: 'FLAG',
    Symbol: 'FLAG',
    CoinName: 'Flag Network',
    FullName: 'Flag Network (FLAG)',
  },
  RLOOP: {
    Name: 'RLOOP',
    Symbol: 'RLOOP',
    CoinName: 'rLoop',
    FullName: 'rLoop (RLOOP)',
  },
  MELT: {
    Name: 'MELT',
    Symbol: 'MELT',
    CoinName: 'Defrost Finance',
    FullName: 'Defrost Finance (MELT)',
  },
  PHL: {
    Name: 'PHL',
    Symbol: 'PHL',
    CoinName: 'Philcoin',
    FullName: 'Philcoin (PHL)',
  },
  HARAMBE: {
    Name: 'HARAMBE',
    Symbol: 'HARAMBE',
    CoinName: 'Harambe',
    FullName: 'Harambe (HARAMBE)',
  },
  MOWA: {
    Name: 'MOWA',
    Symbol: 'MOWA',
    CoinName: 'Moniwar',
    FullName: 'Moniwar (MOWA)',
  },
  YETI: {
    Name: 'YETI',
    Symbol: 'YETI',
    CoinName: 'Yeti Finance',
    FullName: 'Yeti Finance (YETI)',
  },
  QUB: {
    Name: 'QUB',
    Symbol: 'QUB',
    CoinName: 'Qubism',
    FullName: 'Qubism (QUB)',
  },
  ECD: {
    Name: 'ECD',
    Symbol: 'ECD',
    CoinName: 'Echidna',
    FullName: 'Echidna (ECD)',
  },
  BDC: {
    Name: 'BDC',
    Symbol: 'BDC',
    CoinName: 'Based',
    FullName: 'Based (BDC)',
  },
  COREDAO: {
    Name: 'COREDAO',
    Symbol: 'COREDAO',
    CoinName: 'coreDAO',
    FullName: 'coreDAO (COREDAO)',
  },
  FPIS: {
    Name: 'FPIS',
    Symbol: 'FPIS',
    CoinName: 'Frax Price Index Share',
    FullName: 'Frax Price Index Share (FPIS)',
  },
  OSQTH: {
    Name: 'OSQTH',
    Symbol: 'OSQTH',
    CoinName: 'Opyn Squeeth',
    FullName: 'Opyn Squeeth (OSQTH)',
  },
  XDOGE: {
    Name: 'XDOGE',
    Symbol: 'XDOGE',
    CoinName: 'Xdoge',
    FullName: 'Xdoge (XDOGE)',
  },
  GEIST: {
    Name: 'GEIST',
    Symbol: 'GEIST',
    CoinName: 'Geist Finance',
    FullName: 'Geist Finance (GEIST)',
  },
  SHIT: {
    Name: 'SHIT',
    Symbol: 'SHIT',
    CoinName: 'I will poop it NFT',
    FullName: 'I will poop it NFT (SHIT)',
  },
  HASH: {
    Name: 'HASH',
    Symbol: 'HASH',
    CoinName: 'Provenance Blockchain',
    FullName: 'Provenance Blockchain (HASH)',
  },
  FORT: {
    Name: 'FORT',
    Symbol: 'FORT',
    CoinName: 'Forta',
    FullName: 'Forta (FORT)',
  },
  EXFI: {
    Name: 'EXFI',
    Symbol: 'EXFI',
    CoinName: 'Flare Finance',
    FullName: 'Flare Finance (EXFI)',
  },
  SPH: {
    Name: 'SPH',
    Symbol: 'SPH',
    CoinName: 'Spheroid Universe',
    FullName: 'Spheroid Universe (SPH)',
  },
  RBW: {
    Name: 'RBW',
    Symbol: 'RBW',
    CoinName: 'Crypto Unicorns Rainbow',
    FullName: 'Crypto Unicorns Rainbow (RBW)',
  },
  TGR: {
    Name: 'TGR',
    Symbol: 'TGR',
    CoinName: 'Tegro',
    FullName: 'Tegro (TGR)',
  },
  ONES: {
    Name: 'ONES',
    Symbol: 'ONES',
    CoinName: 'OneSwap DAO',
    FullName: 'OneSwap DAO (ONES)',
  },
  UNIM: {
    Name: 'UNIM',
    Symbol: 'UNIM',
    CoinName: 'Unicorn Milk',
    FullName: 'Unicorn Milk (UNIM)',
  },
  EKTA: {
    Name: 'EKTA',
    Symbol: 'EKTA',
    CoinName: 'Ekta',
    FullName: 'Ekta (EKTA)',
  },
  SFL: {
    Name: 'SFL',
    Symbol: 'SFL',
    CoinName: 'Sunflower Land',
    FullName: 'Sunflower Land (SFL)',
  },
  TBAC: {
    Name: 'TBAC',
    Symbol: 'TBAC',
    CoinName: 'BlockAura',
    FullName: 'BlockAura (TBAC)',
  },
  CLY: {
    Name: 'CLY',
    Symbol: 'CLY',
    CoinName: 'Colony',
    FullName: 'Colony (CLY)',
  },
  FCH: {
    Name: 'FCH',
    Symbol: 'FCH',
    CoinName: 'Freecash',
    FullName: 'Freecash (FCH)',
  },
  OCB: {
    Name: 'OCB',
    Symbol: 'OCB',
    CoinName: 'BLOCKMAX',
    FullName: 'BLOCKMAX (OCB)',
  },
  TINC: {
    Name: 'TINC',
    Symbol: 'TINC',
    CoinName: 'Tiny Coin',
    FullName: 'Tiny Coin (TINC)',
  },
  MNDCC: {
    Name: 'MNDCC',
    Symbol: 'MNDCC',
    CoinName: 'Mondo Community Coin',
    FullName: 'Mondo Community Coin (MNDCC)',
  },
  LUNCH: {
    Name: 'LUNCH',
    Symbol: 'LUNCH',
    CoinName: 'LunchDAO',
    FullName: 'LunchDAO (LUNCH)',
  },
  ML: {
    Name: 'ML',
    Symbol: 'ML',
    CoinName: 'Market Ledger',
    FullName: 'Market Ledger (ML)',
  },
  NIT: {
    Name: 'NIT',
    Symbol: 'NIT',
    CoinName: 'Nesten',
    FullName: 'Nesten (NIT)',
  },
  WZRD: {
    Name: 'WZRD',
    Symbol: 'WZRD',
    CoinName: 'Wizardia',
    FullName: 'Wizardia (WZRD)',
  },
  WOOL: {
    Name: 'WOOL',
    Symbol: 'WOOL',
    CoinName: 'Wolf Game Wool',
    FullName: 'Wolf Game Wool (WOOL)',
  },
  WGL: {
    Name: 'WGL',
    Symbol: 'WGL',
    CoinName: 'Wiggly Finance',
    FullName: 'Wiggly Finance (WGL)',
  },
  BIOFI: {
    Name: 'BIOFI',
    Symbol: 'BIOFI',
    CoinName: 'Biometric Financial',
    FullName: 'Biometric Financial (BIOFI)',
  },
  ARTM: {
    Name: 'ARTM',
    Symbol: 'ARTM',
    CoinName: 'ARTM',
    FullName: 'ARTM (ARTM)',
  },
  DUKE: {
    Name: 'DUKE',
    Symbol: 'DUKE',
    CoinName: 'Duke Inu',
    FullName: 'Duke Inu (DUKE)',
  },
  WELUPS: {
    Name: 'WELUPS',
    Symbol: 'WELUPS',
    CoinName: 'Welups Blockchain',
    FullName: 'Welups Blockchain (WELUPS)',
  },
  OLOID: {
    Name: 'OLOID',
    Symbol: 'OLOID',
    CoinName: 'OLOID',
    FullName: 'OLOID (OLOID)',
  },
  TARP: {
    Name: 'TARP',
    Symbol: 'TARP',
    CoinName: 'Totally A Rug Pull',
    FullName: 'Totally A Rug Pull (TARP)',
  },
  AENS: {
    Name: 'AENS',
    Symbol: 'AENS',
    CoinName: 'AEN Smart',
    FullName: 'AEN Smart (AENS)',
  },
  POTS: {
    Name: 'POTS',
    Symbol: 'POTS',
    CoinName: 'Moonpot',
    FullName: 'Moonpot (POTS)',
  },
  LEGO: {
    Name: 'LEGO',
    Symbol: 'LEGO',
    CoinName: 'Lego Coin',
    FullName: 'Lego Coin (LEGO)',
  },
  MANC: {
    Name: 'MANC',
    Symbol: 'MANC',
    CoinName: 'Mancium',
    FullName: 'Mancium (MANC)',
  },
  TURBO: {
    Name: 'TURBO',
    Symbol: 'TURBO',
    CoinName: 'Turbo Wallet',
    FullName: 'Turbo Wallet (TURBO)',
  },
  CHECK: {
    Name: 'CHECK',
    Symbol: 'CHECK',
    CoinName: 'Paycheck',
    FullName: 'Paycheck (CHECK)',
  },
  GYMNET: {
    Name: 'GYMNET',
    Symbol: 'GYMNET',
    CoinName: 'Gym Network',
    FullName: 'Gym Network (GYMNET)',
  },
  MPD: {
    Name: 'MPD',
    Symbol: 'MPD',
    CoinName: 'Metapad',
    FullName: 'Metapad (MPD)',
  },
  GALAXY: {
    Name: 'GALAXY',
    Symbol: 'GALAXY',
    CoinName: 'GalaxyCoin',
    FullName: 'GalaxyCoin (GALAXY)',
  },
  LUCA: {
    Name: 'LUCA',
    Symbol: 'LUCA',
    CoinName: 'LUCA',
    FullName: 'LUCA (LUCA)',
  },
  DOGECOIN: {
    Name: 'DOGECOIN',
    Symbol: 'DOGECOIN',
    CoinName: 'Buff Doge Coin',
    FullName: 'Buff Doge Coin (DOGECOIN)',
  },
  DINGO: {
    Name: 'DINGO',
    Symbol: 'DINGO',
    CoinName: 'Dingocoin',
    FullName: 'Dingocoin (DINGO)',
  },
  HELIOS: {
    Name: 'HELIOS',
    Symbol: 'HELIOS',
    CoinName: 'Mission Helios',
    FullName: 'Mission Helios (HELIOS)',
  },
  '2022M': {
    Name: '2022M',
    Symbol: '2022M',
    CoinName: '2022MOON',
    FullName: '2022MOON (2022M)',
  },
  FONE: {
    Name: 'FONE',
    Symbol: 'FONE',
    CoinName: 'Fone',
    FullName: 'Fone (FONE)',
  },
  PSTN: {
    Name: 'PSTN',
    Symbol: 'PSTN',
    CoinName: 'Piston',
    FullName: 'Piston (PSTN)',
  },
  RND: {
    Name: 'RND',
    Symbol: 'RND',
    CoinName: 'The RandomDAO',
    FullName: 'The RandomDAO (RND)',
  },
  WACO: {
    Name: 'WACO',
    Symbol: 'WACO',
    CoinName: 'Waste Digital Coin',
    FullName: 'Waste Digital Coin (WACO)',
  },
  LXTO: {
    Name: 'LXTO',
    Symbol: 'LXTO',
    CoinName: 'LuxTTO',
    FullName: 'LuxTTO (LXTO)',
  },
  ADAO: {
    Name: 'ADAO',
    Symbol: 'ADAO',
    CoinName: 'ADADao',
    FullName: 'ADADao (ADAO)',
  },
  YYE: {
    Name: 'YYE',
    Symbol: 'YYE',
    CoinName: 'YYE Energy',
    FullName: 'YYE Energy (YYE)',
  },
  STMAN: {
    Name: 'STMAN',
    Symbol: 'STMAN',
    CoinName: 'Stickman Battleground',
    FullName: 'Stickman Battleground (STMAN)',
  },
  ROG: {
    Name: 'ROG',
    Symbol: 'ROG',
    CoinName: 'ROGin AI',
    FullName: 'ROGin AI (ROG)',
  },
  LIBERO: {
    Name: 'LIBERO',
    Symbol: 'LIBERO',
    CoinName: 'Libero Financial',
    FullName: 'Libero Financial (LIBERO)',
  },
  APRIL: {
    Name: 'APRIL',
    Symbol: 'APRIL',
    CoinName: 'April',
    FullName: 'April (APRIL)',
  },
  TASTE: {
    Name: 'TASTE',
    Symbol: 'TASTE',
    CoinName: 'TasteNFT',
    FullName: 'TasteNFT (TASTE)',
  },
  CMK: {
    Name: 'CMK',
    Symbol: 'CMK',
    CoinName: 'Credmark',
    FullName: 'Credmark (CMK)',
  },
  TRDC: {
    Name: 'TRDC',
    Symbol: 'TRDC',
    CoinName: 'Traders Coin',
    FullName: 'Traders Coin (TRDC)',
  },
  MERCE: {
    Name: 'MERCE',
    Symbol: 'MERCE',
    CoinName: 'MetaMerce',
    FullName: 'MetaMerce (MERCE)',
  },
  DRIP: {
    Name: 'DRIP',
    Symbol: 'DRIP',
    CoinName: 'Drip Network',
    FullName: 'Drip Network (DRIP)',
  },
  SBCC: {
    Name: 'SBCC',
    Symbol: 'SBCC',
    CoinName: 'Smart Block Chain City',
    FullName: 'Smart Block Chain City (SBCC)',
  },
  NITRO: {
    Name: 'NITRO',
    Symbol: 'NITRO',
    CoinName: 'Nitro League',
    FullName: 'Nitro League (NITRO)',
  },
  SWDAO: {
    Name: 'SWDAO',
    Symbol: 'SWDAO',
    CoinName: 'Super Whale DAO',
    FullName: 'Super Whale DAO (SWDAO)',
  },
  UMAD: {
    Name: 'UMAD',
    Symbol: 'UMAD',
    CoinName: 'MADworld',
    FullName: 'MADworld (UMAD)',
  },
  GBEX: {
    Name: 'GBEX',
    Symbol: 'GBEX',
    CoinName: 'Globiance Exchange',
    FullName: 'Globiance Exchange (GBEX)',
  },
  FUTURE: {
    Name: 'FUTURE',
    Symbol: 'FUTURE',
    CoinName: 'FutureCoin',
    FullName: 'FutureCoin (FUTURE)',
  },
  MNTG: {
    Name: 'MNTG',
    Symbol: 'MNTG',
    CoinName: 'Monetas',
    FullName: 'Monetas (MNTG)',
  },
  CAST: {
    Name: 'CAST',
    Symbol: 'CAST',
    CoinName: 'Castello Coin',
    FullName: 'Castello Coin (CAST)',
  },
  SGLY: {
    Name: 'SGLY',
    Symbol: 'SGLY',
    CoinName: 'Singularity',
    FullName: 'Singularity (SGLY)',
  },
  SMRAT: {
    Name: 'SMRAT',
    Symbol: 'SMRAT',
    CoinName: 'Secured MoonRat',
    FullName: 'Secured MoonRat (SMRAT)',
  },
  SAFUU: {
    Name: 'SAFUU',
    Symbol: 'SAFUU',
    CoinName: 'SAFUU',
    FullName: 'SAFUU (SAFUU)',
  },
  ISTEP: {
    Name: 'ISTEP',
    Symbol: 'ISTEP',
    CoinName: 'iSTEP',
    FullName: 'iSTEP (ISTEP)',
  },
  CRIME: {
    Name: 'CRIME',
    Symbol: 'CRIME',
    CoinName: 'Crime Gold',
    FullName: 'Crime Gold (CRIME)',
  },
  SPRT: {
    Name: 'SPRT',
    Symbol: 'SPRT',
    CoinName: 'Sportium',
    FullName: 'Sportium (SPRT)',
  },
  MSOT: {
    Name: 'MSOT',
    Symbol: 'MSOT',
    CoinName: 'BTour Chain',
    FullName: 'BTour Chain (MSOT)',
  },
  HRDG: {
    Name: 'HRDG',
    Symbol: 'HRDG',
    CoinName: 'HRDGCOIN',
    FullName: 'HRDGCOIN (HRDG)',
  },
  HELPS: {
    Name: 'HELPS',
    Symbol: 'HELPS',
    CoinName: 'HelpSeed',
    FullName: 'HelpSeed (HELPS)',
  },
  MOVEZ: {
    Name: 'MOVEZ',
    Symbol: 'MOVEZ',
    CoinName: 'MoveZ',
    FullName: 'MoveZ (MOVEZ)',
  },
  EAI: {
    Name: 'EAI',
    Symbol: 'EAI',
    CoinName: 'Edain',
    FullName: 'Edain (EAI)',
  },
  XDEN: {
    Name: 'XDEN',
    Symbol: 'XDEN',
    CoinName: 'Xiden',
    FullName: 'Xiden (XDEN)',
  },
  HITOP: {
    Name: 'HITOP',
    Symbol: 'HITOP',
    CoinName: 'Hitop',
    FullName: 'Hitop (HITOP)',
  },
  SHIBDOGE: {
    Name: 'SHIBDOGE',
    Symbol: 'SHIBDOGE',
    CoinName: 'ShibaDoge',
    FullName: 'ShibaDoge (SHIBDOGE)',
  },
  RCH: {
    Name: 'RCH',
    Symbol: 'RCH',
    CoinName: 'Rich',
    FullName: 'Rich (RCH)',
  },
  PTX: {
    Name: 'PTX',
    Symbol: 'PTX',
    CoinName: 'PlatinX',
    FullName: 'PlatinX (PTX)',
  },
  JMPT: {
    Name: 'JMPT',
    Symbol: 'JMPT',
    CoinName: 'JumpToken',
    FullName: 'JumpToken (JMPT)',
  },
  LTEX: {
    Name: 'LTEX',
    Symbol: 'LTEX',
    CoinName: 'Ltradex',
    FullName: 'Ltradex (LTEX)',
  },
  BGC: {
    Name: 'BGC',
    Symbol: 'BGC',
    CoinName: 'Bee Token',
    FullName: 'Bee Token (BGC)',
  },
  TSG: {
    Name: 'TSG',
    Symbol: 'TSG',
    CoinName: 'The Soldiers Gold',
    FullName: 'The Soldiers Gold (TSG)',
  },
  CHECKR: {
    Name: 'CHECKR',
    Symbol: 'CHECKR',
    CoinName: 'CheckerChain',
    FullName: 'CheckerChain (CHECKR)',
  },
  GRBT: {
    Name: 'GRBT',
    Symbol: 'GRBT',
    CoinName: 'Grinbit',
    FullName: 'Grinbit (GRBT)',
  },
  GTCOIN: {
    Name: 'GTCOIN',
    Symbol: 'GTCOIN',
    CoinName: 'Game Tree',
    FullName: 'Game Tree (GTCOIN)',
  },
  '4JNET': {
    Name: '4JNET',
    Symbol: '4JNET',
    CoinName: '4JNET',
    FullName: '4JNET (4JNET)',
  },
  EUL: {
    Name: 'EUL',
    Symbol: 'EUL',
    CoinName: 'Euler',
    FullName: 'Euler (EUL)',
  },
  HOP: {
    Name: 'HOP',
    Symbol: 'HOP',
    CoinName: 'Hop Protocol',
    FullName: 'Hop Protocol (HOP)',
  },
  CTP: {
    Name: 'CTP',
    Symbol: 'CTP',
    CoinName: 'Captain Planet',
    FullName: 'Captain Planet (CTP)',
  },
  ADXX: {
    Name: 'ADXX',
    Symbol: 'ADXX',
    CoinName: 'AnonyDoxx',
    FullName: 'AnonyDoxx (ADXX)',
  },
  RLT: {
    Name: 'RLT',
    Symbol: 'RLT',
    CoinName: 'Runner Land',
    FullName: 'Runner Land (RLT)',
  },
  ASKO: {
    Name: 'ASKO',
    Symbol: 'ASKO',
    CoinName: 'Asko',
    FullName: 'Asko (ASKO)',
  },
  CALO: {
    Name: 'CALO',
    Symbol: 'CALO',
    CoinName: 'Calo',
    FullName: 'Calo (CALO)',
  },
  COPI: {
    Name: 'COPI',
    Symbol: 'COPI',
    CoinName: 'Cornucopias',
    FullName: 'Cornucopias (COPI)',
  },
  DEFLY: {
    Name: 'DEFLY',
    Symbol: 'DEFLY',
    CoinName: 'Deflyball',
    FullName: 'Deflyball (DEFLY)',
  },
  MCT: {
    Name: 'MCT',
    Symbol: 'MCT',
    CoinName: 'MyConstant',
    FullName: 'MyConstant (MCT)',
  },
  GOTG: {
    Name: 'GOTG',
    Symbol: 'GOTG',
    CoinName: 'Got Guaranteed',
    FullName: 'Got Guaranteed (GOTG)',
  },
  SDL: {
    Name: 'SDL',
    Symbol: 'SDL',
    CoinName: 'Saddle Finance',
    FullName: 'Saddle Finance (SDL)',
  },
  LEPA: {
    Name: 'LEPA',
    Symbol: 'LEPA',
    CoinName: 'Lepasa',
    FullName: 'Lepasa (LEPA)',
  },
  KDIA: {
    Name: 'KDIA',
    Symbol: 'KDIA',
    CoinName: 'KDIA COIN',
    FullName: 'KDIA COIN (KDIA)',
  },
  MOI: {
    Name: 'MOI',
    Symbol: 'MOI',
    CoinName: 'MyOwnItem',
    FullName: 'MyOwnItem (MOI)',
  },
  GDSC: {
    Name: 'GDSC',
    Symbol: 'GDSC',
    CoinName: 'Golden Safety Coin',
    FullName: 'Golden Safety Coin (GDSC)',
  },
  QA: {
    Name: 'QA',
    Symbol: 'QA',
    CoinName: 'Quantum Assets',
    FullName: 'Quantum Assets (QA)',
  },
  LEAN: {
    Name: 'LEAN',
    Symbol: 'LEAN',
    CoinName: 'Lean Management',
    FullName: 'Lean Management (LEAN)',
  },
  JPEG: {
    Name: 'JPEG',
    Symbol: 'JPEG',
    CoinName: "JPEG'd",
    FullName: "JPEG'd (JPEG)",
  },
  YUCJ: {
    Name: 'YUCJ',
    Symbol: 'YUCJ',
    CoinName: 'Yu Coin',
    FullName: 'Yu Coin (YUCJ)',
  },
  LIBERA: {
    Name: 'LIBERA',
    Symbol: 'LIBERA',
    CoinName: 'Libera Financial',
    FullName: 'Libera Financial (LIBERA)',
  },
  MINIFOOTBALL: {
    Name: 'MINIFOOTBALL',
    Symbol: 'MINIFOOTBALL',
    CoinName: 'Minifootball',
    FullName: 'Minifootball (MINIFOOTBALL)',
  },
  CPO: {
    Name: 'CPO',
    Symbol: 'CPO',
    CoinName: 'Cryptopolis',
    FullName: 'Cryptopolis (CPO)',
  },
  CPAD: {
    Name: 'CPAD',
    Symbol: 'CPAD',
    CoinName: 'Cronospad',
    FullName: 'Cronospad (CPAD)',
  },
  SCO: {
    Name: 'SCO',
    Symbol: 'SCO',
    CoinName: 'SCOPE',
    FullName: 'SCOPE (SCO)',
  },
  ETHSHIB: {
    Name: 'ETHSHIB',
    Symbol: 'ETHSHIB',
    CoinName: 'Eth Shiba',
    FullName: 'Eth Shiba (ETHSHIB)',
  },
  DCCT: {
    Name: 'DCCT',
    Symbol: 'DCCT',
    CoinName: 'DocuChain',
    FullName: 'DocuChain (DCCT)',
  },
  TSCT: {
    Name: 'TSCT',
    Symbol: 'TSCT',
    CoinName: 'Transient',
    FullName: 'Transient (TSCT)',
  },
  OPTCM: {
    Name: 'OPTCM',
    Symbol: 'OPTCM',
    CoinName: 'Optimus',
    FullName: 'Optimus (OPTCM)',
  },
  FCF: {
    Name: 'FCF',
    Symbol: 'FCF',
    CoinName: 'French Connection Finance',
    FullName: 'French Connection Finance (FCF)',
  },
  CMSN: {
    Name: 'CMSN',
    Symbol: 'CMSN',
    CoinName: 'The Commission',
    FullName: 'The Commission (CMSN)',
  },
  ERA7: {
    Name: 'ERA7',
    Symbol: 'ERA7',
    CoinName: 'Era Token',
    FullName: 'Era Token (ERA7)',
  },
  OBX: {
    Name: 'OBX',
    Symbol: 'OBX',
    CoinName: 'OpenBlox',
    FullName: 'OpenBlox (OBX)',
  },
  OZG: {
    Name: 'OZG',
    Symbol: 'OZG',
    CoinName: 'Ozagold',
    FullName: 'Ozagold (OZG)',
  },
  MIVRS: {
    Name: 'MIVRS',
    Symbol: 'MIVRS',
    CoinName: 'Minionverse',
    FullName: 'Minionverse (MIVRS)',
  },
  SUNOLD: {
    Name: 'SUNOLD',
    Symbol: 'SUNOLD',
    CoinName: 'Sun Token',
    FullName: 'Sun Token (SUNOLD)',
  },
  BLST: {
    Name: 'BLST',
    Symbol: 'BLST',
    CoinName: 'Crypto Legions Bloodstone',
    FullName: 'Crypto Legions Bloodstone (BLST)',
  },
  PERL: {
    Name: 'PERL',
    Symbol: 'PERL',
    CoinName: 'PERL.eco',
    FullName: 'PERL.eco (PERL)',
  },
  OPV: {
    Name: 'OPV',
    Symbol: 'OPV',
    CoinName: 'OpenLive NFT',
    FullName: 'OpenLive NFT (OPV)',
  },
  FRIN: {
    Name: 'FRIN',
    Symbol: 'FRIN',
    CoinName: 'Fringe Finance',
    FullName: 'Fringe Finance (FRIN)',
  },
  JAM: {
    Name: 'JAM',
    Symbol: 'JAM',
    CoinName: 'Tune.Fm',
    FullName: 'Tune.Fm (JAM)',
  },
  FX: {
    Name: 'FX',
    Symbol: 'FX',
    CoinName: 'Function X',
    FullName: 'Function X (FX)',
  },
  FCOIN: {
    Name: 'FCOIN',
    Symbol: 'FCOIN',
    CoinName: 'FCoin',
    FullName: 'FCoin (FCOIN)',
  },
  EARTHCOIN: {
    Name: 'EARTHCOIN',
    Symbol: 'EARTHCOIN',
    CoinName: 'EarthCoin',
    FullName: 'EarthCoin (EARTHCOIN)',
  },
  EAC: {
    Name: 'EAC',
    Symbol: 'EAC',
    CoinName: 'Education Assessment Cult',
    FullName: 'Education Assessment Cult (EAC)',
  },
  GDS: {
    Name: 'GDS',
    Symbol: 'GDS',
    CoinName: 'Grat Deal Coin',
    FullName: 'Grat Deal Coin (GDS)',
  },
  NFCR: {
    Name: 'NFCR',
    Symbol: 'NFCR',
    CoinName: 'NFCore',
    FullName: 'NFCore (NFCR)',
  },
  CRP: {
    Name: 'CRP',
    Symbol: 'CRP',
    CoinName: 'Crypton',
    FullName: 'Crypton (CRP)',
  },
  BELR: {
    Name: 'BELR',
    Symbol: 'BELR',
    CoinName: 'Belrium',
    FullName: 'Belrium (BELR)',
  },
  CROPPER: {
    Name: 'CROPPER',
    Symbol: 'CROPPER',
    CoinName: 'CropperFinance',
    FullName: 'CropperFinance (CROPPER)',
  },
  HASHT: {
    Name: 'HASHT',
    Symbol: 'HASHT',
    CoinName: 'HASH Token',
    FullName: 'HASH Token (HASHT)',
  },
  REVOLAND: {
    Name: 'REVOLAND',
    Symbol: 'REVOLAND',
    CoinName: 'Revoland Governance Token',
    FullName: 'Revoland Governance Token (REVOLAND)',
  },
  TPY: {
    Name: 'TPY',
    Symbol: 'TPY',
    CoinName: 'Thrupenny',
    FullName: 'Thrupenny (TPY)',
  },
  WPC: {
    Name: 'WPC',
    Symbol: 'WPC',
    CoinName: 'WePiggy Coin',
    FullName: 'WePiggy Coin (WPC)',
  },
  MAXR: {
    Name: 'MAXR',
    Symbol: 'MAXR',
    CoinName: 'Max Revive',
    FullName: 'Max Revive (MAXR)',
  },
  LDX: {
    Name: 'LDX',
    Symbol: 'LDX',
    CoinName: 'Litedex',
    FullName: 'Litedex (LDX)',
  },
  SAITANOBI: {
    Name: 'SAITANOBI',
    Symbol: 'SAITANOBI',
    CoinName: 'Saitanobi',
    FullName: 'Saitanobi (SAITANOBI)',
  },
  ANJI: {
    Name: 'ANJI',
    Symbol: 'ANJI',
    CoinName: 'Anji',
    FullName: 'Anji (ANJI)',
  },
  WINRY: {
    Name: 'WINRY',
    Symbol: 'WINRY',
    CoinName: 'Winry Inu',
    FullName: 'Winry Inu (WINRY)',
  },
  MBF: {
    Name: 'MBF',
    Symbol: 'MBF',
    CoinName: 'MoonBear.Finance',
    FullName: 'MoonBear.Finance (MBF)',
  },
  CCP: {
    Name: 'CCP',
    Symbol: 'CCP',
    CoinName: 'CryptoCoinPay',
    FullName: 'CryptoCoinPay (CCP)',
  },
  RENS: {
    Name: 'RENS',
    Symbol: 'RENS',
    CoinName: 'Rens',
    FullName: 'Rens (RENS)',
  },
  COINSCOPE: {
    Name: 'COINSCOPE',
    Symbol: 'COINSCOPE',
    CoinName: 'Coinscope',
    FullName: 'Coinscope (COINSCOPE)',
  },
  MANDOX: {
    Name: 'MANDOX',
    Symbol: 'MANDOX',
    CoinName: 'MandoX',
    FullName: 'MandoX (MANDOX)',
  },
  CASIO: {
    Name: 'CASIO',
    Symbol: 'CASIO',
    CoinName: 'CasinoXMetaverse',
    FullName: 'CasinoXMetaverse (CASIO)',
  },
  GBD: {
    Name: 'GBD',
    Symbol: 'GBD',
    CoinName: 'Great Bounty Dealer',
    FullName: 'Great Bounty Dealer (GBD)',
  },
  NPXS: {
    Name: 'NPXS',
    Symbol: 'NPXS',
    CoinName: 'Pundi X',
    FullName: 'Pundi X (NPXS)',
  },
  BLOCKN: {
    Name: 'BLOCKN',
    Symbol: 'BLOCKN',
    CoinName: 'BlockNet',
    FullName: 'BlockNet (BLOCKN)',
  },
  DGP: {
    Name: 'DGP',
    Symbol: 'DGP',
    CoinName: 'DGPayment',
    FullName: 'DGPayment (DGP)',
  },
  METAF: {
    Name: 'METAF',
    Symbol: 'METAF',
    CoinName: 'MetaFastest',
    FullName: 'MetaFastest (METAF)',
  },
  NAS: {
    Name: 'NAS',
    Symbol: 'NAS',
    CoinName: 'Nebulas',
    FullName: 'Nebulas (NAS)',
  },
  LTR: {
    Name: 'LTR',
    Symbol: 'LTR',
    CoinName: 'LogiTron',
    FullName: 'LogiTron (LTR)',
  },
  PSOL: {
    Name: 'PSOL',
    Symbol: 'PSOL',
    CoinName: 'Parasol Finance',
    FullName: 'Parasol Finance (PSOL)',
  },
  XMP: {
    Name: 'XMP',
    Symbol: 'XMP',
    CoinName: 'Mapt.Coin',
    FullName: 'Mapt.Coin (XMP)',
  },
  MELOS: {
    Name: 'MELOS',
    Symbol: 'MELOS',
    CoinName: 'Melos Studio',
    FullName: 'Melos Studio (MELOS)',
  },
  MBCASH: {
    Name: 'MBCASH',
    Symbol: 'MBCASH',
    CoinName: 'MBCash',
    FullName: 'MBCash (MBCASH)',
  },
  ZIK: {
    Name: 'ZIK',
    Symbol: 'ZIK',
    CoinName: 'Ziktalk',
    FullName: 'Ziktalk (ZIK)',
  },
  ASIMI: {
    Name: 'ASIMI',
    Symbol: 'ASIMI',
    CoinName: 'ASIMI',
    FullName: 'ASIMI (ASIMI)',
  },
  TP: {
    Name: 'TP',
    Symbol: 'TP',
    CoinName: 'Token Swap',
    FullName: 'Token Swap (TP)',
  },
  WITCH: {
    Name: 'WITCH',
    Symbol: 'WITCH',
    CoinName: 'Witch',
    FullName: 'Witch (WITCH)',
  },
  BTFA: {
    Name: 'BTFA',
    Symbol: 'BTFA',
    CoinName: 'Banana Task Force Ape',
    FullName: 'Banana Task Force Ape (BTFA)',
  },
  WAGMIGAMES: {
    Name: 'WAGMIGAMES',
    Symbol: 'WAGMIGAMES',
    CoinName: 'WAGMI Game',
    FullName: 'WAGMI Game (WAGMIGAMES)',
  },
  LAW: {
    Name: 'LAW',
    Symbol: 'LAW',
    CoinName: 'Law Token',
    FullName: 'Law Token (LAW)',
  },
  EAT: {
    Name: 'EAT',
    Symbol: 'EAT',
    CoinName: 'EDGE Activity Token',
    FullName: 'EDGE Activity Token (EAT)',
  },
  SNE: {
    Name: 'SNE',
    Symbol: 'SNE',
    CoinName: 'StrongNode',
    FullName: 'StrongNode (SNE)',
  },
  ELG: {
    Name: 'ELG',
    Symbol: 'ELG',
    CoinName: 'EscoinToken',
    FullName: 'EscoinToken (ELG)',
  },
  RADIO: {
    Name: 'RADIO',
    Symbol: 'RADIO',
    CoinName: 'RadioShack',
    FullName: 'RadioShack (RADIO)',
  },
  XY: {
    Name: 'XY',
    Symbol: 'XY',
    CoinName: 'XY Finance',
    FullName: 'XY Finance (XY)',
  },
  QBU: {
    Name: 'QBU',
    Symbol: 'QBU',
    CoinName: 'Quannabu',
    FullName: 'Quannabu (QBU)',
  },
  PCE: {
    Name: 'PCE',
    Symbol: 'PCE',
    CoinName: 'PEACE COIN',
    FullName: 'PEACE COIN (PCE)',
  },
  CARES: {
    Name: 'CARES',
    Symbol: 'CARES',
    CoinName: 'CareCoin',
    FullName: 'CareCoin (CARES)',
  },
  ICOM: {
    Name: 'ICOM',
    Symbol: 'ICOM',
    CoinName: 'iCommunity',
    FullName: 'iCommunity (ICOM)',
  },
  LEMC: {
    Name: 'LEMC',
    Symbol: 'LEMC',
    CoinName: 'LemonChain',
    FullName: 'LemonChain (LEMC)',
  },
  BZX: {
    Name: 'BZX',
    Symbol: 'BZX',
    CoinName: 'Bitcoin Zero',
    FullName: 'Bitcoin Zero (BZX)',
  },
  FAKT: {
    Name: 'FAKT',
    Symbol: 'FAKT',
    CoinName: 'Medifakt',
    FullName: 'Medifakt (FAKT)',
  },
  XMT: {
    Name: 'XMT',
    Symbol: 'XMT',
    CoinName: 'MetalSwap',
    FullName: 'MetalSwap (XMT)',
  },
  DCIP: {
    Name: 'DCIP',
    Symbol: 'DCIP',
    CoinName: 'Decentralized Community Investment Protocol',
    FullName: 'Decentralized Community Investment Protocol (DCIP)',
  },
  EGX: {
    Name: 'EGX',
    Symbol: 'EGX',
    CoinName: 'Enegra',
    FullName: 'Enegra (EGX)',
  },
  GLDX: {
    Name: 'GLDX',
    Symbol: 'GLDX',
    CoinName: 'Goldex',
    FullName: 'Goldex (GLDX)',
  },
  ERW: {
    Name: 'ERW',
    Symbol: 'ERW',
    CoinName: 'ZeLoop Eco Reward',
    FullName: 'ZeLoop Eco Reward (ERW)',
  },
  BENX: {
    Name: 'BENX',
    Symbol: 'BENX',
    CoinName: 'BlueBenx',
    FullName: 'BlueBenx (BENX)',
  },
  NNN: {
    Name: 'NNN',
    Symbol: 'NNN',
    CoinName: 'Novem Gold',
    FullName: 'Novem Gold (NNN)',
  },
  UNR: {
    Name: 'UNR',
    Symbol: 'UNR',
    CoinName: 'Unirealchain',
    FullName: 'Unirealchain (UNR)',
  },
  ANA: {
    Name: 'ANA',
    Symbol: 'ANA',
    CoinName: 'Nirvana ANA',
    FullName: 'Nirvana ANA (ANA)',
  },
  FENOMY: {
    Name: 'FENOMY',
    Symbol: 'FENOMY',
    CoinName: 'Fenomy',
    FullName: 'Fenomy (FENOMY)',
  },
  CPU: {
    Name: 'CPU',
    Symbol: 'CPU',
    CoinName: 'CPUcoin',
    FullName: 'CPUcoin (CPU)',
  },
  XTAL: {
    Name: 'XTAL',
    Symbol: 'XTAL',
    CoinName: 'XTAL',
    FullName: 'XTAL (XTAL)',
  },
  OBOT: {
    Name: 'OBOT',
    Symbol: 'OBOT',
    CoinName: 'Obortech',
    FullName: 'Obortech (OBOT)',
  },
  LMCSWAP: {
    Name: 'LMCSWAP',
    Symbol: 'LMCSWAP',
    CoinName: 'LimoCoin SWAP',
    FullName: 'LimoCoin SWAP (LMCSWAP)',
  },
  UEDC: {
    Name: 'UEDC',
    Symbol: 'UEDC',
    CoinName: 'United Emirate Decentralized Coin',
    FullName: 'United Emirate Decentralized Coin (UEDC)',
  },
  PAYT: {
    Name: 'PAYT',
    Symbol: 'PAYT',
    CoinName: 'PayAccept',
    FullName: 'PayAccept (PAYT)',
  },
  GDO: {
    Name: 'GDO',
    Symbol: 'GDO',
    CoinName: 'GroupDao',
    FullName: 'GroupDao (GDO)',
  },
  ZURR: {
    Name: 'ZURR',
    Symbol: 'ZURR',
    CoinName: 'ZURRENCY',
    FullName: 'ZURRENCY (ZURR)',
  },
  DKEY: {
    Name: 'DKEY',
    Symbol: 'DKEY',
    CoinName: 'DKEY Bank',
    FullName: 'DKEY Bank (DKEY)',
  },
  IMPER: {
    Name: 'IMPER',
    Symbol: 'IMPER',
    CoinName: 'Impermax',
    FullName: 'Impermax (IMPER)',
  },
  BIBL: {
    Name: 'BIBL',
    Symbol: 'BIBL',
    CoinName: 'Biblecoin',
    FullName: 'Biblecoin (BIBL)',
  },
  FYN: {
    Name: 'FYN',
    Symbol: 'FYN',
    CoinName: 'Affyn',
    FullName: 'Affyn (FYN)',
  },
  XETA: {
    Name: 'XETA',
    Symbol: 'XETA',
    CoinName: 'Xana',
    FullName: 'Xana (XETA)',
  },
  CEJI: {
    Name: 'CEJI',
    Symbol: 'CEJI',
    CoinName: 'Ceji',
    FullName: 'Ceji (CEJI)',
  },
  LUXO: {
    Name: 'LUXO',
    Symbol: 'LUXO',
    CoinName: 'Luxo',
    FullName: 'Luxo (LUXO)',
  },
  EVCOIN: {
    Name: 'EVCOIN',
    Symbol: 'EVCOIN',
    CoinName: 'EverestCoin',
    FullName: 'EverestCoin (EVCOIN)',
  },
  KYCC: {
    Name: 'KYCC',
    Symbol: 'KYCC',
    CoinName: 'KYCCOIN',
    FullName: 'KYCCOIN (KYCC)',
  },
  LBL: {
    Name: 'LBL',
    Symbol: 'LBL',
    CoinName: 'LABEL Foundation',
    FullName: 'LABEL Foundation (LBL)',
  },
  NFTD: {
    Name: 'NFTD',
    Symbol: 'NFTD',
    CoinName: 'NFTrade',
    FullName: 'NFTrade (NFTD)',
  },
  ITAMCUBE: {
    Name: 'ITAMCUBE',
    Symbol: 'ITAMCUBE',
    CoinName: 'CUBE',
    FullName: 'CUBE (ITAMCUBE)',
  },
  MSTO: {
    Name: 'MSTO',
    Symbol: 'MSTO',
    CoinName: 'Millennium Sapphire',
    FullName: 'Millennium Sapphire (MSTO)',
  },
  CROGE: {
    Name: 'CROGE',
    Symbol: 'CROGE',
    CoinName: 'Crogecoin',
    FullName: 'Crogecoin (CROGE)',
  },
  HANDY: {
    Name: 'HANDY',
    Symbol: 'HANDY',
    CoinName: 'Handy',
    FullName: 'Handy (HANDY)',
  },
  PANDA: {
    Name: 'PANDA',
    Symbol: 'PANDA',
    CoinName: 'PandaDAO',
    FullName: 'PandaDAO (PANDA)',
  },
  ASTROC: {
    Name: 'ASTROC',
    Symbol: 'ASTROC',
    CoinName: 'Astroport Classic',
    FullName: 'Astroport Classic (ASTROC)',
  },
  NSTE: {
    Name: 'NSTE',
    Symbol: 'NSTE',
    CoinName: 'NewSolution 2.0',
    FullName: 'NewSolution 2.0 (NSTE)',
  },
  SNACK: {
    Name: 'SNACK',
    Symbol: 'SNACK',
    CoinName: 'Crypto Snack',
    FullName: 'Crypto Snack (SNACK)',
  },
  MGPT: {
    Name: 'MGPT',
    Symbol: 'MGPT',
    CoinName: 'MotoGP Fan Token',
    FullName: 'MotoGP Fan Token (MGPT)',
  },
  BXX: {
    Name: 'BXX',
    Symbol: 'BXX',
    CoinName: 'Baanx',
    FullName: 'Baanx (BXX)',
  },
  BND: {
    Name: 'BND',
    Symbol: 'BND',
    CoinName: 'Bened',
    FullName: 'Bened (BND)',
  },
  VER: {
    Name: 'VER',
    Symbol: 'VER',
    CoinName: 'VersalNFT',
    FullName: 'VersalNFT (VER)',
  },
  BME: {
    Name: 'BME',
    Symbol: 'BME',
    CoinName: 'BitcoMine',
    FullName: 'BitcoMine (BME)',
  },
  HTT: {
    Name: 'HTT',
    Symbol: 'HTT',
    CoinName: 'Hello Art',
    FullName: 'Hello Art (HTT)',
  },
  SG: {
    Name: 'SG',
    Symbol: 'SG',
    CoinName: 'SocialGood',
    FullName: 'SocialGood (SG)',
  },
  NEKI: {
    Name: 'NEKI',
    Symbol: 'NEKI',
    CoinName: 'Neki Token',
    FullName: 'Neki Token (NEKI)',
  },
  FCD: {
    Name: 'FCD',
    Symbol: 'FCD',
    CoinName: 'FreshCut Diamond',
    FullName: 'FreshCut Diamond (FCD)',
  },
  GGM: {
    Name: 'GGM',
    Symbol: 'GGM',
    CoinName: 'Monster Galaxy',
    FullName: 'Monster Galaxy (GGM)',
  },
  LGX: {
    Name: 'LGX',
    Symbol: 'LGX',
    CoinName: 'Legion Network',
    FullName: 'Legion Network (LGX)',
  },
  FIU: {
    Name: 'FIU',
    Symbol: 'FIU',
    CoinName: 'beFITTER',
    FullName: 'beFITTER (FIU)',
  },
  SEOR: {
    Name: 'SEOR',
    Symbol: 'SEOR',
    CoinName: 'SEOR Network',
    FullName: 'SEOR Network (SEOR)',
  },
  CML: {
    Name: 'CML',
    Symbol: 'CML',
    CoinName: 'Camelcoin',
    FullName: 'Camelcoin (CML)',
  },
  KNCL: {
    Name: 'KNCL',
    Symbol: 'KNCL',
    CoinName: 'Kyber Network Crystal Legacy',
    FullName: 'Kyber Network Crystal Legacy (KNCL)',
  },
  JUMBO: {
    Name: 'JUMBO',
    Symbol: 'JUMBO',
    CoinName: 'Jumbo Exchange',
    FullName: 'Jumbo Exchange (JUMBO)',
  },
  AZERO: {
    Name: 'AZERO',
    Symbol: 'AZERO',
    CoinName: 'Aleph Zero',
    FullName: 'Aleph Zero (AZERO)',
  },
  QBX: {
    Name: 'QBX',
    Symbol: 'QBX',
    CoinName: 'qiibee foundation',
    FullName: 'qiibee foundation (QBX)',
  },
  MDICE: {
    Name: 'MDICE',
    Symbol: 'MDICE',
    CoinName: 'Multidice',
    FullName: 'Multidice (MDICE)',
  },
  PATH: {
    Name: 'PATH',
    Symbol: 'PATH',
    CoinName: 'PathDAO',
    FullName: 'PathDAO (PATH)',
  },
  FLZ: {
    Name: 'FLZ',
    Symbol: 'FLZ',
    CoinName: 'Fellaz',
    FullName: 'Fellaz (FLZ)',
  },
  LOA: {
    Name: 'LOA',
    Symbol: 'LOA',
    CoinName: 'League of Ancients',
    FullName: 'League of Ancients (LOA)',
  },
  WNDR: {
    Name: 'WNDR',
    Symbol: 'WNDR',
    CoinName: 'Wonderman Nation',
    FullName: 'Wonderman Nation (WNDR)',
  },
  MVEDA: {
    Name: 'MVEDA',
    Symbol: 'MVEDA',
    CoinName: 'MedicalVeda',
    FullName: 'MedicalVeda (MVEDA)',
  },
  SRT: {
    Name: 'SRT',
    Symbol: 'SRT',
    CoinName: 'Smart Reward Token',
    FullName: 'Smart Reward Token (SRT)',
  },
  ZASH: {
    Name: 'ZASH',
    Symbol: 'ZASH',
    CoinName: 'ZIMBOCASH',
    FullName: 'ZIMBOCASH (ZASH)',
  },
  HHEM: {
    Name: 'HHEM',
    Symbol: 'HHEM',
    CoinName: 'Healthureum',
    FullName: 'Healthureum (HHEM)',
  },
  IBANK: {
    Name: 'IBANK',
    Symbol: 'IBANK',
    CoinName: 'iBankCoin',
    FullName: 'iBankCoin (IBANK)',
  },
  ICOB: {
    Name: 'ICOB',
    Symbol: 'ICOB',
    CoinName: 'Icobid',
    FullName: 'Icobid (ICOB)',
  },
  KP3R: {
    Name: 'KP3R',
    Symbol: 'KP3R',
    CoinName: 'Keep3rV1',
    FullName: 'Keep3rV1 (KP3R)',
  },
  LCC: {
    Name: 'LCC',
    Symbol: 'LCC',
    CoinName: 'LitecoinCash',
    FullName: 'LitecoinCash (LCC)',
  },
  LIGER: {
    Name: 'LIGER',
    Symbol: 'LIGER',
    CoinName: 'Ligercoin',
    FullName: 'Ligercoin (LIGER)',
  },
  LYXE: {
    Name: 'LYXE',
    Symbol: 'LYXE',
    CoinName: 'LUKSO',
    FullName: 'LUKSO (LYXE)',
  },
  MAI: {
    Name: 'MAI',
    Symbol: 'MAI',
    CoinName: 'Mindsync',
    FullName: 'Mindsync (MAI)',
  },
  MARI: {
    Name: 'MARI',
    Symbol: 'MARI',
    CoinName: 'MarijuanaCoin',
    FullName: 'MarijuanaCoin (MARI)',
  },
  MARX: {
    Name: 'MARX',
    Symbol: 'MARX',
    CoinName: 'MarX',
    FullName: 'MarX (MARX)',
  },
  BCNT: {
    Name: 'BCNT',
    Symbol: 'BCNT',
    CoinName: 'Bincentive',
    FullName: 'Bincentive (BCNT)',
  },
  MOON: {
    Name: 'MOON',
    Symbol: 'MOON',
    CoinName: 'r/CryptoCurrency Moons',
    FullName: 'r/CryptoCurrency Moons (MOON)',
  },
  MUSIC: {
    Name: 'MUSIC',
    Symbol: 'MUSIC',
    CoinName: 'Musicoin',
    FullName: 'Musicoin (MUSIC)',
  },
  NGC: {
    Name: 'NGC',
    Symbol: 'NGC',
    CoinName: 'NagaCoin',
    FullName: 'NagaCoin (NGC)',
  },
  TAD: {
    Name: 'TAD',
    Symbol: 'TAD',
    CoinName: 'Tadpole Finance',
    FullName: 'Tadpole Finance (TAD)',
  },
  ODNT: {
    Name: 'ODNT',
    Symbol: 'ODNT',
    CoinName: 'Old Dogs New Tricks',
    FullName: 'Old Dogs New Tricks (ODNT)',
  },
  PLANETS: {
    Name: 'PLANETS',
    Symbol: 'PLANETS',
    CoinName: 'PlanetWatch',
    FullName: 'PlanetWatch (PLANETS)',
  },
  QBT: {
    Name: 'QBT',
    Symbol: 'QBT',
    CoinName: 'Cubits',
    FullName: 'Cubits (QBT)',
  },
  REVV: {
    Name: 'REVV',
    Symbol: 'REVV',
    CoinName: 'REVV',
    FullName: 'REVV (REVV)',
  },
  RWD: {
    Name: 'RWD',
    Symbol: 'RWD',
    CoinName: 'Reward Vision',
    FullName: 'Reward Vision (RWD)',
  },
  SAT2: {
    Name: 'SAT2',
    Symbol: 'SAT2',
    CoinName: 'Saturn2Coin',
    FullName: 'Saturn2Coin (SAT2)',
  },
  SCASH: {
    Name: 'SCASH',
    Symbol: 'SCASH',
    CoinName: 'SpaceCash',
    FullName: 'SpaceCash (SCASH)',
  },
  SHX: {
    Name: 'SHX',
    Symbol: 'SHX',
    CoinName: 'Stronghold Token',
    FullName: 'Stronghold Token (SHX)',
  },
  SPEC: {
    Name: 'SPEC',
    Symbol: 'SPEC',
    CoinName: 'SpecCoin',
    FullName: 'SpecCoin (SPEC)',
  },
  SPK: {
    Name: 'SPK',
    Symbol: 'SPK',
    CoinName: 'SparksPay',
    FullName: 'SparksPay (SPK)',
  },
  THC: {
    Name: 'THC',
    Symbol: 'THC',
    CoinName: 'The Hempcoin',
    FullName: 'The Hempcoin (THC)',
  },
  TRIAS: {
    Name: 'TRIAS',
    Symbol: 'TRIAS',
    CoinName: 'Trias',
    FullName: 'Trias (TRIAS)',
  },
  VOL: {
    Name: 'VOL',
    Symbol: 'VOL',
    CoinName: 'Volume Network',
    FullName: 'Volume Network (VOL)',
  },
  XAUT: {
    Name: 'XAUT',
    Symbol: 'XAUT',
    CoinName: 'Tether Gold',
    FullName: 'Tether Gold (XAUT)',
  },
  XIN: {
    Name: 'XIN',
    Symbol: 'XIN',
    CoinName: 'Mixin',
    FullName: 'Mixin (XIN)',
  },
  WLKN: {
    Name: 'WLKN',
    Symbol: 'WLKN',
    CoinName: 'Walken',
    FullName: 'Walken (WLKN)',
  },
  LQDR: {
    Name: 'LQDR',
    Symbol: 'LQDR',
    CoinName: 'LiquidDriver',
    FullName: 'LiquidDriver (LQDR)',
  },
  XLA: {
    Name: 'XLA',
    Symbol: 'XLA',
    CoinName: 'Scala',
    FullName: 'Scala (XLA)',
  },
  XMY: {
    Name: 'XMY',
    Symbol: 'XMY',
    CoinName: 'MyriadCoin',
    FullName: 'MyriadCoin (XMY)',
  },
  ABC: {
    Name: 'ABC',
    Symbol: 'ABC',
    CoinName: 'ABC Chain',
    FullName: 'ABC Chain (ABC)',
  },
  GSTS: {
    Name: 'GSTS',
    Symbol: 'GSTS',
    CoinName: 'Gunstar Metaverse',
    FullName: 'Gunstar Metaverse (GSTS)',
  },
  LAEEB: {
    Name: 'LAEEB',
    Symbol: 'LAEEB',
    CoinName: 'LaEeb',
    FullName: 'LaEeb (LAEEB)',
  },
  TOOB: {
    Name: 'TOOB',
    Symbol: 'TOOB',
    CoinName: 'Toobcoin',
    FullName: 'Toobcoin (TOOB)',
  },
  TAF: {
    Name: 'TAF',
    Symbol: 'TAF',
    CoinName: 'TAF',
    FullName: 'TAF (TAF)',
  },
  UCG: {
    Name: 'UCG',
    Symbol: 'UCG',
    CoinName: 'Universe Crystal Gene',
    FullName: 'Universe Crystal Gene (UCG)',
  },
  XBT: {
    Name: 'XBT',
    Symbol: 'XBT',
    CoinName: 'Xbit',
    FullName: 'Xbit (XBT)',
  },
  IME: {
    Name: 'IME',
    Symbol: 'IME',
    CoinName: 'Imperium Empires',
    FullName: 'Imperium Empires (IME)',
  },
  TIGER: {
    Name: 'TIGER',
    Symbol: 'TIGER',
    CoinName: 'JungleKing TigerCoin',
    FullName: 'JungleKing TigerCoin (TIGER)',
  },
  CASTLE: {
    Name: 'CASTLE',
    Symbol: 'CASTLE',
    CoinName: 'bitCastle',
    FullName: 'bitCastle (CASTLE)',
  },
  STI: {
    Name: 'STI',
    Symbol: 'STI',
    CoinName: 'Seek Tiger',
    FullName: 'Seek Tiger (STI)',
  },
  XOT: {
    Name: 'XOT',
    Symbol: 'XOT',
    CoinName: 'Okuru',
    FullName: 'Okuru (XOT)',
  },
  MYT: {
    Name: 'MYT',
    Symbol: 'MYT',
    CoinName: 'Mytrade',
    FullName: 'Mytrade (MYT)',
  },
  VRO: {
    Name: 'VRO',
    Symbol: 'VRO',
    CoinName: 'VeraOne',
    FullName: 'VeraOne (VRO)',
  },
  METAS: {
    Name: 'METAS',
    Symbol: 'METAS',
    CoinName: 'Metaseer',
    FullName: 'Metaseer (METAS)',
  },
  CLM: {
    Name: 'CLM',
    Symbol: 'CLM',
    CoinName: 'CoinClaim',
    FullName: 'CoinClaim (CLM)',
  },
  MTG: {
    Name: 'MTG',
    Symbol: 'MTG',
    CoinName: 'MagnetGold',
    FullName: 'MagnetGold (MTG)',
  },
  INES: {
    Name: 'INES',
    Symbol: 'INES',
    CoinName: 'Inescoin',
    FullName: 'Inescoin (INES)',
  },
  FERMA: {
    Name: 'FERMA',
    Symbol: 'FERMA',
    CoinName: 'Ferma',
    FullName: 'Ferma (FERMA)',
  },
  CTK: {
    Name: 'CTK',
    Symbol: 'CTK',
    CoinName: 'Shentu',
    FullName: 'Shentu (CTK)',
  },
  WOMBAT: {
    Name: 'WOMBAT',
    Symbol: 'WOMBAT',
    CoinName: 'Wombat',
    FullName: 'Wombat (WOMBAT)',
  },
  OGV: {
    Name: 'OGV',
    Symbol: 'OGV',
    CoinName: 'Origin Dollar Governance',
    FullName: 'Origin Dollar Governance (OGV)',
  },
  MJT: {
    Name: 'MJT',
    Symbol: 'MJT',
    CoinName: 'MojitoSwap',
    FullName: 'MojitoSwap (MJT)',
  },
  TAUM: {
    Name: 'TAUM',
    Symbol: 'TAUM',
    CoinName: 'Orbitau Taureum',
    FullName: 'Orbitau Taureum (TAUM)',
  },
  PLD: {
    Name: 'PLD',
    Symbol: 'PLD',
    CoinName: 'Plutonian DAO',
    FullName: 'Plutonian DAO (PLD)',
  },
  HIBAYC: {
    Name: 'HIBAYC',
    Symbol: 'HIBAYC',
    CoinName: 'hiBAYC',
    FullName: 'hiBAYC (HIBAYC)',
  },
  OMAX: {
    Name: 'OMAX',
    Symbol: 'OMAX',
    CoinName: 'Omax',
    FullName: 'Omax (OMAX)',
  },
  MIE: {
    Name: 'MIE',
    Symbol: 'MIE',
    CoinName: 'MIE Network',
    FullName: 'MIE Network (MIE)',
  },
  PICO: {
    Name: 'PICO',
    Symbol: 'PICO',
    CoinName: 'PicoGo',
    FullName: 'PicoGo (PICO)',
  },
  XGLI: {
    Name: 'XGLI',
    Symbol: 'XGLI',
    CoinName: 'Glitter Finance',
    FullName: 'Glitter Finance (XGLI)',
  },
  MVDG: {
    Name: 'MVDG',
    Symbol: 'MVDG',
    CoinName: 'MetaVerse Dog',
    FullName: 'MetaVerse Dog (MVDG)',
  },
  AMB: {
    Name: 'AMB',
    Symbol: 'AMB',
    CoinName: 'Ambrosus',
    FullName: 'Ambrosus (AMB)',
  },
  SD: {
    Name: 'SD',
    Symbol: 'SD',
    CoinName: 'Stader',
    FullName: 'Stader (SD)',
  },
  ZORA: {
    Name: 'ZORA',
    Symbol: 'ZORA',
    CoinName: 'Zoracles',
    FullName: 'Zoracles (ZORA)',
  },
  PKOIN: {
    Name: 'PKOIN',
    Symbol: 'PKOIN',
    CoinName: 'Pocketcoin',
    FullName: 'Pocketcoin (PKOIN)',
  },
  TKP: {
    Name: 'TKP',
    Symbol: 'TKP',
    CoinName: 'TOKPIE',
    FullName: 'TOKPIE (TKP)',
  },
  PYM: {
    Name: 'PYM',
    Symbol: 'PYM',
    CoinName: 'Playermon',
    FullName: 'Playermon (PYM)',
  },
  BITCCA: {
    Name: 'BITCCA',
    Symbol: 'BITCCA',
    CoinName: 'Bitcci Cash',
    FullName: 'Bitcci Cash (BITCCA)',
  },
  TMON: {
    Name: 'TMON',
    Symbol: 'TMON',
    CoinName: 'Two Monkey Juice Bar',
    FullName: 'Two Monkey Juice Bar (TMON)',
  },
  VHC: {
    Name: 'VHC',
    Symbol: 'VHC',
    CoinName: 'Vault Hill City',
    FullName: 'Vault Hill City (VHC)',
  },
  WCSOV: {
    Name: 'WCSOV',
    Symbol: 'WCSOV',
    CoinName: 'Wrapped CrownSterling',
    FullName: 'Wrapped CrownSterling (WCSOV)',
  },
  NIFT: {
    Name: 'NIFT',
    Symbol: 'NIFT',
    CoinName: 'Niftify',
    FullName: 'Niftify (NIFT)',
  },
  NSUR: {
    Name: 'NSUR',
    Symbol: 'NSUR',
    CoinName: 'NSUR Coin',
    FullName: 'NSUR Coin (NSUR)',
  },
  ZGD: {
    Name: 'ZGD',
    Symbol: 'ZGD',
    CoinName: 'ZambesiGold',
    FullName: 'ZambesiGold (ZGD)',
  },
  CHESS: {
    Name: 'CHESS',
    Symbol: 'CHESS',
    CoinName: 'Tranchess',
    FullName: 'Tranchess (CHESS)',
  },
  MAN: {
    Name: 'MAN',
    Symbol: 'MAN',
    CoinName: 'Matrix AI Network',
    FullName: 'Matrix AI Network (MAN)',
  },
  MEME: {
    Name: 'MEME',
    Symbol: 'MEME',
    CoinName: 'Meme',
    FullName: 'Meme (MEME)',
  },
  NEWOS: {
    Name: 'NEWOS',
    Symbol: 'NEWOS',
    CoinName: 'NewsToken',
    FullName: 'NewsToken (NEWOS)',
  },
  NVC: {
    Name: 'NVC',
    Symbol: 'NVC',
    CoinName: 'NovaCoin',
    FullName: 'NovaCoin (NVC)',
  },
  SSP: {
    Name: 'SSP',
    Symbol: 'SSP',
    CoinName: 'Smartshare',
    FullName: 'Smartshare (SSP)',
  },
  WPR: {
    Name: 'WPR',
    Symbol: 'WPR',
    CoinName: 'WePower',
    FullName: 'WePower (WPR)',
  },
  INDEX: {
    Name: 'INDEX',
    Symbol: 'INDEX',
    CoinName: 'Index Cooperative',
    FullName: 'Index Cooperative (INDEX)',
  },
  EURT: {
    Name: 'EURT',
    Symbol: 'EURT',
    CoinName: 'Euro Tether',
    FullName: 'Euro Tether (EURT)',
  },
  PULI: {
    Name: 'PULI',
    Symbol: 'PULI',
    CoinName: 'Puli',
    FullName: 'Puli (PULI)',
  },
  CWBTC: {
    Name: 'CWBTC',
    Symbol: 'CWBTC',
    CoinName: 'Compound Wrapped BTC',
    FullName: 'Compound Wrapped BTC (CWBTC)',
  },
  DUO: {
    Name: 'DUO',
    Symbol: 'DUO',
    CoinName: 'ParallelCoin',
    FullName: 'ParallelCoin (DUO)',
  },
  FEAR: {
    Name: 'FEAR',
    Symbol: 'FEAR',
    CoinName: 'Fear',
    FullName: 'Fear (FEAR)',
  },
  HOKK: {
    Name: 'HOKK',
    Symbol: 'HOKK',
    CoinName: 'Hokkaidu Inu',
    FullName: 'Hokkaidu Inu (HOKK)',
  },
  KAT: {
    Name: 'KAT',
    Symbol: 'KAT',
    CoinName: 'Kambria',
    FullName: 'Kambria (KAT)',
  },
  KAWA: {
    Name: 'KAWA',
    Symbol: 'KAWA',
    CoinName: 'Kawakami Inu',
    FullName: 'Kawakami Inu (KAWA)',
  },
  LINKC: {
    Name: 'LINKC',
    Symbol: 'LINKC',
    CoinName: 'LINKCHAIN',
    FullName: 'LINKCHAIN (LINKC)',
  },
  GSTBSC: {
    Name: 'GSTBSC',
    Symbol: 'GSTBSC',
    CoinName: 'Green Satoshi Token (BSC)',
    FullName: 'Green Satoshi Token (BSC) (GSTBSC)',
  },
  GSTETH: {
    Name: 'GSTETH',
    Symbol: 'GSTETH',
    CoinName: 'Green Satoshi Token (ETH)',
    FullName: 'Green Satoshi Token (ETH) (GSTETH)',
  },
  GST: {
    Name: 'GST',
    Symbol: 'GST',
    CoinName: 'Green Satoshi Token (SOL)',
    FullName: 'Green Satoshi Token (SOL) (GST)',
  },
  BX: {
    Name: 'BX',
    Symbol: 'BX',
    CoinName: 'BlockXpress',
    FullName: 'BlockXpress (BX)',
  },
  DVRS: {
    Name: 'DVRS',
    Symbol: 'DVRS',
    CoinName: 'DaoVerse',
    FullName: 'DaoVerse (DVRS)',
  },
  URQA: {
    Name: 'URQA',
    Symbol: 'URQA',
    CoinName: 'UREEQA',
    FullName: 'UREEQA (URQA)',
  },
  TOPG: {
    Name: 'TOPG',
    Symbol: 'TOPG',
    CoinName: 'Tate Token',
    FullName: 'Tate Token (TOPG)',
  },
  EON: {
    Name: 'EON',
    Symbol: 'EON',
    CoinName: 'Exscudo',
    FullName: 'Exscudo (EON)',
  },
  ALTA: {
    Name: 'ALTA',
    Symbol: 'ALTA',
    CoinName: 'Alta Finance',
    FullName: 'Alta Finance (ALTA)',
  },
  GHNY: {
    Name: 'GHNY',
    Symbol: 'GHNY',
    CoinName: 'Grizzly Honey',
    FullName: 'Grizzly Honey (GHNY)',
  },
  GZX: {
    Name: 'GZX',
    Symbol: 'GZX',
    CoinName: 'GreenZoneX',
    FullName: 'GreenZoneX (GZX)',
  },
  BTBS: {
    Name: 'BTBS',
    Symbol: 'BTBS',
    CoinName: 'BitBase Token',
    FullName: 'BitBase Token (BTBS)',
  },
  SMOON: {
    Name: 'SMOON',
    Symbol: 'SMOON',
    CoinName: 'SaylorMoon',
    FullName: 'SaylorMoon (SMOON)',
  },
  LF: {
    Name: 'LF',
    Symbol: 'LF',
    CoinName: 'Linkflow',
    FullName: 'Linkflow (LF)',
  },
  CWD: {
    Name: 'CWD',
    Symbol: 'CWD',
    CoinName: 'CROWD',
    FullName: 'CROWD (CWD)',
  },
  THEOS: {
    Name: 'THEOS',
    Symbol: 'THEOS',
    CoinName: 'Theos',
    FullName: 'Theos (THEOS)',
  },
  BNSD: {
    Name: 'BNSD',
    Symbol: 'BNSD',
    CoinName: 'BNSD Finance',
    FullName: 'BNSD Finance (BNSD)',
  },
  FDLS: {
    Name: 'FDLS',
    Symbol: 'FDLS',
    CoinName: 'FIDELIS',
    FullName: 'FIDELIS (FDLS)',
  },
  AIC: {
    Name: 'AIC',
    Symbol: 'AIC',
    CoinName: 'AI Crypto',
    FullName: 'AI Crypto (AIC)',
  },
  ARI: {
    Name: 'ARI',
    Symbol: 'ARI',
    CoinName: 'AriCoin',
    FullName: 'AriCoin (ARI)',
  },
  ARNX: {
    Name: 'ARNX',
    Symbol: 'ARNX',
    CoinName: 'Aeron',
    FullName: 'Aeron (ARNX)',
  },
  BABYDOGE: {
    Name: 'BABYDOGE',
    Symbol: 'BABYDOGE',
    CoinName: 'BabyDoge',
    FullName: 'BabyDoge (BABYDOGE)',
  },
  BEC: {
    Name: 'BEC',
    Symbol: 'BEC',
    CoinName: 'Betherchip',
    FullName: 'Betherchip (BEC)',
  },
  BFIC: {
    Name: 'BFIC',
    Symbol: 'BFIC',
    CoinName: 'Bficoin',
    FullName: 'Bficoin (BFIC)',
  },
  BITS: {
    Name: 'BITS',
    Symbol: 'BITS',
    CoinName: 'BitstarCoin',
    FullName: 'BitstarCoin (BITS)',
  },
  BXK: {
    Name: 'BXK',
    Symbol: 'BXK',
    CoinName: 'Bitbook Gambling',
    FullName: 'Bitbook Gambling (BXK)',
  },
  CDY: {
    Name: 'CDY',
    Symbol: 'CDY',
    CoinName: 'Bitcoin Candy',
    FullName: 'Bitcoin Candy (CDY)',
  },
  CHA: {
    Name: 'CHA',
    Symbol: 'CHA',
    CoinName: 'Charity Coin',
    FullName: 'Charity Coin (CHA)',
  },
  CIN: {
    Name: 'CIN',
    Symbol: 'CIN',
    CoinName: 'CinderCoin',
    FullName: 'CinderCoin (CIN)',
  },
  CLOUT: {
    Name: 'CLOUT',
    Symbol: 'CLOUT',
    CoinName: 'BitClout',
    FullName: 'BitClout (CLOUT)',
  },
  CONV: {
    Name: 'CONV',
    Symbol: 'CONV',
    CoinName: 'Convergence',
    FullName: 'Convergence (CONV)',
  },
  CREA: {
    Name: 'CREA',
    Symbol: 'CREA',
    CoinName: 'CreativeChain',
    FullName: 'CreativeChain (CREA)',
  },
  CVA: {
    Name: 'CVA',
    Symbol: 'CVA',
    CoinName: 'Crypto Village Accelerator',
    FullName: 'Crypto Village Accelerator (CVA)',
  },
  KVERSE: {
    Name: 'KVERSE',
    Symbol: 'KVERSE',
    CoinName: 'KEEPs Coin',
    FullName: 'KEEPs Coin (KVERSE)',
  },
  FDM: {
    Name: 'FDM',
    Symbol: 'FDM',
    CoinName: 'Fandom',
    FullName: 'Fandom (FDM)',
  },
  BZRX: {
    Name: 'BZRX',
    Symbol: 'BZRX',
    CoinName: 'bZx Protocol',
    FullName: 'bZx Protocol (BZRX)',
  },
  SPY: {
    Name: 'SPY',
    Symbol: 'SPY',
    CoinName: 'Smarty Pay',
    FullName: 'Smarty Pay (SPY)',
  },
  LPY: {
    Name: 'LPY',
    Symbol: 'LPY',
    CoinName: 'LeisurePay',
    FullName: 'LeisurePay (LPY)',
  },
  GBPT: {
    Name: 'GBPT',
    Symbol: 'GBPT',
    CoinName: 'poundtoken',
    FullName: 'poundtoken (GBPT)',
  },
  SMTF: {
    Name: 'SMTF',
    Symbol: 'SMTF',
    CoinName: 'SmartFi',
    FullName: 'SmartFi (SMTF)',
  },
  MOGX: {
    Name: 'MOGX',
    Symbol: 'MOGX',
    CoinName: 'Mogu',
    FullName: 'Mogu (MOGX)',
  },
  WAXE: {
    Name: 'WAXE',
    Symbol: 'WAXE',
    CoinName: 'WAXE',
    FullName: 'WAXE (WAXE)',
  },
  POPK: {
    Name: 'POPK',
    Symbol: 'POPK',
    CoinName: 'POPKON',
    FullName: 'POPKON (POPK)',
  },
  GHOSTM: {
    Name: 'GHOSTM',
    Symbol: 'GHOSTM',
    CoinName: 'GhostMarket',
    FullName: 'GhostMarket (GHOSTM)',
  },
  GLC: {
    Name: 'GLC',
    Symbol: 'GLC',
    CoinName: 'GoldCoin',
    FullName: 'GoldCoin (GLC)',
  },
  BB: {
    Name: 'BB',
    Symbol: 'BB',
    CoinName: 'Baby Bali',
    FullName: 'Baby Bali (BB)',
  },
  GAMI: {
    Name: 'GAMI',
    Symbol: 'GAMI',
    CoinName: 'GAMI World',
    FullName: 'GAMI World (GAMI)',
  },
  BKBT: {
    Name: 'BKBT',
    Symbol: 'BKBT',
    CoinName: 'BeeKan',
    FullName: 'BeeKan (BKBT)',
  },
  FNF: {
    Name: 'FNF',
    Symbol: 'FNF',
    CoinName: 'FunFi',
    FullName: 'FunFi (FNF)',
  },
  CURI: {
    Name: 'CURI',
    Symbol: 'CURI',
    CoinName: 'Curium',
    FullName: 'Curium (CURI)',
  },
  YGG: {
    Name: 'YGG',
    Symbol: 'YGG',
    CoinName: 'Yield Guild Games',
    FullName: 'Yield Guild Games (YGG)',
  },
  CHZ: {
    Name: 'CHZ',
    Symbol: 'CHZ',
    CoinName: 'Chiliz',
    FullName: 'Chiliz (CHZ)',
  },
  DAI: {
    Name: 'DAI',
    Symbol: 'DAI',
    CoinName: 'Dai',
    FullName: 'Dai (DAI)',
  },
  SUSD: {
    Name: 'SUSD',
    Symbol: 'SUSD',
    CoinName: 'sUSD',
    FullName: 'sUSD (SUSD)',
  },
  INDIA: {
    Name: 'INDIA',
    Symbol: 'INDIA',
    CoinName: 'Indiacoin',
    FullName: 'Indiacoin (INDIA)',
  },
  APOLLO: {
    Name: 'APOLLO',
    Symbol: 'APOLLO',
    CoinName: 'Apollo Crypto',
    FullName: 'Apollo Crypto (APOLLO)',
  },
  MMG: {
    Name: 'MMG',
    Symbol: 'MMG',
    CoinName: 'Monopoly Millionaire Game',
    FullName: 'Monopoly Millionaire Game (MMG)',
  },
  AVDO: {
    Name: 'AVDO',
    Symbol: 'AVDO',
    CoinName: 'AvocadoCoin',
    FullName: 'AvocadoCoin (AVDO)',
  },
  CSTR: {
    Name: 'CSTR',
    Symbol: 'CSTR',
    CoinName: 'CoreStarter',
    FullName: 'CoreStarter (CSTR)',
  },
  TUSD: {
    Name: 'TUSD',
    Symbol: 'TUSD',
    CoinName: 'True USD',
    FullName: 'True USD (TUSD)',
  },
  KLAP: {
    Name: 'KLAP',
    Symbol: 'KLAP',
    CoinName: 'Klap Finance',
    FullName: 'Klap Finance (KLAP)',
  },
  XMARK: {
    Name: 'XMARK',
    Symbol: 'XMARK',
    CoinName: 'xMARK',
    FullName: 'xMARK (XMARK)',
  },
  METAG: {
    Name: 'METAG',
    Symbol: 'METAG',
    CoinName: 'MetagamZ',
    FullName: 'MetagamZ (METAG)',
  },
  KNOT: {
    Name: 'KNOT',
    Symbol: 'KNOT',
    CoinName: 'Karmaverse',
    FullName: 'Karmaverse (KNOT)',
  },
  FOF: {
    Name: 'FOF',
    Symbol: 'FOF',
    CoinName: 'Future Of Fintech',
    FullName: 'Future Of Fintech (FOF)',
  },
  MOPS: {
    Name: 'MOPS',
    Symbol: 'MOPS',
    CoinName: 'Mops',
    FullName: 'Mops (MOPS)',
  },
  HECTA: {
    Name: 'HECTA',
    Symbol: 'HECTA',
    CoinName: 'Hectagon',
    FullName: 'Hectagon (HECTA)',
  },
  SUZUME: {
    Name: 'SUZUME',
    Symbol: 'SUZUME',
    CoinName: 'Shita-kiri Suzume',
    FullName: 'Shita-kiri Suzume (SUZUME)',
  },
  SOM: {
    Name: 'SOM',
    Symbol: 'SOM',
    CoinName: 'Souls of Meta',
    FullName: 'Souls of Meta (SOM)',
  },
  CHRP: {
    Name: 'CHRP',
    Symbol: 'CHRP',
    CoinName: 'Chirpley',
    FullName: 'Chirpley (CHRP)',
  },
  AITECH: {
    Name: 'AITECH',
    Symbol: 'AITECH',
    CoinName: 'Artificial Intelligence Utility Token',
    FullName: 'Artificial Intelligence Utility Token (AITECH)',
  },
  ARIA: {
    Name: 'ARIA',
    Symbol: 'ARIA',
    CoinName: 'Legends of Aria',
    FullName: 'Legends of Aria (ARIA)',
  },
  KINGDOMQUEST: {
    Name: 'KINGDOMQUEST',
    Symbol: 'KINGDOMQUEST',
    CoinName: 'Kingdom Quest',
    FullName: 'Kingdom Quest (KINGDOMQUEST)',
  },
  VSYS: {
    Name: 'VSYS',
    Symbol: 'VSYS',
    CoinName: 'V Systems',
    FullName: 'V Systems (VSYS)',
  },
  NEC: {
    Name: 'NEC',
    Symbol: 'NEC',
    CoinName: 'Nectar',
    FullName: 'Nectar (NEC)',
  },
  HIENS4: {
    Name: 'HIENS4',
    Symbol: 'HIENS4',
    CoinName: 'hiENS4',
    FullName: 'hiENS4 (HIENS4)',
  },
  ACK: {
    Name: 'ACK',
    Symbol: 'ACK',
    CoinName: 'Arcade Kingdoms',
    FullName: 'Arcade Kingdoms (ACK)',
  },
  ARTH: {
    Name: 'ARTH',
    Symbol: 'ARTH',
    CoinName: 'ARTH',
    FullName: 'ARTH (ARTH)',
  },
  WNXM: {
    Name: 'WNXM',
    Symbol: 'WNXM',
    CoinName: 'Wrapped NXM',
    FullName: 'Wrapped NXM (WNXM)',
  },
  TOKKI: {
    Name: 'TOKKI',
    Symbol: 'TOKKI',
    CoinName: 'CRYPTOKKI',
    FullName: 'CRYPTOKKI (TOKKI)',
  },
  REDLC: {
    Name: 'REDLC',
    Symbol: 'REDLC',
    CoinName: 'Redlight Chain',
    FullName: 'Redlight Chain (REDLC)',
  },
  GIGA: {
    Name: 'GIGA',
    Symbol: 'GIGA',
    CoinName: 'GigaSwap',
    FullName: 'GigaSwap (GIGA)',
  },
  MPM: {
    Name: 'MPM',
    Symbol: 'MPM',
    CoinName: 'Monopoly Meta',
    FullName: 'Monopoly Meta (MPM)',
  },
  IJZ: {
    Name: 'IJZ',
    Symbol: 'IJZ',
    CoinName: 'iinjaz',
    FullName: 'iinjaz (IJZ)',
  },
  DOGECUBE: {
    Name: 'DOGECUBE',
    Symbol: 'DOGECUBE',
    CoinName: 'DogeCube',
    FullName: 'DogeCube (DOGECUBE)',
  },
  '37C': {
    Name: '37C',
    Symbol: '37C',
    CoinName: '37Protocol',
    FullName: '37Protocol (37C)',
  },
  CLEG: {
    Name: 'CLEG',
    Symbol: 'CLEG',
    CoinName: 'Chain of Legends',
    FullName: 'Chain of Legends (CLEG)',
  },
  BANDEX: {
    Name: 'BANDEX',
    Symbol: 'BANDEX',
    CoinName: 'Banana Index',
    FullName: 'Banana Index (BANDEX)',
  },
  QLINDO: {
    Name: 'QLINDO',
    Symbol: 'QLINDO',
    CoinName: 'QLINDO',
    FullName: 'QLINDO (QLINDO)',
  },
  HZM: {
    Name: 'HZM',
    Symbol: 'HZM',
    CoinName: 'HZM Coin',
    FullName: 'HZM Coin (HZM)',
  },
  AMPLIFI: {
    Name: 'AMPLIFI',
    Symbol: 'AMPLIFI',
    CoinName: 'AmpliFi',
    FullName: 'AmpliFi (AMPLIFI)',
  },
  DEFY: {
    Name: 'DEFY',
    Symbol: 'DEFY',
    CoinName: 'DEFY',
    FullName: 'DEFY (DEFY)',
  },
  P202: {
    Name: 'P202',
    Symbol: 'P202',
    CoinName: 'Project 202',
    FullName: 'Project 202 (P202)',
  },
  MTD: {
    Name: 'MTD',
    Symbol: 'MTD',
    CoinName: 'Minted',
    FullName: 'Minted (MTD)',
  },
  BACK: {
    Name: 'BACK',
    Symbol: 'BACK',
    CoinName: 'DollarBack',
    FullName: 'DollarBack (BACK)',
  },
  CATS: {
    Name: 'CATS',
    Symbol: 'CATS',
    CoinName: 'CatCoin Token',
    FullName: 'CatCoin Token (CATS)',
  },
  KSP: {
    Name: 'KSP',
    Symbol: 'KSP',
    CoinName: 'KlaySwap Protocol',
    FullName: 'KlaySwap Protocol (KSP)',
  },
  TWEP: {
    Name: 'TWEP',
    Symbol: 'TWEP',
    CoinName: 'The Web3 Project',
    FullName: 'The Web3 Project (TWEP)',
  },
  MCD: {
    Name: 'MCD',
    Symbol: 'MCD',
    CoinName: 'CDbio',
    FullName: 'CDbio (MCD)',
  },
  UDOO: {
    Name: 'UDOO',
    Symbol: 'UDOO',
    CoinName: 'Hyprr',
    FullName: 'Hyprr (UDOO)',
  },
  SERG: {
    Name: 'SERG',
    Symbol: 'SERG',
    CoinName: 'Seiren Games Network',
    FullName: 'Seiren Games Network (SERG)',
  },
  SGOLD: {
    Name: 'SGOLD',
    Symbol: 'SGOLD',
    CoinName: 'SpaceGold',
    FullName: 'SpaceGold (SGOLD)',
  },
  GOPX: {
    Name: 'GOPX',
    Symbol: 'GOPX',
    CoinName: 'GOPX Token',
    FullName: 'GOPX Token (GOPX)',
  },
  AVAT: {
    Name: 'AVAT',
    Symbol: 'AVAT',
    CoinName: 'AVATA Network',
    FullName: 'AVATA Network (AVAT)',
  },
  ALGOBLK: {
    Name: 'ALGOBLK',
    Symbol: 'ALGOBLK',
    CoinName: 'AlgoBlocks',
    FullName: 'AlgoBlocks (ALGOBLK)',
  },
  KILLER: {
    Name: 'KILLER',
    Symbol: 'KILLER',
    CoinName: 'Fat Cat Killer',
    FullName: 'Fat Cat Killer (KILLER)',
  },
  BIGSB: {
    Name: 'BIGSB',
    Symbol: 'BIGSB',
    CoinName: 'BigShortBets',
    FullName: 'BigShortBets (BIGSB)',
  },
  GUILD: {
    Name: 'GUILD',
    Symbol: 'GUILD',
    CoinName: 'BlockchainSpace',
    FullName: 'BlockchainSpace (GUILD)',
  },
  YCO: {
    Name: 'YCO',
    Symbol: 'YCO',
    CoinName: 'Y Coin',
    FullName: 'Y Coin (YCO)',
  },
  IMBTC: {
    Name: 'IMBTC',
    Symbol: 'IMBTC',
    CoinName: 'The Tokenized Bitcoin',
    FullName: 'The Tokenized Bitcoin (IMBTC)',
  },
  BDY: {
    Name: 'BDY',
    Symbol: 'BDY',
    CoinName: 'Buddy DAO',
    FullName: 'Buddy DAO (BDY)',
  },
  DOGEMETA: {
    Name: 'DOGEMETA',
    Symbol: 'DOGEMETA',
    CoinName: 'Dogemetaverse',
    FullName: 'Dogemetaverse (DOGEMETA)',
  },
  XI: {
    Name: 'XI',
    Symbol: 'XI',
    CoinName: 'Xi',
    FullName: 'Xi (XI)',
  },
  VTS: {
    Name: 'VTS',
    Symbol: 'VTS',
    CoinName: 'Veritise',
    FullName: 'Veritise (VTS)',
  },
  CENX: {
    Name: 'CENX',
    Symbol: 'CENX',
    CoinName: 'Centcex',
    FullName: 'Centcex (CENX)',
  },
  PHN: {
    Name: 'PHN',
    Symbol: 'PHN',
    CoinName: 'Phayny',
    FullName: 'Phayny (PHN)',
  },
  NASSR: {
    Name: 'NASSR',
    Symbol: 'NASSR',
    CoinName: 'Alnassr FC Fan Token',
    FullName: 'Alnassr FC Fan Token (NASSR)',
  },
  LUNC: {
    Name: 'LUNC',
    Symbol: 'LUNC',
    CoinName: 'Terra Classic',
    FullName: 'Terra Classic (LUNC)',
  },
  SKL: {
    Name: 'SKL',
    Symbol: 'SKL',
    CoinName: 'SKALE Network',
    FullName: 'SKALE Network (SKL)',
  },
  AVA: {
    Name: 'AVA',
    Symbol: 'AVA',
    CoinName: 'Travala',
    FullName: 'Travala (AVA)',
  },
  WBTC: {
    Name: 'WBTC',
    Symbol: 'WBTC',
    CoinName: 'Wrapped Bitcoin',
    FullName: 'Wrapped Bitcoin (WBTC)',
  },
  KODACHI: {
    Name: 'KODACHI',
    Symbol: 'KODACHI',
    CoinName: 'Kodachi Token',
    FullName: 'Kodachi Token (KODACHI)',
  },
  THECITADEL: {
    Name: 'THECITADEL',
    Symbol: 'THECITADEL',
    CoinName: 'The Citadel',
    FullName: 'The Citadel (THECITADEL)',
  },
  LEZ: {
    Name: 'LEZ',
    Symbol: 'LEZ',
    CoinName: 'Peoplez',
    FullName: 'Peoplez (LEZ)',
  },
  KKT: {
    Name: 'KKT',
    Symbol: 'KKT',
    CoinName: 'Kingdom Karnage',
    FullName: 'Kingdom Karnage (KKT)',
  },
  FEENIXV2: {
    Name: 'FEENIXV2',
    Symbol: 'FEENIXV2',
    CoinName: 'ProjectFeenixv2',
    FullName: 'ProjectFeenixv2 (FEENIXV2)',
  },
  HTB: {
    Name: 'HTB',
    Symbol: 'HTB',
    CoinName: 'Hotbit',
    FullName: 'Hotbit (HTB)',
  },
  CTN: {
    Name: 'CTN',
    Symbol: 'CTN',
    CoinName: 'Continuum Finance',
    FullName: 'Continuum Finance (CTN)',
  },
  FVT: {
    Name: 'FVT',
    Symbol: 'FVT',
    CoinName: 'Finance Vote',
    FullName: 'Finance Vote (FVT)',
  },
  BXTB: {
    Name: 'BXTB',
    Symbol: 'BXTB',
    CoinName: 'BXTB Foundation',
    FullName: 'BXTB Foundation (BXTB)',
  },
  ZPAY: {
    Name: 'ZPAY',
    Symbol: 'ZPAY',
    CoinName: 'ZoidPay',
    FullName: 'ZoidPay (ZPAY)',
  },
  RBXS: {
    Name: 'RBXS',
    Symbol: 'RBXS',
    CoinName: 'RBXSamurai',
    FullName: 'RBXSamurai (RBXS)',
  },
  FRZSS: {
    Name: 'FRZSS',
    Symbol: 'FRZSS',
    CoinName: 'Frz Solar System',
    FullName: 'Frz Solar System (FRZSS)',
  },
  POLVEN: {
    Name: 'POLVEN',
    Symbol: 'POLVEN',
    CoinName: 'Polka Ventures',
    FullName: 'Polka Ventures (POLVEN)',
  },
  CYOP: {
    Name: 'CYOP',
    Symbol: 'CYOP',
    CoinName: 'CyOp Protocol',
    FullName: 'CyOp Protocol (CYOP)',
  },
  OTHR: {
    Name: 'OTHR',
    Symbol: 'OTHR',
    CoinName: 'OtherDAO',
    FullName: 'OtherDAO (OTHR)',
  },
  LXF: {
    Name: 'LXF',
    Symbol: 'LXF',
    CoinName: 'LuxFi',
    FullName: 'LuxFi (LXF)',
  },
  SLRR: {
    Name: 'SLRR',
    Symbol: 'SLRR',
    CoinName: 'Solarr',
    FullName: 'Solarr (SLRR)',
  },
  TRAXX: {
    Name: 'TRAXX',
    Symbol: 'TRAXX',
    CoinName: 'Traxx',
    FullName: 'Traxx (TRAXX)',
  },
  COLR: {
    Name: 'COLR',
    Symbol: 'COLR',
    CoinName: 'colR Coin',
    FullName: 'colR Coin (COLR)',
  },
  WINGS: {
    Name: 'WINGS',
    Symbol: 'WINGS',
    CoinName: 'Wings DAO',
    FullName: 'Wings DAO (WINGS)',
  },
  SENT: {
    Name: 'SENT',
    Symbol: 'SENT',
    CoinName: 'Sentinel',
    FullName: 'Sentinel (SENT)',
  },
  BLOCM: {
    Name: 'BLOCM',
    Symbol: 'BLOCM',
    CoinName: 'BLOC.MONEY',
    FullName: 'BLOC.MONEY (BLOCM)',
  },
  BLOC: {
    Name: 'BLOC',
    Symbol: 'BLOC',
    CoinName: 'Blockcloud',
    FullName: 'Blockcloud (BLOC)',
  },
  TKN: {
    Name: 'TKN',
    Symbol: 'TKN',
    CoinName: 'Monolith',
    FullName: 'Monolith (TKN)',
  },
  XEC: {
    Name: 'XEC',
    Symbol: 'XEC',
    CoinName: 'eCash',
    FullName: 'eCash (XEC)',
  },
  PLA: {
    Name: 'PLA',
    Symbol: 'PLA',
    CoinName: 'PlayDapp',
    FullName: 'PlayDapp (PLA)',
  },
  FLUX: {
    Name: 'FLUX',
    Symbol: 'FLUX',
    CoinName: 'Flux',
    FullName: 'Flux (FLUX)',
  },
  GODS: {
    Name: 'GODS',
    Symbol: 'GODS',
    CoinName: 'Gods Unchained',
    FullName: 'Gods Unchained (GODS)',
  },
  NFT: {
    Name: 'NFT',
    Symbol: 'NFT',
    CoinName: 'APENFT',
    FullName: 'APENFT (NFT)',
  },
  HTO: {
    Name: 'HTO',
    Symbol: 'HTO',
    CoinName: 'Heavenland HTO',
    FullName: 'Heavenland HTO (HTO)',
  },
  SAITAMAV1: {
    Name: 'SAITAMAV1',
    Symbol: 'SAITAMAV1',
    CoinName: 'Saitama v1',
    FullName: 'Saitama v1 (SAITAMAV1)',
  },
  META: {
    Name: 'META',
    Symbol: 'META',
    CoinName: 'Metadium',
    FullName: 'Metadium (META)',
  },
  BURGER: {
    Name: 'BURGER',
    Symbol: 'BURGER',
    CoinName: 'Burger Swap',
    FullName: 'Burger Swap (BURGER)',
  },
  ENS: {
    Name: 'ENS',
    Symbol: 'ENS',
    CoinName: 'Ethereum Name Service',
    FullName: 'Ethereum Name Service (ENS)',
  },
  STMX: {
    Name: 'STMX',
    Symbol: 'STMX',
    CoinName: 'StormX',
    FullName: 'StormX (STMX)',
  },
  JASMY: {
    Name: 'JASMY',
    Symbol: 'JASMY',
    CoinName: 'JasmyCoin',
    FullName: 'JasmyCoin (JASMY)',
  },
  STPT: {
    Name: 'STPT',
    Symbol: 'STPT',
    CoinName: 'STP Network',
    FullName: 'STP Network (STPT)',
  },
  BAL: {
    Name: 'BAL',
    Symbol: 'BAL',
    CoinName: 'Balancer',
    FullName: 'Balancer (BAL)',
  },
  '1INCH': {
    Name: '1INCH',
    Symbol: '1INCH',
    CoinName: '1inch',
    FullName: '1inch (1INCH)',
  },
  ONE: {
    Name: 'ONE',
    Symbol: 'ONE',
    CoinName: 'Harmony',
    FullName: 'Harmony (ONE)',
  },
  KLAY: {
    Name: 'KLAY',
    Symbol: 'KLAY',
    CoinName: 'Klaytn',
    FullName: 'Klaytn (KLAY)',
  },
  HBAR: {
    Name: 'HBAR',
    Symbol: 'HBAR',
    CoinName: 'Hedera Hashgraph',
    FullName: 'Hedera Hashgraph (HBAR)',
  },
  KIBA: {
    Name: 'KIBA',
    Symbol: 'KIBA',
    CoinName: 'Kiba Inu',
    FullName: 'Kiba Inu (KIBA)',
  },
  PPALPHA: {
    Name: 'PPALPHA',
    Symbol: 'PPALPHA',
    CoinName: 'Phoenix Protocol',
    FullName: 'Phoenix Protocol (PPALPHA)',
  },
  TINU: {
    Name: 'TINU',
    Symbol: 'TINU',
    CoinName: 'Telegram Inu',
    FullName: 'Telegram Inu (TINU)',
  },
  MNFT: {
    Name: 'MNFT',
    Symbol: 'MNFT',
    CoinName: 'Marvelous NFTs',
    FullName: 'Marvelous NFTs (MNFT)',
  },
  ACAT: {
    Name: 'ACAT',
    Symbol: 'ACAT',
    CoinName: 'Alphacat',
    FullName: 'Alphacat (ACAT)',
  },
  XSN: {
    Name: 'XSN',
    Symbol: 'XSN',
    CoinName: 'StakeNet',
    FullName: 'StakeNet (XSN)',
  },
  BCD: {
    Name: 'BCD',
    Symbol: 'BCD',
    CoinName: 'Bitcoin Diamond',
    FullName: 'Bitcoin Diamond (BCD)',
  },
  POT: {
    Name: 'POT',
    Symbol: 'POT',
    CoinName: 'PotCoin',
    FullName: 'PotCoin (POT)',
  },
  PORTAL: {
    Name: 'PORTAL',
    Symbol: 'PORTAL',
    CoinName: 'Portal',
    FullName: 'Portal (PORTAL)',
  },
  S2K: {
    Name: 'S2K',
    Symbol: 'S2K',
    CoinName: 'Sports 2K75',
    FullName: 'Sports 2K75 (S2K)',
  },
  TRNDZ: {
    Name: 'TRNDZ',
    Symbol: 'TRNDZ',
    CoinName: 'Trendsy',
    FullName: 'Trendsy (TRNDZ)',
  },
  LKD: {
    Name: 'LKD',
    Symbol: 'LKD',
    CoinName: 'LinkDao',
    FullName: 'LinkDao (LKD)',
  },
  TAO: {
    Name: 'TAO',
    Symbol: 'TAO',
    CoinName: 'Fusotao',
    FullName: 'Fusotao (TAO)',
  },
  USDI: {
    Name: 'USDI',
    Symbol: 'USDI',
    CoinName: 'Interest Protocol USDi',
    FullName: 'Interest Protocol USDi (USDI)',
  },
  BTCPAY: {
    Name: 'BTCPAY',
    Symbol: 'BTCPAY',
    CoinName: 'Bitcoin Pay',
    FullName: 'Bitcoin Pay (BTCPAY)',
  },
  PRXY: {
    Name: 'PRXY',
    Symbol: 'PRXY',
    CoinName: 'Proxy',
    FullName: 'Proxy (PRXY)',
  },
  YESCOIN: {
    Name: 'YESCOIN',
    Symbol: 'YESCOIN',
    CoinName: 'YesCoin',
    FullName: 'YesCoin (YESCOIN)',
  },
  EARN: {
    Name: 'EARN',
    Symbol: 'EARN',
    CoinName: 'EarnGuild',
    FullName: 'EarnGuild (EARN)',
  },
  BSGG: {
    Name: 'BSGG',
    Symbol: 'BSGG',
    CoinName: 'Betswap.gg',
    FullName: 'Betswap.gg (BSGG)',
  },
  ITG: {
    Name: 'ITG',
    Symbol: 'ITG',
    CoinName: 'iTrust Governance',
    FullName: 'iTrust Governance (ITG)',
  },
  FREL: {
    Name: 'FREL',
    Symbol: 'FREL',
    CoinName: 'Freela',
    FullName: 'Freela (FREL)',
  },
  TOON: {
    Name: 'TOON',
    Symbol: 'TOON',
    CoinName: 'Pontoon',
    FullName: 'Pontoon (TOON)',
  },
  D11: {
    Name: 'D11',
    Symbol: 'D11',
    CoinName: 'DeFi11',
    FullName: 'DeFi11 (D11)',
  },
  SQAT: {
    Name: 'SQAT',
    Symbol: 'SQAT',
    CoinName: 'Syndiqate',
    FullName: 'Syndiqate (SQAT)',
  },
  GALA: {
    Name: 'GALA',
    Symbol: 'GALA',
    CoinName: 'Gala',
    FullName: 'Gala (GALA)',
  },
  DAT: {
    Name: 'DAT',
    Symbol: 'DAT',
    CoinName: 'Datum',
    FullName: 'Datum (DAT)',
  },
  GAMEC: {
    Name: 'GAMEC',
    Symbol: 'GAMEC',
    CoinName: 'Game',
    FullName: 'Game (GAMEC)',
  },
  ETHPOW: {
    Name: 'ETHPOW',
    Symbol: 'ETHPOW',
    CoinName: 'ETHPoW',
    FullName: 'ETHPoW (ETHPOW)',
  },
  ETHPOS: {
    Name: 'ETHPOS',
    Symbol: 'ETHPOS',
    CoinName: 'ETHPoS',
    FullName: 'ETHPoS (ETHPOS)',
  },
  SQUIDGROW: {
    Name: 'SQUIDGROW',
    Symbol: 'SQUIDGROW',
    CoinName: 'SquidGrow',
    FullName: 'SquidGrow (SQUIDGROW)',
  },
  JACY: {
    Name: 'JACY',
    Symbol: 'JACY',
    CoinName: 'JACY',
    FullName: 'JACY (JACY)',
  },
  IUX: {
    Name: 'IUX',
    Symbol: 'IUX',
    CoinName: 'GeniuX',
    FullName: 'GeniuX (IUX)',
  },
  NGM: {
    Name: 'NGM',
    Symbol: 'NGM',
    CoinName: 'e-Money',
    FullName: 'e-Money (NGM)',
  },
  GLM: {
    Name: 'GLM',
    Symbol: 'GLM',
    CoinName: 'Golem Network Token',
    FullName: 'Golem Network Token (GLM)',
  },
  FLOW: {
    Name: 'FLOW',
    Symbol: 'FLOW',
    CoinName: 'Flow',
    FullName: 'Flow (FLOW)',
  },
  REEF: {
    Name: 'REEF',
    Symbol: 'REEF',
    CoinName: 'Reef',
    FullName: 'Reef (REEF)',
  },
  DARC: {
    Name: 'DARC',
    Symbol: 'DARC',
    CoinName: 'Konstellation',
    FullName: 'Konstellation (DARC)',
  },
  UMEE: {
    Name: 'UMEE',
    Symbol: 'UMEE',
    CoinName: 'Umee',
    FullName: 'Umee (UMEE)',
  },
  INX: {
    Name: 'INX',
    Symbol: 'INX',
    CoinName: 'Insight Protocol',
    FullName: 'Insight Protocol (INX)',
  },
  BLD: {
    Name: 'BLD',
    Symbol: 'BLD',
    CoinName: 'Agoric',
    FullName: 'Agoric (BLD)',
  },
  WING: {
    Name: 'WING',
    Symbol: 'WING',
    CoinName: 'Wing Finance',
    FullName: 'Wing Finance (WING)',
  },
  WMEMO: {
    Name: 'WMEMO',
    Symbol: 'WMEMO',
    CoinName: 'Wonderful Memories',
    FullName: 'Wonderful Memories (WMEMO)',
  },
  ORAI: {
    Name: 'ORAI',
    Symbol: 'ORAI',
    CoinName: 'Oraichain Token',
    FullName: 'Oraichain Token (ORAI)',
  },
  ANC: {
    Name: 'ANC',
    Symbol: 'ANC',
    CoinName: 'Anchor Protocol',
    FullName: 'Anchor Protocol (ANC)',
  },
  KAVA: {
    Name: 'KAVA',
    Symbol: 'KAVA',
    CoinName: 'Kava',
    FullName: 'Kava (KAVA)',
  },
  XPRT: {
    Name: 'XPRT',
    Symbol: 'XPRT',
    CoinName: 'Persistence',
    FullName: 'Persistence (XPRT)',
  },
  IOV: {
    Name: 'IOV',
    Symbol: 'IOV',
    CoinName: 'Starname',
    FullName: 'Starname (IOV)',
  },
  JUNO: {
    Name: 'JUNO',
    Symbol: 'JUNO',
    CoinName: 'JUNO',
    FullName: 'JUNO (JUNO)',
  },
  LIKEC: {
    Name: 'LIKEC',
    Symbol: 'LIKEC',
    CoinName: 'LikeCoin',
    FullName: 'LikeCoin (LIKEC)',
  },
  MED: {
    Name: 'MED',
    Symbol: 'MED',
    CoinName: 'Medibloc',
    FullName: 'Medibloc (MED)',
  },
  CFX: {
    Name: 'CFX',
    Symbol: 'CFX',
    CoinName: 'Conflux Network',
    FullName: 'Conflux Network (CFX)',
  },
  BEAM: {
    Name: 'BEAM',
    Symbol: 'BEAM',
    CoinName: 'Beam',
    FullName: 'Beam (BEAM)',
  },
  MNC: {
    Name: 'MNC',
    Symbol: 'MNC',
    CoinName: 'MainCoin',
    FullName: 'MainCoin (MNC)',
  },
  GAS: {
    Name: 'GAS',
    Symbol: 'GAS',
    CoinName: 'Gas',
    FullName: 'Gas (GAS)',
  },
  EVER: {
    Name: 'EVER',
    Symbol: 'EVER',
    CoinName: 'Everscale',
    FullName: 'Everscale (EVER)',
  },
  DCNT: {
    Name: 'DCNT',
    Symbol: 'DCNT',
    CoinName: 'Decanect',
    FullName: 'Decanect (DCNT)',
  },
  WLXT: {
    Name: 'WLXT',
    Symbol: 'WLXT',
    CoinName: 'Wallex Token',
    FullName: 'Wallex Token (WLXT)',
  },
  THETA: {
    Name: 'THETA',
    Symbol: 'THETA',
    CoinName: 'Theta Network',
    FullName: 'Theta Network (THETA)',
  },
  CAKE: {
    Name: 'CAKE',
    Symbol: 'CAKE',
    CoinName: 'PancakeSwap',
    FullName: 'PancakeSwap (CAKE)',
  },
  DYDX: {
    Name: 'DYDX',
    Symbol: 'DYDX',
    CoinName: 'dYdX',
    FullName: 'dYdX (DYDX)',
  },
  EPX: {
    Name: 'EPX',
    Symbol: 'EPX',
    CoinName: 'Ellipsis X',
    FullName: 'Ellipsis X (EPX)',
  },
  GMT: {
    Name: 'GMT',
    Symbol: 'GMT',
    CoinName: 'STEPN',
    FullName: 'STEPN (GMT)',
  },
  EURS: {
    Name: 'EURS',
    Symbol: 'EURS',
    CoinName: 'STASIS EURS',
    FullName: 'STASIS EURS (EURS)',
  },
  STUDENTC: {
    Name: 'STUDENTC',
    Symbol: 'STUDENTC',
    CoinName: 'Student Coin',
    FullName: 'Student Coin (STUDENTC)',
  },
  STC: {
    Name: 'STC',
    Symbol: 'STC',
    CoinName: 'Satoshi Island',
    FullName: 'Satoshi Island (STC)',
  },
  ROSE: {
    Name: 'ROSE',
    Symbol: 'ROSE',
    CoinName: 'Oasis Labs',
    FullName: 'Oasis Labs (ROSE)',
  },
  ABBC: {
    Name: 'ABBC',
    Symbol: 'ABBC',
    CoinName: 'ABBC Coin',
    FullName: 'ABBC Coin (ABBC)',
  },
  ALG: {
    Name: 'ALG',
    Symbol: 'ALG',
    CoinName: 'Algory',
    FullName: 'Algory (ALG)',
  },
  AMIO: {
    Name: 'AMIO',
    Symbol: 'AMIO',
    CoinName: 'Amino Network',
    FullName: 'Amino Network (AMIO)',
  },
  APPC: {
    Name: 'APPC',
    Symbol: 'APPC',
    CoinName: 'AppCoins',
    FullName: 'AppCoins (APPC)',
  },
  EXP: {
    Name: 'EXP',
    Symbol: 'EXP',
    CoinName: 'Expanse',
    FullName: 'Expanse (EXP)',
  },
  BZNT: {
    Name: 'BZNT',
    Symbol: 'BZNT',
    CoinName: 'Bezant',
    FullName: 'Bezant (BZNT)',
  },
  BTG: {
    Name: 'BTG',
    Symbol: 'BTG',
    CoinName: 'Bitcoin Gold',
    FullName: 'Bitcoin Gold (BTG)',
  },
  BSV: {
    Name: 'BSV',
    Symbol: 'BSV',
    CoinName: 'Bitcoin SV',
    FullName: 'Bitcoin SV (BSV)',
  },
  BF: {
    Name: 'BF',
    Symbol: 'BF',
    CoinName: 'BitForex Token',
    FullName: 'BitForex Token (BF)',
  },
  ZEC: {
    Name: 'ZEC',
    Symbol: 'ZEC',
    CoinName: 'ZCash',
    FullName: 'ZCash (ZEC)',
  },
  YEE: {
    Name: 'YEE',
    Symbol: 'YEE',
    CoinName: 'Yeeco',
    FullName: 'Yeeco (YEE)',
  },
  BUT: {
    Name: 'BUT',
    Symbol: 'BUT',
    CoinName: 'BitUP Token',
    FullName: 'BitUP Token (BUT)',
  },
  BLK: {
    Name: 'BLK',
    Symbol: 'BLK',
    CoinName: 'BlackCoin',
    FullName: 'BlackCoin (BLK)',
  },
  WAVES: {
    Name: 'WAVES',
    Symbol: 'WAVES',
    CoinName: 'Waves',
    FullName: 'Waves (WAVES)',
  },
  VIA: {
    Name: 'VIA',
    Symbol: 'VIA',
    CoinName: 'ViaCoin',
    FullName: 'ViaCoin (VIA)',
  },
  VET: {
    Name: 'VET',
    Symbol: 'VET',
    CoinName: 'VeChain',
    FullName: 'VeChain (VET)',
  },
  FLO: {
    Name: 'FLO',
    Symbol: 'FLO',
    CoinName: 'Flo',
    FullName: 'Flo (FLO)',
  },
  UNIVRS: {
    Name: 'UNIVRS',
    Symbol: 'UNIVRS',
    CoinName: 'Universe',
    FullName: 'Universe (UNIVRS)',
  },
  UBQ: {
    Name: 'UBQ',
    Symbol: 'UBQ',
    CoinName: 'Ubiq',
    FullName: 'Ubiq (UBQ)',
  },
  UBEX: {
    Name: 'UBEX',
    Symbol: 'UBEX',
    CoinName: 'Ubex',
    FullName: 'Ubex (UBEX)',
  },
  FUNDZ: {
    Name: 'FUNDZ',
    Symbol: 'FUNDZ',
    CoinName: 'FundFantasy',
    FullName: 'FundFantasy (FUNDZ)',
  },
  CNN: {
    Name: 'CNN',
    Symbol: 'CNN',
    CoinName: 'Content Neutrality Network',
    FullName: 'Content Neutrality Network (CNN)',
  },
  PING: {
    Name: 'PING',
    Symbol: 'PING',
    CoinName: 'CryptoPing',
    FullName: 'CryptoPing (PING)',
  },
  DCR: {
    Name: 'DCR',
    Symbol: 'DCR',
    CoinName: 'Decred',
    FullName: 'Decred (DCR)',
  },
  DGB: {
    Name: 'DGB',
    Symbol: 'DGB',
    CoinName: 'DigiByte',
    FullName: 'DigiByte (DGB)',
  },
  DIGIC: {
    Name: 'DIGIC',
    Symbol: 'DIGIC',
    CoinName: 'DigiCube',
    FullName: 'DigiCube (DIGIC)',
  },
  EMC2: {
    Name: 'EMC2',
    Symbol: 'EMC2',
    CoinName: 'Einsteinium',
    FullName: 'Einsteinium (EMC2)',
  },
  SC: {
    Name: 'SC',
    Symbol: 'SC',
    CoinName: 'Siacoin',
    FullName: 'Siacoin (SC)',
  },
  ENG: {
    Name: 'ENG',
    Symbol: 'ENG',
    CoinName: 'Enigma',
    FullName: 'Enigma (ENG)',
  },
  QCX: {
    Name: 'QCX',
    Symbol: 'QCX',
    CoinName: 'QuickX Protocol',
    FullName: 'QuickX Protocol (QCX)',
  },
  FCT: {
    Name: 'FCT',
    Symbol: 'FCT',
    CoinName: 'FirmaChain',
    FullName: 'FirmaChain (FCT)',
  },
  BLUESPARROWOLD: {
    Name: 'BLUESPARROWOLD',
    Symbol: 'BLUESPARROWOLD',
    CoinName: 'BlueSparrowToken',
    FullName: 'BlueSparrowToken (BLUESPARROWOLD)',
  },
  PRE: {
    Name: 'PRE',
    Symbol: 'PRE',
    CoinName: 'Presearch',
    FullName: 'Presearch (PRE)',
  },
  POCC: {
    Name: 'POCC',
    Symbol: 'POCC',
    CoinName: 'POC Chain',
    FullName: 'POC Chain (POCC)',
  },
  HLC: {
    Name: 'HLC',
    Symbol: 'HLC',
    CoinName: 'HalalChain',
    FullName: 'HalalChain (HLC)',
  },
  XHV: {
    Name: 'XHV',
    Symbol: 'XHV',
    CoinName: 'Haven Protocol',
    FullName: 'Haven Protocol (XHV)',
  },
  PLAY: {
    Name: 'PLAY',
    Symbol: 'PLAY',
    CoinName: 'HEROcoin',
    FullName: 'HEROcoin (PLAY)',
  },
  HC: {
    Name: 'HC',
    Symbol: 'HC',
    CoinName: 'HyperCash',
    FullName: 'HyperCash (HC)',
  },
  HYN: {
    Name: 'HYN',
    Symbol: 'HYN',
    CoinName: 'Hyperion',
    FullName: 'Hyperion (HYN)',
  },
  TFUEL: {
    Name: 'TFUEL',
    Symbol: 'TFUEL',
    CoinName: 'Theta Fuel',
    FullName: 'Theta Fuel (TFUEL)',
  },
  IMPACT: {
    Name: 'IMPACT',
    Symbol: 'IMPACT',
    CoinName: 'Impact',
    FullName: 'Impact (IMPACT)',
  },
  ICC: {
    Name: 'ICC',
    Symbol: 'ICC',
    CoinName: 'Insta Cash Coin',
    FullName: 'Insta Cash Coin (ICC)',
  },
  IDT: {
    Name: 'IDT',
    Symbol: 'IDT',
    CoinName: 'InvestDigital',
    FullName: 'InvestDigital (IDT)',
  },
  KSM: {
    Name: 'KSM',
    Symbol: 'KSM',
    CoinName: 'Kusama',
    FullName: 'Kusama (KSM)',
  },
  MHC: {
    Name: 'MHC',
    Symbol: 'MHC',
    CoinName: 'MetaHash',
    FullName: 'MetaHash (MHC)',
  },
  ETP: {
    Name: 'ETP',
    Symbol: 'ETP',
    CoinName: 'Metaverse',
    FullName: 'Metaverse (ETP)',
  },
  XMR: {
    Name: 'XMR',
    Symbol: 'XMR',
    CoinName: 'Monero',
    FullName: 'Monero (XMR)',
  },
  NEX: {
    Name: 'NEX',
    Symbol: 'NEX',
    CoinName: 'Nash Exchange',
    FullName: 'Nash Exchange (NEX)',
  },
  NCC: {
    Name: 'NCC',
    Symbol: 'NCC',
    CoinName: 'NeuroChain',
    FullName: 'NeuroChain (NCC)',
  },
  NEW: {
    Name: 'NEW',
    Symbol: 'NEW',
    CoinName: 'Newton',
    FullName: 'Newton (NEW)',
  },
  OMG: {
    Name: 'OMG',
    Symbol: 'OMG',
    CoinName: 'OMG Network',
    FullName: 'OMG Network (OMG)',
  },
  ECOM: {
    Name: 'ECOM',
    Symbol: 'ECOM',
    CoinName: 'Omnitude',
    FullName: 'Omnitude (ECOM)',
  },
  ONT: {
    Name: 'ONT',
    Symbol: 'ONT',
    CoinName: 'Ontology',
    FullName: 'Ontology (ONT)',
  },
  NAVC: {
    Name: 'NAVC',
    Symbol: 'NAVC',
    CoinName: 'NavC token',
    FullName: 'NavC token (NAVC)',
  },
  UNFI: {
    Name: 'UNFI',
    Symbol: 'UNFI',
    CoinName: 'Unifi Protocol DAO',
    FullName: 'Unifi Protocol DAO (UNFI)',
  },
  CTC: {
    Name: 'CTC',
    Symbol: 'CTC',
    CoinName: 'Creditcoin',
    FullName: 'Creditcoin (CTC)',
  },
  MIOTA: {
    Name: 'MIOTA',
    Symbol: 'MIOTA',
    CoinName: 'IOTA',
    FullName: 'IOTA (MIOTA)',
  },
  BTCST: {
    Name: 'BTCST',
    Symbol: 'BTCST',
    CoinName: 'BTC Standard Hashrate Token',
    FullName: 'BTC Standard Hashrate Token (BTCST)',
  },
  PAXG: {
    Name: 'PAXG',
    Symbol: 'PAXG',
    CoinName: 'PAX Gold',
    FullName: 'PAX Gold (PAXG)',
  },
  APM: {
    Name: 'APM',
    Symbol: 'APM',
    CoinName: 'apM Coin',
    FullName: 'apM Coin (APM)',
  },
  SPELL: {
    Name: 'SPELL',
    Symbol: 'SPELL',
    CoinName: 'Spell Token',
    FullName: 'Spell Token (SPELL)',
  },
  BRZ: {
    Name: 'BRZ',
    Symbol: 'BRZ',
    CoinName: 'Brazilian Digital Token',
    FullName: 'Brazilian Digital Token (BRZ)',
  },
  TAMA: {
    Name: 'TAMA',
    Symbol: 'TAMA',
    CoinName: 'Tamadoge',
    FullName: 'Tamadoge (TAMA)',
  },
  ASA: {
    Name: 'ASA',
    Symbol: 'ASA',
    CoinName: 'ASA Coin',
    FullName: 'ASA Coin (ASA)',
  },
  DAR: {
    Name: 'DAR',
    Symbol: 'DAR',
    CoinName: 'Mines of Dalarnia',
    FullName: 'Mines of Dalarnia (DAR)',
  },
  SLP: {
    Name: 'SLP',
    Symbol: 'SLP',
    CoinName: 'Smooth Love Potion',
    FullName: 'Smooth Love Potion (SLP)',
  },
  ALPHA: {
    Name: 'ALPHA',
    Symbol: 'ALPHA',
    CoinName: 'Alpha Finance Lab',
    FullName: 'Alpha Finance Lab (ALPHA)',
  },
  ACH: {
    Name: 'ACH',
    Symbol: 'ACH',
    CoinName: 'Alchemy Pay',
    FullName: 'Alchemy Pay (ACH)',
  },
  VIDT: {
    Name: 'VIDT',
    Symbol: 'VIDT',
    CoinName: 'VIDT Datalink',
    FullName: 'VIDT Datalink (VIDT)',
  },
  WOM: {
    Name: 'WOM',
    Symbol: 'WOM',
    CoinName: 'WOM',
    FullName: 'WOM (WOM)',
  },
  BOA: {
    Name: 'BOA',
    Symbol: 'BOA',
    CoinName: 'BOSAGORA',
    FullName: 'BOSAGORA (BOA)',
  },
  CHANGE: {
    Name: 'CHANGE',
    Symbol: 'CHANGE',
    CoinName: 'ChangeX',
    FullName: 'ChangeX (CHANGE)',
  },
  HYDRA: {
    Name: 'HYDRA',
    Symbol: 'HYDRA',
    CoinName: 'Hydra',
    FullName: 'Hydra (HYDRA)',
  },
  XVG: {
    Name: 'XVG',
    Symbol: 'XVG',
    CoinName: 'Verge',
    FullName: 'Verge (XVG)',
  },
  ZAP: {
    Name: 'ZAP',
    Symbol: 'ZAP',
    CoinName: 'Zap',
    FullName: 'Zap (ZAP)',
  },
  YEED: {
    Name: 'YEED',
    Symbol: 'YEED',
    CoinName: 'Yggdrash',
    FullName: 'Yggdrash (YEED)',
  },
  ACT: {
    Name: 'ACT',
    Symbol: 'ACT',
    CoinName: 'Achain',
    FullName: 'Achain (ACT)',
  },
  WAXP: {
    Name: 'WAXP',
    Symbol: 'WAXP',
    CoinName: 'Worldwide Asset eXchange',
    FullName: 'Worldwide Asset eXchange (WAXP)',
  },
  ALGO: {
    Name: 'ALGO',
    Symbol: 'ALGO',
    CoinName: 'Algorand',
    FullName: 'Algorand (ALGO)',
  },
  VIBE: {
    Name: 'VIBE',
    Symbol: 'VIBE',
    CoinName: 'VIBEHub',
    FullName: 'VIBEHub (VIBE)',
  },
  VTC: {
    Name: 'VTC',
    Symbol: 'VTC',
    CoinName: 'Vertcoin',
    FullName: 'Vertcoin (VTC)',
  },
  VRS: {
    Name: 'VRS',
    Symbol: 'VRS',
    CoinName: 'Veros',
    FullName: 'Veros (VRS)',
  },
  THR: {
    Name: 'THR',
    Symbol: 'THR',
    CoinName: 'Thorecoin',
    FullName: 'Thorecoin (THR)',
  },
  ABYSS: {
    Name: 'ABYSS',
    Symbol: 'ABYSS',
    CoinName: 'Abyss Finance',
    FullName: 'Abyss Finance (ABYSS)',
  },
  TERN: {
    Name: 'TERN',
    Symbol: 'TERN',
    CoinName: 'Ternio',
    FullName: 'Ternio (TERN)',
  },
  SKY: {
    Name: 'SKY',
    Symbol: 'SKY',
    CoinName: 'Skycoin',
    FullName: 'Skycoin (SKY)',
  },
  AUC: {
    Name: 'AUC',
    Symbol: 'AUC',
    CoinName: 'Auctus',
    FullName: 'Auctus (AUC)',
  },
  AVT: {
    Name: 'AVT',
    Symbol: 'AVT',
    CoinName: 'Aventus',
    FullName: 'Aventus (AVT)',
  },
  SEELE: {
    Name: 'SEELE',
    Symbol: 'SEELE',
    CoinName: 'Seele',
    FullName: 'Seele (SEELE)',
  },
  SAFEX: {
    Name: 'SAFEX',
    Symbol: 'SAFEX',
    CoinName: 'SafeExchangeCoin',
    FullName: 'SafeExchangeCoin (SAFEX)',
  },
  BGG: {
    Name: 'BGG',
    Symbol: 'BGG',
    CoinName: 'BGG Token',
    FullName: 'BGG Token (BGG)',
  },
  BTS: {
    Name: 'BTS',
    Symbol: 'BTS',
    CoinName: 'Bitshares',
    FullName: 'Bitshares (BTS)',
  },
  BKK: {
    Name: 'BKK',
    Symbol: 'BKK',
    CoinName: 'BKEX Token',
    FullName: 'BKEX Token (BKK)',
  },
  QASH: {
    Name: 'QASH',
    Symbol: 'QASH',
    CoinName: 'Quoine Liquid',
    FullName: 'Quoine Liquid (QASH)',
  },
  BCPT: {
    Name: 'BCPT',
    Symbol: 'BCPT',
    CoinName: 'BlockMason Credit Protocol',
    FullName: 'BlockMason Credit Protocol (BCPT)',
  },
  PLBT: {
    Name: 'PLBT',
    Symbol: 'PLBT',
    CoinName: 'Polybius',
    FullName: 'Polybius (PLBT)',
  },
  FACTOM: {
    Name: 'FACTOM',
    Symbol: 'FACTOM',
    CoinName: 'Factom',
    FullName: 'Factom (FACTOM)',
  },
  FAT: {
    Name: 'FAT',
    Symbol: 'FAT',
    CoinName: 'Fatcoin',
    FullName: 'Fatcoin (FAT)',
  },
  FTC: {
    Name: 'FTC',
    Symbol: 'FTC',
    CoinName: 'FeatherCoin',
    FullName: 'FeatherCoin (FTC)',
  },
  FIL: {
    Name: 'FIL',
    Symbol: 'FIL',
    CoinName: 'FileCoin',
    FullName: 'FileCoin (FIL)',
  },
  FIRO: {
    Name: 'FIRO',
    Symbol: 'FIRO',
    CoinName: 'Firo',
    FullName: 'Firo (FIRO)',
  },
  FLMC: {
    Name: 'FLMC',
    Symbol: 'FLMC',
    CoinName: 'FOLM coin',
    FullName: 'FOLM coin (FLMC)',
  },
  FORCEC: {
    Name: 'FORCEC',
    Symbol: 'FORCEC',
    CoinName: 'Force Coin',
    FullName: 'Force Coin (FORCEC)',
  },
  FKX: {
    Name: 'FKX',
    Symbol: 'FKX',
    CoinName: 'FortKnoxster',
    FullName: 'FortKnoxster (FKX)',
  },
  FXP: {
    Name: 'FXP',
    Symbol: 'FXP',
    CoinName: 'FXPay',
    FullName: 'FXPay (FXP)',
  },
  GEM: {
    Name: 'GEM',
    Symbol: 'GEM',
    CoinName: 'Gems',
    FullName: 'Gems (GEM)',
  },
  GRIN: {
    Name: 'GRIN',
    Symbol: 'GRIN',
    CoinName: 'Grin',
    FullName: 'Grin (GRIN)',
  },
  ZEN: {
    Name: 'ZEN',
    Symbol: 'ZEN',
    CoinName: 'Horizen',
    FullName: 'Horizen (ZEN)',
  },
  ICX: {
    Name: 'ICX',
    Symbol: 'ICX',
    CoinName: 'ICON Project',
    FullName: 'ICON Project (ICX)',
  },
  ICOS: {
    Name: 'ICOS',
    Symbol: 'ICOS',
    CoinName: 'ICOBox',
    FullName: 'ICOBox (ICOS)',
  },
  XNK: {
    Name: 'XNK',
    Symbol: 'XNK',
    CoinName: 'Ink Protocol',
    FullName: 'Ink Protocol (XNK)',
  },
  IOST: {
    Name: 'IOST',
    Symbol: 'IOST',
    CoinName: 'IOS token',
    FullName: 'IOS token (IOST)',
  },
  IQC: {
    Name: 'IQC',
    Symbol: 'IQC',
    CoinName: 'IQ.cash',
    FullName: 'IQ.cash (IQC)',
  },
  IRIS: {
    Name: 'IRIS',
    Symbol: 'IRIS',
    CoinName: 'IRIS Network',
    FullName: 'IRIS Network (IRIS)',
  },
  CODY: {
    Name: 'CODY',
    Symbol: 'CODY',
    CoinName: 'Coindy',
    FullName: 'Coindy (CODY)',
  },
  ATOM: {
    Name: 'ATOM',
    Symbol: 'ATOM',
    CoinName: 'Cosmos',
    FullName: 'Cosmos (ATOM)',
  },
  COV: {
    Name: 'COV',
    Symbol: 'COV',
    CoinName: 'Covesting',
    FullName: 'Covesting (COV)',
  },
  SMR: {
    Name: 'SMR',
    Symbol: 'SMR',
    CoinName: 'Shimmer',
    FullName: 'Shimmer (SMR)',
  },
  XUC: {
    Name: 'XUC',
    Symbol: 'XUC',
    CoinName: 'Exchange Union',
    FullName: 'Exchange Union (XUC)',
  },
  ESS: {
    Name: 'ESS',
    Symbol: 'ESS',
    CoinName: 'Essentia',
    FullName: 'Essentia (ESS)',
  },
  ELY: {
    Name: 'ELY',
    Symbol: 'ELY',
    CoinName: 'Elysian',
    FullName: 'Elysian (ELY)',
  },
  EKT: {
    Name: 'EKT',
    Symbol: 'EKT',
    CoinName: 'EDUCare',
    FullName: 'EDUCare (EKT)',
  },
  RATING: {
    Name: 'RATING',
    Symbol: 'RATING',
    CoinName: 'DPRating',
    FullName: 'DPRating (RATING)',
  },
  DMT: {
    Name: 'DMT',
    Symbol: 'DMT',
    CoinName: 'DMarket',
    FullName: 'DMarket (DMT)',
  },
  JNT: {
    Name: 'JNT',
    Symbol: 'JNT',
    CoinName: 'Jibrel Network Token',
    FullName: 'Jibrel Network Token (JNT)',
  },
  DERO: {
    Name: 'DERO',
    Symbol: 'DERO',
    CoinName: 'Dero',
    FullName: 'Dero (DERO)',
  },
  KIN: {
    Name: 'KIN',
    Symbol: 'KIN',
    CoinName: 'Kin',
    FullName: 'Kin (KIN)',
  },
  KNGN: {
    Name: 'KNGN',
    Symbol: 'KNGN',
    CoinName: 'KingN Coin',
    FullName: 'KingN Coin (KNGN)',
  },
  LBC: {
    Name: 'LBC',
    Symbol: 'LBC',
    CoinName: 'LBRY Credits',
    FullName: 'LBRY Credits (LBC)',
  },
  LET: {
    Name: 'LET',
    Symbol: 'LET',
    CoinName: 'LinkEye',
    FullName: 'LinkEye (LET)',
  },
  MEX: {
    Name: 'MEX',
    Symbol: 'MEX',
    CoinName: 'MEX',
    FullName: 'MEX (MEX)',
  },
  MIX: {
    Name: 'MIX',
    Symbol: 'MIX',
    CoinName: 'MIXMARVEL',
    FullName: 'MIXMARVEL (MIX)',
  },
  MOAC: {
    Name: 'MOAC',
    Symbol: 'MOAC',
    CoinName: 'MOAC',
    FullName: 'MOAC (MOAC)',
  },
  MDA: {
    Name: 'MDA',
    Symbol: 'MDA',
    CoinName: 'Moeda',
    FullName: 'Moeda (MDA)',
  },
  MONA: {
    Name: 'MONA',
    Symbol: 'MONA',
    CoinName: 'MonaCoin',
    FullName: 'MonaCoin (MONA)',
  },
  NANO: {
    Name: 'NANO',
    Symbol: 'NANO',
    CoinName: 'Nano',
    FullName: 'Nano (NANO)',
  },
  NEAR: {
    Name: 'NEAR',
    Symbol: 'NEAR',
    CoinName: 'Near',
    FullName: 'Near (NEAR)',
  },
  OPEN: {
    Name: 'OPEN',
    Symbol: 'OPEN',
    CoinName: 'Open Platform',
    FullName: 'Open Platform (OPEN)',
  },
  PART: {
    Name: 'PART',
    Symbol: 'PART',
    CoinName: 'Particl',
    FullName: 'Particl (PART)',
  },
  TVK: {
    Name: 'TVK',
    Symbol: 'TVK',
    CoinName: 'Terra Virtua Kolect',
    FullName: 'Terra Virtua Kolect (TVK)',
  },
  BLV3: {
    Name: 'BLV3',
    Symbol: 'BLV3',
    CoinName: 'Crypto Legions V3',
    FullName: 'Crypto Legions V3 (BLV3)',
  },
  WIKEN: {
    Name: 'WIKEN',
    Symbol: 'WIKEN',
    CoinName: 'Project WITH',
    FullName: 'Project WITH (WIKEN)',
  },
  SFP: {
    Name: 'SFP',
    Symbol: 'SFP',
    CoinName: 'SafePal',
    FullName: 'SafePal (SFP)',
  },
  LOKA: {
    Name: 'LOKA',
    Symbol: 'LOKA',
    CoinName: 'League of Kingdoms',
    FullName: 'League of Kingdoms (LOKA)',
  },
  BOUNCE: {
    Name: 'BOUNCE',
    Symbol: 'BOUNCE',
    CoinName: 'Bounce Token',
    FullName: 'Bounce Token (BOUNCE)',
  },
  PYR: {
    Name: 'PYR',
    Symbol: 'PYR',
    CoinName: 'Vulcan Forged',
    FullName: 'Vulcan Forged (PYR)',
  },
  AUCTION: {
    Name: 'AUCTION',
    Symbol: 'AUCTION',
    CoinName: 'Bounce',
    FullName: 'Bounce (AUCTION)',
  },
  TITAN: {
    Name: 'TITAN',
    Symbol: 'TITAN',
    CoinName: 'TitanSwap',
    FullName: 'TitanSwap (TITAN)',
  },
  VLX: {
    Name: 'VLX',
    Symbol: 'VLX',
    CoinName: 'Velas',
    FullName: 'Velas (VLX)',
  },
  EASYF: {
    Name: 'EASYF',
    Symbol: 'EASYF',
    CoinName: 'EasyFeedback',
    FullName: 'EasyFeedback (EASYF)',
  },
  DEP: {
    Name: 'DEP',
    Symbol: 'DEP',
    CoinName: 'DEAPCOIN',
    FullName: 'DEAPCOIN (DEP)',
  },
  AE: {
    Name: 'AE',
    Symbol: 'AE',
    CoinName: 'Aeternity',
    FullName: 'Aeternity (AE)',
  },
  ZEL: {
    Name: 'ZEL',
    Symbol: 'ZEL',
    CoinName: 'Zelcash',
    FullName: 'Zelcash (ZEL)',
  },
  ZB: {
    Name: 'ZB',
    Symbol: 'ZB',
    CoinName: 'ZB',
    FullName: 'ZB (ZB)',
  },
  AGVC: {
    Name: 'AGVC',
    Symbol: 'AGVC',
    CoinName: 'AgaveCoin',
    FullName: 'AgaveCoin (AGVC)',
  },
  DLT: {
    Name: 'DLT',
    Symbol: 'DLT',
    CoinName: 'Agrello Delta',
    FullName: 'Agrello Delta (DLT)',
  },
  AIDOC: {
    Name: 'AIDOC',
    Symbol: 'AIDOC',
    CoinName: 'AI Doctor',
    FullName: 'AI Doctor (AIDOC)',
  },
  ME: {
    Name: 'ME',
    Symbol: 'ME',
    CoinName: 'All.me',
    FullName: 'All.me (ME)',
  },
  APL: {
    Name: 'APL',
    Symbol: 'APL',
    CoinName: 'Apollo Currency',
    FullName: 'Apollo Currency (APL)',
  },
  WGRT: {
    Name: 'WGRT',
    Symbol: 'WGRT',
    CoinName: 'WaykiChain Governance Coin',
    FullName: 'WaykiChain Governance Coin (WGRT)',
  },
  IPL: {
    Name: 'IPL',
    Symbol: 'IPL',
    CoinName: 'VouchForMe',
    FullName: 'VouchForMe (IPL)',
  },
  VNT: {
    Name: 'VNT',
    Symbol: 'VNT',
    CoinName: 'VNT Chain',
    FullName: 'VNT Chain (VNT)',
  },
  VTHO: {
    Name: 'VTHO',
    Symbol: 'VTHO',
    CoinName: 'VeChainThor',
    FullName: 'VeChainThor (VTHO)',
  },
  UQC: {
    Name: 'UQC',
    Symbol: 'UQC',
    CoinName: 'Uquid Coin',
    FullName: 'Uquid Coin (UQC)',
  },
  UGAS: {
    Name: 'UGAS',
    Symbol: 'UGAS',
    CoinName: 'Ultrain',
    FullName: 'Ultrain (UGAS)',
  },
  TRIO: {
    Name: 'TRIO',
    Symbol: 'TRIO',
    CoinName: 'Tripio',
    FullName: 'Tripio (TRIO)',
  },
  TNT: {
    Name: 'TNT',
    Symbol: 'TNT',
    CoinName: 'Tierion',
    FullName: 'Tierion (TNT)',
  },
  TLOS: {
    Name: 'TLOS',
    Symbol: 'TLOS',
    CoinName: 'Telos',
    FullName: 'Telos (TLOS)',
  },
  SYS: {
    Name: 'SYS',
    Symbol: 'SYS',
    CoinName: 'SysCoin',
    FullName: 'SysCoin (SYS)',
  },
  SUR: {
    Name: 'SUR',
    Symbol: 'SUR',
    CoinName: 'Suretly',
    FullName: 'Suretly (SUR)',
  },
  XLM: {
    Name: 'XLM',
    Symbol: 'XLM',
    CoinName: 'Stellar',
    FullName: 'Stellar (XLM)',
  },
  SMART: {
    Name: 'SMART',
    Symbol: 'SMART',
    CoinName: 'SmartCash',
    FullName: 'SmartCash (SMART)',
  },
  SRN: {
    Name: 'SRN',
    Symbol: 'SRN',
    CoinName: 'SirinLabs',
    FullName: 'SirinLabs (SRN)',
  },
  ARDR: {
    Name: 'ARDR',
    Symbol: 'ARDR',
    CoinName: 'Ardor',
    FullName: 'Ardor (ARDR)',
  },
  SNGLS: {
    Name: 'SNGLS',
    Symbol: 'SNGLS',
    CoinName: 'SingularDTV',
    FullName: 'SingularDTV (SNGLS)',
  },
  ARK: {
    Name: 'ARK',
    Symbol: 'ARK',
    CoinName: 'ARK',
    FullName: 'ARK (ARK)',
  },
  SIB: {
    Name: 'SIB',
    Symbol: 'SIB',
    CoinName: 'SibCoin',
    FullName: 'SibCoin (SIB)',
  },
  SHIFT: {
    Name: 'SHIFT',
    Symbol: 'SHIFT',
    CoinName: 'Shift',
    FullName: 'Shift (SHIFT)',
  },
  ATMCHAIN: {
    Name: 'ATMCHAIN',
    Symbol: 'ATMCHAIN',
    CoinName: 'ATMChain',
    FullName: 'ATMChain (ATMCHAIN)',
  },
  SRM: {
    Name: 'SRM',
    Symbol: 'SRM',
    CoinName: 'Serum',
    FullName: 'Serum (SRM)',
  },
  BEZ: {
    Name: 'BEZ',
    Symbol: 'BEZ',
    CoinName: 'Bezop',
    FullName: 'Bezop (BEZ)',
  },
  QKC: {
    Name: 'QKC',
    Symbol: 'QKC',
    CoinName: 'QuarkChain',
    FullName: 'QuarkChain (QKC)',
  },
  QTUM: {
    Name: 'QTUM',
    Symbol: 'QTUM',
    CoinName: 'QTUM',
    FullName: 'QTUM (QTUM)',
  },
  PMA: {
    Name: 'PMA',
    Symbol: 'PMA',
    CoinName: 'PumaPay',
    FullName: 'PumaPay (PMA)',
  },
  PRA: {
    Name: 'PRA',
    Symbol: 'PRA',
    CoinName: 'ProChain',
    FullName: 'ProChain (PRA)',
  },
  BIX: {
    Name: 'BIX',
    Symbol: 'BIX',
    CoinName: 'BiboxCoin',
    FullName: 'BiboxCoin (BIX)',
  },
  BCH: {
    Name: 'BCH',
    Symbol: 'BCH',
    CoinName: 'Bitcoin Cash',
    FullName: 'Bitcoin Cash (BCH)',
  },
  CLO: {
    Name: 'CLO',
    Symbol: 'CLO',
    CoinName: 'Callisto Network',
    FullName: 'Callisto Network (CLO)',
  },
  CELO: {
    Name: 'CELO',
    Symbol: 'CELO',
    CoinName: 'Celo',
    FullName: 'Celo (CELO)',
  },
  CENNZ: {
    Name: 'CENNZ',
    Symbol: 'CENNZ',
    CoinName: 'Centrality Token',
    FullName: 'Centrality Token (CENNZ)',
  },
  COING: {
    Name: 'COING',
    Symbol: 'COING',
    CoinName: 'Coingrid',
    FullName: 'Coingrid (COING)',
  },
  HBOT: {
    Name: 'HBOT',
    Symbol: 'HBOT',
    CoinName: 'Hummingbot',
    FullName: 'Hummingbot (HBOT)',
  },
  SRLTY: {
    Name: 'SRLTY',
    Symbol: 'SRLTY',
    CoinName: 'SaitaRealty',
    FullName: 'SaitaRealty (SRLTY)',
  },
  CBT: {
    Name: 'CBT',
    Symbol: 'CBT',
    CoinName: 'CommerceBlock Token',
    FullName: 'CommerceBlock Token (CBT)',
  },
  COMP: {
    Name: 'COMP',
    Symbol: 'COMP',
    CoinName: 'Compound Governance Token',
    FullName: 'Compound Governance Token (COMP)',
  },
  DACC: {
    Name: 'DACC',
    Symbol: 'DACC',
    CoinName: 'Decentralized Accessible Content Chain',
    FullName: 'Decentralized Accessible Content Chain (DACC)',
  },
  DCN: {
    Name: 'DCN',
    Symbol: 'DCN',
    CoinName: 'Dentacoin',
    FullName: 'Dentacoin (DCN)',
  },
  XDN: {
    Name: 'XDN',
    Symbol: 'XDN',
    CoinName: 'DigitalNote',
    FullName: 'DigitalNote (XDN)',
  },
  DGD: {
    Name: 'DGD',
    Symbol: 'DGD',
    CoinName: 'Digix DAO',
    FullName: 'Digix DAO (DGD)',
  },
  DRGN: {
    Name: 'DRGN',
    Symbol: 'DRGN',
    CoinName: 'Dragonchain',
    FullName: 'Dragonchain (DRGN)',
  },
  EKO: {
    Name: 'EKO',
    Symbol: 'EKO',
    CoinName: 'EchoLink',
    FullName: 'EchoLink (EKO)',
  },
  EDU: {
    Name: 'EDU',
    Symbol: 'EDU',
    CoinName: 'EduCoin',
    FullName: 'EduCoin (EDU)',
  },
  ELA: {
    Name: 'ELA',
    Symbol: 'ELA',
    CoinName: 'Elastos',
    FullName: 'Elastos (ELA)',
  },
  ETN: {
    Name: 'ETN',
    Symbol: 'ETN',
    CoinName: 'Electroneum',
    FullName: 'Electroneum (ETN)',
  },
  ENCRYPG: {
    Name: 'ENCRYPG',
    Symbol: 'ENCRYPG',
    CoinName: 'EncrypGen',
    FullName: 'EncrypGen (ENCRYPG)',
  },
  ETC: {
    Name: 'ETC',
    Symbol: 'ETC',
    CoinName: 'Ethereum Classic',
    FullName: 'Ethereum Classic (ETC)',
  },
  WSTR: {
    Name: 'WSTR',
    Symbol: 'WSTR',
    CoinName: 'Wrapped Star',
    FullName: 'Wrapped Star (WSTR)',
  },
  DAWN: {
    Name: 'DAWN',
    Symbol: 'DAWN',
    CoinName: 'Dawn Protocol',
    FullName: 'Dawn Protocol (DAWN)',
  },
  PICKLE: {
    Name: 'PICKLE',
    Symbol: 'PICKLE',
    CoinName: 'Pickle Finance',
    FullName: 'Pickle Finance (PICKLE)',
  },
  FAIRG: {
    Name: 'FAIRG',
    Symbol: 'FAIRG',
    CoinName: 'FairGame',
    FullName: 'FairGame (FAIRG)',
  },
  BIT: {
    Name: 'BIT',
    Symbol: 'BIT',
    CoinName: 'BitDAO',
    FullName: 'BitDAO (BIT)',
  },
  EHIVE: {
    Name: 'EHIVE',
    Symbol: 'EHIVE',
    CoinName: 'eHive',
    FullName: 'eHive (EHIVE)',
  },
  ZLA: {
    Name: 'ZLA',
    Symbol: 'ZLA',
    CoinName: 'Zilla',
    FullName: 'Zilla (ZLA)',
  },
  SUGAR: {
    Name: 'SUGAR',
    Symbol: 'SUGAR',
    CoinName: 'Sugar Exchange',
    FullName: 'Sugar Exchange (SUGAR)',
  },
  WAB: {
    Name: 'WAB',
    Symbol: 'WAB',
    CoinName: 'WABnetwork',
    FullName: 'WABnetwork (WAB)',
  },
  EVX: {
    Name: 'EVX',
    Symbol: 'EVX',
    CoinName: 'Everex',
    FullName: 'Everex (EVX)',
  },
  LC4: {
    Name: 'LC4',
    Symbol: 'LC4',
    CoinName: 'LEOcoin',
    FullName: 'LEOcoin (LC4)',
  },
  LEMO: {
    Name: 'LEMO',
    Symbol: 'LEMO',
    CoinName: 'LemoChain',
    FullName: 'LemoChain (LEMO)',
  },
  ZIL: {
    Name: 'ZIL',
    Symbol: 'ZIL',
    CoinName: 'Zilliqa',
    FullName: 'Zilliqa (ZIL)',
  },
  COFI: {
    Name: 'COFI',
    Symbol: 'COFI',
    CoinName: 'CoinFi',
    FullName: 'CoinFi (COFI)',
  },
  DASH: {
    Name: 'DASH',
    Symbol: 'DASH',
    CoinName: 'Dash',
    FullName: 'Dash (DASH)',
  },
  BRIC: {
    Name: 'BRIC',
    Symbol: 'BRIC',
    CoinName: 'BrightCoin',
    FullName: 'BrightCoin (BRIC)',
  },
  CND: {
    Name: 'CND',
    Symbol: 'CND',
    CoinName: 'Cindicator',
    FullName: 'Cindicator (CND)',
  },
  XWC: {
    Name: 'XWC',
    Symbol: 'XWC',
    CoinName: 'WhiteCoin',
    FullName: 'WhiteCoin (XWC)',
  },
  DDD: {
    Name: 'DDD',
    Symbol: 'DDD',
    CoinName: 'Scry.info',
    FullName: 'Scry.info (DDD)',
  },
  C20: {
    Name: 'C20',
    Symbol: 'C20',
    CoinName: 'Crypto20',
    FullName: 'Crypto20 (C20)',
  },
  KMD: {
    Name: 'KMD',
    Symbol: 'KMD',
    CoinName: 'Komodo',
    FullName: 'Komodo (KMD)',
  },
  NLG: {
    Name: 'NLG',
    Symbol: 'NLG',
    CoinName: 'Gulden',
    FullName: 'Gulden (NLG)',
  },
  GRS: {
    Name: 'GRS',
    Symbol: 'GRS',
    CoinName: 'Groestlcoin',
    FullName: 'Groestlcoin (GRS)',
  },
  SWRV: {
    Name: 'SWRV',
    Symbol: 'SWRV',
    CoinName: 'Swerve',
    FullName: 'Swerve (SWRV)',
  },
  SALT: {
    Name: 'SALT',
    Symbol: 'SALT',
    CoinName: 'Salt Lending',
    FullName: 'Salt Lending (SALT)',
  },
  FSN: {
    Name: 'FSN',
    Symbol: 'FSN',
    CoinName: 'Fusion',
    FullName: 'Fusion (FSN)',
  },
  XCP: {
    Name: 'XCP',
    Symbol: 'XCP',
    CoinName: 'CounterParty',
    FullName: 'CounterParty (XCP)',
  },
  XEM: {
    Name: 'XEM',
    Symbol: 'XEM',
    CoinName: 'NEM',
    FullName: 'NEM (XEM)',
  },
  TRIBL: {
    Name: 'TRIBL',
    Symbol: 'TRIBL',
    CoinName: 'Tribal Token',
    FullName: 'Tribal Token (TRIBL)',
  },
  AZA: {
    Name: 'AZA',
    Symbol: 'AZA',
    CoinName: 'Kaliza',
    FullName: 'Kaliza (AZA)',
  },
  ELONGT: {
    Name: 'ELONGT',
    Symbol: 'ELONGT',
    CoinName: 'Elon GOAT',
    FullName: 'Elon GOAT (ELONGT)',
  },
  X7R: {
    Name: 'X7R',
    Symbol: 'X7R',
    CoinName: 'X7R',
    FullName: 'X7R (X7R)',
  },
  X7DAO: {
    Name: 'X7DAO',
    Symbol: 'X7DAO',
    CoinName: 'X7DAO',
    FullName: 'X7DAO (X7DAO)',
  },
  MPLX: {
    Name: 'MPLX',
    Symbol: 'MPLX',
    CoinName: 'Metaplex',
    FullName: 'Metaplex (MPLX)',
  },
  '00': {
    Name: '00',
    Symbol: '00',
    CoinName: 'ZER0ZER0',
    FullName: 'ZER0ZER0 (00)',
  },
  KISHU: {
    Name: 'KISHU',
    Symbol: 'KISHU',
    CoinName: 'Kishu Inu',
    FullName: 'Kishu Inu (KISHU)',
  },
  MDT: {
    Name: 'MDT',
    Symbol: 'MDT',
    CoinName: 'Measurable Data Token',
    FullName: 'Measurable Data Token (MDT)',
  },
  QI: {
    Name: 'QI',
    Symbol: 'QI',
    CoinName: 'BENQI',
    FullName: 'BENQI (QI)',
  },
  ONIT: {
    Name: 'ONIT',
    Symbol: 'ONIT',
    CoinName: 'ONBUFF',
    FullName: 'ONBUFF (ONIT)',
  },
  AXLINU: {
    Name: 'AXLINU',
    Symbol: 'AXLINU',
    CoinName: 'AXL INU',
    FullName: 'AXL INU (AXLINU)',
  },
  AXL: {
    Name: 'AXL',
    Symbol: 'AXL',
    CoinName: 'Axelar',
    FullName: 'Axelar (AXL)',
  },
  APRICOT: {
    Name: 'APRICOT',
    Symbol: 'APRICOT',
    CoinName: 'Apricot Finance',
    FullName: 'Apricot Finance (APRICOT)',
  },
  ICP: {
    Name: 'ICP',
    Symbol: 'ICP',
    CoinName: 'Internet Computer',
    FullName: 'Internet Computer (ICP)',
  },
  RSS3: {
    Name: 'RSS3',
    Symbol: 'RSS3',
    CoinName: 'RSS3',
    FullName: 'RSS3 (RSS3)',
  },
  FJB: {
    Name: 'FJB',
    Symbol: 'FJB',
    CoinName: 'Freedom. Jobs. Business.',
    FullName: 'Freedom. Jobs. Business. (FJB)',
  },
  INERY: {
    Name: 'INERY',
    Symbol: 'INERY',
    CoinName: 'Inery',
    FullName: 'Inery (INERY)',
  },
  FER: {
    Name: 'FER',
    Symbol: 'FER',
    CoinName: 'Ferro',
    FullName: 'Ferro (FER)',
  },
  RAIDER: {
    Name: 'RAIDER',
    Symbol: 'RAIDER',
    CoinName: 'Crypto Raiders',
    FullName: 'Crypto Raiders (RAIDER)',
  },
  ETH: {
    Name: 'ETH',
    Symbol: 'ETH',
    CoinName: 'Ethereum',
    FullName: 'Ethereum (ETH)',
  },
  LEO: {
    Name: 'LEO',
    Symbol: 'LEO',
    CoinName: 'LEO Token',
    FullName: 'LEO Token (LEO)',
  },
  NEO: {
    Name: 'NEO',
    Symbol: 'NEO',
    CoinName: 'NEO',
    FullName: 'NEO (NEO)',
  },
  SCRT: {
    Name: 'SCRT',
    Symbol: 'SCRT',
    CoinName: 'Secret',
    FullName: 'Secret (SCRT)',
  },
  LSK: {
    Name: 'LSK',
    Symbol: 'LSK',
    CoinName: 'Lisk',
    FullName: 'Lisk (LSK)',
  },
  FUN: {
    Name: 'FUN',
    Symbol: 'FUN',
    CoinName: 'FUN Token',
    FullName: 'FUN Token (FUN)',
  },
  CHESSCOIN: {
    Name: 'CHESSCOIN',
    Symbol: 'CHESSCOIN',
    CoinName: 'ChessCoin',
    FullName: 'ChessCoin (CHESSCOIN)',
  },
  LEND: {
    Name: 'LEND',
    Symbol: 'LEND',
    CoinName: 'Aave',
    FullName: 'Aave (LEND)',
  },
  PIVX: {
    Name: 'PIVX',
    Symbol: 'PIVX',
    CoinName: 'Private Instant Verified Transaction',
    FullName: 'Private Instant Verified Transaction (PIVX)',
  },
  REPO: {
    Name: 'REPO',
    Symbol: 'REPO',
    CoinName: 'Repo Coin',
    FullName: 'Repo Coin (REPO)',
  },
  AION: {
    Name: 'AION',
    Symbol: 'AION',
    CoinName: 'Aion',
    FullName: 'Aion (AION)',
  },
  SERO: {
    Name: 'SERO',
    Symbol: 'SERO',
    CoinName: 'Super Zero',
    FullName: 'Super Zero (SERO)',
  },
  WABI: {
    Name: 'WABI',
    Symbol: 'WABI',
    CoinName: 'WABI',
    FullName: 'WABI (WABI)',
  },
  NRG: {
    Name: 'NRG',
    Symbol: 'NRG',
    CoinName: 'Energi',
    FullName: 'Energi (NRG)',
  },
  GO: {
    Name: 'GO',
    Symbol: 'GO',
    CoinName: 'GoChain',
    FullName: 'GoChain (GO)',
  },
  SNC: {
    Name: 'SNC',
    Symbol: 'SNC',
    CoinName: 'SunContract',
    FullName: 'SunContract (SNC)',
  },
  PAY: {
    Name: 'PAY',
    Symbol: 'PAY',
    CoinName: 'TenX',
    FullName: 'TenX (PAY)',
  },
  BAX: {
    Name: 'BAX',
    Symbol: 'BAX',
    CoinName: 'BABB',
    FullName: 'BABB (BAX)',
  },
  LDC: {
    Name: 'LDC',
    Symbol: 'LDC',
    CoinName: 'LeadCoin',
    FullName: 'LeadCoin (LDC)',
  },
  MWAT: {
    Name: 'MWAT',
    Symbol: 'MWAT',
    CoinName: 'RED MegaWatt',
    FullName: 'RED MegaWatt (MWAT)',
  },
  HPB: {
    Name: 'HPB',
    Symbol: 'HPB',
    CoinName: 'High Performance Blockchain',
    FullName: 'High Performance Blockchain (HPB)',
  },
  GVT: {
    Name: 'GVT',
    Symbol: 'GVT',
    CoinName: 'Genesis Vision',
    FullName: 'Genesis Vision (GVT)',
  },
  BRD: {
    Name: 'BRD',
    Symbol: 'BRD',
    CoinName: 'Bread token',
    FullName: 'Bread token (BRD)',
  },
  SLS: {
    Name: 'SLS',
    Symbol: 'SLS',
    CoinName: 'SaluS',
    FullName: 'SaluS (SLS)',
  },
  IIC: {
    Name: 'IIC',
    Symbol: 'IIC',
    CoinName: 'Intelligent Investment Chain',
    FullName: 'Intelligent Investment Chain (IIC)',
  },
  RMT: {
    Name: 'RMT',
    Symbol: 'RMT',
    CoinName: 'SureRemit',
    FullName: 'SureRemit (RMT)',
  },
  SENC: {
    Name: 'SENC',
    Symbol: 'SENC',
    CoinName: 'Sentinel Chain',
    FullName: 'Sentinel Chain (SENC)',
  },
  LUN: {
    Name: 'LUN',
    Symbol: 'LUN',
    CoinName: 'Lunyr',
    FullName: 'Lunyr (LUN)',
  },
  BCDN: {
    Name: 'BCDN',
    Symbol: 'BCDN',
    CoinName: 'BlockCDN',
    FullName: 'BlockCDN (BCDN)',
  },
  INTR: {
    Name: 'INTR',
    Symbol: 'INTR',
    CoinName: 'Interlay',
    FullName: 'Interlay (INTR)',
  },
  LEOX: {
    Name: 'LEOX',
    Symbol: 'LEOX',
    CoinName: 'Galileo',
    FullName: 'Galileo (LEOX)',
  },
  GAL: {
    Name: 'GAL',
    Symbol: 'GAL',
    CoinName: 'Galxe',
    FullName: 'Galxe (GAL)',
  },
  NODL: {
    Name: 'NODL',
    Symbol: 'NODL',
    CoinName: 'Nodle Network',
    FullName: 'Nodle Network (NODL)',
  },
  SIFU: {
    Name: 'SIFU',
    Symbol: 'SIFU',
    CoinName: 'SIFU',
    FullName: 'SIFU (SIFU)',
  },
  PARALL: {
    Name: 'PARALL',
    Symbol: 'PARALL',
    CoinName: 'Parallel Finance',
    FullName: 'Parallel Finance (PARALL)',
  },
  TSUKA: {
    Name: 'TSUKA',
    Symbol: 'TSUKA',
    CoinName: 'Dejitaru Tsuka',
    FullName: 'Dejitaru Tsuka (TSUKA)',
  },
  DVF: {
    Name: 'DVF',
    Symbol: 'DVF',
    CoinName: 'Rhino.fi',
    FullName: 'Rhino.fi (DVF)',
  },
  OM: {
    Name: 'OM',
    Symbol: 'OM',
    CoinName: 'MANTRA',
    FullName: 'MANTRA (OM)',
  },
  MAYACOIN: {
    Name: 'MAYACOIN',
    Symbol: 'MAYACOIN',
    CoinName: 'MayaCoin',
    FullName: 'MayaCoin (MAYACOIN)',
  },
  MYC: {
    Name: 'MYC',
    Symbol: 'MYC',
    CoinName: 'Mycelium',
    FullName: 'Mycelium (MYC)',
  },
  UCOIN: {
    Name: 'UCOIN (1)',
    Symbol: 'UCOIN',
    CoinName: 'Ucoin',
    FullName: 'Ucoin (UCOIN (1))',
  },
  TEER: {
    Name: 'TEER',
    Symbol: 'TEER',
    CoinName: 'Integritee',
    FullName: 'Integritee (TEER)',
  },
  IP3: {
    Name: 'IP3',
    Symbol: 'IP3',
    CoinName: 'Cripco',
    FullName: 'Cripco (IP3)',
  },
  WAXL: {
    Name: 'WAXL',
    Symbol: 'WAXL',
    CoinName: 'Wrapped Axelar',
    FullName: 'Wrapped Axelar (WAXL)',
  },
  XPLA: {
    Name: 'XPLA',
    Symbol: 'XPLA',
    CoinName: 'XPLA',
    FullName: 'XPLA (XPLA)',
  },
  BULLSH: {
    Name: 'BULLSH',
    Symbol: 'BULLSH',
    CoinName: 'Bullshit Inu',
    FullName: 'Bullshit Inu (BULLSH)',
  },
  EVU: {
    Name: 'EVU',
    Symbol: 'EVU',
    CoinName: 'Evulus Token',
    FullName: 'Evulus Token (EVU)',
  },
  AURO: {
    Name: 'AURO',
    Symbol: 'AURO',
    CoinName: 'Aurora',
    FullName: 'Aurora (AURO)',
  },
  ETHERNITY: {
    Name: 'ETHERNITY',
    Symbol: 'ETHERNITY',
    CoinName: 'Ethernity Chain',
    FullName: 'Ethernity Chain (ETHERNITY)',
  },
  BTCHD: {
    Name: 'BTCHD',
    Symbol: 'BTCHD',
    CoinName: 'Bitcoin HD',
    FullName: 'Bitcoin HD (BTCHD)',
  },
  CRV: {
    Name: 'CRV',
    Symbol: 'CRV',
    CoinName: 'Curve DAO Token',
    FullName: 'Curve DAO Token (CRV)',
  },
  MINA: {
    Name: 'MINA',
    Symbol: 'MINA',
    CoinName: 'Mina Protocol',
    FullName: 'Mina Protocol (MINA)',
  },
  ORBS: {
    Name: 'ORBS',
    Symbol: 'ORBS',
    CoinName: 'Orbs',
    FullName: 'Orbs (ORBS)',
  },
  ZRX: {
    Name: 'ZRX',
    Symbol: 'ZRX',
    CoinName: '0x',
    FullName: '0x (ZRX)',
  },
  SNT: {
    Name: 'SNT',
    Symbol: 'SNT',
    CoinName: 'Status Network Token',
    FullName: 'Status Network Token (SNT)',
  },
  HXRO: {
    Name: 'HXRO',
    Symbol: 'HXRO',
    CoinName: 'Hxro',
    FullName: 'Hxro (HXRO)',
  },
  HEDG: {
    Name: 'HEDG',
    Symbol: 'HEDG',
    CoinName: 'HedgeTrade',
    FullName: 'HedgeTrade (HEDG)',
  },
  BAND: {
    Name: 'BAND',
    Symbol: 'BAND',
    CoinName: 'Band Protocol',
    FullName: 'Band Protocol (BAND)',
  },
  DIA: {
    Name: 'DIA',
    Symbol: 'DIA',
    CoinName: 'DIA',
    FullName: 'DIA (DIA)',
  },
  BEL: {
    Name: 'BEL',
    Symbol: 'BEL',
    CoinName: 'Bella Protocol',
    FullName: 'Bella Protocol (BEL)',
  },
  DNT: {
    Name: 'DNT',
    Symbol: 'DNT',
    CoinName: 'district0x',
    FullName: 'district0x (DNT)',
  },
  YCC: {
    Name: 'YCC',
    Symbol: 'YCC',
    CoinName: 'Yuan Chain Coin',
    FullName: 'Yuan Chain Coin (YCC)',
  },
  SOUL: {
    Name: 'SOUL',
    Symbol: 'SOUL',
    CoinName: 'Phantasma',
    FullName: 'Phantasma (SOUL)',
  },
  PNT: {
    Name: 'PNT',
    Symbol: 'PNT',
    CoinName: 'pNetwork Token',
    FullName: 'pNetwork Token (PNT)',
  },
  HPT: {
    Name: 'HPT',
    Symbol: 'HPT',
    CoinName: 'Huobi Pool Token',
    FullName: 'Huobi Pool Token (HPT)',
  },
  ANONCOIN: {
    Name: 'ANONCOIN',
    Symbol: 'ANONCOIN',
    CoinName: 'Anoncoin',
    FullName: 'Anoncoin (ANONCOIN)',
  },
  IHT: {
    Name: 'IHT',
    Symbol: 'IHT',
    CoinName: 'I-House Token',
    FullName: 'I-House Token (IHT)',
  },
  CAPP: {
    Name: 'CAPP',
    Symbol: 'CAPP',
    CoinName: 'Cappasity',
    FullName: 'Cappasity (CAPP)',
  },
  AAC: {
    Name: 'AAC',
    Symbol: 'AAC',
    CoinName: 'Double-A Chain',
    FullName: 'Double-A Chain (AAC)',
  },
  GMB: {
    Name: 'GMB',
    Symbol: 'GMB',
    CoinName: 'GMB',
    FullName: 'GMB (GMB)',
  },
  FYP: {
    Name: 'FYP',
    Symbol: 'FYP',
    CoinName: 'FlypMe',
    FullName: 'FlypMe (FYP)',
  },
  KCASH: {
    Name: 'KCASH',
    Symbol: 'KCASH',
    CoinName: 'Kcash',
    FullName: 'Kcash (KCASH)',
  },
  CLOAK: {
    Name: 'CLOAK',
    Symbol: 'CLOAK',
    CoinName: 'CloakCoin',
    FullName: 'CloakCoin (CLOAK)',
  },
  BITM: {
    Name: 'BITM',
    Symbol: 'BITM',
    CoinName: 'BitMoney',
    FullName: 'BitMoney (BITM)',
  },
  EGT: {
    Name: 'EGT',
    Symbol: 'EGT',
    CoinName: 'Egretia',
    FullName: 'Egretia (EGT)',
  },
  YAM: {
    Name: 'YAM',
    Symbol: 'YAM',
    CoinName: 'YAM',
    FullName: 'YAM (YAM)',
  },
  RCN: {
    Name: 'RCN',
    Symbol: 'RCN',
    CoinName: 'Ripio',
    FullName: 'Ripio (RCN)',
  },
  NXT: {
    Name: 'NXT',
    Symbol: 'NXT',
    CoinName: 'Nxt',
    FullName: 'Nxt (NXT)',
  },
  OXYC: {
    Name: 'OXYC',
    Symbol: 'OXYC',
    CoinName: 'Oxycoin',
    FullName: 'Oxycoin (OXYC)',
  },
  LAMB: {
    Name: 'LAMB',
    Symbol: 'LAMB',
    CoinName: 'Lambda',
    FullName: 'Lambda (LAMB)',
  },
  ULT: {
    Name: 'ULT',
    Symbol: 'ULT',
    CoinName: 'Ultiledger',
    FullName: 'Ultiledger (ULT)',
  },
  YDF: {
    Name: 'YDF',
    Symbol: 'YDF',
    CoinName: 'Yieldification',
    FullName: 'Yieldification (YDF)',
  },
  ICSA: {
    Name: 'ICSA',
    Symbol: 'ICSA',
    CoinName: 'Icosa',
    FullName: 'Icosa (ICSA)',
  },
  CRAMER: {
    Name: 'CRAMER',
    Symbol: 'CRAMER',
    CoinName: 'Cramer Coin',
    FullName: 'Cramer Coin (CRAMER)',
  },
  BIVE: {
    Name: 'BIVE',
    Symbol: 'BIVE',
    CoinName: 'BIZVERSE',
    FullName: 'BIZVERSE (BIVE)',
  },
  CAND: {
    Name: 'CAND',
    Symbol: 'CAND',
    CoinName: 'Canary Dollar',
    FullName: 'Canary Dollar (CAND)',
  },
  LDO: {
    Name: 'LDO',
    Symbol: 'LDO',
    CoinName: 'Lido DAO',
    FullName: 'Lido DAO (LDO)',
  },
  FRP: {
    Name: 'FRP',
    Symbol: 'FRP',
    CoinName: 'Fame Reward Plus',
    FullName: 'Fame Reward Plus (FRP)',
  },
  GHT: {
    Name: 'GHT',
    Symbol: 'GHT',
    CoinName: 'Global Human Trust',
    FullName: 'Global Human Trust (GHT)',
  },
  MASK: {
    Name: 'MASK',
    Symbol: 'MASK',
    CoinName: 'Mask Network',
    FullName: 'Mask Network (MASK)',
  },
  ISHND: {
    Name: 'ISHND',
    Symbol: 'ISHND',
    CoinName: 'StrongHands Finance',
    FullName: 'StrongHands Finance (ISHND)',
  },
  LOOP: {
    Name: 'LOOP1',
    Symbol: 'LOOP',
    CoinName: 'ARCADE',
    FullName: 'ARCADE (LOOP1)',
  },
  INXTOKEN: {
    Name: 'INXTOKEN',
    Symbol: 'INXTOKEN',
    CoinName: 'INX Token',
    FullName: 'INX Token (INXTOKEN)',
  },
  NUMI: {
    Name: 'NUMI',
    Symbol: 'NUMI',
    CoinName: 'Numitor',
    FullName: 'Numitor (NUMI)',
  },
  PROTO: {
    Name: 'PROTO',
    Symbol: 'PROTO',
    CoinName: 'Protocon',
    FullName: 'Protocon (PROTO)',
  },
  WTN: {
    Name: 'WTN',
    Symbol: 'WTN',
    CoinName: 'Wateenswap',
    FullName: 'Wateenswap (WTN)',
  },
  ZZC: {
    Name: 'ZZC',
    Symbol: 'ZZC',
    CoinName: 'ZudgeZury',
    FullName: 'ZudgeZury (ZZC)',
  },
  GXA: {
    Name: 'GXA',
    Symbol: 'GXA',
    CoinName: 'Galaxia',
    FullName: 'Galaxia (GXA)',
  },
  GAIA: {
    Name: 'GAIA',
    Symbol: 'GAIA',
    CoinName: 'Gaia Everworld',
    FullName: 'Gaia Everworld (GAIA)',
  },
  XCF: {
    Name: 'XCF',
    Symbol: 'XCF',
    CoinName: 'Cenfura Token',
    FullName: 'Cenfura Token (XCF)',
  },
  RENBTC: {
    Name: 'RENBTC',
    Symbol: 'RENBTC',
    CoinName: 'renBTC',
    FullName: 'renBTC (RENBTC)',
  },
  TRV: {
    Name: 'TRV',
    Symbol: 'TRV',
    CoinName: 'TrustVerse',
    FullName: 'TrustVerse (TRV)',
  },
  SML: {
    Name: 'SML',
    Symbol: 'SML',
    CoinName: 'Saltmarble',
    FullName: 'Saltmarble (SML)',
  },
  ITC: {
    Name: 'ITC',
    Symbol: 'ITC',
    CoinName: 'IoT Chain',
    FullName: 'IoT Chain (ITC)',
  },
  KICK: {
    Name: 'KICK',
    Symbol: 'KICK',
    CoinName: 'Kick',
    FullName: 'Kick (KICK)',
  },
  GUP: {
    Name: 'GUP',
    Symbol: 'GUP',
    CoinName: 'Guppy',
    FullName: 'Guppy (GUP)',
  },
  ACTIN: {
    Name: 'ACTIN',
    Symbol: 'ACTIN',
    CoinName: 'Actinium',
    FullName: 'Actinium (ACTIN)',
  },
  IOC: {
    Name: 'IOC',
    Symbol: 'IOC',
    CoinName: 'IOCoin',
    FullName: 'IOCoin (IOC)',
  },
  HQX: {
    Name: 'HQX',
    Symbol: 'HQX',
    CoinName: 'HOQU',
    FullName: 'HOQU (HQX)',
  },
  TSL: {
    Name: 'TSL',
    Symbol: 'TSL',
    CoinName: 'Energo',
    FullName: 'Energo (TSL)',
  },
  NODE: {
    Name: 'NODE',
    Symbol: 'NODE',
    CoinName: 'Whole Network',
    FullName: 'Whole Network (NODE)',
  },
  REM: {
    Name: 'REM',
    Symbol: 'REM',
    CoinName: 'REMME',
    FullName: 'REMME (REM)',
  },
  FTI: {
    Name: 'FTI',
    Symbol: 'FTI',
    CoinName: 'FansTime',
    FullName: 'FansTime (FTI)',
  },
  UUU: {
    Name: 'UUU',
    Symbol: 'UUU',
    CoinName: 'U Network',
    FullName: 'U Network (UUU)',
  },
  MTX: {
    Name: 'MTX',
    Symbol: 'MTX',
    CoinName: 'Matryx',
    FullName: 'Matryx (MTX)',
  },
  HSC: {
    Name: 'HSC',
    Symbol: 'HSC',
    CoinName: 'HashCoin',
    FullName: 'HashCoin (HSC)',
  },
  BNTY: {
    Name: 'BNTY',
    Symbol: 'BNTY',
    CoinName: 'Bounty0x',
    FullName: 'Bounty0x (BNTY)',
  },
  RNT: {
    Name: 'RNT',
    Symbol: 'RNT',
    CoinName: 'OneRoot Network',
    FullName: 'OneRoot Network (RNT)',
  },
  MORE: {
    Name: 'MORE',
    Symbol: 'MORE',
    CoinName: 'More Coin',
    FullName: 'More Coin (MORE)',
  },
  RUFF: {
    Name: 'RUFF',
    Symbol: 'RUFF',
    CoinName: 'Ruff',
    FullName: 'Ruff (RUFF)',
  },
  ADB: {
    Name: 'ADB',
    Symbol: 'ADB',
    CoinName: 'Adbank',
    FullName: 'Adbank (ADB)',
  },
  EOSDAC: {
    Name: 'EOSDAC',
    Symbol: 'EOSDAC',
    CoinName: 'eosDAC',
    FullName: 'eosDAC (EOSDAC)',
  },
  SKM: {
    Name: 'SKM',
    Symbol: 'SKM',
    CoinName: 'Skrumble Network',
    FullName: 'Skrumble Network (SKM)',
  },
  XMX: {
    Name: 'XMX',
    Symbol: 'XMX',
    CoinName: 'XMax',
    FullName: 'XMax (XMX)',
  },
  BOX: {
    Name: 'BOX',
    Symbol: 'BOX',
    CoinName: 'ContentBox',
    FullName: 'ContentBox (BOX)',
  },
  MDS: {
    Name: 'MDS',
    Symbol: 'MDS',
    CoinName: 'MediShares',
    FullName: 'MediShares (MDS)',
  },
  ZSC: {
    Name: 'ZSC',
    Symbol: 'ZSC',
    CoinName: 'Zeusshield',
    FullName: 'Zeusshield (ZSC)',
  },
  DPY: {
    Name: 'DPY',
    Symbol: 'DPY',
    CoinName: 'Delphy',
    FullName: 'Delphy (DPY)',
  },
  PVT: {
    Name: 'PVT',
    Symbol: 'PVT',
    CoinName: 'Pivot Token',
    FullName: 'Pivot Token (PVT)',
  },
  ELEC: {
    Name: 'ELEC',
    Symbol: 'ELEC',
    CoinName: 'Electrify.Asia',
    FullName: 'Electrify.Asia (ELEC)',
  },
  LINK: {
    Name: 'LINK',
    Symbol: 'LINK',
    CoinName: 'Chainlink',
    FullName: 'Chainlink (LINK)',
  },
  TOPC: {
    Name: 'TOPC',
    Symbol: 'TOPC',
    CoinName: 'Topchain',
    FullName: 'Topchain (TOPC)',
  },
  JAR: {
    Name: 'JAR',
    Symbol: 'JAR',
    CoinName: 'Jarvis+',
    FullName: 'Jarvis+ (JAR)',
  },
  VIDY: {
    Name: 'VIDY',
    Symbol: 'VIDY',
    CoinName: 'Vidy',
    FullName: 'Vidy (VIDY)',
  },
  HIT: {
    Name: 'HIT',
    Symbol: 'HIT',
    CoinName: 'HitChain',
    FullName: 'HitChain (HIT)',
  },
  UNI: {
    Name: 'UNI',
    Symbol: 'UNI',
    CoinName: 'Uniswap Protocol Token',
    FullName: 'Uniswap Protocol Token (UNI)',
  },
  SOC: {
    Name: 'SOC',
    Symbol: 'SOC',
    CoinName: 'All Sports Coin',
    FullName: 'All Sports Coin (SOC)',
  },
  TMTG: {
    Name: 'TMTG',
    Symbol: 'TMTG',
    CoinName: 'The Midas Touch Gold',
    FullName: 'The Midas Touch Gold (TMTG)',
  },
  GSC: {
    Name: 'GSC',
    Symbol: 'GSC',
    CoinName: 'Global Social Chain',
    FullName: 'Global Social Chain (GSC)',
  },
  PST: {
    Name: 'PST',
    Symbol: 'PST',
    CoinName: 'Primas',
    FullName: 'Primas (PST)',
  },
  LBA: {
    Name: 'LBA',
    Symbol: 'LBA',
    CoinName: 'Cred',
    FullName: 'Cred (LBA)',
  },
  CRO: {
    Name: 'CRO',
    Symbol: 'CRO',
    CoinName: 'Cronos',
    FullName: 'Cronos (CRO)',
  },
  SAND: {
    Name: 'SAND',
    Symbol: 'SAND',
    CoinName: 'The Sandbox',
    FullName: 'The Sandbox (SAND)',
  },
  LRN: {
    Name: 'LRN',
    Symbol: 'LRN',
    CoinName: 'Loopring [NEO]',
    FullName: 'Loopring [NEO] (LRN)',
  },
  XBB: {
    Name: 'XBB',
    Symbol: 'XBB',
    CoinName: 'BrickBlock',
    FullName: 'BrickBlock (XBB)',
  },
  ATP: {
    Name: 'ATP',
    Symbol: 'ATP',
    CoinName: 'Atlas Protocol',
    FullName: 'Atlas Protocol (ATP)',
  },
  IQN: {
    Name: 'IQN',
    Symbol: 'IQN',
    CoinName: 'IQeon',
    FullName: 'IQeon (IQN)',
  },
  CMT: {
    Name: 'CMT',
    Symbol: 'CMT',
    CoinName: 'CyberMiles',
    FullName: 'CyberMiles (CMT)',
  },
  KCS: {
    Name: 'KCS',
    Symbol: 'KCS',
    CoinName: 'KuCoin Token',
    FullName: 'KuCoin Token (KCS)',
  },
  CV: {
    Name: 'CV',
    Symbol: 'CV',
    CoinName: 'CarVertical',
    FullName: 'CarVertical (CV)',
  },
  MANA: {
    Name: 'MANA',
    Symbol: 'MANA',
    CoinName: 'Decentraland',
    FullName: 'Decentraland (MANA)',
  },
  SNET: {
    Name: 'SNET',
    Symbol: 'SNET',
    CoinName: 'Snetwork',
    FullName: 'Snetwork (SNET)',
  },
  AAVE: {
    Name: 'AAVE',
    Symbol: 'AAVE',
    CoinName: 'Aave',
    FullName: 'Aave (AAVE)',
  },
  GNX: {
    Name: 'GNX',
    Symbol: 'GNX',
    CoinName: 'Genaro Network',
    FullName: 'Genaro Network (GNX)',
  },
  EOS: {
    Name: 'EOS',
    Symbol: 'EOS',
    CoinName: 'EOS',
    FullName: 'EOS (EOS)',
  },
  LYM: {
    Name: 'LYM',
    Symbol: 'LYM',
    CoinName: 'Lympo',
    FullName: 'Lympo (LYM)',
  },
  PAXEX: {
    Name: 'PAXEX',
    Symbol: 'PAXEX',
    CoinName: 'PAXEX',
    FullName: 'PAXEX (PAXEX)',
  },
  NEXO: {
    Name: 'NEXO',
    Symbol: 'NEXO',
    CoinName: 'NEXO',
    FullName: 'NEXO (NEXO)',
  },
  MKR: {
    Name: 'MKR',
    Symbol: 'MKR',
    CoinName: 'Maker',
    FullName: 'Maker (MKR)',
  },
  AURORA: {
    Name: 'AURORA',
    Symbol: 'AURORA',
    CoinName: 'Aurora',
    FullName: 'Aurora (AURORA)',
  },
  EM: {
    Name: 'EM',
    Symbol: 'EM',
    CoinName: 'Eminer',
    FullName: 'Eminer (EM)',
  },
  TRUE: {
    Name: 'TRUE',
    Symbol: 'TRUE',
    CoinName: 'True Chain',
    FullName: 'True Chain (TRUE)',
  },
  ABL: {
    Name: 'ABL',
    Symbol: 'ABL',
    CoinName: 'Airbloc',
    FullName: 'Airbloc (ABL)',
  },
  SNX: {
    Name: 'SNX',
    Symbol: 'SNX',
    CoinName: 'Synthetix',
    FullName: 'Synthetix (SNX)',
  },
  NEU: {
    Name: 'NEU',
    Symbol: 'NEU',
    CoinName: 'Neumark',
    FullName: 'Neumark (NEU)',
  },
  RUNE: {
    Name: 'RUNE',
    Symbol: 'RUNE',
    CoinName: 'Thorchain',
    FullName: 'Thorchain (RUNE)',
  },
  GT: {
    Name: 'GT',
    Symbol: 'GT',
    CoinName: 'Gatechain Token',
    FullName: 'Gatechain Token (GT)',
  },
  CVT: {
    Name: 'CVT',
    Symbol: 'CVT',
    CoinName: 'CyberVein',
    FullName: 'CyberVein (CVT)',
  },
  CNNS: {
    Name: 'CNNS',
    Symbol: 'CNNS',
    CoinName: 'CNNS',
    FullName: 'CNNS (CNNS)',
  },
  CEL: {
    Name: 'CEL',
    Symbol: 'CEL',
    CoinName: 'Celsius Network',
    FullName: 'Celsius Network (CEL)',
  },
  RSR: {
    Name: 'RSR',
    Symbol: 'RSR',
    CoinName: 'Reserve Rights',
    FullName: 'Reserve Rights (RSR)',
  },
  TCT: {
    Name: 'TCT',
    Symbol: 'TCT',
    CoinName: 'TokenClub',
    FullName: 'TokenClub (TCT)',
  },
  YOYOW: {
    Name: 'YOYOW',
    Symbol: 'YOYOW',
    CoinName: 'Yoyow',
    FullName: 'Yoyow (YOYOW)',
  },
  CVNT: {
    Name: 'CVNT',
    Symbol: 'CVNT',
    CoinName: 'Conscious Value Network',
    FullName: 'Conscious Value Network (CVNT)',
  },
  TNB: {
    Name: 'TNB',
    Symbol: 'TNB',
    CoinName: 'Time New Bank',
    FullName: 'Time New Bank (TNB)',
  },
  LTO: {
    Name: 'LTO',
    Symbol: 'LTO',
    CoinName: 'LTO Network',
    FullName: 'LTO Network (LTO)',
  },
  HYPERS: {
    Name: 'HYPERS',
    Symbol: 'HYPERS',
    CoinName: 'HyperSpace',
    FullName: 'HyperSpace (HYPERS)',
  },
  DMG: {
    Name: 'DMG',
    Symbol: 'DMG',
    CoinName: 'DMM: Governance',
    FullName: 'DMM: Governance (DMG)',
  },
  MX: {
    Name: 'MX',
    Symbol: 'MX',
    CoinName: 'MX Token',
    FullName: 'MX Token (MX)',
  },
  PPT: {
    Name: 'PPT',
    Symbol: 'PPT',
    CoinName: 'Populous',
    FullName: 'Populous (PPT)',
  },
  ENJ: {
    Name: 'ENJ',
    Symbol: 'ENJ',
    CoinName: 'Enjin Coin',
    FullName: 'Enjin Coin (ENJ)',
  },
  VEST: {
    Name: 'VEST',
    Symbol: 'VEST',
    CoinName: 'VestChain',
    FullName: 'VestChain (VEST)',
  },
  STOX: {
    Name: 'STOX',
    Symbol: 'STOX',
    CoinName: 'Stox',
    FullName: 'Stox (STOX)',
  },
  YOU: {
    Name: 'YOU',
    Symbol: 'YOU',
    CoinName: 'YOU Chain',
    FullName: 'YOU Chain (YOU)',
  },
  SUSHI: {
    Name: 'SUSHI',
    Symbol: 'SUSHI',
    CoinName: 'Sushi',
    FullName: 'Sushi (SUSHI)',
  },
  LRC: {
    Name: 'LRC',
    Symbol: 'LRC',
    CoinName: 'Loopring',
    FullName: 'Loopring (LRC)',
  },
  BCN: {
    Name: 'BCN',
    Symbol: 'BCN',
    CoinName: 'ByteCoin',
    FullName: 'ByteCoin (BCN)',
  },
  SHR: {
    Name: 'SHR',
    Symbol: 'SHR',
    CoinName: 'ShareToken',
    FullName: 'ShareToken (SHR)',
  },
  OLT: {
    Name: 'OLT',
    Symbol: 'OLT',
    CoinName: 'OneLedger',
    FullName: 'OneLedger (OLT)',
  },
  DATA: {
    Name: 'DATA',
    Symbol: 'DATA',
    CoinName: 'Streamr',
    FullName: 'Streamr (DATA)',
  },
  DBC: {
    Name: 'DBC',
    Symbol: 'DBC',
    CoinName: 'DeepBrain Chain',
    FullName: 'DeepBrain Chain (DBC)',
  },
  KAN: {
    Name: 'KAN',
    Symbol: 'KAN',
    CoinName: 'Bitkan',
    FullName: 'Bitkan (KAN)',
  },
  MTA: {
    Name: 'MTA',
    Symbol: 'MTA',
    CoinName: 'Meta',
    FullName: 'Meta (MTA)',
  },
  ISR: {
    Name: 'ISR',
    Symbol: 'ISR',
    CoinName: 'Insureum',
    FullName: 'Insureum (ISR)',
  },
  BFT: {
    Name: 'BFT',
    Symbol: 'BFT',
    CoinName: 'BF Token',
    FullName: 'BF Token (BFT)',
  },
  CRPT: {
    Name: 'CRPT',
    Symbol: 'CRPT',
    CoinName: 'Crypterium',
    FullName: 'Crypterium (CRPT)',
  },
  RDN: {
    Name: 'RDN',
    Symbol: 'RDN',
    CoinName: 'Raiden Network Token',
    FullName: 'Raiden Network Token (RDN)',
  },
  GNO: {
    Name: 'GNO',
    Symbol: 'GNO',
    CoinName: 'Gnosis',
    FullName: 'Gnosis (GNO)',
  },
  MTV: {
    Name: 'MTV',
    Symbol: 'MTV',
    CoinName: 'MultiVAC',
    FullName: 'MultiVAC (MTV)',
  },
  RVN: {
    Name: 'RVN',
    Symbol: 'RVN',
    CoinName: 'Ravencoin',
    FullName: 'Ravencoin (RVN)',
  },
  JRT: {
    Name: 'JRT',
    Symbol: 'JRT',
    CoinName: 'Jarvis Reward Token',
    FullName: 'Jarvis Reward Token (JRT)',
  },
  SWTH: {
    Name: 'SWTH',
    Symbol: 'SWTH',
    CoinName: 'Carbon',
    FullName: 'Carbon (SWTH)',
  },
  LTK: {
    Name: 'LTK',
    Symbol: 'LTK',
    CoinName: 'LinkToken',
    FullName: 'LinkToken (LTK)',
  },
  HYDRO: {
    Name: 'HYDRO',
    Symbol: 'HYDRO',
    CoinName: 'Hydro',
    FullName: 'Hydro (HYDRO)',
  },
  TEMCO: {
    Name: 'TEMCO',
    Symbol: 'TEMCO',
    CoinName: 'TEMCO',
    FullName: 'TEMCO (TEMCO)',
  },
  OAX: {
    Name: 'OAX',
    Symbol: 'OAX',
    CoinName: 'Oax',
    FullName: 'Oax (OAX)',
  },
  ULTRA: {
    Name: 'ULTRA',
    Symbol: 'ULTRA',
    CoinName: 'Ultra',
    FullName: 'Ultra (ULTRA)',
  },
  QLC: {
    Name: 'QLC',
    Symbol: 'QLC',
    CoinName: 'QLC Chain',
    FullName: 'QLC Chain (QLC)',
  },
  VIB: {
    Name: 'VIB',
    Symbol: 'VIB',
    CoinName: 'Viberate',
    FullName: 'Viberate (VIB)',
  },
  SWFTC: {
    Name: 'SWFTC',
    Symbol: 'SWFTC',
    CoinName: 'SWFTCoin',
    FullName: 'SWFTCoin (SWFTC)',
  },
  GBYTE: {
    Name: 'GBYTE',
    Symbol: 'GBYTE',
    CoinName: 'Obyte',
    FullName: 'Obyte (GBYTE)',
  },
  FRM: {
    Name: 'FRM',
    Symbol: 'FRM',
    CoinName: 'Ferrum Network',
    FullName: 'Ferrum Network (FRM)',
  },
  QSP: {
    Name: 'QSP',
    Symbol: 'QSP',
    CoinName: 'Quantstamp',
    FullName: 'Quantstamp (QSP)',
  },
  DOCK: {
    Name: 'DOCK',
    Symbol: 'DOCK',
    CoinName: 'Dock.io',
    FullName: 'Dock.io (DOCK)',
  },
  KRL: {
    Name: 'KRL',
    Symbol: 'KRL',
    CoinName: 'Kryll',
    FullName: 'Kryll (KRL)',
  },
  AKRO: {
    Name: 'AKRO',
    Symbol: 'AKRO',
    CoinName: 'Akropolis',
    FullName: 'Akropolis (AKRO)',
  },
  BTM: {
    Name: 'BTM',
    Symbol: 'BTM',
    CoinName: 'Bytom',
    FullName: 'Bytom (BTM)',
  },
  MITH: {
    Name: 'MITH',
    Symbol: 'MITH',
    CoinName: 'Mithril',
    FullName: 'Mithril (MITH)',
  },
  BRC: {
    Name: 'BRC',
    Symbol: 'BRC',
    CoinName: 'Baer Chain',
    FullName: 'Baer Chain (BRC)',
  },
  HOT: {
    Name: 'HOT',
    Symbol: 'HOT',
    CoinName: 'Holo',
    FullName: 'Holo (HOT)',
  },
  CTXC: {
    Name: 'CTXC',
    Symbol: 'CTXC',
    CoinName: 'Cortex',
    FullName: 'Cortex (CTXC)',
  },
  HYDROP: {
    Name: 'HYDROP',
    Symbol: 'HYDROP',
    CoinName: 'Hydro Protocol',
    FullName: 'Hydro Protocol (HYDROP)',
  },
  ABT: {
    Name: 'ABT',
    Symbol: 'ABT',
    CoinName: 'ArcBlock',
    FullName: 'ArcBlock (ABT)',
  },
  FRONT: {
    Name: 'FRONT',
    Symbol: 'FRONT',
    CoinName: 'Frontier',
    FullName: 'Frontier (FRONT)',
  },
  FARM: {
    Name: 'FARM',
    Symbol: 'FARM',
    CoinName: 'Harvest Finance',
    FullName: 'Harvest Finance (FARM)',
  },
  NULS: {
    Name: 'NULS',
    Symbol: 'NULS',
    CoinName: 'Nuls',
    FullName: 'Nuls (NULS)',
  },
  KEY: {
    Name: 'KEY',
    Symbol: 'KEY',
    CoinName: 'SelfKey',
    FullName: 'SelfKey (KEY)',
  },
  UPP: {
    Name: 'UPP',
    Symbol: 'UPP',
    CoinName: 'Sentinel Protocol',
    FullName: 'Sentinel Protocol (UPP)',
  },
  ADX: {
    Name: 'ADX',
    Symbol: 'ADX',
    CoinName: 'Ambire AdEx',
    FullName: 'Ambire AdEx (ADX)',
  },
  YFI: {
    Name: 'YFI',
    Symbol: 'YFI',
    CoinName: 'yearn.finance',
    FullName: 'yearn.finance (YFI)',
  },
  ANKR: {
    Name: 'ANKR',
    Symbol: 'ANKR',
    CoinName: 'Ankr Network',
    FullName: 'Ankr Network (ANKR)',
  },
  BESTC: {
    Name: 'BESTC',
    Symbol: 'BESTC',
    CoinName: 'BestChain',
    FullName: 'BestChain (BESTC)',
  },
  JST: {
    Name: 'JST',
    Symbol: 'JST',
    CoinName: 'JUST',
    FullName: 'JUST (JST)',
  },
  POLY: {
    Name: 'POLY',
    Symbol: 'POLY',
    CoinName: 'Polymath Network',
    FullName: 'Polymath Network (POLY)',
  },
  UMA: {
    Name: 'UMA',
    Symbol: 'UMA',
    CoinName: 'UMA',
    FullName: 'UMA (UMA)',
  },
  CHSB: {
    Name: 'CHSB',
    Symbol: 'CHSB',
    CoinName: 'SwissBorg',
    FullName: 'SwissBorg (CHSB)',
  },
  STORJ: {
    Name: 'STORJ',
    Symbol: 'STORJ',
    CoinName: 'Storj',
    FullName: 'Storj (STORJ)',
  },
  COTI: {
    Name: 'COTI',
    Symbol: 'COTI',
    CoinName: 'COTI',
    FullName: 'COTI (COTI)',
  },
  POWR: {
    Name: 'POWR',
    Symbol: 'POWR',
    CoinName: 'Power Ledger',
    FullName: 'Power Ledger (POWR)',
  },
  KNC: {
    Name: 'KNC',
    Symbol: 'KNC',
    CoinName: 'Kyber Network Crystal v2',
    FullName: 'Kyber Network Crystal v2 (KNC)',
  },
  SXP: {
    Name: 'SXP',
    Symbol: 'SXP',
    CoinName: 'Swipe',
    FullName: 'Swipe (SXP)',
  },
  NMR: {
    Name: 'NMR',
    Symbol: 'NMR',
    CoinName: 'Numeraire',
    FullName: 'Numeraire (NMR)',
  },
  TEL: {
    Name: 'TEL',
    Symbol: 'TEL',
    CoinName: 'Telcoin',
    FullName: 'Telcoin (TEL)',
  },
  MXC: {
    Name: 'MXC',
    Symbol: 'MXC',
    CoinName: 'Machine Xchange Coin',
    FullName: 'Machine Xchange Coin (MXC)',
  },
  CELR: {
    Name: 'CELR',
    Symbol: 'CELR',
    CoinName: 'Celer Network',
    FullName: 'Celer Network (CELR)',
  },
  OGN: {
    Name: 'OGN',
    Symbol: 'OGN',
    CoinName: 'Origin Protocol',
    FullName: 'Origin Protocol (OGN)',
  },
  MVL: {
    Name: 'MVL',
    Symbol: 'MVL',
    CoinName: 'MVL',
    FullName: 'MVL (MVL)',
  },
  SUN: {
    Name: 'SUN',
    Symbol: 'SUN',
    CoinName: 'Sun Token',
    FullName: 'Sun Token (SUN)',
  },
  ORN: {
    Name: 'ORN',
    Symbol: 'ORN',
    CoinName: 'Orion Protocol',
    FullName: 'Orion Protocol (ORN)',
  },
  REN: {
    Name: 'REN',
    Symbol: 'REN',
    CoinName: 'REN',
    FullName: 'REN (REN)',
  },
  NXM: {
    Name: 'NXM',
    Symbol: 'NXM',
    CoinName: 'Nexus Mutual',
    FullName: 'Nexus Mutual (NXM)',
  },
  CVC: {
    Name: 'CVC',
    Symbol: 'CVC',
    CoinName: 'Civic',
    FullName: 'Civic (CVC)',
  },
  MCO: {
    Name: 'MCO',
    Symbol: 'MCO',
    CoinName: 'Crypto.com',
    FullName: 'Crypto.com (MCO)',
  },
  OCEAN: {
    Name: 'OCEAN',
    Symbol: 'OCEAN',
    CoinName: 'Ocean Protocol',
    FullName: 'Ocean Protocol (OCEAN)',
  },
  TRAC: {
    Name: 'TRAC',
    Symbol: 'TRAC',
    CoinName: 'OriginTrail',
    FullName: 'OriginTrail (TRAC)',
  },
  ELF: {
    Name: 'ELF',
    Symbol: 'ELF',
    CoinName: 'aelf',
    FullName: 'aelf (ELF)',
  },
  REQ: {
    Name: 'REQ',
    Symbol: 'REQ',
    CoinName: 'Request Network',
    FullName: 'Request Network (REQ)',
  },
  WIN: {
    Name: 'WIN',
    Symbol: 'WIN',
    CoinName: 'WINk',
    FullName: 'WINk (WIN)',
  },
  RLC: {
    Name: 'RLC',
    Symbol: 'RLC',
    CoinName: 'iExec',
    FullName: 'iExec (RLC)',
  },
  IDO: {
    Name: 'IDO',
    Symbol: 'IDO',
    CoinName: 'Idexo',
    FullName: 'Idexo (IDO)',
  },
  STEEM: {
    Name: 'STEEM',
    Symbol: 'STEEM',
    CoinName: 'Steem',
    FullName: 'Steem (STEEM)',
  },
  MBL: {
    Name: 'MBL',
    Symbol: 'MBL',
    CoinName: 'MovieBloc',
    FullName: 'MovieBloc (MBL)',
  },
  BMX: {
    Name: 'BMX',
    Symbol: 'BMX',
    CoinName: 'BitMart Token',
    FullName: 'BitMart Token (BMX)',
  },
  FET: {
    Name: 'FET',
    Symbol: 'FET',
    CoinName: 'Fetch.AI',
    FullName: 'Fetch.AI (FET)',
  },
  SUKU: {
    Name: 'SUKU',
    Symbol: 'SUKU',
    CoinName: 'SUKU',
    FullName: 'SUKU (SUKU)',
  },
  BNT: {
    Name: 'BNT',
    Symbol: 'BNT',
    CoinName: 'Bancor Network Token',
    FullName: 'Bancor Network Token (BNT)',
  },
  DENT: {
    Name: 'DENT',
    Symbol: 'DENT',
    CoinName: 'Dent',
    FullName: 'Dent (DENT)',
  },
  NKN: {
    Name: 'NKN',
    Symbol: 'NKN',
    CoinName: 'NKN',
    FullName: 'NKN (NKN)',
  },
  CHR: {
    Name: 'CHR',
    Symbol: 'CHR',
    CoinName: 'Chroma',
    FullName: 'Chroma (CHR)',
  },
  XYO: {
    Name: 'XYO',
    Symbol: 'XYO',
    CoinName: 'XY Oracle',
    FullName: 'XY Oracle (XYO)',
  },
  ANT: {
    Name: 'ANT',
    Symbol: 'ANT',
    CoinName: 'Aragon',
    FullName: 'Aragon (ANT)',
  },
  UTK: {
    Name: 'UTK',
    Symbol: 'UTK',
    CoinName: 'Utrust',
    FullName: 'Utrust (UTK)',
  },
  AERGO: {
    Name: 'AERGO',
    Symbol: 'AERGO',
    CoinName: 'AERGO',
    FullName: 'AERGO (AERGO)',
  },
  LST: {
    Name: 'LST',
    Symbol: 'LST',
    CoinName: 'Lendroid Support Token',
    FullName: 'Lendroid Support Token (LST)',
  },
  DUSK: {
    Name: 'DUSK',
    Symbol: 'DUSK',
    CoinName: 'Dusk Network',
    FullName: 'Dusk Network (DUSK)',
  },
  LOOM: {
    Name: 'LOOM',
    Symbol: 'LOOM',
    CoinName: 'Loom Network',
    FullName: 'Loom Network (LOOM)',
  },
  MTL: {
    Name: 'MTL',
    Symbol: 'MTL',
    CoinName: 'Metal',
    FullName: 'Metal (MTL)',
  },
  PRO: {
    Name: 'PRO',
    Symbol: 'PRO',
    CoinName: 'Propy',
    FullName: 'Propy (PRO)',
  },
  AST: {
    Name: 'AST',
    Symbol: 'AST',
    CoinName: 'AirSwap',
    FullName: 'AirSwap (AST)',
  },
  IDEX: {
    Name: 'IDEX',
    Symbol: 'IDEX',
    CoinName: 'IDEX',
    FullName: 'IDEX (IDEX)',
  },
  RIF: {
    Name: 'RIF',
    Symbol: 'RIF',
    CoinName: 'RIF Token',
    FullName: 'RIF Token (RIF)',
  },
  REP: {
    Name: 'REP',
    Symbol: 'REP',
    CoinName: 'Augur',
    FullName: 'Augur (REP)',
  },
  YFII: {
    Name: 'YFII',
    Symbol: 'YFII',
    CoinName: 'DFI.money',
    FullName: 'DFI.money (YFII)',
  },
  WXT: {
    Name: 'WXT',
    Symbol: 'WXT',
    CoinName: 'WXT',
    FullName: 'WXT (WXT)',
  },
  MLN: {
    Name: 'MLN',
    Symbol: 'MLN',
    CoinName: 'Enzyme',
    FullName: 'Enzyme (MLN)',
  },
  NIOX: {
    Name: 'NIOX',
    Symbol: 'NIOX',
    CoinName: 'Autonio',
    FullName: 'Autonio (NIOX)',
  },
  ARPA: {
    Name: 'ARPA',
    Symbol: 'ARPA',
    CoinName: 'ARPA Chain',
    FullName: 'ARPA Chain (ARPA)',
  },
  DF: {
    Name: 'DF',
    Symbol: 'DF',
    CoinName: 'dForce',
    FullName: 'dForce (DF)',
  },
  SOLVE: {
    Name: 'SOLVE',
    Symbol: 'SOLVE',
    CoinName: 'SOLVE',
    FullName: 'SOLVE (SOLVE)',
  },
  AMPL: {
    Name: 'AMPL',
    Symbol: 'AMPL',
    CoinName: 'Ampleforth',
    FullName: 'Ampleforth (AMPL)',
  },
  CVP: {
    Name: 'CVP',
    Symbol: 'CVP',
    CoinName: 'PowerPool Concentrated Voting Power',
    FullName: 'PowerPool Concentrated Voting Power (CVP)',
  },
  VRA: {
    Name: 'VRA',
    Symbol: 'VRA',
    CoinName: 'Verasity',
    FullName: 'Verasity (VRA)',
  },
  NEBL: {
    Name: 'NEBL',
    Symbol: 'NEBL',
    CoinName: 'Neblio',
    FullName: 'Neblio (NEBL)',
  },
  MOC: {
    Name: 'MOC',
    Symbol: 'MOC',
    CoinName: 'Mossland',
    FullName: 'Mossland (MOC)',
  },
  BLZ: {
    Name: 'BLZ',
    Symbol: 'BLZ',
    CoinName: 'Bluzelle',
    FullName: 'Bluzelle (BLZ)',
  },
  DX: {
    Name: 'DX',
    Symbol: 'DX',
    CoinName: 'DxChain Token',
    FullName: 'DxChain Token (DX)',
  },
  TRB: {
    Name: 'TRB',
    Symbol: 'TRB',
    CoinName: 'Tellor',
    FullName: 'Tellor (TRB)',
  },
  DGTX: {
    Name: 'DGTX',
    Symbol: 'DGTX',
    CoinName: 'Digitex Token',
    FullName: 'Digitex Token (DGTX)',
  },
  DNOTES: {
    Name: 'DNOTES',
    Symbol: 'DNOTES',
    CoinName: 'Dnotes',
    FullName: 'Dnotes (DNOTES)',
  },
  WAN: {
    Name: 'WAN',
    Symbol: 'WAN',
    CoinName: 'Wanchain',
    FullName: 'Wanchain (WAN)',
  },
  RFR: {
    Name: 'RFR',
    Symbol: 'RFR',
    CoinName: 'Refereum',
    FullName: 'Refereum (RFR)',
  },
  CREAM: {
    Name: 'CREAM',
    Symbol: 'CREAM',
    CoinName: 'Cream',
    FullName: 'Cream (CREAM)',
  },
  UBT: {
    Name: 'UBT',
    Symbol: 'UBT',
    CoinName: 'UniBright',
    FullName: 'UniBright (UBT)',
  },
  NOVA: {
    Name: 'NOVA',
    Symbol: 'NOVA',
    CoinName: 'Nova Finance',
    FullName: 'Nova Finance (NOVA)',
  },
  RDNT: {
    Name: 'RDNT',
    Symbol: 'RDNT',
    CoinName: 'Radiant Capital',
    FullName: 'Radiant Capital (RDNT)',
  },
  XSPECTAR: {
    Name: 'XSPECTAR',
    Symbol: 'XSPECTAR',
    CoinName: 'xSPECTAR',
    FullName: 'xSPECTAR (XSPECTAR)',
  },
  MOONER: {
    Name: 'MOONER',
    Symbol: 'MOONER',
    CoinName: 'CoinMooner',
    FullName: 'CoinMooner (MOONER)',
  },
  FDC: {
    Name: 'FDC',
    Symbol: 'FDC',
    CoinName: 'Fidance',
    FullName: 'Fidance (FDC)',
  },
  FOXV2: {
    Name: 'FOXV2',
    Symbol: 'FOXV2',
    CoinName: 'FoxFinanceV2',
    FullName: 'FoxFinanceV2 (FOXV2)',
  },
  BPX: {
    Name: 'BPX',
    Symbol: 'BPX',
    CoinName: 'Black Phoenix',
    FullName: 'Black Phoenix (BPX)',
  },
  ETHW: {
    Name: 'ETHW',
    Symbol: 'ETHW',
    CoinName: 'Ethereum PoW',
    FullName: 'Ethereum PoW (ETHW)',
  },
  VRGW: {
    Name: 'VRGW',
    Symbol: 'VRGW',
    CoinName: 'Virtual Reality Game World',
    FullName: 'Virtual Reality Game World (VRGW)',
  },
  GDE: {
    Name: 'GDE',
    Symbol: 'GDE',
    CoinName: 'Golden Eagle',
    FullName: 'Golden Eagle (GDE)',
  },
  GMEX: {
    Name: 'GMEX',
    Symbol: 'GMEX',
    CoinName: 'Game Coin',
    FullName: 'Game Coin (GMEX)',
  },
  AR: {
    Name: 'AR',
    Symbol: 'AR',
    CoinName: 'Arweave',
    FullName: 'Arweave (AR)',
  },
  VLTY: {
    Name: 'VLTY',
    Symbol: 'VLTY',
    CoinName: 'Vaulty',
    FullName: 'Vaulty (VLTY)',
  },
  HELLO: {
    Name: 'HELLO',
    Symbol: 'HELLO',
    CoinName: 'HELLO',
    FullName: 'HELLO (HELLO)',
  },
  VICA: {
    Name: 'VICA',
    Symbol: 'VICA',
    CoinName: 'ViCA Token',
    FullName: 'ViCA Token (VICA)',
  },
  HNTR: {
    Name: 'HNTR',
    Symbol: 'HNTR',
    CoinName: 'Hunter',
    FullName: 'Hunter (HNTR)',
  },
  HPN: {
    Name: 'HPN',
    Symbol: 'HPN',
    CoinName: 'HyperonChain',
    FullName: 'HyperonChain (HPN)',
  },
  KALI: {
    Name: 'KALI',
    Symbol: 'KALI',
    CoinName: 'Kalissa',
    FullName: 'Kalissa (KALI)',
  },
  VOLR: {
    Name: 'VOLR',
    Symbol: 'VOLR',
    CoinName: 'Volare Network',
    FullName: 'Volare Network (VOLR)',
  },
  BLITZP: {
    Name: 'BLITZP',
    Symbol: 'BLITZP',
    CoinName: 'BlitzPredict',
    FullName: 'BlitzPredict (BLITZP)',
  },
  CYBERC: {
    Name: 'CYBERC',
    Symbol: 'CYBERC',
    CoinName: 'CyberCoin',
    FullName: 'CyberCoin (CYBERC)',
  },
  KRU: {
    Name: 'KRU',
    Symbol: 'KRU',
    CoinName: 'Kingaru',
    FullName: 'Kingaru (KRU)',
  },
  TIKTOKEN: {
    Name: 'TIKTOKEN',
    Symbol: 'TIKTOKEN',
    CoinName: 'TikToken',
    FullName: 'TikToken (TIKTOKEN)',
  },
  TCG2: {
    Name: 'TCG2',
    Symbol: 'TCG2',
    CoinName: 'TCG Coin 2.0',
    FullName: 'TCG Coin 2.0 (TCG2)',
  },
  KTO: {
    Name: 'KTO',
    Symbol: 'KTO',
    CoinName: 'Kounotori',
    FullName: 'Kounotori (KTO)',
  },
  TOMS: {
    Name: 'TOMS',
    Symbol: 'TOMS',
    CoinName: 'TomTomCoin',
    FullName: 'TomTomCoin (TOMS)',
  },
  PI: {
    Name: 'PI',
    Symbol: 'PI',
    CoinName: 'Plian',
    FullName: 'Plian (PI)',
  },
  TDX: {
    Name: 'TDX',
    Symbol: 'TDX',
    CoinName: 'Tidex Token',
    FullName: 'Tidex Token (TDX)',
  },
  SPUME: {
    Name: 'SPUME',
    Symbol: 'SPUME',
    CoinName: 'Spume',
    FullName: 'Spume (SPUME)',
  },
  UNITED: {
    Name: 'UNITED',
    Symbol: 'UNITED',
    CoinName: 'UnitedCoins',
    FullName: 'UnitedCoins (UNITED)',
  },
  RYOMA: {
    Name: 'RYOMA',
    Symbol: 'RYOMA',
    CoinName: 'Ryoma',
    FullName: 'Ryoma (RYOMA)',
  },
  RETH: {
    Name: 'RETH',
    Symbol: 'RETH',
    CoinName: 'Realms of Ethernity',
    FullName: 'Realms of Ethernity (RETH)',
  },
  PGALA: {
    Name: 'PGALA',
    Symbol: 'PGALA',
    CoinName: 'pGALA',
    FullName: 'pGALA (PGALA)',
  },
  MCG: {
    Name: 'MCG',
    Symbol: 'MCG',
    CoinName: 'MicroChains Gov Token',
    FullName: 'MicroChains Gov Token (MCG)',
  },
  KUBE: {
    Name: 'KUBE',
    Symbol: 'KUBE',
    CoinName: 'KubeCoin',
    FullName: 'KubeCoin (KUBE)',
  },
  '1SOL': {
    Name: '1SOL',
    Symbol: '1SOL',
    CoinName: '1Sol',
    FullName: '1Sol (1SOL)',
  },
  GOLC: {
    Name: 'GOLC',
    Symbol: 'GOLC',
    CoinName: 'GOLCOIN',
    FullName: 'GOLCOIN (GOLC)',
  },
  FND: {
    Name: 'FND',
    Symbol: 'FND',
    CoinName: 'FundRequest',
    FullName: 'FundRequest (FND)',
  },
  FJT: {
    Name: 'FJT',
    Symbol: 'FJT',
    CoinName: 'Fuji FJT',
    FullName: 'Fuji FJT (FJT)',
  },
  DRIVECRYPTO: {
    Name: 'DRIVECRYPTO',
    Symbol: 'DRIVECRYPTO',
    CoinName: 'Drive Crypto',
    FullName: 'Drive Crypto (DRIVECRYPTO)',
  },
  YCT: {
    Name: 'YCT',
    Symbol: 'YCT',
    CoinName: 'Youclout',
    FullName: 'Youclout (YCT)',
  },
  CGO: {
    Name: 'CGO',
    Symbol: 'CGO',
    CoinName: 'Comtech Gold',
    FullName: 'Comtech Gold (CGO)',
  },
  TOMO: {
    Name: 'TOMO',
    Symbol: 'TOMO',
    CoinName: 'TomoChain',
    FullName: 'TomoChain (TOMO)',
  },
  DRIV: {
    Name: 'DRIV',
    Symbol: 'DRIV',
    CoinName: 'DRIVEZ',
    FullName: 'DRIVEZ (DRIV)',
  },
  ZNT: {
    Name: 'ZNT',
    Symbol: 'ZNT',
    CoinName: 'Zenith Finance',
    FullName: 'Zenith Finance (ZNT)',
  },
  ESNC: {
    Name: 'ESNC',
    Symbol: 'ESNC',
    CoinName: 'Galaxy Arena Metaverse',
    FullName: 'Galaxy Arena Metaverse (ESNC)',
  },
  FDT: {
    Name: 'FDT',
    Symbol: 'FDT',
    CoinName: 'Frutti Dino',
    FullName: 'Frutti Dino (FDT)',
  },
  FANC: {
    Name: 'FANC',
    Symbol: 'FANC',
    CoinName: 'fanC',
    FullName: 'fanC (FANC)',
  },
  GCAKE: {
    Name: 'GCAKE',
    Symbol: 'GCAKE',
    CoinName: 'Pancake Games',
    FullName: 'Pancake Games (GCAKE)',
  },
  LING: {
    Name: 'LING',
    Symbol: 'LING',
    CoinName: 'Lingose',
    FullName: 'Lingose (LING)',
  },
  ETHF: {
    Name: 'ETHF',
    Symbol: 'ETHF',
    CoinName: 'EthereumFair',
    FullName: 'EthereumFair (ETHF)',
  },
  HFT: {
    Name: 'HFT',
    Symbol: 'HFT',
    CoinName: 'Hashflow',
    FullName: 'Hashflow (HFT)',
  },
  ERTH: {
    Name: 'ERTH',
    Symbol: 'ERTH',
    CoinName: 'Erth Point',
    FullName: 'Erth Point (ERTH)',
  },
  FANV: {
    Name: 'FANV',
    Symbol: 'FANV',
    CoinName: 'FanVerse',
    FullName: 'FanVerse (FANV)',
  },
  IVAR: {
    Name: 'IVAR',
    Symbol: 'IVAR',
    CoinName: 'Ivar Coin',
    FullName: 'Ivar Coin (IVAR)',
  },
  LOF: {
    Name: 'LOF',
    Symbol: 'LOF',
    CoinName: 'Land of Fantasy',
    FullName: 'Land of Fantasy (LOF)',
  },
  KEES: {
    Name: 'KEES',
    Symbol: 'KEES',
    CoinName: 'Korea Entertainment Education & Shopping',
    FullName: 'Korea Entertainment Education & Shopping (KEES)',
  },
  RATECOIN: {
    Name: 'RATECOIN',
    Symbol: 'RATECOIN',
    CoinName: 'Ratecoin',
    FullName: 'Ratecoin (RATECOIN)',
  },
  XRA: {
    Name: 'XRA',
    Symbol: 'XRA',
    CoinName: 'Xriba',
    FullName: 'Xriba (XRA)',
  },
  WMF: {
    Name: 'WMF',
    Symbol: 'WMF',
    CoinName: 'Whale Maker Fund',
    FullName: 'Whale Maker Fund (WMF)',
  },
  ARIX: {
    Name: 'ARIX',
    Symbol: 'ARIX',
    CoinName: 'Arix',
    FullName: 'Arix (ARIX)',
  },
  VNES: {
    Name: 'VNES',
    Symbol: 'VNES',
    CoinName: 'Vanesse',
    FullName: 'Vanesse (VNES)',
  },
  QIE: {
    Name: 'QIE',
    Symbol: 'QIE',
    CoinName: 'QI Blockchain',
    FullName: 'QI Blockchain (QIE)',
  },
  PNFT: {
    Name: 'PNFT',
    Symbol: 'PNFT',
    CoinName: 'Pawn My NFT',
    FullName: 'Pawn My NFT (PNFT)',
  },
  MTRM: {
    Name: 'MTRM',
    Symbol: 'MTRM',
    CoinName: 'Materium',
    FullName: 'Materium (MTRM)',
  },
  WETH: {
    Name: 'WETH',
    Symbol: 'WETH',
    CoinName: 'WETH',
    FullName: 'WETH (WETH)',
  },
  MAGIC: {
    Name: 'MAGIC',
    Symbol: 'MAGIC',
    CoinName: 'Magic',
    FullName: 'Magic (MAGIC)',
  },
  PUREALT: {
    Name: 'PUREALT',
    Symbol: 'PUREALT',
    CoinName: 'Pure',
    FullName: 'Pure (PUREALT)',
  },
  TOMAHAWKCOIN: {
    Name: 'TOMAHAWKCOIN',
    Symbol: 'TOMAHAWKCOIN',
    CoinName: 'Tomahawkcoin',
    FullName: 'Tomahawkcoin (TOMAHAWKCOIN)',
  },
  TOM: {
    Name: 'TOM',
    Symbol: 'TOM',
    CoinName: 'TOM Finance',
    FullName: 'TOM Finance (TOM)',
  },
  CACHEGOLD: {
    Name: 'CACHEGOLD',
    Symbol: 'CACHEGOLD',
    CoinName: 'CACHE Gold',
    FullName: 'CACHE Gold (CACHEGOLD)',
  },
  CGT: {
    Name: 'CGT',
    Symbol: 'CGT',
    CoinName: 'Coin Gabbar Token',
    FullName: 'Coin Gabbar Token (CGT)',
  },
  MOBIE: {
    Name: 'MOBIE',
    Symbol: 'MOBIE',
    CoinName: 'MobieCoin',
    FullName: 'MobieCoin (MOBIE)',
  },
  MBX: {
    Name: 'MBX',
    Symbol: 'MBX',
    CoinName: 'Marblex',
    FullName: 'Marblex (MBX)',
  },
  IZICHAIN: {
    Name: 'IZICHAIN',
    Symbol: 'IZICHAIN',
    CoinName: 'IZIChain',
    FullName: 'IZIChain (IZICHAIN)',
  },
  IZI: {
    Name: 'IZI',
    Symbol: 'IZI',
    CoinName: 'Izumi Finance',
    FullName: 'Izumi Finance (IZI)',
  },
  OLIVE: {
    Name: 'OLIVE',
    Symbol: 'OLIVE',
    CoinName: 'Olive',
    FullName: 'Olive (OLIVE)',
  },
  OLE: {
    Name: 'OLE',
    Symbol: 'OLE',
    CoinName: 'OpenLeverage',
    FullName: 'OpenLeverage (OLE)',
  },
  REDLANG: {
    Name: 'REDLANG',
    Symbol: 'REDLANG',
    CoinName: 'RED',
    FullName: 'RED (REDLANG)',
  },
  RED: {
    Name: 'RED',
    Symbol: 'RED',
    CoinName: 'RED TOKEN',
    FullName: 'RED TOKEN (RED)',
  },
  REFTOKEN: {
    Name: 'REFTOKEN',
    Symbol: 'REFTOKEN',
    CoinName: 'RefToken',
    FullName: 'RefToken (REFTOKEN)',
  },
  REF: {
    Name: 'REF',
    Symbol: 'REF',
    CoinName: 'Ref Finance',
    FullName: 'Ref Finance (REF)',
  },
  XENIX: {
    Name: 'XENIX',
    Symbol: 'XENIX',
    CoinName: 'XenixCoin',
    FullName: 'XenixCoin (XENIX)',
  },
  XEN: {
    Name: 'XEN',
    Symbol: 'XEN',
    CoinName: 'XEN Crypto',
    FullName: 'XEN Crypto (XEN)',
  },
  BRITTO: {
    Name: 'BRITTO',
    Symbol: 'BRITTO',
    CoinName: 'Britto',
    FullName: 'Britto (BRITTO)',
  },
  BRT: {
    Name: 'BRT',
    Symbol: 'BRT',
    CoinName: 'Bikerush',
    FullName: 'Bikerush (BRT)',
  },
  MONEYIMT: {
    Name: 'MONEYIMT',
    Symbol: 'MONEYIMT',
    CoinName: 'MoneyToken',
    FullName: 'MoneyToken (MONEYIMT)',
  },
  IMT: {
    Name: 'IMT',
    Symbol: 'IMT',
    CoinName: 'IMOV',
    FullName: 'IMOV (IMT)',
  },
  U: {
    Name: 'U',
    Symbol: 'U',
    CoinName: 'Unidef',
    FullName: 'Unidef (U)',
  },
  STEEMD: {
    Name: 'STEEMD',
    Symbol: 'STEEMD',
    CoinName: 'Steem Dollars',
    FullName: 'Steem Dollars (STEEMD)',
  },
  BTCPX: {
    Name: 'BTCPX',
    Symbol: 'BTCPX',
    CoinName: 'BTC Proxy',
    FullName: 'BTC Proxy (BTCPX)',
  },
  POPSICLE: {
    Name: 'POPSICLE',
    Symbol: 'POPSICLE',
    CoinName: 'Popsicle Finance',
    FullName: 'Popsicle Finance (POPSICLE)',
  },
  ICE: {
    Name: 'ICE',
    Symbol: 'ICE',
    CoinName: 'Decentral Games ICE',
    FullName: 'Decentral Games ICE (ICE)',
  },
  ZZ: {
    Name: 'ZZ',
    Symbol: 'ZZ',
    CoinName: 'ZigZag',
    FullName: 'ZigZag (ZZ)',
  },
  SMETA: {
    Name: 'SMETA',
    Symbol: 'SMETA',
    CoinName: 'StarkMeta',
    FullName: 'StarkMeta (SMETA)',
  },
  SYPOOL: {
    Name: 'SYPOOL',
    Symbol: 'SYPOOL',
    CoinName: 'Sypool',
    FullName: 'Sypool (SYPOOL)',
  },
  AUROS: {
    Name: 'AUROS',
    Symbol: 'AUROS',
    CoinName: 'AurusGOLD',
    FullName: 'AurusGOLD (AUROS)',
  },
  SPAT: {
    Name: 'SPAT',
    Symbol: 'SPAT',
    CoinName: 'Meta Spatial',
    FullName: 'Meta Spatial (SPAT)',
  },
  NEURALINK: {
    Name: 'NEURALINK',
    Symbol: 'NEURALINK',
    CoinName: 'Neuralink',
    FullName: 'Neuralink (NEURALINK)',
  },
  FAME: {
    Name: 'FAME',
    Symbol: 'FAME',
    CoinName: 'Fame MMA',
    FullName: 'Fame MMA (FAME)',
  },
  INJ: {
    Name: 'INJ',
    Symbol: 'INJ',
    CoinName: 'Injective',
    FullName: 'Injective (INJ)',
  },
  OKG: {
    Name: 'OKG',
    Symbol: 'OKG',
    CoinName: 'Ookeenga',
    FullName: 'Ookeenga (OKG)',
  },
  SETH: {
    Name: 'SETH',
    Symbol: 'SETH',
    CoinName: 'sETH',
    FullName: 'sETH (SETH)',
  },
  SETH2: {
    Name: 'SETH2',
    Symbol: 'SETH2',
    CoinName: 'sETH2',
    FullName: 'sETH2 (SETH2)',
  },
  HT: {
    Name: 'HT',
    Symbol: 'HT',
    CoinName: 'Huobi Token',
    FullName: 'Huobi Token (HT)',
  },
  OKSE: {
    Name: 'OKSE',
    Symbol: 'OKSE',
    CoinName: 'Okse',
    FullName: 'Okse (OKSE)',
  },
  SMCW: {
    Name: 'SMCW',
    Symbol: 'SMCW',
    CoinName: 'Space Misfits',
    FullName: 'Space Misfits (SMCW)',
  },
  '2GCC': {
    Name: '2GCC',
    Symbol: '2GCC',
    CoinName: '2G Carbon Coin',
    FullName: '2G Carbon Coin (2GCC)',
  },
  MNTL: {
    Name: 'MNTL',
    Symbol: 'MNTL',
    CoinName: 'AssetMantle',
    FullName: 'AssetMantle (MNTL)',
  },
  ABIC: {
    Name: 'ABIC',
    Symbol: 'ABIC',
    CoinName: 'Arabic',
    FullName: 'Arabic (ABIC)',
  },
  POR: {
    Name: 'POR',
    Symbol: 'POR',
    CoinName: 'Portugal National Team Fan Token',
    FullName: 'Portugal National Team Fan Token (POR)',
  },
  SKEB: {
    Name: 'SKEB',
    Symbol: 'SKEB',
    CoinName: 'Skeb',
    FullName: 'Skeb (SKEB)',
  },
  APXVENTURES: {
    Name: 'APXVENTURES',
    Symbol: 'APXVENTURES',
    CoinName: 'Apx',
    FullName: 'Apx (APXVENTURES)',
  },
  APX: {
    Name: 'APX',
    Symbol: 'APX',
    CoinName: 'ApolloX',
    FullName: 'ApolloX (APX)',
  },
  CRYPTOBULLION: {
    Name: 'CRYPTOBULLION',
    Symbol: 'CRYPTOBULLION',
    CoinName: 'CryptoBullion',
    FullName: 'CryptoBullion (CRYPTOBULLION)',
  },
  DELCHAIN: {
    Name: 'DELCHAIN',
    Symbol: 'DELCHAIN',
    CoinName: 'DelChain',
    FullName: 'DelChain (DELCHAIN)',
  },
  DEL: {
    Name: 'DEL',
    Symbol: 'DEL',
    CoinName: 'Decimal',
    FullName: 'Decimal (DEL)',
  },
  SNM: {
    Name: 'SNM',
    Symbol: 'SNM',
    CoinName: 'SONM',
    FullName: 'SONM (SNM)',
  },
  MINTME: {
    Name: 'MINTME',
    Symbol: 'MINTME',
    CoinName: 'MintMe.com Coin',
    FullName: 'MintMe.com Coin (MINTME)',
  },
  AGX: {
    Name: 'AGX',
    Symbol: 'AGX',
    CoinName: 'Agricoin',
    FullName: 'Agricoin (AGX)',
  },
  TT: {
    Name: 'TT',
    Symbol: 'TT',
    CoinName: 'ThunderCore',
    FullName: 'ThunderCore (TT)',
  },
  SNAP: {
    Name: 'SNAP',
    Symbol: 'SNAP',
    CoinName: 'SnapEx',
    FullName: 'SnapEx (SNAP)',
  },
  ETHAX: {
    Name: 'ETHAX',
    Symbol: 'ETHAX',
    CoinName: 'ETHAX',
    FullName: 'ETHAX (ETHAX)',
  },
  USDZ: {
    Name: 'USDZ',
    Symbol: 'USDZ',
    CoinName: 'Zedxion USDZ',
    FullName: 'Zedxion USDZ (USDZ)',
  },
  STSR: {
    Name: 'STSR',
    Symbol: 'STSR',
    CoinName: 'SatelStar',
    FullName: 'SatelStar (STSR)',
  },
  DKD: {
    Name: 'DKD',
    Symbol: 'DKD',
    CoinName: 'Dekado',
    FullName: 'Dekado (DKD)',
  },
  ITEM: {
    Name: 'ITEM',
    Symbol: 'ITEM',
    CoinName: 'ITEMVERSE',
    FullName: 'ITEMVERSE (ITEM)',
  },
  LUNAT: {
    Name: 'LUNAT',
    Symbol: 'LUNAT',
    CoinName: 'Lunatics',
    FullName: 'Lunatics (LUNAT)',
  },
  METAVIE: {
    Name: 'METAVIE',
    Symbol: 'METAVIE',
    CoinName: 'Metavie',
    FullName: 'Metavie (METAVIE)',
  },
  BETH: {
    Name: 'BETH',
    Symbol: 'BETH',
    CoinName: 'Beacon ETH',
    FullName: 'Beacon ETH (BETH)',
  },
  DOGE: {
    Name: 'DOGE',
    Symbol: 'DOGE',
    CoinName: 'Dogecoin',
    FullName: 'Dogecoin (DOGE)',
  },
  ISLAMI: {
    Name: 'ISLAMI',
    Symbol: 'ISLAMI',
    CoinName: 'ISLAMICOIN',
    FullName: 'ISLAMICOIN (ISLAMI)',
  },
  IBAT: {
    Name: 'IBAT',
    Symbol: 'IBAT',
    CoinName: 'Battle Infinity',
    FullName: 'Battle Infinity (IBAT)',
  },
  MNT: {
    Name: 'MNT',
    Symbol: 'MNT',
    CoinName: 'microNFT',
    FullName: 'microNFT (MNT)',
  },
  MBASE: {
    Name: 'MBASE',
    Symbol: 'MBASE',
    CoinName: 'Minebase',
    FullName: 'Minebase (MBASE)',
  },
  DKS: {
    Name: 'DKS',
    Symbol: 'DKS',
    CoinName: 'DarkShield',
    FullName: 'DarkShield (DKS)',
  },
  PAXW: {
    Name: 'PAXW',
    Symbol: 'PAXW',
    CoinName: 'pax.world',
    FullName: 'pax.world (PAXW)',
  },
  GATEWAY: {
    Name: 'GATEWAY',
    Symbol: 'GATEWAY',
    CoinName: 'Gateway Protocol',
    FullName: 'Gateway Protocol (GATEWAY)',
  },
  EVO: {
    Name: 'EVO',
    Symbol: 'EVO',
    CoinName: 'EvoVerses',
    FullName: 'EvoVerses (EVO)',
  },
  DOGBOSS: {
    Name: 'DOGBOSS',
    Symbol: 'DOGBOSS',
    CoinName: 'Dog Boss',
    FullName: 'Dog Boss (DOGBOSS)',
  },
  CNG: {
    Name: 'CNG',
    Symbol: 'CNG',
    CoinName: 'Changer',
    FullName: 'Changer (CNG)',
  },
  PRMX: {
    Name: 'PRMX',
    Symbol: 'PRMX',
    CoinName: 'PREMA',
    FullName: 'PREMA (PRMX)',
  },
  MTRX: {
    Name: 'MTRX',
    Symbol: 'MTRX',
    CoinName: 'Metarix',
    FullName: 'Metarix (MTRX)',
  },
  BURN: {
    Name: 'BURN',
    Symbol: 'BURN',
    CoinName: 'Bitburn',
    FullName: 'Bitburn (BURN)',
  },
  DRACOO: {
    Name: 'DRACOO',
    Symbol: 'DRACOO',
    CoinName: 'DracooMaster',
    FullName: 'DracooMaster (DRACOO)',
  },
  ELVN: {
    Name: 'ELVN',
    Symbol: 'ELVN',
    CoinName: '11Minutes',
    FullName: '11Minutes (ELVN)',
  },
  BFHT: {
    Name: 'BFHT',
    Symbol: 'BFHT',
    CoinName: 'BeFaster Holder Token',
    FullName: 'BeFaster Holder Token (BFHT)',
  },
  PIAS: {
    Name: 'PIAS',
    Symbol: 'PIAS',
    CoinName: 'PIAS',
    FullName: 'PIAS (PIAS)',
  },
  TRICKLE: {
    Name: 'TRICKLE',
    Symbol: 'TRICKLE',
    CoinName: 'Trickle',
    FullName: 'Trickle (TRICKLE)',
  },
  POSS: {
    Name: 'POSS',
    Symbol: 'POSS',
    CoinName: 'Posschain',
    FullName: 'Posschain (POSS)',
  },
  XODEX: {
    Name: 'XODEX',
    Symbol: 'XODEX',
    CoinName: 'Xodex',
    FullName: 'Xodex (XODEX)',
  },
  HAO: {
    Name: 'HAO',
    Symbol: 'HAO',
    CoinName: 'HistoryDAO',
    FullName: 'HistoryDAO (HAO)',
  },
  LINU: {
    Name: 'LINU',
    Symbol: 'LINU',
    CoinName: 'Luna Inu',
    FullName: 'Luna Inu (LINU)',
  },
  BGS: {
    Name: 'BGS',
    Symbol: 'BGS',
    CoinName: 'Battle of Guardians Share',
    FullName: 'Battle of Guardians Share (BGS)',
  },
  CNFI: {
    Name: 'CNFI',
    Symbol: 'CNFI',
    CoinName: 'Connect Financial',
    FullName: 'Connect Financial (CNFI)',
  },
  YEON: {
    Name: 'YEON',
    Symbol: 'YEON',
    CoinName: 'Yeon',
    FullName: 'Yeon (YEON)',
  },
  MNDE: {
    Name: 'MNDE',
    Symbol: 'MNDE',
    CoinName: 'Marinade',
    FullName: 'Marinade (MNDE)',
  },
  LUNG: {
    Name: 'LUNG',
    Symbol: 'LUNG',
    CoinName: 'LunaGens',
    FullName: 'LunaGens (LUNG)',
  },
  LUXY: {
    Name: 'LUXY',
    Symbol: 'LUXY',
    CoinName: 'Luxy',
    FullName: 'Luxy (LUXY)',
  },
  FLOOR: {
    Name: 'FLOOR',
    Symbol: 'FLOOR',
    CoinName: 'FloorDAO',
    FullName: 'FloorDAO (FLOOR)',
  },
  HRTS: {
    Name: 'HRTS',
    Symbol: 'HRTS',
    CoinName: 'YellowHeart Protocol',
    FullName: 'YellowHeart Protocol (HRTS)',
  },
  BMEX: {
    Name: 'BMEX',
    Symbol: 'BMEX',
    CoinName: 'BitMEX',
    FullName: 'BitMEX (BMEX)',
  },
  UCAP: {
    Name: 'UCAP',
    Symbol: 'UCAP',
    CoinName: 'Unicap.finance',
    FullName: 'Unicap.finance (UCAP)',
  },
  PRI: {
    Name: 'PRI',
    Symbol: 'PRI',
    CoinName: 'PRIVATEUM INITIATIVE',
    FullName: 'PRIVATEUM INITIATIVE (PRI)',
  },
  IXP: {
    Name: 'IXP',
    Symbol: 'IXP',
    CoinName: 'IMPACTXPRIME',
    FullName: 'IMPACTXPRIME (IXP)',
  },
  PSL: {
    Name: 'PSL',
    Symbol: 'PSL',
    CoinName: 'Pastel',
    FullName: 'Pastel (PSL)',
  },
  TAPT: {
    Name: 'TAPT',
    Symbol: 'TAPT',
    CoinName: 'Tortuga Staked Aptos',
    FullName: 'Tortuga Staked Aptos (TAPT)',
  },
  RCCC: {
    Name: 'RCCC',
    Symbol: 'RCCC',
    CoinName: 'RCCC',
    FullName: 'RCCC (RCCC)',
  },
  RIDEMY: {
    Name: 'RIDEMY',
    Symbol: 'RIDEMY',
    CoinName: 'Ride My Car',
    FullName: 'Ride My Car (RIDEMY)',
  },
  RIDE: {
    Name: 'RIDE',
    Symbol: 'RIDE',
    CoinName: 'Holoride',
    FullName: 'Holoride (RIDE)',
  },
  ZEDTOKEN: {
    Name: 'ZEDTOKEN',
    Symbol: 'ZEDTOKEN',
    CoinName: 'Zed Token',
    FullName: 'Zed Token (ZEDTOKEN)',
  },
  SIGNAT: {
    Name: 'SIGNAT',
    Symbol: 'SIGNAT',
    CoinName: 'SignatureChain',
    FullName: 'SignatureChain (SIGNAT)',
  },
  EQUITOKEN: {
    Name: 'EQUITOKEN',
    Symbol: 'EQUITOKEN',
    CoinName: 'EQUI Token',
    FullName: 'EQUI Token (EQUITOKEN)',
  },
  EQUI: {
    Name: 'EQUI',
    Symbol: 'EQUI',
    CoinName: 'EQUI',
    FullName: 'EQUI (EQUI)',
  },
  SIGN: {
    Name: 'SIGN',
    Symbol: 'SIGN',
    CoinName: 'Signin',
    FullName: 'Signin (SIGN)',
  },
  DON: {
    Name: 'DON',
    Symbol: 'DON',
    CoinName: 'Donnie Finance',
    FullName: 'Donnie Finance (DON)',
  },
  DONK: {
    Name: 'DONK',
    Symbol: 'DONK',
    CoinName: 'Don-key',
    FullName: 'Don-key (DONK)',
  },
  GRANDCOIN: {
    Name: 'GRANDCOIN',
    Symbol: 'GRANDCOIN',
    CoinName: 'GrandCoin',
    FullName: 'GrandCoin (GRANDCOIN)',
  },
  GDC: {
    Name: 'GDC',
    Symbol: 'GDC',
    CoinName: 'Global Digital Content',
    FullName: 'Global Digital Content (GDC)',
  },
  WTF: {
    Name: 'WTF',
    Symbol: 'WTF',
    CoinName: 'Waterfall Governance',
    FullName: 'Waterfall Governance (WTF)',
  },
  WTFT: {
    Name: 'WTFT',
    Symbol: 'WTFT',
    CoinName: 'WTF Token',
    FullName: 'WTF Token (WTFT)',
  },
  APEXCOIN: {
    Name: 'APEXCOIN',
    Symbol: 'APEXCOIN',
    CoinName: 'ApexCoin',
    FullName: 'ApexCoin (APEXCOIN)',
  },
  APEX: {
    Name: 'APEX',
    Symbol: 'APEX',
    CoinName: 'ApeX Protocol',
    FullName: 'ApeX Protocol (APEX)',
  },
  COMPCOIN: {
    Name: 'COMPCOIN',
    Symbol: 'COMPCOIN',
    CoinName: 'Compcoin',
    FullName: 'Compcoin (COMPCOIN)',
  },
  DOLLARCOIN: {
    Name: 'DOLLARCOIN',
    Symbol: 'DOLLARCOIN',
    CoinName: 'DollarCoin',
    FullName: 'DollarCoin (DOLLARCOIN)',
  },
  DLC: {
    Name: 'DLC',
    Symbol: 'DLC',
    CoinName: 'Diamond Launch',
    FullName: 'Diamond Launch (DLC)',
  },
  PLAYCOIN: {
    Name: 'PLAYCOIN',
    Symbol: 'PLAYCOIN',
    CoinName: 'PlayCoin',
    FullName: 'PlayCoin (PLAYCOIN)',
  },
  STERLINGCOIN: {
    Name: 'STERLINGCOIN',
    Symbol: 'STERLINGCOIN',
    CoinName: 'SterlingCoin',
    FullName: 'SterlingCoin (STERLINGCOIN)',
  },
  TAPPINGCOIN: {
    Name: 'TAPPINGCOIN',
    Symbol: 'TAPPINGCOIN',
    CoinName: 'TappingCoin',
    FullName: 'TappingCoin (TAPPINGCOIN)',
  },
  TAP: {
    Name: 'TAP',
    Symbol: 'TAP',
    CoinName: 'TAP FANTASY',
    FullName: 'TAP FANTASY (TAP)',
  },
  ALCAZAR: {
    Name: 'ALCAZAR',
    Symbol: 'ALCAZAR',
    CoinName: 'Alcazar',
    FullName: 'Alcazar (ALCAZAR)',
  },
  AMDG: {
    Name: 'AMDG',
    Symbol: 'AMDG',
    CoinName: 'AMDG',
    FullName: 'AMDG (AMDG)',
  },
  AOK: {
    Name: 'AOK',
    Symbol: 'AOK',
    CoinName: 'AOK',
    FullName: 'AOK (AOK)',
  },
  ARTL: {
    Name: 'ARTL',
    Symbol: 'ARTL',
    CoinName: 'ARTL',
    FullName: 'ARTL (ARTL)',
  },
  STX: {
    Name: 'STX',
    Symbol: 'STX',
    CoinName: 'Stacks',
    FullName: 'Stacks (STX)',
  },
  USTC: {
    Name: 'USTC',
    Symbol: 'USTC',
    CoinName: 'TerraClassicUSD',
    FullName: 'TerraClassicUSD (USTC)',
  },
  FTM: {
    Name: 'FTM',
    Symbol: 'FTM',
    CoinName: 'Fantom',
    FullName: 'Fantom (FTM)',
  },
  XTZ: {
    Name: 'XTZ',
    Symbol: 'XTZ',
    CoinName: 'Tezos',
    FullName: 'Tezos (XTZ)',
  },
  ECOX: {
    Name: 'ECOX',
    Symbol: 'ECOX',
    CoinName: 'ECOx',
    FullName: 'ECOx (ECOX)',
  },
  AVL: {
    Name: 'AVL',
    Symbol: 'AVL',
    CoinName: 'Aston Villa Fan Token',
    FullName: 'Aston Villa Fan Token (AVL)',
  },
  BINGO: {
    Name: 'BINGO',
    Symbol: 'BINGO',
    CoinName: 'Tomorrowland',
    FullName: 'Tomorrowland (BINGO)',
  },
  CMQ: {
    Name: 'CMQ',
    Symbol: 'CMQ',
    CoinName: 'Communique',
    FullName: 'Communique (CMQ)',
  },
  CTS: {
    Name: 'CTS',
    Symbol: 'CTS',
    CoinName: 'Citrus',
    FullName: 'Citrus (CTS)',
  },
  DOTR: {
    Name: 'DOTR',
    Symbol: 'DOTR',
    CoinName: 'Cydotori',
    FullName: 'Cydotori (DOTR)',
  },
  FRNT: {
    Name: 'FRNT',
    Symbol: 'FRNT',
    CoinName: 'Final Frontier',
    FullName: 'Final Frontier (FRNT)',
  },
  HOWL: {
    Name: 'HOWL',
    Symbol: 'HOWL',
    CoinName: 'Coyote',
    FullName: 'Coyote (HOWL)',
  },
  LUFC: {
    Name: 'LUFC',
    Symbol: 'LUFC',
    CoinName: 'Leeds United Fan Token',
    FullName: 'Leeds United Fan Token (LUFC)',
  },
  OKB: {
    Name: 'OKB',
    Symbol: 'OKB',
    CoinName: 'OKB',
    FullName: 'OKB (OKB)',
  },
  OKT: {
    Name: 'OKT',
    Symbol: 'OKT',
    CoinName: 'OKC Token',
    FullName: 'OKC Token (OKT)',
  },
  SMBR: {
    Name: 'SMBR',
    Symbol: 'SMBR',
    CoinName: 'Sombra',
    FullName: 'Sombra (SMBR)',
  },
  MEMORYCOIN: {
    Name: 'MEMORYCOIN',
    Symbol: 'MEMORYCOIN',
    CoinName: 'MemoryCoin',
    FullName: 'MemoryCoin (MEMORYCOIN)',
  },
  MMC: {
    Name: 'MMC',
    Symbol: 'MMC',
    CoinName: 'Monopoly Millionaire Control',
    FullName: 'Monopoly Millionaire Control (MMC)',
  },
  MONAIZE: {
    Name: 'MONAIZE',
    Symbol: 'MONAIZE',
    CoinName: 'Monaize',
    FullName: 'Monaize (MONAIZE)',
  },
  MNZ: {
    Name: 'MNZ',
    Symbol: 'MNZ',
    CoinName: 'Menzy',
    FullName: 'Menzy (MNZ)',
  },
  MYTHT: {
    Name: 'MYTHT',
    Symbol: 'MYTHT',
    CoinName: 'Myth Token',
    FullName: 'Myth Token (MYTHT)',
  },
  MYTH: {
    Name: 'MYTH',
    Symbol: 'MYTH',
    CoinName: 'Mythos',
    FullName: 'Mythos (MYTH)',
  },
  CRYSTALCLEAR: {
    Name: 'CRYSTALCLEAR',
    Symbol: 'CRYSTALCLEAR',
    CoinName: 'Crystal Clear Token',
    FullName: 'Crystal Clear Token (CRYSTALCLEAR)',
  },
  COMMUNITYCOIN: {
    Name: 'COMMUNITYCOIN',
    Symbol: 'COMMUNITYCOIN',
    CoinName: 'Community Coin',
    FullName: 'Community Coin (COMMUNITYCOIN)',
  },
  COC: {
    Name: 'COC',
    Symbol: 'COC',
    CoinName: 'Coin of the champions',
    FullName: 'Coin of the champions (COC)',
  },
  SOMNIUM: {
    Name: 'SOMNIUM',
    Symbol: 'SOMNIUM',
    CoinName: 'Somnium Space CUBEs',
    FullName: 'Somnium Space CUBEs (SOMNIUM)',
  },
  CUBE: {
    Name: 'CUBE',
    Symbol: 'CUBE',
    CoinName: 'Cube Network',
    FullName: 'Cube Network (CUBE)',
  },
  LTC: {
    Name: 'LTC',
    Symbol: 'LTC',
    CoinName: 'Litecoin',
    FullName: 'Litecoin (LTC)',
  },
  NINO: {
    Name: 'NINO',
    Symbol: 'NINO',
    CoinName: 'Ninneko',
    FullName: 'Ninneko (NINO)',
  },
  NPAS: {
    Name: 'NPAS',
    Symbol: 'NPAS',
    CoinName: 'New Paradigm Assets Solution',
    FullName: 'New Paradigm Assets Solution (NPAS)',
  },
  NCDT: {
    Name: 'NCDT',
    Symbol: 'NCDT',
    CoinName: 'Nuco.Cloud',
    FullName: 'Nuco.Cloud (NCDT)',
  },
  MF: {
    Name: 'MF',
    Symbol: 'MF',
    CoinName: 'MetaFighter',
    FullName: 'MetaFighter (MF)',
  },
  METAV: {
    Name: 'METAV',
    Symbol: 'METAV',
    CoinName: 'MetaVPad',
    FullName: 'MetaVPad (METAV)',
  },
  CATHEON: {
    Name: 'CATHEON',
    Symbol: 'CATHEON',
    CoinName: 'Catheon Gaming',
    FullName: 'Catheon Gaming (CATHEON)',
  },
  POO: {
    Name: 'POO',
    Symbol: 'POO',
    CoinName: 'POOMOON',
    FullName: 'POOMOON (POO)',
  },
  FTT: {
    Name: 'FTT',
    Symbol: 'FTT',
    CoinName: 'FTX Token',
    FullName: 'FTX Token (FTT)',
  },
  PRTG: {
    Name: 'PRTG',
    Symbol: 'PRTG',
    CoinName: 'Pre-Retogeum',
    FullName: 'Pre-Retogeum (PRTG)',
  },
  RB: {
    Name: 'RB',
    Symbol: 'RB',
    CoinName: 'REBorn',
    FullName: 'REBorn (RB)',
  },
  RBD: {
    Name: 'RBD',
    Symbol: 'RBD',
    CoinName: 'Rubidium',
    FullName: 'Rubidium (RBD)',
  },
  RCG: {
    Name: 'RCG',
    Symbol: 'RCG',
    CoinName: 'Recharge',
    FullName: 'Recharge (RCG)',
  },
  RVLNG: {
    Name: 'RVLNG',
    Symbol: 'RVLNG',
    CoinName: 'RevolutionGames',
    FullName: 'RevolutionGames (RVLNG)',
  },
  RXT: {
    Name: 'RXT',
    Symbol: 'RXT',
    CoinName: 'RIMAUNANGIS',
    FullName: 'RIMAUNANGIS (RXT)',
  },
  ZFM: {
    Name: 'ZFM',
    Symbol: 'ZFM',
    CoinName: 'ZFMCOIN',
    FullName: 'ZFMCOIN (ZFM)',
  },
  RENDOGE: {
    Name: 'RENDOGE',
    Symbol: 'RENDOGE',
    CoinName: 'renDOGE',
    FullName: 'renDOGE (RENDOGE)',
  },
  COUNOS: {
    Name: 'COUNOS',
    Symbol: 'COUNOS',
    CoinName: 'Counos Coin',
    FullName: 'Counos Coin (COUNOS)',
  },
  IPX: {
    Name: 'IPX',
    Symbol: 'IPX',
    CoinName: 'InpulseX',
    FullName: 'InpulseX (IPX)',
  },
  RDX: {
    Name: 'RDX',
    Symbol: 'RDX',
    CoinName: 'Redux Protocol',
    FullName: 'Redux Protocol (RDX)',
  },
  DZAR: {
    Name: 'DZAR',
    Symbol: 'DZAR',
    CoinName: 'Digital Rand',
    FullName: 'Digital Rand (DZAR)',
  },
  LNT: {
    Name: 'LNT',
    Symbol: 'LNT',
    CoinName: 'Lottonation',
    FullName: 'Lottonation (LNT)',
  },
  NFTT: {
    Name: 'NFTT',
    Symbol: 'NFTT',
    CoinName: 'NFT',
    FullName: 'NFT (NFTT)',
  },
  MOVE: {
    Name: 'MOVE',
    Symbol: 'MOVE',
    CoinName: 'MarketMove',
    FullName: 'MarketMove (MOVE)',
  },
  HARD: {
    Name: 'HARD',
    Symbol: 'HARD',
    CoinName: 'Kava Lend',
    FullName: 'Kava Lend (HARD)',
  },
  IQ: {
    Name: 'IQ',
    Symbol: 'IQ',
    CoinName: 'IQ',
    FullName: 'IQ (IQ)',
  },
  DIE: {
    Name: 'DIE',
    Symbol: 'DIE',
    CoinName: 'Die Protocol',
    FullName: 'Die Protocol (DIE)',
  },
  COX: {
    Name: 'COX',
    Symbol: 'COX',
    CoinName: 'CobraCoin',
    FullName: 'CobraCoin (COX)',
  },
  EXIT: {
    Name: 'EXIT',
    Symbol: 'EXIT',
    CoinName: 'ExitCoin',
    FullName: 'ExitCoin (EXIT)',
  },
  GOKU: {
    Name: 'GOKU',
    Symbol: 'GOKU',
    CoinName: 'Goku',
    FullName: 'Goku (GOKU)',
  },
  H2ON: {
    Name: 'H2ON',
    Symbol: 'H2ON',
    CoinName: 'H2O Securities',
    FullName: 'H2O Securities (H2ON)',
  },
  LITHO: {
    Name: 'LITHO',
    Symbol: 'LITHO',
    CoinName: 'Lithosphere',
    FullName: 'Lithosphere (LITHO)',
  },
  RTC: {
    Name: 'RTC',
    Symbol: 'RTC',
    CoinName: 'Reltime',
    FullName: 'Reltime (RTC)',
  },
  MMAI: {
    Name: 'MMAI',
    Symbol: 'MMAI',
    CoinName: 'MetamonkeyAi',
    FullName: 'MetamonkeyAi (MMAI)',
  },
  ZKP: {
    Name: 'ZKP',
    Symbol: 'ZKP',
    CoinName: 'Panther Protocol',
    FullName: 'Panther Protocol (ZKP)',
  },
  STRIP: {
    Name: 'STRIP',
    Symbol: 'STRIP',
    CoinName: 'Stripto',
    FullName: 'Stripto (STRIP)',
  },
  BAT: {
    Name: 'BAT',
    Symbol: 'BAT',
    CoinName: 'Basic Attention Token',
    FullName: 'Basic Attention Token (BAT)',
  },
  SHIB: {
    Name: 'SHIB',
    Symbol: 'SHIB',
    CoinName: 'Shiba Inu',
    FullName: 'Shiba Inu (SHIB)',
  },
  TONCOIN: {
    Name: 'TONCOIN',
    Symbol: 'TONCOIN',
    CoinName: 'The Open Network',
    FullName: 'The Open Network (TONCOIN)',
  },
  XCN: {
    Name: 'XCN',
    Symbol: 'XCN',
    CoinName: 'Chain',
    FullName: 'Chain (XCN)',
  },
  BTT: {
    Name: 'BTT',
    Symbol: 'BTT',
    CoinName: 'BitTorrent',
    FullName: 'BitTorrent (BTT)',
  },
  GMX: {
    Name: 'GMX',
    Symbol: 'GMX',
    CoinName: 'GMX',
    FullName: 'GMX (GMX)',
  },
  MV: {
    Name: 'MV',
    Symbol: 'MV',
    CoinName: 'GensoKishi Metaverse',
    FullName: 'GensoKishi Metaverse (MV)',
  },
  RPL: {
    Name: 'RPL',
    Symbol: 'RPL',
    CoinName: 'RocketPool',
    FullName: 'RocketPool (RPL)',
  },
  NXTT: {
    Name: 'NXTT',
    Symbol: 'NXTT',
    CoinName: 'Next Earth',
    FullName: 'Next Earth (NXTT)',
  },
  FUSE: {
    Name: 'FUSE',
    Symbol: 'FUSE',
    CoinName: 'Fuse Network Token',
    FullName: 'Fuse Network Token (FUSE)',
  },
  BONE: {
    Name: 'BONE',
    Symbol: 'BONE',
    CoinName: 'Bone ShibaSwap',
    FullName: 'Bone ShibaSwap (BONE)',
  },
  DAC: {
    Name: 'DAC',
    Symbol: 'DAC',
    CoinName: 'Davinci Coin',
    FullName: 'Davinci Coin (DAC)',
  },
  ZEON: {
    Name: 'ZEON',
    Symbol: 'ZEON',
    CoinName: 'Zeon Network',
    FullName: 'Zeon Network (ZEON)',
  },
  VERI: {
    Name: 'VERI',
    Symbol: 'VERI',
    CoinName: 'Veritaseum',
    FullName: 'Veritaseum (VERI)',
  },
  WEMIX: {
    Name: 'WEMIX',
    Symbol: 'WEMIX',
    CoinName: 'WEMIX',
    FullName: 'WEMIX (WEMIX)',
  },
  DEXE: {
    Name: 'DEXE',
    Symbol: 'DEXE',
    CoinName: 'DeXe',
    FullName: 'DeXe (DEXE)',
  },
  KAS: {
    Name: 'KAS',
    Symbol: 'KAS',
    CoinName: 'Kaspa',
    FullName: 'Kaspa (KAS)',
  },
  CEEK: {
    Name: 'CEEK',
    Symbol: 'CEEK',
    CoinName: 'CEEK Smart VR Token',
    FullName: 'CEEK Smart VR Token (CEEK)',
  },
  RACA: {
    Name: 'RACA',
    Symbol: 'RACA',
    CoinName: 'Radio Caca',
    FullName: 'Radio Caca (RACA)',
  },
  BFC: {
    Name: 'BFC',
    Symbol: 'BFC',
    CoinName: 'Bifrost',
    FullName: 'Bifrost (BFC)',
  },
  HUM: {
    Name: 'HUM',
    Symbol: 'HUM',
    CoinName: 'Humanscape',
    FullName: 'Humanscape (HUM)',
  },
  CORE: {
    Name: 'CORE',
    Symbol: 'CORE',
    CoinName: 'cVault.finance',
    FullName: 'cVault.finance (CORE)',
  },
  POLYX: {
    Name: 'POLYX',
    Symbol: 'POLYX',
    CoinName: 'Polymesh',
    FullName: 'Polymesh (POLYX)',
  },
  VAI: {
    Name: 'VAI',
    Symbol: 'VAI',
    CoinName: 'Vai',
    FullName: 'Vai (VAI)',
  },
  QUACK: {
    Name: 'QUACK',
    Symbol: 'QUACK',
    CoinName: 'Rich Quack',
    FullName: 'Rich Quack (QUACK)',
  },
  SURE: {
    Name: 'SURE',
    Symbol: 'SURE',
    CoinName: 'inSure',
    FullName: 'inSure (SURE)',
  },
  ORC: {
    Name: 'ORC',
    Symbol: 'ORC',
    CoinName: 'Orbit Chain',
    FullName: 'Orbit Chain (ORC)',
  },
  OUSD: {
    Name: 'OUSD',
    Symbol: 'OUSD',
    CoinName: 'Origin Dollar',
    FullName: 'Origin Dollar (OUSD)',
  },
  COL: {
    Name: 'COL',
    Symbol: 'COL',
    CoinName: 'Clash of Lilliput',
    FullName: 'Clash of Lilliput (COL)',
  },
  AXEL: {
    Name: 'AXEL',
    Symbol: 'AXEL',
    CoinName: 'AXEL',
    FullName: 'AXEL (AXEL)',
  },
  CCD: {
    Name: 'CCD',
    Symbol: 'CCD',
    CoinName: 'Concordium',
    FullName: 'Concordium (CCD)',
  },
  BWO: {
    Name: 'BWO',
    Symbol: 'BWO',
    CoinName: 'Battle World',
    FullName: 'Battle World (BWO)',
  },
  SAITAMA: {
    Name: 'SAITAMA',
    Symbol: 'SAITAMA',
    CoinName: 'Saitama Inu',
    FullName: 'Saitama Inu (SAITAMA)',
  },
  ASD: {
    Name: 'ASD',
    Symbol: 'ASD',
    CoinName: 'AscendEX Token',
    FullName: 'AscendEX Token (ASD)',
  },
  MUSD: {
    Name: 'MUSD',
    Symbol: 'MUSD',
    CoinName: 'mStable USD',
    FullName: 'mStable USD (MUSD)',
  },
  DIVI: {
    Name: 'DIVI',
    Symbol: 'DIVI',
    CoinName: 'Divi Project',
    FullName: 'Divi Project (DIVI)',
  },
  GTN: {
    Name: 'GTN',
    Symbol: 'GTN',
    CoinName: 'GlitzKoin',
    FullName: 'GlitzKoin (GTN)',
  },
  ADA: {
    Name: 'ADA',
    Symbol: 'ADA',
    CoinName: 'Cardano',
    FullName: 'Cardano (ADA)',
  },
  SSX: {
    Name: 'SSX',
    Symbol: 'SSX',
    CoinName: 'SOMESING',
    FullName: 'SOMESING (SSX)',
  },
  BUSD: {
    Name: 'BUSD',
    Symbol: 'BUSD',
    CoinName: 'BUSD',
    FullName: 'BUSD (BUSD)',
  },
  XCAD: {
    Name: 'XCAD',
    Symbol: 'XCAD',
    CoinName: 'XCAD Network',
    FullName: 'XCAD Network (XCAD)',
  },
  AXS: {
    Name: 'AXS',
    Symbol: 'AXS',
    CoinName: 'Axie Infinity Shards',
    FullName: 'Axie Infinity Shards (AXS)',
  },
  ANY: {
    Name: 'ANY',
    Symbol: 'ANY',
    CoinName: 'Anyswap',
    FullName: 'Anyswap (ANY)',
  },
  HUSD: {
    Name: 'HUSD',
    Symbol: 'HUSD',
    CoinName: 'HUSD',
    FullName: 'HUSD (HUSD)',
  },
  LEVER: {
    Name: 'LEVER',
    Symbol: 'LEVER',
    CoinName: 'LeverFi',
    FullName: 'LeverFi (LEVER)',
  },
  HSF: {
    Name: 'HSF',
    Symbol: 'HSF',
    CoinName: 'Hillstone Finance',
    FullName: 'Hillstone Finance (HSF)',
  },
  QNT: {
    Name: 'QNT',
    Symbol: 'QNT',
    CoinName: 'Quant',
    FullName: 'Quant (QNT)',
  },
  SOL: {
    Name: 'SOL',
    Symbol: 'SOL',
    CoinName: 'Solana',
    FullName: 'Solana (SOL)',
  },
  UFO: {
    Name: 'UFO',
    Symbol: 'UFO',
    CoinName: 'UFO Gaming',
    FullName: 'UFO Gaming (UFO)',
  },
  GXC: {
    Name: 'GXC',
    Symbol: 'GXC',
    CoinName: 'GXChain',
    FullName: 'GXChain (GXC)',
  },
  VEGA: {
    Name: 'VEGA',
    Symbol: 'VEGA',
    CoinName: 'Vega Protocol',
    FullName: 'Vega Protocol (VEGA)',
  },
  DFG: {
    Name: 'DFG',
    Symbol: 'DFG',
    CoinName: 'Defigram',
    FullName: 'Defigram (DFG)',
  },
  USDK: {
    Name: 'USDK',
    Symbol: 'USDK',
    CoinName: 'USDK',
    FullName: 'USDK (USDK)',
  },
  OP: {
    Name: 'OP',
    Symbol: 'OP',
    CoinName: 'Optimism',
    FullName: 'Optimism (OP)',
  },
  ICHI: {
    Name: 'ICHI',
    Symbol: 'ICHI',
    CoinName: 'ICHI',
    FullName: 'ICHI (ICHI)',
  },
  EUROC: {
    Name: 'EUROC',
    Symbol: 'EUROC',
    CoinName: 'Euro Coin',
    FullName: 'Euro Coin (EUROC)',
  },
  YOOSHI: {
    Name: 'YOOSHI',
    Symbol: 'YOOSHI',
    CoinName: 'YooShi',
    FullName: 'YooShi (YOOSHI)',
  },
  LON: {
    Name: 'LON',
    Symbol: 'LON',
    CoinName: 'Tokenlon',
    FullName: 'Tokenlon (LON)',
  },
  CBK: {
    Name: 'CBK',
    Symbol: 'CBK',
    CoinName: 'Cobak Token',
    FullName: 'Cobak Token (CBK)',
  },
  VRSC: {
    Name: 'VRSC',
    Symbol: 'VRSC',
    CoinName: 'Verus Coin',
    FullName: 'Verus Coin (VRSC)',
  },
  DXD: {
    Name: 'DXD',
    Symbol: 'DXD',
    CoinName: 'DXdao',
    FullName: 'DXdao (DXD)',
  },
  CHO: {
    Name: 'CHO',
    Symbol: 'CHO',
    CoinName: 'Choise',
    FullName: 'Choise (CHO)',
  },
  ALPINE: {
    Name: 'ALPINE',
    Symbol: 'ALPINE',
    CoinName: 'Alpine F1 Team Fan Token',
    FullName: 'Alpine F1 Team Fan Token (ALPINE)',
  },
  CHP: {
    Name: 'CHP',
    Symbol: 'CHP',
    CoinName: 'CoinPoker Token',
    FullName: 'CoinPoker Token (CHP)',
  },
  LINA: {
    Name: 'LINA',
    Symbol: 'LINA',
    CoinName: 'Linear',
    FullName: 'Linear (LINA)',
  },
  SOFI: {
    Name: 'SOFI',
    Symbol: 'SOFI',
    CoinName: 'RAI Finance',
    FullName: 'RAI Finance (SOFI)',
  },
  CULT: {
    Name: 'CULT',
    Symbol: 'CULT',
    CoinName: 'Cult DAO',
    FullName: 'Cult DAO (CULT)',
  },
  QRDO: {
    Name: 'QRDO',
    Symbol: 'QRDO',
    CoinName: 'Qredo',
    FullName: 'Qredo (QRDO)',
  },
  FWT: {
    Name: 'FWT',
    Symbol: 'FWT',
    CoinName: 'Freeway Token',
    FullName: 'Freeway Token (FWT)',
  },
  SWEAT: {
    Name: 'SWEAT',
    Symbol: 'SWEAT',
    CoinName: 'Sweat Economy',
    FullName: 'Sweat Economy (SWEAT)',
  },
  SANTOS: {
    Name: 'SANTOS',
    Symbol: 'SANTOS',
    CoinName: 'Santos FC Fan Token',
    FullName: 'Santos FC Fan Token (SANTOS)',
  },
  REI: {
    Name: 'REI',
    Symbol: 'REI',
    CoinName: 'REI Network',
    FullName: 'REI Network (REI)',
  },
  STR: {
    Name: 'STR',
    Symbol: 'STR',
    CoinName: 'Sourceless',
    FullName: 'Sourceless (STR)',
  },
  XCONSOL: {
    Name: 'XCONSOL',
    Symbol: 'XCONSOL',
    CoinName: 'X-Consoles',
    FullName: 'X-Consoles (XCONSOL)',
  },
  EWTT: {
    Name: 'EWTT',
    Symbol: 'EWTT',
    CoinName: 'Ecowatt',
    FullName: 'Ecowatt (EWTT)',
  },
  RECKOON: {
    Name: 'RECKOON',
    Symbol: 'RECKOON',
    CoinName: 'Reckoon',
    FullName: 'Reckoon (RECKOON)',
  },
  KGC: {
    Name: 'KGC',
    Symbol: 'KGC',
    CoinName: 'Krypton Galaxy Coin',
    FullName: 'Krypton Galaxy Coin (KGC)',
  },
  MIDASDOLLAR: {
    Name: 'MIDASDOLLAR',
    Symbol: 'MIDASDOLLAR',
    CoinName: 'Midas Dollar Share',
    FullName: 'Midas Dollar Share (MIDASDOLLAR)',
  },
  MIDAS: {
    Name: 'MIDAS',
    Symbol: 'MIDAS',
    CoinName: 'Midas',
    FullName: 'Midas (MIDAS)',
  },
  STRM: {
    Name: 'STRM',
    Symbol: 'STRM',
    CoinName: 'StreamCoin',
    FullName: 'StreamCoin (STRM)',
  },
  HPAY: {
    Name: 'HPAY',
    Symbol: 'HPAY',
    CoinName: 'HedgePay',
    FullName: 'HedgePay (HPAY)',
  },
  SPXC: {
    Name: 'SPXC',
    Symbol: 'SPXC',
    CoinName: 'SpaceXCoin',
    FullName: 'SpaceXCoin (SPXC)',
  },
  GEOJ: {
    Name: 'GEOJ',
    Symbol: 'GEOJ',
    CoinName: 'Geojam',
    FullName: 'Geojam (GEOJ)',
  },
  BTC: {
    Name: 'BTC',
    Symbol: 'BTC',
    CoinName: 'Bitcoin',
    FullName: 'Bitcoin (BTC)',
  },
  USDT: {
    Name: 'USDT',
    Symbol: 'USDT',
    CoinName: 'Tether',
    FullName: 'Tether (USDT)',
  },
  USDC: {
    Name: 'USDC',
    Symbol: 'USDC',
    CoinName: 'USD Coin',
    FullName: 'USD Coin (USDC)',
  },
  BNB: {
    Name: 'BNB',
    Symbol: 'BNB',
    CoinName: 'Binance Coin',
    FullName: 'Binance Coin (BNB)',
  },
  XRP: {
    Name: 'XRP',
    Symbol: 'XRP',
    CoinName: 'XRP',
    FullName: 'XRP (XRP)',
  },
  MATIC: {
    Name: 'MATIC',
    Symbol: 'MATIC',
    CoinName: 'Polygon',
    FullName: 'Polygon (MATIC)',
  },
  VGX: {
    Name: 'VGX',
    Symbol: 'VGX',
    CoinName: 'Voyager Token',
    FullName: 'Voyager Token (VGX)',
  },
  IOTX: {
    Name: 'IOTX',
    Symbol: 'IOTX',
    CoinName: 'IoTeX Network',
    FullName: 'IoTeX Network (IOTX)',
  },
  DOT: {
    Name: 'DOT',
    Symbol: 'DOT',
    CoinName: 'Polkadot',
    FullName: 'Polkadot (DOT)',
  },
  TRX: {
    Name: 'TRX',
    Symbol: 'TRX',
    CoinName: 'TRON',
    FullName: 'TRON (TRX)',
  },
  PORTO: {
    Name: 'PORTO',
    Symbol: 'PORTO',
    CoinName: 'FC Porto',
    FullName: 'FC Porto (PORTO)',
  },
  AQT: {
    Name: 'AQT',
    Symbol: 'AQT',
    CoinName: 'Alpha Quark Token',
    FullName: 'Alpha Quark Token (AQT)',
  },
  MNGO: {
    Name: 'MNGO',
    Symbol: 'MNGO',
    CoinName: 'Mango protocol',
    FullName: 'Mango protocol (MNGO)',
  },
  BITCI: {
    Name: 'BITCI',
    Symbol: 'BITCI',
    CoinName: 'Bitcicoin',
    FullName: 'Bitcicoin (BITCI)',
  },
  ROUTE: {
    Name: 'ROUTE',
    Symbol: 'ROUTE',
    CoinName: 'Router Protocol',
    FullName: 'Router Protocol (ROUTE)',
  },
  '1ECO': {
    Name: '1ECO',
    Symbol: '1ECO',
    CoinName: '1eco',
    FullName: '1eco (1ECO)',
  },
  CITY: {
    Name: 'CITY',
    Symbol: 'CITY',
    CoinName: 'Manchester City Fan Token',
    FullName: 'Manchester City Fan Token (CITY)',
  },
  TPT: {
    Name: 'TPT',
    Symbol: 'TPT',
    CoinName: 'Token Pocket',
    FullName: 'Token Pocket (TPT)',
  },
  MEV: {
    Name: 'MEV',
    Symbol: 'MEV',
    CoinName: 'MEVerse',
    FullName: 'MEVerse (MEV)',
  },
  PIT: {
    Name: 'PIT',
    Symbol: 'PIT',
    CoinName: 'Pitbull',
    FullName: 'Pitbull (PIT)',
  },
  NMC: {
    Name: 'NMC',
    Symbol: 'NMC',
    CoinName: 'Namecoin',
    FullName: 'Namecoin (NMC)',
  },
  BIDR: {
    Name: 'BIDR',
    Symbol: 'BIDR',
    CoinName: 'Binance IDR Stable Coin',
    FullName: 'Binance IDR Stable Coin (BIDR)',
  },
  MET: {
    Name: 'MET',
    Symbol: 'MET',
    CoinName: 'Metronome',
    FullName: 'Metronome (MET)',
  },
  CPOOL: {
    Name: 'CPOOL',
    Symbol: 'CPOOL',
    CoinName: 'Clearpool',
    FullName: 'Clearpool (CPOOL)',
  },
  PROS: {
    Name: 'PROS',
    Symbol: 'PROS',
    CoinName: 'Prosper',
    FullName: 'Prosper (PROS)',
  },
  BLCT: {
    Name: 'BLCT',
    Symbol: 'BLCT',
    CoinName: 'Bloomzed Loyalty Club Ticket',
    FullName: 'Bloomzed Loyalty Club Ticket (BLCT)',
  },
  BANANA: {
    Name: 'BANANA',
    Symbol: 'BANANA',
    CoinName: 'ApeSwap',
    FullName: 'ApeSwap (BANANA)',
  },
  CRTS: {
    Name: 'CRTS',
    Symbol: 'CRTS',
    CoinName: 'Cratos',
    FullName: 'Cratos (CRTS)',
  },
  STAT: {
    Name: 'STAT',
    Symbol: 'STAT',
    CoinName: 'STAT',
    FullName: 'STAT (STAT)',
  },
  HAPI: {
    Name: 'HAPI',
    Symbol: 'HAPI',
    CoinName: 'HAPI',
    FullName: 'HAPI (HAPI)',
  },
  URUS: {
    Name: 'URUS',
    Symbol: 'URUS',
    CoinName: 'Urus Token',
    FullName: 'Urus Token (URUS)',
  },
  QOM: {
    Name: 'QOM',
    Symbol: 'QOM',
    CoinName: 'Shiba Predator',
    FullName: 'Shiba Predator (QOM)',
  },
  ALT: {
    Name: 'ALT',
    Symbol: 'ALT',
    CoinName: 'Alitas',
    FullName: 'Alitas (ALT)',
  },
  IHF: {
    Name: 'IHF',
    Symbol: 'IHF',
    CoinName: 'Invictus Hyperion Fund',
    FullName: 'Invictus Hyperion Fund (IHF)',
  },
  SDAO: {
    Name: 'SDAO',
    Symbol: 'SDAO',
    CoinName: 'SingularityDAO',
    FullName: 'SingularityDAO (SDAO)',
  },
  OXEN: {
    Name: 'OXEN',
    Symbol: 'OXEN',
    CoinName: 'Oxen',
    FullName: 'Oxen (OXEN)',
  },
  PPC: {
    Name: 'PPC',
    Symbol: 'PPC',
    CoinName: 'PeerCoin',
    FullName: 'PeerCoin (PPC)',
  },
  NCT: {
    Name: 'NCT',
    Symbol: 'NCT',
    CoinName: 'PolySwarm',
    FullName: 'PolySwarm (NCT)',
  },
  SIX: {
    Name: 'SIX',
    Symbol: 'SIX',
    CoinName: 'SIX Network',
    FullName: 'SIX Network (SIX)',
  },
  DPR: {
    Name: 'DPR',
    Symbol: 'DPR',
    CoinName: 'Deeper Network',
    FullName: 'Deeper Network (DPR)',
  },
  BABY: {
    Name: 'BABY',
    Symbol: 'BABY',
    CoinName: 'BabySwap',
    FullName: 'BabySwap (BABY)',
  },
  AFC: {
    Name: 'AFC',
    Symbol: 'AFC',
    CoinName: 'Arsenal Fan Token',
    FullName: 'Arsenal Fan Token (AFC)',
  },
  ERC20: {
    Name: 'ERC20',
    Symbol: 'ERC20',
    CoinName: 'ERC20',
    FullName: 'ERC20 (ERC20)',
  },
  ZKS: {
    Name: 'ZKS',
    Symbol: 'ZKS',
    CoinName: 'ZKSpace',
    FullName: 'ZKSpace (ZKS)',
  },
  XDCE: {
    Name: 'XDCE',
    Symbol: 'XDCE',
    CoinName: 'XinFin Coin',
    FullName: 'XinFin Coin (XDCE)',
  },
  STARSH: {
    Name: 'STARSH',
    Symbol: 'STARSH',
    CoinName: 'StarShip Token',
    FullName: 'StarShip Token (STARSH)',
  },
  PLATINUM: {
    Name: 'PLATINUM',
    Symbol: 'PLATINUM',
    CoinName: 'Platinum',
    FullName: 'Platinum (PLATINUM)',
  },
  PLAT: {
    Name: 'PLAT',
    Symbol: 'PLAT',
    CoinName: 'BitGuild PLAT',
    FullName: 'BitGuild PLAT (PLAT)',
  },
  SOONCOIN: {
    Name: 'SOONCOIN',
    Symbol: 'SOONCOIN',
    CoinName: 'SoonCoin',
    FullName: 'SoonCoin (SOONCOIN)',
  },
  BITBOOST: {
    Name: 'BITBOOST',
    Symbol: 'BITBOOST',
    CoinName: 'BitBoost',
    FullName: 'BitBoost (BITBOOST)',
  },
  BBT: {
    Name: 'BBT',
    Symbol: 'BBT',
    CoinName: 'BitBook',
    FullName: 'BitBook (BBT)',
  },
  JUV: {
    Name: 'JUV',
    Symbol: 'JUV',
    CoinName: 'Juventus Fan Token',
    FullName: 'Juventus Fan Token (JUV)',
  },
  DACXI: {
    Name: 'DACXI',
    Symbol: 'DACXI',
    CoinName: 'Dacxi',
    FullName: 'Dacxi (DACXI)',
  },
  FUND: {
    Name: 'FUND',
    Symbol: 'FUND',
    CoinName: 'Unification',
    FullName: 'Unification (FUND)',
  },
  TTT: {
    Name: 'TTT',
    Symbol: 'TTT',
    CoinName: 'The Transfer Token',
    FullName: 'The Transfer Token (TTT)',
  },
  IDRT: {
    Name: 'IDRT',
    Symbol: 'IDRT',
    CoinName: 'Rupiah Token',
    FullName: 'Rupiah Token (IDRT)',
  },
  XNC: {
    Name: 'XNC',
    Symbol: 'XNC',
    CoinName: 'Xenios',
    FullName: 'Xenios (XNC)',
  },
  OBSR: {
    Name: 'OBSR',
    Symbol: 'OBSR',
    CoinName: 'OBSERVER Coin',
    FullName: 'OBSERVER Coin (OBSR)',
  },
  ADP: {
    Name: 'ADP',
    Symbol: 'ADP',
    CoinName: 'Adappter Token',
    FullName: 'Adappter Token (ADP)',
  },
  FORTUNA: {
    Name: 'FORTUNA',
    Symbol: 'FORTUNA',
    CoinName: 'Fortuna',
    FullName: 'Fortuna (FORTUNA)',
  },
  FOTA: {
    Name: 'FOTA',
    Symbol: 'FOTA',
    CoinName: 'Fight Of The Ages',
    FullName: 'Fight Of The Ages (FOTA)',
  },
  QRL: {
    Name: 'QRL',
    Symbol: 'QRL',
    CoinName: 'Quantum Resistant Ledger',
    FullName: 'Quantum Resistant Ledger (QRL)',
  },
  GRID: {
    Name: 'GRID',
    Symbol: 'GRID',
    CoinName: 'Grid+',
    FullName: 'Grid+ (GRID)',
  },
  MCRT: {
    Name: 'MCRT',
    Symbol: 'MCRT',
    CoinName: 'MagicCraft',
    FullName: 'MagicCraft (MCRT)',
  },
  PZM: {
    Name: 'PZM',
    Symbol: 'PZM',
    CoinName: 'Prizm',
    FullName: 'Prizm (PZM)',
  },
  BTC2: {
    Name: 'BTC2',
    Symbol: 'BTC2',
    CoinName: 'Bitcoin 2',
    FullName: 'Bitcoin 2 (BTC2)',
  },
  VITE: {
    Name: 'VITE',
    Symbol: 'VITE',
    CoinName: 'VITE',
    FullName: 'VITE (VITE)',
  },
  SOMA: {
    Name: 'SOMA',
    Symbol: 'SOMA',
    CoinName: 'Soma',
    FullName: 'Soma (SOMA)',
  },
  ES: {
    Name: 'ES',
    Symbol: 'ES',
    CoinName: 'Era Swap Token',
    FullName: 'Era Swap Token (ES)',
  },
  XEP: {
    Name: 'XEP',
    Symbol: 'XEP',
    CoinName: 'Electra Protocol',
    FullName: 'Electra Protocol (XEP)',
  },
  OST: {
    Name: 'OST',
    Symbol: 'OST',
    CoinName: 'OST',
    FullName: 'OST (OST)',
  },
  RDD: {
    Name: 'RDD',
    Symbol: 'RDD',
    CoinName: 'Reddcoin',
    FullName: 'Reddcoin (RDD)',
  },
  TREEB: {
    Name: 'TREEB',
    Symbol: 'TREEB',
    CoinName: 'Retreeb',
    FullName: 'Retreeb (TREEB)',
  },
  INTER: {
    Name: 'INTER',
    Symbol: 'INTER',
    CoinName: 'Inter Milan Fan Token',
    FullName: 'Inter Milan Fan Token (INTER)',
  },
  BAN: {
    Name: 'BAN',
    Symbol: 'BAN',
    CoinName: 'Banano',
    FullName: 'Banano (BAN)',
  },
  DIO: {
    Name: 'DIO',
    Symbol: 'DIO',
    CoinName: 'Decimated',
    FullName: 'Decimated (DIO)',
  },
  IHC: {
    Name: 'IHC',
    Symbol: 'IHC',
    CoinName: 'Inflation Hedging Coin',
    FullName: 'Inflation Hedging Coin (IHC)',
  },
  TNC: {
    Name: 'TNC',
    Symbol: 'TNC',
    CoinName: 'TNC Coin',
    FullName: 'TNC Coin (TNC)',
  },
  POSI: {
    Name: 'POSI',
    Symbol: 'POSI',
    CoinName: 'Position Token',
    FullName: 'Position Token (POSI)',
  },
  XMC: {
    Name: 'XMC',
    Symbol: 'XMC',
    CoinName: 'Monero Classic',
    FullName: 'Monero Classic (XMC)',
  },
  SHPING: {
    Name: 'SHPING',
    Symbol: 'SHPING',
    CoinName: 'Shping Coin',
    FullName: 'Shping Coin (SHPING)',
  },
  NXS: {
    Name: 'NXS',
    Symbol: 'NXS',
    CoinName: 'Nexus',
    FullName: 'Nexus (NXS)',
  },
  NUM: {
    Name: 'NUM',
    Symbol: 'NUM',
    CoinName: 'Numbers Protocol',
    FullName: 'Numbers Protocol (NUM)',
  },
  NAKA: {
    Name: 'NAKA',
    Symbol: 'NAKA',
    CoinName: 'Nakamoto Games',
    FullName: 'Nakamoto Games (NAKA)',
  },
  PEAK: {
    Name: 'PEAK',
    Symbol: 'PEAK',
    CoinName: 'PEAKDEFI',
    FullName: 'PEAKDEFI (PEAK)',
  },
  ASTA: {
    Name: 'ASTA',
    Symbol: 'ASTA',
    CoinName: 'ASTA',
    FullName: 'ASTA (ASTA)',
  },
  ARIA20: {
    Name: 'ARIA20',
    Symbol: 'ARIA20',
    CoinName: 'Arianee',
    FullName: 'Arianee (ARIA20)',
  },
  CELOUSD: {
    Name: 'CELOUSD',
    Symbol: 'CELOUSD',
    CoinName: 'Celo Dollar',
    FullName: 'Celo Dollar (CELOUSD)',
  },
  OVR: {
    Name: 'OVR',
    Symbol: 'OVR',
    CoinName: 'Ovr',
    FullName: 'Ovr (OVR)',
  },
  BIOT: {
    Name: 'BIOT',
    Symbol: 'BIOT',
    CoinName: 'Bio Passport',
    FullName: 'Bio Passport (BIOT)',
  },
  VBG: {
    Name: 'VBG',
    Symbol: 'VBG',
    CoinName: 'Vibing',
    FullName: 'Vibing (VBG)',
  },
  AWC: {
    Name: 'AWC',
    Symbol: 'AWC',
    CoinName: 'Atomic Wallet Coin',
    FullName: 'Atomic Wallet Coin (AWC)',
  },
  THG: {
    Name: 'THG',
    Symbol: 'THG',
    CoinName: 'Thetan Arena',
    FullName: 'Thetan Arena (THG)',
  },
  XPX: {
    Name: 'XPX',
    Symbol: 'XPX',
    CoinName: 'ProximaX',
    FullName: 'ProximaX (XPX)',
  },
  VEE: {
    Name: 'VEE',
    Symbol: 'VEE',
    CoinName: 'BLOCKv',
    FullName: 'BLOCKv (VEE)',
  },
  SCP: {
    Name: 'SCP',
    Symbol: 'SCP',
    CoinName: 'ScPrime',
    FullName: 'ScPrime (SCP)',
  },
  BRG: {
    Name: 'BRG',
    Symbol: 'BRG',
    CoinName: 'Bridge Oracle',
    FullName: 'Bridge Oracle (BRG)',
  },
  INSTAR: {
    Name: 'INSTAR',
    Symbol: 'INSTAR',
    CoinName: 'Insights Network',
    FullName: 'Insights Network (INSTAR)',
  },
  DIME: {
    Name: 'DIME',
    Symbol: 'DIME',
    CoinName: 'DimeCoin',
    FullName: 'DimeCoin (DIME)',
  },
  ASK: {
    Name: 'ASK',
    Symbol: 'ASK',
    CoinName: 'Permission Coin',
    FullName: 'Permission Coin (ASK)',
  },
  MOF: {
    Name: 'MOF',
    Symbol: 'MOF',
    CoinName: 'Molecular Future (TRC20)',
    FullName: 'Molecular Future (TRC20) (MOF)',
  },
  FOAM: {
    Name: 'FOAM',
    Symbol: 'FOAM',
    CoinName: 'Foam',
    FullName: 'Foam (FOAM)',
  },
  BUX: {
    Name: 'BUX',
    Symbol: 'BUX',
    CoinName: 'BUX',
    FullName: 'BUX (BUX)',
  },
  CCT: {
    Name: 'CCT',
    Symbol: 'CCT',
    CoinName: 'Carbon Credit',
    FullName: 'Carbon Credit (CCT)',
  },
  ASR: {
    Name: 'ASR',
    Symbol: 'ASR',
    CoinName: 'AS Roma Fan Token',
    FullName: 'AS Roma Fan Token (ASR)',
  },
  HIBS: {
    Name: 'HIBS',
    Symbol: 'HIBS',
    CoinName: 'Hiblocks',
    FullName: 'Hiblocks (HIBS)',
  },
  VRT: {
    Name: 'VRT',
    Symbol: 'VRT',
    CoinName: 'Venus Reward Token',
    FullName: 'Venus Reward Token (VRT)',
  },
  JETCOIN: {
    Name: 'JETCOIN',
    Symbol: 'JETCOIN',
    CoinName: 'Jetcoin',
    FullName: 'Jetcoin (JETCOIN)',
  },
  JET: {
    Name: 'JET',
    Symbol: 'JET',
    CoinName: 'Jet Protocol',
    FullName: 'Jet Protocol (JET)',
  },
  TRECENTO: {
    Name: 'TRECENTO',
    Symbol: 'TRECENTO',
    CoinName: 'Trecento Blockchain Capital',
    FullName: 'Trecento Blockchain Capital (TRECENTO)',
  },
  STARLAUNCH: {
    Name: 'STARLAUNCH',
    Symbol: 'STARLAUNCH',
    CoinName: 'StarLaunch',
    FullName: 'StarLaunch (STARLAUNCH)',
  },
  STARS: {
    Name: 'STARS',
    Symbol: 'STARS',
    CoinName: 'Mogul Productions',
    FullName: 'Mogul Productions (STARS)',
  },
  TBC: {
    Name: 'TBC',
    Symbol: 'TBC',
    CoinName: 'Ten Best Coins',
    FullName: 'Ten Best Coins (TBC)',
  },
  BITCRATIC: {
    Name: 'BITCRATIC',
    Symbol: 'BITCRATIC',
    CoinName: 'Bitcratic Token',
    FullName: 'Bitcratic Token (BITCRATIC)',
  },
  BCT: {
    Name: 'BCT',
    Symbol: 'BCT',
    CoinName: 'Toucan Protocol: Base Carbon Tonne',
    FullName: 'Toucan Protocol: Base Carbon Tonne (BCT)',
  },
  BITAIR: {
    Name: 'BITAIR',
    Symbol: 'BITAIR',
    CoinName: 'Bitair',
    FullName: 'Bitair (BITAIR)',
  },
  BTCA: {
    Name: 'BTCA',
    Symbol: 'BTCA',
    CoinName: 'BITCOIN ADDITIONAL',
    FullName: 'BITCOIN ADDITIONAL (BTCA)',
  },
  ASSARA: {
    Name: 'ASSARA',
    Symbol: 'ASSARA',
    CoinName: 'ASSARA',
    FullName: 'ASSARA (ASSARA)',
  },
  ASSA: {
    Name: 'ASSA',
    Symbol: 'ASSA',
    CoinName: 'AssaPlay',
    FullName: 'AssaPlay (ASSA)',
  },
  BONK: {
    Name: 'BONK',
    Symbol: 'BONK',
    CoinName: 'Bonk',
    FullName: 'Bonk (BONK)',
  },
  MBONK: {
    Name: 'MBONK',
    Symbol: 'MBONK',
    CoinName: 'megaBonk',
    FullName: 'megaBonk (MBONK)',
  },
  REAP: {
    Name: 'REAP',
    Symbol: 'REAP',
    CoinName: 'ReapChain',
    FullName: 'ReapChain (REAP)',
  },
  EQUAD: {
    Name: 'EQUAD',
    Symbol: 'EQUAD',
    CoinName: 'Quadrant Protocol',
    FullName: 'Quadrant Protocol (EQUAD)',
  },
  '8X8': {
    Name: '8X8',
    Symbol: '8X8',
    CoinName: '8X8 Protocol',
    FullName: '8X8 Protocol (8X8)',
  },
  CCA: {
    Name: 'CCA',
    Symbol: 'CCA',
    CoinName: 'CCA',
    FullName: 'CCA (CCA)',
  },
  TACHYON: {
    Name: 'TACHYON',
    Symbol: 'TACHYON',
    CoinName: 'Tachyon Protocol',
    FullName: 'Tachyon Protocol (TACHYON)',
  },
  LEVERJ: {
    Name: 'LEVERJ',
    Symbol: 'LEVERJ',
    CoinName: 'Leverj',
    FullName: 'Leverj (LEVERJ)',
  },
  LEV: {
    Name: 'LEV',
    Symbol: 'LEV',
    CoinName: 'Levante U.D. Fan Token',
    FullName: 'Levante U.D. Fan Token (LEV)',
  },
  AZUM: {
    Name: 'AZUM',
    Symbol: 'AZUM',
    CoinName: 'Azuma Coin',
    FullName: 'Azuma Coin (AZUM)',
  },
  SDUSD: {
    Name: 'SDUSD',
    Symbol: 'SDUSD',
    CoinName: 'SDUSD',
    FullName: 'SDUSD (SDUSD)',
  },
  PHIGOLD: {
    Name: 'PHIGOLD',
    Symbol: 'PHIGOLD',
    CoinName: 'PhiGold Coin',
    FullName: 'PhiGold Coin (PHIGOLD)',
  },
  PGX: {
    Name: 'PGX',
    Symbol: 'PGX',
    CoinName: 'Pegaxy Stone',
    FullName: 'Pegaxy Stone (PGX)',
  },
  RAVENCOINC: {
    Name: 'RAVENCOINC',
    Symbol: 'RAVENCOINC',
    CoinName: 'Ravencoin Classic',
    FullName: 'Ravencoin Classic (RAVENCOINC)',
  },
  RVC: {
    Name: 'RVC',
    Symbol: 'RVC',
    CoinName: 'Revenue Coin',
    FullName: 'Revenue Coin (RVC)',
  },
  KRUGERCOIN: {
    Name: 'KRUGERCOIN',
    Symbol: 'KRUGERCOIN',
    CoinName: 'KrugerCoin',
    FullName: 'KrugerCoin (KRUGERCOIN)',
  },
  WWDOGE: {
    Name: 'WWDOGE',
    Symbol: 'WWDOGE',
    CoinName: 'Wrapped WDOGE',
    FullName: 'Wrapped WDOGE (WWDOGE)',
  },
  DC: {
    Name: 'DC',
    Symbol: 'DC',
    CoinName: 'Dogechain',
    FullName: 'Dogechain (DC)',
  },
  SUPER: {
    Name: 'SUPER',
    Symbol: 'SUPER',
    CoinName: 'SuperVerse',
    FullName: 'SuperVerse (SUPER)',
  },
  AWX: {
    Name: 'AWX',
    Symbol: 'AWX',
    CoinName: 'AurusX',
    FullName: 'AurusX (AWX)',
  },
  ARRR: {
    Name: 'ARRR',
    Symbol: 'ARRR',
    CoinName: 'Pirate Chain',
    FullName: 'Pirate Chain (ARRR)',
  },
  EXM: {
    Name: 'EXM',
    Symbol: 'EXM',
    CoinName: 'EXMO Coin',
    FullName: 'EXMO Coin (EXM)',
  },
  CNHT: {
    Name: 'CNHT',
    Symbol: 'CNHT',
    CoinName: 'Tether CNH',
    FullName: 'Tether CNH (CNHT)',
  },
  PROB: {
    Name: 'PROB',
    Symbol: 'PROB',
    CoinName: 'ProBit Token',
    FullName: 'ProBit Token (PROB)',
  },
  SWASH: {
    Name: 'SWASH',
    Symbol: 'SWASH',
    CoinName: 'Swash',
    FullName: 'Swash (SWASH)',
  },
  XDAI: {
    Name: 'XDAI',
    Symbol: 'XDAI',
    CoinName: 'XDAI',
    FullName: 'XDAI (XDAI)',
  },
  GULF: {
    Name: 'GULF',
    Symbol: 'GULF',
    CoinName: 'GulfCoin',
    FullName: 'GulfCoin (GULF)',
  },
  CBX: {
    Name: 'CBX',
    Symbol: 'CBX',
    CoinName: 'CropBytes',
    FullName: 'CropBytes (CBX)',
  },
  RAINI: {
    Name: 'RAINI',
    Symbol: 'RAINI',
    CoinName: 'Rainicorn',
    FullName: 'Rainicorn (RAINI)',
  },
  NAP: {
    Name: 'NAP',
    Symbol: 'NAP',
    CoinName: 'Napoli Fan Token',
    FullName: 'Napoli Fan Token (NAP)',
  },
  MASQ: {
    Name: 'MASQ',
    Symbol: 'MASQ',
    CoinName: 'MASQ',
    FullName: 'MASQ (MASQ)',
  },
  TOWN: {
    Name: 'TOWN',
    Symbol: 'TOWN',
    CoinName: 'Town Star',
    FullName: 'Town Star (TOWN)',
  },
  NOW: {
    Name: 'NOW',
    Symbol: 'NOW',
    CoinName: 'NOW Token',
    FullName: 'NOW Token (NOW)',
  },
  VR: {
    Name: 'VR',
    Symbol: 'VR',
    CoinName: 'Victoria',
    FullName: 'Victoria (VR)',
  },
  ALU: {
    Name: 'ALU',
    Symbol: 'ALU',
    CoinName: 'Altura',
    FullName: 'Altura (ALU)',
  },
  TEN: {
    Name: 'TEN',
    Symbol: 'TEN',
    CoinName: 'Tokenomy',
    FullName: 'Tokenomy (TEN)',
  },
  NEER: {
    Name: 'NEER',
    Symbol: 'NEER',
    CoinName: 'Metaverse.Network Pioneer',
    FullName: 'Metaverse.Network Pioneer (NEER)',
  },
  POA: {
    Name: 'POA',
    Symbol: 'POA',
    CoinName: 'Poa Network',
    FullName: 'Poa Network (POA)',
  },
  CBG: {
    Name: 'CBG',
    Symbol: 'CBG',
    CoinName: 'Chainbing',
    FullName: 'Chainbing (CBG)',
  },
  SHFT: {
    Name: 'SHFT',
    Symbol: 'SHFT',
    CoinName: 'Shyft Network',
    FullName: 'Shyft Network (SHFT)',
  },
  POOLZ: {
    Name: 'POOLZ',
    Symbol: 'POOLZ',
    CoinName: 'Poolz Finance',
    FullName: 'Poolz Finance (POOLZ)',
  },
  WWY: {
    Name: 'WWY',
    Symbol: 'WWY',
    CoinName: 'WeWay',
    FullName: 'WeWay (WWY)',
  },
  TRA: {
    Name: 'TRA',
    Symbol: 'TRA',
    CoinName: 'Trabzonspor Fan Token',
    FullName: 'Trabzonspor Fan Token (TRA)',
  },
  EPIK: {
    Name: 'EPIK',
    Symbol: 'EPIK',
    CoinName: 'EPIK Token',
    FullName: 'EPIK Token (EPIK)',
  },
  POLA: {
    Name: 'POLA',
    Symbol: 'POLA',
    CoinName: 'Polaris Share',
    FullName: 'Polaris Share (POLA)',
  },
  OG: {
    Name: 'OG',
    Symbol: 'OG',
    CoinName: 'OG Fan Token',
    FullName: 'OG Fan Token (OG)',
  },
  BITCNY: {
    Name: 'BITCNY',
    Symbol: 'BITCNY',
    CoinName: 'bitCNY',
    FullName: 'bitCNY (BITCNY)',
  },
  VEMP: {
    Name: 'VEMP',
    Symbol: 'VEMP',
    CoinName: 'vEmpire DDAO',
    FullName: 'vEmpire DDAO (VEMP)',
  },
  FWC: {
    Name: 'FWC',
    Symbol: 'FWC',
    CoinName: 'Qatar 2022',
    FullName: 'Qatar 2022 (FWC)',
  },
  TABOO: {
    Name: 'TABOO',
    Symbol: 'TABOO',
    CoinName: 'Taboo Token',
    FullName: 'Taboo Token (TABOO)',
  },
  XCHF: {
    Name: 'XCHF',
    Symbol: 'XCHF',
    CoinName: 'CryptoFranc',
    FullName: 'CryptoFranc (XCHF)',
  },
  PAC: {
    Name: 'PAC',
    Symbol: 'PAC',
    CoinName: 'PAC Protocol',
    FullName: 'PAC Protocol (PAC)',
  },
  WRLD: {
    Name: 'WRLD',
    Symbol: 'WRLD',
    CoinName: 'NFT Worlds',
    FullName: 'NFT Worlds (WRLD)',
  },
  VIDYA: {
    Name: 'VIDYA',
    Symbol: 'VIDYA',
    CoinName: 'Vidya',
    FullName: 'Vidya (VIDYA)',
  },
  KROM: {
    Name: 'KROM',
    Symbol: 'KROM',
    CoinName: 'Kromatika',
    FullName: 'Kromatika (KROM)',
  },
  ILC: {
    Name: 'ILC',
    Symbol: 'ILC',
    CoinName: 'ILCOIN',
    FullName: 'ILCOIN (ILC)',
  },
  TIFI: {
    Name: 'TIFI',
    Symbol: 'TIFI',
    CoinName: 'TiFi Token',
    FullName: 'TiFi Token (TIFI)',
  },
  CARD: {
    Name: 'CARD',
    Symbol: 'CARD',
    CoinName: 'Cardstack',
    FullName: 'Cardstack (CARD)',
  },
  STRX: {
    Name: 'STRX',
    Symbol: 'STRX',
    CoinName: 'StrikeX',
    FullName: 'StrikeX (STRX)',
  },
  SIDUS: {
    Name: 'SIDUS',
    Symbol: 'SIDUS',
    CoinName: 'Sidus',
    FullName: 'Sidus (SIDUS)',
  },
  CUMMIES: {
    Name: 'CUMMIES',
    Symbol: 'CUMMIES',
    CoinName: 'CumRocket',
    FullName: 'CumRocket (CUMMIES)',
  },
  NAV: {
    Name: 'NAV',
    Symbol: 'NAV',
    CoinName: 'NavCoin',
    FullName: 'NavCoin (NAV)',
  },
  JGN: {
    Name: 'JGN',
    Symbol: 'JGN',
    CoinName: 'Juggernaut',
    FullName: 'Juggernaut (JGN)',
  },
  SAN: {
    Name: 'SAN',
    Symbol: 'SAN',
    CoinName: 'Santiment',
    FullName: 'Santiment (SAN)',
  },
  RVLT: {
    Name: 'RVLT',
    Symbol: 'RVLT',
    CoinName: 'Revolt 2 Earn',
    FullName: 'Revolt 2 Earn (RVLT)',
  },
  OTO: {
    Name: 'OTO',
    Symbol: 'OTO',
    CoinName: 'OTOCASH',
    FullName: 'OTOCASH (OTO)',
  },
  CMP: {
    Name: 'CMP',
    Symbol: 'CMP',
    CoinName: 'Caduceus',
    FullName: 'Caduceus (CMP)',
  },
  DFA: {
    Name: 'DFA',
    Symbol: 'DFA',
    CoinName: 'DeFine',
    FullName: 'DeFine (DFA)',
  },
  FLR: {
    Name: 'FLR',
    Symbol: 'FLR',
    CoinName: 'Flare',
    FullName: 'Flare (FLR)',
  },
  WSI: {
    Name: 'WSI',
    Symbol: 'WSI',
    CoinName: 'WeSendit',
    FullName: 'WeSendit (WSI)',
  },
  FUR: {
    Name: 'FUR',
    Symbol: 'FUR',
    CoinName: 'Furio',
    FullName: 'Furio (FUR)',
  },
  CUNI: {
    Name: 'CUNI',
    Symbol: 'CUNI',
    CoinName: 'Compound Uni',
    FullName: 'Compound Uni (CUNI)',
  },
  BOMBM: {
    Name: 'BOMBM',
    Symbol: 'BOMBM',
    CoinName: 'Bomb Money',
    FullName: 'Bomb Money (BOMBM)',
  },
  UPCG: {
    Name: 'UPCG',
    Symbol: 'UPCG',
    CoinName: 'Upcomings',
    FullName: 'Upcomings (UPCG)',
  },
  MOOV: {
    Name: 'MOOV',
    Symbol: 'MOOV',
    CoinName: 'dotmoovs',
    FullName: 'dotmoovs (MOOV)',
  },
  SAFEMOON: {
    Name: 'SAFEMOON',
    Symbol: 'SAFEMOON',
    CoinName: 'SafeMoon',
    FullName: 'SafeMoon (SAFEMOON)',
  },
  MINT: {
    Name: 'MINT',
    Symbol: 'MINT',
    CoinName: 'Mint Club',
    FullName: 'Mint Club (MINT)',
  },
  MTH: {
    Name: 'MTH',
    Symbol: 'MTH',
    CoinName: 'Monetha',
    FullName: 'Monetha (MTH)',
  },
  NVT: {
    Name: 'NVT',
    Symbol: 'NVT',
    CoinName: 'NerveNetwork',
    FullName: 'NerveNetwork (NVT)',
  },
  HAM: {
    Name: 'HAM',
    Symbol: 'HAM',
    CoinName: 'Hamster',
    FullName: 'Hamster (HAM)',
  },
  RONIN: {
    Name: 'RONIN',
    Symbol: 'RONIN',
    CoinName: 'Ronin',
    FullName: 'Ronin (RONIN)',
  },
  BEND: {
    Name: 'BEND',
    Symbol: 'BEND',
    CoinName: 'BendDao',
    FullName: 'BendDao (BEND)',
  },
  OOE: {
    Name: 'OOE',
    Symbol: 'OOE',
    CoinName: 'OpenOcean',
    FullName: 'OpenOcean (OOE)',
  },
  DERI: {
    Name: 'DERI',
    Symbol: 'DERI',
    CoinName: 'Deri Protocol',
    FullName: 'Deri Protocol (DERI)',
  },
  GMCOIN: {
    Name: 'GMCOIN',
    Symbol: 'GMCOIN',
    CoinName: 'GMCoin',
    FullName: 'GMCoin (GMCOIN)',
  },
  XFC: {
    Name: 'XFC',
    Symbol: 'XFC',
    CoinName: 'Football Coin',
    FullName: 'Football Coin (XFC)',
  },
  PRISM: {
    Name: 'PRISM',
    Symbol: 'PRISM',
    CoinName: 'Prism',
    FullName: 'Prism (PRISM)',
  },
  BLP: {
    Name: 'BLP',
    Symbol: 'BLP',
    CoinName: 'BullPerks',
    FullName: 'BullPerks (BLP)',
  },
  BOTTO: {
    Name: 'BOTTO',
    Symbol: 'BOTTO',
    CoinName: 'Botto',
    FullName: 'Botto (BOTTO)',
  },
  NYAN: {
    Name: 'NYAN',
    Symbol: 'NYAN',
    CoinName: 'NyanCoin',
    FullName: 'NyanCoin (NYAN)',
  },
  BNC: {
    Name: 'BNC',
    Symbol: 'BNC',
    CoinName: 'Bifrost Native Coin',
    FullName: 'Bifrost Native Coin (BNC)',
  },
  COLX: {
    Name: 'COLX',
    Symbol: 'COLX',
    CoinName: 'ColossusCoinXT',
    FullName: 'ColossusCoinXT (COLX)',
  },
  EOSDT: {
    Name: 'EOSDT',
    Symbol: 'EOSDT',
    CoinName: 'EOSDT',
    FullName: 'EOSDT (EOSDT)',
  },
  VDG: {
    Name: 'VDG',
    Symbol: 'VDG',
    CoinName: 'VeriDocGlobal',
    FullName: 'VeriDocGlobal (VDG)',
  },
  PTP: {
    Name: 'PTP',
    Symbol: 'PTP',
    CoinName: 'Platypus Finance',
    FullName: 'Platypus Finance (PTP)',
  },
  GPX: {
    Name: 'GPX',
    Symbol: 'GPX',
    CoinName: 'GPEX',
    FullName: 'GPEX (GPX)',
  },
  DSLA: {
    Name: 'DSLA',
    Symbol: 'DSLA',
    CoinName: 'DSLA Protocol',
    FullName: 'DSLA Protocol (DSLA)',
  },
  VCF: {
    Name: 'VCF',
    Symbol: 'VCF',
    CoinName: 'Valencia CF Fan Token',
    FullName: 'Valencia CF Fan Token (VCF)',
  },
  COAL: {
    Name: 'COAL',
    Symbol: 'COAL',
    CoinName: 'BitCoal',
    FullName: 'BitCoal (COAL)',
  },
  KMON: {
    Name: 'KMON',
    Symbol: 'KMON',
    CoinName: 'Kryptomon',
    FullName: 'Kryptomon (KMON)',
  },
  MBN: {
    Name: 'MBN',
    Symbol: 'MBN',
    CoinName: 'Mobilian Coin',
    FullName: 'Mobilian Coin (MBN)',
  },
  TARA: {
    Name: 'TARA',
    Symbol: 'TARA',
    CoinName: 'Taraxa',
    FullName: 'Taraxa (TARA)',
  },
  DDIM: {
    Name: 'DDIM',
    Symbol: 'DDIM',
    CoinName: 'DuckDaoDime',
    FullName: 'DuckDaoDime (DDIM)',
  },
  RTH: {
    Name: 'RTH',
    Symbol: 'RTH',
    CoinName: 'Rotharium',
    FullName: 'Rotharium (RTH)',
  },
  DEFIT: {
    Name: 'DEFIT',
    Symbol: 'DEFIT',
    CoinName: 'Digital Fitness',
    FullName: 'Digital Fitness (DEFIT)',
  },
  ROWAN: {
    Name: 'ROWAN',
    Symbol: 'ROWAN',
    CoinName: 'Sifchain',
    FullName: 'Sifchain (ROWAN)',
  },
  SIN: {
    Name: 'SIN',
    Symbol: 'SIN',
    CoinName: 'Sinverse',
    FullName: 'Sinverse (SIN)',
  },
  TAROT: {
    Name: 'TAROT',
    Symbol: 'TAROT',
    CoinName: 'Tarot',
    FullName: 'Tarot (TAROT)',
  },
  PRIMATE: {
    Name: 'PRIMATE',
    Symbol: 'PRIMATE',
    CoinName: 'Primate',
    FullName: 'Primate (PRIMATE)',
  },
  IDV: {
    Name: 'IDV',
    Symbol: 'IDV',
    CoinName: 'Idavoll DAO',
    FullName: 'Idavoll DAO (IDV)',
  },
  MAHA: {
    Name: 'MAHA',
    Symbol: 'MAHA',
    CoinName: 'MahaDAO',
    FullName: 'MahaDAO (MAHA)',
  },
  CTCN: {
    Name: 'CTCN',
    Symbol: 'CTCN',
    CoinName: 'Contracoin',
    FullName: 'Contracoin (CTCN)',
  },
  BELT: {
    Name: 'BELT',
    Symbol: 'BELT',
    CoinName: 'Belt',
    FullName: 'Belt (BELT)',
  },
  THN: {
    Name: 'THN',
    Symbol: 'THN',
    CoinName: 'Throne',
    FullName: 'Throne (THN)',
  },
  GOZ: {
    Name: 'GOZ',
    Symbol: 'GOZ',
    CoinName: 'Göztepe S.K. Fan Token',
    FullName: 'Göztepe S.K. Fan Token (GOZ)',
  },
  PSG: {
    Name: 'PSG',
    Symbol: 'PSG',
    CoinName: 'Paris Saint-Germain Fan Token',
    FullName: 'Paris Saint-Germain Fan Token (PSG)',
  },
  ERSDL: {
    Name: 'ERSDL',
    Symbol: 'ERSDL',
    CoinName: 'UnFederalReserve',
    FullName: 'UnFederalReserve (ERSDL)',
  },
  KRIPTO: {
    Name: 'KRIPTO',
    Symbol: 'KRIPTO',
    CoinName: 'Kripto',
    FullName: 'Kripto (KRIPTO)',
  },
  '0XBTC': {
    Name: '0XBTC',
    Symbol: '0XBTC',
    CoinName: '0xBitcoin',
    FullName: '0xBitcoin (0XBTC)',
  },
  PALM: {
    Name: 'PALM',
    Symbol: 'PALM',
    CoinName: 'PalmPay',
    FullName: 'PalmPay (PALM)',
  },
  ATT: {
    Name: 'ATT',
    Symbol: 'ATT',
    CoinName: 'Attila',
    FullName: 'Attila (ATT)',
  },
  MATTER: {
    Name: 'MATTER',
    Symbol: 'MATTER',
    CoinName: 'AntiMatter',
    FullName: 'AntiMatter (MATTER)',
  },
  KONO: {
    Name: 'KONO',
    Symbol: 'KONO',
    CoinName: 'Konomi Network',
    FullName: 'Konomi Network (KONO)',
  },
  EOSC: {
    Name: 'EOSC',
    Symbol: 'EOSC',
    CoinName: 'EOSForce',
    FullName: 'EOSForce (EOSC)',
  },
  EQ: {
    Name: 'EQ',
    Symbol: 'EQ',
    CoinName: 'Equilibrium',
    FullName: 'Equilibrium (EQ)',
  },
  LNR: {
    Name: 'LNR',
    Symbol: 'LNR',
    CoinName: 'Lunar',
    FullName: 'Lunar (LNR)',
  },
  BPTC: {
    Name: 'BPTC',
    Symbol: 'BPTC',
    CoinName: 'Business Platform Tomato Coin',
    FullName: 'Business Platform Tomato Coin (BPTC)',
  },
  EDGT: {
    Name: 'EDGT',
    Symbol: 'EDGT',
    CoinName: 'Edgecoin',
    FullName: 'Edgecoin (EDGT)',
  },
  WMATIC: {
    Name: 'WMATIC',
    Symbol: 'WMATIC',
    CoinName: 'Wrapped Matic',
    FullName: 'Wrapped Matic (WMATIC)',
  },
  MONI: {
    Name: 'MONI',
    Symbol: 'MONI',
    CoinName: 'Monsta Infinite',
    FullName: 'Monsta Infinite (MONI)',
  },
  CBETH: {
    Name: 'CBETH',
    Symbol: 'CBETH',
    CoinName: 'Coinbase Wrapped Staked ETH',
    FullName: 'Coinbase Wrapped Staked ETH (CBETH)',
  },
  WSTETH: {
    Name: 'WSTETH',
    Symbol: 'WSTETH',
    CoinName: 'Lido wstETH',
    FullName: 'Lido wstETH (WSTETH)',
  },
  XTUSD: {
    Name: 'XTUSD',
    Symbol: 'XTUSD',
    CoinName: 'XT Stablecoin XTUSD',
    FullName: 'XT Stablecoin XTUSD (XTUSD)',
  },
  XT: {
    Name: 'XT',
    Symbol: 'XT',
    CoinName: 'XT.com Token',
    FullName: 'XT.com Token (XT)',
  },
  AVAX: {
    Name: 'AVAX',
    Symbol: 'AVAX',
    CoinName: 'Avalanche',
    FullName: 'Avalanche (AVAX)',
  },
  DMTR: {
    Name: 'DMTR',
    Symbol: 'DMTR',
    CoinName: 'Dimitra',
    FullName: 'Dimitra (DMTR)',
  },
  WBT: {
    Name: 'WBT',
    Symbol: 'WBT',
    CoinName: 'WhiteBIT Token',
    FullName: 'WhiteBIT Token (WBT)',
  },
  FRA: {
    Name: 'FRA',
    Symbol: 'FRA',
    CoinName: 'Findora',
    FullName: 'Findora (FRA)',
  },
  CNX: {
    Name: 'CNX',
    Symbol: 'CNX',
    CoinName: 'Cryptonex',
    FullName: 'Cryptonex (CNX)',
  },
  SOON: {
    Name: 'SOON',
    Symbol: 'SOON',
    CoinName: 'Soonaverse',
    FullName: 'Soonaverse (SOON)',
  },
  ZEDXION: {
    Name: 'ZEDXION',
    Symbol: 'ZEDXION',
    CoinName: 'Zedxion',
    FullName: 'Zedxion (ZEDXION)',
  },
  DEBT: {
    Name: 'DEBT',
    Symbol: 'DEBT',
    CoinName: 'The Debt Box',
    FullName: 'The Debt Box (DEBT)',
  },
  CONG: {
    Name: 'CONG',
    Symbol: 'CONG',
    CoinName: 'The Conglomerate Capital',
    FullName: 'The Conglomerate Capital (CONG)',
  },
  HTR: {
    Name: 'HTR',
    Symbol: 'HTR',
    CoinName: 'Hathor',
    FullName: 'Hathor (HTR)',
  },
  WALV: {
    Name: 'WALV',
    Symbol: 'WALV',
    CoinName: 'Alvey Chain',
    FullName: 'Alvey Chain (WALV)',
  },
  QUINT: {
    Name: 'QUINT',
    Symbol: 'QUINT',
    CoinName: 'Quint',
    FullName: 'Quint (QUINT)',
  },
  TBCC: {
    Name: 'TBCC',
    Symbol: 'TBCC',
    CoinName: 'TBCC',
    FullName: 'TBCC (TBCC)',
  },
  BPLC: {
    Name: 'BPLC',
    Symbol: 'BPLC',
    CoinName: 'BlackPearl Token',
    FullName: 'BlackPearl Token (BPLC)',
  },
  WE: {
    Name: 'WE',
    Symbol: 'WE',
    CoinName: 'WeBuy',
    FullName: 'WeBuy (WE)',
  },
  DUST: {
    Name: 'DUST',
    Symbol: 'DUST',
    CoinName: 'DUST Protocol',
    FullName: 'DUST Protocol (DUST)',
  },
  LYO: {
    Name: 'LYO',
    Symbol: 'LYO',
    CoinName: 'LYO Credit',
    FullName: 'LYO Credit (LYO)',
  },
  AGLA: {
    Name: 'AGLA',
    Symbol: 'AGLA',
    CoinName: 'Angola',
    FullName: 'Angola (AGLA)',
  },
  KON: {
    Name: 'KON',
    Symbol: 'KON',
    CoinName: 'KonPay',
    FullName: 'KonPay (KON)',
  },
  FT: {
    Name: 'FT',
    Symbol: 'FT',
    CoinName: 'Fracton Protocol',
    FullName: 'Fracton Protocol (FT)',
  },
  ORBR: {
    Name: 'ORBR',
    Symbol: 'ORBR',
    CoinName: 'Orbler',
    FullName: 'Orbler (ORBR)',
  },
  OASC: {
    Name: 'OASC',
    Symbol: 'OASC',
    CoinName: 'Oasis City',
    FullName: 'Oasis City (OASC)',
  },
  YOUC: {
    Name: 'YOUC',
    Symbol: 'YOUC',
    CoinName: 'yOUcash',
    FullName: 'yOUcash (YOUC)',
  },
  PRNT: {
    Name: 'PRNT',
    Symbol: 'PRNT',
    CoinName: 'Prime Numbers',
    FullName: 'Prime Numbers (PRNT)',
  },
  TARI: {
    Name: 'TARI',
    Symbol: 'TARI',
    CoinName: 'Tari World',
    FullName: 'Tari World (TARI)',
  },
  GOREC: {
    Name: 'GOREC',
    Symbol: 'GOREC',
    CoinName: 'GoRecruit',
    FullName: 'GoRecruit (GOREC)',
  },
  SHI: {
    Name: 'SHI',
    Symbol: 'SHI',
    CoinName: 'Shirtum',
    FullName: 'Shirtum (SHI)',
  },
  CAN: {
    Name: 'CAN',
    Symbol: 'CAN',
    CoinName: 'Channels',
    FullName: 'Channels (CAN)',
  },
  CET: {
    Name: 'CET',
    Symbol: 'CET',
    CoinName: 'CoinEx Token',
    FullName: 'CoinEx Token (CET)',
  },
  THALES: {
    Name: 'THALES',
    Symbol: 'THALES',
    CoinName: 'Thales',
    FullName: 'Thales (THALES)',
  },
  ZINU: {
    Name: 'ZINU',
    Symbol: 'ZINU',
    CoinName: 'Zombie Inu',
    FullName: 'Zombie Inu (ZINU)',
  },
  NFTB: {
    Name: 'NFTB',
    Symbol: 'NFTB',
    CoinName: 'NFTb',
    FullName: 'NFTb (NFTB)',
  },
  MILO: {
    Name: 'MILO',
    Symbol: 'MILO',
    CoinName: 'Milo Inu',
    FullName: 'Milo Inu (MILO)',
  },
  WAL: {
    Name: 'WAL',
    Symbol: 'WAL',
    CoinName: 'The Wasted Lands',
    FullName: 'The Wasted Lands (WAL)',
  },
  PLCU: {
    Name: 'PLCU',
    Symbol: 'PLCU',
    CoinName: 'PLC Ultima',
    FullName: 'PLC Ultima (PLCU)',
  },
  P2PS: {
    Name: 'P2PS',
    Symbol: 'P2PS',
    CoinName: 'P2P Solutions Foundation',
    FullName: 'P2P Solutions Foundation (P2PS)',
  },
  SIS: {
    Name: 'SIS',
    Symbol: 'SIS',
    CoinName: 'Symbiosis Finance',
    FullName: 'Symbiosis Finance (SIS)',
  },
  AKITA: {
    Name: 'AKITA',
    Symbol: 'AKITA',
    CoinName: 'Akita Inu',
    FullName: 'Akita Inu (AKITA)',
  },
  YES: {
    Name: 'YES',
    Symbol: 'YES',
    CoinName: 'Yes World',
    FullName: 'Yes World (YES)',
  },
  SWP: {
    Name: 'SWP',
    Symbol: 'SWP',
    CoinName: 'Kava Swap',
    FullName: 'Kava Swap (SWP)',
  },
  DREAMS: {
    Name: 'DREAMS',
    Symbol: 'DREAMS',
    CoinName: 'Dreams Quest',
    FullName: 'Dreams Quest (DREAMS)',
  },
  CFC: {
    Name: 'CFC',
    Symbol: 'CFC',
    CoinName: 'CoinField Coin',
    FullName: 'CoinField Coin (CFC)',
  },
  TAVA: {
    Name: 'TAVA',
    Symbol: 'TAVA',
    CoinName: 'ALTAVA',
    FullName: 'ALTAVA (TAVA)',
  },
  MEPAD: {
    Name: 'MEPAD',
    Symbol: 'MEPAD',
    CoinName: 'MemePad',
    FullName: 'MemePad (MEPAD)',
  },
  ILA: {
    Name: 'ILA',
    Symbol: 'ILA',
    CoinName: 'Infinite Launch',
    FullName: 'Infinite Launch (ILA)',
  },
  AZY: {
    Name: 'AZY',
    Symbol: 'AZY',
    CoinName: 'Amazy',
    FullName: 'Amazy (AZY)',
  },
  GDDY: {
    Name: 'GDDY',
    Symbol: 'GDDY',
    CoinName: 'Giddy',
    FullName: 'Giddy (GDDY)',
  },
  KSC: {
    Name: 'KSC',
    Symbol: 'KSC',
    CoinName: 'KStarCoin',
    FullName: 'KStarCoin (KSC)',
  },
  GALEON: {
    Name: 'GALEON',
    Symbol: 'GALEON',
    CoinName: 'Galeon',
    FullName: 'Galeon (GALEON)',
  },
  G: {
    Name: 'G',
    Symbol: 'G',
    CoinName: 'GRN Grid',
    FullName: 'GRN Grid (G)',
  },
  ATOZ: {
    Name: 'ATOZ',
    Symbol: 'ATOZ',
    CoinName: 'Race Kingdom',
    FullName: 'Race Kingdom (ATOZ)',
  },
  DUEL: {
    Name: 'DUEL',
    Symbol: 'DUEL',
    CoinName: 'Duel Network',
    FullName: 'Duel Network (DUEL)',
  },
  PURE: {
    Name: 'PURE',
    Symbol: 'PURE',
    CoinName: 'Puriever',
    FullName: 'Puriever (PURE)',
  },
  FORTH: {
    Name: 'FORTH',
    Symbol: 'FORTH',
    CoinName: 'Ampleforth Governance Token',
    FullName: 'Ampleforth Governance Token (FORTH)',
  },
  RAIN: {
    Name: 'RAIN',
    Symbol: 'RAIN',
    CoinName: 'Rainmaker Games',
    FullName: 'Rainmaker Games (RAIN)',
  },
  FEN: {
    Name: 'FEN',
    Symbol: 'FEN',
    CoinName: 'First Ever NFT',
    FullName: 'First Ever NFT (FEN)',
  },
  LDN: {
    Name: 'LDN',
    Symbol: 'LDN',
    CoinName: 'Ludena Protocol',
    FullName: 'Ludena Protocol (LDN)',
  },
  FILDA: {
    Name: 'FILDA',
    Symbol: 'FILDA',
    CoinName: 'Filda',
    FullName: 'Filda (FILDA)',
  },
  ANML: {
    Name: 'ANML',
    Symbol: 'ANML',
    CoinName: 'Animal Concerts',
    FullName: 'Animal Concerts (ANML)',
  },
  IXT: {
    Name: 'IXT',
    Symbol: 'IXT',
    CoinName: 'iXledger',
    FullName: 'iXledger (IXT)',
  },
  CELEB: {
    Name: 'CELEB',
    Symbol: 'CELEB',
    CoinName: 'CELEBPLUS',
    FullName: 'CELEBPLUS (CELEB)',
  },
  ELT: {
    Name: 'ELT',
    Symbol: 'ELT',
    CoinName: 'Element Black',
    FullName: 'Element Black (ELT)',
  },
  CELT: {
    Name: 'CELT',
    Symbol: 'CELT',
    CoinName: 'Celestial',
    FullName: 'Celestial (CELT)',
  },
  AUCO: {
    Name: 'AUCO',
    Symbol: 'AUCO',
    CoinName: 'Advanced United Continent',
    FullName: 'Advanced United Continent (AUCO)',
  },
  ENCD: {
    Name: 'ENCD',
    Symbol: 'ENCD',
    CoinName: 'Encircled',
    FullName: 'Encircled (ENCD)',
  },
  WMINIMA: {
    Name: 'WMINIMA',
    Symbol: 'WMINIMA',
    CoinName: 'Wrapped Minima',
    FullName: 'Wrapped Minima (WMINIMA)',
  },
  HOOK: {
    Name: 'HOOK',
    Symbol: 'HOOK',
    CoinName: 'Hooked Protocol',
    FullName: 'Hooked Protocol (HOOK)',
  },
  ONUS: {
    Name: 'ONUS',
    Symbol: 'ONUS',
    CoinName: 'ONUS',
    FullName: 'ONUS (ONUS)',
  },
  LCX: {
    Name: 'LCX',
    Symbol: 'LCX',
    CoinName: 'LCX',
    FullName: 'LCX (LCX)',
  },
  ANKRETH: {
    Name: 'ANKRETH',
    Symbol: 'ANKRETH',
    CoinName: 'Ankr Staked ETH',
    FullName: 'Ankr Staked ETH (ANKRETH)',
  },
  VBIT: {
    Name: 'VBIT',
    Symbol: 'VBIT',
    CoinName: 'Valobit',
    FullName: 'Valobit (VBIT)',
  },
  PEEL: {
    Name: 'PEEL',
    Symbol: 'PEEL',
    CoinName: 'Meta Apes',
    FullName: 'Meta Apes (PEEL)',
  },
  PALLA: {
    Name: 'PALLA',
    Symbol: 'PALLA',
    CoinName: 'Pallapay',
    FullName: 'Pallapay (PALLA)',
  },
  PNG: {
    Name: 'PNG',
    Symbol: 'PNG',
    CoinName: 'Pangolin',
    FullName: 'Pangolin (PNG)',
  },
  ACX: {
    Name: 'ACX',
    Symbol: 'ACX',
    CoinName: 'Across Protocol',
    FullName: 'Across Protocol (ACX)',
  },
  UPUNK: {
    Name: 'UPUNK',
    Symbol: 'UPUNK',
    CoinName: 'Unicly CryptoPunks Collection',
    FullName: 'Unicly CryptoPunks Collection (UPUNK)',
  },
  LOIS: {
    Name: 'LOIS',
    Symbol: 'LOIS',
    CoinName: 'Lois Token',
    FullName: 'Lois Token (LOIS)',
  },
  DOBO: {
    Name: 'DOBO',
    Symbol: 'DOBO',
    CoinName: 'DogeBonk',
    FullName: 'DogeBonk (DOBO)',
  },
  OXB: {
    Name: 'OXB',
    Symbol: 'OXB',
    CoinName: 'Oxbull Tech',
    FullName: 'Oxbull Tech (OXB)',
  },
  ZLW: {
    Name: 'ZLW',
    Symbol: 'ZLW',
    CoinName: 'Zelwin',
    FullName: 'Zelwin (ZLW)',
  },
  PUMLX: {
    Name: 'PUMLX',
    Symbol: 'PUMLX',
    CoinName: 'PUMLx',
    FullName: 'PUMLx (PUMLX)',
  },
  MESA: {
    Name: 'MESA',
    Symbol: 'MESA',
    CoinName: 'MetaVisa',
    FullName: 'MetaVisa (MESA)',
  },
  METAX: {
    Name: 'METAX',
    Symbol: 'METAX',
    CoinName: 'MetaverseX',
    FullName: 'MetaverseX (METAX)',
  },
  MONEYBYTE: {
    Name: 'MONEYBYTE',
    Symbol: 'MONEYBYTE',
    CoinName: 'MoneyByte',
    FullName: 'MoneyByte (MONEYBYTE)',
  },
  STORE: {
    Name: 'STORE',
    Symbol: 'STORE',
    CoinName: 'Bit Store',
    FullName: 'Bit Store (STORE)',
  },
  PARMA: {
    Name: 'PARMA',
    Symbol: 'PARMA',
    CoinName: 'PARMA Fan Token',
    FullName: 'PARMA Fan Token (PARMA)',
  },
  ROAD: {
    Name: 'ROAD',
    Symbol: 'ROAD',
    CoinName: 'ROAD',
    FullName: 'ROAD (ROAD)',
  },
  ASTO: {
    Name: 'ASTO',
    Symbol: 'ASTO',
    CoinName: 'Altered State Token',
    FullName: 'Altered State Token (ASTO)',
  },
  GRIDZ: {
    Name: 'GRIDZ',
    Symbol: 'GRIDZ',
    CoinName: 'GridZone.io',
    FullName: 'GridZone.io (GRIDZ)',
  },
  LUFFYOLD: {
    Name: 'LUFFYOLD',
    Symbol: 'LUFFYOLD',
    CoinName: 'Luffy',
    FullName: 'Luffy (LUFFYOLD)',
  },
  LUFFY: {
    Name: 'LUFFY',
    Symbol: 'LUFFY',
    CoinName: 'Luffy',
    FullName: 'Luffy (LUFFY)',
  },
  NPT: {
    Name: 'NPT',
    Symbol: 'NPT',
    CoinName: 'Neopin',
    FullName: 'Neopin (NPT)',
  },
  IDNA: {
    Name: 'IDNA',
    Symbol: 'IDNA',
    CoinName: 'Idena',
    FullName: 'Idena (IDNA)',
  },
  FGD: {
    Name: 'FGD',
    Symbol: 'FGD',
    CoinName: 'Freedom God DAO',
    FullName: 'Freedom God DAO (FGD)',
  },
  HYPE: {
    Name: 'HYPE',
    Symbol: 'HYPE',
    CoinName: 'Hype',
    FullName: 'Hype (HYPE)',
  },
  GMPD: {
    Name: 'GMPD',
    Symbol: 'GMPD',
    CoinName: 'GamesPad',
    FullName: 'GamesPad (GMPD)',
  },
  HEART: {
    Name: 'HEART',
    Symbol: 'HEART',
    CoinName: 'Humans',
    FullName: 'Humans (HEART)',
  },
  FRR: {
    Name: 'FRR',
    Symbol: 'FRR',
    CoinName: 'Frontrow',
    FullName: 'Frontrow (FRR)',
  },
  IPV: {
    Name: 'IPV',
    Symbol: 'IPV',
    CoinName: 'IPVERSE',
    FullName: 'IPVERSE (IPV)',
  },
  FODL: {
    Name: 'FODL',
    Symbol: 'FODL',
    CoinName: 'Fodl Finance',
    FullName: 'Fodl Finance (FODL)',
  },
  ZYRO: {
    Name: 'ZYRO',
    Symbol: 'ZYRO',
    CoinName: 'Zyro',
    FullName: 'Zyro (ZYRO)',
  },
  COW: {
    Name: 'COW',
    Symbol: 'COW',
    CoinName: 'CoW Protocol',
    FullName: 'CoW Protocol (COW)',
  },
  HAWK: {
    Name: 'HAWK',
    Symbol: 'HAWK',
    CoinName: 'Hawksight',
    FullName: 'Hawksight (HAWK)',
  },
  BDOT: {
    Name: 'BDOT',
    Symbol: 'BDOT',
    CoinName: 'Binance Wrapped DOT',
    FullName: 'Binance Wrapped DOT (BDOT)',
  },
  WBX: {
    Name: 'WBX',
    Symbol: 'WBX',
    CoinName: 'WiBX',
    FullName: 'WiBX (WBX)',
  },
  DBX: {
    Name: 'DBX',
    Symbol: 'DBX',
    CoinName: 'DBX',
    FullName: 'DBX (DBX)',
  },
  GGG: {
    Name: 'GGG',
    Symbol: 'GGG',
    CoinName: 'Good Games Guild',
    FullName: 'Good Games Guild (GGG)',
  },
  MRI: {
    Name: 'MRI',
    Symbol: 'MRI',
    CoinName: 'Marshall Inu',
    FullName: 'Marshall Inu (MRI)',
  },
  SLG: {
    Name: 'SLG',
    Symbol: 'SLG',
    CoinName: 'Land Of Conquest',
    FullName: 'Land Of Conquest (SLG)',
  },
  SYNR: {
    Name: 'SYNR',
    Symbol: 'SYNR',
    CoinName: 'MOBLAND',
    FullName: 'MOBLAND (SYNR)',
  },
  OLAND: {
    Name: 'OLAND',
    Symbol: 'OLAND',
    CoinName: 'Oceanland',
    FullName: 'Oceanland (OLAND)',
  },
  ECOIN: {
    Name: 'ECOIN',
    Symbol: 'ECOIN',
    CoinName: 'Ecoin',
    FullName: 'Ecoin (ECOIN)',
  },
  BOB: {
    Name: 'BOB',
    Symbol: 'BOB',
    CoinName: 'BOB',
    FullName: 'BOB (BOB)',
  },
  DBL: {
    Name: 'DBL',
    Symbol: 'DBL',
    CoinName: 'Doubloon',
    FullName: 'Doubloon (DBL)',
  },
  TFLOW: {
    Name: 'TFLOW',
    Symbol: 'TFLOW',
    CoinName: 'TradeFlow',
    FullName: 'TradeFlow (TFLOW)',
  },
  FCON: {
    Name: 'FCON',
    Symbol: 'FCON',
    CoinName: 'SpaceFalcon',
    FullName: 'SpaceFalcon (FCON)',
  },
  OJA: {
    Name: 'OJA',
    Symbol: 'OJA',
    CoinName: 'Ojamu',
    FullName: 'Ojamu (OJA)',
  },
  TYRANT: {
    Name: 'TYRANT',
    Symbol: 'TYRANT',
    CoinName: 'Fable Of The Dragon',
    FullName: 'Fable Of The Dragon (TYRANT)',
  },
  ASY: {
    Name: 'ASY',
    Symbol: 'ASY',
    CoinName: 'ASYAGRO',
    FullName: 'ASYAGRO (ASY)',
  },
  BIOCOIN: {
    Name: 'BIOCOIN',
    Symbol: 'BIOCOIN',
    CoinName: 'Biocoin',
    FullName: 'Biocoin (BIOCOIN)',
  },
  BIO: {
    Name: 'BIO',
    Symbol: 'BIO',
    CoinName: 'BITONE',
    FullName: 'BITONE (BIO)',
  },
  BEETOKEN: {
    Name: 'BEETOKEN',
    Symbol: 'BEETOKEN',
    CoinName: 'Bee Token',
    FullName: 'Bee Token (BEETOKEN)',
  },
  BEE: {
    Name: 'BEE',
    Symbol: 'BEE',
    CoinName: 'Herbee',
    FullName: 'Herbee (BEE)',
  },
  BEETLE: {
    Name: 'BEETLE',
    Symbol: 'BEETLE',
    CoinName: 'Beetle Coin',
    FullName: 'Beetle Coin (BEETLE)',
  },
  ITALOCOIN: {
    Name: 'ITALOCOIN',
    Symbol: 'ITALOCOIN',
    CoinName: 'Italocoin',
    FullName: 'Italocoin (ITALOCOIN)',
  },
  ITA: {
    Name: 'ITA',
    Symbol: 'ITA',
    CoinName: 'Italian National Football Team Fan Token',
    FullName: 'Italian National Football Team Fan Token (ITA)',
  },
  FACETER: {
    Name: 'FACETER',
    Symbol: 'FACETER',
    CoinName: 'Faceter',
    FullName: 'Faceter (FACETER)',
  },
  FACE: {
    Name: 'FACE',
    Symbol: 'FACE',
    CoinName: 'FaceDAO',
    FullName: 'FaceDAO (FACE)',
  },
  SESSIA: {
    Name: 'SESSIA',
    Symbol: 'SESSIA',
    CoinName: 'SESSIA',
    FullName: 'SESSIA (SESSIA)',
  },
  KICKS: {
    Name: 'KICKS',
    Symbol: 'KICKS',
    CoinName: 'GetKicks',
    FullName: 'GetKicks (KICKS)',
  },
  MVD: {
    Name: 'MVD',
    Symbol: 'MVD',
    CoinName: 'Metavault',
    FullName: 'Metavault (MVD)',
  },
  MONST: {
    Name: 'MONST',
    Symbol: 'MONST',
    CoinName: 'Monstock',
    FullName: 'Monstock (MONST)',
  },
  MON: {
    Name: 'MON',
    Symbol: 'MON',
    CoinName: 'Medamon',
    FullName: 'Medamon (MON)',
  },
  ADAX: {
    Name: 'ADAX',
    Symbol: 'ADAX',
    CoinName: 'ADAX',
    FullName: 'ADAX (ADAX)',
  },
  GALAX: {
    Name: 'GALAX',
    Symbol: 'GALAX',
    CoinName: 'Galaxy Finance',
    FullName: 'Galaxy Finance (GALAX)',
  },
  ARGENTUM: {
    Name: 'ARGENTUM',
    Symbol: 'ARGENTUM',
    CoinName: 'Argentum',
    FullName: 'Argentum (ARGENTUM)',
  },
  ARG: {
    Name: 'ARG',
    Symbol: 'ARG',
    CoinName: 'Argentine Football Association Fan Token',
    FullName: 'Argentine Football Association Fan Token (ARG)',
  },
  REVU: {
    Name: 'REVU',
    Symbol: 'REVU',
    CoinName: 'Revuto',
    FullName: 'Revuto (REVU)',
  },
  RVF: {
    Name: 'RVF',
    Symbol: 'RVF',
    CoinName: 'RocketX exchange',
    FullName: 'RocketX exchange (RVF)',
  },
  PLY: {
    Name: 'PLY',
    Symbol: 'PLY',
    CoinName: 'Aurigami',
    FullName: 'Aurigami (PLY)',
  },
  PRDS: {
    Name: 'PRDS',
    Symbol: 'PRDS',
    CoinName: 'Brise Paradise',
    FullName: 'Brise Paradise (PRDS)',
  },
  AMA: {
    Name: 'AMA',
    Symbol: 'AMA',
    CoinName: 'MrWeb',
    FullName: 'MrWeb (AMA)',
  },
  CRDTS: {
    Name: 'CRDTS',
    Symbol: 'CRDTS',
    CoinName: 'Credits',
    FullName: 'Credits (CRDTS)',
  },
  BLUR: {
    Name: 'BLUR',
    Symbol: 'BLUR',
    CoinName: 'Blur',
    FullName: 'Blur (BLUR)',
  },
  UMAMI: {
    Name: 'UMAMI',
    Symbol: 'UMAMI',
    CoinName: 'Umami',
    FullName: 'Umami (UMAMI)',
  },
  SARCO: {
    Name: 'SARCO',
    Symbol: 'SARCO',
    CoinName: 'Sarcophagus',
    FullName: 'Sarcophagus (SARCO)',
  },
  CREDITS: {
    Name: 'CREDITS',
    Symbol: 'CREDITS',
    CoinName: 'Credits',
    FullName: 'Credits (CREDITS)',
  },
  RLY: {
    Name: 'RLY',
    Symbol: 'RLY',
    CoinName: 'Rally',
    FullName: 'Rally (RLY)',
  },
  EUROE: {
    Name: 'EUROE',
    Symbol: 'EUROE',
    CoinName: 'EUROe Stablecoin',
    FullName: 'EUROe Stablecoin (EUROE)',
  },
  AMKT: {
    Name: 'AMKT',
    Symbol: 'AMKT',
    CoinName: 'Alongside Crypto Market Index',
    FullName: 'Alongside Crypto Market Index (AMKT)',
  },
  VERSE: {
    Name: 'VERSE',
    Symbol: 'VERSE',
    CoinName: 'Verse',
    FullName: 'Verse (VERSE)',
  },
  FLIC: {
    Name: 'FLIC',
    Symbol: 'FLIC',
    CoinName: 'Skaflic',
    FullName: 'Skaflic (FLIC)',
  },
  HDX: {
    Name: 'HDX',
    Symbol: 'HDX',
    CoinName: 'HydraDX',
    FullName: 'HydraDX (HDX)',
  },
  GFT: {
    Name: 'GFT',
    Symbol: 'GFT',
    CoinName: 'Gifto',
    FullName: 'Gifto (GFT)',
  },
  VELA: {
    Name: 'VELA',
    Symbol: 'VELA',
    CoinName: 'Vela Token',
    FullName: 'Vela Token (VELA)',
  },
  MADOG: {
    Name: 'MADOG',
    Symbol: 'MADOG',
    CoinName: 'MarvelDoge',
    FullName: 'MarvelDoge (MADOG)',
  },
  HEARTR: {
    Name: 'HEARTR',
    Symbol: 'HEARTR',
    CoinName: 'Heart Rate',
    FullName: 'Heart Rate (HEARTR)',
  },
  CC: {
    Name: 'CC',
    Symbol: 'CC',
    CoinName: 'CloudChat',
    FullName: 'CloudChat (CC)',
  },
  CRE: {
    Name: 'CRE',
    Symbol: 'CRE',
    CoinName: 'Carry',
    FullName: 'Carry (CRE)',
  },
  BIDAO: {
    Name: 'BIDAO',
    Symbol: 'BIDAO',
    CoinName: 'Bidao',
    FullName: 'Bidao (BIDAO)',
  },
  BID: {
    Name: 'BID',
    Symbol: 'BID',
    CoinName: 'TopBidder',
    FullName: 'TopBidder (BID)',
  },
  MIININGNFT: {
    Name: 'MIININGNFT',
    Symbol: 'MIININGNFT',
    CoinName: 'MiningNFT',
    FullName: 'MiningNFT (MIININGNFT)',
  },
  MIT: {
    Name: 'MIT',
    Symbol: 'MIT',
    CoinName: 'Galaxy Blitz',
    FullName: 'Galaxy Blitz (MIT)',
  },
  IUNGO: {
    Name: 'IUNGO',
    Symbol: 'IUNGO',
    CoinName: 'Iungo',
    FullName: 'Iungo (IUNGO)',
  },
  ING: {
    Name: 'ING',
    Symbol: 'ING',
    CoinName: 'Infinity Games',
    FullName: 'Infinity Games (ING)',
  },
  KEXCOIN: {
    Name: 'KexCoin',
    Symbol: 'KEXCOIN',
    CoinName: 'KexCoin',
    FullName: 'KexCoin (KexCoin)',
  },
  KEX: {
    Name: 'KEX',
    Symbol: 'KEX',
    CoinName: 'Kira Network',
    FullName: 'Kira Network (KEX)',
  },
  BULLION: {
    Name: 'BULLION',
    Symbol: 'BULLION',
    CoinName: 'BullionFX',
    FullName: 'BullionFX (BULLION)',
  },
  BULL: {
    Name: 'BULL',
    Symbol: 'BULL',
    CoinName: 'Bullieverse',
    FullName: 'Bullieverse (BULL)',
  },
  gOHM: {
    Name: 'gOHM',
    Symbol: 'gOHM',
    CoinName: 'Governance OHM',
    FullName: 'Governance OHM (gOHM)',
  },
  RANKER: {
    Name: 'RANKER',
    Symbol: 'RANKER',
    CoinName: 'RankerDao',
    FullName: 'RankerDao (RANKER)',
  },
  SHIB05: {
    Name: 'SHIB05',
    Symbol: 'SHIB05',
    CoinName: 'Half Shiba Inu',
    FullName: 'Half Shiba Inu (SHIB05)',
  },
  APT: {
    Name: 'APT',
    Symbol: 'APT',
    CoinName: 'Aptos',
    FullName: 'Aptos (APT)',
  },
  GRV: {
    Name: 'GRV',
    Symbol: 'GRV',
    CoinName: 'Grove',
    FullName: 'Grove (GRV)',
  },
  EGLD: {
    Name: 'EGLD',
    Symbol: 'EGLD',
    CoinName: 'eGold',
    FullName: 'eGold (EGLD)',
  },
  ZCN: {
    Name: 'ZCN',
    Symbol: 'ZCN',
    CoinName: 'Züs',
    FullName: 'Züs (ZCN)',
  },
  HIFI: {
    Name: 'HIFI',
    Symbol: 'HIFI',
    CoinName: 'Hifi Finance',
    FullName: 'Hifi Finance (HIFI)',
  },
  MFT: {
    Name: 'MFT',
    Symbol: 'MFT',
    CoinName: 'Hifi Finance (Old)',
    FullName: 'Hifi Finance (Old) (MFT)',
  },
  KALM: {
    Name: 'KALM',
    Symbol: 'KALM',
    CoinName: 'KALM',
    FullName: 'KALM (KALM)',
  },
  VOW: {
    Name: 'VOW',
    Symbol: 'VOW',
    CoinName: 'Vow',
    FullName: 'Vow (VOW)',
  },
  XIL: {
    Name: 'XIL',
    Symbol: 'XIL',
    CoinName: 'Xillion',
    FullName: 'Xillion (XIL)',
  },
  EGAME: {
    Name: 'EGAME',
    Symbol: 'EGAME',
    CoinName: 'Every Game',
    FullName: 'Every Game (EGAME)',
  },
  AGI: {
    Name: 'AGI',
    Symbol: 'AGI',
    CoinName: 'SingularityNET',
    FullName: 'SingularityNET (AGI)',
  },
  GART: {
    Name: 'GART',
    Symbol: 'GART',
    CoinName: 'Griffin Art',
    FullName: 'Griffin Art (GART)',
  },
  STATER: {
    Name: 'STATER',
    Symbol: 'STATER',
    CoinName: 'Stater',
    FullName: 'Stater (STATER)',
  },
  VOLTOLD: {
    Name: 'VOLTOLD',
    Symbol: 'VOLTOLD',
    CoinName: 'Volt Inu (Old)',
    FullName: 'Volt Inu (Old) (VOLTOLD)',
  },
  QANX: {
    Name: 'QANX',
    Symbol: 'QANX',
    CoinName: 'QANplatform',
    FullName: 'QANplatform (QANX)',
  },
  ENVION: {
    Name: 'ENVION',
    Symbol: 'ENVION',
    CoinName: 'Envion',
    FullName: 'Envion (ENVION)',
  },
  BRN: {
    Name: 'BRN',
    Symbol: 'BRN',
    CoinName: 'BRN Metaverse',
    FullName: 'BRN Metaverse (BRN)',
  },
  EVN: {
    Name: 'EVN',
    Symbol: 'EVN',
    CoinName: 'Evn Token',
    FullName: 'Evn Token (EVN)',
  },
  LKC: {
    Name: 'LKC',
    Symbol: 'LKC',
    CoinName: 'LuckyCoin',
    FullName: 'LuckyCoin (LKC)',
  },
  KOI: {
    Name: 'KOI',
    Symbol: 'KOI',
    CoinName: 'Koi Network',
    FullName: 'Koi Network (KOI)',
  },
  SPACEPI: {
    Name: 'SPACEPI',
    Symbol: 'SPACEPI',
    CoinName: 'SpacePi',
    FullName: 'SpacePi (SPACEPI)',
  },
  DIONE: {
    Name: 'DIONE',
    Symbol: 'DIONE',
    CoinName: 'Dione',
    FullName: 'Dione (DIONE)',
  },
  BABYTK: {
    Name: 'BABYTK',
    Symbol: 'BABYTK',
    CoinName: 'Baby Tiger King',
    FullName: 'Baby Tiger King (BABYTK)',
  },
  GODE: {
    Name: 'GODE',
    Symbol: 'GODE',
    CoinName: 'Gode Chain',
    FullName: 'Gode Chain (GODE)',
  },
  PBTC35A: {
    Name: 'PBTC35A',
    Symbol: 'PBTC35A',
    CoinName: 'pBTC35A',
    FullName: 'pBTC35A (PBTC35A)',
  },
  GRAIL: {
    Name: 'GRAIL',
    Symbol: 'GRAIL',
    CoinName: 'Camelot Token',
    FullName: 'Camelot Token (GRAIL)',
  },
  GVR: {
    Name: 'GVR',
    Symbol: 'GVR',
    CoinName: 'Grove [OLD]',
    FullName: 'Grove [OLD] (GVR)',
  },
  MMAPS: {
    Name: 'MMAPS',
    Symbol: 'MMAPS',
    CoinName: 'MapMetrics',
    FullName: 'MapMetrics (MMAPS)',
  },
  KIRBYRELOADED: {
    Name: 'KIRBYRELOADED',
    Symbol: 'KIRBYRELOADED',
    CoinName: 'Kirby Reloaded',
    FullName: 'Kirby Reloaded (KIRBYRELOADED)',
  },
  BITSPACE: {
    Name: 'BITSPACE',
    Symbol: 'BITSPACE',
    CoinName: 'Bitspace',
    FullName: 'Bitspace (BITSPACE)',
  },
  BSX: {
    Name: 'BSX',
    Symbol: 'BSX',
    CoinName: 'Basilisk',
    FullName: 'Basilisk (BSX)',
  },
  CONSENTIUM: {
    Name: 'CONSENTIUM',
    Symbol: 'CONSENTIUM',
    CoinName: 'Consentium',
    FullName: 'Consentium (CONSENTIUM)',
  },
  CSM: {
    Name: 'CSM',
    Symbol: 'CSM',
    CoinName: 'Crust Shadow',
    FullName: 'Crust Shadow (CSM)',
  },
  SYN: {
    Name: 'SYN',
    Symbol: 'SYN',
    CoinName: 'Synapse',
    FullName: 'Synapse (SYN)',
  },
}
