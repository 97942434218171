export enum NetworkName {
  Staging = 'staging',
  Mainnet = 'live',
  Local = 'dev',
  Testnet = 'testnet',
}
export enum WagmiNetworkName {
  ArbitrumMainnet = 'arbitrum',
  PolygonMainnet = 'polygon',
  PolygonMumbai = 'polygonMumbai',
  ZksyncMainnet = 'zkSync',
  ZksyncTestnet = 'zkSyncTestnet',
  ArbitrumGoerli = 'arbGoerli',
  EtherumGoerli = 'goerli',
  EthereumMainnet = 'mainnet',
  Staging = 'staging',
  Local = 'dev',
}
export enum BlockchainName {
  ETHEREUM = 'ethereum',
  POLYGON = 'polygon',
  ARBITRUM = 'arbitrum',
  ETHEREUM_GOERLI = 'ethereum-goerli',
  ARBITRUM_GOERLI = 'arbitrum-goerli',
  POLYGON_MUMBAI = 'polygon-mumbai',
  ZK_MAINNET = 'zkMainnet',
  ZK_TESTNET = 'zkTestnet',
}

export const cviHistoryGroupBy = ['day', 'hour', 'minutes'] as const
export type CviHistoryGroupBy = typeof cviHistoryGroupBy[number]

export const cviOracleType = ['CVI', 'UCVI'] as const
export type CviOracleType = typeof cviOracleType[number]

export const cviChartType = ['normal', 'ohlc'] as const
export type CviChartType = typeof cviChartType[number]

export type TheGraphGraph = 'platformGraph'

export type BackendInversifyConfig = {
  zapper: {
    zapperApiKeys: string[]
    proxy?: { host: string; port: number; auth?: { username: string; password: string } }
  }
}

export enum ChainId {
  EthereumMainnet = '1',
  PolygonMainnet = '137',
  ArbitrumMainnet = '42161',
  EthereumStaging = '31337',
  PolygonStaging = '31338',
  ArbitrumStaging = '31339',
  EthereumLocal = '31137',
  PolygonLocal = '31138',
  ArbitrumLocal = '31139',
  PolygonMumbai = '80001',
  PolygonMumbaiLocal = '31141',
  PolygonMumbaiStaging = '31341',
  EthereumGoerli = '5',
  EthereumGoerliLocal = '31140',
  EthereumGoerliStaging = '31340',
  ArbitrumGoerli = '421613',
  zkMainnet = '324',
  zkTestnet = '280',
}

type ChainConfigCommon = {
  chainId: ChainId
  nameToDisplay: string
  hardhatConfigNetworkName: string
  networkName: NetworkName
  wagmiNetworkName: WagmiNetworkName
  blockchainName: BlockchainName
  nativeCurrency: { name: string; symbol: string; decimals: number }
  externalRpcUrl: string
  deployAndForkRpcUrl: string
  blockExplorerUrl: string
  backendUrls?: BackendUrls
}

type ChainConfig = ChainConfigCommon & {
  networkName: NetworkName.Mainnet | NetworkName.Testnet
  wssUrl: string
  singleDeploymentsFile: string
}

type MainnetChainConfig = ChainConfig & {
  networkName: NetworkName.Mainnet
  publicRpcUrl: string
}

type TestnetChainConfig = ChainConfig & {
  networkName: NetworkName.Testnet
}

type HardhatChainConfig = ChainConfigCommon & {
  networkName: NetworkName.Staging | NetworkName.Local
  forkOfChainId: ChainId
  anvilHelperApiPort: number
  anvilNodePort: number
}

type StagingChainConfig = HardhatChainConfig & {
  networkName: NetworkName.Staging
  apiUrl: string
}

type LocalChainConfig = HardhatChainConfig & {
  networkName: NetworkName.Local
}

type BackendUrls = {
  ilBackend: string
}

type MainnetChainsInfo = {
  [ChainId.EthereumMainnet]: {
    hardhatConfigNetworkName: 'EthereumMainnet'
    networkName: NetworkName.Mainnet
    wagmiNetworkName: WagmiNetworkName.EthereumMainnet
    chainId: ChainId.EthereumMainnet
    blockchainName: BlockchainName.ETHEREUM
    nativeCurrency: {
      name: 'Ethereum'
      symbol: 'ETH'
      decimals: 18
    }
  } & MainnetChainConfig
  [ChainId.PolygonMainnet]: {
    hardhatConfigNetworkName: 'PolygonMainnet'
    networkName: NetworkName.Mainnet
    wagmiNetworkName: WagmiNetworkName.PolygonMainnet
    chainId: ChainId.PolygonMainnet
    blockchainName: BlockchainName.POLYGON
    nativeCurrency: {
      name: 'Matic'
      symbol: 'MATIC'
      decimals: 18
    }
  } & MainnetChainConfig
  [ChainId.ArbitrumMainnet]: {
    hardhatConfigNetworkName: 'ArbitrumMainnet'
    networkName: NetworkName.Mainnet
    wagmiNetworkName: WagmiNetworkName.ArbitrumMainnet
    chainId: ChainId.ArbitrumMainnet
    blockchainName: BlockchainName.ARBITRUM
    nativeCurrency: {
      name: 'Arbitrum ETH'
      symbol: 'AETH'
      decimals: 18
    }
  } & MainnetChainConfig
  [ChainId.zkMainnet]: {
    hardhatConfigNetworkName: 'zkMainnet'
    networkName: NetworkName.Mainnet
    wagmiNetworkName: WagmiNetworkName.ZksyncMainnet
    chainId: ChainId.zkMainnet
    blockchainName: BlockchainName.ZK_MAINNET
    nativeCurrency: {
      name: 'zkSync Era Mainnet'
      symbol: 'ETH'
      decimals: 18
    }
  } & MainnetChainConfig
}

type TestnetChainsInfo = {
  [ChainId.EthereumGoerli]: {
    hardhatConfigNetworkName: 'EthereumGoerli'
    networkName: NetworkName.Testnet
    wagmiNetworkName: WagmiNetworkName.EtherumGoerli
    chainId: ChainId.EthereumGoerli
    blockchainName: BlockchainName.ETHEREUM_GOERLI
    nativeCurrency: {
      name: 'Goerli ETH'
      symbol: 'GoerliETH'
      decimals: 18
    }
  } & TestnetChainConfig
  [ChainId.PolygonMumbai]: {
    hardhatConfigNetworkName: 'PolygonMumbai'
    networkName: NetworkName.Testnet
    wagmiNetworkName: WagmiNetworkName.PolygonMumbai
    chainId: ChainId.PolygonMumbai
    blockchainName: BlockchainName.POLYGON_MUMBAI
    nativeCurrency: {
      name: 'Matic'
      symbol: 'MATIC'
      decimals: 18
    }
  } & TestnetChainConfig
  [ChainId.zkTestnet]: {
    hardhatConfigNetworkName: 'zkTestnet'
    networkName: NetworkName.Testnet
    wagmiNetworkName: WagmiNetworkName.ZksyncTestnet
    chainId: ChainId.zkTestnet
    blockchainName: BlockchainName.ZK_TESTNET
    nativeCurrency: {
      name: 'zkSync Era Testnet'
      symbol: 'ETH'
      decimals: 18
    }
  } & TestnetChainConfig
  [ChainId.ArbitrumGoerli]: {
    hardhatConfigNetworkName: 'ArbitrumGoerli'
    networkName: NetworkName.Testnet
    wagmiNetworkName: WagmiNetworkName.ArbitrumGoerli
    chainId: ChainId.ArbitrumGoerli
    blockchainName: BlockchainName.ARBITRUM_GOERLI
    nativeCurrency: {
      name: 'Goerli ETH'
      symbol: 'GoerliETH'
      decimals: 18
    }
  } & TestnetChainConfig
}

type StagingChainsInfo = {
  [ChainId.EthereumStaging]: {
    hardhatConfigNetworkName: 'EthereumStaging'
    networkName: NetworkName.Staging
    chainId: ChainId.EthereumStaging
    blockchainName: BlockchainName.ETHEREUM
    nativeCurrency: MainnetChainsInfo[ChainId.EthereumMainnet]['nativeCurrency']
    forkOfChainId: ChainId.EthereumMainnet
  } & StagingChainConfig
  [ChainId.PolygonStaging]: {
    hardhatConfigNetworkName: 'PolygonStaging'
    networkName: NetworkName.Staging
    chainId: ChainId.PolygonStaging
    blockchainName: BlockchainName.POLYGON
    nativeCurrency: MainnetChainsInfo[ChainId.PolygonMainnet]['nativeCurrency']
    forkOfChainId: ChainId.PolygonMainnet
  } & StagingChainConfig
  [ChainId.ArbitrumStaging]: {
    hardhatConfigNetworkName: 'ArbitrumStaging'
    networkName: NetworkName.Staging
    chainId: ChainId.ArbitrumStaging
    blockchainName: BlockchainName.ARBITRUM
    nativeCurrency: MainnetChainsInfo[ChainId.ArbitrumMainnet]['nativeCurrency']
    forkOfChainId: ChainId.ArbitrumMainnet
  } & StagingChainConfig
  [ChainId.EthereumGoerliStaging]: {
    hardhatConfigNetworkName: 'EthereumGoerliStaging'
    networkName: NetworkName.Staging
    chainId: ChainId.EthereumGoerliStaging
    blockchainName: BlockchainName.ETHEREUM_GOERLI
    nativeCurrency: TestnetChainsInfo[ChainId.EthereumGoerli]['nativeCurrency']
    forkOfChainId: ChainId.EthereumGoerli
  } & StagingChainConfig
  [ChainId.PolygonMumbaiStaging]: {
    hardhatConfigNetworkName: 'PolygonMumbaiStaging'
    networkName: NetworkName.Staging
    chainId: ChainId.PolygonMumbaiStaging
    blockchainName: BlockchainName.POLYGON_MUMBAI
    nativeCurrency: TestnetChainsInfo[ChainId.PolygonMumbai]['nativeCurrency']
    forkOfChainId: ChainId.PolygonMumbai
  } & StagingChainConfig
}

type LocalChainsInfo = {
  [ChainId.EthereumLocal]: {
    hardhatConfigNetworkName: 'EthereumLocal'
    networkName: NetworkName.Local
    chainId: ChainId.EthereumLocal
    blockchainName: BlockchainName.ETHEREUM
    nativeCurrency: MainnetChainsInfo[ChainId.EthereumMainnet]['nativeCurrency']
    forkOfChainId: ChainId.EthereumMainnet
  } & LocalChainConfig
  [ChainId.PolygonLocal]: {
    hardhatConfigNetworkName: 'PolygonLocal'
    networkName: NetworkName.Local
    chainId: ChainId.PolygonLocal
    blockchainName: BlockchainName.POLYGON
    nativeCurrency: MainnetChainsInfo[ChainId.PolygonMainnet]['nativeCurrency']
    forkOfChainId: ChainId.PolygonMainnet
  } & LocalChainConfig
  [ChainId.ArbitrumLocal]: {
    hardhatConfigNetworkName: 'ArbitrumLocal'
    networkName: NetworkName.Local
    chainId: ChainId.ArbitrumLocal
    blockchainName: BlockchainName.ARBITRUM
    nativeCurrency: MainnetChainsInfo[ChainId.ArbitrumMainnet]['nativeCurrency']
    forkOfChainId: ChainId.ArbitrumMainnet
  } & LocalChainConfig
  [ChainId.EthereumGoerliLocal]: {
    hardhatConfigNetworkName: 'EthereumGoerliLocal'
    networkName: NetworkName.Local
    chainId: ChainId.EthereumGoerliLocal
    blockchainName: BlockchainName.ETHEREUM_GOERLI
    nativeCurrency: TestnetChainsInfo[ChainId.EthereumGoerli]['nativeCurrency']
    forkOfChainId: ChainId.EthereumGoerli
  } & LocalChainConfig
  [ChainId.PolygonMumbaiLocal]: {
    hardhatConfigNetworkName: 'PolygonMumbaiLocal'
    networkName: NetworkName.Local
    chainId: ChainId.PolygonMumbaiLocal
    blockchainName: BlockchainName.POLYGON_MUMBAI
    nativeCurrency: TestnetChainsInfo[ChainId.PolygonMumbai]['nativeCurrency']
    forkOfChainId: ChainId.PolygonMumbai
  } & LocalChainConfig
}

export type ChainsInfo = MainnetChainsInfo & TestnetChainsInfo & StagingChainsInfo & LocalChainsInfo

export type ChainInfo = ChainsInfo[ChainId]

export const mainnetInfo: MainnetChainsInfo = {
  [ChainId.EthereumMainnet]: {
    nameToDisplay: 'Ethereum',
    hardhatConfigNetworkName: 'EthereumMainnet',
    networkName: NetworkName.Mainnet,
    wagmiNetworkName: WagmiNetworkName.EthereumMainnet,
    chainId: ChainId.EthereumMainnet,
    blockchainName: BlockchainName.ETHEREUM,
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_1__blockchain_ethereum__network_live.json',
    externalRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/2o-x6REiw96Bw1noK6KFJbMZKkKWLf8t',
    deployAndForkRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/2o-x6REiw96Bw1noK6KFJbMZKkKWLf8t',
    blockExplorerUrl: 'https://etherscan.com',
    backendUrls: {
      ilBackend: '',
    },
    wssUrl: 'wss://polygon-mainnet.g.alchemy.com/v2/2o-x6REiw96Bw1noK6KFJbMZKkKWLf8t',
    publicRpcUrl: 'https://eth.llamarpc.com',
  },
  [ChainId.PolygonMainnet]: {
    nameToDisplay: 'Polygon',
    hardhatConfigNetworkName: 'PolygonMainnet',
    networkName: NetworkName.Mainnet,
    wagmiNetworkName: WagmiNetworkName.PolygonMainnet,
    chainId: ChainId.PolygonMainnet,
    blockchainName: BlockchainName.POLYGON,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    // https://dashboard.alchemyapi.io/apps/t2xirbr9i39lk2dx
    singleDeploymentsFile: 'chain_id_137__blockchain_polygon__network_live.json',
    externalRpcUrl: 'https://polygon-mainnet.g.alchemy.com/v2/92NxyTyKRdmDaXT61GaVbcDBpkRpLOPc',
    deployAndForkRpcUrl: 'https://polygon-mainnet.g.alchemy.com/v2/92NxyTyKRdmDaXT61GaVbcDBpkRpLOPc',
    blockExplorerUrl: 'https://explorer-mainnet.maticvigil.com',
    backendUrls: {
      ilBackend: 'https://il-backend-polygon-mainnet.cvi-team.com',
      // ilBackend: 'http://localhost:8001',
    },
    wssUrl: 'wss://polygon-mainnet.g.alchemy.com/v2/92NxyTyKRdmDaXT61GaVbcDBpkRpLOPc',
    publicRpcUrl: 'https://polygon-rpc.com',
  },
  [ChainId.ArbitrumMainnet]: {
    nameToDisplay: 'Arbitrum',
    hardhatConfigNetworkName: 'ArbitrumMainnet',
    networkName: NetworkName.Mainnet,
    wagmiNetworkName: WagmiNetworkName.ArbitrumMainnet,
    chainId: ChainId.ArbitrumMainnet,
    blockchainName: BlockchainName.ARBITRUM,
    nativeCurrency: {
      name: 'Arbitrum ETH',
      symbol: 'AETH',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_42161__blockchain_arbitrum__network_live.json',
    externalRpcUrl: 'https://arb-mainnet.g.alchemy.com/v2/UOKZ89ZJLc1k-VKPVWZtTPgWPNDa9xbB',
    // externalRpcUrl: 'https://arbitrum-mainnet.infura.io/v3/b454739b95444d53812c01d1b9c60549',
    deployAndForkRpcUrl: 'https://arb1.arbitrum.io/rpc',
    blockExplorerUrl: 'https://arbiscan.io',
    backendUrls: {
      ilBackend: 'il-backend-arbitrum-staging.cvi-team.com',
    },
    wssUrl: 'wss://arb-mainnet.g.alchemy.com/v2/UOKZ89ZJLc1k-VKPVWZtTPgWPNDa9xbB',
    publicRpcUrl: 'https://arb1.arbitrum.io/rpc',
  },
  [ChainId.zkMainnet]: {
    nameToDisplay: 'zkSync',
    hardhatConfigNetworkName: 'zkMainnet',
    networkName: NetworkName.Mainnet,
    wagmiNetworkName: WagmiNetworkName.ZksyncMainnet,
    chainId: ChainId.zkMainnet,
    blockchainName: BlockchainName.ZK_MAINNET,
    nativeCurrency: {
      name: 'zkSync Era Mainnet',
      symbol: 'ETH',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_324__blockchain_zksync__network_live.json',
    externalRpcUrl: 'https://mainnet.era.zksync.io',
    deployAndForkRpcUrl: 'https://mainnet.era.zksync.io',
    blockExplorerUrl: 'https://explorer.zksync.io',
    // externalRpcUrl: 'https://zksync2-mainnet.zksync.io',
    // deployAndForkRpcUrl: 'https://zksync2-mainnet.zksync.io',
    // blockExplorerUrl: 'https://zkscan.io/',
    backendUrls: {
      ilBackend: '',
    },
    wssUrl: 'wss://mainnet.era.zksync.io/ws',
    publicRpcUrl: 'https://mainnet.era.zksync.io',
  },
}

export const testnetsInfo: TestnetChainsInfo = {
  [ChainId.EthereumGoerli]: {
    nameToDisplay: 'Ethereum Goerli',
    hardhatConfigNetworkName: 'EthereumGoerli',
    networkName: NetworkName.Testnet,
    wagmiNetworkName: WagmiNetworkName.EtherumGoerli,
    chainId: ChainId.EthereumGoerli,
    blockchainName: BlockchainName.ETHEREUM_GOERLI,
    nativeCurrency: {
      name: 'Goerli ETH',
      symbol: 'GoerliETH',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_5__blockchain_ethereum__network_testnet.json',
    externalRpcUrl: 'https://eth-goerli.g.alchemy.com/v2/ChYO94QaOW50m6tnOt8y-3CF9D4vfcyL',
    deployAndForkRpcUrl: 'https://goerli.blockpi.network/v1/rpc/public',
    blockExplorerUrl: 'https://goerli.etherscan.io',
    backendUrls: {
      ilBackend: '',
    },
    wssUrl: 'wss://eth-goerli.g.alchemy.com/v2/ChYO94QaOW50m6tnOt8y-3CF9D4vfcyL',
  },
  [ChainId.zkTestnet]: {
    nameToDisplay: 'zkSync Testnet',
    hardhatConfigNetworkName: 'zkTestnet',
    networkName: NetworkName.Testnet,
    wagmiNetworkName: WagmiNetworkName.ZksyncTestnet,
    chainId: ChainId.zkTestnet,
    blockchainName: BlockchainName.ZK_TESTNET,
    nativeCurrency: {
      name: 'zkSync Era Testnet',
      symbol: 'ETH',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_280__blockchain_zksync__network_testnet.json',
    externalRpcUrl: 'https://testnet.era.zksync.dev',
    deployAndForkRpcUrl: 'https://testnet.era.zksync.dev',
    blockExplorerUrl: 'https://goerli.explorer.zksync.io',
    // externalRpcUrl: 'https://testnet.era.zksync.dev',
    // deployAndForkRpcUrl: 'https://testnet.era.zksync.dev',
    // blockExplorerUrl: 'https://zksync2-testnet.zkscan.io/',
    backendUrls: {
      ilBackend: '',
    },
    wssUrl: 'wss://testnet.era.zksync.dev/ws',
  },
  [ChainId.PolygonMumbai]: {
    nameToDisplay: 'Polygon Mumbai',
    hardhatConfigNetworkName: 'PolygonMumbai',
    networkName: NetworkName.Testnet,
    wagmiNetworkName: WagmiNetworkName.PolygonMumbai,
    chainId: ChainId.PolygonMumbai,
    blockchainName: BlockchainName.POLYGON_MUMBAI,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_80001__blockchain_polygon__network_testnet.json',
    externalRpcUrl: 'https://polygon-mumbai.g.alchemy.com/v2/k9EduLRWPNpVoKY-P_PZ4MBHx3804WHj',
    deployAndForkRpcUrl: 'https://polygon-mumbai.g.alchemy.com/v2/k9EduLRWPNpVoKY-P_PZ4MBHx3804WHj',
    blockExplorerUrl: 'https://mumbai.polygonscan.com',
    backendUrls: {
      ilBackend: '',
    },
    wssUrl: 'wss://polygon-mumbai.g.alchemy.com/v2/k9EduLRWPNpVoKY-P_PZ4MBHx3804WHj',
  },
  [ChainId.ArbitrumGoerli]: {
    nameToDisplay: 'Arbitrum',
    hardhatConfigNetworkName: 'ArbitrumGoerli',
    networkName: NetworkName.Testnet,
    wagmiNetworkName: WagmiNetworkName.ArbitrumGoerli,
    chainId: ChainId.ArbitrumGoerli,
    blockchainName: BlockchainName.ARBITRUM_GOERLI,
    nativeCurrency: {
      name: 'Goerli ETH',
      symbol: 'GoerliETH',
      decimals: 18,
    },
    singleDeploymentsFile: 'chain_id_421613__blockchain_arbitrum__network_testnet.json',
    externalRpcUrl: 'https://goerli-rollup.arbitrum.io/rpc',
    deployAndForkRpcUrl: 'https://goerli-rollup.arbitrum.io/rpc',
    blockExplorerUrl: 'https://goerli.arbiscan.io/',
    backendUrls: {
      ilBackend: '',
    },
    wssUrl: '',
  },
}

const stagingChainsInfo: StagingChainsInfo = {
  [ChainId.EthereumStaging]: {
    nameToDisplay: 'EthereumStaging',
    networkName: NetworkName.Staging,
    wagmiNetworkName: WagmiNetworkName.Staging,
    chainId: ChainId.EthereumStaging,
    blockchainName: BlockchainName.ETHEREUM,
    forkOfChainId: ChainId.EthereumMainnet,
    deployAndForkRpcUrl: mainnetInfo[ChainId.EthereumMainnet].externalRpcUrl,
    hardhatConfigNetworkName: 'EthereumStaging',
    nativeCurrency: mainnetInfo[ChainId.EthereumMainnet].nativeCurrency,
    blockExplorerUrl: mainnetInfo[ChainId.EthereumMainnet].blockExplorerUrl,
    externalRpcUrl: 'https://hardhat-ethereum.cvi-team.com',
    apiUrl: 'https://hardhat-ethereum-deployments-file.cvi-team.com',
    anvilNodePort: 8545,
    anvilHelperApiPort: 7001,
    backendUrls: {
      ilBackend: '',
    },
  },
  [ChainId.PolygonStaging]: {
    nameToDisplay: 'PolygonStaging',
    networkName: NetworkName.Staging,
    wagmiNetworkName: WagmiNetworkName.Staging,
    chainId: ChainId.PolygonStaging,
    blockchainName: BlockchainName.POLYGON,
    forkOfChainId: ChainId.PolygonMainnet,
    externalRpcUrl: 'https://hardhat-polygon.cvi-team.com',
    deployAndForkRpcUrl: mainnetInfo[ChainId.PolygonMainnet].externalRpcUrl,
    hardhatConfigNetworkName: 'PolygonStaging',
    nativeCurrency: mainnetInfo[ChainId.PolygonMainnet].nativeCurrency,
    blockExplorerUrl: mainnetInfo[ChainId.PolygonMainnet].blockExplorerUrl,
    apiUrl: 'https://hardhat-polygon-deployments-file.cvi-team.com',
    anvilNodePort: 8546,
    anvilHelperApiPort: 7002,
    backendUrls: {
      ilBackend: 'https://il-backend-polygon-mainnet.cvi-team.com',
    },
  },
  [ChainId.ArbitrumStaging]: {
    nameToDisplay: 'ArbitrumStaging',
    networkName: NetworkName.Staging,
    wagmiNetworkName: WagmiNetworkName.Staging,
    chainId: ChainId.ArbitrumStaging,
    blockchainName: BlockchainName.ARBITRUM,
    forkOfChainId: ChainId.ArbitrumMainnet,
    externalRpcUrl: 'https://hardhat-arbitrum.cvi-team.com',
    deployAndForkRpcUrl: mainnetInfo[ChainId.ArbitrumMainnet].externalRpcUrl,
    hardhatConfigNetworkName: 'ArbitrumStaging',
    nativeCurrency: mainnetInfo[ChainId.ArbitrumMainnet].nativeCurrency,
    blockExplorerUrl: mainnetInfo[ChainId.ArbitrumMainnet].blockExplorerUrl,
    apiUrl: 'https://hardhat-arbitrum-deployments-file.cvi-team.com',
    anvilNodePort: 8547,
    anvilHelperApiPort: 7003,
    backendUrls: {
      ilBackend: 'https://il-backend-arbitrum-mainnet.cvi-team.com',
    },
  },
  [ChainId.EthereumGoerliStaging]: {
    nameToDisplay: 'EthereumGoerliStaging',
    networkName: NetworkName.Staging,
    wagmiNetworkName: WagmiNetworkName.Staging,
    chainId: ChainId.EthereumGoerliStaging,
    blockchainName: BlockchainName.ETHEREUM_GOERLI,
    forkOfChainId: ChainId.EthereumGoerli,
    externalRpcUrl: 'https://hardhat-ethereum-goerli.cvi-team.com',
    deployAndForkRpcUrl: testnetsInfo[ChainId.EthereumGoerli].externalRpcUrl,
    hardhatConfigNetworkName: 'EthereumGoerliStaging',
    nativeCurrency: testnetsInfo[ChainId.EthereumGoerli].nativeCurrency,
    blockExplorerUrl: testnetsInfo[ChainId.EthereumGoerli].blockExplorerUrl,
    apiUrl: 'https://hardhat-ethereum-goerli-deployments-file.cvi-team.com',
    anvilNodePort: 8548,
    anvilHelperApiPort: 7004,
    backendUrls: {
      ilBackend: '',
    },
  },
  [ChainId.PolygonMumbaiStaging]: {
    nameToDisplay: 'PolygonMumbaiStaging',
    networkName: NetworkName.Staging,
    wagmiNetworkName: WagmiNetworkName.Staging,
    chainId: ChainId.PolygonMumbaiStaging,
    blockchainName: BlockchainName.POLYGON_MUMBAI,
    forkOfChainId: ChainId.PolygonMumbai,
    externalRpcUrl: 'https://hardhat-polygon-mumbai.cvi-team.com',
    deployAndForkRpcUrl: testnetsInfo[ChainId.PolygonMumbai].externalRpcUrl,
    hardhatConfigNetworkName: 'PolygonMumbaiStaging',
    nativeCurrency: testnetsInfo[ChainId.PolygonMumbai].nativeCurrency,
    blockExplorerUrl: testnetsInfo[ChainId.PolygonMumbai].blockExplorerUrl,
    apiUrl: 'https://hardhat-polygon-mumbai-deployments-file.cvi-team.com',
    anvilNodePort: 8549,
    anvilHelperApiPort: 7005,
    backendUrls: {
      ilBackend: '',
    },
  },
}

const localChainsInfo: LocalChainsInfo = {
  [ChainId.EthereumLocal]: {
    nameToDisplay: 'EthereumLocal',
    networkName: NetworkName.Local,
    wagmiNetworkName: WagmiNetworkName.Local,
    chainId: ChainId.EthereumLocal,
    blockchainName: BlockchainName.ETHEREUM,
    forkOfChainId: ChainId.EthereumMainnet,
    externalRpcUrl: 'http://localhost:8545',
    deployAndForkRpcUrl: mainnetInfo[ChainId.EthereumMainnet].externalRpcUrl,
    hardhatConfigNetworkName: 'EthereumLocal',
    nativeCurrency: mainnetInfo[ChainId.EthereumMainnet].nativeCurrency,
    blockExplorerUrl: mainnetInfo[ChainId.EthereumMainnet].blockExplorerUrl,
    anvilNodePort: 8545,
    anvilHelperApiPort: 7001,
    backendUrls: {
      ilBackend: '',
    },
  },
  [ChainId.PolygonLocal]: {
    nameToDisplay: 'PolygonLocal',
    networkName: NetworkName.Local,
    wagmiNetworkName: WagmiNetworkName.Local,
    chainId: ChainId.PolygonLocal,
    blockchainName: BlockchainName.POLYGON,
    forkOfChainId: ChainId.PolygonMainnet,
    externalRpcUrl: 'http://localhost:8546',
    deployAndForkRpcUrl: mainnetInfo[ChainId.PolygonMainnet].externalRpcUrl,
    hardhatConfigNetworkName: 'PolygonLocal',
    nativeCurrency: mainnetInfo[ChainId.PolygonMainnet].nativeCurrency,
    blockExplorerUrl: mainnetInfo[ChainId.PolygonMainnet].blockExplorerUrl,
    anvilNodePort: 8546,
    anvilHelperApiPort: 7002,
    backendUrls: {
      ilBackend: '',
    },
  },
  [ChainId.ArbitrumLocal]: {
    nameToDisplay: 'ArbitrumLocal',
    networkName: NetworkName.Local,
    wagmiNetworkName: WagmiNetworkName.Local,
    chainId: ChainId.ArbitrumLocal,
    blockchainName: BlockchainName.ARBITRUM,
    forkOfChainId: ChainId.ArbitrumMainnet,
    externalRpcUrl: 'http://localhost:8547',
    deployAndForkRpcUrl: mainnetInfo[ChainId.ArbitrumMainnet].externalRpcUrl,
    hardhatConfigNetworkName: 'ArbitrumLocal',
    nativeCurrency: mainnetInfo[ChainId.ArbitrumMainnet].nativeCurrency,
    blockExplorerUrl: mainnetInfo[ChainId.ArbitrumMainnet].blockExplorerUrl,
    anvilNodePort: 8547,
    anvilHelperApiPort: 7003,
    backendUrls: {
      ilBackend: '',
    },
  },
  [ChainId.EthereumGoerliLocal]: {
    nameToDisplay: 'EthereumGoerliLocal',
    networkName: NetworkName.Local,
    wagmiNetworkName: WagmiNetworkName.Local,
    chainId: ChainId.EthereumGoerliLocal,
    blockchainName: BlockchainName.ETHEREUM_GOERLI,
    forkOfChainId: ChainId.EthereumGoerli,
    externalRpcUrl: 'http://localhost:8548',
    deployAndForkRpcUrl: testnetsInfo[ChainId.EthereumGoerli].externalRpcUrl,
    hardhatConfigNetworkName: 'EthereumGoerliLocal',
    nativeCurrency: testnetsInfo[ChainId.EthereumGoerli].nativeCurrency,
    blockExplorerUrl: testnetsInfo[ChainId.EthereumGoerli].blockExplorerUrl,
    anvilNodePort: 8548,
    anvilHelperApiPort: 7004,
    backendUrls: {
      ilBackend: '',
    },
  },
  [ChainId.PolygonMumbaiLocal]: {
    nameToDisplay: 'PolygonMumbaiLocal',
    networkName: NetworkName.Local,
    wagmiNetworkName: WagmiNetworkName.Local,
    chainId: ChainId.PolygonMumbaiLocal,
    blockchainName: BlockchainName.POLYGON_MUMBAI,
    forkOfChainId: ChainId.PolygonMumbai,
    externalRpcUrl: 'http://localhost:8549',
    deployAndForkRpcUrl: testnetsInfo[ChainId.PolygonMumbai].externalRpcUrl,
    hardhatConfigNetworkName: 'PolygonMumbaiLocal',
    nativeCurrency: testnetsInfo[ChainId.PolygonMumbai].nativeCurrency,
    blockExplorerUrl: testnetsInfo[ChainId.PolygonMumbai].blockExplorerUrl,
    anvilNodePort: 8549,
    anvilHelperApiPort: 7005,
    backendUrls: {
      ilBackend: '',
    },
  },
}

export const CHAIN_IDS_INFO: ChainsInfo = { ...mainnetInfo, ...testnetsInfo, ...stagingChainsInfo, ...localChainsInfo }

export type ChainIdsOfBlockchainName<B extends BlockchainName> = {
  [chainId in ChainId]: ChainsInfo[chainId]['blockchainName'] extends B ? chainId : never
}[ChainId]

export type ChainIdsOfNetworkName<B extends NetworkName> = {
  [chainId in ChainId]: ChainsInfo[chainId]['networkName'] extends B ? chainId : never
}[ChainId]

export type ArbitrumChainIds = ChainIdsOfBlockchainName<BlockchainName.ARBITRUM>
export type PolygonChainIds = ChainIdsOfBlockchainName<BlockchainName.POLYGON>
export type EthereumChainIds = ChainIdsOfBlockchainName<BlockchainName.ETHEREUM>
export const ARBITRUM_CHAIN_IDS = Object.values(CHAIN_IDS_INFO)
  .filter(c => c.blockchainName === BlockchainName.ARBITRUM)
  .map(c => c.chainId)
export const POLYGON_CHAIN_IDS = Object.values(CHAIN_IDS_INFO)
  .filter(c => c.blockchainName === BlockchainName.POLYGON)
  .map(c => c.chainId)
export const ETHEREUM_CHAIN_IDS = Object.values(CHAIN_IDS_INFO)
  .filter(c => c.blockchainName === BlockchainName.ETHEREUM)
  .map(c => c.chainId)
export const isArbitrumChainId = (chainId: ChainId): chainId is ArbitrumChainIds => ARBITRUM_CHAIN_IDS.includes(chainId)
export const isPolygonChainId = (chainId: ChainId): chainId is PolygonChainIds => POLYGON_CHAIN_IDS.includes(chainId)
export const isEthereumChainId = (chainId: ChainId): chainId is EthereumChainIds => ETHEREUM_CHAIN_IDS.includes(chainId)

// chain ids that are supported by the infrastructure
export const IL_SUPPORTED_CHAIN_IDS = [ChainId.PolygonStaging, ChainId.PolygonLocal, ChainId.PolygonMainnet] as const
export type IlSupportedChainIds = typeof IL_SUPPORTED_CHAIN_IDS[number]
export const isIlSupportedChainId = (chainId: ChainId): chainId is IlSupportedChainIds =>
  (IL_SUPPORTED_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const TV_SUPPORTED_CHAIN_IDS = [ChainId.ArbitrumStaging, ChainId.ArbitrumLocal, ChainId.ArbitrumMainnet] as const
export type TvSupportedChainIds = typeof TV_SUPPORTED_CHAIN_IDS[number]
export const isTVSupportedChainId = (chainId: ChainId): chainId is TvSupportedChainIds =>
  (TV_SUPPORTED_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const ZKSYNC_CHAIN_IDS = [ChainId.zkMainnet, ChainId.zkTestnet] as const
export type ZkSupportedChainIds = typeof ZKSYNC_CHAIN_IDS[number]
export const isZKSupportedChainId = (chainId: ChainId): chainId is ZkSupportedChainIds =>
  (ZKSYNC_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const VESTING_SUPPORTED_CHAIN_IDS = [
  ChainId.ArbitrumStaging,
  ChainId.ArbitrumLocal,
  ChainId.ArbitrumMainnet,
  ChainId.zkTestnet,
  ChainId.zkMainnet,
] as const
export type VestingSupportedChainIds = typeof VESTING_SUPPORTED_CHAIN_IDS[number]
export const isVestingSupportedChainId = (chainId: ChainId): chainId is VestingSupportedChainIds =>
  (VESTING_SUPPORTED_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const TV_IL_SUPPORTED_CHAIN_IDS = [...new Set([...IL_SUPPORTED_CHAIN_IDS, ...TV_SUPPORTED_CHAIN_IDS])] as const

export const CVI_SUPPORTED_CHAIN_IDS = [
  ...new Set([...IL_SUPPORTED_CHAIN_IDS, ...TV_SUPPORTED_CHAIN_IDS, ...VESTING_SUPPORTED_CHAIN_IDS]),
] as const
export type CVISupportedChainIds = typeof CVI_SUPPORTED_CHAIN_IDS[number]
export const isCVISupportedChainId = (chainId: ChainId): chainId is CVISupportedChainIds =>
  (CVI_SUPPORTED_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const CVI_ORACLE_SUPPORTED_CHAIN_IDS = [
  ...new Set([...IL_SUPPORTED_CHAIN_IDS, ...TV_SUPPORTED_CHAIN_IDS]),
] as const
export type CVIOracleSupportedChainIds = typeof CVI_ORACLE_SUPPORTED_CHAIN_IDS[number]

// blockchain names that are supported by the infrastructure
export const IL_SUPPORTED_BLOCKCHAIN_NAMES = [BlockchainName.POLYGON] as const
export type IlSupportedBlockchainNames = typeof IL_SUPPORTED_BLOCKCHAIN_NAMES[number]

export const TV_SUPPORTED_BLOCKCHAIN_NAMES = [BlockchainName.ARBITRUM] as const
export type TvSupportedBlockchainNames = typeof TV_SUPPORTED_BLOCKCHAIN_NAMES[number]

export const VESTING_SUPPORTED_BLOCKCHAIN_NAMES = [
  BlockchainName.ARBITRUM,
  BlockchainName.ZK_TESTNET,
  BlockchainName.ZK_MAINNET,
] as const
export type VestingSupportedBlockchainNames = typeof VESTING_SUPPORTED_BLOCKCHAIN_NAMES[number]

export const ZK_SUPPORT_BLOCKCHAIN_NAMES = [BlockchainName.ZK_TESTNET, BlockchainName.ZK_MAINNET] as const

export const CVI_SUPPORTED_BLOCKCHAIN_NAMES = [
  ...new Set([...IL_SUPPORTED_BLOCKCHAIN_NAMES, ...TV_SUPPORTED_BLOCKCHAIN_NAMES]),
] as const
export type CVISupportedBlockchainNames = IlSupportedBlockchainNames | TvSupportedBlockchainNames

const MAINNET_CHAIN_IDS: MainnetChainIds[] = Object.values(mainnetInfo).map(c => c.chainId)
const TESTNET_CHAIN_IDS: TestnetChainIds[] = Object.values(testnetsInfo).map(c => c.chainId)
const MAINNET_OR_TESTNET_CHAIN_IDS: MainnetOrTestnetChainIds[] = [...MAINNET_CHAIN_IDS, ...TESTNET_CHAIN_IDS]

export type MainnetChainIds = ChainIdsOfNetworkName<NetworkName.Mainnet>
export type TestnetChainIds = ChainIdsOfNetworkName<NetworkName.Testnet>
export type MainnetOrTestnetChainIds = MainnetChainIds | TestnetChainIds

export const isMainnetChainId = (chainId: ChainId): chainId is MainnetChainIds =>
  (MAINNET_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const isTestnetChainId = (chainId: ChainId): chainId is TestnetChainIds =>
  (TESTNET_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const isMainnetOrTestnetChainId = (chainId: ChainId): chainId is MainnetOrTestnetChainIds =>
  (MAINNET_OR_TESTNET_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export const LOCAL_CHAIN_IDS: LocalChainIds[] = Object.values(localChainsInfo).map(c => c.chainId)
export const STAGING_CHAIN_IDS: StagingChainIds[] = Object.values(stagingChainsInfo).map(c => c.chainId)
export const HARDHAT_SUPPORTED_CHAIN_IDS: HardhatSupportedChainIds[] = [...LOCAL_CHAIN_IDS, ...STAGING_CHAIN_IDS]

export type LocalChainIds = ChainIdsOfNetworkName<NetworkName.Local>
export type StagingChainIds = ChainIdsOfNetworkName<NetworkName.Staging>
export type HardhatSupportedChainIds = LocalChainIds | StagingChainIds

export const isLocalChainId = (chainId: ChainId): chainId is LocalChainIds =>
  (LOCAL_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)
export const isStagingChainId = (chainId: ChainId): chainId is StagingChainIds =>
  (STAGING_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)
export const isHardhatChainId = (chainId: ChainId): chainId is HardhatSupportedChainIds =>
  (HARDHAT_SUPPORTED_CHAIN_IDS as ReadonlyArray<ChainId>).includes(chainId)

export type ChainIdsOfForkedNetworkName<B extends NetworkName> = {
  [chainId in HardhatSupportedChainIds]: ChainsInfo[ChainsInfo[chainId]['forkOfChainId']]['networkName'] extends B
    ? chainId
    : never
}[HardhatSupportedChainIds]

export type ForkedFromTestnetChainId = ChainIdsOfForkedNetworkName<NetworkName.Testnet>
export const isForkedFromTestnetChainId = (chainId: ChainId): chainId is ForkedFromTestnetChainId =>
  (
    HARDHAT_SUPPORTED_CHAIN_IDS.filter(c => isTestnetChainId(CHAIN_IDS_INFO[c].forkOfChainId)) as ReadonlyArray<ChainId>
  ).includes(chainId)

export const isTestnetOrForkedFromTestnetChainId = (
  chainId: ChainId,
): chainId is TestnetChainIds | ForkedFromTestnetChainId =>
  isTestnetChainId(chainId) || isForkedFromTestnetChainId(chainId)

type INVALIDATION_KEY_SCHEME = {
  [chainId in ChainId]: {
    'cvi-oracle-events-group': `cvi-oracle-events-group::${chainId}::${typeof CHAIN_IDS_INFO[chainId]['blockchainName']}::0`
    'latest-block-number': `latest-block-number::${chainId}::${typeof CHAIN_IDS_INFO[chainId]['blockchainName']}::0`
    'general-info-of-event-from-block': `general-info-of-event-from-block::${chainId}::${typeof CHAIN_IDS_INFO[chainId]['blockchainName']}::0`
    'general-info-of-event-by-address': `general-info-of-event-by-address::${chainId}::${typeof CHAIN_IDS_INFO[chainId]['blockchainName']}::0`
  }
}

export type RedisInvalidationKeysName = keyof INVALIDATION_KEY_SCHEME[ChainId]
export type RedisInvalidationKeysValue = INVALIDATION_KEY_SCHEME[ChainId][RedisInvalidationKeysName]

export const REDIS_INVALIDATION_KEY_BY_CHAIN_ID = CVI_SUPPORTED_CHAIN_IDS.reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: {
      'cvi-oracle-events-group': `cvi-oracle-events-group::${CHAIN_IDS_INFO[curr].chainId}::${CHAIN_IDS_INFO[curr].blockchainName}::0`,
      'latest-block-number': `latest-block-number::${CHAIN_IDS_INFO[curr].chainId}::${CHAIN_IDS_INFO[curr].blockchainName}::0`,
      'general-info-of-event-from-block': `general-info-of-event-from-block::${CHAIN_IDS_INFO[curr].chainId}::${CHAIN_IDS_INFO[curr].blockchainName}::0`,
      'general-info-of-event-by-address': `general-info-of-event-by-address::${CHAIN_IDS_INFO[curr].chainId}::${CHAIN_IDS_INFO[curr].blockchainName}::0`,
    },
  }
}, {} as Record<ChainId, Record<RedisInvalidationKeysName, RedisInvalidationKeysValue>>)
