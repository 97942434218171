import { isZKSupportedChainId } from '@coti-cvi/lw-sdk'
import type { InverifyContext } from '../context/inversify-context'
import type { MenuItem } from '../types'
import type { Wrappers } from './wrappers'
import { ethers } from 'ethers'

export class ZKSyncNFT {
  public readonly zkSyncNFTMenu: { [key: string]: MenuItem } = {
    i: { description: 'info', action: () => this.info() },
    m: { description: 'mint', action: () => this.mint() },
    c: { description: 'change Nft Uri Ipfs Cid', action: () => this.changeNftUriIpfsCid() },
    p: { description: 'change NFT price (in GOVI) inside paymaster', action: () => this.changeNftPriceInGovi() },
    w: {
      description: 'withdraw eth and govi from paymaster to paymaster-owner-address',
      action: () => this.withdrawEthAndGoviFromPaymasterToOwner(),
    },
  }

  constructor(private readonly inverifyContext: Required<InverifyContext>, private readonly wrappers: Wrappers) {}

  private async getService() {
    if (!isZKSupportedChainId(this.inverifyContext.chainId)) {
      throw new Error('zkSync NFT is not supported on this chainId.')
    }
    return this.inverifyContext.inversifyContainer.getByBlockchain(
      this.inverifyContext.chainId,
      'ZKSyncNFTInversifyService',
    )
  }

  public async getOnBoardProvider() {
    const wallets = await this.inverifyContext.useOnBoardClient().connectWallet()
    if (wallets.length === 0 || !wallets) {
      throw new Error(`fail to connect`)
    }
    return new ethers.providers.Web3Provider(wallets[0].provider)
  }

  public async info(): Promise<void> {
    const service = await this.getService()
    const [signerTokenId, balances, goviPrice] = await Promise.all([
      service.getNftIdByAddress(this.inverifyContext.signerInversifyService.address),
      service.getPaymasterBalances(),
      service.getNftPriceInGovi(),
    ])

    this.wrappers.writeOutput(`GOVI contract Address: "${service.tokenGOVI.address}"`)
    this.wrappers.writeOutput(`NFT contract Address: "${service.nftContract.address}"`)
    this.wrappers.writeOutput(
      `Paymaster contract Address: "${service.paymasterContract.address}". Balances: ${balances.ethBalance} ETH, ${balances.goviBalance} GOVI`,
    )
    this.wrappers.writeOutput(`price in govi: ${goviPrice} GOVI`)
    this.wrappers.writeOutput(`signer tokenId: "${signerTokenId ?? 'None'}"`)
  }

  public async mint(): Promise<void> {
    const provider = await this.getOnBoardProvider()
    const service = await this.getService()
    const [goviBalance, { goviCostString }] = await Promise.all([
      service.tokenGOVI.balanceToString(this.inverifyContext.signerInversifyService.address),
      service.getCostOfMintInGOVI(),
    ])
    await this.wrappers.question(`confirm minting of nft! balance: ${goviBalance} cost: ${goviCostString}`)
    const res = await service.mintNFT({ injectedProvider: provider })
    const signerTokenId = await service.getNftIdByAddress(this.inverifyContext.signerInversifyService.address)
    this.wrappers.writeOutput(
      `minted tokenId: "${signerTokenId}", NFT contract Address: "${service.nftContract.address}", tsHash: "${res.transactionHash}"`,
    )
  }

  public async withdrawEthAndGoviFromPaymasterToOwner(): Promise<void> {
    const provider = await this.getOnBoardProvider()
    const service = await this.getService()

    const balances = await service.getPaymasterBalances()

    this.wrappers.writeOutput(
      `withdrawing: ${balances.ethBalance} ETH, ${balances.goviBalance} GOVI to: ${this.inverifyContext.signerInversifyService.address}...`,
    )
    await service.withdrawEthAndGoviFromPaymasterToOwner(provider)
  }

  public async changeNftUriIpfsCid(): Promise<void> {
    const provider = await this.getOnBoardProvider()
    const service = await this.getService()

    this.wrappers.writeOutput(`current nft ipfs cid: ${(await service.nftContract.nftURI()) ?? 'Empty'}`)
    const newUri = await this.wrappers.question(`new NFT IPFS CID: `)
    await service.changeNftUriIpfsCid(provider, newUri)
  }

  public async changeNftPriceInGovi(): Promise<void> {
    const service = await this.getService()
    this.wrappers.writeOutput(`current nft price: ${await service.getNftPriceInGovi()} GOVI`)
    const newPriceInGovi = await this.wrappers.question(`new price in GOVI: `)
    const provider = await this.getOnBoardProvider()
    await service.setNftPriceInGovi(provider, +newPriceInGovi)
  }
}
